export const GET_SESSION_TYPES = "GET_SESSION_TYPES";
export const GET_SESSION_TYPE_BY_ID = "GET_SESSION_TYPE_BY_ID";
export const UPDATE_SESSION_TYPE = "UPDATE_SESSION_TYPE";
export const DELETE_SESSION_TYPE = "DELETE_SESSION_TYPE";
export const CREATE_SESSION_TYPE = "CREATE_SESSION_TYPE";
export const GET_SESSION_TYPE_SESSION_MEDIA = "GET_SESSION_TYPE_SESSION_MEDIA";
export const GET_SESSION_TYPE_PRODUCTION_TYPES =
  "GET_SESSION_TYPE_PRODUCTION_TYPES";
export const GET_SESSION_TYPE_CATEGORIES = "GET_SESSION_TYPE_CATEGORIES";
export const GET_SESSION_TYPE_RATES = "GET_SESSION_TYPE_RATES";
