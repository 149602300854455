import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const eventStatuses = `/${service}/eventStatuses`;

export function getEventStatuses() {
  const url = `${eventStatuses}`;
  return {
    method: GET,
    url,
  };
}

export function getEventStatuseById(id) {
  const url = `${eventStatuses}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateEventStatuse(id, body) {
  const url = `${eventStatuses}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEventStatuse(id) {
  const url = `${eventStatuses}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEventStatuse(body) {
  const url = `${eventStatuses}`;
  return {
    method: POST,
    url,
    body,
  };
}
