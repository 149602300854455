// actions
import * as Actions from "actions";
import MercuryNotify from "components/MercuryNotify";
import Notify from "components/Notify";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperStudioSessionPage from "../component/WrapperStudioSessionPage";

const mapStateToProps = (state) => ({
  instruments: state.instruments,
  app: state.app,
  sessionTypes: state.sessionTypes,
  projects: state.projects,
  payrolls: state.payrolls,
  instrumentSections: state.instrumentSections,
  instrumentFamilies: state.instrumentFamilies,
  sectionRoles: state.sectionRoles,
  eventStatuses: state.eventStatuses,
  layoutPresets: state.layoutPresets,
  studios: state.studios,
  configurations: state.configurations,
  studioSessionStatuses: state.studioSessionStatuses,
  studioSessionDashboard: state.studioSessionDashboard,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getRosterNotes: (...args) => dispatch(Actions.getRosterNotes(...args)),
  createRosterNote: (...args) => dispatch(Actions.createRosterNote(...args)),
  updateRosterNote: (...args) => dispatch(Actions.updateRosterNote(...args)),
  deleteRosterNote: (...args) => dispatch(Actions.deleteRosterNote(...args)),
  getLinks: (...args) => dispatch(Actions.getLinks(...args)),
  createLink: (...args) => dispatch(Actions.createLink(...args)),
  updateLink: (...args) => dispatch(Actions.updateLink(...args)),
  deleteLink: (...args) => dispatch(Actions.deleteLink(...args)),
  createTie: (...args) => dispatch(Actions.createTie(...args)),
  updateTie: (...args) => dispatch(Actions.updateTie(...args)),
  deleteTie: (...args) => dispatch(Actions.deleteTie(...args)),
  getStudioSessionById: (...args) =>
    dispatch(Actions.getStudioSessionById(...args)),
  getInstruments: (...args) => dispatch(Actions.getInstruments(...args)),
  getProjects: (...args) => dispatch(Actions.getProjects(...args)),
  getProjectLayout: (...args) => dispatch(Actions.getProjectLayout(...args)),
  getStudioSessionMusicians: (...args) =>
    dispatch(Actions.getStudioSessionMusicians(...args)),
  getStudioSessionMusicianById: (...args) =>
    dispatch(Actions.getStudioSessionMusicianById(...args)),
  getStudioSessionTunes: (...args) =>
    dispatch(Actions.getStudioSessionTunes(...args)),
  getStudioSessionRehearsals: (...args) =>
    dispatch(Actions.getStudioSessionRehearsals(...args)),
  createStudioSessionMusician: (...args) =>
    dispatch(Actions.createStudioSessionMusician(...args)),
  updateStudioSessionMusician: (...args) =>
    dispatch(Actions.updateStudioSessionMusician(...args)),
  deleteStudioSessionMusician: (...args) =>
    dispatch(Actions.deleteStudioSessionMusician(...args)),
  createMusicianInstrument: (...args) =>
    dispatch(Actions.createMusicianInstrument(...args)),
  deleteMusicianInstrument: (...args) =>
    dispatch(Actions.deleteMusicianInstrument(...args)),
  updateStudioSession: (...args) =>
    dispatch(Actions.updateStudioSession(...args)),
  updateMusician: (...args) => dispatch(Actions.updateMusician(...args)),
  createMusician: (...args) => dispatch(Actions.createMusician(...args)),
  deleteMusician: (...args) => dispatch(Actions.deleteMusician(...args)),
  getMusicianById: (...args) => dispatch(Actions.getMusicianById(...args)),
  getSessionTypes: (...args) => dispatch(Actions.getSessionTypes(...args)),
  updateTune: (...args) => dispatch(Actions.updateTune(...args)),
  createTune: (...args) => dispatch(Actions.createTune(...args)),
  deleteTune: (...args) => dispatch(Actions.deleteTune(...args)),
  updateRehearsal: (...args) => dispatch(Actions.updateRehearsal(...args)),
  createRehearsal: (...args) => dispatch(Actions.createRehearsal(...args)),
  deleteRehearsal: (...args) => dispatch(Actions.deleteRehearsal(...args)),
  calculatePensionWelfare: (...args) =>
    dispatch(Actions.calculatePensionWelfare(...args)),
  getSessionCategories: (...args) =>
    dispatch(Actions.getSessionCategories(...args)),
  createStudioSessionCategory: (...args) =>
    dispatch(Actions.createStudioSessionCategory(...args)),
  deleteStudioSessionCategory: (...args) =>
    dispatch(Actions.deleteStudioSessionCategory(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),
  getInstrumentFamilies: (...args) =>
    dispatch(Actions.getInstrumentFamilies(...args)),
  getInstrumentSections: (...args) =>
    dispatch(Actions.getInstrumentSections(...args)),
  createSessionSection: (...args) =>
    dispatch(Actions.createSessionSection(...args)),
  deleteSessionSection: (...args) =>
    dispatch(Actions.deleteSessionSection(...args)),
  getStudioSessionSections: (...args) =>
    dispatch(Actions.getStudioSessionSections(...args)),
  getSectionRoles: (...args) => dispatch(Actions.getSectionRoles(...args)),
  getEventStatuses: (...args) => dispatch(Actions.getEventStatuses(...args)),
  getStudioSessionEvents: (...args) =>
    dispatch(Actions.getStudioSessionEvents(...args)),
  createEvent: (...args) => dispatch(Actions.createEvent(...args)),
  updateEvent: (...args) => dispatch(Actions.updateEvent(...args)),
  getStudios: (...args) => dispatch(Actions.getStudios(...args)),
  getInstrumentSectionMusicians: (...args) =>
    dispatch(Actions.getInstrumentSectionMusicians(...args)),
  updateIdentification: (...args) =>
    dispatch(Actions.updateIdentification(...args)),
  createIdentification: (...args) =>
    dispatch(Actions.createIdentification(...args)),
  deleteIdentification: (...args) =>
    dispatch(Actions.deleteIdentification(...args)),
  getStudioSessionIdentification: (...args) =>
    dispatch(Actions.getStudioSessionIdentification(...args)),
  updateProject: (...args) => dispatch(Actions.updateProject(...args)),
  updateEngagement: (...args) => dispatch(Actions.updateEngagement(...args)),
  createEngagement: (...args) => dispatch(Actions.createEngagement(...args)),
  deleteEngagement: (...args) => dispatch(Actions.deleteEngagement(...args)),
  getStudioSessionEngagements: (...args) =>
    dispatch(Actions.getStudioSessionEngagements(...args)),
  createSessionFamilie: (...args) =>
    dispatch(Actions.createSessionFamilie(...args)),
  getStudioSessionStatuses: (...args) =>
    dispatch(Actions.getStudioSessionStatuses(...args)),
  getSessionMusicianStatuses: (...args) =>
    dispatch(Actions.getSessionMusicianStatuses(...args)),
  notify: (...args) => dispatch(Actions.notify(...args)),
  duplicateSession: (...args) => dispatch(Actions.duplicateSession(...args)),
  notifySessionMusician: (...args) =>
    dispatch(Actions.notifySessionMusician(...args)),
  getPayrolls: (...args) => dispatch(Actions.getPayrolls(...args)),
  getLayoutPresets: (...args) => dispatch(Actions.getLayoutPresets(...args)),
  getLayoutPresetById: (...args) =>
    dispatch(Actions.getLayoutPresetById(...args)),
  createLayoutPreset: (...args) =>
    dispatch(Actions.createLayoutPreset(...args)),
  deleteLayoutPreset: (...args) =>
    dispatch(Actions.deleteLayoutPreset(...args)),
  studioSessionAutofill: (...args) =>
    dispatch(Actions.studioSessionAutofill(...args)),
  getConfigurations: (...args) => dispatch(Actions.getConfigurations(...args)),
  getMercuryJob: (...args) => dispatch(Actions.getMercuryJob(...args)),
  getRhapsodyJob: (...args) => dispatch(Actions.getRhapsodyJob(...args)),
  updateMercuryJob: (...args) => dispatch(Actions.updateMercuryJob(...args)),
  getStudioSessionMercury: (...args) =>
    dispatch(Actions.getStudioSessionMercury(...args)),
  createPost: (...args) => dispatch(Actions.createPost(...args)),
  getStages: (...args) => dispatch(Actions.getStages(...args)),
});
class StudioSessionPage extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    instruments: PropTypes.array,
    projects: PropTypes.array,
    getLayoutPresets: PropTypes.func,
    createLayoutPreset: PropTypes.func,
    deleteLayoutPreset: PropTypes.func,
    getLayoutPresetById: PropTypes.func,
    getRosterNotes: PropTypes.func,
    createRosterNote: PropTypes.func,
    updateRosterNote: PropTypes.func,
    deleteRosterNote: PropTypes.func,
    getInstruments: PropTypes.func,
    getConfigurations: PropTypes.func,
    updateStudioSession: PropTypes.func,
    calculatePensionWelfare: PropTypes.func,
    getMercuryJob: PropTypes.func,
    createStudioSessionMusician: PropTypes.func,
    updateStudioSessionMusician: PropTypes.func,
    deleteStudioSessionMusician: PropTypes.func,
    studioSessionAutofill: PropTypes.func,
    deleteMusicianInstrument: PropTypes.func,
    updateProject: PropTypes.func,
    getProjectLayout: PropTypes.func,
    createMusicianInstrument: PropTypes.func,
    createStudioSessionCategory: PropTypes.func,
    deleteStudioSessionCategory: PropTypes.func,
    getStudioSessionEngagements: PropTypes.func,
    getStudioSessionMusicianById: PropTypes.func,
    getStudioSessionMercury: PropTypes.func,
    getLinks: PropTypes.func,
    createLink: PropTypes.func,
    updateLink: PropTypes.func,
    deleteLink: PropTypes.func,
    createEvent: PropTypes.func,
    updateEvent: PropTypes.func,
    createMusician: PropTypes.func,
    getMusicianById: PropTypes.func,
    updateMusician: PropTypes.func,
    deleteMusician: PropTypes.func,
    getSessionTypes: PropTypes.func,
    getProjects: PropTypes.func,
    sessionTypes: PropTypes.array,
    layoutPresets: PropTypes.array,
    getStudioSessionTunes: PropTypes.func,
    updateTune: PropTypes.func,
    createTune: PropTypes.func,
    deleteTune: PropTypes.func,
    updateIdentification: PropTypes.func,
    createIdentification: PropTypes.func,
    deleteIdentification: PropTypes.func,
    updateEngagement: PropTypes.func,
    createEngagement: PropTypes.func,
    deleteEngagement: PropTypes.func,
    getStudioSessionIdentification: PropTypes.func,
    getStudioSessionRehearsals: PropTypes.func,
    updateRehearsal: PropTypes.func,
    createRehearsal: PropTypes.func,
    deleteRehearsal: PropTypes.func,
    getCurrentCompany: PropTypes.func,
    getInstrumentFamilies: PropTypes.func,
    getInstrumentSections: PropTypes.func,
    instrumentSections: PropTypes.array,
    instrumentFamilies: PropTypes.array,
    createSessionSection: PropTypes.func,
    deleteSessionSection: PropTypes.func,
    getStudioSessionSections: PropTypes.func,
    getStudioSessionEvents: PropTypes.func,
    getSectionRoles: PropTypes.func,
    createTie: PropTypes.func,
    updateTie: PropTypes.func,
    deleteTie: PropTypes.func,
    getEventStatuses: PropTypes.func,
    eventStatuses: PropTypes.array,
    sectionRoles: PropTypes.array,
    getStudios: PropTypes.func,
    getInstrumentSectionMusicians: PropTypes.func,
    studios: PropTypes.array,
    createSessionFamilie: PropTypes.func,
    notify: PropTypes.func,
    duplicateSession: PropTypes.func,
    notifySessionMusician: PropTypes.func,
    getStudioSessionStatuses: PropTypes.func,
    getSessionMusicianStatuses: PropTypes.func,
    studioSessionStatuses: PropTypes.array,
    getPayrolls: PropTypes.func,
    payrolls: PropTypes.array,
    studioSessionDashboard: PropTypes.array,
    updateMercuryJob: PropTypes.func,
    getRhapsodyJob: PropTypes.func,
    app: PropTypes.object,
    createPost: PropTypes.func,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      getInstruments,
      getProjects,
      getSessionTypes,
      getInstrumentFamilies,
      getInstrumentSections,
      getSectionRoles,
      getEventStatuses,
      getStages,
      getPayrolls,
      getLayoutPresets,
      getStudios,
      getConfigurations,
    } = this.props;

    const urlParams = getJsonFromUrl(location);
    this.state = {
      sessionID: urlParams.id,
      tuto: urlParams.tuto !== undefined,
      studioSession: {},
      urlParams,
      studioSessionMusicians: [],
      studioSessionTunes: [],
      studioSessionRehearsals: [],
      studioSessionIdentifications: [],
      studioSessionEngagements: [],
      loading: true,
    };
    getInstruments();
    getStages();
    getSessionTypes();
    getProjects();
    getPayrolls();
    getConfigurations();
    getInstrumentFamilies();
    getInstrumentSections();
    getSectionRoles();
    getEventStatuses();
    getStudios();
    getLayoutPresets();
    this.refresh();
  }

  async refresh() {
    const {
      getStudioSessionById,
      getStudioSessionMusicians,
      getStudioSessionTunes,
      getStudioSessionRehearsals,
      getCurrentCompany,
      getStudioSessionIdentification,
      getStudioSessionEngagements,
    } = this.props;
    const { sessionID } = this.state;
    const resps = await Promise.all([
      getStudioSessionById(sessionID),
      getStudioSessionMusicians(sessionID),
      getStudioSessionTunes(sessionID),
      getCurrentCompany(),
      getStudioSessionRehearsals(sessionID),
      getStudioSessionIdentification(sessionID),
      getStudioSessionEngagements(sessionID),
    ]);

    if (resps[0].success) {
      this.setState({
        studioSession: resps[0].payload,
        studioSessionMusicians: resps[1].payload,
        studioSessionTunes: resps[2].payload,
        currentCompany: resps[3].payload,
        studioSessionRehearsals: resps[4].payload,
        studioSessionIdentifications: resps[5].payload,
        studioSessionEngagements: resps[6].payload,
        refreshKey: Date.now(),
        loading: false,
      });
    }
  }

  async hiringRefresh() {
    const { getStudioSessionById } = this.props;
    const { sessionID } = this.state;
    const resps = await Promise.all([getStudioSessionById(sessionID)]);

    this.setState({
      studioSession: resps[0].payload,
      refreshKey: Date.now(),
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      projects,
      layoutPresets,
      updateStudioSession,
      calculatePensionWelfare,
      getLayoutPresetById,
      updateStudioSessionMusician,
      deleteStudioSessionMusician,
      createStudioSessionMusician,
      getInstrumentSectionMusicians,
      getStudioSessionMusicianById,
      getStudioSessionMercury,
      getLinks,
      createLink,
      updateLink,
      deleteLink,
      createMusicianInstrument,
      createMusician,
      deleteMusicianInstrument,
      getMusicianById,
      updateMusician,
      deleteMusician,
      instruments,
      updateTune,
      createTune,
      deleteTune,
      updateRehearsal,
      createRehearsal,
      deleteRehearsal,
      updateIdentification,
      createIdentification,
      deleteIdentification,
      updateProject,
      updateEngagement,
      createEngagement,
      deleteEngagement,
      sessionTypes,
      createStudioSessionCategory,
      deleteStudioSessionCategory,
      instrumentFamilies,
      instrumentSections,
      createSessionSection,
      deleteSessionSection,
      getStudioSessionSections,
      getStudioSessionEvents,
      sectionRoles,
      eventStatuses,
      studioSessionAutofill,
      createEvent,
      updateEvent,
      studios,
      createSessionFamilie,
      notify,
      notifySessionMusician,
      getStudioSessionStatuses,
      getSessionMusicianStatuses,
      studioSessionStatuses,
      studioSessionDashboard,
      createTie,
      updateTie,
      deleteTie,
      app,
      payrolls,
      duplicateSession,
      getProjectLayout,
      getRosterNotes,
      createRosterNote,
      updateRosterNote,
      deleteRosterNote,
      getLayoutPresets,
      createLayoutPreset,
      deleteLayoutPreset,
      configurations,
      getMercuryJob,
      updateMercuryJob,
      getRhapsodyJob,
      createPost,
      user,
    } = this.props;

    const {
      urlParams,
      studioSession,
      studioSessionMusicians,
      studioSessionTunes,
      studioSessionRehearsals,
      studioSessionIdentifications,
      studioSessionEngagements,
      refreshKey,
      loading,
      sessionCategories,
      currentCompany,
      tuto,
    } = this.state;

    const api = {
      getLayoutPresets,
      createLayoutPreset,
      deleteLayoutPreset,
      updateStudioSession,
      updateProject,
      getProjectLayout,
      calculatePensionWelfare,
      updateStudioSessionMusician,
      deleteStudioSessionMusician,
      createStudioSessionMusician,
      getInstrumentSectionMusicians,
      getStudioSessionMusicianById,
      createMusicianInstrument,
      createMusician,
      deleteMusicianInstrument,
      getMusicianById,
      getLayoutPresetById,
      updateMusician,
      getStudioSessionMercury,
      studioSessionAutofill,
      deleteMusician,
      createTie,
      updateTie,
      deleteTie,
      updateTune,
      createTune,
      deleteTune,
      updateRehearsal,
      createRehearsal,
      deleteRehearsal,
      updateIdentification,
      createIdentification,
      deleteIdentification,
      updateEngagement,
      createEngagement,
      deleteEngagement,
      createStudioSessionCategory,
      deleteStudioSessionCategory,
      createSessionSection,
      deleteSessionSection,
      getStudioSessionSections,
      getStudioSessionEvents,
      createEvent,
      updateEvent,
      createSessionFamilie,
      notify,
      notifySessionMusician,
      getStudioSessionStatuses,
      getSessionMusicianStatuses,
      studioSessionStatuses,
      studioSessionDashboard,
      getLinks,
      createLink,
      updateLink,
      deleteLink,
      duplicateSession,
      getRosterNotes,
      createRosterNote,
      updateRosterNote,
      deleteRosterNote,
      getMercuryJob,
      updateMercuryJob,
      getRhapsodyJob,
      createPost,
      refresh: this.refresh.bind(this),
    };

    let conciergeReadOnly =
      studioSession?.concierge === true &&
      !(
        user.services.account.indexOf("superadmin") !== -1 ||
        user.services.rhapsody.indexOf("concierge") !== -1
      );

    return (
      <MercuryNotify
        studioSession={studioSession}
        refresh={this.refresh.bind(this)}
      >
        <Notify
          studios={studios}
          getStudioSessionStatuses={getStudioSessionStatuses}
          getSessionMusicianStatuses={getSessionMusicianStatuses}
          payrolls={payrolls}
          configurations={configurations}
          studioSession={studioSession}
          studioSessionStatuses={studioSessionStatuses}
          studioSessionEngagements={studioSessionEngagements}
          currentCompany={currentCompany}
          notify={notify}
          notifySessionMusician={notifySessionMusician}
          api={api}
          refresh={this.refresh.bind(this)}
        >
          <WrapperStudioSessionPage
            history={history}
            location={location}
            tuto={tuto}
            layoutPresets={layoutPresets}
            urlParams={urlParams}
            studioSession={studioSession}
            currentCompany={currentCompany}
            studioSessionMusicians={studioSessionMusicians}
            studioSessionTunes={studioSessionTunes}
            studios={studios}
            instruments={instruments}
            sessionTypes={sessionTypes}
            sessionCategories={sessionCategories}
            projects={projects}
            instrumentFamilies={instrumentFamilies}
            instrumentSections={instrumentSections}
            sectionRoles={sectionRoles}
            studioSessionEngagements={studioSessionEngagements}
            eventStatuses={eventStatuses}
            studioSessionRehearsals={studioSessionRehearsals || []}
            studioSessionIdentifications={studioSessionIdentifications || []}
            studioSessionStatuses={studioSessionStatuses}
            studioSessionDashboard={studioSessionDashboard}
            refresh={this.refresh.bind(this)}
            hiringRefresh={this.hiringRefresh.bind(this)}
            refreshKey={refreshKey}
            loading={loading}
            payrolls={payrolls}
            api={api}
            user={user}
            app={app}
            conciergeReadOnly={conciergeReadOnly}
          />
        </Notify>
      </MercuryNotify>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudioSessionPage);
