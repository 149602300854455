import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Eye from "@material-ui/icons/RemoveRedEyeTwoTone";
// material-ui
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class Event extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    event: PropTypes.object,
  };

  render() {
    const { classes, event } = this.props;

    return (
      <Paper
        style={{
          padding: 10,
          borderLeft: `solid 2px ${event.status.color}`,
        }}
        elevation={0}
      >
        <Grid container justify="space-between">
          <Grid item style={{ width: "calc(100% - 35px)" }}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Avatar style={{ fontSize: "1.1rem", height: 34, width: 34 }}>
                  {event.creatorName && event.creatorName.split(" ")[0][0]}
                  {event.creatorName && event.creatorName.split(" ")[1][0]}
                </Avatar>
              </Grid>
              <Grid item>
                <Typography variant="body2">{event.creatorName}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment(event.creationDate).fromNow()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <img src={event.status.icon} alt="event" style={{ width: 35 }} />
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Typography variant="body2">
              <b>
                {event.musician
                  ? event.musician.nickName ||
                    `${event.musician.firstName} ${event.musician.lastName}`
                  : ""}
              </b>{" "}
              <Chip
                label={event.status.name}
                className={classes.chip}
                style={{
                  color: "white",
                  background: event.readReceipt
                    ? event.status.colorRead
                    : event.status.color,
                }}
              />
            </Typography>
            <Typography variant="body2">{event.greeting}</Typography>
          </Grid>
          {event.notes ? (
            <Grid item xs={12}>
              <div
                style={{
                  background: "rgba(255,235,59, 0.1)",
                  border: "solid 1px rgba(251,192,45,0.2)",
                  marginTop: 10,
                  padding: 2,
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  {event.notes}
                </Typography>
              </div>
            </Grid>
          ) : (
            []
          )}
          {event.readReceipt ? (
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Divider />
              <Grid container alignItems="center">
                <Grid item>
                  <Eye style={{ width: 15, marginRight: 4 }} color="disabled" />
                </Grid>
                <Grid item>
                  <Typography variant="caption" color="textSecondary">
                    Seen by{" "}
                    {event.musician
                      ? event.musician.nickName ||
                        `${event.musician.firstName} ${event.musician.lastName}`
                      : ""}{" "}
                    {moment(event.readDate).fromNow()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            []
          )}
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Event);
