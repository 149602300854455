export default (theme) => ({
  widget: {
    background: theme.palette.background.default,
    borderRadius: 4,
    border: "solid 1px rgba(155, 155, 155, 0.3)",
    padding: 10,
    height: "calc(100% - 20px)",
    position: "relative",
  },
  paper: {
    background: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: "0px 0px 20px 1px rgba(0,0,0,0.15)",
    padding: 8,
    marginBottom: 20,
  },
  circularContainer: {
    position: "relative",
    paddingTop: 10,
    height: 75,
    width: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  background: {
    color: "rgba(155,155,155,0.3)",
    position: "absolute",
    left: 0,
  },
  foreground: {
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
});
