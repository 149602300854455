import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const titlePatterns = `${REACT_APP_API_PREFIX}/${service}/titlePatterns`;

export function getTitlePatterns(filters) {
  const url = `${titlePatterns}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getTitlePatternById(id) {
  const url = `${titlePatterns}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTitlePattern(id, body) {
  const url = `${titlePatterns}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTitlePattern(id) {
  const url = `${titlePatterns}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTitlePattern(body) {
  const url = `${titlePatterns}`;
  return {
    method: POST,
    url,
    body,
  };
}
