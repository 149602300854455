export default {
  root: {
    strokeWidth: ".5px",
    stroke: "#9e9e9e",
    opacity: 0.5,
    cursor: "pointer",
    fill: "transparent",
    transition: "opacity 0.2s, stroke 0.2s, strokeWidth 0.2s",
    WebkitTransition: "opacity 0.2s, stroke 0.2s, strokeWidth 0.2s",
  },
};
