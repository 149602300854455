import { networkAction } from "helpers/network/networkAction";

import {
  GET_COMPANIES,
  GET_CURRENT_COMPANY,
  UPDATE_CURRENT_COMPANY,
  GET_COMPANY_BY_ID,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  CREATE_COMPANY,
  UPLOAD_SIGNATURE,
  UPLOAD_LOGO,
} from "constants/companies";

import * as Api from "api";

export const getCompanies = () => async (dispatch) =>
  networkAction(dispatch, GET_COMPANIES, Api.getCompanies, []);

export const uploadSignature = (signatureImage) => async (dispatch) =>
  networkAction(dispatch, UPLOAD_SIGNATURE, Api.uploadSignature, [
    signatureImage,
  ]);

export const uploadLogo = (logoImage) => async (dispatch) =>
  networkAction(dispatch, UPLOAD_LOGO, Api.uploadLogo, [logoImage]);

export const getCurrentCompany = () => async (dispatch) =>
  networkAction(dispatch, GET_CURRENT_COMPANY, Api.getCurrentCompany, []);

export const updateCurrentCompany = (body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CURRENT_COMPANY, Api.updateCurrentCompany, [
    body,
  ]);

export const getCompanyById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_COMPANY_BY_ID, Api.getCompanyById, [id]);

export const deleteCompany = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COMPANY, Api.deleteCompany, [id]);

export const createCompany = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_COMPANY, Api.createCompany, [body]);

export const updateCompany = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COMPANY, Api.updateCompany, [id, body]);
