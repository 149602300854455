export default () => ({
  container: {
    maxWidth: 1200,
    margin: "auto",
  },
  signatureBlock: {
    background: "#f5f5f5",
    border: "solid 1px #979797",
    borderRadius: "8px",
  },
  image: {
    opacity: 0.5,
  },
  grid: {
    paddingTop: 10,
  },
});
