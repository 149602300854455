// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Tour from "reactour";
// styles
import styles from "./styles";

const data = {
  0: {
    steps: [
      {
        selector: '[data-tut="reactour__addFamily"]',
        content: "Click on this button to add positions to your work session.",
      },
      {
        selector: '[data-tut="reactour__selectFamily"]',
        content: "Choose what you need for your session",
      },
      {
        selector: '[data-tut="reactour__musician"]',
        content:
          "Congrats! you have added your first positions! Now it is time to assign a musician to it. Just click on this box.",
      },
      {
        selector: '[data-tut="reactour__musicianList"]',
        content:
          "Choose a musician from your address book. If you don't have any, that's ok. You just have to create one.",
      },
      {
        selector: '[data-tut="reactour__notify"]',
        content:
          "Repeat these steps until your roster is completed. When you are finished, click on this button to invite the musicians.",
      },
    ],
    config: {
      showNavigation: false,
      showButtons: false,
    },
  },
  1: {
    steps: [
      {
        content:
          "Welcome to Rhapsody! Before we get started, let's take a look around.",
        // selector: '[data-tut="reactour__newSession"]',
      },
      {
        content: "View all your Work Sessions in this menu. A Work Session is ",
        selector: '[data-tut="reactour__WorkSessions"]',
      },
      {
        content: "Organize your Work Session within a Project",
        selector: '[data-tut="reactour__Projects"]',
      },
      {
        content: "Link a Customer to a project. ",
        selector: '[data-tut="reactour__Customers"]',
      },
      {
        content: "Input the Studio/Venues for your Work Sessions.",
        selector: '[data-tut="reactour__Studio"]',
      },
      {
        content:
          "Input your prefered payroll company's information. This will be included in the final report form.",
        selector: '[data-tut="reactour__Payrolls"]',
      },
      {
        content:
          "Build your musician address book here or import a list from an existing file of your choice.",
        selector: '[data-tut="reactour__Musicians"]',
      },
      {
        content:
          "Any issues? Start a chat with our team. We will be happy to help.",
        selector: '[data-tut="reactour__crisp"]',
      },
      {
        content: "Whenever you are ready, create your first Work Session here.",
        selector: '[data-tut="reactour__newSession"]',
      },
    ],
    config: {
      showNavigation: true,
      showButtons: true,
    },
  },
  2: {
    steps: [
      {
        selector: '[data-tut="reactour__create"]',
        content: "Whenever you want to create something, click on this button.",
      },
    ],
  },
  3: {
    steps: [
      {
        selector: '[data-tut="reactour__musicians"]',
        content:
          "Pssst! Speed up the data entry process by importing all your musicians using a VCF file or from your Google account!",
      },
    ],
  },
};

class TourCenter extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
  };

  static childContextTypes = {
    TourCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      target: 0,
      isTourOpen: false,
      touring: false,
      currentStep: 0,
    };
    this.ref = React.createRef();
  }

  getChildContext() {
    return {
      TourCenter: {
        start: this.start.bind(this),
        stop: this.stop.bind(this),
        gotoStep: this.gotoStep.bind(this),
        hide: this.hide.bind(this),
        show: this.show.bind(this),
        next: this.next.bind(this),
      },
    };
  }

  next() {
    const { touring } = this.state;
    if (touring) {
      this.show();
      setTimeout(this.ref.current.nextStep, 500);
    }
  }

  stop() {
    this.setState({ touring: false });
    this.hide();
  }

  start(target) {
    this.setState({ target, touring: true }, () => {
      this.ref.current.gotoStep(0);
      this.show();
    });
  }

  gotoStep(step) {
    const { touring } = this.state;
    if (touring) {
      this.ref.current.gotoStep(step);
    }
  }

  hide() {
    this.setState({ isTourOpen: false });
  }

  show() {
    const { touring } = this.state;
    if (touring) {
      this.setState({ isTourOpen: true });
    }
  }

  render() {
    const { children } = this.props;
    const { target, isTourOpen } = this.state;

    return (
      <React.Fragment>
        {children}
        <Tour
          ref={this.ref}
          steps={data[target].steps}
          accentColor={"#ffc107"}
          isOpen={isTourOpen}
          onRequestClose={() => {
            this.hide();
            this.stop();
          }}
          {...data[target].config}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TourCenter);
