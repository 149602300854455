import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Hidden,
  Icon,
  InputBase,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as Actions from "actions";
// material-ui
import { REACT_APP_API } from "config";
import React from "react";
import { useDispatch } from "react-redux";
import { Bar, Container, Section } from "react-simple-resizer";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function PayrollFormBuilder({ children, id, refresh, token }) {
  const classes = useStyles(styles);
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [preview, setPreview] = React.useState("");
  const [continuation, setContinuation] = React.useState([]);
  const [file, setFile] = React.useState("");
  const [key, setKey] = React.useState(false);
  const [formData, setFormData] = React.useState();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();

  React.useEffect(() => {
    getAgnosticFormData();
  }, []);

  React.useEffect(() => {
    if (preview && preview.url) {
      dispatch(Actions.getAgnosticFormData(id)).then((r) => {
        let body = JSON.parse(JSON.stringify(r.payload));
        console.log(preview);
        // debugger;
        if (preview.continuation) {
          body.map = preview.map;
          body.mapLists = preview.mapLists;
          body.tabMap = preview.tabMap;
          body.url = preview.url;

          // body.obj = {};
          // body.tabMap = body.conTabMap;
          // body = keepOnlyContinuationIdRanges(body, true);

          getFile(body);
        } else {
          // body = keepOnlyContinuationIdRanges(body, false);
          body.url = preview.url;
          getFile(body);
        }
      });
    }
  }, [preview]);

  function keepOnlyContinuationIdRanges(body, condition) {
    const musicianMapListIndex = body.mapLists.findIndex(
      (e) => e.name === "musicians"
    );
    if (musicianMapListIndex !== -1) {
      let _idrange = [];
      for (const key in body.mapLists[musicianMapListIndex].idrange) {
        if (
          Object.hasOwnProperty.call(
            body.mapLists[musicianMapListIndex].idrange,
            key
          )
        ) {
          const idRange = body.mapLists[musicianMapListIndex].idrange[key];
          if (idRange.continuation === condition) {
            _idrange.push(idRange);
          }
        }
      }
      body.mapLists[musicianMapListIndex] = {
        ...body.mapLists[musicianMapListIndex],
        idrange: _idrange,
      };
    }

    return body;
  }

  function r() {
    setKey(new Date().getTime());
    refresh();
  }

  function getAgnosticFormData() {
    dispatch(Actions.getAgnosticFormData(id)).then((r) => {
      if (r.success) {
        setFormData(r.payload);
        setUrl(r.payload.url);
      }
    });
  }

  async function getFile(body) {
    fetch(`${REACT_APP_API}/afm42/form`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.blob())
      .then((data) => {
        setFile(window.URL.createObjectURL(data));
        setKey(new Date().getTime());
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const child = React.cloneElement(children, { refresh: r });

  const handleContinuationEdit = (i, type) => (e) => {
    const _continuation = JSON.parse(JSON.stringify(continuation));
    _continuation[i][type] = e.target.value;
    setContinuation(_continuation);
  };

  const handleDeleteContinuation = (i) => () => {
    const _continuation = JSON.parse(JSON.stringify(continuation));
    _continuation.splice(i, 1);
    setContinuation(_continuation);
  };

  return (
    <>
      <Container
        style={{
          overflowY: "scroll",
          position: "relative",
          display: "flex",
          flex: 1,
        }}
      >
        <Section
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
          key={1}
        >
          {file ? (
            <iFrame
              style={{ flex: 1, height: "100%", width: "100%", border: "none" }}
              src={file}
            />
          ) : (
            <iFrame
              style={{ flex: 1, height: "100%", width: "100%", border: "none" }}
              src={`${REACT_APP_API}/rhapsody/workSessions/${id}/preview?key=${key}`}
            />
          )}
        </Section>
        {sm ? (
          <>
            <Bar expandInteractiveArea size={8}></Bar>
            <Section
              defaultSize={500}
              style={{
                overflowX: "hidden",
                marginRight: 16,
                padding: "20px 2px 0px 20px",
                height: "calc(100% - 20px)",
                overflowY: "scroll",
                borderLeft: "solid 1px rgba(155,155,155,0.3)",
                position: "relative",
              }}
            >
              <Paper
                className={classes.paper}
                style={{
                  marginBottom: 16,
                  padding: 20,
                }}
              >
                <Typography variant="h6">PDF Autofill</Typography>
                <Typography variant="caption" color="textSecondary">
                  Fill out an interactive PDF with the session's data.
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InputBase
                    fullWidth
                    className={classes.inputBase}
                    value={url}
                    placeholder="URL of the PDF form"
                    onChange={(e) => setUrl(e.target.value)}
                  />
                  <Button
                    style={{
                      background: "#ffc107",
                      color: "white",
                      marginLeft: 20,
                    }}
                    size="small"
                    onClick={() => {
                      setPreview({ url: url, continuation: false });
                    }}
                  >
                    Preview
                  </Button>
                </div>
                {formData?.continuationForms?.map((c, i) => (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 8,
                      alignItems: "center",
                    }}
                  >
                    <InputBase
                      fullWidth
                      className={classes.inputBase}
                      defaultValue={c.url}
                      placeholder="URL of the PDF continuation form"
                      onChange={handleContinuationEdit(i, "url")}
                      style={{ flex: 4 }}
                    />
                    <Button
                      style={{
                        background: "#ffc107",
                        color: "white",
                        marginLeft: 20,
                      }}
                      size="small"
                      onClick={() => {
                        setPreview({ ...c, continuation: true });
                      }}
                    >
                      Preview
                    </Button>
                  </div>
                ))}
              </Paper>
              {child}
            </Section>
          </>
        ) : (
          []
        )}
      </Container>
      <Hidden mdUp>
        <Fab
          color="secondary"
          onClick={() => setOpen((p) => !p)}
          style={{ position: "fixed", bottom: 20, right: 20, zIndex: 999 }}
        >
          <Icon className="fad fa-cog" />
        </Fab>
        <Dialog open={open} fullScreen>
          <DialogContent>{child}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen((p) => !p)}
              fullWidth
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Hidden>
    </>
  );
}
