import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class Tune extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    tune: PropTypes.object,
    updateTune: PropTypes.func,
    deleteTune: PropTypes.func,
    refresh: PropTypes.func,
    disabled: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { tune } = this.props;
    this.state = {
      ...tune,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name) {
    const { updateTune, refresh, tune } = this.props;
    if (tune[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateTune(tune.id, {
      [name]: this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const { classes, deleteTune, refresh, tune, disabled } = this.props;
    const { alphaKey, trackLength, title, loading } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("alphaKey");
            }}
            disabled={loading || disabled}
            onBlur={() => this.save("alphaKey")}
            className={disabled ? undefined : classes.inputBase}
            fullWidth
            defaultValue={alphaKey}
            onChange={this.handleChange("alphaKey")}
          />
        </Grid>
        <Grid item xs={3}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("trackLength");
            }}
            disabled={loading || disabled}
            fullWidth
            onBlur={() => this.save("trackLength")}
            className={disabled ? undefined : classes.inputBase}
            defaultValue={trackLength}
            onChange={this.handleChange("trackLength")}
          />
        </Grid>
        <Grid item xs={7}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("title");
            }}
            disabled={loading || disabled}
            onBlur={() => this.save("title")}
            fullWidth
            className={disabled ? undefined : classes.inputBase}
            defaultValue={title}
            onChange={this.handleChange("title")}
          />
        </Grid>
        <Grid item xs={1}>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <Tooltip title="Delete Tune">
              <IconButton
                disabled={disabled}
                aria-label="Delete"
                className={classes.margin}
                onClick={async () => {
                  await deleteTune(tune.id);
                  refresh();
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Tune);
