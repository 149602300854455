export default (theme) => ({
  container: {
    padding: 5,
  },

  leftIcon: {
    marginRight: theme.spacing(1),
  },
  userImage: {
    width: "100%",
    textAlign: "center",
    paddingTop: 20,
  },
  textField: {
    width: "100%",
  },
  progressContainer: {
    textAlign: "center",
    padding: 50,
  },
  dialogActions: {
    justifyContent: "space-between",
  },
});
