import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class TableEdit extends PureComponent {
  static propTypes = {
    onEdit: PropTypes.func,
    datum: PropTypes.object,
  };

  render() {
    const { onEdit, datum } = this.props;

    return (
      <IconButton
        aria-label="Edit"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onEdit(datum);
        }}
      >
        <Edit fontSize="small" />
      </IconButton>
    );
  }
}

export default withStyles(styles)(TableEdit);
