import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperInstrumentPage from "../component/WrapperInstrumentPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  instruments: state.instruments,
  instrumentSections: state.instrumentSections,
});

const mapDispatchToProps = (dispatch) => ({
  getInstruments: () => dispatch(Actions.getInstruments()),
  getInstrumentSections: () => dispatch(Actions.getInstrumentSections()),
  getInstrumentById: (...args) => dispatch(Actions.getInstrumentById(...args)),
  createInstrument: (...args) => dispatch(Actions.createInstrument(...args)),
  deleteInstrument: (...args) => dispatch(Actions.deleteInstrument(...args)),
  updateInstrument: (...args) => dispatch(Actions.updateInstrument(...args)),
});
class InstrumentPage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    instruments: PropTypes.array,
    instrumentSections: PropTypes.array,
    getInstrumentSections: PropTypes.func,
    getInstruments: PropTypes.func,
    getInstrumentById: PropTypes.func,
    createInstrument: PropTypes.func,
    deleteInstrument: PropTypes.func,
    updateInstrument: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, instruments } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: instruments.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getInstruments, getInstrumentSections } = this.props;
    await Promise.all([getInstruments(), getInstrumentSections()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      instruments,
      getInstrumentById,
      createInstrument,
      deleteInstrument,
      updateInstrument,
      instrumentSections,
      history,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperInstrumentPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        history={history}
        instruments={instruments}
        getInstrumentById={getInstrumentById}
        createInstrument={createInstrument}
        deleteInstrument={deleteInstrument}
        updateInstrument={updateInstrument}
        instrumentSections={instrumentSections}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InstrumentPage);
