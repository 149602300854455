import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { SketchField, Tools } from "react-sketch";
import styles from "./styles";

class Signature extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    clearSignature: PropTypes.func,
    height: PropTypes.number,
    width: PropTypes.number,
    signature: PropTypes.any,
    getSignature: PropTypes.func,
    signatureHasChanged: PropTypes.func,
    onSave: PropTypes.func,
  };

  state = {
    value: null,
    hasChanged: false,
  };

  componentDidMount(...args) {
    const { getSignature } = this.props;
    this.sketch = React.createRef();
    getSignature(this.getSignature.bind(this));
  }

  onSubmit() {
    this.sketch.current.clear();
    this.setState({
      value: null,
    });
  }

  download() {
    const { onSignatureReady } = this.props;
    if (onSignatureReady)
      onSignatureReady(this.sketch.current.toDataURL(), new Date());
    this.sketch.current.clear();
  }

  getSignature() {
    if (this.sketch.current) {
      return this.sketch.current.toDataURL();
    }

    return;
  }

  handleChange(e) {
    const { signatureHasChanged } = this.props;
    signatureHasChanged();
    this.setState({
      hasChanged: true,
      value: e.target.value,
    });
  }

  render() {
    const {
      classes,
      height,
      width,
      signature,
      clearSignature,
      onSave,
    } = this.props;

    const { value } = this.state;

    return (
      <div style={{ width, height: height + 50 }}>
        {signature ? (
          <img
            alt="signatureImage"
            src={signature}
            className={classes.signatureBlock}
            style={{
              height,
              width,
            }}
          />
        ) : (
          <div
            className={classes.signatureBlock}
            style={{
              height,
              width,
            }}
          >
            <SketchField
              ref={this.sketch}
              width={width}
              height={height}
              tool={Tools.Pencil}
              lineColor="black"
              lineWidth={3}
              onChange={this.handleChange.bind(this)}
              value={value}
              imageFormat="png"
            />
          </div>
        )}
        <Grid container justify="flex-end" spacing={1} className={classes.grid}>
          <Grid item>
            <Chip
              label="Clear"
              size="small"
              variant="outlined"
              onClick={() => {
                if (signature) {
                  clearSignature();
                } else {
                  this.onSubmit();
                }
              }}
            />
          </Grid>
          <Grid item>
            <Chip
              size="small"
              label="Save Signature"
              color="primary"
              onClick={onSave}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Signature);
