export default (theme) => ({
  container: {
    padding: 5,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    border: "solid 1px rgba(155,155,155,0.3)",
    padding: 8,
    textAlign: "center",
    marginTop: -1,
    display: "flex",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 4,
  },
  focused: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px #ffa000",
    borderRadius: 4,
  },
  rowItem: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    margin: 4,
  },
  align: {
    justifyContent: "center",
  },
  chip: {
    background: "#e0e0e0",
    borderRadius: 16,
    padding: "6px 8px 6px 8px",
    fontWeight: 400,
    fontSize: "0.875rem",
  },
});
