import { Grid, Grow, Paper, Typography, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
// components
import Page from "components/Page";
import { getGreetingTime } from "helpers";
import Post from "hooks/Post";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperJournalPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    posts: PropTypes.array,
    user: PropTypes.object,
    updatePost: PropTypes.func,
    refresh: PropTypes.func,
    width: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const { history, user, posts, loading, updatePost, refresh } = this.props;

    const { old } = this.state;

    let postsToDisplay = [];

    if (old) {
      postsToDisplay = posts.filter((p) => p.discarded);
    } else {
      postsToDisplay = posts.filter((p) => !p.discarded);
    }

    postsToDisplay = postsToDisplay.sort(
      (a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()
    );

    return (
      <Page helmet="Journal">
        <div>
          <Grid container justify="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                style={{ fontWeight: 800 }}
                display="block"
                color="textPrimary"
              >
                {getGreetingTime(moment())}{" "}
                <span
                  style={{
                    background:
                      "-webkit-linear-gradient(45deg, #ffc107, #ff6f00)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {user.firstName}
                </span>
              </Typography>
              {!loading && postsToDisplay.length === 0 ? (
                <Grow in>
                  <Paper
                    style={{
                      overflow: "hidden",
                      position: "relative",
                      maxWidth: 600,
                      margin: "auto",
                      padding: 20,
                      marginTop: 20,

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "solid 1px rgba(155,155,155,0.3)",
                    }}
                    elevation={0}
                  >
                    <div style={{ flex: 2 }}>
                      <i
                        style={{ fontSize: 70 }}
                        class="fad fa-badge-check"
                      ></i>
                      <Typography color="textSecondary" variant="h6">
                        All caught up!
                      </Typography>
                    </div>
                    <div style={{ textAlign: "left", flex: 3 }}>
                      <Typography color="textSecondary" variant="body2">
                        Your journal is empty.
                        <br />
                        You will see a post when a musician has:
                      </Typography>
                      <Typography color="textSecondary" variant="caption">
                        <ul>
                          <li>
                            <i class="fad fa-ban"></i> declined
                          </li>
                          <li>
                            <i class="fad fa-hand-paper"></i> hold
                          </li>
                          <li>
                            <i class="fad fa-comment-dots"></i> left a message
                          </li>
                        </ul>
                      </Typography>
                    </div>
                  </Paper>
                </Grow>
              ) : (
                []
              )}
            </Grid>
            {loading ? (
              <>
                <Grid item xs={12} style={{ paddingBottom: 32 }}>
                  <div style={{ maxWidth: 600, margin: "auto" }}>
                    <Post loading />
                  </div>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: 32 }}>
                  <div style={{ maxWidth: 600, margin: "auto" }}>
                    <Post loading />
                  </div>
                </Grid>
              </>
            ) : (
              []
            )}
            {/* {!loading && pinnedPosts.length ? (
              <Grid item xs={12} style={{ padding: "20px 0px 40px 0px" }}>
                <Carousel
                  slidesToShow={slidesToShow}
                  frameOverflow
                  cellSpacing={20}
                  enableKeyboardControls
                  renderBottomCenterControls={({ previousSlide }) => <div />}
                  renderCenterLeftControls={({ previousSlide }) =>
                    pinnedPosts.length > 3 ? (
                      <IconButton
                        color="secondary"
                        onClick={previousSlide}
                        style={{ marginLeft: -20 }}
                      >
                        <Icon class="fad fa-arrow-circle-left" />
                      </IconButton>
                    ) : (
                      <div />
                    )
                  }
                  renderCenterRightControls={({ nextSlide }) =>
                    pinnedPosts.length > 3 ? (
                      <IconButton
                        color="secondary"
                        onClick={nextSlide}
                        style={{ marginRight: -20 }}
                      >
                        <Icon class="fad fa-arrow-circle-right" />
                      </IconButton>
                    ) : (
                      []
                    )
                  }
                >
                  {pinnedPosts.map((p) => (
                    <Post
                      {...p}
                      history={history}
                      updatePost={updatePost}
                      refresh={refresh}
                    />
                  ))}
                  <PinPlaceholder />
                </Carousel>
              </Grid>
            ) : (
              []
            )} */}
            {!loading &&
              postsToDisplay
                .filter((p) => !p.pinned)
                .map((p) => (
                  <Grid item xs={12} key={p.id}>
                    <div
                      style={{
                        maxWidth: 600,
                        margin: "auto",
                        paddingBottom: 32,
                      }}
                    >
                      <Grow in>
                        <Post
                          {...p}
                          history={history}
                          updatePost={updatePost}
                          refresh={refresh}
                        />
                      </Grow>
                    </div>
                  </Grid>
                ))}
            {/* <Grid item xs={12} style={{ textAlign: "center" }}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={old}
                    onChange={(e) => this.setState({ old: e.target.checked })}
                  />
                }
                label={
                  <Typography variant="caption" color="textSecondary">
                    Show old posts
                  </Typography>
                }
              />
            </Grid> */}
          </Grid>
        </div>
      </Page>
    );
  }
}
export default withWidth()(withStyles(styles)(WrapperJournalPage));
