import { networkAction } from "helpers/network/networkAction";

import {
  GET_SESSION_FAMILIES,
  GET_SESSION_FAMILIE_BY_ID,
  DELETE_SESSION_FAMILIE,
  UPDATE_SESSION_FAMILIE,
  CREATE_SESSION_FAMILIE,
} from "constants/sessionFamilies";

import * as Api from "api";

export const getSessionFamilies = () => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_FAMILIES, Api.getSessionFamilies, []);

export const getSessionFamilieById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_SESSION_FAMILIE_BY_ID,
    Api.getSessionFamilieById,
    [id]
  );

export const deleteSessionFamilie = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SESSION_FAMILIE, Api.deleteSessionFamilie, [
    id,
  ]);

export const createSessionFamilie = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SESSION_FAMILIE, Api.createSessionFamilie, [
    body,
  ]);

export const updateSessionFamilie = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SESSION_FAMILIE, Api.updateSessionFamilie, [
    id,
    body,
  ]);
