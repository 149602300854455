import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const musicianGroups = `${REACT_APP_API_PREFIX}/${service}/musicianGroups`;

export function getMusicianGroups(filters) {
  const url = `${musicianGroups}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getMusicianGroupById(id) {
  const url = `${musicianGroups}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateMusicianGroup(id, body) {
  const url = `${musicianGroups}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMusicianGroup(id) {
  const url = `${musicianGroups}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMusicianGroup(body) {
  const url = `${musicianGroups}`;
  return {
    method: POST,
    url,
    body,
  };
}
