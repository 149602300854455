import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import { MusicianSuggestion } from "components";
import { formatDate } from "helpers";
import MusicianAvatar from "hooks/MusicianAvatar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ReplaceMusician({ item, projectID, refresh }) {
  const [requestedMusician, setRequestedMusician] = React.useState(null);
  const [popperAnchor, setPopperAnchor] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [sectionMusicians, setSectionMusicians] = React.useState([]);
  const [hasDeclinedWorkSessions, setHasDeclinedWorkSessions] =
    React.useState(false);
  const [declinedWorkSessions, setDeclinedWorkSessions] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [selectedWorkSessions, setSelectedWorkSessions] = React.useState([]);
  const sectionRoles = useSelector((state) => state.sectionRoles);
  const stages = useSelector((state) => state.stages);
  const dispatch = useDispatch();
  const classes = useStyles(styles);

  const { musician } = item;

  React.useEffect(() => {
    const _declinedWs = [];
    const _selectedWs = [];
    setHasDeclinedWorkSessions(false);
    for (const key in item.sessions) {
      if (Object.hasOwnProperty.call(item.sessions, key)) {
        const session = item.sessions[key];
        const _stage =
          stages.find((s) => s.id === session.currentJobStage?.stageID) ?? {};
        session.currentStage = { ...session.currentStage, ..._stage };

        if (session.currentStage.id === 3 || session.currentStage.id === 9 || session.currentStage.id === 20) {
          setHasDeclinedWorkSessions(true);
          _declinedWs.push(session);
          _selectedWs.push(session.sessionID);
        }
      }
    }
    setSelectedWorkSessions(_selectedWs);
    setDeclinedWorkSessions(_declinedWs);
  }, [item]);

  async function getSectionMusicians(e) {
    setLoading(true);
    setPopperAnchor(e.currentTarget);
    const firstDeclinedWorkSession = declinedWorkSessions[0];
    const r = await dispatch(
      Actions.getInstrumentSectionMusicians(
        firstDeclinedWorkSession.sectionID,
        firstDeclinedWorkSession.sessionID,
        firstDeclinedWorkSession.sectionRoleID
      )
    );
    if (r.success) {
      setSectionMusicians(r.payload);
      setLoading(false);
    }
  }

  function reset() {
    setRequestedMusician(null);
    setPopperAnchor(null);
    setDialogOpen(false);
    setSectionMusicians([]);
    setLoading(true);
  }

  function confirm() {
    const body = {
      sessionIDs: selectedWorkSessions,
      musicianIDs: [musician.id],
      requestedMusicianID: requestedMusician.id,
    };

    dispatch(Actions.updateProjectHiring(projectID, body)).then(refresh);
    reset();
  }

  if (!hasDeclinedWorkSessions) return [];

  return (
    <div style={{ paddingLeft: 16 }}>
      <Tooltip title="Replace Musician">
        <IconButton
          size="small"
          style={{ background: "#ffcdd2" }}
          onClick={(e) => getSectionMusicians(e)}
        >
          <i
            class="fas fa-people-arrows"
            style={{
              fontSize: 14,
              color: "#f44336",
              padding: 4,
            }}
          ></i>
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(popperAnchor)}
        disableEnforceFocus
        disableRestoreFocus
        onClose={() => setPopperAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={popperAnchor}
      >
        <MusicianSuggestion
          sectionRoles={sectionRoles}
          close={() => setPopperAnchor(null)}
          search
          loading={loading}
          showPrime
          sortAlphabetically
          linkMusician={(e) => {
            setRequestedMusician(e);
            setDialogOpen(true);
            setPopperAnchor(null);
          }}
          label={"Seating"}
          suggestions={sectionMusicians}
        />
      </Popover>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {requestedMusician ? (
          <DialogContent>
            <Typography variant="h6">You are about to replace:</Typography>
            <br />
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{
                border: "solid 1px rgba(155,155,155,0.3)",
                borderRadius: 4,
                padding: 8,
              }}
            >
              <Grid item style={{ textAlign: "center" }}>
                <MusicianAvatar size={60} musician={musician} />
                <br />
                <Typography>
                  {musician.nickName ||
                    `${musician.firstName} ${musician.lastName}`}
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: "center", padding: 16 }}>
                <Typography variant="button">
                  <b>BY</b>
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                <MusicianAvatar size={60} musician={requestedMusician} />
                <br />
                <Typography>
                  {requestedMusician.nickName ||
                    `${requestedMusician.firstName} ${requestedMusician.lastName}`}
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Typography variant="subtitle2">
              On the sessions{" "}
              {musician.nickName ||
                `${musician.firstName} ${musician.lastName}`}{" "}
              declined:
            </Typography>
            <br />
            <List
              style={{
                border: "solid 1px rgba(155,155,155,0.3)",
                borderRadius: 4,
              }}
            >
              {declinedWorkSessions.map((s, i) => {
                return (
                  <ListItem
                    divider={Number(i) !== declinedWorkSessions.length - 1}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          selectedWorkSessions.indexOf(s.sessionID) !== -1
                        }
                        onChange={(e) => {
                          if (!e.target.checked) {
                            const _sws = [...selectedWorkSessions];
                            _sws.splice(_sws.indexOf(s.sessionID), 1);
                            setSelectedWorkSessions(_sws);
                          } else {
                            setSelectedWorkSessions([
                              ...selectedWorkSessions,
                              s.sessionID,
                            ]);
                          }
                        }}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography noWrap>{s.fullSession.title}</Typography>
                      <Typography
                        variant="caption"
                        noWrap
                        color="textSecondary"
                      >
                        {formatDate(
                          s.fullSession.dateFromUTC,
                          s.fullSession.tzName
                        )}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
        ) : (
          []
        )}
        <DialogActions>
          <Button onClick={reset} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
