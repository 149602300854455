export const ROOT = "/";
export const SIGNIN = "/signin";
export const DEMO = "/demo";
export const DEFAULT = "/default";
export const MY_ACCOUNT = "/my-account";
export const USERS = "/users";
export const GROUP = "/groups";
export const ROLE = "/roles";
export const RULE = "/rules";
export const PATH = "/paths";
export const DEBUG = "/debug";
export const MARKDOWN = "/markdown";
export const DOCS = "/docs";
export const PROJECT_TYPE = "/global-settings";
export const CONTENT = "/content";
export const INSTRUMENT = "/instruments";
export const CUSTOMER = "/customers";
export const PROJECT = "/projects";
export const STUDIO_SESSION = "/workSessions";
export const MUSICIAN = "/musicians";
export const STUDIO_SESSION_DETAIL = "/workSession";
export const SESSION_TYPE = "/sessionTypes";
export const PROJECT_DETAIL = "/project";
export const CUSTOMER_DETAIL = "/customer";
export const MUSICIAN_DETAIL = "/musician";
export const STATEMENT = "/statement";
export const INSTRUMENT_FAMILIES = "/instrumentFamilies";
export const INSTRUMENT_SECTIONS = "/instrumentSections";
export const PAYROLLS = "/payrolls";
export const PAYROLL = "/payroll";
export const INSTRUMENT_CONFIGURATOR = "/instrument-configurator";
export const EVENT_STATUSES = "/eventStatuses";
export const STUDIOS = "/studios";
export const STUDIO = "/studio";
export const ACTIONS = "/actions";
export const S = "/s";
export const EMAILS = "/emails";
export const EMAIL = "/email";
export const PRICINGS = "/pricings";
export const CORE_CONCEPT = "/core-concept";
export const SLIDES = "/slides";
export const JOURNAL = "/journal";
export const POST_BUILDER = "/postBuilder";
export const PIPELINE = "/pipeline";
export const QUOTES = "/quotes";
export const TEMPLATES = "/templates";
export const TEMPLATE = "/template";
