import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function RestrictOrganization() {
  const user = useSelector((s) => s.user);
  const currentCompany = useSelector((s) => s.currentCompany);

  useEffect(() => {
    if (user?.id && currentCompany?.id) {
      const allowedCompanies = [5, 68];
      const isSuperAdmin = user?.services?.account.indexOf("superadmin") >= 0;

      if (
        !isSuperAdmin &&
        allowedCompanies?.indexOf(currentCompany?.id) === -1
      ) {
        window.location.replace("https://ensemble.live.rhapsody.la/");
      }
    }
  }, [user, currentCompany]);
  return <div />;
}
