export default (theme) => ({
  container: {
    padding: 5,
  },
  stripe: {
    padding: 15,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 6,
    marginTop: 10,
  },
});
