import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperActionsPage from "../component/WrapperActionsPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  processBazaar: (...args) => dispatch(Actions.processBazaar(...args)),
  responseTest: (...args) => dispatch(Actions.responseTest(...args)),
});
class ActionsPage extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    processBazaar: PropTypes.func,
    responseTest: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      loading: true,
    };
  }

  render() {
    const { location, history, processBazaar, responseTest } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperActionsPage
        history={history}
        location={location}
        urlParams={urlParams}
        loading={loading}
        processBazaar={processBazaar}
        responseTest={responseTest}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActionsPage);
