export default (theme) => ({
  container: {
    padding: 10,
    textAlign: "center",
    cursor: "pointer",
    position: "relative",
    height: "calc(100% - 20px)",
  },
  album: {
    height: 40,
    width: 40,
  },
});
