import {
  Backdrop,
  BottomNavigation,
  BottomNavigationAction,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  ListItemText,
  Menu,
  withMobileDialog,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import * as Actions from "actions";
// component
import NoRender from "components/NoRender";
// config
import { REACT_APP_API } from "config";
import RichText from "hooks/RichText";
import SMSPreview from "hooks/SMSPreview";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  studios: state.studios,
  payrolls: state.payrolls,
  currentCompany: state.currentCompany,
  configurations: state.configurations,
  projectHiring: state.projectHiring,
});

const mapDispatchToProps = (dispatch) => ({
  updateProject: (...args) => dispatch(Actions.updateProject(...args)),
  updateStudioSession: (...args) =>
    dispatch(Actions.updateStudioSession(...args)),
  updateMercuryJob: (...args) => dispatch(Actions.updateMercuryJob(...args)),
  updateProjectMercury: (...args) =>
    dispatch(Actions.updateProjectMercury(...args)),
  getMercuryJob: (...args) => dispatch(Actions.getMercuryJob(...args)),
  getStudioSessionMercury: (...args) =>
    dispatch(Actions.getStudioSessionMercury(...args)),
  getStudios: (...args) => dispatch(Actions.getStudios(...args)),
  getPayrolls: (...args) => dispatch(Actions.getPayrolls(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),
  getConfigurations: (...args) => dispatch(Actions.getConfigurations(...args)),
  getStudioSessionById: (...args) =>
    dispatch(Actions.getStudioSessionById(...args)),
  updateWorkSessionMercury: (...args) =>
    dispatch(Actions.updateWorkSessionMercury(...args)),
  getProjectById: (...args) => dispatch(Actions.getProjectById(...args)),
  getProjectMercury: (...args) => dispatch(Actions.getProjectMercury(...args)),
  updateProjectHiringNotify: (...args) =>
    dispatch(Actions.updateProjectHiringNotify(...args)),
});
// component

class MercuryNotify extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    studios: PropTypes.array,
    payrolls: PropTypes.array,
    studioSession: PropTypes.object,
    project: PropTypes.object,
    configurations: PropTypes.array,
    notifySessionMusician: PropTypes.func,
    notify: PropTypes.func,
    currentCompany: PropTypes.object,
    refresh: PropTypes.func,
    children: PropTypes.node,
    getProjectById: PropTypes.func,
    getStudioSessionMercury: PropTypes.func,
    getProjectMercury: PropTypes.func,
    updateProject: PropTypes.func,
    updateMercuryJob: PropTypes.func,
    updateStudioSession: PropTypes.func,
  };

  static childContextTypes = {
    MercuryNotify: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    SubscriptionCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const {
      studios,
      getStudios,
      payrolls,
      getPayrolls,
      currentCompany,
      getCurrentCompany,
      configurations,
      getConfigurations,
    } = this.props;
    if (!studios) getStudios();
    if (!payrolls) getPayrolls();
    if (!currentCompany) getCurrentCompany();
    if (!configurations) getConfigurations();
    this.iframe = React.createRef();
    this.state = {};
    this.init();
  }

  getChildContext() {
    return {
      MercuryNotify: {
        job: this.job.bind(this),
        workSession: this.workSession.bind(this),
        project: this.project.bind(this),
        nudgeJob: this.nudgeJob.bind(this),
        nudgeWorkSession: this.nudgeWorkSession.bind(this),
        nudgeProject: this.nudgeProject.bind(this),
        close: this.close.bind(this),
      },
    };
  }

  /// GENERAL
  init(callback) {
    //eslint-disable-line
    this.setState(
      {
        agree: false,
        open: false,
        previewLoading: true,
        iframeHeight: 0,
        bottomNavigationStep: 0,
        init: false,
        selectedStage: undefined,
        musician: undefined,
        greeting: "",
        _greeting: "",
        notes: "",
        composer: "",
        leader: "",
        wageAgreement: "",
        name: "",
        studioID: "",
        payrollID: "",
        contractor: "",
        title: "",
        showRoster: false,
        showSessionDetails: true,
        hideEmailLogo: false,
        loading: false,
        preview: [],
        job: undefined,
        workSession: undefined,
        project: undefined,
        nudge: false,
        notify: false,
        jobs: [],
        selectedJob: undefined,
      },
      callback
    );
  }

  notify() {
    const { job, workSession, project } = this.state;
    if (job) {
      this.notifyForJob();
    } else if (workSession) {
      this.notifyForWorkSession();
    } else if (project) {
      this.notifyForProject();
    }
  }

  nudge() {
    const { job, workSession, project } = this.state;
    if (job) {
      this.nudgeForJob();
    } else if (workSession) {
      this.nudgeForWorkSession();
    } else if (project) {
      this.nudgeForProject();
    }
  }

  // Returns the email preview in HTML
  async getHTMLPreview() {
    const {
      _greeting,
      notes,
      showRoster,
      selectedStage,
      showSessionDetails,
      workSession,
      job,
      selectedJob,
      project,
    } = this.state;
    let resp;

    if (job) {
      resp = await fetch(
        `${REACT_APP_API}/rhapsody/jobs/${job.rhapsodyJob.id}/mercury/html`,
        {
          body: JSON.stringify({
            greeting: _greeting,
            notes: notes,
            showRoster,
            showSessionDetails,
            mercuryStageID: selectedStage.id,
          }),
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
          method: "PUT",
        }
      );
    } else if (workSession) {
      resp = await fetch(
        `${REACT_APP_API}/rhapsody/workSessions/${workSession.studioSession.id}/mercury/html`,
        {
          body: JSON.stringify({
            greeting: _greeting,
            notes: notes,
            showRoster,
            showSessionDetails,
            mercuryStageID: selectedStage.id,
            musician: { id: selectedJob.refID },
          }),
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
          method: "PUT",
        }
      );
    } else if (project) {
      resp = await fetch(
        `${REACT_APP_API}/rhapsody/projects/${project.project.id}/mercury/html`,
        {
          body: JSON.stringify({
            greeting: _greeting,
            notes: notes,
            showRoster,
            showSessionDetails,
            mercuryStageID: selectedStage.id,
            musician: { id: selectedJob.refID },
            ...this.getProjectHiringBody(),
          }),
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
          method: "PUT",
        }
      );
    }

    resp.text().then((t) => {
      this.setState({ preview: t });
    });
  }

  initStudioSessionTextField(studioSession, callback) {
    this.setState(
      {
        agreement: studioSession.wageAgreement,
        name: studioSession.projectName,
        title: studioSession.title,
        studioID: studioSession.studio ? studioSession.studio.id : undefined,
      },
      callback
    );
  }

  initProjectTextField(project) {
    this.setState({
      notes: project.emailNotes,
      composer: project.composer,
      hideEmailLogo: project.hideEmailLogo,
      configurationCode: project.configurationCode,
      leader: project.leader,
      contractor: project.contractor,
      payrollID: project.payrollID,
    });
  }

  // Save new values for the project from the right edit panel
  async saveProject(name, numeric) {
    const { updateProject, refresh, studioSession } = this.props;
    const { project } = this.state;

    let projectID;
    if (studioSession) projectID = studioSession.projectID;
    if (project) projectID = project.project.id;

    // if (studioSession.project[name] === this.state[name]) return;

    await updateProject(projectID, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.getHTMLPreview();

    refresh();
  }

  // Save new values for the session from the right edit panel
  async saveSession(name, numeric) {
    const { updateStudioSession, refresh, studioSession } = this.props;

    if (studioSession[name] === this.state[name]) return;

    await updateStudioSession(studioSession.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.getHTMLPreview();

    refresh();
  }

  // Closes the notify panel
  close() {
    this.init();
    this.setState({
      open: false,
      nudgeOpen: false,
    });
  }

  /// JOB INVITE
  async job(rhapsodyJob, mercuryJob, mercuryStage) {
    const { getStudioSessionById } = this.props;
    this.setState({ backdropOpen: true });
    const r = await getStudioSessionById(rhapsodyJob.sessionID);
    const studioSession = r.payload;
    this.init(() =>
      this.initStudioSessionTextField(studioSession, () =>
        this.initProjectTextField(studioSession.project)
      )
    );
    this.setState(
      {
        open: true,
        job: { rhapsodyJob, mercuryJob, mercuryStage },
        selectedStage: mercuryStage,
        _greeting: "",
        agree: false,
        init: true,
        backdropOpen: false,
        notify: true,
        jobs: [rhapsodyJob],
        selectedJob: mercuryJob,
      },
      this.getHTMLPreview.bind(this)
    );
  }

  async notifyForJob() {
    const { updateMercuryJob, refresh } = this.props;

    const {
      _greeting,
      notes,
      showRoster,
      showSessionDetails,
      selectedStage,
      job,
    } = this.state;

    const { NotificationCenter } = this.context;
    this.setState({ loading: true });

    await updateMercuryJob(job.rhapsodyJob.id, {
      mercuryStageID: selectedStage.id,
      greeting: _greeting,
      notes,
      showRoster,
      showSessionDetails,
    });

    this.close();

    NotificationCenter.sweetAlert({
      title: "Email(s) sent",
      success: true,
    });
    setTimeout(NotificationCenter.hide, 1500);
    setTimeout(refresh, 2000);
  }

  nudgeJob(rhapsodyJob, mercuryJob, mercuryStage) {
    this.init();
    this.setState({
      nudgeOpen: true,
      job: { rhapsodyJob, mercuryJob, mercuryStage },
      selectedStage: mercuryStage,
      nudge: true,
      nudgeSMS: rhapsodyJob?.musician?.contactBySMS ?? false,
    });
  }

  async nudgeForJob() {
    const { updateMercuryJob } = this.props;
    const { job, nudgeSMS, project } = this.state;
    this.setState({ nudgeOpen: false });
    const body = {
      mercuryStageID: job.rhapsodyJob.currentStage.id,
      nudge: true,
      notes: project?.project?.emailNotes,
      musician: { contactBySMS: nudgeSMS },
    };
    await updateMercuryJob(job.rhapsodyJob.id, body);
  }

  refreshMercuryJob() {
    const { getMercuryJob } = this.props;
    const { job } = this.state;
    getMercuryJob(job.rhapsodyJob.id).then((r) => {
      const _job = {
        ...job,
        mercuryJob: r.payload,
      };
      this.setState({ _job: _job });
    });
  }

  /// WORK SESSION INVITE
  async workSession(workSessionID) {
    const { getStudioSessionById, getStudioSessionMercury } = this.props;
    this.setState({ backdropOpen: true });
    const r = await Promise.all([
      getStudioSessionById(workSessionID),
      getStudioSessionMercury(workSessionID),
    ]);
    const studioSession = r[0].payload;
    const studioSessionMercury = r[1].payload;
    this.init(() =>
      this.initStudioSessionTextField(studioSession, () =>
        this.initProjectTextField(studioSession.project)
      )
    );
    this.setState({
      open: true,
      workSession: { studioSession, studioSessionMercury },
      upcomingStages: studioSessionMercury.upcomingStages,
      // selectedStage: undefined,
      _greeting: "",
      agree: false,
      init: true,
      notify: true,
      backdropOpen: false,
    });
  }

  async notifyForWorkSession() {
    const { updateWorkSessionMercury, refresh } = this.props;

    const {
      _greeting,
      notes,
      showRoster,
      showSessionDetails,
      selectedStage,
      workSession,
    } = this.state;

    const { NotificationCenter } = this.context;
    this.setState({ loading: true });

    await updateWorkSessionMercury(workSession.studioSession.id, {
      greeting: _greeting,
      notes: notes,
      showRoster,
      showSessionDetails,
      mercuryStageID: selectedStage.id,
    });

    this.close();

    NotificationCenter.sweetAlert({
      title: "Email(s) sent",
      success: true,
    });
    setTimeout(NotificationCenter.hide, 1500);
    setTimeout(refresh, 2000);
  }

  async nudgeWorkSession(workSessionID) {
    const { getStudioSessionById, getStudioSessionMercury } = this.props;
    this.init();
    this.setState({ backdropOpen: true });
    const r = await Promise.all([
      getStudioSessionById(workSessionID),
      getStudioSessionMercury(workSessionID),
    ]);
    const studioSession = r[0].payload;
    const studioSessionMercury = r[1].payload;
    this.init(() =>
      this.initStudioSessionTextField(studioSession, () =>
        this.initProjectTextField(studioSession.project)
      )
    );
    this.setState({
      open: true,
      workSession: { studioSession, studioSessionMercury },
      upcomingStages:
        studioSessionMercury?.currentStages?.filter((s) => s.nudgeable) ?? [],
      // selectedStage: undefined,
      _greeting: "",
      agree: false,
      init: true,
      nudge: true,
      backdropOpen: false,
    });
  }

  async nudgeForWorkSession() {
    const { updateWorkSessionMercury } = this.props;
    const { workSession, nudgeSMS, selectedStage, project } = this.state;
    this.setState({ nudgeOpen: false });
    const body = {
      mercuryStageID: selectedStage.id,
      nudge: true,
      notes: project?.project?.emailNotes,
      musician: { contactBySMS: nudgeSMS },
    };
    await updateWorkSessionMercury(workSession.studioSession.id, body);
  }

  /// PROJECT INVITE
  async project(projectID) {
    const {
      getProjectById,
      getProjectMercury,
      projectHiring,
      updateProjectHiringNotify,
    } = this.props;
    this.setState({ backdropOpen: true });
    const r = await Promise.all([
      getProjectById(projectID),
      projectHiring
        ? updateProjectHiringNotify(projectID, this.getProjectHiringBody())
        : getProjectMercury(projectID),
    ]);
    const project = r[0].payload;
    const projectMercury = r[1].payload;
    this.init(() => this.initProjectTextField(project));
    this.setState({
      open: true,
      project: { project, projectMercury },
      upcomingStages: projectMercury.upcomingStages,
      _greeting: "",
      agree: false,
      init: true,
      notify: true,
      backdropOpen: false,
    });
  }

  getProjectHiringBody() {
    const { projectHiring } = this.props;
    if (
      projectHiring.selectedMusicians.length === 0 ||
      projectHiring.selectedSessions.length === 0
    )
      return {};
    const body = { sessionIDs: [], musicianIDs: [] };
    for (const key in projectHiring.selectedMusicians) {
      if (Object.hasOwnProperty.call(projectHiring.selectedMusicians, key)) {
        const musician = projectHiring.selectedMusicians[key];
        body.musicianIDs.push(musician.id);
      }
    }

    for (const key in projectHiring.selectedSessions) {
      if (Object.hasOwnProperty.call(projectHiring.selectedSessions, key)) {
        const session = projectHiring.selectedSessions[key];
        body.sessionIDs.push(session.id);
      }
    }

    return body;
  }

  async notifyForProject() {
    const { updateProjectMercury, refresh } = this.props;

    const {
      _greeting,
      notes,
      showRoster,
      showSessionDetails,
      selectedStage,
      project,
    } = this.state;

    const { NotificationCenter } = this.context;
    this.setState({ loading: true });

    await updateProjectMercury(project.project.id, {
      greeting: _greeting,
      notes: notes,
      showRoster,
      showSessionDetails,
      mercuryStageID: selectedStage.id,
      ...this.getProjectHiringBody(),
    });

    this.close();

    NotificationCenter.sweetAlert({
      title: "Email(s) sent",
      success: true,
    });
    setTimeout(NotificationCenter.hide, 1500);
    setTimeout(refresh, 2000);
  }

  async nudgeProject(projectID) {
    const {
      getProjectById,
      getProjectMercury,
      projectHiring,
      updateProjectHiringNotify,
    } = this.props;
    this.init();
    this.setState({ backdropOpen: true });
    const r = await Promise.all([
      getProjectById(projectID),
      projectHiring
        ? updateProjectHiringNotify(projectID, this.getProjectHiringBody())
        : getProjectMercury(projectID),
    ]);
    const project = r[0].payload;
    const projectMercury = r[1].payload;

    this.setState({
      open: true,
      project: { project, projectMercury },
      upcomingStages:
        projectMercury?.currentStages?.filter((s) => s.nudgeable) ?? [],
      _greeting: "",
      agree: false,
      nudge: true,
      init: true,
      backdropOpen: false,
    });
  }

  async nudgeForProject() {
    const { updateProjectMercury } = this.props;
    const { project, nudgeSMS, selectedStage } = this.state;
    this.setState({ nudgeOpen: false });
    const body = {
      mercuryStageID: selectedStage.id,
      nudge: true,
      notes: project?.project?.emailNotes,
      musician: { contactBySMS: nudgeSMS },
      ...this.getProjectHiringBody(),
    };
    await updateProjectMercury(project.project.id, body);
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState(
      { [name]: event.target.checked },
      this.getHTMLPreview.bind(this)
    );
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const {
      classes,
      studios,
      payrolls,
      children,
      configurations,
      currentCompany,
      fullScreen,
      projectHiring,
    } = this.props;

    const {
      loading,
      open,
      agree,
      selectedStage,
      showRoster,
      showSessionDetails,
      wageAgreement,
      notes,
      _greeting,
      composer,
      leader,
      contractor,
      studioID,
      payrollID,
      title,
      preview,
      iframeHeight,
      previewLoading,
      hideEmailLogo,
      configurationCode,
      upcomingStages,
      selectedJob,
      jobs,
      nudge,
      project,
      workSession,
    } = this.state;

    let dialog;

    if (loading) {
      dialog = (
        <Dialog
          open={open}
          onClose={() => this.close()}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"sm"}
        >
          <Grid
            container
            className={classes.container}
            justify="center"
            spacing={2}
          >
            <Grid item>
              <CircularProgress color="secondary" />
            </Grid>
          </Grid>
        </Dialog>
      );
    } else if (selectedStage === undefined) {
      dialog = (
        <Dialog
          open={open}
          onClose={() => {
            this.close();
            this.setState({ selectedStage: undefined });
          }}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"sm"}
        >
          <Grid
            container
            className={classes.container}
            justify="center"
            spacing={2}
          >
            {upcomingStages && upcomingStages.length === 0 ? (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6">
                  It looks like there is nobody to {nudge ? "nudge" : "notify"}!
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Make sure you have assigned musicians to positions
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  {this.state.nudge ? (
                    <span>
                      <i class="fas fa-bullhorn p-right"></i> Nudge Musicians{" "}
                      {project ? (
                        <span style={{ fontWeight: 800, color: "#2196f3" }}>
                          {projectHiring.selectedMusicians.length ||
                          projectHiring.selectedSessions.length ? (
                            <span>
                              based on your selection{" "}
                              <i class="fad fa-lasso p-left"></i>
                            </span>
                          ) : (
                            <span>
                              for the entire project{" "}
                              <i class="fad fa-folder-open p-left"></i>
                            </span>
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.notify ? (
                    <span>
                      <i class="fas fa-play-circle p-right"></i> Notify
                      Musicians{" "}
                      {project ? (
                        <span style={{ fontWeight: 800, color: "#2196f3" }}>
                          {projectHiring.selectedMusicians.length ||
                          projectHiring.selectedSessions.length ? (
                            <span>
                              based on your selection{" "}
                              <i class="fad fa-lasso p-left"></i>
                            </span>
                          ) : (
                            <span>
                              for the entire project{" "}
                              <i class="fad fa-folder-open p-left"></i>
                            </span>
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                      {workSession ? (
                        <span style={{ fontWeight: 800, color: "#ffa000" }}>
                          for this work session only
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {this.state.nudge ? "Select the stage you want to nudge" : ""}
                  {this.state.notify
                    ? "Select the next stage you want to notify"
                    : ""}
                </Typography>
              </Grid>
            )}
            {upcomingStages &&
              upcomingStages.map((s) => (
                <>
                  <Grid
                    item
                    xs={6}
                    md={3}
                    key={s.id}
                    onClick={() => {
                      this.setState(
                        (prevState) => ({
                          selectedStage: s,
                          jobs: s.jobs,
                          selectedJob: s.jobs[0],
                          open: !prevState.nudge,
                          nudgeOpen: prevState.nudge,
                        }),
                        this.getHTMLPreview.bind(this)
                      );
                    }}
                  >
                    <div className={classes.widget}>
                      <img
                        src={s.icon}
                        style={{
                          height: 45,
                        }}
                        alt="status"
                      />
                      <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {s.name}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {s.jobCount} Musician{s.jobCount > 1 ? "s" : ""}
                      </Typography>
                    </div>
                  </Grid>
                </>
              ))}
          </Grid>
        </Dialog>
      );
    } else if (!nudge) {
      dialog = (
        <Dialog
          open={open}
          onClose={() => {
            this.close();
            this.setState({ selectedStage: undefined });
          }}
          aria-labelledby="form-dialog-title"
          fullWidth
          fullScreen={fullScreen}
          classes={{
            paper: !fullScreen && classes.paper,
          }}
          scroll="paper"
          maxWidth={"md"}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="stretch"
            style={{ flex: 1, height: 0 }}
          >
            <Hidden smDown>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  background: "#F1F5F9",
                  display: "flex",
                  overflow: "scroll",
                  height: "100%",
                  flexDirection: "column",
                  position: "relative",
                  borderRight: "solid 1px rgba(155,155,155, 0.3)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexWrap: "wrap",
                    flexDirection: "column",
                  }}
                >
                  {this.state.bottomNavigationStep === 1 ? (
                    <>
                      <Paper
                        elevation={0}
                        style={{
                          padding: 8,
                          zIndex: 9999,
                          borderBottom: "solid 1px rgba(155,155,155, 0.3)",
                        }}
                      >
                        <Typography variant="caption" display="block">
                          <b>To:</b>{" "}
                          {selectedJob?.channels?.find((c) => c.name === "SMS")
                            ?.contact ?? "Unkown"}
                        </Typography>
                      </Paper>
                      <SMSPreview
                        text={
                          selectedJob?.channels?.find((c) => c.name === "SMS")
                            ?.subject ?? "Unkown"
                        }
                      />
                    </>
                  ) : (
                    []
                  )}

                  {this.state.bottomNavigationStep === 0 ? (
                    <div style={{ flex: 1 }}>
                      <Paper
                        elevation={0}
                        style={{
                          padding: 8,
                          borderBottom: "solid 1px rgba(155,155,155, 0.3)",
                        }}
                      >
                        <Typography
                          onClick={(e) =>
                            this.setState({ jobListAnchorEl: e.currentTarget })
                          }
                          variant="caption"
                          display="inline-block"
                          style={{ cursor: "pointer" }}
                        >
                          <b>To:</b>{" "}
                          <span
                            style={{
                              background: "rgba(155,155,155,0.1)",
                              border: "solid 1px rgba(155,155,155,0.3)",
                              padding: "0px 4px",
                              borderRadius: 30,
                            }}
                          >
                            {selectedJob.name} (
                            {selectedJob?.channels?.find(
                              (c) => c.name === "Email"
                            )?.contact ?? "Unkown"}
                            )
                          </span>
                          <b>
                            {jobs.length - 1 > 1
                              ? ` +${jobs.length - 1} more ▾`
                              : ""}{" "}
                          </b>
                        </Typography>
                        <Menu
                          open={Boolean(this.state.jobListAnchorEl)}
                          anchorEl={this.state.jobListAnchorEl}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          PaperProps={{
                            style: {
                              maxHeight: 400,
                              width: "20ch",
                            },
                          }}
                          onClose={() =>
                            this.setState({
                              jobListAnchorEl: undefined,
                            })
                          }
                        >
                          {jobs?.map((j) => (
                            <MenuItem
                              dense
                              onClick={() =>
                                this.setState(
                                  {
                                    selectedJob: j,
                                    jobListAnchorEl: undefined,
                                  },
                                  this.getHTMLPreview.bind(this)
                                )
                              }
                            >
                              <ListItemText
                                secondary={
                                  j?.channels?.find((c) => c.name === "Email")
                                    ?.contact ?? "Unkown"
                                }
                                primary={j.name}
                              />
                            </MenuItem>
                          ))}
                        </Menu>
                        <Typography variant="caption" display="block">
                          <b>From:</b>{" "}
                          {contractor || currentCompany.name || "Rhapsody Bot"}{" "}
                          (studio@rhapsody.la)
                        </Typography>
                        <Typography variant="caption" display="block">
                          <b>Reply To:</b> {currentCompany.email}
                        </Typography>
                        <Typography variant="caption" display="block">
                          <b>Subject:</b>{" "}
                          {selectedJob?.channels?.find(
                            (c) => c.name === "Email"
                          )?.subject ?? ""}
                          {selectedStage?.channels?.find(
                            (c) => c.name === "Email"
                          )?.subject ?? ""}
                        </Typography>
                      </Paper>
                      {previewLoading ? (
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <CircularProgress
                              size={20}
                              style={{ color: "#607d8b" }}
                            />
                            <br />
                            <Typography variant="caption" color="textSecondary">
                              Loading Email Preview...
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        []
                      )}
                      {preview ? (
                        <div
                          style={{
                            flex: "1 1 auto",
                            minHeight: 0,
                            width: "100%",
                            overflow: "auto",
                            position: "relative",
                          }}
                        >
                          <iframe
                            title="preview"
                            srcDoc={preview}
                            ref={this.iframe}
                            onLoad={() => {
                              const iframe = this.iframe.current;
                              if (iframe.contentWindow) {
                                const ih =
                                  iframe.contentWindow.document.body
                                    .scrollHeight;
                                if (ih > 0) {
                                  this.setState({
                                    iframeHeight: ih,
                                    previewLoading: false,
                                  });
                                }
                              }
                            }}
                            style={{
                              border: "none",
                              width: "100%",
                              height: iframeHeight,
                            }}
                          />
                          <div
                            style={{
                              height: iframeHeight,
                              width: "100%",
                              position: "absolute",
                              top: 0,
                            }}
                          />
                        </div>
                      ) : (
                        []
                      )}
                    </div>
                  ) : (
                    []
                  )}
                  {selectedJob?.channels?.find((c) => c.name === "SMS") ? (
                    <BottomNavigation
                      size="small"
                      value={this.state.bottomNavigationStep}
                      onChange={(event, newValue) => {
                        this.setState({ bottomNavigationStep: newValue });
                      }}
                      style={{
                        position: "sticky",
                        bottom: 0,
                        width: "100%",
                      }}
                      showLabels
                    >
                      <BottomNavigationAction
                        size="small"
                        label="Email Preview"
                        icon={<Icon className="fad fa-envelope-open-text" />}
                      />
                      <BottomNavigationAction
                        size="small"
                        label="SMS Preview"
                        icon={<Icon className="fad fa-sms" />}
                      />
                    </BottomNavigation>
                  ) : (
                    []
                  )}
                </div>
              </Grid>
            </Hidden>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                overflow: "scroll",
                position: "relative",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ padding: 12, flexGrow: 1 }}>
                <Typography variant="h6">
                  <img src={selectedStage.icon} height="25" alt="sttusicon" />
                  <span style={{ color: selectedStage.color, marginLeft: 4 }}>
                    {selectedStage.name}
                  </span>
                </Typography>
                <Grid container justify="space-between" alignItems="center">
                  {configurations && configurations.length ? (
                    <Grid item xs={12}>
                      <TextField
                        select
                        label="Header"
                        value={configurationCode}
                        // disabled={loading}
                        onChange={(e) => {
                          this.state.configurationCode = e.target.value; // eslint-disable-line
                          this.saveProject("configurationCode");
                        }}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        style={{ width: "calc(100% - 8px)" }}
                        variant="outlined"
                      >
                        {configurations &&
                          configurations.length &&
                          configurations.map((c) => (
                            <MenuItem
                              key={`configurations_${c.id}`}
                              value={c.code}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src={c.appLogo}
                                    alt="appLogo"
                                    style={{ width: 200 }}
                                  />
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  ) : (
                    []
                  )}
                  <Grid item xs={12} style={{ padding: 8 }}>
                    <FormControlLabel
                      size="small"
                      control={
                        <Switch
                          checked={hideEmailLogo}
                          size="small"
                          onChange={(e) => {
                            this.setState(
                              { hideEmailLogo: e.target.checked },
                              () => {
                                this.saveProject("hideEmailLogo");
                              }
                            );
                          }}
                          value="hideEmailLogo"
                        />
                      }
                      label="Hide Logo"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Greeting"
                      className={classes.textField}
                      helperText={
                        _greeting.length > 255
                          ? "Text Too long"
                          : `${255 - _greeting.length} char left`
                      }
                      value={_greeting}
                      error={255 - _greeting.length <= 0}
                      fullWidth
                      multiline
                      onChange={this.handleChange("_greeting")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          this.setState({ greeting: _greeting });
                      }}
                      onBlur={() =>
                        this.setState(
                          { greeting: _greeting },
                          this.getHTMLPreview.bind(this)
                        )
                      }
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  {!project ? (
                    <Grid item xs={12}>
                      <TextField
                        label="Title"
                        className={classes.textField}
                        value={title}
                        onChange={this.handleChange("title")}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") this.saveSession("title");
                        }}
                        onBlur={() => this.saveSession("title")}
                        disabled={loading}
                        margin="normal"
                        variant="outlined"
                        style={{ width: "calc(100% - 8px)" }}
                      />
                    </Grid>
                  ) : (
                    []
                  )}
                  {!project ? (
                    <Grid item xs={6}>
                      <TextField
                        select
                        label="Location"
                        value={studioID}
                        disabled
                        // disabled={loading}
                        onChange={(e) => {
                          this.state.studioID = e.target.value; // eslint-disable-line
                          this.saveSession("studioID");
                        }}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        style={{ width: "calc(100% - 8px)" }}
                        variant="outlined"
                      >
                        {studios.map((s) => (
                          <MenuItem key={`s_${s.id}`} value={s.id}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  ) : (
                    []
                  )}
                  <Grid item xs={6}>
                    <TextField
                      label="Composer"
                      className={classes.textField}
                      value={composer}
                      onChange={this.handleChange("composer")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.saveProject("composer");
                      }}
                      onBlur={() => this.saveProject("composer")}
                      margin="normal"
                      variant="outlined"
                      disabled={loading}
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Leader"
                      className={classes.textField}
                      value={leader}
                      onChange={this.handleChange("leader")}
                      onBlur={() => this.saveProject("leader")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.saveProject("leader");
                      }}
                      margin="normal"
                      variant="outlined"
                      disabled={loading}
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Wage Scale Agreement"
                      className={classes.textField}
                      value={wageAgreement}
                      onChange={this.handleChange("wageAgreement")}
                      onBlur={() => this.saveProject("wageAgreement")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          this.saveProject("wageAgreement");
                      }}
                      margin="normal"
                      variant="outlined"
                      disabled={loading}
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Payroll"
                      value={payrollID}
                      disabled={loading}
                      onChange={(e) => {
                        this.state.payrollID = e.target.value; // eslint-disable-line
                        this.saveProject("payrollID");
                      }}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      style={{ width: "calc(100% - 8px)" }}
                      variant="outlined"
                    >
                      {payrolls.map((s) => (
                        <MenuItem key={`p_${s.id}`} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={project ? 12 : 6}>
                    <TextField
                      label="Contractor"
                      className={classes.textField}
                      value={contractor}
                      disabled={loading}
                      onChange={this.handleChange("contractor")}
                      onBlur={() => {
                        this.saveProject("contractor").then(() => {
                          if (this.state.job) this.refreshMercuryJob();
                        });
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.saveProject("contractor");
                      }}
                      margin="normal"
                      variant="outlined"
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ padding: 8 }}>
                    <FormControlLabel
                      size="small"
                      control={
                        <Switch
                          checked={showSessionDetails}
                          size="small"
                          onChange={this.handleCheckboxChange(
                            "showSessionDetails"
                          )}
                          value="showSessionDetails"
                        />
                      }
                      label="Show Session Details"
                    />
                  </Grid>
                  {showSessionDetails ? (
                    <Grid item xs={6} style={{ padding: 8 }}>
                      <FormControlLabel
                        size="small"
                        control={
                          <Switch
                            checked={showRoster}
                            size="small"
                            onChange={this.handleCheckboxChange("showRoster")}
                            value="showRoster"
                          />
                        }
                        label="Send Roster"
                      />
                    </Grid>
                  ) : (
                    []
                  )}
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                      Notes
                    </Typography>
                    <RichText
                      value={notes}
                      onSave={(v) =>
                        this.setState(
                          { notes: v },
                          this.getHTMLPreview.bind(this)
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.accept}>
                <FormGroup row style={{ paddingBottom: 8 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={agree}
                        onChange={this.handleCheckboxChange("agree")}
                        value="checkedA"
                      />
                    }
                    label={
                      <Typography variant="caption">
                        I understand that this action will send emails/SMS. All
                        informations concerning the project and the work
                        sessions are correct
                      </Typography>
                    }
                  />
                </FormGroup>
                <Hidden mdUp>
                  <Grid container spacing={1} justify="space-between">
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          this.setState({ previewOpen: true });
                        }}
                        fullWidth
                        style={{
                          background: "#2196f3",
                          color: "white",
                        }}
                      >
                        Preview
                      </Button>
                    </Grid>
                    <>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            this.close();
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          disabled={!agree || _greeting.length > 255}
                          onClick={this.notify.bind(this)}
                          variant="contained"
                          color="secondary"
                        >
                          Contact {jobs.length} Musician
                          {jobs.length > 1 ? "s" : ""}
                        </Button>
                      </Grid>
                    </>
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => {
                          this.close();
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={!agree || _greeting.length > 255}
                        onClick={this.notify.bind(this)}
                        variant="contained"
                        color="secondary"
                      >
                        Contact {jobs.length} Musician
                        {jobs.length > 1 ? "s" : ""}
                      </Button>
                    </Grid>
                  </Grid>
                </Hidden>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      );
    }

    return (
      <div>
        <NoRender open={open} node={children} />
        {dialog}
        <Dialog
          open={this.state.nudgeOpen}
          onClose={() => {
            this.setState({ nudgeOpen: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            <Typography variant="h2">
              <i class="fad fa-bullhorn"></i>
            </Typography>
            You are about to send a nudge to{" "}
            {this.state.job
              ? `${this.state.job?.rhapsodyJob?.musician?.firstName} ${this.state.job?.rhapsodyJob?.musician?.lastName}`
              : []}
            {this.state.workSession
              ? `${this.state.jobs?.length} musician${
                  this.state.jobs?.length > 1 ? "s" : ""
                }`
              : []}
            {this.state.project
              ? `${this.state.jobs?.length} musician${
                  this.state.jobs?.length > 1 ? "s" : ""
                }`
              : []}
            {this.state.jobs?.length ? (
              <Typography
                variant="caption"
                display="block"
                color="textSecondary"
              >
                {this.state.jobs
                  .reduce((a, v) => {
                    a.push(v.name);
                    return a;
                  }, [])
                  .join(", ")}
              </Typography>
            ) : (
              []
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.nudgeSMS}
                    onChange={(e) => {
                      this.setState({ nudgeSMS: e.target.checked });
                    }}
                    style={{ color: selectedStage?.color }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={`Send also a SMS`}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.close();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              autoFocus
              onClick={this.nudge.bind(this)}
              style={{
                background: this.state.selectedStage?.color,
                color: "white",
              }}
            >
              <i class="fad fa-bullhorn p-right"></i> Nudge!
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop style={{ zIndex: 9999 }} open={this.state.backdropOpen}>
          <CircularProgress style={{ color: "white" }} />
        </Backdrop>
        <Hidden mdUp>
          <Dialog
            open={this.state.previewOpen}
            onClose={() => this.setState({ previewOpen: false })}
            fullScreen
            scroll="body"
            classes={{
              paper: classes.previewPaper,
            }}
          >
            <DialogContent style={{ position: "relative", padding: 0 }}>
              <iframe
                title="preview"
                srcDoc={preview}
                ref={this.iframe}
                onLoad={() => {
                  const iframe = this.iframe.current;
                  if (iframe.contentWindow) {
                    const ih = iframe.contentWindow.document.body.scrollHeight;
                    if (ih > 0) {
                      this.setState({
                        iframeHeight: ih,
                        previewLoading: false,
                      });
                    }
                  }
                }}
                style={{
                  border: "none",
                  width: "100%",
                  height: iframeHeight,
                }}
              />
              <div
                style={{
                  height: iframeHeight,
                  width: "100%",
                  position: "absolute",
                  top: 0,
                }}
              />
            </DialogContent>
            <DialogActions
              style={{ position: "sticky", bottom: 0, background: "white" }}
            >
              <Button
                fullWidth
                variant="contained"
                onClick={() => this.setState({ previewOpen: false })}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Hidden>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMobileDialog()(MercuryNotify)));
