import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// constants
import { EMAIL } from "constants/regexp";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class FormPersonalInfo extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    updateUser: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { user } = this.props;
    this.state = {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      validEmail: false,
      emailBlur: false,
      hasChanged: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.id) {
      this.setState({
        firstName: nextProps.user.firstName,
        lastName: nextProps.user.lastName,
        email: nextProps.user.email,
      });
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;
    if (name === "email") {
      let validEmail = false;

      if (EMAIL.test(value)) {
        validEmail = true;
      }

      this.setState({ validEmail });
    }

    this.setState({
      [name]: value,
      hasChanged: true,
    });
  };

  isValid() {
    const { hasChanged, email } = this.state;

    return hasChanged && EMAIL.test(email);
  }

  async updateUserPassword() {
    const { NotificationCenter } = this.context;
    const { updateUser, user, refresh } = this.props;
    const { firstName, lastName, email } = this.state;

    const resp = await updateUser(user.id, {
      firstName,
      lastName,
      email,
    });

    if (resp.success) {
      refresh();
      NotificationCenter.stack(
        {
          title: "Success",
          subtitle: "Your info have been updated.",
          timestamp: new Date().getTime(),
          success: true,
        },
        {
          confirm: {
            label: "Ok",
            level: "success",
          },
        }
      );

      this.setState({
        hasChanged: false,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { lastName, firstName, email, validEmail } = this.state;

    return (
      <div>
        <Paper className={classes.container}>
          <Typography variant="h6" gutterBottom>
            First Name, Last Name and E-mail
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="firstName"
                label="First Name *"
                value={firstName}
                onChange={this.handleChange("firstName")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="lastName"
                label="Last Name *"
                value={lastName}
                onChange={this.handleChange("lastName")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={!validEmail && !EMAIL.test(email)}
                onBlur={() => this.setState({ emailBlur: true })}
                id="email"
                helperText={
                  !validEmail && !EMAIL.test(email) ? "Email not valid" : ""
                }
                label="Email *"
                value={email}
                onChange={this.handleChange("email")}
              />
            </Grid>
          </Grid>
          <br />
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              disabled={!this.isValid()}
              onClick={this.updateUserPassword.bind(this)}
            >
              Save
            </Button>
          </DialogActions>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(FormPersonalInfo);
