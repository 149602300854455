import * as Api from "api";
import {
  CREATE_MERCURY_JOB,
  GET_MERCURY_JOB,
  GET_RHAPSODY_JOB,
  UPDATE_MERCURY_JOB,
} from "constants/jobs";
import { networkAction } from "helpers/network/networkAction";

export const getMercuryJob = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MERCURY_JOB, Api.getMercuryJob, [id]);

export const updateMercuryJob = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MERCURY_JOB, Api.updateMercuryJob, [id, body]);

export const getRhapsodyJob = (id, body) => async (dispatch) =>
  networkAction(dispatch, GET_RHAPSODY_JOB, Api.getRhapsodyJob, [id, body]);

export const createMercuryJob = (id, body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MERCURY_JOB, Api.createMercuryJob, [id, body]);
