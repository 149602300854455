import { CircularProgress, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { withStyles } from "@material-ui/styles";
import ChoiceModal from "components/ChoiceModal";
import Help from "components/Help";
import LinkChip from "components/LinkChip";
// custom
import Loading from "components/Loading";
import ProjectAlternates from "components/ProjectAlternates";
import WorkSessionPresentationSelect from "components/WorkSessionPresentationSelect";
import WorkSessions from "components/WorkSessions";
import { WIDGET } from "config";
// constants
import { PROJECT, STUDIO_SESSION_DETAIL, TEMPLATES } from "constants/routes";
// helpers
import {
  buildForURL,
  getJsonFromUrl,
  goBack,
  removeURLParameter,
  updateURLParameter,
} from "helpers";
import AddWorkSessions from "hooks/AddWorkSessions";
import ColorPicker from "hooks/ColorPicker";
import FileUpload from "hooks/FileUpload";
import ProjectHiring from "hooks/ProjectHiring";
import ProjectWidget from "hooks/ProjectWidget";
import RichText from "hooks/RichText";
import Rosters from "hooks/Rosters";
import IframeResizer from "iframe-resizer-react";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
// styles
import styles from "./styles";

function MercuryEnabler({ api, project, refresh }) {
  const [enabled, setEnabled] = React.useState(project.mercuryEnabled);
  const [loading, setLoading] = React.useState(false);

  const changeMercury = async () => {
    if (!project.mercuryEnabled) {
      setLoading(true);
      const r = await api.updateProject(project.id, {
        mercuryEnabled: !enabled,
      });
      refresh();
      if (r.success) {
        setEnabled(true);
      } else {
        setEnabled(false);
      }
      setLoading(false);
    }
  };

  return (
    <div
      onClick={() => changeMercury()}
      style={{
        display: "flex",
        cursor: !project.mercuryEnabled && "pointer",
        alignItems: "center",
        color: enabled ? "white" : "#ef6c00",
        position: "relative",
        padding: 12,
        borderRadius: 8,
        background: enabled
          ? "linear-gradient(354deg, rgba(255,49,0,1) 0%, rgba(255,193,7,1) 100%)"
          : "rgba(255,49,0,0)",
        border: !enabled ? "solid 1px #ef6c00" : "solid 1px rgba(0,0,0,0)",
        transition: "background .2s, color .2s, border .2s",
      }}
    >
      <div
        style={{
          fontSize: 40,
          paddingRight: 20,
        }}
      >
        {loading ? (
          <CircularProgress
            style={{
              color: "#ef6c00",
              height: 30,
              width: 30,
            }}
            color="secondary"
          />
        ) : (
          <i class="fad fa-solar-system"></i>
        )}
      </div>
      <div>
        <Typography variant="body2" display="block">
          <b>{enabled ? "Mercury Enabled" : "Enable Mercury"}</b>
          <sup
            style={{
              paddingLeft: 4,
              fontSize: 10,
              fontWeight: 800,
            }}
          >
            BETA
          </sup>
        </Typography>
        <Typography variant="caption" display="block">
          The next gen of the communication API{" "}
        </Typography>
      </div>
    </div>
  );
}

class WrapperProjectPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    api: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    currentCompany: PropTypes.object,
    refresh: PropTypes.func,
    loading: PropTypes.bool,
    project: PropTypes.object,
    layoutPresets: PropTypes.array,
    studioSessions: PropTypes.array,
    organizations: PropTypes.array,
    customers: PropTypes.array,
    sectionRoles: PropTypes.array,
    payrolls: PropTypes.array,
    studios: PropTypes.array,
    sessionTypes: PropTypes.array,
    configurations: PropTypes.array,
    refreshKey: PropTypes.number,
    conciergeReadOnly: PropTypes.bool,
    projectHiring: PropTypes.object,
    template: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    MercuryNotify: PropTypes.object,
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams, project } = this.props;
    this.state = {
      ...project,
      index: urlParams.index ? Number(urlParams.index) : 0,
      anchorEl: null,
      loading: false,
      schedulerOpen: urlParams.workSessionScheduler ? true : false,
      formStudioSessionOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const urlParams = getJsonFromUrl(nextProps.location);
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.setState({ ...nextProps.project });
    }
    if (urlParams.workSessionScheduler) {
      this.setState({ schedulerOpen: true });
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { refresh, project, api } = this.props;

    if (project[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await api.updateProject(project.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
      hasChanged: true,
    });
  };

  getStepContent() {
    const {
      studioSessions,
      history,
      classes,
      customers,
      payrolls,
      api,
      sectionRoles,
      layoutPresets,
      refresh,
      project,
      configurations,
      refreshKey,
      template,
      theme,
      conciergeReadOnly,
      subscription,
    } = this.props;

    const {
      index,
      loading,
      name,
      description,
      industryProjectNumber,
      label,
      customerID,
      contractor,
      payrollID,
      configurationCode,
      composer,
      leader,
      agreement,
      hideEmailLogo,
      color,
      hideRosterLogo,
      logoURL,
      emailNotes,
      generalNotes,
    } = this.state;

    const { MercuryNotify } = this.context;

    let archived = project.archived;

    switch (index) {
      case 0:
        return (
          <div style={{ flex: 1 }}>
            <ProjectAlternates
              api={api}
              template={template}
              subscription={subscription}
              project={project}
              layoutPresets={layoutPresets}
              history={history}
              disabled={archived || conciergeReadOnly}
              sectionRoles={sectionRoles}
              studioSessions={studioSessions}
              changeIndex={(i) => this.setState({ index: i })}
            />
          </div>
        );
      case 1:
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {studioSessions?.length ? (
              <div>
                <Grid container spacing={1} alignItems="stretch">
                  <Hidden mdUp>
                    {project?.mercuryEnabled ? (
                      <Grid item xs={12} style={{ textAlign: "right" }}>
                        <Chip
                          label={
                            <span>
                              <i class="fas fa-bullhorn p-right"></i> Nudge!
                            </span>
                          }
                          onClick={() => MercuryNotify.nudgeProject(project.id)}
                          size="small"
                        />
                        <Chip
                          label={
                            <span>
                              <i class="fas fa-paper-plane p-right"></i> Notify
                            </span>
                          }
                          onClick={() => MercuryNotify.project(project.id)}
                          size="small"
                          style={{
                            marginLeft: 8,
                            background: "#FFC107",
                            color: "#2196f3",
                          }}
                        />
                      </Grid>
                    ) : (
                      []
                    )}
                  </Hidden>
                  {project?.mercuryEnabled ? (
                    <>
                      <Grid item xs={6}>
                        <Paper style={{ minHeight: 220 }}>
                          <IframeResizer
                            frameborder="0"
                            src={`${WIDGET}?projectID=${project.id}&token=${
                              project.publicToken
                            }${theme.palette.type === "dark" ? "&dark" : ""}`}
                            style={{ width: "100%", minWidth: "100%" }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <ProjectWidget
                          project={project}
                          workSessions={studioSessions}
                        />
                      </Grid>
                    </>
                  ) : (
                    []
                  )}
                  <Grid item xs={12} style={{ height: "100%" }}></Grid>
                </Grid>
              </div>
            ) : (
              []
            )}
            <div style={{ flexGrow: 1, display: "flex" }}>
              <WorkSessions
                workSessions={studioSessions}
                project
                projectID={project.id}
                setSelectedCalendarDay={api.setSelectedCalendarDay}
                disabled={project.disabled || archived || conciergeReadOnly}
                history={history}
                archived={project.archived}
                onSelect={(session) =>
                  history.push({
                    pathname: STUDIO_SESSION_DETAIL,
                    search: buildForURL({
                      id: session.id,
                      back: `${window.location.pathname}${window.location.search}`,
                    }),
                  })
                }
              />
            </div>
          </div>
        );
      case 2:
        return <Rosters project={project} />;
      case 3:
        return (
          <ProjectHiring
            project={project}
            history={history}
            subscription={subscription}
            conciergeReadOnly={conciergeReadOnly}
          />
        );
      case 4:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Typography variant="h6" color="textPrimary">
                      About the Project
                    </Typography>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Name:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("name");
                          }}
                          disabled={archived || conciergeReadOnly}
                          fullWidth
                          onBlur={() => this.save("name")}
                          className={classes.inputBase}
                          value={name || ""}
                          onChange={this.handleChange("name")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Contractor:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("contractor");
                          }}
                          disabled={archived || conciergeReadOnly}
                          fullWidth
                          onBlur={() => this.save("contractor")}
                          className={classes.inputBase}
                          value={contractor}
                          onChange={this.handleChange("contractor")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Composer:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("composer");
                          }}
                          disabled={archived || conciergeReadOnly}
                          fullWidth
                          onBlur={() => this.save("composer")}
                          className={classes.inputBase}
                          value={composer}
                          onChange={this.handleChange("composer")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Leader:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("leader");
                          }}
                          disabled={archived || conciergeReadOnly}
                          fullWidth
                          onBlur={() => this.save("leader")}
                          className={classes.inputBase}
                          value={leader}
                          onChange={this.handleChange("leader")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          Wage Scale Agreement:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("agreement");
                          }}
                          disabled={archived || conciergeReadOnly}
                          fullWidth
                          onBlur={() => this.save("agreement")}
                          className={classes.inputBase}
                          value={agreement}
                          onChange={this.handleChange("agreement")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Help
                          help="Work sessions will use this color in the Calendar"
                          title="Color"
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <ColorPicker
                          onChange={(c) => {
                            this.setState({ color: c }, () =>
                              this.save("color")
                            );
                          }}
                          disabled={archived || conciergeReadOnly}
                          color={color}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Help
                          title="Description:"
                          help="For internal use only"
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          disabled={archived || conciergeReadOnly}
                          fullWidth
                          rows={3}
                          rowsMax={100}
                          multiline
                          onBlur={() => this.save("description")}
                          style={{ background: "rgba(255,235,59,0.1)" }}
                          className={classes.inputBase}
                          value={description || ""}
                          onChange={this.handleChange("description")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Help
                          title="Email Notes:"
                          help="Will be included in all emails sent for this project"
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <RichText
                          value={emailNotes}
                          disabled={archived || conciergeReadOnly}
                          onSave={(v) =>
                            this.setState({ emailNotes: v }, () =>
                              this.save("emailNotes")
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Help
                          title="General Notes"
                          help="This can be shared on a PDF roster"
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          disabled={archived || conciergeReadOnly}
                          fullWidth
                          rows={3}
                          rowsMax={100}
                          multiline
                          onBlur={() => this.save("generalNotes")}
                          className={classes.inputBase}
                          value={generalNotes || ""}
                          onChange={this.handleChange("generalNotes")}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Typography variant="h6" color="textPrimary">
                      For Payroll Forms Only
                    </Typography>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Label:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("label");
                          }}
                          disabled={archived || conciergeReadOnly}
                          fullWidth
                          onBlur={() => this.save("label")}
                          className={classes.inputBase}
                          value={label || ""}
                          onChange={this.handleChange("label")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          Industry Project #:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter")
                              this.save("industryProjectNumber");
                          }}
                          disabled={archived || conciergeReadOnly}
                          fullWidth
                          onBlur={() => this.save("industryProjectNumber")}
                          className={classes.inputBase}
                          value={industryProjectNumber}
                          onChange={this.handleChange("industryProjectNumber")}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Typography variant="h6" color="textPrimary">
                      Project Banner
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      The Roster and emails will show this banenr instead of
                      your company's logo.
                      <br />
                      Image should be a PNG with Dimension 460x90px on a
                      transparent Background.
                    </Typography>
                    <div style={{ marginBottom: 20 }}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <FileUpload
                            disabled={archived || conciergeReadOnly}
                            onUpload={(f) =>
                              this.setState({ logoURL: f }, () =>
                                this.save("logoURL")
                              )
                            }
                          />
                        </Grid>
                        {logoURL ? (
                          <Grid item>
                            <Chip
                              onClick={() => {
                                this.setState({ logoURL: "" }, () =>
                                  this.save("logoURL")
                                );
                              }}
                              style={{ marginBottom: 4 }}
                              label="Remove Logo"
                              size="small"
                              variant="outlined"
                            />
                          </Grid>
                        ) : (
                          []
                        )}
                      </Grid>
                      {logoURL ? (
                        <img
                          src={logoURL}
                          alt="Your Logo"
                          style={{
                            width: "50%",
                            border: "solid 1px rgba(155,155,155,0.3)",
                            borderRadius: 4,
                          }}
                        />
                      ) : (
                        []
                      )}
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Help
                            title="Hide Logo"
                            help="Your logo won't show in the Email"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={hideEmailLogo}
                                disabled={archived || conciergeReadOnly}
                                size="small"
                                onChange={(e) => {
                                  this.setState(
                                    { hideEmailLogo: e.target.checked },
                                    () => this.save("hideEmailLogo")
                                  );
                                }}
                              />
                            }
                            label=""
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Help
                            title="Hide Roster Logo"
                            help="Your logo won't show in the Roster"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={hideRosterLogo}
                                disabled={archived || conciergeReadOnly}
                                size="small"
                                onChange={(e) => {
                                  this.setState(
                                    { hideRosterLogo: e.target.checked },
                                    () => this.save("hideRosterLogo")
                                  );
                                }}
                              />
                            }
                            label=""
                          />
                        </Grid>
                      </Grid>
                      {configurations && configurations.length ? (
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={4}>
                            <Help
                              title="Email Header (Branding)"
                              help="Select the email header for this project."
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <FormControl
                              className={classes.formControl}
                              fullWidth
                            >
                              <Select
                                inputProps={{
                                  name: "Project",
                                  id: "project-simple",
                                }}
                                disabled={archived || conciergeReadOnly}
                                value={configurationCode}
                                onChange={(e) => {
                                  this.state.configurationCode = e.target.value; // eslint-disable-line
                                  this.save("configurationCode");
                                }}
                              >
                                {configurations.map((c) => (
                                  <MenuItem
                                    key={`configurations_${c.id}`}
                                    value={c.code}
                                  >
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ textAlign: "center" }}
                                      >
                                        <img
                                          alt="appLogo"
                                          src={c.appLogo}
                                          style={{ width: 200 }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ) : (
                        []
                      )}
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Typography variant="h6" color="textPrimary">
                      Related
                    </Typography>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          Customer / Billing Entity:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl className={classes.formControl} fullWidth>
                          <Select
                            inputProps={{
                              name: "Project",
                              id: "project-simple",
                            }}
                            disabled={archived || conciergeReadOnly}
                            value={customerID}
                            onChange={(e) => {
                              this.state.customerID = e.target.value; // eslint-disable-line
                              this.save("customerID");
                            }}
                          >
                            {customers.map((c) => (
                              <MenuItem key={`customer_${c.id}`} value={c.id}>
                                {c.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Payroll:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl className={classes.formControl} fullWidth>
                          <Select
                            inputProps={{
                              name: "Payroll",
                              id: "Payroll-simple",
                            }}
                            disabled={archived || conciergeReadOnly}
                            value={payrollID}
                            onChange={(e) => {
                              this.state.payrollID = e.target.value; // eslint-disable-line
                              this.save("payrollID");
                            }}
                          >
                            {payrolls.map((p) => (
                              <MenuItem key={`payroll_${p.id}`} value={p.id}>
                                {p.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      case 5:
        return (
          <div style={{ padding: 20, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography variant="h6" color="textPrimary">
                        {project.archived ? "Archived" : "Archive"}
                      </Typography>
                      <Typography color="textSecondary">
                        {project.archived
                          ? `Your project is archived.`
                          : template
                          ? "If you no longer use this template, you can archive it."
                          : "If your Project is done, you can archive it. It will show in the archive tab on the main list. All related Work Session will also be archived."}
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <div style={{ width: "100%" }}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        style={{ width: "100%" }}
                      >
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            disabled={archived || conciergeReadOnly}
                            onClick={async () => {
                              await api.updateProject(project.id, {
                                archived: !project.archived,
                              });
                              refresh();
                            }}
                          >
                            {!project.archived
                              ? `Archive ${template ? "Template" : "Project"}`
                              : `Unarchive ${
                                  template ? "Template" : "Project"
                                }`}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Grid>
          </div>
        );
      default:
        return (
          <Typography variant="h6" color="textPrimary">
            Coming Soon
          </Typography>
        );
    }
  }

  openStudioSession() {
    const { api } = this.props;
    api.getStudios();
    this.setState({ choiceOpen: true });
  }

  closeStudioSession() {
    this.setState({ formStudioSessionOpen: false });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  callback() {
    const { NotificationCenter } = this.context;

    NotificationCenter.sweetAlert(
      {
        title: "Success",
        success: true,
        body: `Your logo has been uploaded`,
        timestamp: new Date().getTime(),
      },
      {
        confirm: {
          label: "Ok",
          level: "success",
        },
      }
    );

    setTimeout(NotificationCenter.hide, 2000);
  }

  render() {
    const {
      classes,
      history,
      loading,
      project,
      refresh,
      studioSessions,
      conciergeReadOnly,
      projectHiring,
      workSessions,
      template,
    } = this.props;

    const { index } = this.state;

    const { MercuryNotify } = this.context;

    if (loading) {
      return (
        <div className="page">
          <div className={`${classes.background}`}>
            <Loading message={"Loading Project"} />
          </div>
        </div>
      );
    }

    const projectNotifyDisabled =
      projectHiring.selectedSessions.length === 0 ||
      projectHiring.selectedMusicians.length === 0;

    return (
      <div className="page">
        <Helmet>
          <title>{project.name}</title>
          <meta name="project" content="project page" />
        </Helmet>
        <Paper
          elevation={10}
          style={{
            borderRadius: 0,
          }}
          className={classes.subHeader}
        >
          <Hidden smDown>
            <Grid container style={{ padding: 10 }} justify="space-between">
              <Grid item style={{ padding: 6 }}>
                <Breadcrumbs
                  arial-label="Breadcrumb"
                  separator={"-"}
                  style={{
                    color: "white",
                  }}
                >
                  <Chip
                    label={template ? "All Templates" : "All Projects"}
                    className={classes.backChip}
                    onClick={() =>
                      goBack(history, template ? TEMPLATES : PROJECT)
                    }
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Back
                          className={classes.icon}
                          style={{ color: "white" }}
                        />
                      </Avatar>
                    }
                  />
                  <LinkChip
                    icon={<i class="fad fa-folder-open"></i>}
                    label={project.name}
                    history={history}
                  />
                </Breadcrumbs>
              </Grid>
              {conciergeReadOnly ? (
                <div
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    background: "#03a9f4",
                    padding: 4,
                    borderRadius: 4,
                    fontSize: 12,
                  }}
                >
                  <i
                    style={{ marginRight: 8, fontSize: 18 }}
                    class="fad fa-concierge-bell"
                  ></i>
                  <div>
                    <b>Concierge Enabled</b>
                    <br />
                    <span style={{ fontSize: 10 }}>Project is read only</span>
                  </div>
                </div>
              ) : (
                <Grid item>
                  {!template && project?.mercuryEnabled && index === 3 ? (
                    <>
                      <Chip
                        label={
                          <span>
                            <i class="fas fa-bullhorn p-right"></i> Nudge!
                          </span>
                        }
                        disabled={projectNotifyDisabled}
                        onClick={() => MercuryNotify.nudgeProject(project.id)}
                        size="small"
                      />
                      <Chip
                        label={
                          <span>
                            <i class="fas fa-paper-plane p-right"></i> Notify
                          </span>
                        }
                        disabled={projectNotifyDisabled}
                        onClick={() => MercuryNotify.project(project.id)}
                        size="small"
                        style={{
                          marginLeft: 8,
                          background: "#2196f3",
                          color: "white",
                        }}
                      />
                    </>
                  ) : (
                    []
                  )}
                  {/* {!template ? <Autopilot /> : []} */}
                </Grid>
              )}
            </Grid>
          </Hidden>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={index}
              className={classes.subHeader}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
            >
              <Tab
                label="Seating"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  label: index === 0 ? classes.labelSelected : classes.label,
                }}
              />
              <Tab
                style={{ display: template ? "none" : undefined }}
                label="Work Sessions"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  label: index === 1 ? classes.labelSelected : classes.label,
                }}
              />
              {!project.mercuryEnabled || studioSessions.length < 1 ? (
                <CloneProps>
                  {(tabProps) => (
                    <Tooltip title="You need some work sessions first">
                      <div>
                        <Tab
                          style={{ display: template && "none" }}
                          {...tabProps}
                          disabled
                          label={<span>Rosters</span>}
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.tabSelected,
                            disabled: classes.tabDisabled,
                            label:
                              index === 3
                                ? classes.labelSelected
                                : classes.label,
                          }}
                        />
                      </div>
                    </Tooltip>
                  )}
                </CloneProps>
              ) : (
                <Tab
                  style={{ display: template && "none" }}
                  label={<span>Rosters</span>}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                    disabled: classes.tabDisabled,
                    label: index === 3 ? classes.labelSelected : classes.label,
                  }}
                />
              )}
              {!project.mercuryEnabled || studioSessions.length < 1 ? (
                <CloneProps>
                  {(tabProps) => (
                    <Tooltip title="You need some work sessions first">
                      <div>
                        <Tab
                          style={{ display: template && "none" }}
                          {...tabProps}
                          disabled
                          label={<span>Hiring</span>}
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.tabSelected,
                            disabled: classes.tabDisabled,
                            label:
                              index === 3
                                ? classes.labelSelected
                                : classes.label,
                          }}
                        />
                      </div>
                    </Tooltip>
                  )}
                </CloneProps>
              ) : (
                <Tab
                  style={{ display: template && "none" }}
                  label={<span>Hiring</span>}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                    disabled: classes.tabDisabled,
                    label: index === 3 ? classes.labelSelected : classes.label,
                  }}
                />
              )}
              <Tab
                style={{ display: template && "none" }}
                label="Description"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  label: index === 4 ? classes.labelSelected : classes.label,
                }}
              />
              <Tab
                label="Danger Zone"
                style={{ color: "#f44336" }}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.dangerTabSelected,
                  label:
                    index === 5
                      ? classes.dangerLabelSelected
                      : classes.dangerLabel,
                }}
              />
            </Tabs>
            {index === 1 && studioSessions.length ? (
              <WorkSessionPresentationSelect />
            ) : (
              []
            )}
          </div>
        </Paper>
        <div className={`${classes.background}`}>{this.getStepContent()}</div>
        <ChoiceModal
          open={this.state.choiceOpen}
          onClose={() => this.setState({ choiceOpen: false })}
          title="How big is your project?"
          options={[
            {
              title: "Single Date",
              subtitle: "Unique Roster",
              icon: <ScheduleIcon />,
              color: "#9e9e9e",
              callback: () => console.log("test"),
            },
            {
              title: "Multiple Dates",
              subtitle: "1 Credit per Work Session",
              icon: <DateRangeIcon />,
              color: "#2196f3",
              callback: () => console.log("test"),
            },
          ]}
        />
        <AddWorkSessions
          open={this.state.schedulerOpen}
          close={() => {
            this.setState({ schedulerOpen: false });
            history.push({
              search: removeURLParameter([
                "workSessionScheduler",
                "standalone",
              ]),
            });
            refresh();
          }}
          project={project}
        />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(WrapperProjectPage);

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}
