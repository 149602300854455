import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "postal";
const configurations = `${REACT_APP_API_PREFIX}/${service}/configurations`;

export function getConfigurations(filters) {
  const url = `${configurations}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getConfigurationById(id) {
  const url = `${configurations}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateConfiguration(id, body) {
  const url = `${configurations}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteConfiguration(id) {
  const url = `${configurations}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createConfiguration(body) {
  const url = `${configurations}`;
  return {
    method: POST,
    url,
    body,
  };
}
