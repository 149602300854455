import Chip from "@material-ui/core/Chip";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormInstrument from "components/FormInstrument";
// components
import InstrumentsList from "components/InstrumentsList";
// reactor
import Page from "components/Page";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperInstrumentPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    instruments: PropTypes.array,
    createInstrument: PropTypes.func,
    deleteInstrument: PropTypes.func,
    updateInstrument: PropTypes.func,
    instrumentSections: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedInstrument: undefined,
      formInstrumentOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { instruments, instrumentSections } = this.props;

    let data = instruments;
    const section = instrumentSections[index - 1];
    if (section) {
      data = instruments.filter((i) => i.sectionID === section.id);
    }

    return (
      <InstrumentsList
        instruments={data}
        onSelect={(selectedInstrument) =>
          this.setState({
            selectedInstrument,
            formInstrumentOpen: true,
          })
        }
      />
    );
  }

  render() {
    const {
      classes,
      history,
      loading,
      createInstrument,
      deleteInstrument,
      updateInstrument,
      refresh,
      instrumentSections,
    } = this.props;

    const { index, selectedInstrument, formInstrumentOpen } = this.state;

    return (
      <Page
        helmet="All Instruments"
        loadingMessage={"Loading Instruments"}
        loading={loading}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            {instrumentSections.map((i) => (
              <Tab
                label={i.name}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            ))}
          </Tabs>
        }
      >
        <div>
          <Chip
            onClick={() => this.setState({ formInstrumentOpen: true })}
            color="secondary"
            label="+ Instrument"
            style={{ marginBottom: 8 }}
          />
          {this.getStepContent()}
        </div>
        <FormInstrument
          open={formInstrumentOpen}
          instrument={selectedInstrument}
          close={() => {
            this.setState({
              formInstrumentOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedInstrument: undefined,
                }),
              200
            );
          }}
          createInstrument={createInstrument}
          deleteInstrument={deleteInstrument}
          updateInstrument={updateInstrument}
          instrumentSections={instrumentSections}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperInstrumentPage);
