import { Chip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
// constants
import { STUDIO } from "constants/routes";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class StudiosList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    history: PropTypes.object,
    studios: PropTypes.array,
    onSelect: PropTypes.func,
  };

  static contextTypes = {
    TourCenter: PropTypes.array,
  };

  onSelect(selectedStudio) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedStudio);
    }
  }

  render() {
    const { studios, history } = this.props;
    const { TourCenter } = this.context;

    if (studios.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              <i style={{ fontSize: 70 }} class="fad fa-location-dot"></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No Studios/Venues
            </Typography>
            <Typography variant="caption" color="textSecondary">
              This is a place where work sessions take place.
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              onClick={() => TourCenter.start(2)}
              label="+ New Studio/Venue"
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={studios}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "address",
            hidden: { smDown: true },
            title: "Address",
            numeric: false,
            render: (t, d) => (
              <React.Fragment>
                <Typography variant="body2">{d.address}</Typography>
                <Typography variant="body2">
                  {d.zipcode} {d.city} {d.state}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "tzName",
            title: "Timezone",
            hidden: { smDown: true },
            numeric: false,
          },
          {
            path: "contact",
            hidden: { smDown: true },
            title: "Contact",
            numeric: false,
            render: (t, d) => (
              <React.Fragment>
                <Typography variant="body2">{d.contact}</Typography>
                <Typography variant="body2">{d.email}</Typography>
              </React.Fragment>
            ),
          },
        ]}
        title={"Studios / Venues"}
        onRowSelect={(project) => history.push(`${STUDIO}?id=${project.id}`)}
      />
    );
  }
}

export default withStyles(styles)(StudiosList);
