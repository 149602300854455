import { networkAction } from "helpers/network/networkAction";

import {
  GET_PAYROLLS,
  GET_PAYROLL_BY_ID,
  GET_PAYROLL_STUDIO_SESSIONS,
  GET_PAYROLL_PROJECTS,
  DELETE_PAYROLL,
  UPDATE_PAYROLL,
  CREATE_PAYROLL,
} from "constants/payrolls";

import * as Api from "api";

export const getPayrolls = () => async (dispatch) =>
  networkAction(dispatch, GET_PAYROLLS, Api.getPayrolls, []);

export const getPayrollById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PAYROLL_BY_ID, Api.getPayrollById, [id]);

export const getPayrollStudioSessions = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PAYROLL_STUDIO_SESSIONS,
    Api.getPayrollStudioSessions,
    [id]
  );

export const getPayrollProjects = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PAYROLL_PROJECTS, Api.getPayrollProjects, [id]);

export const deletePayroll = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PAYROLL, Api.deletePayroll, [id]);

export const createPayroll = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PAYROLL, Api.createPayroll, [body]);

export const updatePayroll = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PAYROLL, Api.updatePayroll, [id, body]);
