import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const jobs = `/${service}/jobs`;

export function getStudioSessionMusicianById(id) {
  const url = `${jobs}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getSessionMusicianStatuses(id) {
  const url = `${jobs}/${id}/statuses`;
  return {
    method: GET,
    url,
  };
}

export function updateStudioSessionMusician(id, body) {
  const url = `${jobs}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function notifySessionMusician(id, body) {
  const url = `${jobs}/${id}/notify`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function notifySessionMusicianPreview(id, body) {
  const url = `${jobs}/${id}/notifyHTML`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteStudioSessionMusician(id) {
  const url = `${jobs}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createStudioSessionMusician(body) {
  const url = `${jobs}`;
  return {
    method: POST,
    url,
    body,
  };
}
