import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "muse";
const instrumentFamilies = `/${service}/families`;

export function getInstrumentFamilies() {
  const url = `${instrumentFamilies}`;
  return {
    method: GET,
    url,
  };
}

export function getInstrumentFamilyById(id) {
  const url = `${instrumentFamilies}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateInstrumentFamily(id, body) {
  const url = `${instrumentFamilies}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInstrumentFamily(id) {
  const url = `${instrumentFamilies}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInstrumentFamily(body) {
  const url = `${instrumentFamilies}`;
  return {
    method: POST,
    url,
    body,
  };
}
