import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const sectionRoles = `/${service}/sectionRoles`;

export function getSectionRoles() {
  const url = `${sectionRoles}`;
  return {
    method: GET,
    url,
  };
}

export function getSectionRoleById(id) {
  const url = `${sectionRoles}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSectionRole(id, body) {
  const url = `${sectionRoles}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSectionRole(id) {
  const url = `${sectionRoles}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSectionRole(body) {
  const url = `${sectionRoles}`;
  return {
    method: POST,
    url,
    body,
  };
}
