import { Chip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import FormImport from "components/FormImport";
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class ImportFiles extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    createFileImport: PropTypes.func,
    deleteFileImport: PropTypes.func,
    updateFileImport: PropTypes.func,
    getFileImports: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      uploadOpen: false,
      fileImports: [],
    };
    this.refresh();
  }

  async refresh() {
    const { getFileImports } = this.props;
    const resp = await getFileImports();
    if (resp.success) {
      this.setState({
        fileImports: resp.payload,
      });
    }
  }

  render() {
    const {
      classes,
      createFileImport,
      deleteFileImport,
      updateFileImport,
      getFileImports,
    } = this.props;
    const { uploadOpen, fileImports } = this.state;

    return (
      <div className={classes.container}>
        <Typography variant="h6" color="textPrimary">
          Imports
        </Typography>
        <Typography variant="body2" color="textPrimary">
          Because data entry is a time consuming task for a human, you can
          provide a file to fill your Rhapsody Database.
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Processing time can vary from 24h to 48h.
        </Typography>
        <br />
        <Chip
          onClick={() => this.setState({ uploadOpen: true })}
          label={"+ Import"}
          size="small"
        />
        {fileImports && fileImports.length ? (
          <Table
            data={fileImports}
            meta={[
              {
                path: "file",
                title: "Url",
                numeric: false,
              },
              {
                path: "category",
                title: "Category",
                numeric: false,
              },
              {
                path: "notes",
                title: "Notes",
                numeric: false,
              },
              {
                path: "status",
                title: "Status",
                numeric: false,
              },
            ]}
            onRowSelect={(p) =>
              this.setState({ selectedFileImport: p, uploadOpen: true })
            }
          />
        ) : (
          []
        )}
        <FormImport
          open={uploadOpen}
          close={() =>
            this.setState({ uploadOpen: false, selectedFileImport: undefined })
          }
          createFileImport={createFileImport}
          deleteFileImport={deleteFileImport}
          updateFileImport={updateFileImport}
          getFileImports={getFileImports}
          fileImport={this.state.selectedFileImport}
          refresh={this.refresh.bind(this)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ImportFiles);
