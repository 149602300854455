import { networkAction } from "helpers/network/networkAction";

import {
  GET_SESSION_TYPES,
  GET_SESSION_TYPE_BY_ID,
  DELETE_SESSION_TYPE,
  UPDATE_SESSION_TYPE,
  CREATE_SESSION_TYPE,
  GET_SESSION_TYPE_SESSION_MEDIA,
  GET_SESSION_TYPE_PRODUCTION_TYPES,
  GET_SESSION_TYPE_CATEGORIES,
  GET_SESSION_TYPE_RATES,
} from "constants/sessionTypes";

import * as Api from "api";

export const getSessionTypes = () => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_TYPES, Api.getSessionTypes, []);

export const getSessionTypeCategories = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_SESSION_TYPE_CATEGORIES,
    Api.getSessionTypeCategories,
    [id]
  );

export const getSessionTypeRates = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_TYPE_RATES, Api.getSessionTypeRates, [
    id,
  ]);

export const getSessionTypeSessionMedia = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_SESSION_TYPE_SESSION_MEDIA,
    Api.getSessionTypeSessionMedia,
    [id]
  );

export const getSessionTypeProductionTypes = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_SESSION_TYPE_PRODUCTION_TYPES,
    Api.getSessionTypeProductionTypes,
    [id]
  );

export const getSessionTypeById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_TYPE_BY_ID, Api.getSessionTypeById, [id]);

export const deleteSessionType = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SESSION_TYPE, Api.deleteSessionType, [id]);

export const createSessionType = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SESSION_TYPE, Api.createSessionType, [body]);

export const updateSessionType = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SESSION_TYPE, Api.updateSessionType, [
    id,
    body,
  ]);
