import { GET_STAGES } from "constants/stages";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_STAGES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
