export default (theme) => ({
  container: {
    padding: 20,
    background: theme.palette.background.default,
    width: "100%",
  },
  widget: {
    background: theme.palette.background.paper,
    borderRadius: 4,
    border: "solid 1px rgba(155, 155, 155, 0.3)",
    padding: 10,
    height: "calc(100% - 20px)",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
  },
  paper: {
    height: "100vh",
    position: "relative",
    display: "flex",
  },
  previewPaper: {
    position: "relative",
    padding: 0,
  },
  accept: {
    position: "sticky",
    bottom: 0,
    zIndex: 9999,
    padding: 12,
    borderTop: "solid 1px rgba(155,155,155,0.3)",
    background: "rgba(155,155,155,0.1)",
    backdropFilter: "blur(10px)",
    WebkitBackdropFilter: "blur(10px)",
  },
});
