import {
  Checkbox,
  Chip,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MoreVert from "@material-ui/icons/MoreVert";
import * as Actions from "actions";
import { FormMusician } from "components";
import { META } from "config";
import { MUSICIAN_DETAIL } from "constants/routes";
import SyncInput from "hooks/SyncInput";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const multiply = {
  scaleWages: true,
  cartage: true,
  pension: true,
  welfare: true,
  rehearsalScale: true,
  performanceScale: true,
  doublingPay: true,
  premiumPercent: true,
  premiumScale: true,
  nonPensionable: true,
  otherPensionable: true,
  workDues: true,
};

export default function PayrollLineItem({ variant, disabled, value }) {
  const [v, setV] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState();
  const [musician, setMusician] = React.useState();
  const [formMusicianOpen, setFormMusicianOpen] = React.useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [musicianLoading, setMusicianLoading] = React.useState(false);
  const classes = useStyles(styles);
  const instruments = useSelector((state) => state.instruments);
  const dispatch = useDispatch();

  React.useEffect(() => {
    formatValue(value);
  }, [value]);

  function formatValue(val) {
    const _v = { ...val };
    for (const key in multiply) {
      if (Object.hasOwnProperty.call(multiply, key)) {
        _v[key] = _v[key] / 100;
      }
    }
    setV(_v);
  }

  function handleChange(name, value) {
    const _v = { ...v };
    _v[name] = value;
    setV(_v);
  }

  const handleInstrumentClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInstrumentClose = () => {
    setAnchorEl();
  };

  const handleMenuClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl2();
  };

  async function save(name, value, numeric, multiply) {
    setLoading(true);
    const r = await dispatch(
      Actions.updateStudioSessionMusician(v.id, {
        [name]: numeric
          ? Math.round(Number(multiply ? Number(value * 100) : value))
          : value,
      })
    );
    setLoading(false);
    refresh();
    if (r.success) return value;

    return null;
  }

  const emptyPosition = v?.musician?.id === null ?? false;

  async function refresh() {
    dispatch(Actions.getRhapsodyJob(v.id)).then((r) => {
      if (r.success) {
        formatValue(r.payload);
      }
    });
  }

  async function viewMusicianCard(id) {
    setFormMusicianOpen(true);
    setMusicianLoading(true);
    const r = await dispatch(Actions.getMusicianById(id));
    setMusician(r.payload);
    setMusicianLoading(false);
  }

  if (!v) return <div />;

  return (
    <div
      className={classes.paper}
      style={{
        opacity: v.excludedFromPayroll ? 0.5 : 1,
        borderLeft: loading ? "solid 3px #607d8b" : "solid 3px #ffa000",
      }}
    >
      {/* MUSICIAN */}
      {META[variant].musician ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].musician }}
        >
          <div>
            <IconButton
              size="small"
              className={classes.margin}
              disabled={disabled || emptyPosition}
              onClick={handleMenuClick}
              tabIndex={-1}
            >
              <MoreVert fontSize="inherit" />
            </IconButton>
            <Menu
              id="simple-menu"
              size="small"
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={handleMenuClose}
              disabled={disabled}
            >
              {!v.musician.userUUID ? (
                <MenuItem
                  dense
                  disabled={disabled}
                  onClick={() => {
                    handleMenuClose();
                    viewMusicianCard(v.musician.id);
                  }}
                >
                  <ListItemText
                    primary={
                      <span>
                        <i class="fad fa-edit p-right"></i>Quick Musician Edit
                      </span>
                    }
                  />
                </MenuItem>
              ) : (
                []
              )}
              <MenuItem
                dense
                disabled={disabled}
                onClick={async () => {
                  handleMenuClose();
                  dispatch(Actions.deleteStudioSessionMusician(v.id)).then(
                    refresh
                  );
                }}
              >
                <ListItemText
                  primary={
                    <span>
                      <i class="fad fa-user-times p-right"></i>Remove Musician
                    </span>
                  }
                />
              </MenuItem>
              <MenuItem
                dense
                onClick={async () => {
                  handleMenuClose();
                  window.open(`${MUSICIAN_DETAIL}?id=${v.musician.id}`);
                }}
              >
                <ListItemText
                  primary={
                    <span>
                      <i class="fad fa-user-music p-right"></i> Edit musician
                    </span>
                  }
                />
              </MenuItem>
              {!v.excludedFromPayroll ? (
                <MenuItem
                  dense
                  onClick={async () => {
                    handleMenuClose();
                    handleChange("excludedFromPayroll", true);
                    save("excludedFromPayroll", true);
                  }}
                >
                  <ListItemText
                    primary={
                      <span>
                        <i class="fad fa-ban p-right"></i> Exclude from Payroll
                      </span>
                    }
                  />
                </MenuItem>
              ) : (
                <MenuItem
                  dense
                  onClick={async () => {
                    handleMenuClose();
                    handleChange("excludedFromPayroll", false);
                    save("excludedFromPayroll", false);
                  }}
                >
                  <ListItemText
                    primary={
                      <span>
                        <i class="fad fa-user-plus p-right"></i> Include in
                        Payroll
                      </span>
                    }
                  />
                </MenuItem>
              )}
            </Menu>
          </div>

          <Typography variant="body2" color="textPrimary">
            {!emptyPosition ? (
              `${v.musician.firstName} ${v.musician.lastName}`
            ) : (
              <Chip
                variant="outlined"
                style={{ opacity: 0.8 }}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <img
                        alt="chair"
                        src="https://storage.googleapis.com/rhapsody/logos/chair-01.png"
                        style={{ height: 20, marginTop: 2, paddingRight: 2 }}
                      />
                    </div>
                    <div item style={{ flexGrow: 1 }}>
                      <Typography variant="caption" color="textPrimary">
                        Empty Chair
                      </Typography>
                    </div>
                  </div>
                }
              />
            )}
          </Typography>
        </div>
      ) : (
        []
      )}

      {/* INSTRUMENTS */}
      {META[variant].instrument ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].instrument }}
        >
          {v.instrumentID && !emptyPosition ? (
            <div
              style={{
                width: 0,
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <Tooltip title={v.instrument?.name}>
                <Chip
                  size="small"
                  tabIndex={-1}
                  disabled={disabled}
                  label={v.instrument?.name}
                  style={{ maxWidth: "100%" }}
                  onDelete={
                    disabled
                      ? undefined
                      : () => {
                          handleChange("instrumentID", 0);
                          save("instrumentID", 0);
                        }
                  }
                />
              </Tooltip>
            </div>
          ) : (
            <div>
              {!disabled && !emptyPosition ? (
                <div
                  style={{
                    width: 0,
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <Chip
                    size="small"
                    variant="outlined"
                    label="+ Add"
                    tabIndex={-1}
                    onClick={handleInstrumentClick}
                  />
                </div>
              ) : (
                []
              )}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleInstrumentClose}
              >
                {v.musician &&
                  v.musician.instruments &&
                  v.musician.instruments.map((i) => (
                    <MenuItem
                      disabled={disabled}
                      key={`${v.id}_${i.instrument}`}
                      onClick={() => {
                        handleChange("instrumentID", i.id);
                        save("instrumentID", i.id);
                        handleInstrumentClose();
                      }}
                    >
                      {i.name}
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          )}
        </div>
      ) : (
        []
      )}

      {/* ADDRESS */}
      {META[variant].address ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].address }}
        >
          <Typography variant="body2" color="textPrimary">
            {`${v.musician.address || ""} ${v.musician.zipcode || ""} ${
              v.musician.city || ""
            }`}
          </Typography>
        </div>
      ) : (
        []
      )}

      {/* SSN */}
      {META[variant].ssn ? (
        <div
          className={`${classes.rowItem} ${classes.align}`}
          style={{ flex: META[variant].ssn }}
        >
          <Typography variant="body2" color="textPrimary">
            {v.musician.ssn}
          </Typography>
          <Typography variant="body2" color="textPrimary">
            {v.musician.ein}
          </Typography>
        </div>
      ) : (
        []
      )}

      {/* REHEARSAL COUNT */}
      {META[variant].rehearsalCount ? (
        <div
          className={classes.rowItem}
          style={{
            flex: META[variant].rehearsalCount,
            textAlign: "left",
          }}
        >
          <div>
            <SyncInput
              refresh={refresh}
              disabled={disabled}
              value={v.rehearsalCount || ""}
              name={"rehearsalCount"}
              handleChange={handleChange}
              save={save}
              numeric
            />
            <br />
            <Typography variant="caption" color="textPrimary">
              Scale:
              <b>{` ${v.rehearsalScale}`}</b>
            </Typography>
          </div>
        </div>
      ) : (
        []
      )}

      {/* PERFORMANCE COUNT */}
      {META[variant].performanceCount ? (
        <div
          className={classes.rowItem}
          style={{
            flex: META[variant].performanceCount,
            textAlign: "left",
          }}
        >
          <div>
            <SyncInput
              refresh={refresh}
              disabled={disabled}
              value={v.performanceCount || ""}
              name={"performanceCount"}
              handleChange={handleChange}
              save={save}
              numeric
            />
            <br />
            <Typography variant="caption" color="textPrimary">
              Scale:
              <b>{` ${v.performanceScale}`}</b>
            </Typography>
          </div>
        </div>
      ) : (
        []
      )}

      {/* HOURS WORKED */}
      {META[variant].hoursWorked ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].hoursWorked }}
        >
          <SyncInput
            refresh={refresh}
            disabled={disabled}
            value={v.hoursWorked || ""}
            name={"hoursWorked"}
            handleChange={handleChange}
            save={save}
            numeric={false}
          />
        </div>
      ) : (
        []
      )}

      {/* HOURS WORKED */}
      {META[variant].hoursWorked ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].hoursWorked }}
        >
          <SyncInput
            refresh={refresh}
            disabled={disabled}
            value={v.hoursGuaranteed || ""}
            name={"hoursGuaranteed"}
            handleChange={handleChange}
            save={save}
            numeric={false}
          />
        </div>
      ) : (
        []
      )}

      {/* TUNES */}
      {META[variant].tunes ? (
        <div className={classes.rowItem} style={{ flex: META[variant].tunes }}>
          <SyncInput
            refresh={refresh}
            disabled={disabled}
            value={v.tunes || ""}
            name={"tunes"}
            handleChange={handleChange}
            save={save}
            numeric={false}
          />
        </div>
      ) : (
        []
      )}

      {/* OVERSCALE */}
      {META[variant].overscale ? (
        <div
          className={classes.rowItem}
          style={{
            flex: META[variant].overscale,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Checkbox
            size="small"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("overscale", !v.overscale);
            }}
            disabled={disabled}
            checked={v.overscale}
            color="secondary"
            onChange={(e) => {
              handleChange("overscale", e.target.checked);
              save("overscale", e.target.checked);
            }}
          />
        </div>
      ) : (
        []
      )}

      {/* BENEFITS ONLY */}
      {META[variant].benefitsOnly ? (
        <div
          className={classes.rowItem}
          style={{
            flex: META[variant].benefitsOnly,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Checkbox
            size="small"
            onKeyPress={(e) => {
              if (e.key === "Enter") save("benefitsOnly", !v.benefitsOnly);
            }}
            disabled={disabled}
            checked={v.benefitsOnly}
            color="secondary"
            onChange={(e) => {
              handleChange("benefitsOnly", e.target.checked);
              save("benefitsOnly", e.target.checked);
            }}
          />
        </div>
      ) : (
        []
      )}

      {/* CARTAGE */}
      {META[variant].cartage ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].cartage }}
        >
          <SyncInput
            refresh={refresh}
            value={v.cartage || ""}
            multiply
            name={"cartage"}
            handleChange={handleChange}
            save={save}
            numeric
          />
        </div>
      ) : (
        []
      )}

      {/* DOUBLES */}
      {META[variant].doubles ? (
        <div
          className={classes.rowItem}
          style={{
            flex: META[variant].doubles,
            textAlign: "left",
          }}
        >
          <div>
            <SyncInput
              refresh={refresh}
              disabled={disabled}
              value={v.doubles || ""}
              name={"doubles"}
              handleChange={handleChange}
              save={save}
              numeric
            />
            {variant === "LV" ? (
              <Typography variant="caption" color="textPrimary">
                Pay:
                <b>{` ${v.doublingPay}`}</b>
              </Typography>
            ) : (
              []
            )}
          </div>
        </div>
      ) : (
        []
      )}

      {/* PREMIUM PERCENT */}
      {META[variant].premiumPercent ? (
        <div
          className={classes.rowItem}
          style={{
            flex: META[variant].premiumPercent,
            textAlign: "left",
          }}
        >
          <div>
            <SyncInput
              refresh={refresh}
              disabled={disabled}
              value={v.premiumPercent || ""}
              multiply
              name={"premiumPercent"}
              handleChange={handleChange}
              save={save}
              numeric
            />
            <br />
            <Typography variant="caption" color="textPrimary">
              Scale:
              <b>{` ${v.premiumScale}`}</b>
            </Typography>
          </div>
        </div>
      ) : (
        []
      )}

      {/* SCALE WAGES */}
      {META[variant].scaleWages ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].scaleWages }}
        >
          <SyncInput
            refresh={refresh}
            disabled={disabled || variant === "LV"}
            value={v.scaleWages || ""}
            name={"scaleWages"}
            multiply
            handleChange={handleChange}
            save={save}
            numeric
          />
        </div>
      ) : (
        []
      )}

      {/* WORK DUES */}
      {META[variant].workDues ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].workDues }}
        >
          <SyncInput
            refresh={refresh}
            disabled={disabled || variant === "LV"}
            value={v.workDues || ""}
            name={"workDues"}
            multiply
            handleChange={handleChange}
            save={save}
            numeric
          />
        </div>
      ) : (
        []
      )}

      {/* PENSION */}
      {META[variant].pension ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].pension }}
        >
          <SyncInput
            refresh={refresh}
            disabled={disabled || variant === "LV"}
            value={v.pension || ""}
            name={"pension"}
            multiply
            handleChange={handleChange}
            save={save}
            numeric
          />
        </div>
      ) : (
        []
      )}

      {/* WELFARE */}
      {META[variant].welfare ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].welfare }}
        >
          <SyncInput
            refresh={refresh}
            disabled={disabled || variant === "LV"}
            value={v.welfare || ""}
            name={"welfare"}
            multiply
            handleChange={handleChange}
            save={save}
            numeric
          />
        </div>
      ) : (
        []
      )}

      {/* NON PENSIONNABLE */}
      {META[variant].nonPensionable ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].nonPensionable }}
        >
          <SyncInput
            refresh={refresh}
            disabled={disabled}
            value={v.nonPensionable || ""}
            name={"nonPensionable"}
            multiply
            handleChange={handleChange}
            save={save}
            numeric
          />
        </div>
      ) : (
        []
      )}

      {/* OTHER PENSIONNABLE */}
      {META[variant].otherPensionable ? (
        <div
          className={classes.rowItem}
          style={{ flex: META[variant].otherPensionable }}
        >
          <SyncInput
            refresh={refresh}
            disabled={disabled}
            value={v.otherPensionable || ""}
            name={"otherPensionable"}
            multiply
            handleChange={handleChange}
            save={save}
            numeric
          />
        </div>
      ) : (
        []
      )}
      <FormMusician
        open={formMusicianOpen}
        musician={musician}
        close={() => {
          setMusicianLoading(false);
        }}
        refresh={refresh}
        refreshMusician={viewMusicianCard}
        instruments={instruments}
        musicianLoading={musicianLoading}
      />
    </div>
  );
}
