import { networkAction } from "helpers/network/networkAction";

import {
  GET_EVENT_STATUSES,
  GET_EVENT_STATUSE_BY_ID,
  DELETE_EVENT_STATUSE,
  UPDATE_EVENT_STATUSE,
  CREATE_EVENT_STATUSE,
} from "constants/eventStatuses";

import * as Api from "api";

export const getEventStatuses = () => async (dispatch) =>
  networkAction(dispatch, GET_EVENT_STATUSES, Api.getEventStatuses, []);

export const getEventStatuseById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_EVENT_STATUSE_BY_ID, Api.getEventStatuseById, [
    id,
  ]);

export const deleteEventStatuse = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_EVENT_STATUSE, Api.deleteEventStatuse, [id]);

export const createEventStatuse = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_EVENT_STATUSE, Api.createEventStatuse, [body]);

export const updateEventStatuse = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_EVENT_STATUSE, Api.updateEventStatuse, [
    id,
    body,
  ]);
