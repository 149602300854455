import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const events = `/${service}/events`;

export function getEvents() {
  const url = `${events}`;
  return {
    method: GET,
    url,
  };
}

export function getEventById(id) {
  const url = `${events}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateEvent(id, body) {
  const url = `${events}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEvent(id) {
  const url = `${events}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEvent(body) {
  const url = `${events}`;
  return {
    method: POST,
    url,
    body,
  };
}
