import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { withStyles } from "@material-ui/styles";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/fold/xml-fold";
import "codemirror/mode/css/css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/xml/xml";
import "codemirror/theme/monokai.css";
// components
import Page from "components/Page";
import { copyTextToClipboard } from "helpers";
import FileUpload from "hooks/FileUpload";
import Post from "hooks/Post";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import ReactDOM from "react-dom/server";
import fileDownload from "react-file-download";
import { Bar, Container, Section } from "react-simple-resizer";
import "./codemirror.css";
import { indentHTML } from "./indent";
// styles
import styles from "./styles";

var welcome = require("./templates/Welcome.js");

const init = {
  date: moment().utc().format(),
  relatedTitle: "relatedTitle",
  relatedSubhead: "relatedSubhead",
  relatedSubTitle: "relatedSubTitle",
  actionText: "actionText",
  users: [
    {
      firstName: "John",
      lastName: "Doe",
    },
  ],
};

class WrapperPostBuilderPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.codeMirror = React.createRef();
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      json: JSON.stringify(init, null, "\t"),
      canvas: "",
    };
  }

  componentDidMount() {
    if (this.editor === undefined) {
      const e = this.codeMirror.current.getCodeMirror();
      this.editor = e;
      e.on("cursorActivity", () => {
        // const line = this.state.editor.getLine(this.state.editor.getCursor().line);
        const from = this.editor.getCursor("from");
        const to = this.editor.getCursor("to");

        console.log(from, to);

        this.setState({
          from,
          to,
          // line,
        });
      });
      setTimeout(() => {
        e.focus();
        e.setCursor(e.lineCount(), 0);
      }, 200);
    }
  }

  async handleExport() {
    const { json, canvas } = this.state;

    try {
      const body = { ...JSON.parse(json), canvas };
      if (json) {
        fileDownload(
          JSON.stringify(body, null, "\t"),
          `Post_${moment().format("YYYY-MM-DD HH:mm:ss")}.json`
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  toolbarAction(action, options) {
    switch (action) {
      case "bold":
        this.editor.replaceSelection(
          indentHTML(ReactDOM.renderToStaticMarkup(welcome.default))
        );
        break;
      default:
        break;
    }
  }

  render() {
    const { classes } = this.props;
    const { NotificationCenter } = this.context;

    const { json, canvas } = this.state;
    let object;
    let loading = false;
    try {
      object = JSON.parse(json);
    } catch (error) {
      loading = true;
      object = {};
    }

    return (
      <Page helmet="PostBuilder" noPadding>
        <div>
          <AppBar position="absolute" style={{ background: "#424242" }}>
            <Toolbar
              style={{ display: "flex", justifyContent: "flex-end" }}
              variant="dense"
            >
              <Tooltip title="Export in JSON">
                <IconButton
                  onClick={this.handleExport.bind(this)}
                  style={{ color: "white" }}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Container
            style={{
              position: "relative",
              marginTop: 48,
              height: "calc(100vh - 48px)",
            }}
          >
            <FileUpload
              onUpload={(f) => {
                copyTextToClipboard(f);
                NotificationCenter.stack(
                  {
                    title: "Success",
                    success: true,
                    body: `Link has been copied in your clipboard`,
                    timestamp: new Date().getTime(),
                  },
                  {
                    confirm: {
                      label: "Ok",
                      level: "success",
                    },
                  }
                );
                setTimeout(NotificationCenter.hide, 2000);
              }}
            />
            <Section
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#FAFAFA",
              }}
            >
              <div
                style={{
                  width: 600,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Post loading={loading} {...object} canvas={canvas} />
              </div>
            </Section>
            <Bar size={8}></Bar>
            <Section
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Container
                vertical
                style={{
                  height: "100vh",
                  position: "relative",
                }}
              >
                <Section>
                  <CodeMirror
                    value={json}
                    options={{
                      lineNumbers: true,
                      autofocus: true,
                      mode: "javascript",
                      theme: "monokai",
                    }}
                    onChange={(e) => this.setState({ json: e })}
                    className="CustomCodemirror"
                  />
                </Section>
                <Bar size={8} />
                <Section>
                  <div className={classes.toolbar}>
                    <Grid container>
                      <Grid item>
                        <Tooltip title="Welcome Card">
                          <Button
                            className={classes.toolbarButton}
                            onClick={() => this.toolbarAction("bold")}
                          >
                            Welcome
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                  <CodeMirror
                    value={canvas}
                    options={{
                      lineNumbers: true,
                      autofocus: true,
                      theme: "monokai",
                      mode: "text/html",
                      autoCloseTags: true,
                    }}
                    ref={this.codeMirror}
                    onChange={(e) => this.setState({ canvas: e })}
                    className="CustomCodemirror"
                  />
                </Section>
              </Container>
            </Section>
          </Container>
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperPostBuilderPage);
