import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
// reactor
import Page from "components/Page";
import { REACT_APP_API } from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import csv from "./csv.png";
import pdf from "./pdf.png";
// styles
import styles from "./styles";

class WrapperSPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    urlParams: PropTypes.object,
    loading: PropTypes.bool,
    createTracker: PropTypes.func,
    token: PropTypes.string,
  };

  state = {
    t: "",
  };

  render() {
    const { classes, token, loading, history, createTracker } = this.props;

    const { t } = this.state;

    return (
      <Page
        helmet="QRCode Session"
        loadingMessage={"Loading Session"}
        loading={loading}
        noPadding
      >
        <div className={classes.container}>
          <Grid
            container
            style={{ maxWidth: 600, margin: "auto" }}
            alignItems="center"
          >
            <Grid item xs={12}>
              <img
                alt="rhapsody"
                src="https://storage.googleapis.com/rhapsody/logos/logo_main.png"
                style={{ width: "calc(100% - 40px)", maxWidth: 200 }}
              />
            </Grid>
            {token ? (
              <React.Fragment>
                <Grid item xs={12} style={{ textAlign: "left", marginTop: 20 }}>
                  <Typography variant="h5" classes={{ h5: classes.h5 }}>
                    Export form in PDF/CSV
                  </Typography>
                  <Typography color="textSecondary">
                    Choose your option:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    className={classes.paper}
                    onClick={() => {
                      createTracker({
                        token,
                        pattern: "export",
                        action: "pdf",
                      });
                      window.open(
                        `${REACT_APP_API}/rhapsody/workSessions/${token}/pdf`,
                        "_blank"
                      );
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <img alt="pdf" src={pdf} style={{ height: 40 }} />
                      </Grid>
                      <Grid item>
                        <Typography classes={{ h5: classes.h5 }} variant="h5">
                          Download PDF
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    className={classes.paper}
                    onClick={() => {
                      createTracker({
                        token,
                        pattern: "export",
                        action: "csv",
                      });
                      window.open(
                        `${REACT_APP_API}/rhapsody/workSessions/${token}/csv`,
                        "_blank"
                      );
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <img alt="csv" src={csv} style={{ height: 40 }} />
                      </Grid>
                      <Grid item>
                        <Typography classes={{ h5: classes.h5 }} variant="h5">
                          Export in CSV
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "left",
                    marginTop: 20,
                    padding: "8px 0px 8px 0px",
                  }}
                >
                  <Typography variant="h5" classes={{ h5: classes.h5 }}>
                    Find other form
                  </Typography>
                </Grid>
                <Grid item style={{ padding: "8px 0px 8px 0px", flexGrow: 1 }}>
                  <TextField
                    variant="outlined"
                    onChange={(e) => this.setState({ t: e.target.value })}
                    label="Form Identifier"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") history.push(`?t=${t}`);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  style={{ padding: "8px 0px 8px 0px", marginLeft: 16 }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push(`?t=${t}`)}
                  >
                    Find
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ padding: "8px 0px 8px 0px" }}>
                  <Typography color="textSecondary" variant="caption">
                    The code is located at the bottom right of the form. Just
                    below the QR Code. Note that the code is case sensitive.
                  </Typography>
                </Grid>
                <Grid item style={{ padding: "8px 0px 8px 0px" }}>
                  <img
                    src="https://storage.googleapis.com/rhapsody/media/code.png"
                    alt="locate-code"
                    style={{ padding: 0, height: 130, margin: "auto" }}
                    className={classes.paper}
                  />
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "left",
                    marginTop: 20,
                    padding: "8px 0px 8px 0px",
                  }}
                >
                  <Typography variant="h5" classes={{ h5: classes.h5 }}>
                    Enter the Form Identifier to Export in PDF/CSV
                  </Typography>
                </Grid>
                <Grid item style={{ padding: "8px 0px 8px 0px", flexGrow: 1 }}>
                  <TextField
                    variant="outlined"
                    onChange={(e) => this.setState({ t: e.target.value })}
                    label="Form Identifier"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") history.push(`?t=${t}`);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  style={{ padding: "8px 0px 8px 0px", marginLeft: 16 }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push(`?t=${t}`)}
                  >
                    Find
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ padding: "8px 0px 8px 0px" }}>
                  <Typography color="textSecondary" variant="caption">
                    The code is located at the bottom right of the form. Just
                    below the QR Code. Note that the code is case sensitive.
                  </Typography>
                </Grid>
                <Grid item style={{ padding: "8px 0px 8px 0px" }}>
                  <img
                    src="https://storage.googleapis.com/rhapsody/media/code.png"
                    alt="locate-code"
                    style={{ padding: 0, height: 130, margin: "auto" }}
                    className={classes.paper}
                  />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSPage);
