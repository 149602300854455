import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormProject from "components/FormProject";
// custom
import Page from "components/Page";
// components
import ProjectsList from "components/ProjectsList";
import { CUSTOMER } from "constants/routes";
import { removeURLParameter, updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperProjectPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    projects: PropTypes.array,
    createProject: PropTypes.func,
    deleteProject: PropTypes.func,
    updateProject: PropTypes.func,
    currentCompany: PropTypes.object,
    customers: PropTypes.array,
    payrolls: PropTypes.array,
    template: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedProject: undefined,
      formProjectOpen: urlParams.newProject,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.urlParams.newProject) {
      this.setState({ formProjectOpen: true });
    }
  }

  getStepContent() {
    const { index } = this.state;
    const { projects, history, currentCompany, template } = this.props;

    switch (index) {
      case 0:
        return (
          <ProjectsList
            template={template}
            history={history}
            currentCompany={currentCompany}
            projects={projects.filter((p) => !p.archived)}
            onSelect={(selectedProject) => {
              this.setState({
                selectedProject,
              });
              this.openFormProject();
            }}
          />
        );
      case 1:
        return (
          <ProjectsList
            history={history}
            currentCompany={currentCompany}
            projects={projects.filter((p) => p.archived)}
            onSelect={(selectedProject) => {
              this.setState({
                selectedProject,
              });
              this.openFormProject();
            }}
          />
        );
      case 2:
        return (
          <ProjectsList
            history={history}
            currentCompany={currentCompany}
            projects={projects}
            onSelect={(selectedProject) => {
              this.setState({
                selectedProject,
              });
              this.openFormProject();
            }}
          />
        );
      default:
    }

    return [];
  }

  openFormProject() {
    const { customers, history } = this.props;
    const { NotificationCenter } = this.context;
    if (customers.length === 0) {
      NotificationCenter.sweetAlert(
        {
          title: "Create a Customer First",
          body: "Before creating a Project, you must create its Customer.",
          timestamp: new Date().getTime(),
          warning: true,
        },
        {
          cancel: {
            label: "Cancel",
            level: "default",
            callback: () => console.log("Callback"),
          },
          confirm: {
            label: "Let's do it!",
            level: "warning",
            callback: history.push(CUSTOMER),
          },
        }
      );
    }
    this.setState({ formProjectOpen: true });
  }

  render() {
    const {
      classes,
      history,
      loading,
      createProject,
      deleteProject,
      updateProject,
      refresh,
      customers,
      payrolls,
      template,
    } = this.props;

    const { index, selectedProject, formProjectOpen } = this.state;

    return (
      <Page
        helmet="All Projects"
        loading={loading}
        loadingMessage={"Loading Projects"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label={template ? "All" : "Active"}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Archived"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              style={{ display: template && "none" }}
            />
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              style={{ display: template && "none" }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormProject
          customers={customers}
          template={template}
          history={history}
          open={formProjectOpen}
          project={selectedProject}
          close={() => {
            this.setState({
              formProjectOpen: false,
            });
            history.push({ search: removeURLParameter("newProject") });
            setTimeout(
              () =>
                this.setState({
                  selectedProject: undefined,
                }),
              200
            );
          }}
          createProject={createProject}
          deleteProject={deleteProject}
          updateProject={updateProject}
          payrolls={payrolls}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperProjectPage);
