import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import OpenInNew from "@material-ui/icons/OpenInNew";
import People from "@material-ui/icons/People";
// material-ui
import { withStyles } from "@material-ui/styles";
// constants
import { STUDIO_SESSION_DETAIL } from "constants/routes";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class StudioSessionCard extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    studioSession: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    hover: false,
  };

  render() {
    const { classes, studioSession, theme, history } = this.props;
    const { hover } = this.state;
    const color = theme.palette.primary.color[500];

    return (
      <Paper
        className={classes.container}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={() =>
          history.push(`${STUDIO_SESSION_DETAIL}?id=${studioSession.id}`)
        }
        elevation={hover ? 10 : 1}
      >
        {hover ? (
          <OpenInNew
            style={{
              position: "absolute",
              top: 4,
              right: 4,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(
                `${STUDIO_SESSION_DETAIL}?id=${studioSession.id}`,
                "_blank"
              );
            }}
          />
        ) : (
          <div />
        )}
        <Avatar
          style={{
            background: studioSession.archived ? "#9E9E9E" : color,
            color: "white",
            margin: "auto",
          }}
        >
          {studioSession.sessionTypeCode || "ND"}
        </Avatar>
        <Typography display="block" variant="h6">
          {moment
            .tz(studioSession.dateFromUTC, studioSession.tzName)
            .format("MMMM Do YYYY")}
        </Typography>
        {studioSession.agreement ? (
          <Typography display="block" variant="caption" color="textSecondary">
            Producer: {studioSession.producer}
          </Typography>
        ) : (
          <div />
        )}
        {studioSession.contractor ? (
          <Typography display="block" variant="caption" color="textSecondary">
            Producer: {studioSession.contractor}
          </Typography>
        ) : (
          <div />
        )}
        {studioSession.studioName ? (
          <Typography display="block" variant="caption" color="textSecondary">
            Studio: {studioSession.studioName}
          </Typography>
        ) : (
          <div />
        )}
        <Chip
          avatar={
            <Avatar>
              <People />
            </Avatar>
          }
          label={`${studioSession.musicianCount} Musician${
            studioSession.musicianCount > 1 ? "s" : ""
          }`}
        />
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(StudioSessionCard);
