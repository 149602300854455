import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// material-ui
import { withStyles } from "@material-ui/styles";
// helpers
import { getLatLgn, getTimezone } from "helpers";
import PhoneInput from "hooks/PhoneInput";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class FormStudio extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    studio: PropTypes.object,

    createStudio: PropTypes.func,
    updateStudio: PropTypes.func,
    deleteStudio: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      email: "",
      phone: "",
      contact: "",
      address: "",
      zipcode: "",
      city: "",
      state: "",
      latitude: "",
      longitude: "",
      tzName: "",
      infoFetched: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.studio);
    }
  }

  async getStudioMapsInfo() {
    const { address, zipcode, city, state } = this.state;
    let tzName;

    const a = `${address} ${zipcode} ${city} ${state}`;
    const coordinates = await getLatLgn(a);

    if (coordinates.lat && coordinates.lng) {
      tzName = await getTimezone(coordinates.lat, coordinates.lng);
    }

    this.state.latitude = coordinates ? `${coordinates.lat}` : undefined;
    this.state.longitude = coordinates ? `${coordinates.lng}` : undefined;
    this.state.tzName = tzName;
    this.state.infoFetched = true;
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(studio) {
    this.setState({
      loading: false,
      name: studio ? studio.name : "",
      email: studio ? studio.email : "",
      phone: studio ? studio.phone : "",
      contact: studio ? studio.contact : "",
      address: studio ? studio.address : "",
      zipcode: studio ? studio.zipcode : "",
      city: studio ? studio.city : "",
      state: studio ? studio.state : "",
      latitude: "",
      longitude: "",
      tzName: "",
      infoFetched: false,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this studio, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteStudio, close, refresh, studio } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteStudio(studio.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Studio has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateStudio, createStudio, studio, refresh, close } = this.props;

    const {
      name,
      email,
      phone,
      contact,
      address,
      zipcode,
      city,
      state,
      latitude,
      longitude,
      tzName,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (studio) {
      resp = await updateStudio(studio.id, {
        name,
        email,
        phone,
        contact,
        address,
        zipcode,
        city,
        state,
        latitude,
        longitude,
        tzName,
      });
    } else {
      resp = await createStudio({
        name,
        email,
        phone,
        contact,
        address,
        zipcode,
        city,
        state,
        latitude,
        longitude,
        tzName,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: studio ? "Studio has been updated." : "Studio has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, studio, close } = this.props;

    const {
      name,
      email,
      phone,
      contact,
      address,
      zipcode,
      city,
      state,
      loading,
      latitude,
      longitude,
      tzName,
      infoFetched,
    } = this.state;

    const disabled =
      name.length === 0 || city.length === 0 || state.length === 0;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <DialogContent>
              <div
                style={{
                  background: "#e3f2fd",
                  padding: 16,
                  borderRadius: 4,
                  textAlign: "center",
                }}
              >
                <i
                  style={{ fontSize: 80, color: "#001E3C" }}
                  class="fad fa-location-dot"
                ></i>
                <Typography variant="h6" style={{ color: "#001E3C" }}>
                  New Studio/Venue
                </Typography>
                <Typography
                  style={{ color: "#001E3C", opacity: 0.8 }}
                  color="textSecondary"
                  variant="body2"
                >
                  This is a place where work sessions take place.
                </Typography>
              </div>
              <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    autoFocus
                    helperText="Required"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    label="Email"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={this.handleChange("email")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    id="phone"
                    label="Phone"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={phone}
                    onChange={this.handleChange("phone")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="contact"
                    label="Contact"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={contact}
                    onChange={this.handleChange("contact")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="address"
                    label="Address"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={address}
                    onChange={this.handleChange("address")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="zipcode"
                    label="Zipcode"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={zipcode}
                    onChange={this.handleChange("zipcode")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="city"
                    helperText="Required"
                    label="City"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={city}
                    onChange={this.handleChange("city")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="state"
                    helperText="Required"
                    label="State"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={state}
                    onChange={this.handleChange("state")}
                  />
                </Grid>
                {infoFetched ? (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                ) : (
                  []
                )}
                {infoFetched ? (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                      {latitude},{longitude} - {tzName}
                    </Typography>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {studio && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Cancel
              </Button>
              <Button
                style={{
                  background: "#2196f3",
                  color: "white",
                  opacity: disabled ? 0.5 : 1,
                }}
                autoFocus
                disabled={disabled}
                onClick={() => {
                  this.getStudioMapsInfo().then(this.save.bind(this));
                }}
              >
                <SaveIcon className={classes.leftIcon} />
                {studio ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormStudio));
