const HEADER_HEIGHT = 50;
const HEADER_CELL_WIDTH = 200;

export default (theme) => ({
  root: { flex: 1, display: "flex", width: 0, flexDirection: "column" },
  tableHeader: {
    background: "#e0e0e0",
    fontSize: 12,
    height: 20,
    display: "flex",
    zIndex: 1010,
    alignItems: "center",
    position: "sticky",
    top: HEADER_HEIGHT,
    flexGrow: 1,
    paddingLeft: 2,
  },
  tableHeaderSec: {
    background: "#e0e0e0",
    fontSize: 12,
    height: 20,
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: HEADER_HEIGHT,
    flexGrow: 1,
    paddingLeft: 2,
  },
  header: {
    height: HEADER_HEIGHT,
    top: 0,
    position: "sticky",
    zIndex: 1005,
    left: 0,
    display: "flex",
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
  },
  musicianColumn: {
    borderRight: "solid 1px rgba(155,155,155,0.3)",
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
    minWidth: 250,
    zIndex: 999,
    background: theme.palette.type === "dark" ? "#212121" : "#ffffff",
    height: "fit-content",
    left: 0,
    position: "sticky",
  },
  engagementColumn: {
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
    height: "fit-content",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  engagementHeaderCell: {
    minWidth: HEADER_CELL_WIDTH,
    padding: 4,
    borderRight: "solid 1px rgba(155,155,155,0.3)",
    cursor: "pointer",
    background: theme.palette.type === "dark" ? "#212121" : "#ffffff",
    display: "flex",
    position: "sticky",
    left: HEADER_CELL_WIDTH,
    zIndex: 1001,
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableSplitBlock: {
    height: HEADER_HEIGHT,
    background: theme.palette.type === "dark" ? "#212121" : "#ffffff",
    color: theme.palette.type === "dark" ? "white" : "black",
    zIndex: 1099,
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
    top: 0,
    position: "sticky",
    left: 0,
  },
  table: {
    display: "flex",
    flex: 1,
    overflow: "scroll",
    alignItems: "stretch",
  },
  cell: {
    width: HEADER_CELL_WIDTH,
    padding: 4,
    height: 63,
    borderRight: "solid 1px rgba(155,155,155,0.3)",
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cellCrossed: {
    width: HEADER_CELL_WIDTH,
    padding: 4,
    height: 63,
    borderRight: "solid 1px rgba(155,155,155,0.3)",
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage:
      "repeating-linear-gradient(39deg, rgba(155,155,155,0.3) 0px, rgba(155,155,155,0.3) 4px, transparent 4px, transparent 8px, rgba(155,155,155,0.3) 8px)",
  },
  matrixContent: {
    display: "flex",
    flexWrap: "wrap",
  },
  newLine: {
    flexBasis: "100%",
    height: 0,
  },
  link: {
    padding: "0px 4px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 8,
  },
  toolbarGroup3: {
    display: "flex",
    alignItems: "center",
  },
});
