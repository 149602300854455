import moment from "moment-timezone";

export function formatDate(date, tzName) {
  return moment
    .tz(date, tzName || "America/Los_Angeles")
    .format("MM/DD/YYYY hh:mmA");
}

export function formatDateRange(dateFromUTC, dateToUTC, tzName) {
  if (dateFromUTC === dateToUTC) {
    return moment.tz(dateFromUTC, tzName).format("ll");
  }
  return `${moment.tz(dateFromUTC, tzName).format("lll")} - ${moment
    .tz(dateToUTC, tzName)
    .format("h:mm A")}`;
}
