import { networkAction } from 'helpers/network/networkAction';

import {
  GET_MUSICIAN_GROUPS,
  GET_MUSICIAN_GROUP_BY_ID,
  DELETE_MUSICIAN_GROUP,
  UPDATE_MUSICIAN_GROUP,
  CREATE_MUSICIAN_GROUP,
} from 'constants/musicianGroups';

import * as Api from 'api';

export const getMusicianGroups = () => async dispatch => networkAction(
  dispatch,
  GET_MUSICIAN_GROUPS,
  Api.getMusicianGroups,
  []
);

export const getMusicianGroupById = (id) => async dispatch => networkAction(
  dispatch,
  GET_MUSICIAN_GROUP_BY_ID,
  Api.getMusicianGroupById,
  [id]
);

export const deleteMusicianGroup = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_MUSICIAN_GROUP,
  Api.deleteMusicianGroup,
  [id]
);

export const createMusicianGroup = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_MUSICIAN_GROUP,
  Api.createMusicianGroup,
  [body]
);

export const updateMusicianGroup = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_MUSICIAN_GROUP,
  Api.updateMusicianGroup,
  [id, body]
);
