import { networkAction } from "helpers/network/networkAction";

import {
  GET_PROJECT_ALTERNATES,
  GET_PROJECT_ALTERNATE_BY_ID,
  DELETE_PROJECT_ALTERNATE,
  UPDATE_PROJECT_ALTERNATE,
  CREATE_PROJECT_ALTERNATE,
} from "constants/projectAlternates";

import * as Api from "api";

export const getProjectAlternates = () => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_ALTERNATES, Api.getProjectAlternates, []);

export const getProjectAlternateById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROJECT_ALTERNATE_BY_ID,
    Api.getProjectAlternateById,
    [id]
  );

export const deleteProjectAlternate = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_PROJECT_ALTERNATE,
    Api.deleteProjectAlternate,
    [id]
  );

export const createProjectAlternate = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_PROJECT_ALTERNATE,
    Api.createProjectAlternate,
    [body]
  );

export const updateProjectAlternate = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_PROJECT_ALTERNATE,
    Api.updateProjectAlternate,
    [id, body]
  );
