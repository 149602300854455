import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import FormLink from "components/FormLink";
import Table from "components/Table";
import TieCreator from "components/TieCreator";
import PropTypes from "prop-types";
import psl from "psl";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class StudioSessionFiles extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    studioSession: PropTypes.object,
    instrumentSections: PropTypes.array,
    instrumentFamilies: PropTypes.array,
    api: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: true,
      formLinkOpen: false,
      links: [],
    };
    this.refresh();
  }

  async refresh() {
    const { api, studioSession } = this.props;
    const resp = await api.getLinks([
      { name: "sessionID", comparison: "eq", value: studioSession.id },
    ]);
    if (resp.success) {
      this.setState({
        links: resp.payload,
        loading: false,
      });
    }
  }

  extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }

    //find & remove port number
    hostname = hostname.split(":")[0];
    //find & remove "?"
    hostname = hostname.split("?")[0];

    return hostname;
  }

  render() {
    const {
      classes,
      api,
      studioSession,
      instrumentFamilies,
      instrumentSections,
      conciergeReadOnly,
    } = this.props;
    const { links, loading, formLinkOpen, selectedLink } = this.state;

    if (loading) {
      return (
        <div
          className={classes.container}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
            <Typography color="textSecondary">Loading</Typography>
          </div>
        </div>
      );
    }

    if (links.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              <i style={{ fontSize: 70 }} class="fad fa-link"></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No Links
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Include some references about the recording for your musicians.
              <br />
              Links will be included in the emails.
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: 8 }}>
            <Chip
              onClick={() =>
                this.setState({
                  formLinkOpen: true,
                })
              }
              disabled={conciergeReadOnly}
              size="small"
              label="+ Link"
            />
          </Grid>
          <FormLink
            open={formLinkOpen}
            close={() =>
              this.setState({ formLinkOpen: false, selectedLink: undefined })
            }
            createLink={api.createLink}
            deleteLink={api.deleteLink}
            updateLink={api.updateLink}
            createTie={api.createTie}
            deleteTie={api.deleteTie}
            updateTie={api.updateTie}
            instrumentSections={instrumentSections}
            instrumentFamilies={instrumentFamilies}
            sessionID={studioSession.id}
            refresh={this.refresh.bind(this)}
            studioSession={studioSession}
          />
        </Grid>
      );
    }

    return (
      <div className={classes.container}>
        <Chip
          size="small"
          label={"+ Link"}
          onClick={() => this.setState({ formLinkOpen: true })}
          style={{ marginBottom: 16 }}
        />
        <Table
          data={links}
          meta={[
            {
              path: "id",
              render: (t, d) => (
                <img
                  alt="favicon"
                  src={`https://www.google.com/s2/favicons?domain=${psl.get(
                    this.extractHostname(d.url)
                  )}`}
                />
              ),
              hidden: { smDown: true },
              width: 20,
            },
            {
              path: "name",
              title: "Name",
              numeric: false,
            },
            {
              path: "description",
              hidden: { smDown: true },
              title: "Description",
            },
            {
              path: "global",
              title: "Ties",
              hidden: { smDown: true },
              render: (t, d) => (
                <div>
                  {d.global ? (
                    <Chip
                      label="Global"
                      size="small"
                      color="secondary"
                      onDelete={async () => {
                        await api.updateLink(d.id, { global: false });
                        this.refresh();
                      }}
                    />
                  ) : (
                    <Grid container spacing={1}>
                      {d &&
                        d.ties &&
                        d.ties.map((ti) => (
                          <Grid item>
                            <Chip
                              onDelete={async () => {
                                await api.deleteTie(ti.id);
                                this.refresh();
                              }}
                              label={
                                ti.modelName === "family"
                                  ? instrumentFamilies.find(
                                      (fa) => fa.id === ti.modelID
                                    ).name
                                  : instrumentSections.find(
                                      (se) => se.id === ti.modelID
                                    ).name
                              }
                            />
                          </Grid>
                        ))}
                      <Grid item>
                        <TieCreator
                          instrumentSections={instrumentSections}
                          instrumentFamilies={instrumentFamilies}
                          createTie={api.createTie}
                          updateLink={api.updateLink}
                          refresh={this.refresh.bind(this)}
                          link={d}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
              ),
            },
            {
              path: "url",
              render: (t, d) => (
                <Tooltip title="Open in New Tab">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(d.url, "_blank");
                    }}
                  >
                    <i class="fad fa-external-link"></i>
                  </IconButton>
                </Tooltip>
              ),
              width: 60,
            },
          ]}
          onRowSelect={(p) =>
            this.setState({
              selectedLink: p,
              formLinkOpen: true,
            })
          }
        />
        {!conciergeReadOnly ? (
          <FormLink
            open={formLinkOpen}
            close={() =>
              this.setState({ formLinkOpen: false, selectedLink: undefined })
            }
            createLink={api.createLink}
            deleteLink={api.deleteLink}
            updateLink={api.updateLink}
            createTie={api.createTie}
            deleteTie={api.deleteTie}
            updateTie={api.updateTie}
            sessionID={studioSession.id}
            instrumentSections={instrumentSections}
            instrumentFamilies={instrumentFamilies}
            link={selectedLink}
            studioSession={studioSession}
            refresh={this.refresh.bind(this)}
          />
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(StudioSessionFiles);
