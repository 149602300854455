// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperPipelinePage from "../component/WrapperPipelinePage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getPipelines: (...args) => dispatch(Actions.getPipelines(...args)),
  getPipelineById: (...args) => dispatch(Actions.getPipelineById(...args)),
  getPipelineGraphById: (...args) =>
    dispatch(Actions.getPipelineGraphById(...args)),
  deletePipeline: (...args) => dispatch(Actions.deletePipeline(...args)),
  createPipeline: (...args) => dispatch(Actions.createPipeline(...args)),
  updatePipeline: (...args) => dispatch(Actions.updatePipeline(...args)),

  getStages: (...args) => dispatch(Actions.getStages(...args)),
  getStageById: (...args) => dispatch(Actions.getStageById(...args)),
  deleteStage: (...args) => dispatch(Actions.deleteStage(...args)),
  createStage: (...args) => dispatch(Actions.createStage(...args)),
  updateStage: (...args) => dispatch(Actions.updateStage(...args)),

  getInteractors: (...args) => dispatch(Actions.getInteractors(...args)),
  getInteractorById: (...args) => dispatch(Actions.getInteractorById(...args)),
  deleteInteractor: (...args) => dispatch(Actions.deleteInteractor(...args)),
  createInteractor: (...args) => dispatch(Actions.createInteractor(...args)),
  updateInteractor: (...args) => dispatch(Actions.updateInteractor(...args)),
});
class PipelinePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    getPipelines: PropTypes.func,
    getPipelineById: PropTypes.func,
    getPipelineGraphById: PropTypes.func,
    deletePipeline: PropTypes.func,
    createPipeline: PropTypes.func,
    updatePipeline: PropTypes.func,

    getStages: PropTypes.func,
    getStageById: PropTypes.func,
    deleteStage: PropTypes.func,
    createStage: PropTypes.func,
    updateStage: PropTypes.func,

    getInteractors: PropTypes.func,
    getInteractorById: PropTypes.func,
    deleteInteractor: PropTypes.func,
    createInteractor: PropTypes.func,
    updateInteractor: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      id: urlParams.id,
      loading: true,
    };

    this.refresh();
  }

  // componentDidMount() {
  //   this.refresh();
  // }

  async refresh() {
    const { getPipelineGraphById } = this.props;
    const { id } = this.state;
    const r = await getPipelineGraphById(id);
    if (r.success) {
      this.setState({
        loading: false,
        pipeline: r.payload,
        refreshKey: new Date().getTime(),
      });
    }
  }

  render() {
    const {
      history,
      getPipelines,
      getPipelineById,
      getPipelineGraphByID,
      deletePipeline,
      createPipeline,
      updatePipeline,

      getStages,
      getStageById,
      deleteStage,
      createStage,
      updateStage,

      getInteractors,
      getInteractorById,
      deleteInteractor,
      createInteractor,
      updateInteractor,
    } = this.props;

    const { loading, pipeline, refreshKey } = this.state;

    const api = {
      refresh: this.refresh.bind(this),
      getPipelines,
      getPipelineById,
      getPipelineGraphByID,
      deletePipeline,
      createPipeline,
      updatePipeline,

      getStages,
      getStageById,
      deleteStage,
      createStage,
      updateStage,

      getInteractors,
      getInteractorById,
      deleteInteractor,
      createInteractor,
      updateInteractor,
    };

    return (
      <WrapperPipelinePage
        history={history}
        refreshKey={refreshKey}
        api={api}
        loading={loading}
        pipeline={pipeline}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PipelinePage);
