export const GET_MUSICIANS = "GET_MUSICIANS";
export const GET_MUSICIAN_BY_ID = "GET_MUSICIAN_BY_ID";
export const UPDATE_MUSICIAN = "UPDATE_MUSICIAN";
export const DELETE_MUSICIAN = "DELETE_MUSICIAN";
export const CREATE_MUSICIAN = "CREATE_MUSICIAN";
export const CREATE_MUSICIAN_INSTRUMENT = "CREATE_MUSICIAN_INSTRUMENT";
export const DELETE_MUSICIAN_INSTRUMENT = "DELETE_MUSICIAN_INSTRUMENT";
export const GET_MUSICIAN_PROJECTS = "GET_MUSICIAN_PROJECTS";
export const GET_MUSICIAN_STUDIO_SESSIONS = "GET_MUSICIAN_STUDIO_SESSIONS";
export const JOIN_REQUEST = "JOIN_REQUEST";
