// import PropTypes from 'prop-types';
// material-ui
import { Icon, IconButton, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
// styles
export default function Link({ value, onEdit, onDelete }) {
  // const [ state, setState ] = React.useState(initialValue);
  const theme = useTheme();
  const primaryColor = theme.palette.primary.color[600];

  return (
    <div
      onClick={onEdit}
      style={{
        background: "rgba(33,150,243,0.1)",
        border: "solid 1px rgba(33,150,243,0.3)",
        color: primaryColor,
        borderRadius: 4,
        padding: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <i class="fad fa-file-alt p-right"></i>
      <Typography variant="caption">{value.name}</Typography>
      <div style={{ paddingLeft: 10 }}>
        <IconButton size="small" color="inherit">
          <Icon style={{ fontSize: 14 }} className="fas fa-pencil" />
        </IconButton>
        <IconButton
          size="small"
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onDelete();
          }}
        >
          <Icon style={{ fontSize: 18 }} className="far fa-times" />
        </IconButton>
      </div>
    </div>
  );
}
