import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const sessionMedias = `/${service}/sessionMedia`;

export function getSessionMedias() {
  const url = `${sessionMedias}`;
  return {
    method: GET,
    url,
  };
}

export function getSessionMediaById(id) {
  const url = `${sessionMedias}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSessionMedia(id, body) {
  const url = `${sessionMedias}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSessionMedia(id) {
  const url = `${sessionMedias}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSessionMedia(body) {
  const url = `${sessionMedias}`;
  return {
    method: POST,
    url,
    body,
  };
}
