import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class FormSessionType extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    sessionType: PropTypes.object,

    createSessionType: PropTypes.func,
    updateSessionType: PropTypes.func,
    deleteSessionType: PropTypes.func,

    createProductionType: PropTypes.func,
    deleteProductionType: PropTypes.func,
    createSessionMedia: PropTypes.func,
    deleteSessionMedia: PropTypes.func,
    createSessionCategory: PropTypes.func,
    deleteSessionCategory: PropTypes.func,
    createSessionRate: PropTypes.func,
    deleteSessionRate: PropTypes.func,

    sessionTypeLoading: PropTypes.bool,
    refreshSessionType: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      description: "",
      code: "",
      version: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.sessionType);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(sessionType) {
    this.setState({
      loading: false,
      name: sessionType ? sessionType.name : "",
      description: sessionType ? sessionType.description : "",
      code: sessionType ? sessionType.code : "",
      version: sessionType ? sessionType.version : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this sessionType, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteSessionType, close, refresh, sessionType } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteSessionType(sessionType.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "SessionType has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateSessionType,
      createSessionType,
      sessionType,
      refresh,
      close,
    } = this.props;

    const { name, description, code, version } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (sessionType) {
      resp = await updateSessionType(sessionType.id, {
        name,
        description,
        code,
        version,
      });
    } else {
      resp = await createSessionType({
        name,
        description,
        code,
        version,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: sessionType
          ? "SessionType has been updated."
          : "SessionType has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async addProductionType() {
    const {
      createProductionType,
      sessionType,
      refreshSessionType,
    } = this.props;
    const { pendingProductionType } = this.state;
    if (pendingProductionType === "") return;
    this.setState({
      productionTypeLoading: true,
    });

    await createProductionType({
      name: pendingProductionType,
      sessionTypeID: sessionType.id,
    });

    this.setState({
      productionTypeLoading: false,
      pendingProductionType: "",
    });

    refreshSessionType(sessionType);
  }

  async addSessionMedia() {
    const { createSessionMedia, sessionType, refreshSessionType } = this.props;
    const { pendingSessionMedia } = this.state;
    if (pendingSessionMedia === "") return;
    this.setState({
      sessionMediaLoading: true,
    });

    await createSessionMedia({
      name: pendingSessionMedia,
      sessionTypeID: sessionType.id,
    });

    this.setState({
      sessionMediaLoading: false,
      pendingSessionMedia: "",
    });

    refreshSessionType(sessionType);
  }

  async addSessionCategory() {
    const {
      createSessionCategory,
      sessionType,
      refreshSessionType,
    } = this.props;
    const { pendingSessionCategory } = this.state;
    if (pendingSessionCategory === "") return;
    this.setState({
      sessionCategoryLoading: true,
    });

    await createSessionCategory({
      name: pendingSessionCategory,
      sessionTypeID: sessionType.id,
    });

    this.setState({
      sessionCategoryLoading: false,
      pendingSessionCategory: "",
    });

    refreshSessionType(sessionType);
  }

  async addSessionRate() {
    const { createSessionRate, sessionType, refreshSessionType } = this.props;
    const { pendingSessionRate } = this.state;
    if (pendingSessionRate === "") return;
    this.setState({
      sessionRateLoading: true,
    });

    await createSessionRate({
      name: pendingSessionRate,
      sessionTypeID: sessionType.id,
    });

    this.setState({
      sessionRateLoading: false,
      pendingSessionRate: "",
    });

    refreshSessionType(sessionType);
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      sessionType,
      close,
      sessionTypeLoading,
      deleteProductionType,
      deleteSessionMedia,
      deleteSessionCategory,
      deleteSessionRate,
      refreshSessionType,
    } = this.props;

    const {
      name,
      loading,
      description,
      code,
      version,
      pendingProductionType,
      productionTypeLoading,
      pendingSessionMedia,
      sessionMediaLoading,
      pendingSessionCategory,
      sessionCategoryLoading,
      pendingSessionRate,
      sessionRateLoading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        {loading || sessionTypeLoading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {sessionType
                ? `${sessionType.name}`
                : "Let's Create a New Session type"}
              {sessionType ? (
                <Typography variant="caption" color="textSecondary">
                  #{sessionType.id}
                </Typography>
              ) : (
                <div />
              )}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subhead" style={{ color: "#ffc107" }}>
                    General
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="code"
                    label="Code"
                    className={classes.textField}
                    value={code}
                    onChange={this.handleChange("code")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="version"
                    label="Version"
                    className={classes.textField}
                    value={version}
                    onChange={this.handleChange("version")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Description"
                    className={classes.textField}
                    value={description}
                    multiline
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subhead" style={{ color: "#ffc107" }}>
                    Production Types
                  </Typography>
                </Grid>
                {sessionType ? (
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.addProductionType();
                          }}
                          onBlur={this.addProductionType.bind(this)}
                          className={classes.inputBase}
                          placeholder="Add Production Type"
                          value={pendingProductionType || ""}
                          onChange={(e) =>
                            this.setState({
                              pendingProductionType: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      {sessionType.productionTypes.map((pt) => (
                        <Grid item key={`productionType_${pt.id}`}>
                          <Chip
                            label={pt.name}
                            onDelete={async () => {
                              await deleteProductionType(pt.id);
                              refreshSessionType(sessionType);
                            }}
                          />
                        </Grid>
                      ))}
                      {productionTypeLoading ? (
                        <Grid item>
                          <CircularProgress color="secondary" />
                        </Grid>
                      ) : (
                        <div />
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <div />
                )}
                <Grid item xs={12}>
                  <Typography variant="subhead" style={{ color: "#ffc107" }}>
                    Session Media
                  </Typography>
                </Grid>
                {sessionType ? (
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.addSessionMedia();
                          }}
                          onBlur={this.addSessionMedia.bind(this)}
                          className={classes.inputBase}
                          placeholder="Add Session Media"
                          value={pendingSessionMedia || ""}
                          onChange={(e) =>
                            this.setState({
                              pendingSessionMedia: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      {sessionType.sessionMedia.map((sm) => (
                        <Grid item key={`sessionMedia_${sm.id}`}>
                          <Chip
                            label={sm.name}
                            onDelete={async () => {
                              await deleteSessionMedia(sm.id);
                              refreshSessionType(sessionType);
                            }}
                          />
                        </Grid>
                      ))}
                      {sessionMediaLoading ? (
                        <Grid item>
                          <CircularProgress color="secondary" />
                        </Grid>
                      ) : (
                        <div />
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <div />
                )}
                <Grid item xs={12}>
                  <Typography variant="subhead" style={{ color: "#ffc107" }}>
                    Session Rates
                  </Typography>
                </Grid>
                {sessionType ? (
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.addSessionRate();
                          }}
                          onBlur={this.addSessionRate.bind(this)}
                          className={classes.inputBase}
                          placeholder="Add Session Rate"
                          value={pendingSessionRate || ""}
                          onChange={(e) =>
                            this.setState({
                              pendingSessionRate: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      {sessionType.sessionRates &&
                        sessionType.sessionRates.map((sr) => (
                          <Grid item key={`sessionRate_${sr.id}`}>
                            <Chip
                              label={sr.name}
                              onDelete={async () => {
                                await deleteSessionRate(sr.id);
                                refreshSessionType(sessionType);
                              }}
                            />
                          </Grid>
                        ))}
                      {sessionRateLoading ? (
                        <Grid item>
                          <CircularProgress color="secondary" />
                        </Grid>
                      ) : (
                        <div />
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <div />
                )}
                <Grid item xs={12}>
                  <Typography variant="subhead" style={{ color: "#ffc107" }}>
                    Session Categories
                  </Typography>
                </Grid>
                {sessionType ? (
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.addSessionCategory();
                          }}
                          onBlur={this.addSessionCategory.bind(this)}
                          className={classes.inputBase}
                          placeholder="Add Session Category"
                          value={pendingSessionCategory || ""}
                          onChange={(e) =>
                            this.setState({
                              pendingSessionCategory: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      {sessionType.sessionCategories.map((sm) => (
                        <Grid item key={`sessionCategory_${sm.id}`}>
                          <Chip
                            label={sm.name}
                            onDelete={async () => {
                              await deleteSessionCategory(sm.id);
                              refreshSessionType(sessionType);
                            }}
                          />
                        </Grid>
                      ))}
                      {sessionCategoryLoading ? (
                        <Grid item>
                          <CircularProgress color="secondary" />
                        </Grid>
                      ) : (
                        <div />
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <div />
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {sessionType && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {sessionType ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormSessionType));
