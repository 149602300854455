import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import GraphicEq from "@material-ui/icons/GraphicEqTwoTone";
import { withStyles } from "@material-ui/styles";
import CouponsList from "components/CouponsList";
import DatetimeGraph from "components/DatetimeGraph";
// reactor
import Page from "components/Page";
import UsagesList from "components/UsagesList";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class WrapperStatementPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    statement: PropTypes.object,
    refresh: PropTypes.func,
    generateInvoice: PropTypes.func,
    loading: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const { classes, history, loading, statement } = this.props;

    return (
      <Page
        helmet="Statement"
        loadingMessage={"Loading Statement"}
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }}>
            <Grid item>
              <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => history.goBack()}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                <Chip
                  label={`Statement #${statement ? statement.id : ""}`}
                  className={classes.chip}
                  variant="contained"
                  color="secondary"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <GraphicEq className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
      >
        <Grid container style={{ maxWidth: 1200, margin: "auto" }} spacing={2}>
          <Grid xs={12}>
            <Typography variant="h6">
              {statement
                ? moment(statement.creationDate).format("MMMM YYYY")
                : "-"}{" "}
              Statement
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <DatetimeGraph
                noHistory
                label="Payroll Form(s)"
                min={
                  statement
                    ? moment(statement.creationDate).toDate().getTime()
                    : undefined
                }
                max={
                  statement
                    ? moment(statement.creationDate)
                        .add("months", 1)
                        .subtract("days", 1)
                        .toDate()
                        .getTime()
                    : undefined
                }
                data={statement && statement.usages ? statement.usages : []}
                height={200}
                dateKey={"date"}
                dataKey={"quantity"}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <UsagesList usages={statement ? statement.usages : []} />
          </Grid>
          {statement && statement.coupons && statement.coupons.length ? (
            <Grid item xs={12}>
              <CouponsList
                coupons={statement ? statement.coupons : []}
                history={history}
                noPaper
                noTitle
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperStatementPage);
