import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Alert from "@material-ui/lab/Alert";
// import PropTypes from 'prop-types';
// material-ui
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import moment from "moment-timezone";
import React from "react";

export default function DateTime({
  dateFromUTC,
  dateToUTC,
  tzName,
  onChange,
  noTimeRange,
  error,
}) {
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [f, setF] = React.useState(
    dateFromUTC ? moment.tz(dateFromUTC, tzName) : null
  );
  const [t, setT] = React.useState(
    dateToUTC ? moment.tz(dateToUTC, tzName) : null
  );

  const handleFChange = (_f) => {
    if (!f && !t) {
      _f = _f.startOf("day").add(8, "hour");
    }
    setF(_f);
    let newT = _f.clone();
    if (!t) {
      setT(newT);
    } else {
      // We need to change the T date
      const hours = t.format("HH");
      const minutes = t.format("mm");
      newT = newT
        .startOf("day")
        .add(parseInt(hours), "hour")
        .add(parseInt(minutes), "minutes");
      setT(newT);
    }

    onChange(_f.clone().utc().format(), newT.clone().utc().format());
  };

  const handleTChange = (_t) => {
    setT(_t);
    onChange(f.clone().utc().format(), _t.clone().utc().format());
  };

  const tError = moment(t).isBefore(moment(f));

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container onClick={!tzName ? () => setSnackOpen(true) : undefined}>
        <Grid item>
          <DatePicker
            inputVariant="outlined"
            variant="inline"
            value={f}
            onChange={(_f) => handleFChange(_f.clone())}
            placeholder="MM/DD/YYYY"
            autoOk
            error={error}
            disabled={!tzName}
            showTodayButton
            size="small"
            style={{ width: 170 }}
            keyboardIcon={<CalendarTodayIcon style={{ fontSize: 20 }} />}
            KeyboardButtonProps={{ size: "small" }}
            format="MM/DD/YYYY"
          />
        </Grid>
        {!noTimeRange ? (
          <>
            <Grid item style={{ paddingLeft: "4px" }}>
              <TimePicker
                variant="inline"
                inputVariant="outlined"
                error={error}
                value={f}
                onChange={(_f) => handleFChange(_f.clone())}
                placeholder="HH:MM AM"
                autoOk
                disabled={!f || !tzName}
                showTodayButton
                size="small"
                style={{ width: 150 }}
                keyboardIcon={<ScheduleIcon style={{ fontSize: 20 }} />}
                KeyboardButtonProps={{ size: "small" }}
              />
            </Grid>
            <Grid item style={{ padding: "8px 4px" }}>
              <Typography variant="body2">to</Typography>
            </Grid>
            <Grid item style={{ paddingLeft: "4px" }}>
              <TimePicker
                variant="inline"
                error={tError || error}
                inputVariant="outlined"
                value={t}
                onChange={(_t) => handleTChange(_t.clone())}
                placeholder="HH:MM AM"
                autoOk
                disabled={!f || !tzName}
                showTodayButton
                size="small"
                style={{ width: 150 }}
                keyboardIcon={<ScheduleIcon style={{ fontSize: 20 }} />}
                KeyboardButtonProps={{ size: "small" }}
              />
            </Grid>
          </>
        ) : (
          []
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity="warning">
          Please Choose a Venue First.
        </Alert>
      </Snackbar>
    </MuiPickersUtilsProvider>
  );
}
