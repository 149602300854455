import { Checkbox, FormControlLabel } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
// import faker from 'faker';
// material-ui
import { withStyles } from "@material-ui/styles";
import * as Actions from "actions";
import CopyLabel from "hooks/CopyLabel";
import FormattedPhone from "hooks/FormattedPhone";
import GoogleContacts from "hooks/GoogleContacts";
import MusicianGroupManager from "hooks/MusicianGroupManager";
import MusicianInstrumentManager from "hooks/MusicianInstrumentManager";
import PhoneInput from "hooks/PhoneInput";
import VcfContacts from "hooks/VcfContacts";
import { isValidPhoneNumber } from "libphonenumber-js";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createMusician: (...args) => dispatch(Actions.createMusician(...args)),
  updateMusician: (...args) => dispatch(Actions.updateMusician(...args)),
  deleteMusician: (...args) => dispatch(Actions.deleteMusician(...args)),
  createMusicianInstrument: (...args) =>
    dispatch(Actions.createMusicianInstrument(...args)),
  deleteMusicianInstrument: (...args) =>
    dispatch(Actions.deleteMusicianInstrument(...args)),
  createMusicianGroup: (...args) =>
    dispatch(Actions.createMusicianGroup(...args)),
  deleteMusicianGroup: (...args) =>
    dispatch(Actions.deleteMusicianGroup(...args)),
});

class FormMusician extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    musician: PropTypes.object,

    createMusician: PropTypes.func,
    updateMusician: PropTypes.func,
    deleteMusician: PropTypes.func,
    createMusicianInstrument: PropTypes.func,
    deleteMusicianInstrument: PropTypes.func,
    createMusicianGroup: PropTypes.func,
    deleteMusicianGroup: PropTypes.func,
    refresh: PropTypes.func,
    refreshMusician: PropTypes.func,
    instruments: PropTypes.array,
    musicianLoading: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    TourCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      firstName: "",
      middleName: "",
      lastName: "",
      nickName: "",
      email: "",
      phone: "",
      avatar: "",
      companyName: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      ssn: "",
      ein: "",
      localNumber: "",
      comments: "",
      cardNumber: "",
      musicianInstruments: [],
      musicianGroups: [],
      contacts: [],
      star: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { TourCenter } = this.context;
    if (!nextProps.musicianLoading && this.props.musicianLoading) {
      this.init(nextProps.musician);
    }
    if (nextProps.open && !this.props.open) {
      this.init();
      if (nextProps.musicians?.data?.length === 0 && TourCenter) {
        TourCenter.start(3);
      }
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  init(musician) {
    this.setState({
      loading: false,
      firstName: musician && musician.firstName ? musician.firstName : "",
      middleName: musician && musician.middleName ? musician.middleName : "",
      nickName: musician && musician.nickName ? musician.nickName : "",
      lastName: musician && musician.lastName ? musician.lastName : "",
      email: musician && musician.email ? musician.email : "",
      emails: musician?.emails ?? [],
      phone: musician && musician.phone ? musician.phone : "",
      phones: musician?.phones ?? [],
      avatar: musician && musician.avatar ? musician.avatar : "",
      companyName: musician && musician.companyName ? musician.companyName : "",
      address: musician && musician.address ? musician.address : "",
      city: musician && musician.city ? musician.city : "",
      state: musician && musician.state ? musician.state : "",
      zipcode: musician && musician.zipcode ? musician.zipcode : "",
      ssn: musician && musician.ssn ? musician.ssn : "",
      ein: musician && musician.ein ? musician.ein : "",
      localNumber: musician && musician.localNumber ? musician.localNumber : "",
      comments: musician && musician.comments ? musician.comments : "",
      cardNumber: musician && musician.cardNumber ? musician.cardNumber : "",
      contactBySMS:
        musician && musician.contactBySMS ? musician.contactBySMS : false,
      musicianInstruments: [],
      musicianGroups: musician && musician.groups ? musician.groups : [],
      star: musician && musician.star ? musician.star : false,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this musician, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  fake() {
    const { instruments } = this.props;
    this.setState({
      // firstName: faker.name.firstName(),
      // avatar: faker.image.avatar(),
      // middleName: musician && musician.middleName ? musician.middleName : '',
      // nickName: musician && musician.nickName ? musician.nickName : '',
      // lastName: faker.name.lastName(),
      email: "test@wid.la",
      // phone: faker.phone.phoneNumber(),
      // companyName: faker.company.companyName(),
      // address: faker.address.streetAddress(),
      // city: faker.address.city(),
      // state: faker.address.state(),
      // zipcode: faker.address.zipCode(),
      ssn: "1234567890",
      ein: "0987654321",
      localNumber: "47",
      cardNumber: "000000",
      musicianInstruments: [
        instruments[Math.floor(Math.random() * instruments.length)],
      ],
    });
  }

  async confirmDelete() {
    const { deleteMusician, close, refresh, musician } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteMusician(musician.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Musician has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateMusician, createMusician, musician, refresh, close } =
      this.props;

    const {
      firstName,
      middleName,
      lastName,
      nickName,
      avatar,
      email,
      phone,
      companyName,
      address,
      city,
      state,
      zipcode,
      ssn,
      ein,
      localNumber,
      cardNumber,
      comments,
      musicianInstruments,
      musicianGroups,
      contactBySMS,
      contacts,
      star,
    } = this.state;

    const { NotificationCenter } = this.context;

    let schema = yup.object().shape({
      cardNumber: yup.string(),
      localNumber: yup.string(),
      ein: yup.string(),
      ssn: yup.string(),
      state: yup.string(),
      zipcode: yup.string(),
      city: yup.string(),
      address: yup.string(),
      instruments: !musician
        ? yup
            .array()
            .min(1, "Musician needs to play at least 1 instrument")
            .required()
        : yup.array(),
      contactBySMS: yup.bool(),
      star: yup.bool(),
      phone: yup
        .string()
        .test("phone", `phone format is not recognized`, (value) => {
          if (value.length === 0) return true;
          return isValidPhoneNumber(value);
        }),
      email: yup.string().email().required(),
      nickName: yup.string(),
      companyName: yup.string(),
      lastName: yup.string().min(1, "Last Name is required").required(),
      middleName: yup.string(),
      firstName: yup.string().min(1, "First Name is required").required(),
    });

    const body = {
      firstName,
      middleName,
      nickName,
      lastName,
      avatar,
      comments,
      email,
      phone,
      companyName,
      address,
      city,
      state,
      zipcode,
      ssn,
      ein,
      localNumber,
      cardNumber,
      contactBySMS,
      star,
      active: true,
      instruments: !musician ? musicianInstruments : [],
      groups: !musician ? musicianGroups : [],
    };

    this.setState({ loading: true, error: "" });
    const _this = this;
    schema
      .validate(body)
      .catch(function (err) {
        _this.setState({ error: err.errors });
        return;
      })
      .then(async (b) => {
        if (!b) return;
        let resp;
        _this.setState({ loading: true });
        if (musician) {
          resp = await updateMusician(musician.id, b);
        } else {
          resp = await createMusician({ ...b, sub: false });
          if (contacts.length > 1) {
            const _contacts = [...contacts];
            _contacts.shift();
            this.init(_contacts[0]);
            this.setState({ contacts: _contacts });
            return;
          }
        }
        if (resp.success) {
          NotificationCenter.sweetAlert({
            title: musician
              ? "Musician has been updated."
              : "Musician has been created.",
            success: true,
            timestamp: new Date().getTime(),
          });
          close();
          refresh();
          setTimeout(NotificationCenter.hide, 1500);
        }
      });

    this.setState({ loading: false });
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      musician,
      close,
      musicianLoading,
      deleteMusicianInstrument,
      instruments,
      createMusicianInstrument,
      createMusicianGroup,
      deleteMusicianGroup,
      refreshMusician,
    } = this.props;

    const {
      firstName,
      middleName,
      lastName,
      nickName,
      email,
      phone,
      companyName,
      address,
      city,
      state,
      zipcode,
      ssn,
      avatar,
      ein,
      comments,
      localNumber,
      cardNumber,
      loading,
      musicianInstruments,
      musicianGroups,
      contactBySMS,
      emails,
      phones,
      error,
      contacts,
      star,
    } = this.state;

    const { TourCenter } = this.context;

    const musicianIntrumentCollection = musician
      ? musician.instruments
      : musicianInstruments;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={close}
        onMouseDown={(e) => e.stopPropagation()}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        {loading || musicianLoading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <DialogContent>
              {musician ? (
                <div className="refID absolute lined">
                  Musician #
                  <CopyLabel label={`${musician.id}`} />
                </div>
              ) : (
                []
              )}
              <Grid container spacing={2} alignItems="center">
                {musician ? (
                  <Grid item>
                    <Avatar style={{ height: 70, width: 70 }} src={avatar} />
                  </Grid>
                ) : (
                  []
                )}

                <Grid item xs={12}>
                  {musician ? (
                    <Typography variant="h6" color="textPrimary">
                      {nickName || `${firstName} ${lastName}`}
                    </Typography>
                  ) : (
                    <>
                      <div
                        style={{
                          background: "#e3f2fd",
                          padding: 16,
                          borderRadius: 4,
                          textAlign: "center",
                        }}
                      >
                        <i
                          style={{ fontSize: 80, color: "#001E3C" }}
                          class="fad fa-user-music"
                        ></i>
                        <Typography variant="h6" style={{ color: "#001E3C" }}>
                          New Musician
                        </Typography>
                        <Typography
                          style={{ color: "#001E3C", opacity: 0.8 }}
                          variant="body2"
                        >
                          This is someone you will hire for a work session.
                        </Typography>
                      </div>
                      <div
                        data-tut="reactour__musicians"
                        style={{ display: "inline-block", marginTop: 20 }}
                      >
                        <div
                          style={{ display: "flex" }}
                          onClick={() => TourCenter && TourCenter.hide()}
                        >
                          <GoogleContacts
                            onSelect={(e) => {
                              this.setState({ contacts: e });
                              if (e.length) {
                                this.init(e[0]);
                              }
                            }}
                          />
                          <VcfContacts
                            onSelect={(e) => {
                              this.setState({ contacts: e });
                              console.log(e);
                              if (e.length) {
                                this.init(e[0]);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {musician ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<FavoriteBorder />}
                          checkedIcon={<Favorite />}
                          checked={star}
                          style={{ color: star ? "#e91e63" : "#9e9e9e" }}
                          name="checkedH"
                          onChange={this.handleCheckboxChange("star")}
                        />
                      }
                      label=""
                    />
                  ) : (
                    []
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ExpansionPanel defaultExpanded className={classes.expansion}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subheading"
                            style={{ color: "#2196f3" }}
                          >
                            Basic Information
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider style={{ width: "100%" }} />
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{ background: "rgba(155,155,155,0.1)" }}
                    >
                      <Grid container style={{ flexGrow: 1 }} spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            id="firstName"
                            helperText="Required"
                            label="First Name"
                            size="small"
                            variant="outlined"
                            fullWidth
                            autoFocus
                            value={firstName}
                            onChange={this.handleChange("firstName")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="middleName"
                            label="Middle Name"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={middleName}
                            onChange={this.handleChange("middleName")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="lastName"
                            helperText="Required"
                            label="Last Name"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={lastName}
                            onChange={this.handleChange("lastName")}
                          />
                        </Grid>
                        {/* <Grid item xs={4}>
                          <TextField
                            id="avatar"
                            helperText="Required"
                            label="avatar"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={avatar}
                            onChange={this.handleChange("avatar")}
                          />
                        </Grid> */}
                        <Grid item xs={6}>
                          <TextField
                            id="companyName"
                            label="Company Name"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={companyName}
                            onChange={this.handleChange("companyName")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="nickName"
                            label="Nickname"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={nickName}
                            onChange={this.handleChange("nickName")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="email"
                            helperText={!email && "Required"}
                            label="Email"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={email}
                            onChange={this.handleChange("email")}
                          />
                          {emails?.length > 1 ? (
                            <div>
                              <Typography
                                variant="caption"
                                style={{ fontSize: 10 }}
                              >
                                Or
                              </Typography>
                              {emails
                                .filter((e) => e !== email)
                                .map((e) => (
                                  <>
                                    <span
                                      onClick={() =>
                                        this.setState({ email: e })
                                      }
                                      style={{
                                        cursor: "pointer",
                                        background: "#e1f5fe",
                                        border: "solid 1px #03a9f4",
                                        marginLeft: 2,
                                        fontSize: 11,
                                        borderRadius: 20,
                                        padding: "0px 4px",
                                        color: "#0288d1",
                                      }}
                                    >
                                      {e}
                                    </span>{" "}
                                  </>
                                ))}
                            </div>
                          ) : (
                            []
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <PhoneInput
                            onChange={this.handleChange("phone")}
                            value={phone}
                            variant="outlined"
                            fullWidth
                            label="Phone"
                            size="small"
                          />
                          {phones?.length > 1 ? (
                            <div>
                              <Typography
                                variant="caption"
                                style={{ fontSize: 10 }}
                              >
                                Or
                              </Typography>
                              {phones
                                .filter((e) => e !== phone)
                                .map((e) => (
                                  <>
                                    <span
                                      onClick={() =>
                                        this.setState({ phone: e })
                                      }
                                      style={{
                                        cursor: "pointer",
                                        background: "#e1f5fe",
                                        border: "solid 1px #03a9f4",
                                        marginLeft: 2,
                                        fontSize: 11,
                                        borderRadius: 20,
                                        padding: "0px 4px",
                                        color: "#0288d1",
                                      }}
                                    >
                                      <FormattedPhone value={e} />
                                    </span>{" "}
                                  </>
                                ))}
                              <Typography
                                variant="caption"
                                style={{ fontSize: 10 }}
                              >
                                <i class="fad fa-exclamation-triangle p-righ p-left"></i>{" "}
                                Select the correct phone
                              </Typography>
                            </div>
                          ) : (
                            []
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={contactBySMS}
                                onChange={this.handleCheckboxChange(
                                  "contactBySMS"
                                )}
                                name="checkedB"
                                color="primary"
                                size="small"
                              />
                            }
                            label={
                              <Typography variant="caption">
                                Agree to be contacted by SMS
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                <Grid item xs={12}>
                  <ExpansionPanel defaultExpanded className={classes.expansion}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subheading"
                            style={{ color: "#2196f3" }}
                          >
                            Instruments
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider style={{ width: "100%" }} />
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{ background: "rgba(155,155,155,0.1)" }}
                    >
                      <Grid container style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>
                          <MusicianInstrumentManager
                            onAdd={async (i) => {
                              if (musician) {
                                await createMusicianInstrument(musician.id, {
                                  instrumentID: i.id,
                                  variation: i.variation,
                                });
                                // refreshMusician(musician.id);
                              } else if (i) {
                                const _musicianInstruments =
                                  musicianInstruments.slice(0);
                                _musicianInstruments.push(i);
                                this.setState({
                                  musicianInstruments: _musicianInstruments,
                                });
                              }
                            }}
                            onDelete={async (i) => {
                              if (musician) {
                                await deleteMusicianInstrument(musician.id, {
                                  instrumentID: i.id,
                                });
                                // refreshMusician(musician.id);
                              } else {
                                const index = musicianInstruments.findIndex(
                                  (mi) => mi.id === i.id
                                );
                                const _musicianInstruments =
                                  musicianInstruments.slice(0);
                                _musicianInstruments.splice(Number(index), 1);
                                this.setState({
                                  musicianInstruments: _musicianInstruments,
                                });
                              }
                            }}
                            musicianInstruments={musicianIntrumentCollection}
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                <Grid item xs={12}>
                  <ExpansionPanel defaultExpanded className={classes.expansion}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subheading"
                            style={{ color: "#2196f3" }}
                          >
                            Groups
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider style={{ width: "100%" }} />
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{ background: "rgba(155,155,155,0.1)" }}
                    >
                      <Grid container style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>
                          <MusicianGroupManager
                            onAdd={async (g) => {
                              if (musician) {
                                await createMusicianGroup({
                                  groupID: g.id,
                                  musicianID: musician.id,
                                });
                                // refreshMusician(musician.id);
                              } else if (g) {
                                const _musicianGroups = musicianGroups.slice(0);
                                _musicianGroups.push(g);
                                this.setState({
                                  musicianGroups: _musicianGroups,
                                });
                              }
                            }}
                            onDelete={async (g) => {
                              if (musician) {
                                await deleteMusicianGroup(musician.id, {
                                  GroupID: g.id,
                                });
                                // refreshMusician(musician.id);
                              } else {
                                const index = musicianGroups.findIndex(
                                  (mi) => mi.id === g.id
                                );
                                const _musicianGroups = musicianGroups.slice(0);
                                _musicianGroups.splice(Number(index), 1);
                                this.setState({
                                  musicianGroups: _musicianGroups,
                                });
                              }
                            }}
                            musicianGroups={musician?.groups}
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                <Grid item xs={12}>
                  <ExpansionPanel className={classes.expansion}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subheading"
                            style={{ color: "#2196f3" }}
                          >
                            Personal Information
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider style={{ width: "100%" }} />
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{ background: "rgba(155,155,155,0.1)" }}
                    >
                      <Grid container style={{ flexGrow: 1 }} spacing={1}>
                        <Typography variant="caption">
                          <i class="fad fa-shield p-right"></i>
                          Privacy: Musician personal information is encrypted
                          and securely stored. Only you can access and/or edit.
                        </Typography>
                        <Grid item xs={12}>
                          <TextField
                            id="address"
                            label="Address"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={address}
                            onChange={this.handleChange("address")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="city"
                            label="City"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={city}
                            onChange={this.handleChange("city")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="zipcode"
                            label="Zip Code"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={zipcode}
                            onChange={this.handleChange("zipcode")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="state"
                            label="State"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={state}
                            onChange={this.handleChange("state")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="ssn"
                            label="SSN"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={ssn}
                            onChange={this.handleChange("ssn")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="ein"
                            label="EIN"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={ein}
                            onChange={this.handleChange("ein")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="localNumber"
                            label="Local Number"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={localNumber}
                            onChange={this.handleChange("localNumber")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="cardNumber"
                            label="Card Number"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={cardNumber}
                            onChange={this.handleChange("cardNumber")}
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                <Grid item xs={12}>
                  <ExpansionPanel className={classes.expansion}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subheading"
                            style={{ color: "#2196f3" }}
                          >
                            Comments
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider style={{ width: "100%" }} />
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{ background: "rgba(155,155,155,0.1)" }}
                    >
                      <Grid container style={{ flexGrow: 1 }} spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="caption" color="textSecondary">
                            Private. Visible by you only.
                          </Typography>
                          <InputBase
                            fullWidth
                            rows={3}
                            rowsMax={100}
                            multiline
                            onBlur={() => this.save("comments")}
                            style={{ background: "rgba(255,235,59,0.1)" }}
                            className={classes.inputBase}
                            value={comments}
                            onChange={this.handleChange("comments")}
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                {error ? (
                  <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {musician && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              {contacts.length > 1 ? (
                <Typography>{contacts.length - 1} other to review</Typography>
              ) : (
                []
              )}
              <Button
                color="primary"
                variant="contained"
                autoFocus
                onClick={this.save.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} />
                {contacts.length > 1 ? "Next" : "Save"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMobileDialog()(FormMusician)));
