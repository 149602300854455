import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import { GOOGLE_MAPS_GEOCODING_KEY, GOOGLE_MAPS_TIMEZONE_KEY } from "config";
import PhoneInput from "hooks/PhoneInput";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

export default function StudioFinder({ studios, setStudios }) {
  const [predictions, setPredictions] = React.useState([]);
  const [studioInput, setStudioInput] = React.useState("");
  const [tmpStudio, setTmpStudio] = React.useState(null);
  const [studioFormOpen, setStudioFormOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [error, setError] = React.useState("");

  const theme = useTheme();
  const primaryColor = theme.palette.primary.color[600];

  const service = new window.google.maps.places.AutocompleteService(null);
  const geocoder = new window.google.maps.Geocoder();

  const userStudios = useSelector((state) => state.studios);
  const dispatch = useDispatch();

  const mobile = !useMediaQuery("(min-width:600px)");

  React.useEffect(() => {
    if (!userStudios) {
      dispatch(Actions.getStudios());
    }
  }, [userStudios]); //eslint-disable-line

  const searchStudio = (e) => {
    setStudioInput(e.target.value);
    service.getQueryPredictions(
      {
        input: e.target.value,
        componentRestrictions: { country: "us", types: ["establishment"] },
      },
      (s) => {
        setPredictions(s);
      }
    );
  };

  const getGeocodeValue = (g, type) => {
    return (
      g.address_components.find(
        (c) => c.types.findIndex((t) => t === type) !== -1
      )?.long_name ?? ""
    );
  };

  const prepareNewStudio = (s) => {
    const existingMatch = userStudios.find((us) => us.placeID === s.place_id);
    if (existingMatch) {
      setStudios([existingMatch]);
      return;
    }
    const _studio = {
      placeID: s.place_id,
      name: s?.structured_formatting?.main_text,
    };
    geocoder.geocode({ placeId: s.place_id }, async (results, status) => {
      if (status === "OK" && results.length) {
        const result = results[0];
        const lat = result.geometry.location.lat();
        const lng = result.geometry.location.lng();
        _studio.latitude = `${lat}`;
        _studio.longitude = `${lng}`;
        _studio.address = `${getGeocodeValue(
          result,
          "street_number"
        )} ${getGeocodeValue(result, "route")}`;
        _studio.city = `${getGeocodeValue(result, "locality")}`;
        _studio.state = `${getGeocodeValue(
          result,
          "administrative_area_level_1"
        )}`;
        _studio.zipcode = `${getGeocodeValue(result, "postal_code")}`;
        const query = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1458000000&key=${GOOGLE_MAPS_TIMEZONE_KEY}`;
        const r = await fetch(query, {
          method: "GET",
        });
        try {
          let j = await r.json();
          _studio.tzName = j.timeZoneId;
          setTmpStudio(_studio);
          setStudioFormOpen(true);
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log(results);
      }
    });
  };

  let filteredUserStudios =
    userStudios?.filter(
      (s) =>
        `${s.name} ${s.address} ${s.zipcode} ${s.city} ${s.state}`
          .toLowerCase()
          .indexOf(studioInput.toLowerCase()) !== -1
    ) ?? [];
  if (studioInput.length === 0)
    filteredUserStudios = filteredUserStudios.splice(0, 5);

  let schema = yup.object().shape({
    address: yup.string().required(),
    city: yup.string().required(),
    contact: yup.string(),
    email: yup.string(),
    latitude: yup.string().required(),
    longitude: yup.string().required(),
    name: yup.string().required(),
    phone: yup.string(),
    placeID: yup.string().required(),
    state: yup.string().required(),
    tzName: yup.string().required(),
    zipcode: yup.string().required(),
  });

  const doneStudio = () => {
    setError("");
    schema
      .validate(tmpStudio)
      .catch(function (err) {
        setError(err.errors);
        return;
      })
      .then((body) => {
        if (!body) return;
        if (tmpStudio.id) {
          dispatch(Actions.updateStudio(tmpStudio.id, tmpStudio)).then((r) => {
            if (r.success) {
              setStudioFormOpen(false);
              dispatch(Actions.getStudios());
            }
          });
        } else {
          dispatch(Actions.createStudio(tmpStudio)).then((r) => {
            if (r.success) {
              setStudios([r.payload]);
              dispatch(Actions.getStudios()).then(() =>
                dispatch(Actions.getStudios())
              );
              setStudioFormOpen(false);
            }
          });
        }
      });
  };

  return (
    <Grid item xs={12}>
      {studios.length === 0 ? (
        <TextField
          label="Search a Studio"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i class="fad fa-location-dot"></i>
              </InputAdornment>
            ),
            placeholder: "Examples: Capitol Records, Fox...",
          }}
          onBlur={() => setTimeout(() => setAnchorEl(null), 200)}
          size="small"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          value={studioInput}
          onChange={searchStudio}
          fullWidth
          className="textfield"
          style={{ marginBottom: 8 }}
        />
      ) : (
        []
      )}
      <Grid container spacing={1}>
        {studios.map((s, i) => (
          <Grid item xs={12}>
            <div
              style={{
                border: `solid 1px ${primaryColor}`,
                color: primaryColor,
                padding: "0px 8px",
                borderRadius: 4,
                background: "white",
                textAlign: "left",
                lineHeight: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => {
                setTmpStudio(s);
                setStudioFormOpen(true);
              }}
            >
              <div style={{ paddingRight: 4 }}>
                <Typography variant="caption" display="block">
                  {s?.name}
                </Typography>
                <Typography
                  variant="caption"
                  style={{ fontSize: 10, opacity: 0.58 }}
                >
                  {`${s?.address} ${s?.zipcode} ${s?.city} ${s?.state}`}
                </Typography>
              </div>
              <div>
                <IconButton size="small">
                  <Icon
                    style={{ color: primaryColor, fontSize: 14 }}
                    className="fas fa-pencil"
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const _s = [...studios];
                    _s.splice(i, 1);
                    setStudios(_s);
                  }}
                >
                  <Icon
                    fontSize="inherit"
                    style={{ color: primaryColor, fontSize: 18, marginLeft: 4 }}
                    className="far fa-times"
                  />
                </IconButton>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="top"
        style={{
          width: "calc(100% - 40px)",
          maxWidth: 600,
          marginBottom: 10,
          zIndex: 9999,
        }}
      >
        <Paper elevation={10}>
          <List dense style={{ maxHeight: 400, overflow: "scroll" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
                borderBottom: "solid 1px #e0e0e0",
              }}
            >
              <Typography variant="caption" color="textSecondary">
                {userStudios?.length > 1 ? "Select an exsting Studio or " : ""}
                Enter an address or studio name, city
              </Typography>
            </div>
            {filteredUserStudios?.map((p, i) => (
              <ListItem
                button
                dense
                divider
                onClick={() => {
                  setStudios([...studios, p]);
                  setStudioInput("");
                  setAnchorEl(null);
                }}
              >
                <ListItemAvatar>
                  <Avatar style={{ background: primaryColor }}>
                    <i class="fas fa-star"></i>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  key={p.id}
                  primary={p.name}
                  secondary={`${p.address} ${p.zipcode} ${p.city} ${p.state}`}
                />
              </ListItem>
            ))}
            {predictions &&
              predictions.map((p, i) => (
                <ListItem
                  tabIndex={Number(i) + 1}
                  button
                  onClick={() => prepareNewStudio(p)}
                >
                  <ListItemText key={p.place_id} primary={p.description} />
                </ListItem>
              ))}
          </List>
        </Paper>
      </Popper>
      <Dialog open={studioFormOpen} onClose={() => setStudioFormOpen(false)}>
        <DialogTitle>New Studio</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} style={{ position: "relative" }}>
                <img
                  alt="google-maps"
                  style={{ width: "100%" }}
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${
                    tmpStudio?.latitude
                  },${tmpStudio?.longitude}&zoom=15&size=${
                    mobile ? "400x200" : "400x500"
                  }&maptype=roadmap&markers=color:red%7C${
                    tmpStudio?.latitude
                  },${tmpStudio?.longitude}&key=${GOOGLE_MAPS_GEOCODING_KEY}`}
                  x
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{
                        background: "rgba(255,255,255,1)",
                        borderRadius: 4,
                      }}
                      value={tmpStudio?.name ?? ""}
                      onChange={(e) =>
                        setTmpStudio({ ...tmpStudio, name: e.target.value })
                      }
                      inputProps={{
                        style: { color: primaryColor },
                      }}
                      size="small"
                      label="Name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{
                        background: "rgba(255,255,255,1)",
                        borderRadius: 4,
                      }}
                      value={tmpStudio?.contact ?? ""}
                      onChange={(e) =>
                        setTmpStudio({
                          ...tmpStudio,
                          contact: e.target.value,
                        })
                      }
                      inputProps={{
                        style: { color: primaryColor },
                      }}
                      size="small"
                      label="Contact"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{
                        background: "rgba(255,255,255,1)",
                        borderRadius: 4,
                      }}
                      value={tmpStudio?.email ?? ""}
                      onChange={(e) =>
                        setTmpStudio({ ...tmpStudio, email: e.target.value })
                      }
                      inputProps={{
                        style: { color: primaryColor },
                      }}
                      size="small"
                      label="Email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInput
                      fullWidth
                      variant="outlined"
                      style={{
                        background: "white",
                        borderRadius: 4,
                      }}
                      value={tmpStudio?.phone ?? ""}
                      onChange={(e) =>
                        setTmpStudio({ ...tmpStudio, phone: e.target.value })
                      }
                      InputProps={{
                        style: { color: primaryColor },
                      }}
                      size="small"
                      label="Phone"
                    />
                  </Grid>
                  <Grid>
                    <Typography>
                      {tmpStudio?.address}
                      <br />
                      {tmpStudio?.zipcode} {tmpStudio?.city} {tmpStudio?.state}
                    </Typography>
                    <Typography variant="caption" style={{ fontSize: 8 }}>
                      {tmpStudio?.latitude},{tmpStudio?.longitude}
                      {" | "}
                      {tmpStudio?.placeID} {" | "} {tmpStudio?.tzName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {error ? (
                <Grid item xs={12}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setStudioFormOpen(false);
              setTmpStudio(null);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={doneStudio}
            variant="contained"
            color="primary"
            autoFocus
          >
            Use
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
