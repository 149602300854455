import { networkAction } from "helpers/network/networkAction";

import {
  GET_PROJECT_SECTIONS,
  GET_PROJECT_SECTION_BY_ID,
  DELETE_PROJECT_SECTION,
  UPDATE_PROJECT_SECTION,
  CREATE_PROJECT_SECTION,
} from "constants/projectSections";

import * as Api from "api";

export const getProjectSections = () => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_SECTIONS, Api.getProjectSections, []);

export const getProjectSectionById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROJECT_SECTION_BY_ID,
    Api.getProjectSectionById,
    [id]
  );

export const deleteProjectSection = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PROJECT_SECTION, Api.deleteProjectSection, [
    id,
  ]);

export const createProjectSection = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PROJECT_SECTION, Api.createProjectSection, [
    body,
  ]);

export const updateProjectSection = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROJECT_SECTION, Api.updateProjectSection, [
    id,
    body,
  ]);
