// import PropTypes from 'prop-types';
// material-ui
import { Grow, Paper, Typography } from "@material-ui/core";
import React from "react";
import phone from "./phone.svg";
import sms from "./sms.png";

export default function SMSPreview({ text }) {
  // const [ state, setState ] = React.useState(initialValue);

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={phone} alt="phone" style={{ width: "calc(100% - 150px)" }} />
      <Grow
        in
        timeout={{
          appear: 2000,
          enter: 1000,
          exit: 1000,
        }}
      >
        <Paper
          elevation={3}
          style={{
            position: "absolute",
            padding: 10,
            borderRadius: 10,
            boxShadow: "0px 5px 10px rgba(155,155,155,0.3)",
            width: "calc(100% - 190px)",
            top: "40%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={sms}
            alt="sms"
            style={{ height: 40, width: 40, paddingRight: 4 }}
          />
          <div>
            <Typography variant="body2" display="block">
              <b>Rhapsody</b>
            </Typography>
            <Typography variant="body2">{text}</Typography>
          </div>
        </Paper>
      </Grow>
    </div>
  );
}
