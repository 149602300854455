import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperSPage from "../component/WrapperSPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  createTracker: (...args) => dispatch(Actions.createTracker(...args)),
});
class SPage extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    createTracker: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      token: urlParams.t,
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const nextUrlParams = getJsonFromUrl(nextProps.location);
    if (nextUrlParams.t !== this.props.t) {
      this.setState({
        token: nextUrlParams.t,
      });
    }
  }

  render() {
    const { location, history, createTracker } = this.props;

    const { urlParams, token, loading } = this.state;

    return (
      <WrapperSPage
        history={history}
        location={location}
        token={token}
        urlParams={urlParams}
        createTracker={createTracker}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SPage);
