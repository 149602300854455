import { networkAction } from "helpers/network/networkAction";

import {
  GET_IDENTIFICATIONS,
  GET_IDENTIFICATION_BY_ID,
  DELETE_IDENTIFICATION,
  UPDATE_IDENTIFICATION,
  CREATE_IDENTIFICATION,
} from "constants/identifications";

import * as Api from "api";

export const getIdentifications = () => async (dispatch) =>
  networkAction(dispatch, GET_IDENTIFICATIONS, Api.getIdentifications, []);

export const getIdentificationById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_IDENTIFICATION_BY_ID, Api.getIdentificationById, [
    id,
  ]);

export const deleteIdentification = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_IDENTIFICATION, Api.deleteIdentification, [
    id,
  ]);

export const createIdentification = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_IDENTIFICATION, Api.createIdentification, [
    body,
  ]);

export const updateIdentification = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_IDENTIFICATION, Api.updateIdentification, [
    id,
    body,
  ]);
