import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/styles";
// custom
import CheckValue from "components/CheckValue";
import { REACT_APP_API } from "config/env";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class GenerateBForm extends React.Component {
  static propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    musicians: PropTypes.array,
    studioSession: PropTypes.object,
    close: PropTypes.bool,
    open: PropTypes.func,
    refresh: PropTypes.func,
  };

  state = {
    step: 0,
    agree: false,
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const {
      fullScreen,
      open,
      close,
      studioSession,
      refresh,
      musicians,
    } = this.props;
    const { step, agree } = this.state;

    let warning = false;

    for (const key in musicians) {
      if (musicians.hasOwnProperty(key)) {
        const musician = musicians[key];
        if (musician.musicianID === null || musician.musicianID === -1) {
          warning = true;
        }
      }
    }

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <div>
            <Typography variant="h6" style={{ textAlign: "center" }}>
              {studioSession.sessionType.id === 2
                ? "Is your Session performed ?"
                : "Is your Work Session recorded"}
              <br />
              <br />
              <img
                src="https://storage.googleapis.com/rhapsody/logos/form.png"
                alt="b-form icon"
                style={{
                  height: 150,
                  boxShadow: "0px 0px 80px 1px rgba(0,0,0,0.2)",
                  margin: "auto",
                }}
              />
            </Typography>
            <br />
            <Typography variant="body">
              You are about to generate the payroll form. This action will lock
              the following items:
            </Typography>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 20, marginBottom: 20 }}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <CheckValue
                  locked={agree}
                  label="Hiring"
                  warning={warning}
                  value={`${musicians.length} ${
                    musicians.length > 1 ? "Musicians" : "Musician"
                  } `}
                />
                {warning ? (
                  <Typography variant="caption" color="secondary">
                    Some Section Positions are empty
                  </Typography>
                ) : (
                  []
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textPrimary">
                  You won't be able to change, add or delete a musician in the
                  Hiring section. You will still be able to edit values in the
                  Payroll Table.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CheckValue
                  locked={agree}
                  label={
                    studioSession.sessionType.id === 2
                      ? "Performance Date"
                      : "Recording Date"
                  }
                  value={moment(studioSession.dateFromUTC).format(
                    "MM/DD/YY, H:mm:ss"
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textPrimary">
                  By generating the form, we assume that the session is
                  recorded. You won't be able to change this date.
                </Typography>
              </Grid>
            </Grid>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={agree}
                    onChange={this.handleCheckboxChange("agree")}
                    value="checkedA"
                  />
                }
                label="I Understand and I have already checked everything."
              />
            </FormGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={close}
            color={step === 1 ? "primary" : undefined}
            variant={step === 1 ? "contained" : undefined}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              window.open(
                `${REACT_APP_API}/rhapsody/workSessions/${studioSession.id}/preview`,
                "_blank"
              );
              close();
              refresh();
            }}
            color="secondary"
            variant="contained"
            autoFocus
            disabled={!agree}
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(GenerateBForm));
