import { ButtonBase, InputBase } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useRef } from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function AdjustableNumber({
  onChange,
  remove,
  add,
  value,
  background,
}) {
  const ref = useRef();
  return (
    <div style={{ display: "flex", marginLeft: 8 }}>
      <ButtonBase
        style={{
          border: "solid 1px rgba(155,155,155,0.3)",
          height: 30,
          width: 30,
          padding: 0,
          // fontSize: 12,
        }}
        disabled={!value || value === "0"}
        size="small"
        onClick={remove}
      >
        <RemoveIcon fontSize="inherit" />
      </ButtonBase>
      <InputBase
        onClick={() => ref?.current.select()}
        inputRef={ref}
        style={{
          textAlign: "center",
          color: "white",
          width: 30,
          height: 30,
          borderTop: "solid 1px rgba(155,155,155,0.3)",
          borderBottom: "solid 1px rgba(155,155,155,0.3)",
          background: value !== "0" ? background : "#BDBDBD",
          cursor: "pointer",
        }}
        inputProps={{
          style: { textAlign: "center" },
        }}
        onChange={onChange}
        fullWidth
        value={value}
      />
      <ButtonBase
        style={{
          border: "solid 1px rgba(155,155,155,0.3)",
          height: 30,
          width: 30,
          padding: 0,
        }}
        size="small"
        onClick={add}
      >
        <AddIcon fontSize="inherit" />
      </ButtonBase>
    </div>
  );
}
