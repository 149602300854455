// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperQuotesPage from "../component/WrapperQuotesPage";

const mapStateToProps = (state) => ({
  quotes: state.quotes,
});

const mapDispatchToProps = (dispatch) => ({
  getQuotes: (...args) => dispatch(Actions.getQuotes(...args)),
  getQuoteById: (...args) => dispatch(Actions.getQuoteById(...args)),
  createQuote: (...args) => dispatch(Actions.createQuote(...args)),
  deleteQuote: (...args) => dispatch(Actions.deleteQuote(...args)),
  updateQuote: (...args) => dispatch(Actions.updateQuote(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
  setOrganizationByDefault: (...args) =>
    dispatch(Actions.setOrganizationByDefault(...args)),
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
});
class QuotesPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    quotes: PropTypes.array,
    getQuotes: PropTypes.func,
    getQuoteById: PropTypes.func,
    createQuote: PropTypes.func,
    deleteQuote: PropTypes.func,
    updateQuote: PropTypes.func,
    searchUsers: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    getCurrentUser: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, quotes } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: quotes.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  componentWillReceiveProps(nextProps) {
    const nextUrlParams = getJsonFromUrl(nextProps.location);
    const urlParams = getJsonFromUrl(this.props.location);
    if (nextUrlParams.filters !== urlParams.filters) {
      this.refresh(JSON.parse(nextUrlParams.filters));
    }
  }

  async refresh(filters) {
    const { getQuotes } = this.props;
    const defaultFilters = [
      { name: "quotes.status", comparison: "like", value: "ready" },
    ];
    await Promise.all([getQuotes(filters || defaultFilters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      quotes,
      getQuoteById,
      createQuote,
      deleteQuote,
      updateQuote,
      getQuotes,
      history,
      setOrganizationByDefault,
      getCurrentUser,
      searchUsers,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperQuotesPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        quotes={quotes}
        searchUsers={searchUsers}
        getQuoteById={getQuoteById}
        createQuote={createQuote}
        deleteQuote={deleteQuote}
        updateQuote={updateQuote}
        getQuotes={getQuotes}
        setOrganizationByDefault={setOrganizationByDefault}
        getCurrentUser={getCurrentUser}
        urlParams={urlParams}
        history={history}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuotesPage);
