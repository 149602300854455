// import PropTypes from 'prop-types';
// material-ui
import { Chip, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import AutoComplete from "components/AutoComplete";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function MusicianGroupManager({
  onAdd,
  onDelete,
  musicianGroups,
}) {
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const classes = useStyles(styles);
  const groups = useSelector((state) => state.groups);
  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log("React.useEffect []");
    if (groups.length === 0) {
      dispatch(Actions.getGroups());
    }
  }, []);

  React.useEffect(() => {
    console.log("React.useEffect musicianGroups");
    setSelectedGroups(musicianGroups ?? []);
  }, [musicianGroups]);

  function handleOnChange(i) {
    console.log("handleOnChange");
    const _newSelectedGroups = [...selectedGroups, i];
    setSelectedGroups(_newSelectedGroups);
    if (onAdd) onAdd(i, _newSelectedGroups);
  }

  function handleOnDelete(i) {
    console.log("handleOnDelete");
    var _newSelectedGroups = [...selectedGroups];
    const index = _newSelectedGroups.indexOf(i);
    if (index > -1) {
      _newSelectedGroups.splice(index, 1);
    }
    setSelectedGroups(_newSelectedGroups);
    if (onDelete) onDelete(i.musicianRelationID, _newSelectedGroups);
  }

  return (
    <div className={classes.container}>
      <AutoComplete
        anchor="top"
        placeHolder={"Add Group"}
        onSelect={(i) => handleOnChange(i.value)}
        suggestions={
          groups &&
          groups
            .filter(
              (i) => selectedGroups.findIndex((si) => si.id === i.id) === -1
            )
            .map((i) => ({
              value: i,
              label: `${i.name}`,
            }))
        }
      />
      <br />
      {selectedGroups.length ? (
        <Grid container className={classes.box} spacing={1}>
          {selectedGroups.map((si, i) => (
            <Grid item className={classes.item}>
              <Chip label={si.name} onDelete={() => handleOnDelete(si)} />
            </Grid>
          ))}
        </Grid>
      ) : (
        []
      )}
    </div>
  );
}
