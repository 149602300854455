import { GET_PROJECT_SECTIONS } from "constants/projects";

export default (state = null, action) => {
  switch (action.type) {
    case `${GET_PROJECT_SECTIONS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
