import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import Countdown from "hooks/CountDown";
import moment from "moment";
import React from "react";
require("moment-countdown");

export default function ProjectWidget({ project, workSessions }) {
  const [stats, setStats] = React.useState([]);

  React.useEffect(() => {
    const _workSessions = workSessions.sort(
      (a, b) =>
        moment(a.dateFromUTC).valueOf() - moment(a.dateFromUTC).valueOf()
    );
    const now = moment();
    const _data = [];
    let _past = 0;
    let _now = 0;
    let _upComing = 0;
    for (const key in _workSessions) {
      if (Object.hasOwnProperty.call(_workSessions, key)) {
        const ws = _workSessions[key];
        const dateFrom = moment(ws.dateFromUTC);
        const dateTo = moment(ws.dateToUTC);
        const isPast = now.isAfter(dateTo);
        const isUpcoming = now.isBefore(dateFrom);
        const isNow = now.isAfter(dateFrom) && now.isBefore(dateTo);
        const starts = dateFrom.fromNow();
        const ended = dateTo.fromNow();
        console.log(
          ws.id,
          ws.dateFromUTC,
          ws.dateFromUTC,
          now.utc().format(),
          "now",
          now.isAfter(dateFrom) && now.isBefore(dateTo)
        );
        if (isPast) _past += 1;
        if (isNow) _now += 1;
        if (isUpcoming) _upComing += 1;
        _data.push({
          ws,
          isPast,
          isNow,
          starts,
          ended,
          isUpcoming,
        });
      }
    }
    setStats({ _past, _now, _upComing, _data });
  }, [project, workSessions]);

  return (
    <Paper
      style={{
        height: "calc(100% - 40px)",
        display: "flex",
        padding: 20,
      }}
    >
      <div style={{ flex: 1 }}>
        <Typography variant="h6">
          {project.name} <span style={{ opacity: 0.58 }}>contains</span>{" "}
          {workSessions?.length} Work Session
          {workSessions?.length > 1 ? "s" : ""}
        </Typography>
        <Divider />
        {stats._now ? (
          <Grid container>
            {stats._data
              .filter((s) => s.isNow)
              .map((d) => (
                <Grid item style={{ flexGrow: 1 }}>
                  <div
                    style={{
                      background: "#f44336",
                      color: "white",
                      borderRadius: 4,
                      padding: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <i
                        class="fas fa-location-dot"
                        style={{ fontSize: 26, padding: 4 }}
                      ></i>
                      <div style={{ paddingLeft: 10 }}>
                        <Typography>{d.ws.title}</Typography>
                        <Typography variant="caption">
                          @{d.ws.studioName} <br />
                          <span style={{ fontSize: 10 }}>
                            with <b>{d.ws.musicianCount} musicians</b>{" "}
                            <i class="fad fa-user-music"></i>
                          </span>
                        </Typography>
                      </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <Typography variant="overline" style={{ lineHeight: 0 }}>
                        <b>Remaining</b>
                      </Typography>
                      <Typography variant="caption">
                        <Countdown date={d.ws.dateToUTC} />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              ))}
          </Grid>
        ) : (
          []
        )}
        <div style={{ display: "flex" }}>
          {stats?._upComing ? (
            <div style={{ textAlign: "center", marginRight: 16 }}>
              <Typography variant="h6">{stats?._upComing}</Typography>
              <Typography variant="overline">Upcoming</Typography>
            </div>
          ) : (
            []
          )}
          {stats._data?.length && stats._data[0].isUpcoming ? (
            <div style={{ textAlign: "center", marginRight: 16 }}>
              <Typography variant="h6">{stats._data[0].starts}</Typography>
              <Typography variant="overline">Next Session</Typography>
            </div>
          ) : (
            []
          )}
          {stats?._past ? (
            <div style={{ textAlign: "center", marginRight: 16 }}>
              <Typography variant="h6">{stats?._past} </Typography>
              <Typography variant="overline">Completed</Typography>
            </div>
          ) : (
            []
          )}
        </div>
      </div>
    </Paper>
  );
}
