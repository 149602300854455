// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class NoRender extends Component {
  static propTypes = {
    classes: PropTypes.object,
    node: PropTypes.node,
    open: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.open) return false;

    return true;
  }

  render() {
    const { node } = this.props;

    return <>{node}</>;
  }
}

export default withStyles(styles)(NoRender);
