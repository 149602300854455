import { makeStyles } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as Actions from "actions";
// hooks
import ProjectSection from "components/ProjectSection";
// material-ui
import { move, reorder } from "helpers/rhapsody";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
// styles
import styles from "./styles";

const useStyle = makeStyles(styles);

export default function ProjectFamily({
  history,
  api,
  disabled,
  projectFamily,
  layoutPresets,
  refreshKey,
  sectionRoles,
  project,
}) {
  const [lists, setLists] = React.useState([]);
  const dispatch = useDispatch();
  const classes = useStyle(styles);

  React.useEffect(() => {
    if (projectFamily.sections) {
      const _lists = {};
      for (const key in projectFamily.sections) {
        if (Object.hasOwnProperty.call(projectFamily.sections, key)) {
          const section = projectFamily.sections[key];
          _lists[section.sectionID] = section.projectMusicians;
        }
      }
      setLists(_lists);
    }
  }, [projectFamily]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const selectedProjectMusician = lists[source.droppableId][source.index];
    const newSectionOrder = destination.index;
    const newSectionID = Number(destination.droppableId);

    dispatch(
      Actions.updateProjectAlternate(selectedProjectMusician.id, {
        sectionOrder: newSectionOrder,
        sectionID:
          destination.droppableId !== source.droppableId
            ? newSectionID
            : undefined,
      })
    ).then(api.refresh);

    if (source.droppableId === destination.droppableId) {
      // move within the same list
      const result = reorder(
        lists[source.droppableId],
        source.index,
        destination.index
      );

      let _lists = { [source.droppableId]: result };

      setLists({ ...lists, ..._lists });
    } else {
      const result = move(
        lists[source.droppableId],
        lists[destination.droppableId],
        source,
        destination
      );
      setLists({
        ...lists,
        ...result,
      });
    }
  };

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{projectFamily.name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container style={{ flexGrow: 1 }} spacing={2}>
            {projectFamily.sections &&
              projectFamily.sections.map((p) => (
                <Grid
                  key={p.id}
                  item
                  style={{
                    borderRight: "solid 1px rgba(155, 155, 155, 0.3)",
                  }}
                >
                  <ProjectSection
                    api={api}
                    history={history}
                    projectSection={p}
                    project={project}
                    layoutPresets={layoutPresets}
                    projectMusicians={lists[p.sectionID]}
                    refreshKey={refreshKey}
                    sectionRoles={sectionRoles}
                    disabled={disabled}
                  />
                </Grid>
              ))}
          </Grid>
        </DragDropContext>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
