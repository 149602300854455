import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Search from "@material-ui/icons/Search";
// material-ui
import { withStyles } from "@material-ui/styles";
// hooks
import MusicianMenuItem from "hooks/MusicianMenuItem";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class MusicianSuggestion extends PureComponent {
  static propTypes = {
    linkMusician: PropTypes.func,
    onMusicianEdit: PropTypes.func,
    close: PropTypes.func,
    loading: PropTypes.bool,
    label: PropTypes.string,
    search: PropTypes.bool,
    disabled: PropTypes.bool,
    categories: PropTypes.bool,
    sortAlphabetically: PropTypes.bool,
    theme: PropTypes.object,
    sectionRoles: PropTypes.array,
    suggestions: PropTypes.array,
    footer: PropTypes.node,
    showPrime: PropTypes.bool,
  };

  static defaultProps = {
    label: "Suggestions",
    sortAlphabetically: true,
    categories: true,
    showPrime: false,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      searchText: "",
    };
  }

  getMusicianList() {
    const {
      loading,
      suggestions,
      linkMusician,
      theme,
      footer,
      disabled,
      label,
      search,
      sortAlphabetically,
      categories,
      sectionRoles,
      onMusicianEdit,
      close,
      showPrime,
    } = this.props;
    const { searchText } = this.state;

    let list = suggestions || [];
    if (searchText) {
      list = list.filter(
        (a) =>
          `${a.firstName} ${a.nickName} ${a.lastName}`
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
      );
    }

    const onDeck = list.filter((m) => m.onDeck);
    const settled = list.filter((m) => m.settled);
    const other = list.filter((m) => !m.settled && !m.onDeck);

    if (loading) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ width: showPrime ? 320 : 280, padding: 10, height: 400 }}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
            <Typography variant="h6" color="textSecondary">
              Loading Suggestions
            </Typography>
            <Typography color="textSecondary">
              Checking for musicians compatible with this position
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <div
        style={{
          width: showPrime ? 320 : 280,
          display: "flex",
          flexWrap: "wrap",
          alignContent: "start",
          height: 400,
          overflow: "scroll",
          alignItems: "start",
          justifyContent: "start",
        }}
      >
        <div style={{ flex: 1, padding: "10px 10px 0px 10px" }}>
          <Typography variant="h6" color="textPrimary">
            {label}
          </Typography>
        </div>
        <div style={{ flexBasis: "100%", height: 0 }} />
        {search ? (
          <div
            style={{
              position: "sticky",
              alignSelf: "flex-start",
              padding: "5px 10px 10px 10px",
              top: 0,
              flex: 1,
              zIndex: 99999,
              background: theme.palette.type === "dark" ? "#424242" : "white",
            }}
          >
            <InputBase
              style={{
                background: "rgba(155,155,155,0.1)",
                borderRadius: 40,
                padding: "0px 8px",
              }}
              onChange={(e) => this.setState({ searchText: e.target.value })}
              fullWidth
              autoFocus
              startAdornment={
                <Search style={{ color: "rgba(155,155,155,0.8)" }} />
              }
              placeholder="Search..."
            />
          </div>
        ) : (
          []
        )}
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div style={{ flex: 1 }}>
          <List dense style={{ padding: 0 }}>
            {categories ? (
              <div
                style={{
                  position: "sticky",
                  top: search ? 42 : 0,
                  background:
                    theme.palette.type === "dark" ? "#616161" : "#f5f5f5",
                  padding: 4,
                  zIndex: 9999,
                }}
              >
                <Typography variant="subtitle2" color="textSecondary">
                  On Deck
                </Typography>
              </div>
            ) : (
              []
            )}
            {onDeck.length === 0 && categories ? (
              <ListItem divider>
                <ListItemText style={{ textAlign: "center" }}>
                  No Musicians
                </ListItemText>
              </ListItem>
            ) : (
              []
            )}
            {onDeck.map((m) => (
              <MusicianMenuItem
                key={`musicians_${m.id}`}
                linkMusician={linkMusician}
                m={m}
                showPrime={showPrime}
                disabled={disabled}
                sectionRoles={sectionRoles}
                musicianDetails={disabled}
                close={close}
                onMusicianEdit={onMusicianEdit}
              />
            ))}
            {categories ? (
              <div
                style={{
                  position: "sticky",
                  top: search ? 42 : 0,
                  background:
                    theme.palette.type === "dark" ? "#616161" : "#f5f5f5",
                  padding: 4,
                  zIndex: 9999,
                }}
              >
                <Typography variant="subtitle2" color="textSecondary">
                  Settled
                </Typography>
              </div>
            ) : (
              []
            )}
            {settled.length === 0 && categories ? (
              <ListItem divider>
                <ListItemText style={{ textAlign: "center" }}>
                  No Musicians
                </ListItemText>
              </ListItem>
            ) : (
              []
            )}
            {settled.map((m) => (
              <MusicianMenuItem
                key={`musicians_${m.id}`}
                linkMusician={linkMusician}
                disabled={disabled}
                showPrime={showPrime}
                m={m}
                sectionRoles={sectionRoles}
                musicianDetails={disabled}
                close={close}
                onMusicianEdit={onMusicianEdit}
              />
            ))}
          </List>
        </div>
        <div style={{ flexBasis: "100%", height: 0 }} />
        {categories ? (
          <div
            style={{
              flex: 1,
              position: "sticky",
              top: search ? 42 : 0,
              background: theme.palette.type === "dark" ? "#616161" : "#f5f5f5",
              padding: 4,
              zIndex: 9999,
            }}
          >
            <Typography variant="subtitle2" color="textSecondary">
              More Musicians
            </Typography>
          </div>
        ) : (
          []
        )}
        {other.length === 0 && categories ? (
          <ListItem divider>
            <ListItemText style={{ textAlign: "center" }}>
              No Musicians
            </ListItemText>
          </ListItem>
        ) : (
          []
        )}
        {other.map((m) => (
          <MusicianMenuItem
            key={`musicians_${m.id}`}
            linkMusician={linkMusician}
            disabled={disabled}
            showPrime={showPrime}
            m={m}
            sectionRoles={sectionRoles}
            musicianDetails={disabled}
            close={close}
            onMusicianEdit={onMusicianEdit}
          />
        ))}
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            flex: 1,
            padding: 8,
          }}
        >
          {footer}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Paper
        elevation={18}
        data-tut="reactour__musicianList"
        style={{
          zIndex: 9999999999,
        }}
      >
        {this.getMusicianList()}
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MusicianSuggestion);
