import { DELETE, GET, POST, PUT } from "constants/methods";

const museService = "muse";
const rhapsodyService = "rhapsody";
const museSections = `/${museService}/sections`;
const instrumentSections = `/${rhapsodyService}/instrumentSections`;

export function getInstrumentSections() {
  const url = `${museSections}`;
  return {
    method: GET,
    url,
  };
}

export function getMusicianInstrumentForSection(sectionID, musicianID) {
  const url = `${instrumentSections}/${sectionID}/instruments?musicianID=${musicianID}`;
  return {
    method: GET,
    url,
  };
}

export function getInstrumentSectionById(id) {
  const url = `${museSections}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getInstrumentSectionMusicians(id, sessionID, sectionRoleID) {
  const url = `${instrumentSections}/${id}/musicians?sessionID=${sessionID}${
    sectionRoleID ? `&sectionRoleID=${sectionRoleID}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getInstrumentSectionProjectMusicians(id, projectID) {
  const url = `${instrumentSections}/${id}/alternates?projectID=${projectID}`;
  return {
    method: GET,
    url,
  };
}

export function updateInstrumentSection(id, body) {
  const url = `${museSections}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInstrumentSection(id) {
  const url = `${museSections}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInstrumentSection(body) {
  const url = `${museSections}`;
  return {
    method: POST,
    url,
    body,
  };
}
