// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import TableEdit from "../TableEdit";
// styles
import styles from "./styles";

class InstrumentsList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    instruments: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedInstrument) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedInstrument);
    }
  }

  render() {
    const { instruments } = this.props;

    return (
      <Table
        data={instruments}
        meta={[
          {
            path: "id",
            title: "ID",
            width: 30,
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "id",
            numeric: false,
            component: TableEdit,
            inject: {
              onEdit: this.onSelect.bind(this),
            },
            width: 30,
          },
        ]}
        title={"Instruments"}
        onRowSelect={() => console.log("Clicked")}
      />
    );
  }
}

export default withStyles(styles)(InstrumentsList);
