import { Button, Grid, Typography } from "@material-ui/core";
import DirectionsIcon from "@material-ui/icons/Directions";
import React from "react";

export default (
  <Grid
    container
    spacing={2}
    alignItems="center"
    style={{ background: "#ff9800", color: "white" }}
  >
    <Grid item xs={6} style={{ textAlign: "center" }}>
      <img
        alt="musician"
        src="https://storage.googleapis.com/rhapsody-test/data-upload/9495a805-131d-49fa-96bf-8c455dc2fed2/musicians-03.png"
        height="180"
      />
    </Grid>
    <Grid item xs={6}>
      <Typography variant="h5">Welcome to Rhapsody!</Typography>
      <Typography variant="body2" color="textSecondary">
        We are so glad you just joined.
      </Typography>
      <Button
        variant="outlined"
        size="small"
        style={{
          color: "white",
          borderColor: "white",
          textTransform: "none",
          marginTop: 12,
        }}
      >
        Take the tour <DirectionsIcon />
      </Button>
    </Grid>
  </Grid>
);
