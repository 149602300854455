import { Icon, Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import PropTypes from 'prop-types';
// material-ui
import { useTheme } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import * as Actions from "actions";
import { PROJECT, PROJECT_DETAIL } from "constants/routes";
import { getJsonFromUrl } from "helpers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function ProjectSelect({ history, open, close }) {
  const [searchText, setSearchText] = React.useState("");
  const urlParams = getJsonFromUrl(window.location);
  const projects = useSelector((state) => state.projects);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open && projects.length === 0) {
      dispatch(Actions.getProjects());
    }
  }, [open]);

  if (projects.filter((p) => !p.archived).length === 0) {
    return (
      <Dialog onClose={close} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">
          <Grid container justify="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Icon className="fad fa-folder-open" style={{ fontSize: 80 }} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              You need a project to create a work session.
              <Typography variant="body2" color="textSecondary">
                Why don't you create one now?
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                label="Let's do it!"
                color="secondary"
                onClick={() => history.push(`${PROJECT}?newProject`)}
              />
            </Grid>
          </Grid>
        </DialogTitle>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={close} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        <Grid container justify="space-between">
          <Grid item>For which project?</Grid>
          <Grid item>
            <Chip
              label="+ Project"
              size="small"
              onClick={() =>
                history.push(
                  `${PROJECT}?newProject${
                    urlParams.standalone ? "&standalone" : ""
                  }`
                )
              }
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <div
        style={{
          position: "sticky",
          alignSelf: "flex-start",
          padding: "5px 10px 10px 10px",
          width: "calc(100% - 20px)",
          top: 0,
          flex: 1,
          zIndex: 99999,
          background: theme.palette.type === "dark" ? "#424242" : "white",
        }}
      >
        <InputBase
          style={{
            background: "rgba(155,155,155,0.1)",
            borderRadius: 40,
            padding: "0px 8px",
          }}
          onChange={(e) => setSearchText(e.target.value)}
          fullWidth
          value={searchText}
          autoFocus
          startAdornment={<Search style={{ color: "rgba(155,155,155,0.8)" }} />}
          placeholder="Search..."
        />
      </div>
      <List style={{ height: "50vh", overflow: "scroll", width: 600 }}>
        {projects
          .filter((p) => !p.archived && !p.template)
          .filter(
            (a) =>
              `${a.name} ${a.customerName}`
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1
          )
          .map((p) => (
            <ListItem
              key={p.id}
              button
              divider
              onClick={() => {
                history.push(
                  `${PROJECT_DETAIL}?id=${p.id}&index=1&workSessionScheduler${
                    urlParams.standalone ? "&standalone" : ""
                  }`
                );
              }}
              style={{ borderLeft: `solid 4px ${p.color}` }}
            >
              <ListItemText primary={p.name} secondary={p.customerName} />
            </ListItem>
          ))}
      </List>
    </Dialog>
  );
}
