import { networkAction } from "helpers/network/networkAction";

import {
  GET_SECTION_ROLES,
  GET_SECTION_ROLE_BY_ID,
  DELETE_SECTION_ROLE,
  UPDATE_SECTION_ROLE,
  CREATE_SECTION_ROLE,
} from "constants/sectionRoles";

import * as Api from "api";

export const getSectionRoles = () => async (dispatch) =>
  networkAction(dispatch, GET_SECTION_ROLES, Api.getSectionRoles, []);

export const getSectionRoleById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SECTION_ROLE_BY_ID, Api.getSectionRoleById, [id]);

export const deleteSectionRole = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SECTION_ROLE, Api.deleteSectionRole, [id]);

export const createSectionRole = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SECTION_ROLE, Api.createSectionRole, [body]);

export const updateSectionRole = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SECTION_ROLE, Api.updateSectionRole, [
    id,
    body,
  ]);
