export const GET_STUDIO_SESSIONS = "GET_STUDIO_SESSIONS";
export const GET_STUDIO_SESSION_BY_ID = "GET_STUDIO_SESSION_BY_ID";
export const UPDATE_STUDIO_SESSION = "UPDATE_STUDIO_SESSION";
export const DELETE_STUDIO_SESSION = "DELETE_STUDIO_SESSION";
export const CREATE_STUDIO_SESSION = "CREATE_STUDIO_SESSION";
export const GET_STUDIO_SESSION_MUSICIANS = "GET_STUDIO_SESSION_MUSICIANS";
export const GET_STUDIO_SESSION_TUNES = "GET_STUDIO_SESSION_TUNES";
export const GET_STUDIO_SESSION_REHEARSALS = "GET_STUDIO_SESSION_REHEARSALS";
export const CREATE_STUDIO_SESSION_CATEGORY = "CREATE_STUDIO_SESSION_CATEGORY";
export const DELETE_STUDIO_SESSION_CATEGORY = "DELETE_STUDIO_SESSION_CATEGORY";
export const CALCULATE_PENSION_WELFARE = "CALCULATE_PENSION_WELFARE";
export const GET_STUDIO_SESSION_SECTIONS = "GET_STUDIO_SESSION_SECTIONS";
export const GET_STUDIO_SESSION_EVENTS = "GET_STUDIO_SESSION_EVENTS";
export const GET_STUDIO_SESSION_INVITES = "GET_STUDIO_SESSION_INVITES";
export const ACKNOWLEDGE_STUDIO_SESSION_INVITE =
  "ACKNOWLEDGE_STUDIO_SESSION_INVITE";
export const GET_STUDIO_SESSION_IDENTIFICATION =
  "GET_STUDIO_SESSION_IDENTIFICATION";
export const GET_STUDIO_SESSION_ENGAGEMENTS = "GET_STUDIO_SESSION_ENGAGEMENTS";
export const NOTIFY = "NOTIFY";
export const NOTIFY_PREVIEW = "NOTIFY_PREVIEW";
export const PROCESS_BAZAAR = "PROCESS_BAZAAR";
export const GET_STUDIO_SESSION_STATUSES = "GET_STUDIO_SESSION_STATUSES";
export const DUPLICATE_SESSION = "DUPLICATE_SESSION";
export const GET_STUDIO_SESSION_DASHBOARD = "GET_STUDIO_SESSION_DASHBOARD";
export const GET_ACTIVE_STUDIO_SESSIONS = "GET_ACTIVE_STUDIO_SESSIONS";
export const GET_ARCHIVED_STUDIO_SESSIONS = "GET_ARCHIVED_STUDIO_SESSIONS";
export const STUDIO_SESSION_AUTOFILL = "STUDIO_SESSION_AUTOFILL";
export const GET_STUDIO_SESSION_MERCURY = "GET_STUDIO_SESSION_MERCURY";
export const UPDATE_WORK_SESSION_MERCURY = "UPDATE_WORK_SESSION_MERCURY";
export const PROCESS_METADATA = "PROCESS_METADATA";
export const GET_AGNOSTIC_FORM_DATA = "GET_AGNOSTIC_FORM_DATA";
export const GET_STUDIO_SESSION_LAYOUT = "GET_STUDIO_SESSION_LAYOUT";
