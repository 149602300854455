import { Chip } from "@material-ui/core";
import React from "react";
import { useDropzone } from "react-dropzone";
import vCard from "vcf";

export default function VcfContacts({ onSelect }) {
  // const [ state, setState ] = React.useState(initialValue);

  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        var cards = vCard.parse(binaryStr);
        const contacts = [];
        for (const key in cards) {
          if (Object.hasOwnProperty.call(cards, key)) {
            const card = cards[key];
            try {
              const tmpContact = {};
              // emails
              const emailProperties = card.get("email");
              const _emails = [];
              if (Array.isArray(emailProperties)) {
                for (const key in emailProperties) {
                  if (Object.hasOwnProperty.call(emailProperties, key)) {
                    const _email = emailProperties[key];
                    _emails.push(_email._data);
                  }
                }
                tmpContact.emails = _emails;
                tmpContact.email = _emails.length ? _emails[0] : "";
              } else if (emailProperties) {
                tmpContact.email = emailProperties._data;
              }

              // phones
              const telProperties = card.get("tel");
              const _phones = [];
              if (Array.isArray(telProperties)) {
                for (const key in telProperties) {
                  if (Object.hasOwnProperty.call(telProperties, key)) {
                    const _phone = telProperties[key]._data;
                    _phones.push(_phone);
                  }
                }
                tmpContact.phones = _phones;
                tmpContact.phone = _phones.length ? _phones[0] : "";
              } else if (telProperties) {
                tmpContact.phone = telProperties._data;
              }

              // firstname, last name
              const fn = card.get("fn");
              const _names = fn._data.split(" ");
              if (_names.length) {
                tmpContact.firstName = _names[0];
              }
              if (_names.length >= 2) {
                tmpContact.lastName = _names[1];
              }

              // address
              const addressProperties = card.get("adr");
              let mainAdr;
              if (Array.isArray(addressProperties)) {
                mainAdr = addressProperties[0]._data;
              } else if (addressProperties) {
                mainAdr = addressProperties._data;
              }
              const splitAdr = mainAdr?.split(";");
              console.log(mainAdr, splitAdr);
              try {
                tmpContact.address = splitAdr[2];
                tmpContact.city = splitAdr[3];
                tmpContact.zipcode = splitAdr[5];
                tmpContact.state = splitAdr[6];
              } catch {
                console.log("Address not parsed");
              }

              contacts.push(tmpContact);
            } catch (error) {
              console.log(error);
            }
            onSelect(contacts);
          }
        }
      };
      reader.readAsText(file);
    });
    // Do something with the files
  }, []); //eslint-disable-line

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Chip
        size="small"
        style={{
          background: "#e0e0e0",
          color: "#212121",
          marginLeft: 4,
        }}
        label={
          <span>
            <i class="fas fa-id-card-alt p-right"></i>upload vcf
          </span>
        }
      />
    </div>
  );
}
