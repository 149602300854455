// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperProjectPage from "../component/WrapperProjectPage";

const mapStateToProps = (state) => ({
  projects: state.projects,
  customers: state.customers,
  payrolls: state.payrolls,
  currentCompany: state.currentCompany,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: (...args) => dispatch(Actions.getProjects(...args)),
  getPayrolls: (...args) => dispatch(Actions.getPayrolls(...args)),
  getProjectById: (...args) => dispatch(Actions.getProjectById(...args)),
  createProject: (...args) => dispatch(Actions.createProject(...args)),
  deleteProject: (...args) => dispatch(Actions.deleteProject(...args)),
  updateProject: (...args) => dispatch(Actions.updateProject(...args)),
  getCustomers: (...args) => dispatch(Actions.getCustomers(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),
});
class ProjectPage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    projects: PropTypes.array,
    getProjects: PropTypes.func,
    getProjectById: PropTypes.func,
    createProject: PropTypes.func,
    deleteProject: PropTypes.func,
    updateProject: PropTypes.func,

    customers: PropTypes.array,
    payrolls: PropTypes.array,
    getPayrolls: PropTypes.func,
    getCurrentCompany: PropTypes.func,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, projects } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: projects.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getProjects, getCustomers, getPayrolls, getCurrentCompany } =
      this.props;
    await Promise.all([
      getProjects(),
      getCustomers(),
      getPayrolls(),
      getCurrentCompany(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      projects,
      getProjectById,
      createProject,
      deleteProject,
      updateProject,
      customers,
      history,
      payrolls,
      currentCompany,
    } = this.props;

    const { loading } = this.state;

    return (
      <WrapperProjectPage
        history={history}
        refresh={this.refresh.bind(this)}
        customers={customers}
        loading={loading}
        projects={projects.filter((p) => !p.template)}
        getProjectById={getProjectById}
        payrolls={payrolls}
        createProject={createProject}
        deleteProject={deleteProject}
        updateProject={updateProject}
        currentCompany={currentCompany}
        urlParams={getJsonFromUrl(window.location)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
