import Chip from "@material-ui/core/Chip";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormInstrumentFamilie from "components/FormInstrumentFamilie";
// custom
import InstrumentFamiliesList from "components/InstrumentFamiliesList";
// reactor
import Page from "components/Page";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperInstrumentFamiliePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    instrumentFamilies: PropTypes.array,
    createInstrumentFamily: PropTypes.func,
    deleteInstrumentFamily: PropTypes.func,
    updateInstrumentFamily: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedInstrumentFamilie: undefined,
      formInstrumentFamilieOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { instrumentFamilies } = this.props;

    switch (index) {
      case 0:
        return (
          <InstrumentFamiliesList
            instrumentFamilies={instrumentFamilies}
            onSelect={(selectedInstrumentFamilie) =>
              this.setState({
                selectedInstrumentFamilie,
                formInstrumentFamilieOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createInstrumentFamily,
      deleteInstrumentFamily,
      updateInstrumentFamily,
      refresh,
    } = this.props;

    const {
      index,
      selectedInstrumentFamilie,
      formInstrumentFamilieOpen,
    } = this.state;

    return (
      <Page
        helmet="InstrumentFamilies"
        loading={loading}
        loadingMessage={"Fetching all InstrumentFamilies"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div>
          <Chip
            onClick={() => this.setState({ formInstrumentFamilieOpen: true })}
            color="secondary"
            label="+ Instrument Family"
            style={{ marginBottom: 8 }}
          />
          {this.getStepContent()}
        </div>
        <FormInstrumentFamilie
          open={formInstrumentFamilieOpen}
          instrumentFamilie={selectedInstrumentFamilie}
          close={() => {
            this.setState({
              formInstrumentFamilieOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedInstrumentFamilie: undefined,
                }),
              200
            );
          }}
          createInstrumentFamily={createInstrumentFamily}
          deleteInstrumentFamily={deleteInstrumentFamily}
          updateInstrumentFamily={updateInstrumentFamily}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperInstrumentFamiliePage);
