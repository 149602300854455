import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Portal from "@material-ui/core/Portal";
import Slider from "@material-ui/core/Slider";
// material-ui
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import Left from "@material-ui/icons/KeyboardArrowLeft";
import Right from "@material-ui/icons/KeyboardArrowRight";
import Settings from "@material-ui/icons/Settings";
import { withStyles } from "@material-ui/styles";
// custom
import FormInstrumentSection from "components/FormInstrumentSection";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class WrapperInstrumentConfiguratorPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    loading: PropTypes.bool,
    instruments: PropTypes.array,
    instrumentSections: PropTypes.array,
    instrumentFamilies: PropTypes.array,
    createInstrumentSection: PropTypes.func,
    createSectionInstrument: PropTypes.func,
    deleteSectionInstrument: PropTypes.func,
    updateInstrument: PropTypes.func,
    refreshKey: PropTypes.number,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
    KeyboardEventCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { MessageCenter, KeyboardEventCenter } = this.context;
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
    MessageCenter.open("bottom");
    KeyboardEventCenter.listen("left", this.keyboardEvent.bind(this));
    KeyboardEventCenter.listen("right", this.keyboardEvent.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.refreshKey === undefined && nextProps.refreshKey) {
      this.setState({
        instrumentIndex: 0,
        tmpInstrumentName: nextProps.instruments[0].name,
      });
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  keyboardEvent(e) {
    const { instruments } = this.props;
    const { instrumentIndex } = this.state;
    switch (e.shortcut) {
      case "left":
        if (instrumentIndex > 0) {
          this.setState({
            instrumentIndex: instrumentIndex - 1,
            tmpInstrumentName: instruments[instrumentIndex - 1].name,
          });
        }
        break;
      case "right":
        if (instrumentIndex < instruments.length - 1) {
          this.setState({
            instrumentIndex: instrumentIndex + 1,
            tmpInstrumentName: instruments[instrumentIndex + 1].name,
          });
        }
        break;
      default:
        break;
    }
  }

  async deleteSectionInstrument(instrument, sectionID) {
    const { deleteSectionInstrument, refresh } = this.props;
    const toDel = instrument.sections.find((s) => (s.id = sectionID));

    await deleteSectionInstrument(toDel.relationID);
    refresh();
  }

  async createSectionInstrument(instrument, sectionID) {
    const { createSectionInstrument, refresh } = this.props;
    await createSectionInstrument({
      instrumentID: instrument.id,
      sectionID,
    });
    refresh();
  }

  async renameInstrument() {
    const { updateInstrument, instruments, refresh } = this.props;
    const { tmpInstrumentName, instrumentIndex } = this.state;
    const instrument = instruments[instrumentIndex];

    await updateInstrument(instrument.id, { name: tmpInstrumentName });
    refresh();
  }

  render() {
    const {
      classes,
      loading,
      instrumentFamilies,
      instrumentSections,
      createInstrumentSection,
      instruments,
      refresh,
      refreshKey,
    } = this.props;

    const {
      instrumentIndex,
      formInstrumentSectionOpen,
      selectedFamily,
      tmpInstrumentName,
    } = this.state;

    const { MessageCenter } = this.context;

    const selectedInstrument = instruments[instrumentIndex];
    const sections = {};

    selectedInstrument &&
      selectedInstrument.sections &&
      selectedInstrument.sections.forEach((s) => (sections[s.id] = 1));

    return (
      <Page
        helmet="Instrument Configurator"
        loadingMessage={"Loading Interface"}
        loading={loading}
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Settings fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h4" style={{ fontWeight: 600 }}>
              Configurator
            </Typography>
            <Typography variant="body" color="textSecondary">
              For each instrument, choose the correct section.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid container spacing={2} key={refreshKey}>
          {instrumentFamilies &&
            instrumentFamilies.map((f) => (
              <Grid
                item
                key={`instrumentFamily_${f.id}`}
                style={{
                  borderRight: "solid 1px rgba(155, 155, 155, 0.3)",
                }}
              >
                <Typography variant="body" style={{ fontWeight: 600 }}>
                  {f.name}
                </Typography>
                {instrumentSections &&
                  instrumentSections
                    .filter((s) => s.familyID === f.id)
                    .map((s) => (
                      <div
                        style={{ marginTop: 4 }}
                        key={`instrumentSection_${s.id}_${instrumentIndex}`}
                      >
                        <Chip
                          label={s.name}
                          color={sections[s.id] ? "primary" : "default"}
                          onClick={
                            sections[s.id]
                              ? () =>
                                  this.deleteSectionInstrument(
                                    selectedInstrument,
                                    s.id
                                  )
                              : () =>
                                  this.createSectionInstrument(
                                    selectedInstrument,
                                    s.id
                                  )
                          }
                        />
                      </div>
                    ))}
                <div style={{ marginTop: 4 }}>
                  <Chip
                    className={classes.chip}
                    label="New"
                    variant="outlined"
                    onClick={() =>
                      this.setState({
                        selectedFamily: f,
                        formInstrumentSectionOpen: true,
                      })
                    }
                    avatar={
                      <Avatar style={{ background: "none" }}>
                        <Add />
                      </Avatar>
                    }
                  />
                </div>
              </Grid>
            ))}
        </Grid>
        <Portal container={MessageCenter.getPortalContainer("bottom")}>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.container}
          >
            <Grid item>
              <IconButton
                color="inherit"
                onClick={() => this.keyboardEvent({ shortcut: "left" })}
              >
                <Left />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography color="inherit">Instrument</Typography>
              {instrumentIndex !== undefined ? (
                <InputBase
                  id="instrument"
                  style={{ color: "white", fontSize: 40, fontWeight: 500 }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.renameInstrument();
                  }}
                  fullWidth
                  onBlur={this.renameInstrument.bind(this)}
                  value={tmpInstrumentName}
                  onChange={this.handleChange("tmpInstrumentName")}
                />
              ) : (
                <CircularProgress color="secondary" />
              )}
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                onClick={() => this.keyboardEvent({ shortcut: "right" })}
              >
                <Right />
              </IconButton>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 30, textAlign: "center" }}>
              <Slider
                style={{ maxWidth: 300, margin: "auto" }}
                classes={{ container: classes.slider }}
                value={instrumentIndex}
                min={0}
                max={instruments.length - 1}
                step={1}
                onChange={(e, v) =>
                  this.setState({
                    instrumentIndex: v,
                    tmpInstrumentName: instruments[v].name,
                  })
                }
              />
            </Grid>
          </Grid>
        </Portal>
        <FormInstrumentSection
          open={formInstrumentSectionOpen}
          selectedFamily={selectedFamily}
          close={() => {
            this.setState({
              formInstrumentSectionOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedInstrumentSection: undefined,
                }),
              200
            );
          }}
          createInstrumentSection={createInstrumentSection}
          instrumentFamilies={instrumentFamilies}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperInstrumentConfiguratorPage);
