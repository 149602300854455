import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/TablePagination";
import { REACT_APP_API } from "config";
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class InvoicesList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    invoices: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
  };

  onSelect(selectedInvoice) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedInvoice);
    }
  }

  render() {
    const { invoices } = this.props;

    if (invoices.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              <i
                style={{ fontSize: 70 }}
                class="fad fa-file-invoice-dollar"
              ></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No Invoices
            </Typography>
            <Typography variant="caption" color="textSecondary">
              View and download your Rhapsody's invoices.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={invoices}
        meta={[
          {
            path: "id",
            title: "ID",
            width: 30,
          },
          {
            path: "creationDate",
            title: "Creation",
            hidden: { smDown: true },
            numeric: false,
            transform: (t) => moment(t).fromNow(),
            render: (t, d) => (
              <React.Fragment>
                <Typography variant="body2">{t}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment(d.creationDate).format("lll")}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "dueDate",
            title: "Due",
            hidden: { smDown: true },
            numeric: false,
            transform: (t) => moment(t).fromNow(),
            render: (t, d) => (
              <React.Fragment>
                <Typography variant="body2">{t}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment(d.dueDate).format("lll")}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "subTotal",
            hidden: { smDown: true },
            title: "Sub Total",
            numeric: false,
            transform: (t) => `USD ${formattedPrice(t)}`,
            render: (t, d) => (
              <React.Fragment>
                <Typography variant="body2">{t}</Typography>
                <Typography variant="caption" color="textSecondary">
                  Taxes: {d.taxPercent}%
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "total",
            title: "Total",
            numeric: false,
            transform: (t) => `USD ${formattedPrice(t)}`,
          },
        ]}
        title={"Invoices"}
        onRowSelect={(p) =>
          window.open(`${REACT_APP_API}/bazaar/invoices/${p.id}/preview`)
        }
      />
    );
  }
}

export default withStyles(styles)(InvoicesList);
