import { Chip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import TableArrow from "components/TableArrow";
// constants
import { CUSTOMER_DETAIL } from "constants/routes";
import FormattedPhone from "hooks/FormattedPhone";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class CustomersList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    customers: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
  };

  static contextTypes = {
    TourCenter: PropTypes.array,
  };

  onSelect(selectedCustomer) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCustomer);
    }
  }

  render() {
    const { customers, history } = this.props;
    const { TourCenter } = this.context;

    if (customers.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              <i style={{ fontSize: 70 }} class="fad fa-building"></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No Customers
            </Typography>
            <Typography variant="caption" color="textSecondary">
              A customer is an entity you are working with.
              <br />
              You do projects for them.
            </Typography>
          </Grid>
          <Grid item>
            <Chip onClick={() => TourCenter.start(2)} label="+ New Customer" />
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={customers}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "city",
            title: "City",
            numeric: false,
            hidden: { smDown: true },
          },
          {
            path: "contact",
            title: "Contact",
            numeric: false,
            hidden: { smDown: true },
          },
          {
            path: "phone",
            title: "Phone",
            numeric: false,
            hidden: { smDown: true },
            render: (t) => <FormattedPhone value={t} />,
          },
          {
            path: "id",
            numeric: false,
            hidden: { smDown: true },
            component: TableArrow,
            width: 30,
          },
        ]}
        title={"Customers"}
        onRowSelect={(project) =>
          history.push(`${CUSTOMER_DETAIL}?id=${project.id}`)
        }
      />
    );
  }
}

export default withStyles(styles)(CustomersList);
