export default (theme) => ({
  container: {
    maxWidth: 1200,
    margin: "auto",
  },
  urgent: {
    color: "white",
    background: "#f44336",
    fontSize: 11,
    padding: "0px 4px",
    borderRadius: 10,
    fontWeight: 600,
  },
  status: {
    color: "white",
    display: "inline-block",
    fontSize: 12,
    padding: "0px 4px",
    borderRadius: 10,
    fontWeight: 600,
    textTransform: "capitalize",
  },
});
