// import PropTypes from 'prop-types';
// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { GOOGLE_MAPS_GEOCODING_KEY, WIDGET } from "config";
import { formatDate } from "helpers";
import WorkSession from "hooks/WorkSession";
import IframeResizer from "iframe-resizer-react";
import moment from "moment";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

function ChartWidget(props) {
  const theme = useTheme();

  return (
    <div className={props.paper}>
      {/* <div
        style={{
          position: "absolute",
          top: 4,
          right: 4,
        }}
      >
        {!loading
          ? !success && (
              <Tooltip title="Pin on Journal">
                <IconButton onClick={pinToJournal} size="small">
                  <Icon fontSize="inherit" className="fad fa-thumbtack" />
                </IconButton>
              </Tooltip>
            )
          : !success && <CircularProgress size={16} />}
        {success && (
          <Grow in>
            <Typography variant="caption" color="textPrimary">
              <i class="fad fa-check-circle"></i> Pinned!
            </Typography>
          </Grow>
        )}
      </div> */}
      <IframeResizer
        frameborder="0"
        src={`${WIDGET}?workSessionID=${props.studioSession.id}&token=${
          props.studioSession.publicToken
        }${theme.palette.type === "dark" ? "&dark" : ""}`}
        style={{ width: "1px", minWidth: "100%" }}
      />
    </div>
  );
}

function WorkSessionDetailsWidget(props) {
  const [open, setOpen] = React.useState(false);
  return (
    <div className={props.paper} style={{ padding: 10 }}>
      <Grid container justify="space-between" alignItems="baseline">
        <Grid item style={{ width: 220 }}>
          <Typography variant="h6">{props.studioSession.title}</Typography>
        </Grid>
        <Grid item style={{ width: 30 }}>
          <Tooltip title="Edit Title, Date, Studio/Venue">
            <IconButton
              size="small"
              onClick={() => setOpen(true)}
              style={{ border: "solid 1px rgba(155,155,155)" }}
            >
              <Icon
                className="fa-solid fa-pencil"
                style={{ fontSize: 12, padding: 4 }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        {props.studioSession.tzName ||
        (props.studioSession.studio && props.studioSession.studio.tzName) ? (
          <Grid item xs={6}>
            <Typography variant="overline" color="textPrimary">
              {formatDate(
                props.studioSession.dateFromUTC,
                props.studioSession.tzName || props.studioSession.studio.tzName
              )}
            </Typography>
          </Grid>
        ) : (
          []
        )}
        <Grid
          item
          xs={6}
          style={{
            textAlign: "right",
          }}
        >
          <Typography variant="overline" color="textPrimary">
            <b>{moment(props.studioSession.dateFromUTC).fromNow()}</b>
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container justify="space-between" style={{ marginTop: 8 }}>
        {props.studioSession.studio ? (
          <Grid
            item
            style={{
              width: 80,
            }}
          >
            <div
              style={{
                width: 80,
                height: 80,
                borderRadius: 4,
                overflow: "hidden",
              }}
            >
              <img
                alt="google-maps"
                style={{
                  width: 80,
                  height: 80,
                }}
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${props.studioSession.studio.latitude},${props.studioSession.studio.longitude}&zoom=15&size=200x230&maptype=roadmap&markers=color:red%7C${props.studioSession.studio.latitude},${props.studioSession.studio.longitude}&key=${GOOGLE_MAPS_GEOCODING_KEY}`}
                x
              />
            </div>
          </Grid>
        ) : (
          []
        )}
        <Grid
          item
          style={{
            textAlign: "right",
            width: "calc(100% - 100px)",
          }}
        >
          {props.studioSession.studio ? (
            <div>
              <Typography variant="body2" color="textPrimary">
                {props.studioSession.studio.name}
                <br />
                {props.studioSession.studio.address}
                <br />
                {props.studioSession.studio.zipcode}{" "}
                {props.studioSession.studio.city}{" "}
                {props.studioSession.studio.state}
              </Typography>
            </div>
          ) : (
            <Typography variant="body2" color="textPrimary">
              No Studio Selected
            </Typography>
          )}
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <WorkSession workSessionID={props.studioSession.id} standalone />
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              props.api.refresh();
            }}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function MercuryHiringDashboard({
  api,
  studioSession,
  subscription,
}) {
  const classes = useStyles(styles);

  return (
    <>
      <div>
        <div
          style={{
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" color="textPrimary"></Typography>
        </div>
      </div>
      <WorkSessionDetailsWidget
        studioSession={studioSession}
        paper={classes.paper}
        api={api}
      ></WorkSessionDetailsWidget>

      <ChartWidget
        paper={classes.paper}
        studioSession={studioSession}
        api={api}
      ></ChartWidget>
    </>
  );
}
