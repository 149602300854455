import { networkAction } from "helpers/network/networkAction";

import {
  GET_ROSTER_NOTES,
  GET_ROSTER_NOTE_BY_ID,
  DELETE_ROSTER_NOTE,
  UPDATE_ROSTER_NOTE,
  CREATE_ROSTER_NOTE,
} from "constants/rosterNotes";

import * as Api from "api";

export const getRosterNotes = () => async (dispatch) =>
  networkAction(dispatch, GET_ROSTER_NOTES, Api.getRosterNotes, []);

export const getRosterNoteById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ROSTER_NOTE_BY_ID, Api.getRosterNoteById, [id]);

export const deleteRosterNote = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ROSTER_NOTE, Api.deleteRosterNote, [id]);

export const createRosterNote = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ROSTER_NOTE, Api.createRosterNote, [body]);

export const updateRosterNote = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ROSTER_NOTE, Api.updateRosterNote, [id, body]);
