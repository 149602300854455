import { networkAction } from "helpers/network/networkAction";

import {
  GET_SESSION_CATEGORYS,
  GET_SESSION_CATEGORY_BY_ID,
  DELETE_SESSION_CATEGORY,
  UPDATE_SESSION_CATEGORY,
  CREATE_SESSION_CATEGORY,
} from "constants/sessionCategories";

import * as Api from "api";

export const getSessionCategories = () => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_CATEGORYS, Api.getSessionCategories, []);

export const getSessionCategoryById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_SESSION_CATEGORY_BY_ID,
    Api.getSessionCategoryById,
    [id]
  );

export const deleteSessionCategory = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SESSION_CATEGORY, Api.deleteSessionCategory, [
    id,
  ]);

export const createSessionCategory = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SESSION_CATEGORY, Api.createSessionCategory, [
    body,
  ]);

export const updateSessionCategory = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SESSION_CATEGORY, Api.updateSessionCategory, [
    id,
    body,
  ]);
