export default (theme) => ({
  container: {
    padding: 5,
  },
  chip: {
    backgroundColor: "none",
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
});
