export default (theme) => ({
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 4,
  },
  paper: {
    padding: 20,
    borderLeft: `solid 3px #ffc107`,
  },
});
