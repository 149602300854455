import { GET_EVENT_STATUSES } from "constants/eventStatuses";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_EVENT_STATUSES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
