import {
  SELECTED_CALENDAR_DAY,
  SET_CALENDAR_OPEN_TO,
  SET_DARK_MODE,
  SET_PREFERED_WORK_SESSION_PRESENTATION,
  SET_PROJECT_HIRING_SORT_BY,
  STORE_RESET_PASSWORD_TOKEN,
} from "constants/app";

function getCookie(cookiename) {
  // Get name followed by anything except a semicolon
  const cookiestring = RegExp(`${cookiename}[^;]+`).exec(document.cookie);

  // Return everything after the equal sign, or an empty string if the cookie name not found
  const ret = decodeURIComponent(
    cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : ""
  );
  return ret;
}

export default (
  state = {
    darkMode: getCookie("darkMode") === "true",
    selectedCalendarDay: {},
    projectHiringSortBy: "Alphabetical",
    workSessionPresentation:
      window.localStorage.getItem("preferedWorkSessionsPresentation") ||
      "calendar",
  },
  action
) => {
  switch (action.type) {
    case SET_PROJECT_HIRING_SORT_BY:
      return { ...state, projectHiringSortBy: action.sortBy };
    case SET_CALENDAR_OPEN_TO:
      return { ...state, calendarOpenTo: action.calendarOpenTo };
    case STORE_RESET_PASSWORD_TOKEN:
      return { ...state, resetPasswordToken: action.token };
    case SELECTED_CALENDAR_DAY:
      const _state = { ...state }; //eslint-disable-line
      _state.selectedCalendarDay[action.body.id] = action.body.date;
      return _state;
    case SET_DARK_MODE:
      return { ...state, darkMode: action.token };
    case SET_PREFERED_WORK_SESSION_PRESENTATION:
      window.localStorage.setItem(
        "preferedWorkSessionsPresentation",
        action.presentation
      );
      return { ...state, workSessionPresentation: action.presentation };
    default:
      return state;
  }
};
