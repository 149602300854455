import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const engagements = `/${service}/engagements`;

export function getEngagements() {
  const url = `${engagements}`;
  return {
    method: GET,
    url,
  };
}

export function getEngagementById(id) {
  const url = `${engagements}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateEngagement(id, body) {
  const url = `${engagements}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEngagement(id) {
  const url = `${engagements}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEngagement(body) {
  const url = `${engagements}`;
  return {
    method: POST,
    url,
    body,
  };
}
