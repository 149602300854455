import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { withStyles } from "@material-ui/styles";
// custom
import Card from "components/Card";
import CreateCard from "components/CreateCard";
import FormBilling from "components/FormBilling";
import FormCompanyInfo from "components/FormCompanyInfo";
import FormOrganization from "components/FormOrganization";
import FormPersonalInfo from "components/FormPersonalInfo";
// custom
import FormResetPassword from "components/FormResetPassword";
import ImportFiles from "components/ImportFiles";
import InvoicesList from "components/InvoicesList";
// custom
import OrganizationsList from "components/OrganizationsList";
// reactor
import Page from "components/Page";
import StatementsList from "components/StatementsList";
// constant
import { STRIPE_TOKEN } from "config";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
// styles
import styles from "./styles";

class WrapperMyAccountPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    refresh: PropTypes.func,
    customer: PropTypes.object,
    cards: PropTypes.array,
    loading: PropTypes.bool,
    payments: PropTypes.array,
    statements: PropTypes.array,
    titlePatterns: PropTypes.array,
    app: PropTypes.object,
    company: PropTypes.object,
    api: PropTypes.object,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
    SubscriptionCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { MessageCenter } = this.context;
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      addCardCollapsed: false,
    };
    MessageCenter.close("right");
  }

  componentWillReceiveProps(nextProps) {
    if (Number(nextProps.urlParams.index) !== this.state.index) {
      this.setState({ index: Number(nextProps.urlParams.index) });
    }
  }

  getStepContent() {
    const {
      user,
      refresh,
      cards,
      app,
      customer,
      history,
      company,
      api,
      titlePatterns,
    } = this.props;

    const { MessageCenter } = this.context;

    const { index, addCardCollapsed, formOrganizationOpen } = this.state;

    switch (index) {
      case 0:
        return (
          <div>
            <Grid
              container
              spacing={2}
              style={{
                maxWidth: 900,
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
              }}
              justify="center"
            >
              <Grid item xs={12}>
                <FormPersonalInfo
                  refresh={refresh}
                  user={user}
                  updateUser={api.updateUser}
                />
              </Grid>
              <Grid item xs={12}>
                <FormResetPassword
                  user={user}
                  updateUserPassword={api.updateUserPassword}
                />
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <Grid
            container
            spacing={2}
            style={{
              maxWidth: 900,
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          >
            <Grid item xs={12}>
              <FormCompanyInfo
                refresh={refresh}
                company={company}
                titlePatterns={titlePatterns}
                api={api}
                upload={api.upload}
                updateCurrentCompany={api.updateCurrentCompany}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <div
            style={{
              maxWidth: 900,
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          >
            <StatementsList
              statements={customer ? customer.statements : []}
              history={history}
            />
          </div>
        );
      case 3:
        return (
          <div
            style={{
              maxWidth: 900,
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          >
            <InvoicesList
              invoices={customer ? customer.invoices : []}
              history={history}
            />
          </div>
        );
      case 4:
        return (
          <Grid
            container
            spacing={2}
            style={{
              maxWidth: 900,
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {customer ? (
                  <Grid item xs={12}>
                    <Paper style={{ padding: 20 }}>
                      <Typography variant="h6" color="textPrimary">
                        Billing Information
                      </Typography>
                      <br />
                      <FormBilling
                        customer={customer}
                        updateCustomer={api.updateCustomer}
                        refresh={refresh}
                      />
                    </Paper>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {cards && cards.length ? (
                  <Grid item xs={12}>
                    <Paper style={{ padding: 20 }}>
                      <Typography variant="h6" color="textPrimary">
                        Your Credit and Debit Cards
                      </Typography>
                      <br />
                      <Grid container spacing={2}>
                        {cards &&
                          cards.map((card) => (
                            <Grid item key={card.id} xs={12}>
                              <Card
                                dardMode={app.darkMode}
                                card={card}
                                key={card.id}
                                updateCard={api.updateCard}
                                deleteCard={api.deleteCard}
                                createCard={api.createCard}
                                setCardAsFavorite={api.setCardAsFavorite}
                                refresh={refresh}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Paper>
                  </Grid>
                ) : (
                  []
                )}
                <Grid item xs={12}>
                  <Paper style={{ padding: 20 }}>
                    <Typography variant="h6" color="textPrimary">
                      Add a New Payment Method
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      We accept all major credit and debit cards. We use Stripe
                      to secure your transaction. Your information is saved with
                      Stripe servers only.
                    </Typography>
                    <br />
                    <Button
                      variant="outlined"
                      onClick={() =>
                        this.setState((prevState) => ({
                          addCardCollapsed: !prevState.addCardCollapsed,
                        }))
                      }
                    >
                      {addCardCollapsed ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                      Add a Card
                    </Button>
                    <Collapse in={addCardCollapsed} style={{ paddingTop: 20 }}>
                      <StripeProvider apiKey={STRIPE_TOKEN}>
                        <Elements>
                          <CreateCard
                            createCard={api.createCard}
                            refresh={refresh}
                            onClose={() =>
                              this.setState({ addCardCollapsed: false })
                            }
                          />
                        </Elements>
                      </StripeProvider>
                    </Collapse>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      case 5:
        return (
          <div
            style={{
              maxWidth: 900,
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          >
            <ImportFiles
              createFileImport={api.createFileImport}
              deleteFileImport={api.deleteFileImport}
              updateFileImport={api.updateFileImport}
              getFileImports={api.getFileImports}
            />
          </div>
        );
      case 6:
        return (
          <div
            style={{ maxWidth: 1500, margin: "auto", width: "100%", flex: 1 }}
          >
            <OrganizationsList
              organizations={user.organizations}
              user={user}
              api={api}
              getOrganizations={api.getOrganizations}
              history={history}
              admin
              onSelect={(selectedOrganization) => {
                this.setState({
                  selectedOrganization,
                });
                MessageCenter.open("right");
              }}
            />
            <FormOrganization
              open={formOrganizationOpen}
              close={() => {
                this.setState({
                  formOrganizationOpen: false,
                });
                setTimeout(
                  () =>
                    this.setState({
                      selectedOrganization: undefined,
                    }),
                  200
                );
              }}
              createOrganization={api.createOrganization}
              deleteOrganization={api.deleteOrganization}
              updateOrganization={api.updateOrganization}
              refresh={refresh}
            />
          </div>
        );
      default:
        return (
          <Typography variant="h6" color="textPrimary">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const { classes, history, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="My Account"
        loading={loading}
        loadingMessage={"Loading Account Information"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Credentials"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Contractor Settings"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Statements"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Invoices"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Payment Options"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Imports"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Users"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMyAccountPage);
