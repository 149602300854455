import { GET_SESSION_CATEGORYS } from "constants/sessionCategories";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_SESSION_CATEGORYS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
