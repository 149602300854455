import {
  CircularProgress,
  DialogContentText,
  Divider,
  Icon,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Warning from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/styles";
import * as Actions from "actions";
import FormMusician from "components/FormMusician";
// components
import MemberStatus from "components/MemberStatus";
import MusicianSuggestion from "components/MusicianSuggestion";
import { CONNECT } from "config";
// constants
import { MUSICIAN, PROJECT_DETAIL } from "constants/routes";
import { ContentState, EditorState } from "draft-js";
import { copyTextToClipboard, getCurrentStageName } from "helpers";
// helpers
import { hexToRgbA } from "helpers/colors";
import CopyLabel from "hooks/CopyLabel";
import MercuryJobHistory from "hooks/MercuryJobHistory";
// hooks
import MusicianAvatar from "hooks/MusicianAvatar";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import PropTypes from "prop-types";
import Radium, { StyleRoot } from "radium";
import React, { PureComponent } from "react";
import { headShake, swing, tada } from "react-animations";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  sectionRoles: state.sectionRoles,
  instruments: state.instruments,
  sections: state.instrumentSections,
  stages: state.stages,
});

const mapDispatchToProps = (dispatch) => ({
  updateStudioSessionMusician: (...args) =>
    dispatch(Actions.updateStudioSessionMusician(...args)),
  getInstrumentSectionMusicians: (...args) =>
    dispatch(Actions.getInstrumentSectionMusicians(...args)),
  getMusicianById: (...args) => dispatch(Actions.getMusicianById(...args)),
  getRhapsodyJob: (...args) => dispatch(Actions.getRhapsodyJob(...args)),
  getMercuryJob: (...args) => dispatch(Actions.getMercuryJob(...args)),
  updateMusician: (...args) => dispatch(Actions.updateMusician(...args)),
  createMusician: (...args) => dispatch(Actions.createMusician(...args)),
  deleteMusician: (...args) => dispatch(Actions.deleteMusician(...args)),
  updateMercuryJob: (...args) => dispatch(Actions.updateMercuryJob(...args)),
  createMusicianInstrument: (...args) =>
    dispatch(Actions.createMusicianInstrument(...args)),
  deleteMusicianInstrument: (...args) =>
    dispatch(Actions.deleteMusicianInstrument(...args)),
  deleteStudioSessionMusician: (...args) =>
    dispatch(Actions.deleteStudioSessionMusician(...args)),
  getMusicianInstrumentForSection: (...args) =>
    dispatch(Actions.getMusicianInstrumentForSection(...args)),
});

class Job extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    disabled: PropTypes.bool,
    job: PropTypes.object,
    refresh: PropTypes.array,
    studioSession: PropTypes.object,
    history: PropTypes.object,
    conciergeReadOnly: PropTypes.bool,
    small: PropTypes.bool,
    sections: PropTypes.array,
    stages: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    SubscriptionCenter: PropTypes.object,
    TourCenter: PropTypes.object,
    MercuryNotify: PropTypes.object,
  };

  static defaultProps = {
    small: false,
  };

  constructor(...args) {
    super(...args);
    const { subscription, job } = this.props;

    this.state = {
      roleAnchor: null,
      notes: "",
      statusAnchor: null,
      popperAnchor: null,
      instrumentAnchor: null,
      menuAnchor: null,
      formMusicianOpen: false,
      notesOpen: false,
      musiciansLoading: false,
      loading: false,
      menuOpen: false,
      pulse: false,
      sectionAnchor: null,
    };

    subscription?.on("publish", this.wsMessageReceive.bind(this));
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  wsMessageReceive({ se, data }) {
    const { job } = this.props;
    if (data.id === job.id && this._ismounted) {
      this.getRhapsodyJob();
      // this.getMercuryJob();
    }
  }

  setMusicianLoading(loading) {
    this.setState({ loading });
  }

  getPlainText(value) {
    let es;
    if (value) {
      const html = value;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        es = EditorState.createWithContent(contentState);
        if (es) {
          return es.getCurrentContent().getPlainText("\u0001");
        }
      }
    }

    return value;
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleRoleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ roleAnchor: event.currentTarget });
  };

  handleRoleClose = () => {
    this.setState({ roleAnchor: null });
  };

  handleSectionClose = () => {
    this.setState({ sectionAnchor: null });
  };

  openJobDetails = (event) => {
    if (!this.state.menuAnchor) {
      this.getMercuryJob();
    }
    this.setState({ menuAnchor: event.currentTarget, menuOpen: true });
  };

  closeJobDetails = () => {
    this.setState({
      menuAnchor: null,
      menuOpen: false,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm you action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  closeMusicianSuggestions() {
    this.setState({
      popperAnchor: null,
    });
  }

  async removeMusician() {
    const { updateStudioSessionMusician, job, refresh } = this.props;
    this.setState({ loading: true });
    await updateStudioSessionMusician(job.id, {
      musicianID: -1,
      instrumentID: -1,
    });
    await refresh();
    this.setState({ loading: false });
  }

  async changeSectionRole(role) {
    const { updateStudioSessionMusician, job, refresh } = this.props;
    this.handleRoleClose();
    this.setState({ loading: true });
    await updateStudioSessionMusician(job.id, {
      sectionRoleID: role.id,
    });
    await refresh();
    this.setState({ loading: false });
  }

  async confirmDelete() {
    const { deleteStudioSessionMusician, job, refresh } = this.props;
    this.setState({ loading: true });
    await deleteStudioSessionMusician(job.id);
    await refresh();
    this.setState({ loading: false });
  }

  async handleNotes() {
    const { updateStudioSessionMusician, job, refresh } = this.props;

    const { notes } = this.state;

    this.setState({
      notesOpen: false,
      loading: true,
    });

    this.closeJobDetails();

    await updateStudioSessionMusician(job.id, { memo: notes });

    await refresh();
    this.setState({
      loading: false,
      notes: "",
    });
  }

  async linkMusician(musician) {
    const { updateStudioSessionMusician, job, refresh } = this.props;
    const { TourCenter } = this.context;
    this.closeMusicianSuggestions();
    this.setState({ loading: true });
    if (TourCenter) TourCenter.next();
    await updateStudioSessionMusician(job.id, {
      musicianID: musician.id,
    });
    await refresh();
    this.setState({ loading: false });
  }

  async getSectionMusicians(e) {
    const {
      getInstrumentSectionMusicians,
      // sessionSection,
      studioSession,
      sectionRoles,
      job,
    } = this.props;
    const { TourCenter } = this.context;

    const role = sectionRoles.find((sr) => sr.id === job.sectionRoleID);

    if (TourCenter) TourCenter.next();

    this.setState({ musiciansLoading: true, popperAnchor: e.currentTarget });
    const r = await getInstrumentSectionMusicians(
      // sessionSection.id,
      job.sectionID,
      studioSession.id,
      role.id
    );
    if (r.success) {
      this.setState({ sectionMusicians: r.payload, musiciansLoading: false });
    }
  }

  async viewMusicianCard(id) {
    const { getMusicianById } = this.props;

    this.setState({
      formMusicianOpen: true,
      musicianLoading: true,
      prevent: true,
    });

    const r = await getMusicianById(id);
    this.setState({
      musician: r.payload,
      musicianLoading: false,
    });
  }

  async getRhapsodyJob() {
    const { getRhapsodyJob, job } = this.props;
    const { rhapsodyJob } = this.state;
    const r = await getRhapsodyJob(job.id);

    var _job = { ...job, currentStage: this.injectStage(job.currentJobStage) };
    _job = rhapsodyJob || _job;
    // debugger;

    if (r.success) {
      const _rhapsodyJob = r.payload;
      if (_job.currentStage.id !== _rhapsodyJob.currentStage.id) {
        let anim = "";
        switch (_rhapsodyJob.currentStage.id) {
          case 2:
            anim = "tada";
            break;
          case 3:
            anim = "headShake";
            break;
          case 4:
            anim = "swing";
            break;
          default:
            break;
        }

        this.setState({ anim });
        setTimeout(() => this.setState({ anim: undefined }), 1000);
      }

      this.setState({ rhapsodyJob: _rhapsodyJob });
    }
  }

  async getMercuryJob() {
    const { getMercuryJob, job, refresh } = this.props;
    if (job.musicianID) {
      const r = await getMercuryJob(job.id);
      console.log("getMercuryJob");
      console.log(r);
      // debugger;

      if (r.success) {
        const mercuryJob = r.payload;
        // console.log(mercuryJob);
        // debugger;
        // if (mercuryJob.currentStageID === 3 && refresh) {
        //   refresh();
        // }
        this.setState({ mercuryJob: r.payload });
      }
    }
  }

  getRoleSelection(currentStage, role) {
    const { classes, sectionRoles, disabled, conciergeReadOnly } = this.props;

    const { roleAnchor } = this.state;
    return (
      <Grid item style={{ display: "flex", alignItems: "center" }}>
        <Chip
          aria-owns={roleAnchor ? "simple-menu" : undefined}
          aria-haspopup="true"
          size="small"
          disabled={conciergeReadOnly || disabled}
          onDelete={this.handleRoleClick}
          onClick={this.handleRoleClick}
          label={role?.name}
          deleteIcon={<ArrowDropDownIcon />}
          className={currentStage ? classes.chip : classes.emptyChip}
        />
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Menu
            id="simple-menu"
            anchorEl={roleAnchor}
            open={Boolean(roleAnchor)}
            onClose={this.handleRoleClose}
            disableEnforceFocus
            disableRestoreFocus
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {sectionRoles.map((sr) => (
              <MenuItem
                key={sr.id}
                disabled={disabled || conciergeReadOnly}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.changeSectionRole(sr);
                }}
              >
                {sr.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Grid>
    );
  }

  getTopRightStatusInfo(currentStage, glanceInspector) {
    return (
      <div style={{ lineHeight: 1 }}>
        {currentStage?.id ? (
          <Typography
            variant="caption"
            display="block"
            style={{
              color: currentStage.color,
              lineHeight: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={currentStage.icon}
              alt="status"
              style={{ height: 10, paddingRight: 4 }}
            />{" "}
            {getCurrentStageName(currentStage)}
            {currentStage.nudgeCount ? (
              <Tooltip
                title={`Nudged ${currentStage.nudgeCount} time${
                  currentStage.nudgeCount > 1 ? "s" : ""
                }`}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i
                    class="fas fa-bullhorn p-left"
                    style={{ fontSize: 11 }}
                  ></i>
                  <div
                    style={{
                      background: currentStage.color,
                      color: "white",
                      borderRadius: 30,
                      padding: "0px 4px",
                      marginLeft: 2,
                      fontSize: 11,
                    }}
                  >
                    {currentStage.nudgeCount}
                  </div>
                </div>
              </Tooltip>
            ) : (
              []
            )}
          </Typography>
        ) : (
          []
        )}
        {glanceInspector ? (
          <Tooltip title={moment(glanceInspector.createdAt).fromNow()}>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ fontSize: 11 }}
            >
              {glanceInspector.icon && (
                <i className={`${glanceInspector.icon} p-right`} />
              )}
              {glanceInspector.message}
            </Typography>
          </Tooltip>
        ) : (
          []
        )}
      </div>
    );
  }

  injectStage(currentJobStage) {
    const { stages } = this.props;
    if (currentJobStage) {
      const _stage = stages.find((s) => s.id === currentJobStage.stageID);
      if (_stage) return { ...currentJobStage, ..._stage };
    }
    return { ...currentJobStage, color: "#9e9e9e", nudgeCount: 0, id: null };
  }

  getMusicianPopper(job) {
    const {
      classes,
      disabled,
      refresh,
      conciergeReadOnly,
      updateMercuryJob,
      stages,
    } = this.props;

    const { MercuryNotify } = this.context;

    const {
      menuAnchor,
      menuOpen,
      prevent,
      formMusicianOpen,
      rhapsodyJob,
      mercuryJob,
    } = this.state;

    const { musician, currentJobStage } = job;
    const currentStage = this.injectStage(currentJobStage);

    return (
      <div>
        <Popper anchorEl={menuAnchor} open={menuOpen} style={{ zIndex: 1100 }}>
          <ClickAwayListener
            onClickAway={() => {
              if (!prevent && !formMusicianOpen) this.closeJobDetails();
            }}
          >
            <Paper
              elevation={24}
              style={{
                width: 300,
                position: "relative",
                overflow: "hidden",
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              <div className={classes.jobHeader}>
                <div style={{ padding: 8 }}>
                  <Typography color="inherit">
                    <b>Job Details</b>
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {!conciergeReadOnly ? (
                    <>
                      <Tooltip title="Edit Musician">
                        <Typography
                          color="inherit"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.viewMusicianCard(job.musicianID)}
                        >
                          <i
                            class="fad fa-user-music"
                            style={{
                              marginRight: 8,
                              fontSize: 12,
                            }}
                          ></i>
                        </Typography>
                      </Tooltip>
                      <Tooltip title="Open Job Page">
                        <Typography
                          style={{ cursor: "pointer" }}
                          color="inherit"
                          onClick={() => {
                            window.open(
                              `${CONNECT}?token=${job.publicToken}`,
                              "_blank"
                            );
                          }}
                        >
                          <i
                            class="fad fa-eye"
                            style={{
                              marginRight: 8,
                              fontSize: 12,
                            }}
                          ></i>
                        </Typography>
                      </Tooltip>
                    </>
                  ) : (
                    []
                  )}
                  <div className="refID">
                    Job #
                    <CopyLabel label={`${job.id}`} />
                  </div>
                </div>
              </div>
              <Grid container>
                <Grid item xs={12} className={classes.jobSubheader}>
                  <Grid container alignItems="space-between">
                    <Grid
                      item
                      style={{
                        maxWidth: "calc(100% - 46px)",
                        flexGrow: 1,
                      }}
                    >
                      <Tooltip title="Send Email">
                        <Typography
                          variant="caption"
                          display="block"
                          color="inherit"
                          style={{
                            cursor: "pointer",
                            opacity: 0.58,
                          }}
                          onClick={() => {
                            window.open(`mailTo:${musician.email}`, "_blank");
                          }}
                        >
                          <i class="fas fa-envelope"></i> {musician.email}
                        </Typography>
                      </Tooltip>
                      {musician.phone ? (
                        <Tooltip title="Phone">
                          <Typography
                            variant="caption"
                            color="inherit"
                            display="block"
                            style={{
                              cursor: "pointer",
                              opacity: 0.58,
                            }}
                            onClick={() => {
                              window.open(`tel:${musician.phone}`, "_blank");
                            }}
                          >
                            <i class="fas fa-phone-alt"></i> {musician.phone}
                          </Typography>
                        </Tooltip>
                      ) : (
                        []
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div
                    id="mercuryJobHistoryContainer"
                    style={{
                      height: 400,
                      overflow: "scroll",
                      position: "relative",
                      textAlign: "center",
                    }}
                  >
                    <MercuryJobHistory
                      musician={musician}
                      conciergeReadOnly={conciergeReadOnly}
                      mercuryJob={mercuryJob}
                      rhapsodyJob={rhapsodyJob || this.props.job}
                      setPrevent={(v) => this.setState({ prevent: v })}
                      refreshJob={this.getMercuryJob.bind(this)}
                      api={{
                        updateMercuryJob: updateMercuryJob,
                      }}
                      refresh={refresh}
                      MercuryNotify={MercuryNotify}
                    />
                  </div>
                </Grid>
              </Grid>
              {currentStage.id === 3 ||
              currentStage.id === 9 ||
              currentStage.id === 20 ||
              currentStage.id === null ? (
                <>
                  <Divider />
                  <MenuItem
                    selected
                    disabled={disabled || conciergeReadOnly}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.removeMusician();
                      this.closeJobDetails();
                    }}
                  >
                    <ListItemText style={{ textAlign: "center" }}>
                      <Typography>
                        <i class="fas fa-user-times p-right"></i> Remove From
                        Session
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                </>
              ) : (
                []
              )}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    );
  }

  getMusicianFeedback(musicianFeedback, currentStage) {
    if (!musicianFeedback) return <div />;
    return (
      <>
        <Grid
          item
          xs={musicianFeedback ? undefined : 12}
          style={{
            backgroundColor: currentStage.color,
            border: `solid 1px ${currentStage.color}`,
            color: musicianFeedback ? "white" : currentStage.color,
            marginTop: 8,
            borderRadius: 8,
            maxWidth: "100%",
            padding: "2px 8px",
            position: "relative",
          }}
        >
          <div
            style={{
              top: -5,
              left: 17,
              position: "absolute",
              width: 0,
              height: 0,
              borderLeft: "3px solid transparent",
              borderRight: "3px solid transparent",
              borderBottom: `5px solid ${currentStage.color}`,
            }}
          />
          <Tooltip title="Click to view">
            <Typography
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.setState({ musicianFeedbackOpen: true });
              }}
              variant="caption"
              color="inherit"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {musicianFeedback.message}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Tooltip title="Click to copy the message to your clipboard">
            <Typography
              style={{
                fontSize: 9,
                opacity: 0.58,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                copyTextToClipboard(musicianFeedback.message);
              }}
            >
              Copy
            </Typography>
          </Tooltip>
        </Grid>
      </>
    );
  }

  getMemo(job, currentStage) {
    if (!job.memo) return <div />;
    return (
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: currentStage?.id
            ? hexToRgbA(currentStage.color, 0.2)
            : "rgba(155,155,155,0.1)",
          marginTop: 8,
          borderRadius: 4,
          padding: 4,
          wordWrap: "break-word",
        }}
      >
        <Typography variant="caption" color="textSecondary">
          <Tooltip title="Edit Memo">
            <Icon
              style={{
                color: "#9e9e9e",
                fontSize: 18,
                float: "right",
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.setState({
                  notesOpen: true,
                  notes: job.memo,
                });
              }}
              class="far fa-clipboard"
            />
          </Tooltip>
          {`Memo: ${job.memo}`}
        </Typography>
      </Grid>
    );
  }

  getRemoveFromSessionButton(currentStage, conciergeReadOnly, disabled) {
    if (
      currentStage?.id === 9 ||
      currentStage?.id === 20 ||
      currentStage?.id === 3
    ) {
      return (
        <Grid item xs={12}>
          <Button
            size="small"
            disabled={conciergeReadOnly || disabled}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.removeMusician();
            }}
            style={{
              background: currentStage.color,
              color: "white",
              marginTop: 4,
              width: "100%",
              opacity: conciergeReadOnly || disabled ? 0.5 : 1,
              textTransform: "none",
            }}
          >
            <i class="fas fa-user-times p-right"></i> Remove From Session
          </Button>
        </Grid>
      );
    }
    return [];
  }

  getEditMemoDialog(job, currentStage, conciergeReadOnly, disabled) {
    const { notesOpen, notes } = this.state;
    return (
      <Dialog open={notesOpen} aria-labelledby="form-dialog-title">
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" color="textPrimary">
              {job.memo ? "Edit the musician Memo" : "Memo for the musician"}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ display: "flex", alignItems: "center" }}
            >
              <VisibilityIcon style={{ fontSize: 16, marginRight: 4 }} /> This
              note will be included in all emails sent to the musician.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="notes"
              label="Notes"
              disabled={conciergeReadOnly || disabled}
              fullWidth
              multiline
              error={notes && 255 - notes.length <= 0}
              helperText={
                notes &&
                (notes.length > 255
                  ? "Text Too long"
                  : `${255 - notes.length} char left`)
              }
              value={notes}
              onChange={this.handleChange("notes")}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ notesOpen: false, notes: "" })}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleNotes.bind(this)}
              variant="contained"
              disabled={
                (notes && notes.length > 255) || conciergeReadOnly || disabled
              }
              color="primary"
            >
              {job.memo ? "Edit" : "Add"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  getMusicianSuggestion() {
    const { sectionRoles, studioSession, history } = this.props;

    const { musiciansLoading, sectionMusicians, popperAnchor } = this.state;
    return (
      <Popover
        open={Boolean(popperAnchor)}
        disableEnforceFocus
        disableRestoreFocus
        onClose={this.closeMusicianSuggestions.bind(this)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={popperAnchor}
      >
        <MusicianSuggestion
          linkMusician={this.linkMusician.bind(this)}
          loading={musiciansLoading}
          sectionRoles={sectionRoles}
          categories={studioSession.hasAlternates}
          onClose={this.closeMusicianSuggestions.bind(this)}
          search
          showPrime={studioSession.hasAlternates}
          sortAlphabetically={!studioSession.hasAlternates}
          label={"Suggestions"}
          suggestions={sectionMusicians}
          footer={
            <>
              {studioSession.hasAlternates ? (
                <>
                  <Typography color="textSecondary" variant="caption">
                    The project does not contain any more musicians.
                  </Typography>
                  <br />
                  <Chip
                    onClick={() =>
                      history.push(
                        `${PROJECT_DETAIL}?id=${studioSession.projectID}&index=1`
                      )
                    }
                    size="small"
                    label="Go to the project's seating"
                  />
                </>
              ) : (
                <>
                  <Typography color="textSecondary" variant="caption">
                    The address book does not contain any other musician for
                    this section.
                  </Typography>
                  <br />
                  <Chip
                    onClick={() => history.push(MUSICIAN)}
                    size="small"
                    label="Go to Musicians"
                  />
                </>
              )}
            </>
          }
        />
      </Popover>
    );
  }

  getFullMusicianFeedbackDialog(musician, currentStage, musicianFeedback) {
    return (
      <Dialog
        open={this.state.musicianFeedbackOpen}
        onClose={() => this.setState({ musicianFeedbackOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ lineHeight: 1 }}>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ lineHeight: 1 }}
              >
                {musician?.nickName ||
                  `${musician?.firstName} ${musician?.lastName}`}{" "}
                said:
              </Typography>
              <Typography
                style={{ lineHeight: 0 }}
                variant="caption"
                color="textSecondary"
              >
                Click on the message to copy
              </Typography>
            </div>
            <br />
            <div
              style={{
                backgroundColor: currentStage?.color,
                border: `solid 1px ${currentStage?.color}`,
                color: musicianFeedback ? "white" : currentStage?.color,
                marginTop: 8,
                borderRadius: 8,
                maxWidth: "100%",
                padding: "2px 8px",
                position: "relative",
                lineHeight: 1,
              }}
            >
              <Typography variant="caption">
                <CopyLabel label={musicianFeedback?.message} />
              </Typography>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => this.setState({ musicianFeedbackOpen: false })}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  getMusicianInfo(
    musician,
    job,
    disabled,
    currentStage,
    musicianFeedback,
    conciergeReadOnly,
    classes
  ) {
    const {
      getMusicianInstrumentForSection,
      updateStudioSessionMusician,
      refresh,
    } = this.props;
    return (
      <Grid
        container
        justify="space-between"
        style={{
          paddingTop: 4,
        }}
      >
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid
              item
              style={{
                width: 46,
                position: "relative",
                textAlign: "center",
              }}
            >
              {!musician?.id ? (
                <i class="fad fa-chair" style={{ fontSize: 30 }}></i>
              ) : (
                <MusicianAvatar musician={musician} />
              )}
            </Grid>
            <Grid item style={{ maxWidth: "calc(100% - 46px)", flexGrow: 1 }}>
              {musician && musician.id ? (
                <Grid container alignItems="flex-end" justify="space-between">
                  {musician.userUUID && !musician.verified ? (
                    <Grid item style={{ marginRight: 4 }}>
                      <MemberStatus verified={musician.verified} small />
                    </Grid>
                  ) : (
                    []
                  )}
                  <Grid item>
                    <Typography variant="body2">
                      {musician.nickName ||
                        `${musician.firstName} ${musician.lastName}`}
                    </Typography>
                    {(musician.id && musician.email === null) ||
                    musician.email === "" ? (
                      <Typography style={{ color: "#f44336" }}>
                        <Warning
                          fontSize="small"
                          color="inherit"
                          style={{ height: 15 }}
                        />{" "}
                        No email
                      </Typography>
                    ) : (
                      []
                    )}
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={classes.instrument}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({ instrumentAnchor: e.currentTarget });
                        getMusicianInstrumentForSection(
                          job.sectionID,
                          job.musician.id
                        ).then((r) => {
                          this.setState({ musicianInstruments: r.payload });
                        });
                      }}
                    >
                      {job.instrument?.id
                        ? job.instrument.name
                        : "No instrument"}
                    </Typography>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <Menu
                        open={Boolean(this.state.instrumentAnchor)}
                        onClose={() =>
                          this.setState({ instrumentAnchor: null })
                        }
                        anchorEl={this.state.instrumentAnchor}
                        disableEnforceFocus
                        disableRestoreFocus
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        {!this.state.musicianInstruments ? (
                          <MenuItem>
                            <CircularProgress size={20} />
                          </MenuItem>
                        ) : (
                          []
                        )}
                        {this.state.musicianInstruments?.map((i) => (
                          <MenuItem
                            onClick={() => {
                              this.setState({ loading: true });
                              updateStudioSessionMusician(job.id, {
                                instrumentID: i.id,
                              }).then(async () => {
                                this.setState({
                                  instrumentAnchor: null,
                                });
                                await refresh();
                                this.setState({
                                  loading: false,
                                });
                              });
                            }}
                            selected={i.id === job.instrumentID}
                          >
                            {i.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </Grid>
                  {!job.memo ? (
                    <Grid item>
                      <Tooltip title="Memo for the musician">
                        <Icon
                          style={{
                            color: "#9e9e9e",
                            fontSize: 18,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.setState({
                              notesOpen: true,
                              notes: job.memo,
                            });
                          }}
                          class="far fa-clipboard"
                        />
                      </Tooltip>
                    </Grid>
                  ) : (
                    []
                  )}
                </Grid>
              ) : (
                <Grid container justify="space-between" alignItems="flex-end">
                  <Grid item>
                    <Typography variant="caption" color="textSecondary">
                      <b>Empty Chair</b>
                      <br />
                      {!disabled ? "Click to assign a musician" : ""}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Memo for the musician">
                      <Icon
                        style={{
                          color: "#9e9e9e",
                          fontSize: 18,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.setState({
                            notesOpen: true,
                            notes: job.memo,
                          });
                        }}
                        class="far fa-clipboard"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container>
            {this.getMusicianFeedback(musicianFeedback, currentStage)}
            {this.getMemo(job, currentStage)}
            {this.getRemoveFromSessionButton(
              currentStage,
              conciergeReadOnly,
              disabled
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  onCardClicked(musician, popperAnchor) {
    return !musician && !popperAnchor
      ? this.getSectionMusicians.bind(this)
      : (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.openJobDetails(e);
        };
  }

  render() {
    const {
      classes,
      sectionRoles,
      disabled,
      instruments,
      refresh,
      conciergeReadOnly,
      small,
    } = this.props;

    const {
      loading,
      musicianLoading,
      hover,
      popperAnchor,
      formMusicianOpen,
      rhapsodyJob,
      anim,
    } = this.state;

    console;

    const job = rhapsodyJob || this.props.job;
    const { musician, currentJobStage } = job;
    const currentStage = currentJobStage
      ? this.injectStage(currentJobStage)
      : job.currentStage;

    // const restricted = !SubscriptionCenter.check(10, true);
    const role = sectionRoles.find((sr) => sr.id === job.sectionRoleID);

    let backgroundColor = undefined;
    let border = undefined;
    if (musician?.id && currentStage?.id) {
      backgroundColor = hexToRgbA(currentStage.color, 0.15);
      border = `solid 1px ${hexToRgbA(currentStage.color, 0.8)}`;
    }
    if (musician?.id && !currentStage?.id) {
      backgroundColor = hexToRgbA("#bdbdbd", 0.15);
      border = `solid 1px ${hexToRgbA("#bdbdbd", 0.8)}`;
    }

    const musicianFeedback = currentStage?.inspectors?.find(
      (i) => i.title === "Message"
    );

    let a;

    switch (anim) {
      case "tada":
        a = tada;
        break;
      case "swing":
        a = swing;
        break;
      case "headShake":
        a = headShake;
        break;
      default:
        break;
    }

    const styles = {
      anim: {
        animation: "x 1s",
        animationName: a && Radium.keyframes(a, "anim"),
      },
    };

    const inspectors = currentStage?.inspectors?.sort((a, b) => b.id - a.id);
    let glanceInspector;
    for (const key in inspectors) {
      if (Object.hasOwnProperty.call(inspectors, key)) {
        const gi = inspectors[key];
        if (
          gi.title === "emailOpen" ||
          gi.title === "smsSent" ||
          gi.title === "emailSent" ||
          gi.title === "emailOutbox" ||
          gi.title === "connectOpen" ||
          gi.title === "pushSent"
        ) {
          glanceInspector = gi;
          break;
        }
      }
    }

    return (
      <StyleRoot>
        <div className="test" style={this.state.anim ? styles.anim : undefined}>
          {!small ? (
            <Paper
              onMouseEnter={() => this.setState({ hover: true })}
              onMouseLeave={() => this.setState({ hover: false })}
              data-tut="reactour__musician"
              className={classes.container}
              onClick={this.onCardClicked(musician, popperAnchor)}
              style={{
                cursor: !disabled ? "pointer" : undefined,
                boxShadow: hover
                  ? "0 5px 25px rgba(155,155,155,0.5)"
                  : "0 5px 25px rgba(0,0,0,0)",
                backgroundColor,
                border,
              }}
            >
              <Grid container justify="space-between">
                {this.getRoleSelection(currentStage, role)}
                <Grid item xs={6}>
                  <Grid container style={{ width: "100%" }} justify="flex-end">
                    <Grid item>
                      {musician?.id ? (
                        <div
                          style={{
                            textAlign: "right",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          {this.getTopRightStatusInfo(
                            currentStage,
                            glanceInspector
                          )}
                        </div>
                      ) : (
                        []
                      )}
                    </Grid>
                    {!disabled && !conciergeReadOnly && !musician?.id ? (
                      <Grid item style={{ textAlign: "right" }}>
                        <Icon
                          onClick={(e) => {
                            this.delete();
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          style={{
                            fontSize: 14,
                            color: "#9e9e9e",
                          }}
                          className="far fa-times"
                        />
                      </Grid>
                    ) : (
                      []
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {this.getMusicianInfo(
                musician,
                job,
                disabled,
                currentStage,
                musicianFeedback,
                conciergeReadOnly,
                classes
              )}
              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress size={20} color="inherit" />
                </div>
              ) : (
                []
              )}
              {this.getMusicianSuggestion()}
            </Paper>
          ) : (
            <div onClick={this.onCardClicked(musician, popperAnchor)}>
              <JobChip
                job={{ ...job, currentStage }}
                musicianFeedback={musicianFeedback}
                onTalkbackClicked={() =>
                  this.setState({ musicianFeedbackOpen: true })
                }
              />
            </div>
          )}
          {this.getEditMemoDialog(
            job,
            currentStage,
            conciergeReadOnly,
            disabled
          )}
          {musician?.id ? this.getMusicianPopper(job) : []}
          <FormMusician
            open={formMusicianOpen}
            musician={this.state.musician}
            close={() => {
              this.setState({
                formMusicianOpen: false,
              });
              setTimeout(() => this.setState({ prevent: false }), 300);
            }}
            refresh={refresh}
            refreshMusician={this.viewMusicianCard.bind(this)}
            instruments={instruments}
            musicianLoading={musicianLoading}
          />
        </div>
        {this.getFullMusicianFeedbackDialog(
          musician,
          currentStage,
          musicianFeedback
        )}
      </StyleRoot>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Job));

function JobChip({ job, musicianFeedback, onTalkbackClicked }) {
  if (!job) return <div />;
  const { currentStage } = job;
  if (!currentStage || currentStage.id == null)
    return (
      <div
        style={{
          background: hexToRgbA("#9e9e9e", 0.2),
          border: `solid 1px #9e9e9e`,
          borderRadius: 16,
          display: "flex",
          padding: "2px 6px",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <img
          src={"https://storage.googleapis.com/rhapsody/logos/ready.png"}
          style={{ height: 16, paddingRight: 4 }}
        />
        <Typography variant="caption" style={{ color: "#9e9e9e" }}>
          Ready
        </Typography>
      </div>
    );
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          background: hexToRgbA(currentStage?.color, 0.2),
          border: `solid 1px ${currentStage?.color}`,
          borderRadius: 16,
          display: "flex",
          padding: "2px 6px",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <img src={currentStage?.icon} style={{ height: 16, paddingRight: 4 }} />
        <Typography variant="caption" style={{ color: currentStage?.color }}>
          {getCurrentStageName(currentStage)}
        </Typography>
      </div>
      {musicianFeedback ? (
        <i
          class="fas fa-comment p-left"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onTalkbackClicked();
          }}
          style={{ color: currentStage?.color, cursor: "pointer" }}
        ></i>
      ) : (
        []
      )}
    </div>
  );
}
