import { networkAction } from "helpers/network/networkAction";

import {
  GET_ENGAGEMENTS,
  GET_ENGAGEMENT_BY_ID,
  DELETE_ENGAGEMENT,
  UPDATE_ENGAGEMENT,
  CREATE_ENGAGEMENT,
} from "constants/engagements";

import * as Api from "api";

export const getEngagements = () => async (dispatch) =>
  networkAction(dispatch, GET_ENGAGEMENTS, Api.getEngagements, []);

export const getEngagementById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ENGAGEMENT_BY_ID, Api.getEngagementById, [id]);

export const deleteEngagement = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ENGAGEMENT, Api.deleteEngagement, [id]);

export const createEngagement = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ENGAGEMENT, Api.createEngagement, [body]);

export const updateEngagement = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ENGAGEMENT, Api.updateEngagement, [id, body]);
