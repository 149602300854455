// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperEmailsPage from "../component/WrapperEmailsPage";

const mapStateToProps = (state) => ({
  emails: state.emails,
});

const mapDispatchToProps = (dispatch) => ({
  getEmails: (...args) => dispatch(Actions.getEmails(...args)),
  getEmailById: (...args) => dispatch(Actions.getEmailById(...args)),
  createEmail: (...args) => dispatch(Actions.createEmail(...args)),
  deleteEmail: (...args) => dispatch(Actions.deleteEmail(...args)),
  updateEmail: (...args) => dispatch(Actions.updateEmail(...args)),
});
class EmailsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    emails: PropTypes.array,
    getEmails: PropTypes.func,
    getEmailById: PropTypes.func,
    createEmail: PropTypes.func,
    deleteEmail: PropTypes.func,
    updateEmail: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, emails } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: emails.length === 0,
      dashboard: [],
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getEmails, location } = this.props;
    const urlParams = getJsonFromUrl(location);
    await Promise.all([
      getEmails(urlParams.filters ? JSON.parse(urlParams.filters) : []),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      emails,
      getEmailById,
      createEmail,
      deleteEmail,
      updateEmail,
      getEmails,
      history,
    } = this.props;

    const { urlParams, loading, dashboard } = this.state;

    return (
      <WrapperEmailsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        emails={emails}
        history={history}
        getEmails={getEmails}
        getEmailById={getEmailById}
        createEmail={createEmail}
        deleteEmail={deleteEmail}
        updateEmail={updateEmail}
        dashboard={dashboard}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailsPage);
