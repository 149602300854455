import { networkAction } from "helpers/network/networkAction";

import {
  GET_SESSION_RATES,
  GET_SESSION_RATE_BY_ID,
  DELETE_SESSION_RATE,
  UPDATE_SESSION_RATE,
  CREATE_SESSION_RATE,
} from "constants/sessionRates";

import * as Api from "api";

export const getSessionRates = () => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_RATES, Api.getSessionRates, []);

export const getSessionRateById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_RATE_BY_ID, Api.getSessionRateById, [id]);

export const deleteSessionRate = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SESSION_RATE, Api.deleteSessionRate, [id]);

export const createSessionRate = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SESSION_RATE, Api.createSessionRate, [body]);

export const updateSessionRate = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SESSION_RATE, Api.updateSessionRate, [
    id,
    body,
  ]);
