import * as Api from "api";
import {
  CREATE_PIPELINE,
  DELETE_PIPELINE,
  GET_PIPELINES,
  GET_PIPELINE_BY_ID,
  GET_PIPELINE_GRAPH_BY_ID,
  UPDATE_PIPELINE,
} from "constants/pipelines";
import { networkAction } from "helpers/network/networkAction";

export const getPipelines = () => async (dispatch) =>
  networkAction(dispatch, GET_PIPELINES, Api.getPipelines, []);

export const getPipelineById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PIPELINE_BY_ID, Api.getPipelineById, [id]);

export const getPipelineGraphById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PIPELINE_GRAPH_BY_ID, Api.getPipelineGraphById, [
    id,
  ]);

export const deletePipeline = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PIPELINE, Api.deletePipeline, [id]);

export const createPipeline = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PIPELINE, Api.createPipeline, [body]);

export const updatePipeline = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PIPELINE, Api.updatePipeline, [id, body]);
