import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const sessionRates = `/${service}/sessionRates`;

export function getSessionRates() {
  const url = `${sessionRates}`;
  return {
    method: GET,
    url,
  };
}

export function getSessionRateById(id) {
  const url = `${sessionRates}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSessionRate(id, body) {
  const url = `${sessionRates}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSessionRate(id) {
  const url = `${sessionRates}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSessionRate(body) {
  const url = `${sessionRates}`;
  return {
    method: POST,
    url,
    body,
  };
}
