import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const productionTypes = `/${service}/productionTypes`;

export function getProductionTypes() {
  const url = `${productionTypes}`;
  return {
    method: GET,
    url,
  };
}

export function getProductionTypeById(id) {
  const url = `${productionTypes}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProductionType(id, body) {
  const url = `${productionTypes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProductionType(id) {
  const url = `${productionTypes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProductionType(body) {
  const url = `${productionTypes}`;
  return {
    method: POST,
    url,
    body,
  };
}
