import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperSessionTypePage from "../component/WrapperSessionTypePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  sessionTypes: state.sessionTypes,
});

const mapDispatchToProps = (dispatch) => ({
  getSessionTypes: () => dispatch(Actions.getSessionTypes()),
  getSessionTypeById: (...args) =>
    dispatch(Actions.getSessionTypeById(...args)),
  createSessionType: (...args) => dispatch(Actions.createSessionType(...args)),
  deleteSessionType: (...args) => dispatch(Actions.deleteSessionType(...args)),
  updateSessionType: (...args) => dispatch(Actions.updateSessionType(...args)),
  getSessionTypeSessionMedia: (...args) =>
    dispatch(Actions.getSessionTypeSessionMedia(...args)),
  getSessionTypeProductionTypes: (...args) =>
    dispatch(Actions.getSessionTypeProductionTypes(...args)),
  getSessionTypeCategories: (...args) =>
    dispatch(Actions.getSessionTypeCategories(...args)),
  getSessionTypeRates: (...args) =>
    dispatch(Actions.getSessionTypeCategories(...args)),

  createProductionType: (...args) =>
    dispatch(Actions.createProductionType(...args)),
  deleteProductionType: (...args) =>
    dispatch(Actions.deleteProductionType(...args)),

  createSessionMedia: (...args) =>
    dispatch(Actions.createSessionMedia(...args)),
  deleteSessionMedia: (...args) =>
    dispatch(Actions.deleteSessionMedia(...args)),

  createSessionCategory: (...args) =>
    dispatch(Actions.createSessionCategory(...args)),
  deleteSessionCategory: (...args) =>
    dispatch(Actions.deleteSessionCategory(...args)),

  createSessionRate: (...args) => dispatch(Actions.createSessionRate(...args)),
  deleteSessionRate: (...args) => dispatch(Actions.deleteSessionRate(...args)),
});
class SessionTypePage extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    sessionTypes: PropTypes.array,
    getSessionTypes: PropTypes.func,
    getSessionTypeById: PropTypes.func,
    createSessionType: PropTypes.func,
    deleteSessionType: PropTypes.func,
    updateSessionType: PropTypes.func,
    getSessionTypeSessionMedia: PropTypes.func,
    getSessionTypeProductionTypes: PropTypes.func,
    getSessionTypeCategories: PropTypes.func,
    getSessionTypeRates: PropTypes.func,

    createProductionType: PropTypes.func,
    deleteProductionType: PropTypes.func,

    createSessionMedia: PropTypes.func,
    deleteSessionMedia: PropTypes.func,

    createSessionCategory: PropTypes.func,
    deleteSessionCategory: PropTypes.func,

    createSessionRate: PropTypes.func,
    deleteSessionRate: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, sessionTypes } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: sessionTypes.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getSessionTypes } = this.props;
    await Promise.all([getSessionTypes()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      sessionTypes,
      getSessionTypeById,
      createSessionType,
      deleteSessionType,
      updateSessionType,
      getSessionTypeSessionMedia,
      getSessionTypeProductionTypes,
      getSessionTypeCategories,
      getSessionTypeRates,
      history,
      createProductionType,
      deleteProductionType,
      createSessionMedia,
      deleteSessionMedia,
      createSessionCategory,
      deleteSessionCategory,
      createSessionRate,
      deleteSessionRate,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperSessionTypePage
        refresh={this.refresh.bind(this)}
        loading={loading}
        sessionTypes={sessionTypes}
        getSessionTypeById={getSessionTypeById}
        createSessionType={createSessionType}
        deleteSessionType={deleteSessionType}
        updateSessionType={updateSessionType}
        getSessionTypeSessionMedia={getSessionTypeSessionMedia}
        getSessionTypeRates={getSessionTypeRates}
        getSessionTypeProductionTypes={getSessionTypeProductionTypes}
        getSessionTypeCategories={getSessionTypeCategories}
        createProductionType={createProductionType}
        deleteProductionType={deleteProductionType}
        createSessionMedia={createSessionMedia}
        deleteSessionMedia={deleteSessionMedia}
        createSessionCategory={createSessionCategory}
        deleteSessionCategory={deleteSessionCategory}
        createSessionRate={createSessionRate}
        deleteSessionRate={deleteSessionRate}
        urlParams={urlParams}
        history={history}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionTypePage);
