import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// constants
import { META } from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class MusicianTableTotal extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    studioSession: PropTypes.object,
    variant: PropTypes.string,
  };

  render() {
    const { classes, variant, studioSession } = this.props;

    if (!META[variant]) return <div />;

    return (
      <div className={classes.container}>
        {/* MUSICIAN */}
        {META[variant].musician ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].musician }}
          >
            <Typography variant="h6" color="textPrimary">
              Total
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* INSTRUMENTS */}
        {META[variant].instrument ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].instrument }}
          />
        ) : (
          []
        )}

        {/* ADDRESS */}
        {META[variant].address ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].address }}
          />
        ) : (
          []
        )}

        {/* SSN */}
        {META[variant].ssn ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].ssn }}
          />
        ) : (
          []
        )}

        {/*  REHEARSAL COUNT   */}
        {META[variant].rehearsalCount ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].rehearsalCount }}
          >
            <Typography variant="caption" color="textSecondary">
              {studioSession.totalRehearsalWages / 100}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/*  PERFORMANCE COUNT   */}
        {META[variant].performanceCount ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].performanceCount }}
          >
            <Typography variant="caption" color="textSecondary">
              {studioSession.totalPerformanceWages / 100}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* HOURS WORKED  */}
        {META[variant].hoursWorked ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].hoursWorked }}
          />
        ) : (
          []
        )}

        {/* TUNES  */}
        {META[variant].tunes ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].tunes }}
          />
        ) : (
          []
        )}

        {/* OVERSCALE  */}
        {META[variant].overscale ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].overscale }}
          />
        ) : (
          []
        )}

        {/* BENEFITS ONLY  */}
        {META[variant].benefitsOnly ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].benefitsOnly }}
          />
        ) : (
          []
        )}

        {/* CARTAGE  */}
        {META[variant].cartage ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].cartage }}
          />
        ) : (
          []
        )}

        {/* DOUBLES  */}
        {META[variant].doubles ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].doubles }}
          >
            {variant === "LV" ? (
              <Typography variant="caption" color="textSecondary">
                {studioSession.totalDoublingWages / 100}
              </Typography>
            ) : (
              []
            )}
          </div>
        ) : (
          []
        )}

        {/* PREMIUM PERCENT  */}
        {META[variant].premiumPercent ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].premiumPercent }}
          >
            <Typography variant="caption" color="textSecondary">
              {studioSession.totalPremiumWages / 100}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* SCALE WAGES  */}
        {META[variant].scaleWages ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].scaleWages }}
          >
            <Typography variant="caption" color="textSecondary">
              {studioSession.totalWages / 100}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* WORK DUES  */}
        {META[variant].workDues ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].workDues }}
          >
            <Typography variant="caption" color="textSecondary">
              {studioSession.totalWorkDues / 100}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* PENSION  */}
        {META[variant].pension ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].pension }}
          >
            <Typography variant="caption" color="textSecondary">
              {studioSession.totalPensionContributions / 100}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* WELFARE  */}
        {META[variant].welfare ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].welfare }}
          >
            <Typography variant="caption" color="textSecondary">
              {studioSession.totalHWContributions / 100}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* NON PENSIONNABLE  */}
        {META[variant].nonPensionable ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].nonPensionable }}
          >
            <Typography variant="caption" color="textSecondary">
              {studioSession.totalNonPensionable / 100}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* OTHER PENSIONNABLE   */}
        {META[variant].otherPensionable ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].otherPensionable }}
          >
            <Typography variant="caption" color="textSecondary">
              {studioSession.totalOtherPensionable / 100}
            </Typography>
          </div>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(MusicianTableTotal);
