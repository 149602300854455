// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/TablePagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class UsagesList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    usages: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedUsage) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedUsage);
    }
  }

  render() {
    const { usages } = this.props;

    return (
      <Table
        data={usages}
        meta={[
          {
            path: "date",
            title: "Date",
            transform: (text) => moment(text).fromNow(),
            render: (t, d) => (
              <React.Fragment>
                <Typography variant="body2">{t}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment(d.date).format("lll")}
                </Typography>
              </React.Fragment>
            ),
            numeric: false,
          },
          {
            path: "description",
            title: "Description",
            numeric: false,
          },
        ]}
        noPaper
        onRowSelect={(p) => console.log(p)}
      />
    );
  }
}

export default withStyles(styles)(UsagesList);
