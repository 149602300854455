export default (theme) => ({
  container: {
    padding: 5,
  },
  alt: {
    padding: 8,
    border: "solid 1px black",
    borderRadius: 8,
  },
});
