import { GET } from "constants/methods";

const service = "rhapsody";
const projects = `/${service}/responseTest`;

export function responseTest(errorTarget) {
  const url = `${projects}?errorTarget=${errorTarget}`;
  return {
    method: GET,
    url,
  };
}
