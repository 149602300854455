import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const studios = `/${service}/studios`;

export function getStudios() {
  const url = `${studios}`;
  return {
    method: GET,
    url,
  };
}

export function getStudioById(id) {
  const url = `${studios}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getStudioStudioSessions(id) {
  const url = `${studios}/${id}/workSessions`;
  return {
    method: GET,
    url,
  };
}

export function getStudioProjects(id) {
  const url = `${studios}/${id}/projects`;
  return {
    method: GET,
    url,
  };
}

export function updateStudio(id, body) {
  const url = `${studios}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteStudio(id) {
  const url = `${studios}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createStudio(body) {
  const url = `${studios}`;
  return {
    method: POST,
    url,
    body,
  };
}
