// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class EventStatusesList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    eventStatuses: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedEventStatuse) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedEventStatuse);
    }
  }

  render() {
    const { eventStatuses } = this.props;

    return (
      <Table
        data={eventStatuses}
        meta={[
          {
            path: "id",
            title: "ID",
            width: 30,
          },
          {
            path: "icon",
            title: "",
            numeric: false,
            render: (t) => <img src={t} alt={"event"} style={{ width: 35 }} />,
            width: 35,
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "color",
            title: "Color",
            numeric: false,
            render: (t) => (
              <div
                style={{
                  height: 25,
                  width: 25,
                  background: t,
                  borderRadius: 25,
                }}
              />
            ),
          },
          {
            path: "colorRead",
            title: "Read Color",
            numeric: false,
            render: (t) => (
              <div
                style={{
                  height: 25,
                  width: 25,
                  background: t,
                  borderRadius: 25,
                }}
              />
            ),
          },
          {
            path: "mercuryStageID",
            title: "Stage ID",
            numeric: false,
          },
        ]}
        title={"Event Statuses"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(EventStatusesList);
