import {
  SET_SELECTED_COLUMN,
  SUBMIT_SYNC_INPUT,
  SYNC_INPUT_HANDLE_CHANGE,
} from "constants/syncInput";

export const syncInputHandleChange = (value) => async (dispatch) =>
  dispatch({
    type: SYNC_INPUT_HANDLE_CHANGE,
    value,
  });

export const submitSyncInput = (value) => async (dispatch) =>
  dispatch({
    type: SUBMIT_SYNC_INPUT,
    value,
  });

export const setSelectedColumn = (value) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_COLUMN,
    value,
  });
