import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const fileImports = `/${service}/fileImports`;

export function getFileImports() {
  const url = `${fileImports}`;
  return {
    method: GET,
    url,
  };
}

export function getFileImportById(id) {
  const url = `${fileImports}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateFileImport(id, body) {
  const url = `${fileImports}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteFileImport(id) {
  const url = `${fileImports}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createFileImport(body) {
  const url = `${fileImports}`;
  return {
    method: POST,
    url,
    body,
  };
}
