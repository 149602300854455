import { combineReducers } from "redux"; //eslint-disable-line
import activeStudioSessions from "./activeStudioSessions";
import activities from "./activities";
import app from "./app";
import archivedStudioSessions from "./archivedStudioSessions";
import bazaarCustomer from "./bazaarCustomer";
import cards from "./cards";
import cart from "./cart";
import configurations from "./configurations";
import currentCompany from "./currentCompany";
import customers from "./customers";
import emails from "./emails";
import errors from "./errors";
import eventStatuses from "./eventStatuses";
import googleContacts from "./googleContacts";
import groups from "./groups";
import groupsById from "./groupsById";
import instrumentFamilies from "./instrumentFamilies";
import instruments from "./instruments";
import instrumentSections from "./instrumentSections";
import layoutPresets from "./layoutPresets";
import musicianGroups from "./musicianGroups";
import musicians from "./musicians";
import nestedInstruments from "./nestedInstruments";
import payrolls from "./payrolls";
import posts from "./posts";
import projectAlternates from "./projectAlternates";
import projectHiring from "./projectHiring";
import projects from "./projects";
import projectSections from "./projectSections";
import quotes from "./quotes";
import restriction440 from "./restriction440";
import roles from "./roles";
import rules from "./rules";
import searchPresets from "./searchPresets";
import sectionRoles from "./sectionRoles";
import serviceNames from "./serviceNames";
import sessionCategories from "./sessionCategories";
import sessionTypes from "./sessionTypes";
import stages from "./stages";
import studios from "./studios";
import studioSessionDashboard from "./studioSessionDashboard";
import studioSessions from "./studioSessions";
import studioSessionStatuses from "./studioSessionStatuses";
import subscriptions from "./subscriptions";
import sumbitSyncInput from "./sumbitSyncInput";
import syncInput from "./syncInput";
import syncInputSelectedColumn from "./syncInputSelectedColumn";
import user from "./user";
import userApps from "./userApps";
import users from "./users";
export default combineReducers({
  errors,
  restriction440,
  user,
  cart,
  app,
  projectSections,
  searchPresets,
  users,
  cards,
  roles,
  rules,
  syncInputSelectedColumn,
  userApps,
  serviceNames,
  instruments,
  customers,
  projects,
  studioSessions,
  musicians,
  sessionTypes,
  sessionCategories,
  bazaarCustomer,
  instrumentFamilies,
  instrumentSections,
  payrolls,
  sectionRoles,
  eventStatuses,
  studios,
  activities,
  currentCompany,
  studioSessionStatuses,
  studioSessionDashboard,
  subscriptions,
  archivedStudioSessions,
  activeStudioSessions,
  nestedInstruments,
  projectAlternates,
  layoutPresets,
  configurations,
  emails,
  posts,
  quotes,
  googleContacts,
  syncInput,
  sumbitSyncInput,
  projectHiring,
  musicianGroups,
  groupsById,
  groups,
  stages,
});
