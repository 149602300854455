// import PropTypes from 'prop-types';
// material-ui
import { Chip, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Send from "@material-ui/icons/Send";
import TmpOrganizationUser from "hooks/TmpOrganizationUser";
import React from "react";
// styles

export default function OrganizationInvite({
  open,
  close,
  organization,
  api,
  refresh,
  NotificationCenter,
}) {
  const [users, setUsers] = React.useState([]);
  const [textInput, setTextInput] = React.useState("");
  const [advanced] = React.useState(false);
  const [joinToggle, setJoinToggle] = React.useState(true);
  const [key, setKey] = React.useState(new Date().getTime());

  const extractEmails = (text) => {
    return (
      text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi) || []
    );
  };

  const detected = extractEmails(textInput);

  const deleteTmpOrganizationUser = (index) => {
    let _users = [...users];
    _users.splice(index, 1);
    setUsers(_users);
  };

  const setInstantToAll = () => {
    let _users = [...users];

    setUsers(_users);
    setJoinToggle(!joinToggle);
    setKey(new Date().getTime());
  };

  const addTmpOrganizationUser = () => {
    let _users = [...users];
    _users.push({
      firstName: "",
      lastName: "",
      inviteEmail: "",
      organizationID: organization.id,
      key: new Date().getTime(),
    });
    setUsers(_users);
  };

  const invite = async () => {
    if (advanced) {
      await api.createOrganizationUser(users);
    } else {
      const usrs = [];
      for (const key in detected) {
        if (detected.hasOwnProperty(key)) {
          const inviteEmail = detected[key];
          usrs.push({
            organizationID: organization.id,
            inviteEmail,
          });
        }
      }
      await api.createOrganizationUser(usrs);
    }

    NotificationCenter.sweetAlert({
      title: "Invitations Sent!",
      success: true,
      timestamp: new Date().getTime(),
    });
    close();
    setTimeout(NotificationCenter.hide, 2000);
    setTimeout(refresh, 500);
  };

  return (
    <Dialog
      open={open}
      maxWidth={advanced ? "sm" : "xs"}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Invite People to the{" "}
        <span style={{ color: "#2196f3" }}>{organization.name}</span>{" "}
        Organization
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {!advanced ? (
            <TextField
              variant="filled"
              fullWidth
              multiline
              label="Email Adresses"
              placeholder="john@doe.com, jeanne@doe.com"
              size="small"
              value={textInput}
              rowsMax={3}
              helperText={
                detected.length <= 1
                  ? `${detected.length} Email Detected`
                  : `${detected.length} Emails Detected`
              }
              onChange={(e) => setTextInput(e.target.value)}
            />
          ) : (
            []
          )}
          {advanced ? (
            <Grid
              container
              key={key}
              spacing={1}
              style={{ maxHeight: 420, overflow: "scroll" }}
            >
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container spacing={1}>
                  <Grid item xs={1} />
                  {/* <Grid item xs={3}>
                    <Typography variant="caption">First Name</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="caption">Last Name</Typography>
                  </Grid> */}
                  <Grid item xs={9}>
                    <Typography variant="caption">Email</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Set to All">
                      <Typography
                        variant="caption"
                        onClick={setInstantToAll}
                        style={{ cursor: "pointer" }}
                      >
                        Instant Join
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              {users.map((u, i) => (
                <Grid item xs={12} key={u.key}>
                  <TmpOrganizationUser
                    user={u}
                    index={Number(i)}
                    onDelete={deleteTmpOrganizationUser}
                  />
                </Grid>
              ))}
              <Grid item>
                <Chip
                  label="+ User"
                  variant="outlined"
                  size="small"
                  onClick={addTmpOrganizationUser}
                />
              </Grid>
            </Grid>
          ) : (
            []
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          style={{
            textTransform: "none",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={invite}
          color="primary"
          variant="contained"
          style={{
            background: "#607d8b",
            color: "white",
            textTransform: "none",
          }}
          autoFocus
        >
          <Send /> Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
