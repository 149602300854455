export default {
  root: {
    position: "relative",
    display: "flex",
    fontFamily: "Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    fontSize: "14px",
    boxSizing: ["content-box", "border-box"],
    textOverflow: "clip",
    border: "none",
  },
  container: { overflow: "hidden" },
  nodes: {
    position: "absolute",
    zIndex: 1000,
    width: "100%",
    height: "100%",
  },
  svg: {
    width: "100%",
    height: "100%",
    padding: "2px",
    backgroundOrigin: "padding-box",
    backgroundClip: "border-box",
    backgroundPosition: "-1px -1px, -1px -1px",
  },
};
