import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class WrapperActionsPage extends PureComponent {
  static propTypes = {
    processBazaar: PropTypes.func,
    responseTest: PropTypes.func,
  };

  render() {
    const { processBazaar } = this.props;

    return (
      <Page helmet="Actions">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              Process Bazaar
            </Typography>
            <Divider />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={processBazaar}
            >
              Process
            </Button>
          </Grid>
        </Grid>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperActionsPage);
