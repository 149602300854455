import { RESTRICTION_440 } from "constants/restriction440";

export default (state = { timestamp: 0 }, action) => {
  switch (action.type) {
    case RESTRICTION_440:
      return action.payload;
    default:
      return state;
  }
};
