import { Chip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
// material-ui
import { withStyles } from "@material-ui/styles";
import * as Actions from "actions";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import { connect } from "react-redux";
import styles from "./styles";
import WorkSessionCalendar from "./WorkSessionCalendar";
// internal
import WorkSessionTable from "./WorkSessionTable";

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  setPreferedWorkSessionPresentation: (...args) =>
    dispatch(Actions.setPreferedWorkSessionPresentation(...args)),
});

class WorkSessions extends PureComponent {
  static propTypes = {
    workSessions: PropTypes.array,
    project: PropTypes.bool,
    disabled: PropTypes.bool,
    archived: PropTypes.bool,
    setSelectedCalendarDay: PropTypes.func,
    setPreferedWorkSessionPresentation: PropTypes.func,
    onSelect: PropTypes.func,
    addButton: PropTypes.node,
    width: PropTypes.string,
  };

  static contextTypes = {
    TourCenter: PropTypes.object,
    WebsocketCenter: PropTypes.object,
    Notify: PropTypes.object,
  };

  getContent() {
    const {
      workSessions,
      setSelectedCalendarDay,
      onSelect,
      project,
      projectID,
      history,
      app,
      setPreferedWorkSessionPresentation,
      width,
      refresh,
    } = this.props;

    const { WebsocketCenter } = this.context;

    let _workSessions = workSessions.sort(
      (a, b) =>
        moment(a.dateFromUTC).valueOf() - moment(b.dateFromUTC).valueOf()
    );

    let presentation = app.workSessionPresentation;
    if (width === "sm" || width === "xs") presentation = "list";

    switch (presentation) {
      case "list":
        return (
          <WorkSessionTable
            app={app}
            setPreferedWorkSessionPresentation={
              setPreferedWorkSessionPresentation
            }
            refresh={refresh}
            history={history}
            centrifuge={WebsocketCenter}
            workSessions={_workSessions}
            onSelect={onSelect}
          />
        );
      case "calendar":
        return (
          <WorkSessionCalendar
            history={history}
            archived={project}
            projectID={projectID}
            workSessions={_workSessions}
            centrifuge={WebsocketCenter}
            setSelectedCalendarDay={setSelectedCalendarDay}
            onSelect={onSelect}
          />
        );
      default:
        return (
          <WorkSessionTable
            history={history}
            refresh={refresh}
            workSessions={_workSessions}
            centrifuge={WebsocketCenter}
            onSelect={onSelect}
          />
        );
    }
  }

  render() {
    const { workSessions, addButton } = this.props;
    const { TourCenter } = this.context;

    if (workSessions.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              <i style={{ fontSize: 70 }} class="fad fa-calendar-day"></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No Work Sessions
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Add all dates, times, and meetings required for your Project.
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              onClick={() => TourCenter.start(2)}
              label="+ New Work Session"
            />
          </Grid>
          <br />
          <Grid item>{addButton}</Grid>
        </Grid>
      );
    }

    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-start",
        }}
      >
        <div style={{ flexGrow: 1, display: "flex", marginTop: 8 }}>
          {this.getContent()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(styles)(WorkSessions)));
