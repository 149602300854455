import { InputBase } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function SyncInput({
  save,
  name,
  numeric,
  multiply,
  value,
  handleChange,
}) {
  const [selectable, setSelectable] = React.useState(false);
  const [selected, setSelected] = React.useState(false);
  const [v, setV] = React.useState(value);
  const [rv, setRv] = React.useState(value);

  const syncValue = useSelector((state) => state.syncInput);
  const sumbitSyncInput = useSelector((state) => state.sumbitSyncInput);
  const syncInputSelectedColumn = useSelector(
    (state) => state.syncInputSelectedColumn
  );

  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const input = React.useRef();

  React.useEffect(() => {
    if (syncInputSelectedColumn === name) {
      setSelected(true);
      input.current?.focus();
      input.current?.select();
    } else {
      setSelected(false);
    }
  }, [syncInputSelectedColumn]); //eslint-disable-line

  React.useEffect(() => {
    setV(value);
  }, [value]);

  React.useEffect(() => {
    if (selected) {
      setV(syncValue);
    }
  }, [syncValue]); //eslint-disable-line

  React.useEffect(() => {
    if (sumbitSyncInput && selected) {
      save(name, v, numeric, multiply).then((r) => r !== null && setRv(r));
    }
  }, [sumbitSyncInput]); //eslint-disable-line

  useHotkeys(
    "*",
    (e) => {
      if (e.keyCode === 91 && e.type === "keydown") {
        setSelectable(true);
      }
      if (e.keyCode === 91 && e.type === "keyup") {
        setSelectable(false);
      }
      if (e.code === "Escape") {
        setSelected(false);
        if (syncInputSelectedColumn) dispatch(Actions.setSelectedColumn(""));
      }
    },
    { enableOnTags: ["INPUT"], keyup: true, keydown: true }
  );

  return (
    <InputBase
      inputRef={input}
      onKeyPress={async (e) => {
        if (v !== rv) {
          if (e.key === "Enter") {
            if (!selected) {
              save(name, v, numeric, multiply).then(
                (r) => r !== null && setRv(r)
              );
            } else {
              dispatch(Actions.submitSyncInput());
              setRv(v);
            }
          }
        }
      }}
      onClick={() => {
        if (selectable) {
          setSelected((prev) => !prev);
        }
      }}
      onBlur={async () => {
        if (v !== rv) {
          if (!selected) {
            save(name, v, numeric, multiply).then(
              (r) => r !== null && setRv(r)
            );
          } else {
            dispatch(Actions.submitSyncInput());
            setRv(v);
          }
        }
      }}
      value={v}
      onChange={(e) => {
        if (selected) {
          dispatch(Actions.syncInputHandleChange(e.target.value));
        } else {
          setV(e.target.value);
        }
      }}
      className={classes.inputBase}
      style={{
        boxShadow: selectable
          ? "rgba(155,155,155,0.3) 0px 2px 6px"
          : "rgba(155,155,155,0.3) 0px 0px 0px",
        transition: "box-shadow .3s",
        borderColor: selected ? "#2196f3" : "rgba(155,155,155,0.3)",
      }}
    />
  );
}
