// import PropTypes from 'prop-types';
// material-ui
import {
  IconButton,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import React from "react";
import { useDispatch } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function MusicianActions({
  musician,
  selectedSessions,
  projectID,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles(styles);

  const dispatch = useDispatch();

  const updateProjectMercury = (interactor) => {
    setAnchorEl(null);
    const body = {
      mercuryInteractorID: interactor.id,
      sessionIDs: selectedSessions.reduce((a, v) => {
        a.push(v.id);
        return a;
      }, []),
      musicianIDs: [musician.id],
    };

    dispatch(Actions.updateProjectMercury(projectID, body));
  };

  return (
    <div>
      {musician?.projectMusician?.validInteractors ? (
        <Tooltip title="Answer for the Musician">
          <IconButton
            size="small"
            style={{ background: "rgba(155,155,155,0.3)" }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <i
              class="fas fa-reply"
              style={{
                fontSize: 14,
                color: "#000000",
                padding: 4,
              }}
            ></i>
          </IconButton>
        </Tooltip>
      ) : (
        []
      )}
      <Menu
        open={Boolean(anchorEl)}
        disableEnforceFocus
        disableRestoreFocus
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "right",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "left",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
      >
        <div
          style={{
            maxWidth: 280,
            padding: 4,
            margin: 8,
            background: "rgba(155,155,155,0.1)",
            border: "solid 1px rgba(155,155,155,0.3)",
            borderRadius: 4,
          }}
        >
          <Typography variant="caption">
            <i class="fas fa-exclamation-triangle"></i> <b>IMPORTANT NOTICE:</b>{" "}
            You're about to answer <u>for</u> the musician on the selected work
            sessions. A confirmation email will be sent to the musician.
            Rhapsody will log that you did this admin action.
          </Typography>
        </div>
        {musician?.projectMusician?.validInteractors
          ?.filter((s) => s.segueID)
          ?.filter(
            (s, index, self) => index === self.findIndex((t) => t.id === s.id)
          )
          ?.map((s) => (
            <MenuItem onClick={() => updateProjectMercury(s)} divider>
              <ListItemAvatar>
                <i class={`${s.icon}`} style={{ color: s.color }} />
              </ListItemAvatar>
              <ListItemText primary={s.name} />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
