import { networkAction } from "helpers/network/networkAction";

import {
  GET_PRODUCTION_TYPES,
  GET_PRODUCTION_TYPE_BY_ID,
  DELETE_PRODUCTION_TYPE,
  UPDATE_PRODUCTION_TYPE,
  CREATE_PRODUCTION_TYPE,
} from "constants/productionTypes";

import * as Api from "api";

export const getProductionTypes = () => async (dispatch) =>
  networkAction(dispatch, GET_PRODUCTION_TYPES, Api.getProductionTypes, []);

export const getProductionTypeById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PRODUCTION_TYPE_BY_ID,
    Api.getProductionTypeById,
    [id]
  );

export const deleteProductionType = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PRODUCTION_TYPE, Api.deleteProductionType, [
    id,
  ]);

export const createProductionType = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PRODUCTION_TYPE, Api.createProductionType, [
    body,
  ]);

export const updateProductionType = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PRODUCTION_TYPE, Api.updateProductionType, [
    id,
    body,
  ]);
