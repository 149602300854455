import { Icon } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import People from "@material-ui/icons/People";
import StarsIcon from "@material-ui/icons/Star";
import TransferWithinAStationIcon from "@material-ui/icons/SwapCalls";
import { Alert, AlertTitle } from "@material-ui/lab";
// material-ui
import { withStyles } from "@material-ui/styles";
import { ChoiceModal } from "components";
import Autofill from "components/Autofill";
// components
import Loading from "components/Loading";
import ProjectFamily from "components/ProjectFamily";
// constants
import { REACT_APP_API } from "config";
import { buildForURL, getJsonFromUrl, removeURLParameter } from "helpers";
// hooks
import InstrumentFamiliesMenu from "hooks/InstrumentFamiliesMenu";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class ProjectAlternates extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    api: PropTypes.object,
    project: PropTypes.object,
    sectionRoles: PropTypes.array,
    layoutPresets: PropTypes.array,
    history: PropTypes.object,
    disabled: PropTypes.bool,
    studioSessions: PropTypes.array,
    changeIndex: PropTypes.func,
    template: PropTypes.bool,
  };

  static contextTypes = {
    SubscriptionCenter: PropTypes.object,
    NotificationCenter: PropTypes.object,
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { project, subscription } = this.props;
    this.state = {
      loading: true,
      layoutLoading: false,
      nextStepOpen: false,
    };
    subscription?.on("publish", this.wsMessageReceive.bind(this));
    this.init();
    this.refresh();
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  wsMessageReceive({ se, data }) {
    if (
      data.method === "GET" &&
      data.resource === "projects" &&
      this._ismounted
    ) {
      this.refresh();
    }
  }

  getMusicianCount() {
    const { projectFamilies } = this.state;
    const r = {
      total: 0,
      primary: 0,
      secondary: 0,
    };

    for (const key in projectFamilies) {
      if (projectFamilies.hasOwnProperty(key)) {
        const family = projectFamilies[key];
        for (const j in family.musicians) {
          if (family.musicians.hasOwnProperty(j)) {
            const musician = family.musicians[j];
            r.total += 1;
            if (musician.prime) {
              r.primary += 1;
            } else {
              r.secondary += 1;
            }
          }
        }
      }
    }

    return r;
  }

  async init() {
    const { api } = this.props;

    const resps = await Promise.all([
      api.getInstrumentFamilies(),
      api.getInstrumentSections(),
    ]);

    this.setState({
      instrumentFamilies: resps[0].payload,
      instrumentSections: resps[1].payload,
    });
  }

  async refresh() {
    const { api, project } = this.props;
    const r = await api.getProjectSections(project.id);

    if (r.success) {
      const projectSections = r.payload;
      const projectFamilies = [];
      let projectMusicians = [];
      for (const key in projectSections) {
        if (projectSections.hasOwnProperty(key)) {
          const element = projectSections[key];
          const familyIndex = projectFamilies.findIndex(
            (f) => f.id === element.familyID
          );
          projectMusicians = projectMusicians.concat(element.projectMusicians);
          if (familyIndex === -1) {
            projectFamilies.push({
              name: element.familyName,
              id: element.familyID,
              sections: [element],
              musicians: element.projectMusicians
                ? element.projectMusicians
                : 0,
            });
          } else {
            projectFamilies[familyIndex].sections.push(element);
            if (element.projectMusicians && element.projectMusicians.length) {
              projectFamilies[familyIndex].musicians = projectFamilies[
                familyIndex
              ].musicians.concat(
                //eslint-disable-line
                element.projectMusicians
              );
            }
          }
        }
      }

      this.setState({
        loading: false,
        projectFamilies,
        refreshKey: new Date().getTime(),
      });
    }
  }

  async createProjectAlternates(e, autofillID) {
    const { api, project, history } = this.props;
    const _urlParams = getJsonFromUrl(window.location);
    const body = e.slice(0);
    this.setState({ layoutLoading: true });

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        body[key].projectID = project.id;
      }
    }

    const r = await api.createProjectAlternate(body);
    history.push({
      search: removeURLParameter("instrumentationRequired"),
    });

    if (r.success) {
      await this.refresh();
      if (_urlParams.instrumentationRequired) {
        this.setState({ nextStepOpen: true });
      }
      if (autofillID) {
        api.projectAutofill(project.id, {
          projectID: autofillID,
        });
      }
    }
    this.setState({ layoutLoading: false });
  }

  render() {
    const {
      classes,
      api,
      sectionRoles,
      layoutPresets,
      project,
      history,
      disabled,
      template,
      studioSessions,
      changeIndex,
    } = this.props;

    const { SubscriptionCenter, NotificationCenter } = this.context;

    const {
      instrumentFamilies,
      instrumentSections,
      projectFamilies,
      layoutLoading,
      loading,
      refreshKey,
      nextStepOpen,
    } = this.state;

    const count = this.getMusicianCount();

    return (
      <>
        {loading ? (
          <Loading message={"Loading"} />
        ) : (
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={2}
            className={classes.container}
          >
            <Grid item xs={12}>
              <Alert
                // action={
                //   <Button variant="outlined" color="inherit" size="small">
                //     Show me how
                //   </Button>
                // }
                icon={<Icon className="fad fa-chair" />}
                severity="info"
              >
                <AlertTitle>Seating</AlertTitle>
                Edit your Seating by assigning Positions, Chairs, Alternates,
                Memos, and Call order
              </Alert>
            </Grid>
            {!project.archived && !disabled ? (
              <Grid item>
                <Grid container>
                  <Grid item>
                    <InstrumentFamiliesMenu
                      api={api}
                      template={template}
                      history={history}
                      primeEnabled
                      layoutPresets={layoutPresets}
                      NotificationCenter={NotificationCenter}
                      SubscriptionCenter={SubscriptionCenter}
                      instrumentFamilies={instrumentFamilies}
                      instrumentSections={instrumentSections}
                      onSelect={this.createProjectAlternates.bind(this)}
                    />
                  </Grid>
                  {count.total ? (
                    <Grid item>
                      <Autofill
                        refresh={this.refresh.bind(this)}
                        history={history}
                        project={project}
                      />
                    </Grid>
                  ) : (
                    []
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid item />
            )}
            {count.total ? (
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Tooltip
                      title={`${count.primary} Primary Position${
                        count.primary ? "s" : ""
                      }`}
                    >
                      <Chip
                        label={count.primary}
                        size="small"
                        style={{
                          background: "#D7B740",
                          color: "white",
                          marginRight: 8,
                        }}
                        icon={<StarsIcon style={{ color: "white" }} />}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={`${count.secondary} Alternate Position${
                        count.secondary ? "s" : ""
                      }`}
                    >
                      <Chip
                        label={count.secondary}
                        size="small"
                        style={{
                          background: "#607d8b",
                          color: "white",
                          marginRight: 8,
                        }}
                        icon={
                          <TransferWithinAStationIcon
                            style={{ color: "white" }}
                          />
                        }
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={`${count.total} Total Position${
                        count.total ? "s" : ""
                      }`}
                    >
                      <Chip
                        label={count.total}
                        size="small"
                        variant="outlined"
                        icon={<People />}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              []
            )}
            {count.total ? (
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <Chip
                  icon={<OpenInNewIcon style={{ fontSize: 18 }} />}
                  size="small"
                  onClick={() =>
                    window.open(
                      `${REACT_APP_API}/rhapsody/projects/roster?token=${project.publicToken}`,
                      "_blank"
                    )
                  }
                  label={"Share as PDF"}
                />
                <Chip
                  size="small"
                  onClick={() =>
                    window.open(
                      `${REACT_APP_API}/rhapsody/projects/${project.id}/rosterCSV`,
                      "_blank"
                    )
                  }
                  style={{ marginLeft: 4 }}
                  label={
                    <span>
                      <i class="fad fa-file-csv"></i> Download CSV
                    </span>
                  }
                />
              </Grid>
            ) : (
              []
            )}
            {projectFamilies && projectFamilies.length ? (
              <Grid item xs={12}>
                {projectFamilies &&
                  projectFamilies.map((pf) => (
                    <ProjectFamily
                      api={{
                        ...api,
                        refresh: this.refresh.bind(this),
                      }}
                      project={project}
                      history={history}
                      projectFamily={pf}
                      layoutPresets={layoutPresets}
                      refreshKey={refreshKey}
                      sectionRoles={sectionRoles}
                      disabled={project.archived || disabled}
                      key={pf.id}
                    />
                  ))}
              </Grid>
            ) : (
              []
            )}
            {layoutLoading && projectFamilies.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  height: "60vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <CircularProgress color="secondary" />
                  <Typography variant="h6" color="textPrimary">
                    Please Wait
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    We are creating the positions
                  </Typography>
                </div>
              </Grid>
            ) : (
              []
            )}
            {projectFamilies &&
            projectFamilies.length === 0 &&
            !layoutLoading ? (
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
                style={{ height: "50vh", textAlign: "center" }}
              >
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {" "}
                    <i style={{ fontSize: 70 }} class="fad fa-chair"></i>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    Seating
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Edit your Seating by assigning Positions, Chairs,
                    Alternates, Memos, and Call order.
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        )}
        <ChoiceModal
          open={nextStepOpen}
          onClose={() => this.setState({ choiceOpen: false })}
          confetti
          title="Project has been created! Next Steps:"
          options={[
            {
              title: "Edit Seating",
              subtitle:
                "Edit your Seating by assigning Positions, Chairs, Alternates, Memos, and Call Order",
              icon: <Icon className="fad fa-chair" style={{ fontSize: 32 }} />,
              color: "#9e9e9e",
              callback: () => this.setState({ nextStepOpen: false }),
            },
            {
              title: "Add Work Sessions",
              subtitle:
                "Add all dates, times, and meetings required for your Project",
              icon: (
                <Icon
                  className="fad fa-calendar-day"
                  style={{ fontSize: 32 }}
                />
              ),
              color: "#2196f3",
              callback: () => {
                this.setState({ nextStepOpen: false });
                history.push({
                  search: buildForURL({
                    id: project.id,
                    index: 1,
                    workSessionScheduler: true,
                  }),
                });
              },
            },
          ]}
        />
      </>
    );
  }
}

export default withStyles(styles)(ProjectAlternates);
