// import PropTypes from 'prop-types';
// material-ui
import { Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getCurrentStageName } from "helpers";
import moment from "moment";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

function Inspector({ i, stage, interactor, current, post, pre, meta }) {
  const classes = useStyles(styles);
  switch (i.kind) {
    case "checkbox":
      return (
        <div
          style={{
            flex: 1,
            textAlign: i.stageTriggered ? "right" : "left",
          }}
        >
          <Tooltip title={moment(i.createdAt).format("lll")}>
            <Chip
              className={interactor}
              label={
                <span>
                  <i
                    class={`${i.icon} p-right` || "fad fa-comment p-right"}
                  ></i>
                  {i.title}
                  <i class="fas fa-check-square p-left"></i>
                </span>
              }
              size="small"
            />
          </Tooltip>
          {/* {meta ? (
            <Meta
              date={i.createdAt}
              author={i.createdBy}
              align={i.stageTriggered ? "right" : "left"}
            />
          ) : (
            []
          )} */}
        </div>
      );
    case "signature":
      return (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Tooltip title={moment(i.createdAt).format("lll")}>
            <div className={interactor} style={{ borderRadius: 8, padding: 6 }}>
              <Typography variant="body2">{i.title}</Typography>
              <img
                src={i.message}
                alt="signature"
                style={{ width: "100%" }}
                className={classes.signature}
              />
            </div>
          </Tooltip>
          {/* {meta ? (
            <Meta
              date={i.createdAt}
              author={i.createdBy}
              align={i.stageTriggered ? "right" : "left"}
            />
          ) : (
            []
          )} */}
        </div>
      );
    case "file":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              textAlign: "left",
            }}
          >
            <Tooltip title={moment(i.createdAt).format("lll")}>
              <div
                style={{
                  borderRadius: 8,
                  padding: 8,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                className={interactor}
                onClick={() => window.open(i.message)}
              >
                <Typography variant="h3">
                  <i class={i.icon}></i>
                </Typography>
                <div style={{ marginLeft: 6 }}>
                  <Typography variant="body2">{i.title}</Typography>
                  <Typography variant="caption" style={{ opacity: 0.58 }}>
                    Click to Download
                  </Typography>
                </div>
              </div>
            </Tooltip>
            {/* {meta ? (
              <Meta
                date={i.createdAt}
                author={i.createdBy}
                align={i.stageTriggered ? "right" : "left"}
              />
            ) : (
              []
            )} */}
          </div>
        </div>
      );
    default:
      return (
        <div
          style={{
            flex: 1,
            textAlign: i.stageTriggered ? "right" : "left",
          }}
        >
          <Tooltip title={moment(i.createdAt).format("lll")}>
            <Typography variant="caption">
              <div
                className={interactor}
                style={{
                  lineBreak: "auto",
                }}
              >
                <i class={`${i.icon} p-right` || "fad fa-comment p-right"}></i>
                {i.message}
              </div>
            </Typography>
          </Tooltip>
          {/* {meta ? (
            <Meta
              date={i.createdAt}
              author={i.createdBy}
              align={i.stageTriggered ? "right" : "left"}
            />
          ) : (
            []
          )} */}
        </div>
      );
  }
}

function Meta({ date, author, align }) {
  return (
    <Tooltip title={moment(date).format("lll")}>
      <div
        style={{
          textAlign: align || "right",
        }}
      >
        <Typography
          variant="caption"
          color="textSecondary"
          style={{
            fontSize: 10,
          }}
        >
          {author}, {moment(date).fromNow()}
        </Typography>
      </div>
    </Tooltip>
  );
}

export default function MercuryJobStage({
  stage,
  current,
  validInteractors,
  onInteraction,
  chronological,
  onNudge,
}) {
  const [locked, setLocked] = React.useState(true);
  const classes = useStyles(styles);

  const interactors = current && validInteractors?.filter((i) => i.segueID);

  let inspectors = {
    pre: [],
    post: [],
  };

  for (const key in stage.inspectors) {
    if (Object.hasOwnProperty.call(stage.inspectors, key)) {
      const inspector = stage.inspectors[key];
      if (moment(inspector.createdAt).isAfter(moment(stage.createdAt))) {
        inspectors.post.push(inspector);
      }
      if (moment(inspector.createdAt).isBefore(moment(stage.createdAt))) {
        inspectors.pre.push(inspector);
      }

      if (moment(inspector.createdAt).isSame(moment(stage.createdAt))) {
        inspectors.post.push(inspector);
      }
    }
  }

  inspectors.pre = inspectors.pre.sort((a, b) => a.id - b.id);
  inspectors.post = inspectors.post.sort((a, b) => b.id - a.id);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: !chronological ? "column" : "column-reverse",
      }}
    >
      {inspectors.post?.map((i, index) => {
        let previousI;
        if (Number(index) > 0 && Number(index) <= inspectors.post.length) {
          previousI = inspectors.post[Number(index) - 1];
        }
        return (
          <div style={{ margin: "4px 0px" }}>
            <Inspector
              pre
              meta={
                !previousI ||
                moment(previousI.createdAt).format("YYYY/MM/DD") !==
                  moment(i.createdAt).format("YYYY/MM/DD")
              }
              stage={stage}
              current={current}
              interactor={classes.interactor}
              i={i}
            />
          </div>
        );
      })}
      <div
        style={{
          margin: "4px 0px",
          position: "sticky",
          top: chronological ? 0 : undefined,
          bottom: !chronological ? 0 : undefined,
          background: "white",
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <div
          className={classes.root}
          style={{
            border: current && `solid 1px ${stage.color}`,
            background: current && stage.color,
            color: current && "white",
          }}
        >
          <img
            src={stage.icon}
            alt={`${stage.name}-icon`}
            style={{
              height: 20,
              filter: current && "brightness(0) invert(1)",
            }}
          />
          <div style={{ flexGrow: 1, paddingLeft: 8 }}>
            <Typography variant="body2" style={{ lineHeight: 0.8 }}>
              <b>{getCurrentStageName(stage)}</b>
            </Typography>
          </div>
          <div className={classes.break} />
          {interactors?.length ? (
            <div className={classes.lock}>
              <Grid container spacing={1} justify="center">
                <i
                  onClick={() => setLocked(true)}
                  class="fad fa-lock-alt"
                  style={{
                    position: "absolute",
                    top: 4,
                    left: 4,
                    fontSize: 12,
                    cursor: "pointer",
                  }}
                ></i>
                {interactors.map((i) => (
                  <Grid item key={i.id}>
                    <Chip
                      label={i.name}
                      size="small"
                      onClick={() => onInteraction(i.id)}
                      style={{ background: "white" }}
                    />
                  </Grid>
                ))}
              </Grid>
              {locked ? (
                <div
                  className={classes.overlay}
                  onClick={() => setLocked(false)}
                >
                  <div style={{ flex: 1, textAlign: "center" }}>
                    <i class="fad fa-unlock-alt"></i>
                  </div>
                  <div style={{ flex: 3 }}>
                    <Typography
                      display="block"
                      variant="caption"
                      style={{ lineHeight: 1 }}
                    >
                      <b>Answer for the Musician</b>
                      <br />
                      <span style={{ fontSize: 10 }}>Click to unlock</span>
                    </Typography>
                  </div>
                </div>
              ) : (
                []
              )}
            </div>
          ) : (
            []
          )}
          {current && stage.nudgeable ? (
            <>
              <div className={classes.break} />
              <Chip
                label={
                  <span>
                    <i class="fas fa-bullhorn"></i> Nudge!
                  </span>
                }
                onClick={onNudge}
                size="small"
                style={{
                  width: "100%",
                  marginTop: 8,
                  background: "white",
                  color: stage.color,
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>

        <Meta
          date={stage.createdAt}
          author={stage.createdBy}
          align="right"
        ></Meta>
      </div>
      {inspectors.pre?.map((i, index) => {
        let previousI;
        if (Number(index) > 0 && Number(index) <= inspectors.pre.length) {
          previousI = inspectors.pre[Number(index) - 1];
        }
        return (
          <div style={{ margin: "4px 0px" }}>
            <Inspector
              pre
              meta={
                !previousI ||
                moment(previousI.createdAt).format("YYYY/MM/DD") !==
                  moment(i.createdAt).format("YYYY/MM/DD")
              }
              stage={stage}
              current={current}
              interactor={classes.interactor}
              i={i}
            />
          </div>
        );
      })}
    </div>
  );
}
