export default (theme) => ({
  container: {
    padding: 5,
  },
  chip: {
    height: 24,
    color: theme.palette.text.default,
    border: `solid 1px ${theme.palette.text.default}`,
    backgroundColor: theme.palette.background.default,
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
});
