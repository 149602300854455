import { Popover } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AppsIcon from "@material-ui/icons/Apps";
import Home from "@material-ui/icons/Home";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import App from "components/App";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

// styles
const styles = (theme) => ({
  container: {
    padding: 5,
  },
});

class Apps extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    darkMode: PropTypes.bool,
    userApps: PropTypes.array,
    history: PropTypes.object,
    baseHostname: PropTypes.string,
  };

  state = {
    open: false,
  };

  handleOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { darkMode, userApps, baseHostname, history } = this.props;
    const { anchorEl } = this.state;

    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <IconButton onClick={this.handleOpen.bind(this)}>
          {darkMode ? <AppsIcon /> : <AppsIcon style={{ color: "white" }} />}
        </IconButton>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorEl)}
          onClose={this.handleClose.bind(this)}
          anchorEl={anchorEl}
        >
          <div
            style={{
              width: 300,
              padding: 15,
            }}
          >
            <Grid container>
              {userApps.map((a) => (
                <Grid item xs={3}>
                  <App {...a} key={a.id} size={50} withName />
                </Grid>
              ))}
            </Grid>
            {history && baseHostname ? (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ paddingTop: 10 }}
              >
                <Grid item>
                  <Button
                    onClick={() => window.location.replace(baseHostname)}
                    variant="outlined"
                  >
                    <Home /> Home
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <div />
            )}
          </div>
        </Popover>
      </div>
    );
  }
}

export default withStyles(styles)(Apps);
