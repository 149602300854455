import { networkAction } from "helpers/network/networkAction";

import {
  GET_SESSION_MEDIAS,
  GET_SESSION_MEDIA_BY_ID,
  DELETE_SESSION_MEDIA,
  UPDATE_SESSION_MEDIA,
  CREATE_SESSION_MEDIA,
} from "constants/sessionMedias";

import * as Api from "api";

export const getSessionMedias = () => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_MEDIAS, Api.getSessionMedias, []);

export const getSessionMediaById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_MEDIA_BY_ID, Api.getSessionMediaById, [
    id,
  ]);

export const deleteSessionMedia = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SESSION_MEDIA, Api.deleteSessionMedia, [id]);

export const createSessionMedia = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SESSION_MEDIA, Api.createSessionMedia, [body]);

export const updateSessionMedia = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SESSION_MEDIA, Api.updateSessionMedia, [
    id,
    body,
  ]);
