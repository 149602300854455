import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperInstrumentFamiliePage from "../component/WrapperInstrumentFamiliePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  instrumentFamilies: state.instrumentFamilies,
});

const mapDispatchToProps = (dispatch) => ({
  getInstrumentFamilies: () => dispatch(Actions.getInstrumentFamilies()),
  getInstrumentFamilyById: (...args) =>
    dispatch(Actions.getInstrumentFamilyById(...args)),
  createInstrumentFamily: (...args) =>
    dispatch(Actions.createInstrumentFamily(...args)),
  deleteInstrumentFamily: (...args) =>
    dispatch(Actions.deleteInstrumentFamily(...args)),
  updateInstrumentFamily: (...args) =>
    dispatch(Actions.updateInstrumentFamily(...args)),
});
class InstrumentFamiliePage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    instrumentFamilies: PropTypes.array,
    getInstrumentFamilies: PropTypes.func,
    getInstrumentFamilyById: PropTypes.func,
    createInstrumentFamily: PropTypes.func,
    deleteInstrumentFamily: PropTypes.func,
    updateInstrumentFamily: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, instrumentFamilies } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: instrumentFamilies.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getInstrumentFamilies } = this.props;
    await Promise.all([getInstrumentFamilies()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      instrumentFamilies,
      getInstrumentFamilyById,
      createInstrumentFamily,
      deleteInstrumentFamily,
      updateInstrumentFamily,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperInstrumentFamiliePage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        instrumentFamilies={instrumentFamilies}
        getInstrumentFamilyById={getInstrumentFamilyById}
        createInstrumentFamily={createInstrumentFamily}
        deleteInstrumentFamily={deleteInstrumentFamily}
        updateInstrumentFamily={updateInstrumentFamily}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstrumentFamiliePage);
