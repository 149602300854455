import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
// import PropTypes from 'prop-types';
// material-ui
import Typography from "@material-ui/core/Typography";
import { Favorite } from "@material-ui/icons";
import MailIcon from "@material-ui/icons/Mail";
import MoreVert from "@material-ui/icons/MoreVert";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PinDropIcon from "@material-ui/icons/PinDrop";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import StarsIcon from "@material-ui/icons/Star";
import TransferWithinAStationIcon from "@material-ui/icons/SwapCalls";
// helpers
import { hexToRgbA } from "helpers/colors";
// hooks
import MusicianAvatar from "hooks/MusicianAvatar";
import React from "react";

export default function MusicianMenuItem({
  linkMusician,
  disabled,
  m,
  sectionRoles,
  musicianDetails,
  onMusicianEdit,
  showPrime,
  close,
}) {
  const [anchorEl, setAnchorEl] = React.useState(false);

  const closeDetails = () => {
    setAnchorEl(null);
  };

  const openDetails = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        onClick={!disabled ? () => linkMusician(m) : undefined}
        style={{
          display: "flex",
          cursor: disabled ? "forbiden" : "pointer",
          alignItems: "stretch",
          padding: 8,
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
          }}
        >
          <MusicianAvatar musician={m} />
        </div>
        <div style={{ flexGrow: 1 }}>
          <Typography variant="body2">
            {m.star ? (
              <Favorite style={{ color: "#e91e63", fontSize: 12 }} />
            ) : (
              []
            )}
            {m.nickName ||
              `${m.firstName} ${m.lastName} ${
                m.sectionRoleID
                  ? `(${
                      sectionRoles.find((sr) => sr.id === m.sectionRoleID).name
                    })`
                  : ""
              }`}
          </Typography>
          {m.instruments?.length && (
            <Typography variant="caption" color="textSecondary" display="block">
              {m.instruments
                ?.reduce((a, v) => {
                  a.push(v.name);
                  return a;
                }, [])
                .join(",")}
            </Typography>
          )}
          {m.groups &&
            m.groups.length &&
            m.groups.map((i) => (
              <Typography
                style={{
                  border: "solid 1px rgba(155,155,155,0.3)",
                  background: "rgba(155,155,155,0.1)",
                  padding: "0px 4px",
                  marginRight: 4,
                  borderRadius: 4,
                }}
                variant="caption"
              >
                {i.name}
              </Typography>
            ))}
          {m.projectNotes ? (
            <div
              style={{
                width: 150,
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginBottom: 4,
                fontSize: 10,
              }}
            >
              <Tooltip title={m.projectNotes}>
                <Typography
                  color="textSecondary"
                  variant="caption"
                  style={{
                    backgroundColor: "rgba(255,235,59,0.1)",
                    border: "solid 1px rgba(255,235,59,0.3)",
                    borderRadius: 4,
                    padding: 4,
                    fontSize: 10,
                    wordWrap: "break-word",
                  }}
                  noWrap
                >
                  {m.projectNotes}
                </Typography>
              </Tooltip>
            </div>
          ) : (
            []
          )}
          {/* DEPECATED */}
          {m.lastStatus ? (
            <Chip
              label={m.lastStatus.name}
              icon={
                <img
                  alt={m.lastStatus.name}
                  style={{ height: 15, width: 15, padding: 4 }}
                  src={m.lastStatus.icon}
                />
              }
              variant="outlined"
              size="small"
              style={{
                borderColor: m.lastStatus.color,
                color: m.lastStatus.color,
                background: hexToRgbA(m.lastStatus.color, 0.15),
              }}
            />
          ) : (
            []
          )}
          {/*DEPECATED */}
          {m.mercuryStage?.id ? (
            <Chip
              label={m.mercuryStage.name}
              icon={
                <img
                  alt={m.mercuryStage.name}
                  style={{ height: 15, width: 15, padding: 4 }}
                  src={m.mercuryStage.icon}
                />
              }
              variant="outlined"
              size="small"
              style={{
                borderColor: m.mercuryStage.color,
                color: m.mercuryStage.color,
                background: hexToRgbA(m.mercuryStage.color, 0.15),
              }}
            />
          ) : (
            []
          )}
        </div>
        {showPrime && m.order !== -1 ? (
          <div style={{ width: 70, display: "flex", justifyContent: "center" }}>
            <Tooltip title={m.prime ? "Primary" : "Alternate"}>
              <Chip
                size="small"
                icon={
                  m.prime ? (
                    <StarsIcon style={{ fontSize: 12, color: "white" }} />
                  ) : (
                    <TransferWithinAStationIcon
                      style={{ fontSize: 12, color: "white" }}
                    />
                  )
                }
                style={{
                  background: m.prime ? "#D7B740" : "#607d8b",
                  color: "white",
                }}
                label={m.order + 1}
              />
            </Tooltip>
            {disabled ? (
              <Grid item>
                <MoreVert
                  style={{
                    fontSize: 16,
                    cursor: "pointer",
                    color: "#9e9e9e",
                    padding: 4,
                  }}
                  onClick={openDetails}
                />
              </Grid>
            ) : (
              []
            )}
          </div>
        ) : (
          []
        )}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          disableEnforceFocus
          disableRestoreFocus
          onClose={closeDetails}
          style={{
            marginLeft: 18,
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <List dense style={{ width: 280 }}>
              <ListItem divider>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary={m.email} />
              </ListItem>
              {m.phone ? (
                <ListItem divider>
                  <ListItemIcon>
                    <PhoneIphoneIcon />
                  </ListItemIcon>
                  <ListItemText primary={m.phone} />
                </ListItem>
              ) : (
                []
              )}
              {m.address ? (
                <ListItem divider>
                  <ListItemIcon>
                    <PinDropIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${m.address || ""}`}
                    secondary={`${m.zipcode || ""} ${m.city || ""}`}
                  />
                </ListItem>
              ) : (
                []
              )}
              {m.comments ? (
                <ListItem divider>
                  <ListItemIcon>
                    <SpeakerNotesIcon />
                  </ListItemIcon>
                  <Tooltip title="Musician's Comment">
                    <ListItemText
                      style={{ background: "rgba(255,235,59,0.1)", padding: 2 }}
                      secondary={<div>{m.comments}</div>}
                    />
                  </Tooltip>
                </ListItem>
              ) : (
                []
              )}
              <ListItem
                divider
                button
                onClick={() => {
                  close();
                  onMusicianEdit(m);
                }}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="View Musician Card" />
              </ListItem>
            </List>
          </div>
        </Popover>
      </div>
      <Divider />
    </div>
  );
}
