import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
// material-ui
import { withStyles } from "@material-ui/styles";
import { Help } from "components";
import DateTime from "components/DateTime";
// custom
import Rehearsal from "components/Rehearsal";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class B10 extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    api: PropTypes.array,
    studioSession: PropTypes.object,
    studioSessionRehearsals: PropTypes.array,
    conciergeReadOnly: PropTypes.bool,
    refresh: PropTypes.func,
    archived: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { studioSession } = this.props;
    this.state = {
      ...studioSession,
      loading: false,
      newTuneLoading: false,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { api, refresh, studioSession } = this.props;

    if (studioSession[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await api.updateStudioSession(studioSession.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const {
      classes,
      api,
      studioSession,
      refresh,
      conciergeReadOnly,
      studioSessionRehearsals,
    } = this.props;

    const {
      loading,
      wageAgreement,
      newRehearsalLoading,
      afmLocal,
      totalSessionHours,
      hoursOfEmployment,
      episodeTitle,
      productionTypeID,
      sessionMediumID,
      sessionRateID,
      date,
      episodeNumber,
      originalReportForm,
      originalRecordingDate,
      memo,
      anchorEl,
      guestStars,
      network,
      releaseDate,
      airAndMinRehAmount,
      addRehPreRec,
      useOfTrack,
      span,
      mealPen,
      wrdBeMakeUp,
      multSpon,
      totalBase,
      engineer,
      conductor,
      producer,
      producerAddress,
      payrollNumber,
      title,
      archived,
      recorded,
      description,
    } = this.state;

    const sessionCategoriesToAdd = studioSession.sessionType.categories.filter(
      (sc) => studioSession.categories.find((c) => c.id === sc.id) === undefined
    );

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2">Title:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      fullWidth
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("title")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("title");
                      }}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={title}
                      onChange={this.handleChange("title")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2">Payroll #:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      fullWidth
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("payrollNumber");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("payrollNumber")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={payrollNumber}
                      onChange={this.handleChange("payrollNumber")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Project Description
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Contract Dates:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DateTime
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("date");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("date")}
                      noTime
                      className={archived ? undefined : classes.inputBase}
                      date={date}
                      onChange={this.handleChange("date")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">{"Program's Name:"}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("episodeTitle");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("episodeTitle")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={episodeTitle}
                      onChange={this.handleChange("episodeTitle")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Title or Show#:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("episodeNumber");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("episodeNumber")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={episodeNumber}
                      onChange={this.handleChange("episodeNumber")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Guest Stars:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("guestStars");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("guestStars")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={guestStars}
                      onChange={this.handleChange("guestStars")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Network:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("network");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("network")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={network}
                      onChange={this.handleChange("network")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Release Date:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DateTime
                      noTime
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("releaseDate");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("releaseDate")}
                      className={archived ? undefined : classes.inputBase}
                      date={releaseDate}
                      onChange={this.handleChange("releaseDate")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Additional Info
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Categories:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <div>
                          {archived !== true ? (
                            <Chip
                              variant="outlined"
                              avatar={
                                <Avatar style={{ background: "none" }}>
                                  <AddCircleOutline />
                                </Avatar>
                              }
                              label="Add Category"
                              onClick={this.handleClick}
                            />
                          ) : (
                            <div />
                          )}
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                          >
                            {sessionCategoriesToAdd &&
                              sessionCategoriesToAdd.map((sc) => (
                                <MenuItem
                                  disabled={archived}
                                  key={`${sc.id}_${sc.name}`}
                                  onClick={async () => {
                                    this.handleClose();
                                    await api.createStudioSessionCategory(
                                      studioSession.id,
                                      { categoryID: sc.id }
                                    );
                                    refresh();
                                  }}
                                >
                                  {sc.name}
                                </MenuItem>
                              ))}
                          </Menu>
                        </div>
                      </Grid>
                      {studioSession.categories.map((c) => (
                        <Grid item>
                          <Chip
                            label={c.name}
                            onDelete={
                              archived
                                ? undefined
                                : async () => {
                                    await api.deleteStudioSessionCategory(
                                      studioSession.id,
                                      { categoryID: c.id }
                                    );
                                    refresh();
                                  }
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Memo:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("memo");
                      }}
                      disabled={archived || conciergeReadOnly}
                      multiline
                      fullWidth
                      onBlur={() => this.save("memo")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={memo}
                      onChange={this.handleChange("memo")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Payment Info
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Production Type:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        inputProps={{
                          name: "Project",
                          id: "project-simple",
                        }}
                        disabled={recorded || archived}
                        value={productionTypeID}
                        onChange={(e) => {
                          this.state.productionTypeID = e.target.value; // eslint-disable-line
                          this.save("productionTypeID");
                        }}
                      >
                        {studioSession &&
                          studioSession.sessionType &&
                          studioSession.sessionType.productionTypes &&
                          studioSession.sessionType.productionTypes.map(
                            (pt) => (
                              <MenuItem key={`prodType_${pt.id}`} value={pt.id}>
                                {pt.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Medium:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        disabled={recorded || archived}
                        inputProps={{
                          name: "Project",
                          id: "project-simple",
                        }}
                        value={sessionMediumID}
                        onChange={(e) => {
                          this.state.sessionMediumID = e.target.value; // eslint-disable-line
                          this.save("sessionMediumID");
                        }}
                      >
                        {studioSession &&
                          studioSession.sessionType &&
                          studioSession.sessionType.sessionMedia &&
                          studioSession.sessionType.sessionMedia.map((sm) => (
                            <MenuItem
                              key={`sessionMedia_${sm.id}`}
                              value={sm.id}
                            >
                              {sm.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Rates:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        disabled={archived}
                        inputProps={{
                          name: "Project",
                          id: "project-simple",
                        }}
                        value={sessionRateID}
                        onChange={(e) => {
                          this.state.sessionRateID = e.target.value; // eslint-disable-line
                          this.save("sessionRateID");
                        }}
                      >
                        {studioSession &&
                          studioSession.sessionType &&
                          studioSession.sessionType.sessionRates &&
                          studioSession.sessionType.sessionRates.map((sr) => (
                            <MenuItem
                              key={`sessionRates_${sr.id}`}
                              value={sr.id}
                            >
                              {sr.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Session Description
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Wage Scale Agreement:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("wageAgreement");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("wageAgreement")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={wageAgreement}
                      onChange={this.handleChange("wageAgreement")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Local#:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("afmLocal");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("afmLocal")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={afmLocal}
                      onChange={this.handleChange("afmLocal")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2"># Musicians:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.musicianCount}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Hrs of Employment:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("hoursOfEmployment");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("hoursOfEmployment")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={hoursOfEmployment}
                      onChange={this.handleChange("hoursOfEmployment")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Total Session Hrs:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("totalSessionHours");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("totalSessionHours")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={totalSessionHours}
                      onChange={this.handleChange("totalSessionHours")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Producer:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("producer");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("producer")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={producer}
                      onChange={this.handleChange("producer")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Address:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("producerAddress");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("producerAddress")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={producerAddress}
                      onChange={this.handleChange("producerAddress")}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Contractor:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => { if (e.key === 'Enter') this.save('contractor'); }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save('contractor')}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={contractor}
                      onChange={this.handleChange('contractor')}
                    />
                  </Grid>
                </Grid> */}
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Engineer:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("engineer");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("engineer")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={engineer}
                      onChange={this.handleChange("engineer")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Conductor:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("conductor");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("conductor")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={conductor}
                      onChange={this.handleChange("conductor")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Help
                      title="Internal Notes:"
                      help="Anything useful for your own organization"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled={loading}
                      fullWidth
                      rows={3}
                      rowsMax={100}
                      multiline
                      onBlur={() => this.save("description")}
                      style={{ background: "rgba(255,235,59,0.1)" }}
                      className={classes.inputBase}
                      value={description || ""}
                      onChange={this.handleChange("description")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  New Use/ Re-Use
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Original Report Form #:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("originalReportForm");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("originalReportForm")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={originalReportForm}
                      onChange={this.handleChange("originalReportForm")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Original Recording Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DateTime
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          this.save("originalRecordingDate");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("originalRecordingDate")}
                      className={archived ? undefined : classes.inputBase}
                      date={originalRecordingDate}
                      onChange={this.handleChange("originalRecordingDate")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Typography variant="h6" color="textPrimary">
              Rehearsals/Pre- Record/Tape Use
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="caption" color="textPrimary">
                  Minutes
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" color="textPrimary">
                  Start
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" color="textPrimary">
                  {"Dism'd"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" color="textPrimary">
                  Hours
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" color="textPrimary">
                  Span
                </Typography>
              </Grid>
            </Grid>
            {studioSessionRehearsals.map((r) => (
              <Rehearsal
                key={`studioSessionTunes_${r.id}`}
                rehearsal={r}
                updateRehearsal={api.updateRehearsal}
                deleteRehearsal={api.deleteRehearsal}
                refresh={refresh}
                disabled={archived}
              />
            ))}
            {newRehearsalLoading ? (
              <Grid container justify="center">
                <Grid item>
                  <CircularProgress color="secondary" />
                </Grid>
              </Grid>
            ) : (
              <div />
            )}
            <Button
              variant="contained"
              color="secondary"
              disabled={archived}
              onClick={async () => {
                this.setState({ newRehearsalLoading: true });
                await api.createRehearsal({
                  sessionID: studioSession.id,
                });
                this.setState({ newRehearsalLoading: false });
                refresh();
              }}
            >
              Add Rehearsal
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Typography variant="h6" color="textPrimary">
              Pay Schedule
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="body2">Air & Min. Reh. $:</Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("airAndMinRehAmount");
                  }}
                  disabled={archived || conciergeReadOnly}
                  fullWidth
                  onBlur={() => this.save("airAndMinRehAmount")}
                  className={archived ? undefined : classes.inputBase}
                  defaultValue={airAndMinRehAmount}
                  onChange={this.handleChange("airAndMinRehAmount")}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body2">Add. Reh//Pre-Rec.:</Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("addRehPreRec");
                  }}
                  disabled={archived || conciergeReadOnly}
                  fullWidth
                  onBlur={() => this.save("addRehPreRec")}
                  className={archived ? undefined : classes.inputBase}
                  defaultValue={addRehPreRec}
                  onChange={this.handleChange("addRehPreRec")}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body2">Use of Track:</Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("useOfTrack");
                  }}
                  disabled={archived || conciergeReadOnly}
                  fullWidth
                  onBlur={() => this.save("useOfTrack")}
                  className={archived ? undefined : classes.inputBase}
                  defaultValue={useOfTrack}
                  onChange={this.handleChange("useOfTrack")}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body2">Span:</Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("span");
                  }}
                  disabled={archived || conciergeReadOnly}
                  fullWidth
                  onBlur={() => this.save("span")}
                  className={archived ? undefined : classes.inputBase}
                  defaultValue={span}
                  onChange={this.handleChange("span")}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Meal Pen.:</Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("mealPen");
                  }}
                  disabled={archived || conciergeReadOnly}
                  fullWidth
                  onBlur={() => this.save("mealPen")}
                  className={archived ? undefined : classes.inputBase}
                  defaultValue={mealPen}
                  onChange={this.handleChange("mealPen")}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body2">Wrd. Be/Make up:</Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("wrdBeMakeUp");
                  }}
                  disabled={archived || conciergeReadOnly}
                  fullWidth
                  onBlur={() => this.save("wrdBeMakeUp")}
                  className={archived ? undefined : classes.inputBase}
                  defaultValue={wrdBeMakeUp}
                  onChange={this.handleChange("wrdBeMakeUp")}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body2">Mult. Spon.:</Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("multSpon");
                  }}
                  disabled={archived || conciergeReadOnly}
                  fullWidth
                  onBlur={() => this.save("multSpon")}
                  className={archived ? undefined : classes.inputBase}
                  defaultValue={multSpon}
                  onChange={this.handleChange("multSpon")}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body2">Total Base:</Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("totalBase");
                  }}
                  disabled={archived || conciergeReadOnly}
                  fullWidth
                  onBlur={() => this.save("totalBase")}
                  className={archived ? undefined : classes.inputBase}
                  defaultValue={totalBase}
                  onChange={this.handleChange("totalBase")}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(B10);
