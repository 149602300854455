import { serviceRoles } from "config/access";
import { CORE_CONCEPT } from "constants/routes";
import { checkAccess } from "helpers/auth";
import CoreConceptPage from "./container/CoreConceptPage";

export default function getSettingsRoute(userAuth) {
  const { account, rhapsody } = serviceRoles;
  const requireAuth = {
    [rhapsody.name]: [account.roles.user],
    [account.name]: [account.roles.superadmin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: CORE_CONCEPT,
    withSidebar: false,
    withAppBar: false,
    component: CoreConceptPage,
  };
}
