import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
// import PropTypes from 'prop-types';
// material-ui
import { withStyles } from "@material-ui/styles";
// image
import RhapsodyBadge from "assets/img/rhapsody-badge.png";
import React from "react";

// styles

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 18,
    height: 18,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

export default function MusicianAvatar({
  musician = { userUUID: "", id: undefined },
  noBadge = false,
  size = 40,
}) {
  // const [ state, setState ] = React.useState(initialValue);

  if (!musician.id)
    return <i class="fad fa-chair" style={{ height: size, width: size }}></i>;

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={
        musician.userUUID && !noBadge ? (
          <Tooltip title="Rhapsody Member">
            <SmallAvatar src={RhapsodyBadge} />
          </Tooltip>
        ) : (
          []
        )
      }
    >
      <Avatar style={{ height: size, width: size }} src={musician.avatar} />
    </Badge>
  );
}
