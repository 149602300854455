import { networkAction } from "helpers/network/networkAction";

import {
  GET_STUDIO_SESSION_MUSICIAN_BY_ID,
  DELETE_STUDIO_SESSION_MUSICIAN,
  UPDATE_STUDIO_SESSION_MUSICIAN,
  CREATE_STUDIO_SESSION_MUSICIAN,
  GET_STUDIO_SESSION_MUSICIAN_STATUSES,
  NOTIFY_SESSION_MUSICIAN_PREVIEW,
  NOTIFY_SESSION_MUSICIAN,
} from "constants/studioSessionMusicians";

import * as Api from "api";

export const getStudioSessionMusicianById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_MUSICIAN_BY_ID,
    Api.getStudioSessionMusicianById,
    [id]
  );

export const getSessionMusicianStatuses = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_MUSICIAN_STATUSES,
    Api.getSessionMusicianStatuses,
    [id]
  );

export const deleteStudioSessionMusician = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_STUDIO_SESSION_MUSICIAN,
    Api.deleteStudioSessionMusician,
    [id]
  );

export const createStudioSessionMusician = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_STUDIO_SESSION_MUSICIAN,
    Api.createStudioSessionMusician,
    [body]
  );

export const updateStudioSessionMusician = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_STUDIO_SESSION_MUSICIAN,
    Api.updateStudioSessionMusician,
    [id, body]
  );

export const notifySessionMusician = (id, body) => async (dispatch) =>
  networkAction(dispatch, NOTIFY_SESSION_MUSICIAN, Api.notifySessionMusician, [
    id,
    body,
  ]);

export const notifySessionMusicianPreview = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    NOTIFY_SESSION_MUSICIAN_PREVIEW,
    Api.notifySessionMusician,
    [id, body]
  );
