import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// component
import NoRender from "components/NoRender";
// config
import { REACT_APP_API } from "config";
import RichText from "hooks/RichText";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";
// component

class Notify extends PureComponent {
  static propTypes = {
    studios: PropTypes.array,
    classes: PropTypes.object,
    studioSession: PropTypes.object,
    project: PropTypes.object,
    notifySessionMusician: PropTypes.func,
    notify: PropTypes.func,
    sessionMusician: PropTypes.object,
    currentCompany: PropTypes.object,
    configurations: PropTypes.array,
    getStudioSessionStatuses: PropTypes.func,
    getSessionMusicianStatuses: PropTypes.func,
    getProjectStatuses: PropTypes.func,
    api: PropTypes.object,
    refresh: PropTypes.func,
    payrolls: PropTypes.array,
    children: PropTypes.node,
  };

  static childContextTypes = {
    Notify: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    SubscriptionCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.iframe = React.createRef();
    this.init();
  }

  getChildContext() {
    return {
      Notify: {
        open: this.open.bind(this),
        close: this.close.bind(this),
      },
    };
  }

  init() {
    //eslint-disable-line
    this.state = {}; //eslint-disable-line
    this.setState({
      agree: false,
      open: false,
      previewLoading: true,
      iframeHeight: 0,
      init: false,
      selectedStatus: undefined,
      greeting: "",
      _greeting: "",
      notes: "",
      composer: "",
      leader: "",
      wageAgreement: "",
      name: "",
      studioID: "",
      payrollID: "",
      contractor: "",
      title: "",
      showRoster: false,
      showSessionDetails: true,
      hideEmailLogo: false,
      loading: false,
      preview: [],
    });
  }

  async getPreview() {
    const {
      _greeting,
      notes,
      showRoster,
      selectedStatus,
      showSessionDetails,
      sessionMusicianID,
    } = this.state;

    const { studioSession } = this.props;

    let resp;

    if (sessionMusicianID) {
      resp = await fetch(
        `${REACT_APP_API}/rhapsody/jobs/${sessionMusicianID}/notifyHTML`,
        {
          body: JSON.stringify({
            greeting: _greeting,
            notes: notes,
            showRoster,
            showSessionDetails,
            statusID: selectedStatus.id,
          }),
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
          method: "PUT",
        }
      );
    } else {
      resp = await fetch(
        `${REACT_APP_API}/rhapsody/workSessions/${studioSession.id}/notifyHTML`,
        {
          body: JSON.stringify({
            greeting: _greeting,
            notes: notes,
            showRoster,
            showSessionDetails,
            statusID: selectedStatus.id,
          }),
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
          method: "PUT",
        }
      );
    }

    resp.text().then((t) => {
      this.setState({ preview: t });
    });
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked }, this.getPreview.bind(this));
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  async notify() {
    const { notify, notifySessionMusician, studioSession, refresh } =
      this.props;

    const {
      _greeting,
      notes,
      showRoster,
      showSessionDetails,
      selectedStatus,
      sessionMusicianID,
    } = this.state;

    const { NotificationCenter } = this.context;
    this.setState({ loading: true });

    if (sessionMusicianID) {
      await notifySessionMusician(sessionMusicianID, {
        greeting: _greeting,
        notes,
        showRoster,
        showSessionDetails,
        statusID: selectedStatus.id,
      });
    } else {
      await notify(studioSession.id, {
        greeting: _greeting,
        notes,
        showRoster,
        showSessionDetails,
        statusID: selectedStatus.id,
      });
    }

    this.close();

    NotificationCenter.sweetAlert({
      title: "Email(s) sent",
      success: true,
    });
    setTimeout(NotificationCenter.hide, 1500);
    setTimeout(refresh, 2000);
  }

  async saveProject(name, numeric) {
    const { api, refresh, studioSession } = this.props;

    if (studioSession.project[name] === this.state[name]) return;

    await api.updateProject(studioSession.projectID, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.getPreview();

    refresh();
  }

  async saveSession(name, numeric) {
    const { api, refresh, studioSession } = this.props;

    if (studioSession[name] === this.state[name]) return;

    await api.updateStudioSession(studioSession.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.getPreview();

    refresh();
  }

  close() {
    this.setState({
      open: false,
      loading: false,
      eventStatuses: [],
      sessionMusicianID: undefined,
      selectedStatus: undefined,
    });
  }

  open(sessionMusicianID) {
    const {
      project,
      studioSession,
      getStudioSessionStatuses,
      getSessionMusicianStatuses,
      getProjectStatuses,
    } = this.props;

    this.init();

    this.setState({ loading: true });
    if (sessionMusicianID) {
      getSessionMusicianStatuses(sessionMusicianID).then((r) => {
        if (r.success) {
          this.setState({ eventStatuses: r.payload, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });
    } else if (project) {
      getProjectStatuses(project.id).then((r) => {
        if (r.success) {
          this.setState({ eventStatuses: r.payload, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      getStudioSessionStatuses(studioSession.id).then((r) => {
        if (r.success) {
          this.setState({ eventStatuses: r.payload, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });
    }

    this.setState({
      open: true,
      sessionMusicianID,
    });
  }

  render() {
    const {
      classes,
      studioSession,
      sessionMusician,
      studios,
      payrolls,
      children,
      configurations,
      currentCompany,
      getProjectStatuses,
    } = this.props;

    const {
      loading,
      open,
      agree,
      selectedStatus,
      showRoster,
      showSessionDetails,
      wageAgreement,
      notes,
      _greeting,
      composer,
      leader,
      contractor,
      studioID,
      payrollID,
      eventStatuses,
      title,
      preview,
      iframeHeight,
      previewLoading,
      hideEmailLogo,
      configurationCode,
    } = this.state;

    const comingSoon = getProjectStatuses !== undefined;

    let dialog;

    if (loading) {
      dialog = (
        <Dialog
          open={open}
          onClose={() => this.close()}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"sm"}
        >
          <Grid
            container
            className={classes.container}
            justify="center"
            spacing={2}
          >
            <Grid item>
              <CircularProgress color="secondary" />
            </Grid>
          </Grid>
        </Dialog>
      );
    } else if (selectedStatus === undefined) {
      dialog = (
        <Dialog
          open={open}
          onClose={() => {
            this.close();
            this.setState({ selectedStatus: undefined });
          }}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"sm"}
        >
          <Grid
            container
            className={classes.container}
            justify="center"
            spacing={2}
          >
            {eventStatuses && eventStatuses.length === 0 ? (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6">
                  It looks like there is nobody to notify!
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Make sure you have assigned musicians to positions
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  Select an Event
                </Typography>
              </Grid>
            )}
            {eventStatuses &&
              eventStatuses
                .filter((s) => s.canNotify)
                .map((s) => (
                  <>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      key={s.id}
                      style={{
                        pointerEvents: comingSoon ? "none" : undefined,
                        cursor: comingSoon ? "not-allowed" : undefined,
                      }}
                      onClick={() => {
                        if (!s.minSubscriptionID || s.minSubscriptionID) {
                          const ss = s;
                          this.setState(
                            {
                              selectedStatus: ss,
                              _greeting: ss.defaultGreeting || "",
                              notes: studioSession.project.emailNotes,
                              composer: studioSession.project.composer,
                              hideEmailLogo:
                                studioSession.project.hideEmailLogo,
                              configurationCode:
                                studioSession.project.configurationCode,
                              leader: studioSession.project.leader,
                              agreement: studioSession.wageAgreement,
                              contractor: studioSession.project.contractor,
                              name: studioSession.projectName,
                              title: studioSession.title,
                              studioID: studioSession.studio
                                ? studioSession.studio.id
                                : undefined,
                              payrollID: studioSession.project.payrollID,
                              agree: false,
                              init: true,
                            },
                            this.getPreview.bind(this)
                          );
                        }
                      }}
                    >
                      <div className={classes.widget}>
                        <img
                          src={s.icon}
                          style={{
                            height: 45,
                          }}
                          alt="status"
                        />
                        <Typography variant="body1" style={{ fontWeight: 700 }}>
                          {s.actionName}
                        </Typography>
                        {!comingSoon ? (
                          <>
                            {sessionMusician ? (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                1 Person
                              </Typography>
                            ) : (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {s.willNotify.length}{" "}
                                {s.willNotify.length > 1 ? "People" : "Person"}
                              </Typography>
                            )}
                          </>
                        ) : (
                          <Typography variant="caption" color="textSecondary">
                            Coming Soon
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </>
                ))}
          </Grid>
        </Dialog>
      );
    } else {
      dialog = (
        <Dialog
          open={open}
          onClose={() => {
            this.close();
            this.setState({ selectedStatus: undefined });
          }}
          aria-labelledby="form-dialog-title"
          fullWidth
          classes={{
            paper: classes.paper,
          }}
          maxWidth={"md"}
        >
          <Grid container direction="row" justify="center" alignItems="stretch">
            <Hidden smDown>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  background: "#F1F5F9",
                  display: "flex",
                  overflow: "scroll",
                  height: "80vh",
                  flexDirection: "column",
                  borderRight: "solid 1px rgba(155,155,155, 0.3)",
                }}
              >
                <Paper
                  elevation={0}
                  style={{
                    padding: 8,
                    borderBottom: "solid 1px rgba(155,155,155, 0.3)",
                  }}
                >
                  <Typography variant="caption" display="block">
                    <b>From:</b>{" "}
                    {contractor || currentCompany.name || "Rhapsody Bot"}
                  </Typography>
                  <Typography variant="caption" display="block">
                    <b>Reply To:</b> {currentCompany.email}
                  </Typography>
                  <Typography variant="caption" display="block">
                    <b>Subject:</b> {selectedStatus && selectedStatus.subject}
                  </Typography>
                </Paper>
                {previewLoading ? (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress
                        size={20}
                        style={{ color: "#607d8b" }}
                      />
                      <br />
                      <Typography variant="caption" color="textSecondary">
                        Loading Email Preview...
                      </Typography>
                    </div>
                  </div>
                ) : (
                  []
                )}
                {preview ? (
                  <div
                    style={{
                      flex: "1 1 auto",
                      minHeight: 0,
                      width: "100%",
                      overflow: "auto",
                      position: "relative",
                    }}
                  >
                    <iframe
                      title="preview"
                      srcDoc={preview}
                      ref={this.iframe}
                      onLoad={() => {
                        const iframe = this.iframe.current;
                        if (iframe.contentWindow) {
                          const ih =
                            iframe.contentWindow.document.body.scrollHeight;
                          if (ih > 0) {
                            this.setState({
                              iframeHeight: ih,
                              previewLoading: false,
                            });
                          }
                        }
                      }}
                      style={{
                        border: "none",
                        width: "100%",
                        height: iframeHeight,
                      }}
                    />
                    <div
                      style={{
                        height: iframeHeight,
                        width: "100%",
                        position: "absolute",
                        top: 0,
                      }}
                    />
                  </div>
                ) : (
                  []
                )}
              </Grid>
            </Hidden>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                overflow: "scroll",
                height: "80vh",
              }}
            >
              <div style={{ padding: 12, paddingBottom: 120 }}>
                <Typography variant="h6">
                  <img src={selectedStatus.icon} height="25" alt="sttusicon" />
                  <span style={{ color: selectedStatus.color, marginLeft: 4 }}>
                    {selectedStatus.actionName}
                  </span>
                </Typography>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      <b>
                        {selectedStatus.willNotify.length}{" "}
                        {selectedStatus.willNotify.length > 1
                          ? "People"
                          : "Person"}
                      </b>{" "}
                      Will receive the email:
                    </Typography>
                  </Grid>
                  {sessionMusician ? (
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        {sessionMusician.nickName ||
                          `${sessionMusician.musician.firstName} ${sessionMusician.musician.lastName}.`}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Grid container>
                        {selectedStatus.willNotify
                          .slice(0, 10)
                          .map((musician, l) => (
                            <Grid item key={`event_${musician.id}`}>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ marginLeft: 2 }}
                              >
                                {`${musician}${
                                  l === selectedStatus.willNotify.length - 1
                                    ? "."
                                    : ", "
                                }`}
                              </Typography>
                            </Grid>
                          ))}
                        {selectedStatus.willNotify.length > 10 ? (
                          <Tooltip
                            title={selectedStatus.willNotify
                              .slice(10, selectedStatus.willNotify.length)
                              .reduce((a, v) => (a += `${v}, `), "")}
                          >
                            <Typography
                              variant="caption"
                              color="secondary"
                              style={{ marginLeft: 2 }}
                            >
                              <b>
                                And {selectedStatus.willNotify.length - 10} More
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          []
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {configurations && configurations.length ? (
                    <Grid item xs={12}>
                      <TextField
                        select
                        label="Header"
                        value={configurationCode}
                        // disabled={loading}
                        onChange={(e) => {
                          this.state.configurationCode = e.target.value; // eslint-disable-line
                          this.saveProject("configurationCode");
                        }}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        style={{ width: "calc(100% - 8px)" }}
                        variant="outlined"
                      >
                        {configurations &&
                          configurations.length &&
                          configurations.map((c) => (
                            <MenuItem
                              key={`configurations_${c.id}`}
                              value={c.code}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src={c.appLogo}
                                    alt="appLogo"
                                    style={{ width: 200 }}
                                  />
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  ) : (
                    []
                  )}
                  <Grid item xs={12} style={{ padding: 8 }}>
                    <FormControlLabel
                      size="small"
                      control={
                        <Switch
                          checked={hideEmailLogo}
                          size="small"
                          onChange={(e) => {
                            this.setState(
                              { hideEmailLogo: e.target.checked },
                              () => {
                                this.saveProject("hideEmailLogo");
                              }
                            );
                          }}
                          value="hideEmailLogo"
                        />
                      }
                      label="Hide Logo"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Greeting"
                      className={classes.textField}
                      helperText={
                        _greeting.length > 255
                          ? "Text Too long"
                          : `${255 - _greeting.length} char left`
                      }
                      value={_greeting}
                      error={255 - _greeting.length <= 0}
                      fullWidth
                      multiline
                      onChange={this.handleChange("_greeting")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          this.setState({ greeting: _greeting });
                      }}
                      onBlur={() =>
                        this.setState(
                          { greeting: _greeting },
                          this.getPreview.bind(this)
                        )
                      }
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                      <TextField
                        label="Project"
                        className={classes.textField}
                        value={name}
                        onChange={this.handleChange('name')}
                        onKeyPress={(e) => { if (e.key === 'Enter') this.saveProject('name'); }}
                        onBlur={() => this.saveProject('name')}
                        disabled={loading}
                        margin="normal"
                        variant="outlined"
                        style={{ width: 'calc(100% - 8px)' }}
                      />
                    </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      label="Title"
                      className={classes.textField}
                      value={title}
                      onChange={this.handleChange("title")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.saveSession("title");
                      }}
                      onBlur={() => this.saveSession("title")}
                      disabled={loading}
                      margin="normal"
                      variant="outlined"
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Location"
                      value={studioID}
                      disabled
                      // disabled={loading}
                      onChange={(e) => {
                        this.state.studioID = e.target.value; // eslint-disable-line
                        this.saveSession("studioID");
                      }}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      style={{ width: "calc(100% - 8px)" }}
                      variant="outlined"
                    >
                      {studios.map((s) => (
                        <MenuItem key={`s_${s.id}`} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Composer"
                      className={classes.textField}
                      value={composer}
                      onChange={this.handleChange("composer")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.saveProject("composer");
                      }}
                      onBlur={() => this.saveProject("composer")}
                      margin="normal"
                      variant="outlined"
                      disabled={loading}
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Leader"
                      className={classes.textField}
                      value={leader}
                      onChange={this.handleChange("leader")}
                      onBlur={() => this.saveProject("leader")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.saveProject("leader");
                      }}
                      margin="normal"
                      variant="outlined"
                      disabled={loading}
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Wage Scale Agreement"
                      className={classes.textField}
                      value={wageAgreement}
                      onChange={this.handleChange("wageAgreement")}
                      onBlur={() => this.saveSession("wageAgreement")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          this.saveProject("wageAgreement");
                      }}
                      margin="normal"
                      variant="outlined"
                      disabled={loading}
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Payroll"
                      value={payrollID}
                      disabled={loading}
                      onChange={(e) => {
                        this.state.payrollID = e.target.value; // eslint-disable-line
                        this.saveProject("payrollID");
                      }}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      style={{ width: "calc(100% - 8px)" }}
                      variant="outlined"
                    >
                      {payrolls.map((s) => (
                        <MenuItem key={`p_${s.id}`} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Contractor"
                      className={classes.textField}
                      value={contractor}
                      disabled={loading}
                      onChange={this.handleChange("contractor")}
                      onBlur={() => this.saveProject("contractor")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.saveProject("contractor");
                      }}
                      margin="normal"
                      variant="outlined"
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ padding: 8 }}>
                    <FormControlLabel
                      size="small"
                      control={
                        <Switch
                          checked={showSessionDetails}
                          size="small"
                          onChange={this.handleCheckboxChange(
                            "showSessionDetails"
                          )}
                          value="showSessionDetails"
                        />
                      }
                      label="Show Session Details"
                    />
                  </Grid>
                  {showSessionDetails ? (
                    <Grid item xs={6} style={{ padding: 8 }}>
                      <FormControlLabel
                        size="small"
                        control={
                          <Switch
                            checked={showRoster}
                            size="small"
                            onChange={this.handleCheckboxChange("showRoster")}
                            value="showRoster"
                          />
                        }
                        label="Send Roster"
                      />
                    </Grid>
                  ) : (
                    []
                  )}
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                      Notes
                    </Typography>
                    <RichText
                      value={notes}
                      onSave={(v) =>
                        this.setState({ notes: v }, this.getPreview.bind(this))
                      }
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.accept}>
                <FormGroup row style={{ paddingBottom: 8 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={agree}
                        onChange={this.handleCheckboxChange("agree")}
                        value="checkedA"
                      />
                    }
                    label={
                      <Typography variant="caption">
                        I understand that this action will send emails. I
                        checked all the information. Individual Memos will also
                        be included in the email.
                      </Typography>
                    }
                  />
                </FormGroup>
                <Grid container spacing={1} justify="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        this.close();
                        this.setState({ selectedStatus: undefined });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={!agree || _greeting.length > 255}
                      onClick={this.notify.bind(this)}
                      variant="contained"
                      color="secondary"
                    >
                      Send Email
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      );
    }

    return (
      <div>
        <NoRender open={open} node={children} />
        {dialog}
      </div>
    );
  }
}

export default withStyles(styles)(Notify);
