import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const ties = `/${service}/linkTies`;

export function getTies() {
  const url = `${ties}`;
  return {
    method: GET,
    url,
  };
}

export function getTieById(id) {
  const url = `${ties}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTie(id, body) {
  const url = `${ties}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTie(id) {
  const url = `${ties}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTie(body) {
  const url = `${ties}`;
  return {
    method: POST,
    url,
    body,
  };
}
