import { networkAction } from "helpers/network/networkAction";

import {
  GET_TUNES,
  GET_TUNE_BY_ID,
  DELETE_TUNE,
  UPDATE_TUNE,
  CREATE_TUNE,
} from "constants/tunes";

import * as Api from "api";

export const getTunes = () => async (dispatch) =>
  networkAction(dispatch, GET_TUNES, Api.getTunes, []);

export const getTuneById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TUNE_BY_ID, Api.getTuneById, [id]);

export const deleteTune = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TUNE, Api.deleteTune, [id]);

export const createTune = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TUNE, Api.createTune, [body]);

export const updateTune = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TUNE, Api.updateTune, [id, body]);
