import * as Api from "api";
import {
  CREATE_INSTRUMENT_SECTION,
  DELETE_INSTRUMENT_SECTION,
  GET_INSTRUMENT_SECTIONS,
  GET_INSTRUMENT_SECTION_BY_ID,
  GET_INSTRUMENT_SECTION_MUSICIANS,
  GET_MUSICIAN_INSTRUMENT_FOR_SECTION,
  UPDATE_INSTRUMENT_SECTION,
} from "constants/instrumentSections";
import { networkAction } from "helpers/network/networkAction";

export const getInstrumentSections = () => async (dispatch) =>
  networkAction(
    dispatch,
    GET_INSTRUMENT_SECTIONS,
    Api.getInstrumentSections,
    []
  );

export const getMusicianInstrumentForSection =
  (sectionID, musicianID) => async (dispatch) =>
    networkAction(
      dispatch,
      GET_MUSICIAN_INSTRUMENT_FOR_SECTION,
      Api.getMusicianInstrumentForSection,
      [sectionID, musicianID]
    );

export const getInstrumentSectionMusicians =
  (id, sessionID, sectionRoleID) => async (dispatch) =>
    networkAction(
      dispatch,
      GET_INSTRUMENT_SECTION_MUSICIANS,
      Api.getInstrumentSectionMusicians,
      [id, sessionID, sectionRoleID]
    );

export const getInstrumentSectionProjectMusicians =
  (id, projectID) => async (dispatch) =>
    networkAction(
      dispatch,
      GET_INSTRUMENT_SECTION_MUSICIANS,
      Api.getInstrumentSectionProjectMusicians,
      [id, projectID]
    );

export const getInstrumentSectionById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_INSTRUMENT_SECTION_BY_ID,
    Api.getInstrumentSectionById,
    [id]
  );

export const deleteInstrumentSection = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_INSTRUMENT_SECTION,
    Api.deleteInstrumentSection,
    [id]
  );

export const createInstrumentSection = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_INSTRUMENT_SECTION,
    Api.createInstrumentSection,
    [body]
  );

export const updateInstrumentSection = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_INSTRUMENT_SECTION,
    Api.updateInstrumentSection,
    [id, body]
  );
