import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
// material-ui
import { withStyles } from "@material-ui/styles";
import FileUpload from "hooks/FileUpload";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class FormLink extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    studioSession: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    link: PropTypes.object,

    createLink: PropTypes.func,
    updateLink: PropTypes.func,
    deleteLink: PropTypes.func,
    instrumentSections: PropTypes.array,
    instrumentFamilies: PropTypes.array,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      url: "",
      name: "",
      description: "",
      glob: false,
      anchorEl: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.link);
    }
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(link) {
    this.setState({
      loading: false,
      fileLoading: false,
      name: link ? link.name : "",
      url: link ? link.url : "",
      description: link ? link.description : "",
      glob: link ? link.global : false,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this link, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteLink, link, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteLink(link.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Link has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateLink,
      createLink,
      link,
      close,
      studioSession,
      refresh,
    } = this.props;

    const { name, url, description, glob } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (link) {
      resp = await updateLink(link.id, {
        name,
        url,
        description,
        global: glob,
        sessionID: studioSession.id,
      });
    } else {
      resp = await createLink({
        name,
        url,
        description,
        global: glob,
        sessionID: studioSession.id,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: link ? "Link has been updated." : "Link has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      setTimeout(NotificationCenter.hide, 2000);
      close();
      refresh();
    }
  }

  render() {
    const { classes, fullScreen, open, link, close } = this.props;

    const {
      name,
      url,
      description,
      loading,
      fileLoading,
      glob,
      errorMessage,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <DialogTitle id="responsive-dialog-title">
              {link ? `${link.name}` : "New Link"}
              <Typography variant="body2" display="block">
                Your files can be stored in the cloud of your choice (Dropbox,
                Google Drive, Private Cloud...). Just provide the public url to
                access it.
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                {"Rhapsody can host your files for free."}
              </Typography>
              <Typography>
                <FileUpload onUpload={(f) => this.setState({ url: f })} />
              </Typography>
              {errorMessage ? (
                <Alert severity="error" style={{ marginTop: 8 }}>
                  {errorMessage}
                </Alert>
              ) : (
                []
              )}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="url"
                    label="Url"
                    className={classes.textField}
                    value={url}
                    onChange={this.handleChange("url")}
                    InputProps={
                      fileLoading
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                <CircularProgress size={25} />
                              </InputAdornment>
                            ),
                          }
                        : undefined
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Description"
                    className={classes.textField}
                    value={description}
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="A global link will be visible by all musicians in the session.">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={glob}
                          onChange={this.handleCheckboxChange("glob")}
                          value="glob"
                        />
                      }
                      label="Global"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {link && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Cancel
              </Button>
              <Button
                color="primary"
                autoFocus
                style={{
                  background: "#2196f3",
                  color: "white",
                }}
                onClick={this.save.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} />
                {link ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormLink));
