import {
  Checkbox,
  Chip,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";
// material-ui
import { withStyles } from "@material-ui/styles";
import { INTAKE, REACT_APP_API } from "config";
import { PROJECT_DETAIL } from "constants/routes";
import { buildForURL } from "helpers";
import AsyncAutocomplete from "hooks/AsyncAutocomplete";
import CopyLabel from "hooks/CopyLabel";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormQuote extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    quote: PropTypes.object,

    createQuote: PropTypes.func,
    updateQuote: PropTypes.func,
    deleteQuote: PropTypes.func,
    getCurrentUser: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      workSessionCount: "",
      musicianCount: "",
      status: "",
      assignee: "",
      assignedToUUID: "",
      urgent: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.quote);
    }
  }

  init(quote) {
    this.setState({
      loading: false,
      ...quote,
    });
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const value = event.target.checked;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle: "If you delete this quote, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteQuote, close, refresh, quote } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteQuote(quote.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Quote has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateQuote, createQuote, quote, refresh, close } = this.props;

    const {
      workSessionCount,
      musicianCount,
      status,
      assignedToUUID,
      urgent,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (quote) {
      resp = await updateQuote(quote.id, {
        workSessionCount: Number(workSessionCount),
        musicianCount: Number(musicianCount),
        status,
        urgent,
        assignedToUUID,
      });
    } else {
      resp = await createQuote({
        workSessionCount: Number(workSessionCount),
        musicianCount: Number(musicianCount),
        status,
        urgent,
        assignedToUUID,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: quote ? "Quote has been updated." : "Quote has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      quote,
      close,
      history,
      setOrganizationByDefault,
      getCurrentUser,
    } = this.props;

    const {
      workSessionCount,
      musicianCount,
      assignee,
      status,
      loading,
      urgent,
    } = this.state;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              <div>
                <Typography
                  variant="h6"
                  display="block"
                  style={{
                    border: "solid 2px black",
                    borderRadius: 30,
                    padding: "0px 8px",
                    display: "inline-block",
                  }}
                >
                  <CopyLabel label={quote?.reference} />
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  Created on {moment(quote?.createdAt).format("lll")}
                  <br />
                  by{" "}
                  <b>
                    {quote?.firstName} {quote?.lastName} ({quote?.email},{" "}
                    {quote?.phone})
                  </b>
                  <br />
                  OrganizationID: {quote?.organizationID}, ProjectID:{" "}
                  {quote?.projectID}, QuoteID: {quote?.id}
                </Typography>
              </div>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Chip
                    onClick={async () => {
                      await setOrganizationByDefault(quote.organizationID);
                      getCurrentUser();
                      setTimeout(() => {
                        window.open(
                          `${INTAKE}/?reference=${quote?.reference}&admin`,
                          "_blank"
                        );
                      }, 500);
                    }}
                    label={
                      <span>
                        <i class="fad fa-clipboard p-right"></i>Open Intake Form
                      </span>
                    }
                  />
                  {quote?.projectID ? (
                    <Chip
                      onClick={async () => {
                        await setOrganizationByDefault(quote.organizationID);
                        getCurrentUser();
                        setTimeout(() => {
                          history.push({
                            pathname: PROJECT_DETAIL,
                            search: buildForURL({
                              id: quote.projectID,
                              back: `${window.location.pathname}${window.location.search}`,
                            }),
                          });
                        }, 500);
                      }}
                      label={
                        <span>
                          <i class="fad fa-folder-open p-right"></i>Open Project
                        </span>
                      }
                      style={{ marginLeft: 8 }}
                    />
                  ) : (
                    []
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Musician Count"
                    InputProps={{
                      autoCorrect: "off",
                      autoCapitalize: "off",
                      spellCheck: "false",
                      type: "tel",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    className={classes.textField}
                    value={musicianCount}
                    onChange={this.handleChange("musicianCount")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    InputProps={{
                      autoCorrect: "off",
                      autoCapitalize: "off",
                      spellCheck: "false",
                      type: "tel",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    size="small"
                    fullWidth
                    label="Work Session Count"
                    className={classes.textField}
                    value={workSessionCount}
                    onChange={this.handleChange("workSessionCount")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AsyncAutocomplete
                    label={assignee || "Assign to"}
                    size="small"
                    searchEndpoint={`${REACT_APP_API}/account/users/search`}
                    bodyTransform={(searchText) => ({ text: searchText })}
                    selectedLabelText={(o) => {
                      if (o) {
                        return `${o.firstName} ${o.lastName} (${o.email})`;
                      }

                      return "";
                    }}
                    onSelect={(o) => {
                      if (o) this.setState({ assignedToUUID: o.uuid });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Status"
                    className={classes.textField}
                    value={status}
                    select
                    onChange={this.handleChange("status")}
                  >
                    <MenuItem value="draft">Draft</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="ready">Ready</MenuItem>
                    <MenuItem value="invoiced">Invoiced</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>
                    <MenuItem value="canceled">Canceled</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={urgent}
                        style={{ color: urgent ? "red" : undefined }}
                        onChange={this.handleCheckboxChange("urgent")}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ lineHeight: 1 }}>
                          <i
                            class={
                              urgent
                                ? "fad fa-siren-on p-right"
                                : "fad fa-siren p-right"
                            }
                            style={{ color: urgent ? "#f44336" : undefined }}
                          ></i>
                          This is an urgent call
                        </div>
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Typography variant="h6">
                    TOTAL: {formatter.format(quote?.total)}
                  </Typography>
                </Grid>
                {status !== quote?.status ? (
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <Alert severity="info">
                      You are about to change the status of the quote.
                      <br />
                      This will trigger an email to the contractor.
                    </Alert>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              <Button
                className={classes.delete}
                onClick={this.delete.bind(this)}
                style={{ textTransform: "none", color: "#f44336" }}
              >
                <DeleteIcon className={classes.leftIcon} /> Delete
              </Button>
              <Button
                color="primary"
                variant="contained"
                autoFocus
                onClick={this.save.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} />{" "}
                {quote ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormQuote));
