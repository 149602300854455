// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// actions
// import * as Actions from 'actions';
// component
import WrapperCoreConceptPage from "../component/WrapperCoreConceptPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  // signin: (...args) => dispatch(Actions.signin(...args)),
});
class CoreConceptPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      loading: false,
    };
  }

  // componentDidMount() {
  //   this.refresh();
  // }

  async refresh() {
    // const { getElementById } = this.props;
    // const { id } = this.state;
    // const r = await getElementById(id);
    // if (r.success) {
    //   this.setState({
    //     refresh: false,
    //     element: r.payload,
    //   });
    // }
  }

  render() {
    const { location, history } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCoreConceptPage
        history={history}
        location={location}
        urlParams={urlParams}
        refresh={this.refresh.bind(this)}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CoreConceptPage);
