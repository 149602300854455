// import PropTypes from 'prop-types';
// material-ui
import {
  Chip,
  CircularProgress,
  Grid,
  Hidden,
  InputBase,
  List,
  Popover,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Search from "@material-ui/icons/Search";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import FormMusicianSettings from "components/FormMusicianSettings";
import MusicianTableHeader from "components/MusicianTableHeader";
import MusicianTableTotal from "components/MusicianTableTotal";
import { REACT_APP_API } from "config";
import MusicianMenuItem from "hooks/MusicianMenuItem";
import PayrollLineItem from "hooks/PayrollLineItem";
import React from "react";
import { useDispatch } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Payroll({
  studioSession,
  conciergeReadOnly,
  loading,
  _studioSessionMusicians,
  archived,
  api,
  instruments,
  refresh,
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const [searchText, setSearchText] = React.useState(false);
  const classes = useStyles(styles);
  const dispatch = useDispatch();

  const theme = useTheme();

  function handleLink(m) {
    setAnchorEl(null);
    dispatch(
      Actions.createMercuryJob([
        {
          sessionID: studioSession.id,
          musicianID: m.id,
          createCount: 1,
          sectionID: -1,
        },
      ])
    ).then(refresh);
  }

  function handleSearchTextChange(e) {
    const value = e.target.value;
    if (value.length > 3) {
      dispatch(
        Actions.getMusicians([
          {
            name: "musicians.firstName",
            comparison: "like",
            value,
            orOperator: true,
          },
          {
            name: "musicians.lastName",
            comparison: "like",
            value,
            orOperator: true,
          },
          {
            name: "musicians.nickname",
            comparison: "like",
            value,
            orOperator: true,
          },
        ])
      ).then((r) => {
        setSuggestions(r.payload);
      });
    }
    setSearchText(value);
  }

  return (
    <div style={{ padding: 20, width: "100%" }}>
      <Grid container spacing={1} justify="space-between">
        <Hidden smDown>
          <Grid item>
            <Grid container>
              {studioSession.sessionType.code !== "ST" ? (
                <Grid item>
                  <Chip
                    size="small"
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={conciergeReadOnly}
                    label={
                      <span>
                        <i class="fad fa-calculator p-right"></i>
                        {studioSession.sessionType.code === "LV"
                          ? "Scales, Pensions & Welfares"
                          : "Pensions & Welfares"}
                      </span>
                    }
                  />
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item>
            <Chip
              label="+ Musician"
              size="small"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              style={{ background: "#ffc107", color: "#001E3C" }}
            />
          </Grid>
        </Hidden>
        <Grid item>
          <Grid container>
            <Grid item>
              <Chip
                size="small"
                label={
                  <span>
                    <i class="fad fa-file-csv p-right"></i> Export in CSV
                  </span>
                }
                onClick={() =>
                  window.open(
                    `${REACT_APP_API}/rhapsody/workSessions/${studioSession.publicToken}/csv`,
                    "_blank"
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Alert severity="info" style={{ marginTop: 16, padding: 4 }}>
          <Typography variant="caption">
            <u>Tips:</u> Edit multiple values at the same time by clicking on
            cells while pressing the <b>command</b> key. Deselect by pressing
            the <b>escape</b> key.
          </Typography>
        </Alert>
      </Hidden>
      <div style={{ marginTop: 20 }}>
        <Hidden smUp>
          <div style={{ textAlign: "center", padding: 40 }}>
            <i class="fad fa-desktop" style={{ fontSize: 90 }}></i>
            <br />
            <Typography variant="caption" color="textSecondary">
              Open this page on a device with a bigger screen to edit the
              payroll values for each musicians.
            </Typography>
          </div>
        </Hidden>
        <Hidden xsDown>
          <MusicianTableHeader variant={studioSession.sessionType.code} />
          {loading ? (
            <div className={classes.paper}>
              <Grid container>
                <Grid xs={12}>
                  <CircularProgress color="secondary" />
                </Grid>
              </Grid>
            </div>
          ) : (
            <div />
          )}
          {_studioSessionMusicians.map((m) => (
            <PayrollLineItem
              key={m.id}
              variant={studioSession.sessionType.code}
              disabled={archived || conciergeReadOnly}
              value={m}
            />
          ))}
          <MusicianTableTotal
            variant={studioSession.sessionType.code}
            studioSession={studioSession}
          />
        </Hidden>
      </div>
      <FormMusicianSettings
        studioSession={studioSession}
        open={open}
        close={() => {
          setOpen(false);
        }}
        updateStudioSession={api.updateStudioSession}
        calculatePensionWelfare={api.calculatePensionWelfare}
        refresh={refresh}
      />
      <Popover
        open={Boolean(anchorEl)}
        disableEnforceFocus
        disableRestoreFocus
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
      >
        <div
          style={{
            width: 280,
            display: "flex",
            flexWrap: "wrap",
            alignContent: "start",
            height: 400,
            overflow: "scroll",
            alignItems: "start",
            justifyContent: "start",
            position: "relative",
          }}
        >
          <div style={{ flex: 1, padding: "10px 10px 0px 10px" }}>
            <Typography variant="h6" color="textPrimary">
              Musicians
            </Typography>
          </div>
          <div style={{ flexBasis: "100%", height: 0 }} />
          <div
            style={{
              position: "sticky",
              alignSelf: "flex-start",
              padding: "5px 10px 10px 10px",
              top: 0,
              flex: 1,
              zIndex: 99999,
              background: theme.palette.type === "dark" ? "#424242" : "white",
            }}
          >
            <InputBase
              style={{
                background: "rgba(155,155,155,0.1)",
                borderRadius: 40,
                padding: "0px 8px",
              }}
              vallue={searchText}
              onChange={handleSearchTextChange}
              fullWidth
              autoFocus
              startAdornment={
                <Search style={{ color: "rgba(155,155,155,0.8)" }} />
              }
              placeholder="Search..."
            />
            <div style={{ flexBasis: "100%", height: 0 }} />
            <div style={{ flex: 1 }}>
              <List dense style={{ padding: 0 }}>
                {suggestions.map((m) => (
                  <MusicianMenuItem
                    key={`musicians_${m.id}`}
                    linkMusician={handleLink}
                    m={m}
                    sectionRoles={[]}
                    close={() => console.log("close")}
                    onMusicianEdit={() => console.log("edit")}
                  />
                ))}
              </List>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
