import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
// material-ui
import { withStyles } from "@material-ui/styles";
import { Help } from "components";
// custom
import DateTime from "components/DateTime";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class B7 extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    studioSession: PropTypes.object,
    refresh: PropTypes.func,
    conciergeReadOnly: PropTypes.bool,
    api: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { studioSession } = this.props;
    this.state = {
      ...studioSession,
      loading: false,
      newTuneLoading: false,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { api, refresh, studioSession } = this.props;

    if (studioSession[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await api.updateStudioSession(studioSession.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const {
      classes,
      api,
      studioSession,
      refresh,
      conciergeReadOnly,
    } = this.props;

    const {
      loading,
      wageAgreement,
      afmProjectNumber,
      afmLocal,
      totalSessionHours,
      agreementOnFile,
      hoursOfEmployment,
      description,
      programLength,
      episodeTitle,
      productionTypeID,
      sessionMediumID,
      date,
      episodeNumber,
      originalReportForm,
      originalRecordingDate,
      producer,
      producerAddress,
      memo,
      anchorEl,
      engineer,
      conductor,
      payrollNumber,
      title,
      archived,
      recorded,
    } = this.state;

    const sessionCategoriesToAdd = studioSession.sessionType.categories.filter(
      (sc) => studioSession.categories.find((c) => c.id === sc.id) === undefined
    );

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2">Title:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      fullWidth
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("title")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("title");
                      }}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={title}
                      onChange={this.handleChange("title")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2">Payroll #:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      fullWidth
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("payrollNumber");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("payrollNumber")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={payrollNumber}
                      onChange={this.handleChange("payrollNumber")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Project Description
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Contract Date:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DateTime
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("date");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("date")}
                      noTime
                      className={archived ? undefined : classes.inputBase}
                      date={date}
                      onChange={this.handleChange("date")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Project Title:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.project.name}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Project#:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.project.industryProjectNumber}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Episode Title:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("episodeTitle");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("episodeTitle")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={episodeTitle}
                      onChange={this.handleChange("episodeTitle")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Episode #:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("episodeNumber");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("episodeNumber")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={episodeNumber}
                      onChange={this.handleChange("episodeNumber")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Program Length:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("programLength");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("programLength")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={programLength}
                      onChange={this.handleChange("programLength")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Client Name:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.customer.name}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Address:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={`${studioSession.customer.address} ${studioSession.customer.city} ${studioSession.customer.state} ${studioSession.customer.zipcode}`}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Project#:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("afmProjectNumber");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("afmProjectNumber")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={afmProjectNumber}
                      onChange={this.handleChange("afmProjectNumber")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Additional Info
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Assumption Agreement on File:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Switch
                      checked={agreementOnFile}
                      onChange={(e) => {
                        this.state.agreementOnFile = e.target.checked; // eslint-disable-line
                        this.save("agreementOnFile");
                      }}
                      color="secondary"
                      disabled={archived}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Categories:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <div>
                          {archived !== true ? (
                            <Chip
                              variant="outlined"
                              avatar={
                                <Avatar style={{ background: "none" }}>
                                  <AddCircleOutline />
                                </Avatar>
                              }
                              label="Add Category"
                              onClick={this.handleClick}
                            />
                          ) : (
                            <div />
                          )}
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                          >
                            {sessionCategoriesToAdd &&
                              sessionCategoriesToAdd.map((sc) => (
                                <MenuItem
                                  disabled={archived}
                                  key={`${sc.id}_${sc.name}`}
                                  onClick={async () => {
                                    this.handleClose();
                                    await api.createStudioSessionCategory(
                                      studioSession.id,
                                      { categoryID: sc.id }
                                    );
                                    refresh();
                                  }}
                                >
                                  {sc.name}
                                </MenuItem>
                              ))}
                          </Menu>
                        </div>
                      </Grid>
                      {studioSession.categories.map((c) => (
                        <Grid item>
                          <Chip
                            label={c.name}
                            onDelete={
                              archived
                                ? undefined
                                : async () => {
                                    await api.deleteStudioSessionCategory(
                                      studioSession.id,
                                      { categoryID: c.id }
                                    );
                                    refresh();
                                  }
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Memo:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("memo");
                      }}
                      disabled={archived || conciergeReadOnly}
                      multiline
                      fullWidth
                      onBlur={() => this.save("memo")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={memo}
                      onChange={this.handleChange("memo")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Session Description
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Wage Scale Agreement:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("wageAgreement");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("wageAgreement")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={wageAgreement}
                      onChange={this.handleChange("wageAgreement")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Local#:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("afmLocal");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("afmLocal")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={afmLocal}
                      onChange={this.handleChange("afmLocal")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2"># Musicians:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.musicianCount}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Hrs of Employment:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("hoursOfEmployment");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("hoursOfEmployment")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={hoursOfEmployment}
                      onChange={this.handleChange("hoursOfEmployment")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Total Session Hrs:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("totalSessionHours");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("totalSessionHours")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={totalSessionHours}
                      onChange={this.handleChange("totalSessionHours")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Producer:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("producer");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("producer")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={producer}
                      onChange={this.handleChange("producer")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Address:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("producerAddress");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("producerAddress")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={producerAddress}
                      onChange={this.handleChange("producerAddress")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Engineer:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("engineer");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("engineer")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={engineer}
                      onChange={this.handleChange("engineer")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Conductor:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("conductor");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("conductor")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={conductor}
                      onChange={this.handleChange("conductor")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Help
                      title="Internal Notes:"
                      help="Anything useful for your own organization"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled={loading}
                      fullWidth
                      rows={3}
                      rowsMax={100}
                      multiline
                      onBlur={() => this.save("description")}
                      style={{ background: "rgba(255,235,59,0.1)" }}
                      className={classes.inputBase}
                      value={description || ""}
                      onChange={this.handleChange("description")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  New Use/ Re-Use
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Original Report Form #:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("originalReportForm");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("originalReportForm")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={originalReportForm}
                      onChange={this.handleChange("originalReportForm")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Original Recording Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DateTime
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          this.save("originalRecordingDate");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("originalRecordingDate")}
                      className={archived ? undefined : classes.inputBase}
                      date={originalRecordingDate}
                      onChange={this.handleChange("originalRecordingDate")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Payment Info
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Production Type:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        inputProps={{
                          name: "Project",
                          id: "project-simple",
                        }}
                        disabled={recorded || archived}
                        value={productionTypeID}
                        onChange={(e) => {
                          this.state.productionTypeID = e.target.value; // eslint-disable-line
                          this.save("productionTypeID");
                        }}
                      >
                        {studioSession &&
                          studioSession.sessionType &&
                          studioSession.sessionType.productionTypes &&
                          studioSession.sessionType.productionTypes.map(
                            (pt) => (
                              <MenuItem key={`prodType_${pt.id}`} value={pt.id}>
                                {pt.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Medium:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        disabled={recorded || archived}
                        inputProps={{
                          name: "Project",
                          id: "project-simple",
                        }}
                        value={sessionMediumID}
                        onChange={(e) => {
                          this.state.sessionMediumID = e.target.value; // eslint-disable-line
                          this.save("sessionMediumID");
                        }}
                      >
                        {studioSession &&
                          studioSession.sessionType &&
                          studioSession.sessionType.sessionMedia &&
                          studioSession.sessionType.sessionMedia.map((sm) => (
                            <MenuItem
                              key={`sessionMedia_${sm.id}`}
                              value={sm.id}
                            >
                              {sm.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(B7);
