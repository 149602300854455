import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const trackers = `/${service}/activities`;

export function getTrackers() {
  const url = `${trackers}`;
  return {
    method: GET,
    url,
  };
}

export function getTrackerById(id) {
  const url = `${trackers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTracker(id, body) {
  const url = `${trackers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTracker(id) {
  const url = `${trackers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTracker(body) {
  const url = `${trackers}`;
  return {
    method: POST,
    url,
    body,
  };
}
