export default (theme) => ({
  container: {
    padding: 20,
    background: theme.palette.background.default,
    width: "100%",
  },
  widget: {
    background: theme.palette.background.paper,
    borderRadius: 4,
    border: "solid 1px rgba(155, 155, 155, 0.3)",
    padding: 10,
    height: "calc(100% - 20px)",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
  },
  paper: {
    height: "80vh",
    position: "relative",
    overflow: "hidden",
  },
  accept: {
    position: "absolute",
    zIndex: 9999,
    bottom: 0,
    padding: 12,
    borderTop: "solid 1px rgba(155,155,155,0.3)",
    background: theme.palette.background.default,
  },
});
