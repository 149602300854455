import { networkAction } from "helpers/network/networkAction";

import {
  GET_INTERACTORS,
  GET_INTERACTOR_BY_ID,
  DELETE_INTERACTOR,
  UPDATE_INTERACTOR,
  CREATE_INTERACTOR,
} from "constants/interactors";

import * as Api from "api";

export const getInteractors = () => async (dispatch) =>
  networkAction(dispatch, GET_INTERACTORS, Api.getInteractors, []);

export const getInteractorById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_INTERACTOR_BY_ID, Api.getInteractorById, [id]);

export const deleteInteractor = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_INTERACTOR, Api.deleteInteractor, [id]);

export const createInteractor = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_INTERACTOR, Api.createInteractor, [body]);

export const updateInteractor = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_INTERACTOR, Api.updateInteractor, [id, body]);
