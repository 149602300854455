import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
// actions
import * as Actions from "actions";
// config
import {
  REACT_APP_API,
  REACT_APP_CLIENT_COLOR,
  REACT_APP_CLIENT_EMAIL,
  REACT_APP_CLIENT_NAME,
  REACT_APP_CLIENT_WEBSITE,
} from "config";
import { routeNames } from "constants/routeNames";
import { JOURNAL } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import * as Routes from "routes";
import theme from "themes/theme";
// component
import WrapperRootPage from "../component/WrapperRootPage";

const mapStateToProps = (state) => ({
  errors: state.errors,
  app: state.app,
  user: state.user,
  posts: state.posts,
  userApps: state.userApps,
  currentCompany: state.currentCompany,
  subscriptions: state.subscriptions,
  bazaarCustomer: state.bazaarCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  checkAccess: (...args) => dispatch(Actions.checkAccess(...args)),
  getPosts: (...args) => dispatch(Actions.getPosts(...args)),
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  getUserApps: (...args) => dispatch(Actions.getUserApps(...args)),
  storeResetPasswordToken: (...args) =>
    dispatch(Actions.storeResetPasswordToken(...args)),
  signout: (...args) => dispatch(Actions.signout(...args)),
  requestResetPassword: (...args) =>
    dispatch(Actions.requestResetPassword(...args)),
  resetPassword: (...args) => dispatch(Actions.resetPassword(...args)),
  signup: (...args) => dispatch(Actions.signup(...args)),
  validateEmail: (...args) => dispatch(Actions.validateEmail(...args)),
  setDarkMode: (...args) => dispatch(Actions.setDarkMode(...args)),
  stopImpersonate: (...args) => dispatch(Actions.stopImpersonate(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),
  getSubscriptions: (...args) => dispatch(Actions.getSubscriptions(...args)),
  websocketMessageReceived: (...args) =>
    dispatch(Actions.websocketMessageReceived(...args)),
  setOrganizationByDefault: (...args) =>
    dispatch(Actions.setOrganizationByDefault(...args)),
  getBazaarCurrentCustomer: (...args) =>
    dispatch(Actions.getBazaarCurrentCustomer(...args)),
  joinOrganization: (...args) => dispatch(Actions.joinOrganization(...args)),
  upload: (...args) => dispatch(Actions.upload(...args)),
});

class RootPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    checkAccess: PropTypes.func,
    getCurrentUser: PropTypes.func,
    getUserApps: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    websocketMessageReceived: PropTypes.func,
    resetPassword: PropTypes.func,
    getSubscriptions: PropTypes.func,
    getBazaarCurrentCustomer: PropTypes.func,
    getPosts: PropTypes.func,
    upload: PropTypes.func,

    user: PropTypes.object,
    userApps: PropTypes.array,
    subscriptions: PropTypes.array,
    errors: PropTypes.object,
    app: PropTypes.object,

    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,
    bazaarCustomer: PropTypes.any,

    getCurrentCompany: PropTypes.func,
    currentCompany: PropTypes.object,

    stopImpersonate: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
  };

  static childContextTypes = {
    checkAccess: PropTypes.func,
    userApps: PropTypes.array,
  };

  getChildContext() {
    const { checkAccess, userApps } = this.props;
    return {
      checkAccess,
      userApps,
    };
  }

  componentWillMount() {
    console.log(
      `%cDeveloped by ${REACT_APP_CLIENT_NAME}`,
      `color: ${REACT_APP_CLIENT_COLOR}; font-size: 28px`
    );
    console.log(
      "%cin Los Angeles, California",
      "color: #607d8b; font-size: 18px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_EMAIL}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_WEBSITE}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%cRunning on ${REACT_APP_API} API`,
      "color: #607d8b; font-size: 12px"
    );
    // setFavicon(REACT_APP_FAVICON);
  }

  getRoutes() {
    const { user, posts } = this.props;
    const routes = [];
    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        if (route.path === JOURNAL) {
          route.badge = posts.filter((p) => !p.discarded).length;
        }
        if (route.onEnter()) {
          routes.unshift(route);
        }
      }
    }

    return routes;
  }

  getAllRoutes() {
    const { user } = this.props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        routes.unshift(route);
      }
    }

    return routes;
  }

  init() {
    const {
      getUserApps,
      getCurrentCompany,
      getBazaarCurrentCustomer,
      getPosts,
    } = this.props;
    return Promise.all([
      getUserApps(),
      getCurrentCompany(),
      getBazaarCurrentCustomer(),
      getPosts(),
    ]);
  }

  render() {
    const {
      app,
      user,
      getCurrentUser,
      checkAccess,
      signout,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      storeResetPasswordToken,
      resetPassword,
      setDarkMode,
      userApps,
      stopImpersonate,
      history,
      currentCompany,
      setOrganizationByDefault,
      joinOrganization,
      websocketMessageReceived,
      upload,
      subscriptions,
      bazaarCustomer,
    } = this.props;

    let muiTheme = createMuiTheme({
      palette: { ...theme, type: this.props.app.darkMode ? "dark" : "light" },
      paper: {
        boxShadow: "none",
      },
      typography: {
        button: {
          textTransform: "none",
        },
        h6: {
          fontWeight: 700,
        },
        useNextVariants: true,
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
    });

    muiTheme = responsiveFontSizes(muiTheme);

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Router>
          <WrapperRootPage
            stopImpersonate={stopImpersonate}
            setDarkMode={setDarkMode}
            app={app}
            checkAccess={checkAccess}
            getCurrentUser={getCurrentUser}
            storeResetPasswordToken={storeResetPasswordToken}
            signout={signout}
            requestResetPassword={requestResetPassword}
            resetPassword={resetPassword}
            websocketMessageReceived={websocketMessageReceived}
            validateEmail={validateEmail}
            signup={signup}
            user={user}
            userApps={userApps}
            errors={errors}
            routes={this.getRoutes()}
            allRoutes={this.getAllRoutes()}
            init={this.init.bind(this)}
            history={history}
            setOrganizationByDefault={setOrganizationByDefault}
            joinOrganization={joinOrganization}
            currentCompany={currentCompany}
            upload={upload}
            subscriptions={subscriptions}
            bazaarCustomer={bazaarCustomer}
          />
        </Router>
      </MuiThemeProvider>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
