import { GET_STUDIO_SESSION_DASHBOARD } from "constants/studioSessions";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_STUDIO_SESSION_DASHBOARD}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
