import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Help extends Component {
  static propTypes = {
    classes: PropTypes.object,
    help: PropTypes.string,
    title: PropTypes.string,
  };

  render() {
    const { title, help } = this.props;

    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="body2">{title}</Typography>
        </Grid>
        <Grid item>
          <Tooltip
            style={{
              fontSize: 16,
              color: "#9e9e9e",
              marginTop: 2,
              cursor: "pointer",
            }}
            title={help}
          >
            <InfoIcon />
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Help);
