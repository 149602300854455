export default (theme) => ({
  container: {
    padding: 5,
  },
  paper: {
    borderRadius: 4,
    background: theme.palette.background.default,
    maxWidth: 620,
  },
});
