import {
  Backdrop,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Drawer,
  Grid,
  Grow,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloudDownload from "@material-ui/icons/CloudDownload";
import SaveIcon from "@material-ui/icons/Save";
import { withStyles } from "@material-ui/styles";
import { Help } from "components";
import Graph from "components/Graph";
import Node from "components/Node";
import Page from "components/Page";
import { getJsonFromUrl } from "helpers";
import ColorPicker from "hooks/ColorPicker";
import PropTypes from "prop-types";
import React, { Component } from "react";
import fileDownload from "react-file-download";
import buttonImg from "./interactions/button.png";
import checkboxImg from "./interactions/checkbox.png";
import fileImg from "./interactions/file.png";
import signatureImg from "./interactions/signature.png";
import textImg from "./interactions/text.png";
// styles
import styles from "./styles";

function StageInspector({ node, api, setSaving, close, NotificationCenter }) {
  let stage = node.data.stage;
  const [name, setName] = React.useState(stage.name || "");
  const [available, setAvailable] = React.useState(stage.available || "");
  const [color, setColor] = React.useState(stage.color || "");
  const [excluded, setExcluded] = React.useState(stage.excluded || "");
  const [icon, setIcon] = React.useState(stage.icon || "");
  const [manual, setManual] = React.useState(stage.manual || false);
  const [nudgeable, setNudgeable] = React.useState(stage.nudgeable || false);
  const [primary, setPrimary] = React.useState(stage.primary || false);
  const [silent, setSilent] = React.useState(stage.silent || false);
  const [position, setPosition] = React.useState(stage.position || "");
  const [hasChanged, setHasChanged] = React.useState(false);

  const save = async (needClose) => {
    setHasChanged(false);
    setSaving(true);
    await api.updateStage(stage.id, {
      name,
      available,
      color,
      excluded,
      icon,
      manual,
      nudgeable,
      primary,
      silent,
      position,
      hasChanged,
    });
    await api.refresh();
    setSaving(false);
    if (needClose) close();
  };

  const deleteStage = async () => {
    setHasChanged(false);
    setSaving(true);
    await api.deleteStage(stage.id);
    await api.refresh();
    setSaving(false);
    close();
  };

  const addInteractor = async () => {
    setHasChanged(false);
    setSaving(true);
    await api.createInteractor({
      stageID: stage.id,
      name: "New Interactor",
      kind: "button",
    });
    await api.refresh();
    setSaving(false);
    close();
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (hasChanged) {
          save(true);
        } else {
          close();
        }
      }}
    >
      <div style={{ padding: 12, flex: 1 }}>
        <Grid container justify="space-between" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="caption" color="textSecondary">
              #{stage.id}
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              label="Apply Changes"
              size="small"
              style={{ color: "white", background: color }}
              onClick={() => save(false)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setName(e.target.value);
                setHasChanged(true);
              }}
              value={name}
              label="Name"
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setIcon(e.target.value);
                setHasChanged(true);
              }}
              value={icon}
              label="Icon URL"
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Help help="Color of the stage" title="Color" />
          </Grid>
          <Grid item>
            <ColorPicker
              onChange={(c) => {
                setColor(c);
                setHasChanged(true);
              }}
              color={color}
            />
          </Grid>
          <div style={{ flexBasis: "100%", height: 0 }} />
          <Grid item>
            <Help
              help="Manual action is needed to go to this stage."
              title="Manual"
            />
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <i class="fas fa-hand-point-up"></i>
            <Checkbox
              onChange={(e) => {
                setManual(e.target.checked);
                setHasChanged(true);
              }}
              style={{ color }}
              checked={manual}
            />
          </Grid>
          <div style={{ flexBasis: "100%", height: 0 }} />
          <Grid item>
            <Help help="Stage is not predominant. " title="Silent" />
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <i class="fas fa-ghost"></i>
            <Checkbox
              onChange={(e) => {
                setSilent(e.target.checked);
                setHasChanged(true);
              }}
              style={{ color }}
              checked={silent}
            />
          </Grid>
          <div style={{ flexBasis: "100%", height: 0 }} />
          <Grid item>
            <Help
              help="Can send a nudge if target doesn't answer."
              title="Nudgeable"
            />
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <i class="fas fa-bullhorn"></i>
            <Checkbox
              onChange={(e) => {
                setNudgeable(e.target.checked);
                setHasChanged(true);
              }}
              style={{ color }}
              checked={nudgeable}
            />
          </Grid>
          <div style={{ flexBasis: "100%", height: 0 }} />
          <Grid item>
            <Help help="Pipeline's entry point." title="Primary" />
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <i class="fas fa-long-arrow-right"></i>
            <Checkbox
              onChange={(e) => {
                setPrimary(e.target.checked);
                setHasChanged(true);
              }}
              style={{ color }}
              checked={primary}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setPosition(e.target.value);
                setHasChanged(true);
              }}
              value={position}
              label="Position (x,y)"
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setAvailable(e.target.value);
                setHasChanged(true);
              }}
              value={available}
              label="Available"
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setExcluded(e.target.value);
                setHasChanged(true);
              }}
              value={excluded}
              label="Excluded"
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 8 }}>
          <Chip
            label="Add Interactor"
            variant="outlined"
            size="small"
            onClick={addInteractor}
          />
          <Chip
            label="Delete Stage"
            variant="outlined"
            style={{
              color: "#f44336",
              borderColor: "#f44336",
              marginLeft: 8,
            }}
            size="small"
            onClick={deleteStage}
          />
        </Grid>
      </div>
    </ClickAwayListener>
  );
}

function InteractorInspector({
  node,
  api,
  setSaving,
  close,
  NotificationCenter,
}) {
  let interactor = node.data.interactor;
  const [name, setName] = React.useState(interactor.name || "");
  const [title, setTitle] = React.useState(interactor.title || "");
  const [description, setDescription] = React.useState(
    interactor.description || ""
  );
  const [kind, setKind] = React.useState(interactor.kind || "");
  const [segueID, setSegueID] = React.useState(interactor.segueID);
  const [order, setOrder] = React.useState(interactor.order);
  const [stageID, setStageID] = React.useState(interactor.stageID);
  const [icon, setIcon] = React.useState(interactor.icon);
  const [color, setColor] = React.useState(interactor.color);
  const [mandatory, setMandatory] = React.useState(
    interactor.mandatory || false
  );
  const [hasChanged, setHasChanged] = React.useState(false);

  const save = async (needClose) => {
    setHasChanged(false);
    setSaving(true);
    await api.updateInteractor(interactor.id, {
      name,
      kind,
      description,
      mandatory,
      title,
      icon,
      color,
      order: order ? Number(order) : undefined,
      segueID: segueID ? Number(segueID) : undefined,
      stageID: stageID ? Number(stageID) : undefined,
    });
    await api.refresh();
    setSaving(false);
    if (needClose) close();
  };

  const deleteInteractor = async () => {
    setHasChanged(false);
    setSaving(true);
    await api.deleteInteractor(interactor.id);
    await api.refresh();
    setSaving(false);
    close();
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (hasChanged) {
          save(true);
        } else {
          close();
        }
      }}
    >
      <div style={{ padding: 12, flex: 1 }}>
        <Grid container justify="space-between" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="caption" color="textSecondary">
              #{interactor.id}
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              label="Apply Changes"
              size="small"
              style={{ color: "white", background: "#2196f3" }}
              onClick={() => save(false)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setName(e.target.value);
                setHasChanged(true);
              }}
              value={name}
              label="Name"
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setKind(e.target.value);
                setHasChanged(true);
              }}
              value={kind}
              label="Kind"
              variant="filled"
              size="small"
              fullWidth
              select
              SelectProps={{
                MenuProps: { disablePortal: true },
              }}
            >
              <MenuItem value="button">Button</MenuItem>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="signature">Signature</MenuItem>
              <MenuItem value="file">File Upload</MenuItem>
              <MenuItem value="download">File Download</MenuItem>
              <MenuItem value="checkbox">Checkbox</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setTitle(e.target.value);
                setHasChanged(true);
              }}
              value={title}
              label="Title"
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setDescription(e.target.value);
                setHasChanged(true);
              }}
              value={description}
              label="Description"
              multiline
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setIcon(e.target.value);
                setHasChanged(true);
              }}
              value={icon}
              label="Icon Class"
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography>Color of the Interactor</Typography>
          </Grid>
          <Grid item>
            <ColorPicker
              onChange={(c) => {
                setColor(c);
                setHasChanged(true);
              }}
              color={color}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setStageID(e.target.value);
                setHasChanged(true);
              }}
              value={stageID}
              label="StageID"
              multiline
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setSegueID(e.target.value);
                setHasChanged(true);
              }}
              value={segueID}
              label="SegueID"
              multiline
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setOrder(e.target.value);
                setHasChanged(true);
              }}
              value={order}
              label="Order"
              multiline
              variant="filled"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Help
              help="Needed before going to the next stage"
              title="Mandatory"
            />
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              onChange={(e) => {
                setMandatory(e.target.checked);
                setHasChanged(true);
              }}
              style={{ color: "#2196f3" }}
              checked={mandatory}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 8 }}>
            <Chip
              label="Delete Interactor"
              variant="outlined"
              style={{
                color: "#f44336",
                borderColor: "#f44336",
                marginLeft: 8,
              }}
              size="small"
              onClick={deleteInteractor}
            />
          </Grid>
        </Grid>
      </div>
    </ClickAwayListener>
  );
}

class WrapperPipelinePage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  state = {};
  graphContainer = React.createRef();

  constructor(...args) {
    super(...args);
    window.addEventListener("resize", this.setGridSize.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pipeline && !this.state.width && !this.state.height) {
      setTimeout(this.setGridSize.bind(this), 400);
      this.setState({ pipeline: nextProps.pipeline });
    }

    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.setState({
        pipeline: nextProps.pipeline,
        refreshKey: nextProps.refreshKey,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setGridSize.bind(this));
  }

  onGraphChange(np) {
    this.setState({ pipeline: np });
  }

  setGridSize() {
    this.setState({
      width: this.graphContainer?.current?.clientWidth,
      height: this.graphContainer?.current?.clientHeight,
    });
  }

  async handleExport() {
    const { pipeline } = this.state;

    try {
      fileDownload(JSON.stringify(pipeline, null, "\t"), "pipeline.json");
    } catch (error) {
      console.log(error);
    }
  }

  async save() {
    const { pipeline, api } = this.props;
    const newPipeline = this.state.pipeline;
    const proms = [];
    this.setState({ saving: true });
    for (const key in pipeline.nodes) {
      if (Object.hasOwnProperty.call(pipeline.nodes, key)) {
        const node = pipeline.nodes[key];
        const newNode = newPipeline.nodes.find((n) => n.id === node.id);
        console.log(node, newNode);
        if (newNode && JSON.stringify(newNode) !== JSON.stringify(node)) {
          // Node has changed
          switch (node.data.type) {
            case "stage":
              proms.push(
                api.updateStage(node.data.stage.id, {
                  position: `${newNode.position.x},${newNode.position.y}`,
                })
              );
              break;
            case "interactor":
              proms.push(
                api.updateInteractor(node.data.interactor.id, {
                  position: `${newNode.position.x},${newNode.position.y}`,
                })
              );
              break;
            default:
              break;
          }
        }
      }
    }
    await Promise.all(proms);
    this.setState({ saving: false });
    api.refresh();
  }

  async newStage() {
    const { api } = this.props;
    const urlParams = getJsonFromUrl(window.location);
    this.setState({ saving: true });
    await api.createStage({
      pipelineID: Number(urlParams.id),
      name: "New Stage",
      color: "#000000",
      position: "100,50",
    });
    await api.refresh();
    this.setState({ saving: false });
  }

  render() {
    const { loading, api } = this.props;
    const { width, height, selectedNode, refreshKey } = this.state;
    const { NotificationCenter } = this.context;
    return (
      <Page noPadding loading={loading}>
        <div
          ref={this.graphContainer}
          style={{ display: "flex", flex: 1, background: "#001E3C" }}
        >
          <div className="dotted" style={{ flex: 1, position: "relative" }}>
            {width && height ? (
              <Graph
                key={refreshKey}
                width={width}
                height={height}
                Node={GitNode}
                snap={20}
                // scale={1}
                // minScale={0.5}
                // maxScale={1}
                onNodeSelect={(sn) =>
                  this.setState({ nodeInspectorOpen: true, selectedNode: sn })
                }
                selectedNodeID={selectedNode?.id}
                shouldNodeFitContent
                isDirected={true}
                json={{ ...this.state.pipeline }}
                onChange={this.onGraphChange.bind(this)}
              />
            ) : (
              []
            )}
            <div
              style={{
                position: "absolute",
                top: 2,
                left: 2,
                color: "rgba(255,255,255,0.8)",
                fontSize: 10,
              }}
            >
              {height}px x {width}px
            </div>
            <div
              style={{
                position: "absolute",
                top: 8,
                left: 8,
              }}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <Chip
                    style={{
                      background: "#ff9800",
                      color: "white",
                    }}
                    size={"small"}
                    label={"+ Stage"}
                    onClick={this.newStage.bind(this)}
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={this.save.bind(this)}>
                    <SaveIcon style={{ color: "white" }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={this.handleExport.bind(this)}>
                    <CloudDownload style={{ color: "white" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </div>
          <Drawer
            anchor="right"
            variant="persistent"
            open={this.state.nodeInspectorOpen}
          >
            <div
              style={{
                flex: 1,
                paddingTop: 47,
                display: "flex",
                width: 320,
              }}
            >
              {selectedNode && selectedNode.data.type === "stage" ? (
                <StageInspector
                  node={selectedNode}
                  NotificationCenter={NotificationCenter}
                  api={api}
                  setSaving={(s) => this.setState({ saving: s })}
                  close={() => {
                    this.setState({
                      nodeInspectorOpen: false,
                      selectedNode: undefined,
                    });
                  }}
                />
              ) : (
                []
              )}
              {selectedNode && selectedNode.data.type === "interactor" ? (
                <InteractorInspector
                  node={selectedNode}
                  NotificationCenter={NotificationCenter}
                  api={api}
                  setSaving={(s) => this.setState({ saving: s })}
                  close={() => {
                    this.setState({
                      nodeInspectorOpen: false,
                      selectedNode: undefined,
                    });
                  }}
                />
              ) : (
                []
              )}
            </div>
          </Drawer>
        </div>
        <Backdrop
          open={this.state.saving}
          style={{ zIndex: 99999, color: "white" }}
        >
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="inherit" />
            <Typography>Loading...</Typography>
          </div>
        </Backdrop>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperPipelinePage);

function Interactor({ node, onNodeSelect }) {
  let icon;

  switch (node.data.interactor.kind) {
    case "button":
      icon = buttonImg;
      break;
    case "checkbox":
      icon = checkboxImg;
      break;
    case "file":
      icon = fileImg;
      break;
    case "signature":
      icon = signatureImg;
      break;
    case "text":
      icon = textImg;
      break;
    default:
      icon = buttonImg;
      break;
  }
  return (
    <div
      onDoubleClick={() => onNodeSelect(node)}
      style={{
        color: "white",
        justifyContent: "space-between",
        border: "solid 1px white",
        alignItems: "center",
        display: "flex",
        borderRadius: 20,
        position: "relative",
        width: 158,
        background: "rgba(255,255,255,0.1)",
        backdropFilter: "blur(10px)",
        WebkitBackdropFilter: "blur(10px)",
      }}
    >
      {icon ? (
        <Tooltip title={node.data.interaction || "button"}>
          <img src={icon} alt="icon" style={{ height: 16, paddingLeft: 8 }} />
        </Tooltip>
      ) : (
        <div style={{ width: 14, paddingLeft: 8 }} />
      )}
      <div style={{ flexGrow: 1, textAlign: "center" }}>
        {node.data.interactor.name}{" "}
        {node.data.interactor.mandatory ? (
          <sup style={{ position: "absolute" }}>*</sup>
        ) : (
          []
        )}
      </div>
      <div style={{ width: 14, paddingRight: 8 }} />
      <div
        style={{
          position: "absolute",
          right: -15,
          color: "white",
          fontSize: 9,
        }}
      >
        {node.data.interactor.id}
      </div>
    </div>
  );
}

function Stage({ node, onNodeSelect }) {
  return (
    <Paper
      onDoubleClick={() => onNodeSelect(node)}
      style={{
        width: 150,
        height: 20,
        borderRadius: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        padding: 4,
      }}
    >
      {node.data.stage.primary ? (
        <div
          style={{ position: "absolute", left: -20, top: 18, color: "white" }}
        >
          <i class="fas fa-long-arrow-right"></i>
        </div>
      ) : (
        []
      )}
      {node.data.stage.manual ? (
        <div style={{ position: "absolute", left: -20, color: "white" }}>
          <Tooltip title="Manual Trigger">
            <div>
              <i class="fas fa-hand-point-up"></i>
            </div>
          </Tooltip>
        </div>
      ) : (
        []
      )}
      <img
        src={node.data.stage.icon}
        alt={"icon"}
        style={{
          height: 20,
          width: 20,
        }}
      />
      <Typography variant="body2">{node.data.stage.name}</Typography>
      <div style={{ display: "flex", paddingRight: 4 }}>
        {node.data.stage.silent ? (
          <Tooltip title="Silent Stage">
            <div style={{ color: "#9e9e9e" }}>
              <i class="fas fa-ghost"></i>
            </div>
          </Tooltip>
        ) : (
          <div />
        )}
        {node.data.stage.nudgeable ? (
          <Tooltip title="Nudgeable">
            <div style={{ color: "#9e9e9e" }}>
              <i class="fas fa-bullhorn"></i>
            </div>
          </Tooltip>
        ) : (
          <div />
        )}
      </div>
      <div
        style={{
          position: "absolute",
          right: -15,
          color: "white",
          fontSize: 9,
        }}
      >
        {node.data.stage.id}
      </div>
    </Paper>
  );
}

class GitNode extends Node {
  renderContainer({ json, node, onNodeSelect }) {
    if (node.data.type === "stage") {
      return (
        <Grow in>
          <Stage node={node} onNodeSelect={onNodeSelect} />
        </Grow>
      );
    }

    if (node.data.type === "interactor") {
      return (
        <Grow in>
          <Interactor node={node} onNodeSelect={onNodeSelect} />
        </Grow>
      );
    }
  }
}
