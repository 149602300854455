import Chip from "@material-ui/core/Chip";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import EventStatusesList from "components/EventStatusesList";
import FormEventStatuse from "components/FormEventStatuse";
// reactor
import Page from "components/Page";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperEventStatusePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    eventStatuses: PropTypes.array,
    createEventStatuse: PropTypes.func,
    deleteEventStatuse: PropTypes.func,
    updateEventStatuse: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedEventStatuse: undefined,
      formEventStatuseOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { eventStatuses } = this.props;

    switch (index) {
      case 0:
        return (
          <EventStatusesList
            eventStatuses={eventStatuses}
            onSelect={(selectedEventStatuse) =>
              this.setState({
                selectedEventStatuse,
                formEventStatuseOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createEventStatuse,
      deleteEventStatuse,
      updateEventStatuse,
      refresh,
    } = this.props;

    const { index, selectedEventStatuse, formEventStatuseOpen } = this.state;

    return (
      <Page
        helmet="EventStatuses"
        loading={loading}
        loadingMessage={"Fetching all EventStatuses"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div>
          <Chip
            onClick={() => this.setState({ formEventStatuseOpen: true })}
            color="secondary"
            label="+ Event Status"
            style={{ marginBottom: 8 }}
          />
          {this.getStepContent()}
        </div>
        <FormEventStatuse
          open={formEventStatuseOpen}
          eventStatuse={selectedEventStatuse}
          close={() => {
            this.setState({
              formEventStatuseOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedEventStatuse: undefined,
                }),
              200
            );
          }}
          createEventStatuse={createEventStatuse}
          deleteEventStatuse={deleteEventStatuse}
          updateEventStatuse={updateEventStatuse}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEventStatusePage);
