import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class HelpCenter extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    getHelpCenter: PropTypes.func,
  };

  static childContextTypes = {
    HelpCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { getHelpCenter } = this.props;
    if (getHelpCenter) {
      getHelpCenter(this.getChildContext());
    }
  }

  state = {
    open: false,
    hover1: false,
    hover2: false,
    hover3: false,
  };

  getChildContext() {
    return {
      HelpCenter: {
        open: this.open.bind(this),
        close: this.close.bind(this),
      },
    };
  }

  open() {
    this.setState({ open: true });
  }

  close() {
    this.setState({ open: false });
  }

  render() {
    const { children, classes } = this.props;
    const { open, hover1, hover2, hover3 } = this.state;

    return (
      <div>
        {children}
        <Dialog
          open={open}
          onClose={this.close.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paper: classes.paper,
          }}
        >
          <DialogTitle id="alert-dialog-title">
            How can we help you with ?
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Paper
                  onMouseEnter={() => this.setState({ hover1: true })}
                  onMouseLeave={() => this.setState({ hover1: false })}
                  elevation={hover1 ? 6 : 1}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://www.blog.rhapsody.la/help-center/",
                      "_blank"
                    )
                  }
                >
                  <Grid
                    style={{
                      background: "#64b5f6",
                      height: 120,
                    }}
                    justify="center"
                    alignItems="center"
                    container
                  >
                    <Grid item>
                      <img
                        src="https://storage.googleapis.com/rhapsody/media/doc.png"
                        alt="doc"
                        style={{ height: 80 }}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ padding: 8, textAlign: "center" }}>
                    <Typography variant="h6" color="textPrimary">
                      How to ?
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      View our guided tutorials
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper
                  onMouseEnter={() => this.setState({ hover2: true })}
                  onMouseLeave={() => this.setState({ hover2: false })}
                  elevation={hover2 ? 6 : 1}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "mailTo:contact@rhapsody.la?subject=Rhapsody Feature Request&body=Describe your feature :",
                      "_blank"
                    )
                  }
                >
                  <Grid
                    style={{
                      background: "#b0bec5",
                      height: 120,
                    }}
                    justify="center"
                    alignItems="center"
                    container
                  >
                    <Grid item>
                      <img
                        src="https://storage.googleapis.com/rhapsody/media/feature.png"
                        alt="doc"
                        style={{ height: 80 }}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ padding: 8, textAlign: "center" }}>
                    <Typography variant="h6" color="textPrimary">
                      Feature Request
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      What would you love to have ?
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper
                  onMouseEnter={() => this.setState({ hover3: true })}
                  onMouseLeave={() => this.setState({ hover3: false })}
                  elevation={hover3 ? 6 : 1}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.$crisp.push(["do", "chat:open"]);
                    window.$crisp.push(["do", "chat:show"]);
                    this.close();
                  }}
                >
                  <Grid
                    style={{
                      background: "#c5e1a5",
                      height: 120,
                    }}
                    justify="center"
                    alignItems="center"
                    container
                  >
                    <Grid item>
                      <img
                        src="https://storage.googleapis.com/rhapsody/media/chat.png"
                        alt="doc"
                        style={{ height: 80 }}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ padding: 8, textAlign: "center" }}>
                    <Typography variant="h6" color="textPrimary">
                      Live Chat
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      General Questions, bug report... etc
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.close} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(HelpCenter);
