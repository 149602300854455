import {
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as Actions from "actions";
import { REACT_APP_API } from "config";
import { STUDIO_SESSION_DETAIL } from "constants/routes";
import { buildForURL, formatDate, move, reorder } from "helpers";
import { getSectionDragType } from "helpers/rhapsody";
import MusicianAvatar from "hooks/MusicianAvatar";
import moment from "moment";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";

const musicianStyle = makeStyles(styles);

const MusicianChip = ({
  job,
  sync,
  newRoleResult,
  setNewRoleResult,
  selectedToSync,
}) => {
  const classes = musicianStyle(styles);
  const [value, setValue] = React.useState(job.sectionRoleID || 9);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const sectionRoles = useSelector((state) => state.sectionRoles);

  React.useEffect(() => {
    if (
      newRoleResult &&
      sync &&
      newRoleResult.job.musician?.id === job.musician?.id &&
      selectedToSync.indexOf(job.sessionID) !== -1
    ) {
      changeSectionRole(newRoleResult.role);
    }
  }, [newRoleResult]);

  const changeSectionRole = async (role) => {
    setValue(role.id);
    if (role)
      await dispatch(
        Actions.updateStudioSessionMusician(job.id, {
          sectionRoleID: role.id,
        })
      );
    job.sectionRoleID = role.id;
  };

  return (
    <div className={classes.musicianCard} elevation={0}>
      <Typography noWrap variant="body2">
        <MusicianAvatar noBadge size={20} musician={job.musician} />{" "}
        {job.musician?.id
          ? job.musician.nickName ||
            `${job.musician.firstName} ${job.musician.lastName}`
          : "Empty Chair"}
      </Typography>
      <div
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className={classes.roleChip}
        style={{ minWidth: 30 }}
      >
        <Typography noWrap variant="caption" color="textSecondary">
          {sectionRoles.find((s) => s.id === value)?.name}
        </Typography>
      </div>
      <Menu
        anchorEl={anchorEl}
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {sectionRoles?.map((sr) => (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              if (sync) {
                setNewRoleResult({ job: job, role: sr });
              } else {
                changeSectionRole(sr);
              }
            }}
            value={sr.id}
          >
            {sr.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid,
  margin: `${grid}px ${grid}px ${grid}px ${grid}px`,

  // change background colour if dragging
  background: isDragging ? "white" : "transparent",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "rgba(139,195,74,0.2)" : "transparent",
  borderRadius: 4,
  padding: grid,
  width: 250,
});

function WorkSessionRoster({
  onWSSelect,
  workSession,
  sync,
  moveResult,
  setMoveResult,
  newRoleResult,
  setNewRoleResult,
  selectedToSync,
}) {
  const [lists, setLists] = React.useState();
  const [downloadAsEl, setDownloadAsEl] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (workSession.sections) {
      const _lists = {};
      for (const key in workSession.sections) {
        if (Object.hasOwnProperty.call(workSession.sections, key)) {
          const section = workSession.sections[key];
          _lists[section.sectionID] = section.jobs;
        }
      }
      setLists(_lists);
    }
  }, [workSession]);

  React.useEffect(() => {
    if (moveResult && sync && selectedToSync.indexOf(workSession.id) !== -1) {
      onDragEnd(moveResult);
    }
  }, [moveResult]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // console.log(source, destination);

    // dropped outside the list
    if (!destination) {
      return;
    }

    const selectedJob = lists[source.droppableId]?.[source.index];

    // console.log(lists, selectedJob);
    if (!selectedJob) return;
    const newSectionOrder = destination.index;
    const newSectionID = Number(destination.droppableId);
    if (selectedJob)
      dispatch(
        Actions.updateStudioSessionMusician(selectedJob.id, {
          sectionOrder: newSectionOrder,
          sectionID:
            destination.droppableId !== source.droppableId
              ? newSectionID
              : undefined,
        })
      );

    if (source.droppableId === destination.droppableId) {
      // move within the same list
      const result = reorder(
        lists[source.droppableId],
        source.index,
        destination.index
      );

      let _lists = { [source.droppableId]: result };

      setLists({ ...lists, ..._lists });
    } else {
      const result = move(
        lists[source.droppableId],
        lists[destination.droppableId],
        source,
        destination
      );
      setLists({
        ...lists,
        ...result,
      });
    }
  };

  if (!lists) return <div />;

  return (
    <Paper
      style={{
        marginRight: 16,
        overflow: "scroll",
        background:
          sync && selectedToSync.indexOf(workSession.id) !== -1
            ? "rgba(33,150,243, 0.1)"
            : undefined,
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          background:
            sync && selectedToSync.indexOf(workSession.id) !== -1
              ? "rgba(33,150,243, 0.2)"
              : "rgba(155,155,155,0.1)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
          padding: 8,
          zIndex: 99,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {sync ? (
          <div>
            <Checkbox
              color="primary"
              checked={selectedToSync.indexOf(workSession.id) !== -1}
              onChange={(e) => onWSSelect(workSession.id)}
            />
          </div>
        ) : (
          []
        )}
        <div style={{ flexGrow: 1 }}>
          <Typography noWrap>{workSession.title}</Typography>
          <Typography noWrap variant="caption" color="textSecondary">
            {formatDate(workSession.dateFromUTC, workSession.tzName)}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Tooltip title={"Download as..."}>
            <div
              style={{ marginRight: 6, cursor: "pointer" }}
              onClick={(e) => setDownloadAsEl(e.currentTarget)}
            >
              <i class="far fa-cloud-download" style={{ fontSize: 12 }}></i>
            </div>
          </Tooltip>
          <Menu
            anchorEl={downloadAsEl}
            open={Boolean(downloadAsEl)}
            onClose={() => setDownloadAsEl(null)}
          >
            <MenuItem
              onClick={() => {
                setDownloadAsEl(null);
                window.open(
                  `${REACT_APP_API}/rhapsody/workSessions/${workSession.id}/roster`,
                  "_blank"
                );
              }}
            >
              PDF
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDownloadAsEl(null);
                window.open(
                  `${REACT_APP_API}/rhapsody/workSessions/${workSession.id}/rosterCSV`,
                  "_blank"
                );
              }}
            >
              CSV
            </MenuItem>
          </Menu>
          <Tooltip title={"Open in new tab"}>
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                window.open(
                  `${STUDIO_SESSION_DETAIL}?${buildForURL({
                    id: workSession.id,
                    index: 3,
                    back: `${window.location.pathname}${window.location.search}`,
                  })}`,
                  "_blank"
                );
              }}
            >
              <i class="far fa-external-link" style={{ fontSize: 12 }}></i>
            </div>
          </Tooltip>
        </div>
      </div>
      <div style={{ padding: 8 }}>
        <DragDropContext onDragEnd={sync ? setMoveResult : onDragEnd}>
          <div style={{ marginRight: 8 }}>
            {workSession.sections.map((s) => (
              <div key={s.id}>
                <Typography variant="subtitle1">
                  <b>{s.sectionName}</b>
                </Typography>
                <Divider />
                <div>
                  <Droppable
                    droppableId={`${s.sectionID}`}
                    type={getSectionDragType(s)}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {lists[s.sectionID].map((item, index) => {
                          if (item)
                            return (
                              <Draggable
                                key={item.id}
                                type={getSectionDragType(s)}
                                draggableId={`${item.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <MusicianChip
                                      job={item}
                                      setNewRoleResult={setNewRoleResult}
                                      newRoleResult={newRoleResult}
                                      selectedToSync={selectedToSync}
                                      sync={sync}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            ))}
          </div>
        </DragDropContext>
      </div>
    </Paper>
  );
}

export default function Rosters({ project }) {
  const [projectHiring, setProjectHiring] = React.useState();
  const [data, setData] = React.useState();
  const [sync, setSync] = React.useState();
  const [selectedToSync, setSelectedToSync] = React.useState([]);
  const [moveResult, setMoveResult] = React.useState();
  const [newRoleResult, setNewRoleResult] = React.useState();
  const classes = musicianStyle(styles);

  const dispatch = useDispatch();

  useHotkeys(
    "*",
    (e) => {
      if (e.keyCode === 91) {
        setSync(!sync);
      }
      if (e.code === "Escape") {
        setSync(false);
      }
    },
    { enableOnTags: ["INPUT"], keyup: true }
  );

  React.useEffect(() => {
    refresh();
  }, []);

  React.useEffect(() => {
    processData();
  }, [projectHiring]);

  function processData() {
    if (!projectHiring) return;
    var _data = {};

    for (const key in projectHiring.sessions) {
      if (Object.hasOwnProperty.call(projectHiring.sessions, key)) {
        const workSession = projectHiring.sessions[key];
        _data[workSession.id] = {
          ...workSession,
          sections: {},
        };
      }
    }

    for (const key in projectHiring.jobs) {
      if (Object.hasOwnProperty.call(projectHiring.jobs, key)) {
        const job = projectHiring.jobs[key];

        console.log(_data[job.sessionID].sections);

        if (_data[job.sessionID].sections[job.sectionID]) {
          _data[job.sessionID].sections[job.sectionID].jobs.push(job);
        } else {
          const section = projectHiring.sections.find(
            (s) => s.sectionID === job.sectionID
          );
          if (section) {
            _data[job.sessionID].sections[job.sectionID] = {
              ...section,
              jobs: [job],
            };
          }
        }
      }
    }

    _data = Object.values(_data);
    _data.sort(
      (a, b) =>
        moment(a.dateFromUTC).valueOf() - moment(b.dateFromUTC).valueOf()
    );

    for (const k in _data) {
      if (Object.hasOwnProperty.call(_data, k)) {
        const ws = _data[k];
        ws.sections = Object.values(ws.sections);
        for (const j in ws.sections) {
          if (Object.hasOwnProperty.call(ws.sections, j)) {
            const section = ws.sections[j];
            section.jobs.sort((a, b) => a.sectionOrder - b.sectionOrder);
          }
        }

        ws.sections.sort((a, b) => {
          if (a.familyPos == b.familyPos) {
            return a.sectionPos - b.sectionPos;
          }
          k;
          return a.familyPos - b.familyPos;
        });
      }
    }

    setData(_data);
  }

  function refresh() {
    dispatch(Actions.getProjectHiring(project.id)).then((r) => {
      setProjectHiring(r.payload);
    });
  }

  if (!data) {
    return (
      <div className={classes.loading}>
        <CircularProgress style={{ color: "#ffc107" }} />
      </div>
    );
  }

  function selectAllToSync() {
    setSelectedToSync(
      data.reduce((a, v) => {
        a.push(v.id);
        return a;
      }, [])
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        overflow: "scroll",
        width: 0,
        flexDirection: "column",
      }}
    >
      <div className={classes.toolbar}>
        <div className={classes.toolbarGroup1}></div>
        <Hidden smDown>
          <div className={classes.toolbarGroup2}>
            {!sync ? (
              <Typography variant="caption" color="textSecondary">
                <i class="fad fa-hand-paper p-right"></i> Drag and drop to
                reorder musicians, change their roles.
              </Typography>
            ) : (
              <Typography variant="caption" style={{ color: "#2196f3" }}>
                <i class="fad fa-hand-paper p-right"></i> Drag and drop to
                reorder musicians, change their roles{" "}
                <b>
                  across {selectedToSync.length} work session
                  {selectedToSync.length > 1 ? "s" : ""}
                </b>
              </Typography>
            )}
          </div>
        </Hidden>
        <div className={classes.toolbarGroup3}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              {sync
                ? [
                    <Typography
                      variant="caption"
                      className={classes.link}
                      onClick={selectAllToSync}
                    >
                      Select All
                    </Typography>,
                    <Typography
                      variant="caption"
                      className={classes.link}
                      onClick={() => setSelectedToSync([])}
                    >
                      Unselect All
                    </Typography>,
                  ]
                : []}
              <Tooltip title="⌘command">
                <Chip
                  color={sync ? "primary" : undefined}
                  label="Sync Columns"
                  size="small"
                  onClick={() => {
                    setSync(!sync);
                    selectAllToSync();
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <i onClick={refresh} class="fad fa-redo-alt"></i>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        {data.map((d) => (
          <div style={{ width: 320 }} key={d.id}>
            <WorkSessionRoster
              workSession={d}
              sync={sync}
              newRoleResult={newRoleResult}
              onWSSelect={(wsID) => {
                const index = selectedToSync.indexOf(wsID);
                if (index !== -1) {
                  // ws exists
                  const _selectedToSync = [...selectedToSync];
                  _selectedToSync.splice(index, 1);
                  setSelectedToSync(_selectedToSync);
                } else {
                  setSelectedToSync([...selectedToSync, wsID]);
                }
              }}
              selectedToSync={selectedToSync}
              setNewRoleResult={(e) => setNewRoleResult(e)}
              moveResult={moveResult}
              setMoveResult={(e) => setMoveResult(e)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
