import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Close from "@material-ui/icons/Close";
import FlagIcon from "@material-ui/icons/FlagOutlined";
import MoreVert from "@material-ui/icons/MoreVert";
import NoteAdd from "@material-ui/icons/NoteAddOutlined";
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import SendIcon from "@material-ui/icons/Send";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EyeIcon from "@material-ui/icons/VisibilityOutlined";
import Warning from "@material-ui/icons/Warning";
import Alert from "@material-ui/lab/Alert";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormMusician from "components/FormMusician";
// components
import MemberStatus from "components/MemberStatus";
import MusicianSuggestion from "components/MusicianSuggestion";
// config
import { MANAGE } from "config";
// constants
import { MUSICIAN, MUSICIAN_DETAIL, PROJECT_DETAIL } from "constants/routes";
import { ContentState, EditorState } from "draft-js";
// helpers
import { hexToRgbA } from "helpers/colors";
// hooks
import MusicianAvatar from "hooks/MusicianAvatar";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Rhapsody from "./rhapsody.png";
// styles
import styles from "./styles";

class SectionMusician extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    api: PropTypes.object,
    theme: PropTypes.object,
    disabled: PropTypes.bool,
    sectionMusician: PropTypes.object,
    sectionRoles: PropTypes.array,
    instruments: PropTypes.array,
    refresh: PropTypes.array,
    onMusicianEdit: PropTypes.func,
    studioSession: PropTypes.object,
    history: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    SubscriptionCenter: PropTypes.object,
    TourCenter: PropTypes.object,
    Notify: PropTypes.object,
  };

  state = {
    roleAnchor: null,
    notes: "",
    statusAnchor: null,
    popperAnchor: null,
    menuAnchor: null,
    sectionMusicianFull: undefined,
    sectionMusicianFullLoading: true,
    formMusicianOpen: false,
    notesOpen: false,
    musiciansLoading: false,
    loading: false,
    menuOpen: false,
  };

  async getStudioSessionMusicianById() {
    const { api, sectionMusician } = this.props;

    const resp = await api.getStudioSessionMusicianById(sectionMusician.id);
    if (resp.success) {
      this.setState({
        sectionMusicianFull: resp.payload,
        sectionMusicianFullLoading: false,
      });
    }
  }

  setMusicianLoading(loading) {
    this.setState({ loading });
  }

  async getSectionMusicians(e) {
    const {
      api,
      sessionSection,
      studioSession,
      sectionRoles,
      sectionMusician,
    } = this.props;
    const { TourCenter } = this.context;

    const role = sectionRoles.find(
      (sr) => sr.id === sectionMusician.sectionRoleID
    );

    if (TourCenter) TourCenter.next();

    this.setState({ musiciansLoading: true, popperAnchor: e.currentTarget });
    const r = await api.getInstrumentSectionMusicians(
      sessionSection.id,
      studioSession.id,
      role.id
    );
    if (r.success) {
      this.setState({ sectionMusicians: r.payload, musiciansLoading: false });
    }
  }

  getPlainText(value) {
    let es;
    if (value) {
      const html = value;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        es = EditorState.createWithContent(contentState);
        if (es) {
          return es.getCurrentContent().getPlainText("\u0001");
        }
      }
    }

    return value;
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleStatusClick = (event) => {
    this.setState({ statusAnchor: event.currentTarget });
  };

  handleStatusClose = () => {
    this.setState({ statusAnchor: null });
  };

  handleRoleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ roleAnchor: event.currentTarget });
  };

  handleRoleClose = () => {
    this.setState({ roleAnchor: null });
  };

  handleMenuClick = (event) => {
    this.setState({ menuAnchor: event.currentTarget, menuOpen: true });
  };

  handleMenuClose = () => {
    this.setState({
      menuAnchor: null,
      menuOpen: false,
    });
  };

  async removeMusician() {
    const { api, sectionMusician, refresh } = this.props;
    this.setState({ loading: true });
    await api.updateStudioSessionMusician(sectionMusician.id, {
      musicianID: -1,
      instrumentID: -1,
    });
    await refresh();
    this.setState({ loading: false });
  }

  async changeSectionRole(role) {
    const { api, sectionMusician, refresh } = this.props;
    this.handleRoleClose();
    this.setState({ loading: true });
    await api.updateStudioSessionMusician(sectionMusician.id, {
      sectionRoleID: role.id,
    });
    await refresh();
    this.setState({ loading: false });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm you action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { api, sectionMusician, refresh } = this.props;
    this.setState({ loading: true });
    await api.deleteStudioSessionMusician(sectionMusician.id);
    await refresh();
    this.setState({ loading: false });
  }

  handleStatusChange = (s) => async (event) => {
    const { api, sectionMusician, studioSession, refresh } = this.props;
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      changeStatusOpen: false,
      loading: true,
    });
    await api.createEvent({
      sessionID: studioSession.id,
      statusID: s.id,
      musicianID: sectionMusician.musician.id,
    });
    await refresh();
    this.setState({ loading: false });
  };

  async handleNotes() {
    const { api, sectionMusician, refresh } = this.props;

    const { notes } = this.state;

    this.setState({
      notesOpen: false,
      loading: true,
    });

    this.handleStatusClose();
    this.handleMenuClose();

    await api.updateStudioSessionMusician(sectionMusician.id, { memo: notes });

    await refresh();
    this.setState({
      loading: false,
      notes: "",
    });
  }

  async fetchEventStatuses() {
    const { api, sectionMusician } = this.props;

    const r = await api.getSessionMusicianStatuses(sectionMusician.id);
    this.setState({
      eventStatuses: r.payload,
    });
  }

  async linkMusician(musician) {
    const { api, sectionMusician, refresh } = this.props;
    this.closeMusicianSuggestions();
    this.setState({ loading: true });
    await api.updateStudioSessionMusician(sectionMusician.id, {
      musicianID: musician.id,
    });
    await refresh();
    this.setState({ loading: false });
  }

  closeMusicianSuggestions() {
    this.setState({
      popperAnchor: null,
    });
  }

  handleChangeStatusClose() {
    this.setState({
      changeStatusOpen: false,
    });
  }

  handleChangeStatusOpen() {
    this.fetchEventStatuses();
    this.setState({
      changeStatusOpen: true,
    });
  }

  async viewMusicianCard(id) {
    const { api } = this.props;
    this.setState({
      formMusicianOpen: true,
      musicianLoading: true,
    });

    const r = await api.getMusicianById(id);
    this.setState({
      musician: r.payload,
      musicianLoading: false,
    });
  }

  render() {
    const {
      classes,
      sectionMusician,
      sectionRoles,
      studioSession,
      disabled,
      history,
      theme,
      instruments,
      refresh,
      api,
    } = this.props;

    const { NotificationCenter } = this.context;

    const { Notify } = this.context;

    const { musician } = sectionMusician;

    const {
      roleAnchor,
      menuAnchor,
      musiciansLoading,
      notesOpen,
      loading,
      notes,
      sectionMusicianFull,
      sectionMusicianFullLoading,
      menuOpen,
      eventStatuses,
      musicianLoading,
      hover,
      prevent,
      sectionMusicians,
      popperAnchor,
      formMusicianOpen,
      changeStatusOpen,
    } = this.state;

    const { displayedEvent } = sectionMusician;
    const role = sectionRoles.find(
      (sr) => sr.id === sectionMusician.sectionRoleID
    );

    const lastStatus = displayedEvent && displayedEvent.status;

    const cantRemove =
      displayedEvent &&
      displayedEvent.status &&
      ((displayedEvent.status.id === 9 && !displayedEvent.readReceipt) ||
        displayedEvent.status.id === 1 ||
        displayedEvent.status.id === 6 ||
        displayedEvent.status.id === 8);

    const darkMode = theme.palette.type === "dark";

    const musicianFeedback =
      lastStatus &&
      (lastStatus.id === 1 || lastStatus.id === 2 || lastStatus.id === 3);
    return (
      <Paper
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        data-tut="reactour__musician"
        className={classes.container}
        onClick={
          musician &&
          musician.id === null &&
          !popperAnchor &&
          this.getSectionMusicians.bind(this)
        }
        style={{
          cursor: !disabled ? "pointer" : undefined,
          position: "relative",
          transition: "box-shadow 0.3s ease-in-out, width 0.3s ease-in-out",
          boxShadow: hover
            ? "0 2px 7px rgba(155,155,155,0.3)"
            : "0 5px 10px rgba(0,0,0,0)",
          backgroundColor: lastStatus
            ? hexToRgbA(lastStatus.color, 0.15)
            : undefined,
          border: lastStatus
            ? `solid 1px ${hexToRgbA(lastStatus.color, 0.8)}`
            : undefined,
        }}
      >
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Chip
              aria-owns={roleAnchor ? "simple-menu" : undefined}
              aria-haspopup="true"
              size="small"
              onDelete={this.handleRoleClick}
              onClick={this.handleRoleClick}
              label={role.name}
              deleteIcon={<ArrowDropDownIcon />}
              className={lastStatus ? classes.chip : classes.emptyChip}
            />
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Menu
                id="simple-menu"
                anchorEl={roleAnchor}
                open={Boolean(roleAnchor)}
                onClose={this.handleRoleClose}
              >
                {sectionRoles.map((sr) => (
                  <MenuItem
                    key={sr.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.changeSectionRole(sr);
                    }}
                  >
                    {sr.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid container style={{ width: "100%" }} justify="flex-end">
              <Grid item>
                {lastStatus ? (
                  <div
                    style={{
                      textAlign: "right",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      variant="caption"
                      style={{ color: lastStatus.color, marginTop: -4 }}
                    >
                      <img
                        src={lastStatus.icon}
                        alt="status"
                        style={{ height: 10 }}
                      />{" "}
                      {lastStatus.name}
                    </Typography>
                    {lastStatus ? (
                      <div>
                        <MoreVert
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.handleMenuClick(e);
                            this.getStudioSessionMusicianById();
                          }}
                          style={{
                            height: 15,
                            width: 15,
                            paddingTop: 1,
                            paddingLeft: 4,
                            color: "#9e9e9e",
                          }}
                        />
                        <Popper
                          anchorEl={menuAnchor}
                          open={menuOpen}
                          style={{ zIndex: 1100 }}
                        >
                          <ClickAwayListener
                            onClickAway={() => {
                              if (!prevent) this.handleMenuClose();
                            }}
                          >
                            <Paper style={{ width: 300 }} elevation={24}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    background: "rgba(155,155,155,0.1)",
                                    padding: 10,
                                  }}
                                >
                                  <Grid container alignItems="center">
                                    <Grid item style={{ width: 46 }}>
                                      <MusicianAvatar musician={musician} />
                                    </Grid>
                                    <Grid
                                      item
                                      style={{
                                        maxWidth: "calc(100% - 46px)",
                                        flexGrow: 1,
                                      }}
                                    >
                                      <Grid container alignItems="center">
                                        {musician.userUUID ? (
                                          <Grid item style={{ marginRight: 4 }}>
                                            <MemberStatus
                                              verified={musician.verified}
                                              small
                                            />
                                          </Grid>
                                        ) : (
                                          []
                                        )}
                                        <Grid item>
                                          <Typography variant="body2">
                                            {musician.nickName ||
                                              `${musician.firstName} ${musician.lastName}`}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Tooltip title="Send Email">
                                        <Typography
                                          variant="caption"
                                          display="block"
                                          color="textSecondary"
                                          style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                          }}
                                          onClick={() => {
                                            window.open(
                                              `mailTo:${musician.email}`,
                                              "_blank"
                                            );
                                            this.handleMenuClose();
                                          }}
                                        >
                                          {musician.email}
                                        </Typography>
                                      </Tooltip>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        display="block"
                                      >
                                        {musician.phone}
                                      </Typography>
                                      <Grid container>
                                        <Grid item>
                                          <Tooltip title="Notify">
                                            <IconButton
                                              className={classes.profileButton}
                                              disabled={disabled}
                                              onClick={() =>
                                                Notify.open(sectionMusician.id)
                                              }
                                            >
                                              <SendIcon
                                                color="inherit"
                                                className={
                                                  classes.profileButtonIcon
                                                }
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                        {/* <Grid item>
                                                  <Tooltip
                                                    title={
                                                      displayedEvent.notes
                                                        ? 'Update Memo'
                                                        : 'Add a Memo'
                                                    }
                                                  >
                                                    <IconButton
                                                      className={classes.profileButton}
                                                      disabled={disabled}
                                                      onClick={() => {
                                                        this.setState({
                                                          notesOpen: true,
                                                          notes: sectionMusician.memo,
                                                        });
                                                        this.handleMenuClose();
                                                      }}
                                                    >
                                                      <NoteAdd color="inherit" className={classes.profileButtonIcon} />
                                                    </IconButton>
                                                  </Tooltip>
                                                </Grid> */}
                                        {!musician.userUUID ? (
                                          <Grid item>
                                            <Tooltip title="View Musician Card">
                                              <IconButton
                                                className={
                                                  classes.profileButton
                                                }
                                                onClick={() =>
                                                  this.viewMusicianCard(
                                                    sectionMusician.musicianID
                                                  )
                                                }
                                              >
                                                <PersonOutlined
                                                  color="inherit"
                                                  className={
                                                    classes.profileButtonIcon
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>
                                        ) : (
                                          <Grid item>
                                            <Tooltip title="Go to Profile">
                                              <IconButton
                                                className={
                                                  classes.profileButton
                                                }
                                                onClick={() => {
                                                  history.push(
                                                    `${MUSICIAN_DETAIL}?id=${musician.id}`
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={Rhapsody}
                                                  style={{ height: 20 }}
                                                  alt="rhapsody-logo"
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>
                                        )}
                                        {lastStatus.id !== 10 ? (
                                          <Grid item>
                                            <Tooltip title="View Invite">
                                              <IconButton
                                                className={
                                                  classes.secondaryProfileButton
                                                }
                                                disabled={
                                                  studioSession.archived ||
                                                  studioSession.recorded
                                                }
                                                onClick={() => {
                                                  this.setState({
                                                    prevent: true,
                                                  });
                                                  NotificationCenter.sweetAlert(
                                                    {
                                                      title:
                                                        "You are about to view the invite",
                                                      body: "This will mark the notification as viewed.",
                                                      warning: true,
                                                      timestamp:
                                                        new Date().getTime(),
                                                    },
                                                    {
                                                      cancel: {
                                                        label: "Cancel",
                                                        level: "default",
                                                        callback: () =>
                                                          setTimeout(
                                                            () =>
                                                              this.setState({
                                                                prevent: false,
                                                              }),
                                                            500
                                                          ),
                                                      },
                                                      confirm: {
                                                        label: "View Invite",
                                                        level: "warning",
                                                        callback: () => {
                                                          window.open(
                                                            `${MANAGE}?token=${sectionMusician.publicToken}`,
                                                            "_blank"
                                                          );
                                                          this.handleMenuClose();
                                                        },
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                <EyeIcon
                                                  color="inherit"
                                                  className={
                                                    classes.profileButtonIcon
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>
                                        ) : (
                                          []
                                        )}
                                        <Grid item>
                                          <Tooltip title="Change Status">
                                            <IconButton
                                              disabled={
                                                studioSession.archived ||
                                                studioSession.recorded
                                              }
                                              className={
                                                classes.secondaryProfileButton
                                              }
                                              onClick={this.handleChangeStatusOpen.bind(
                                                this
                                              )}
                                            >
                                              <FlagIcon
                                                color="inherit"
                                                className={
                                                  classes.profileButtonIcon
                                                }
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {sectionMusicianFullLoading ? (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ padding: 10, textAlign: "center" }}
                                  >
                                    <CircularProgress size={20} />
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      maxHeight: 600,
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <Grid container>
                                      {sectionMusicianFull.events &&
                                        sectionMusicianFull.events.map(
                                          (e, i) => {
                                            const rawNote = this.getPlainText(
                                              e.notes
                                            );
                                            return (
                                              <Grid
                                                item
                                                xs={12}
                                                style={{
                                                  borderLeft: `solid 4px ${e.status.color}`,
                                                }}
                                              >
                                                <Grid container>
                                                  <Grid
                                                    item
                                                    style={{
                                                      width: 30,
                                                      padding: 10,
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        width: 18,
                                                        height: 18,
                                                        background:
                                                          "rgba(155,155,155,0.1)",
                                                        border:
                                                          "solid 1px rgba(155,155,155,0.8)",
                                                        borderRadius: 18,
                                                        fontSize: 14,
                                                        color:
                                                          "rgba(155,155,155,0.8)",
                                                      }}
                                                    >
                                                      {Number(i) + 1}
                                                    </div>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    style={{
                                                      width:
                                                        "calc(100% - 35px)",
                                                      padding: 10,
                                                    }}
                                                  >
                                                    <Typography
                                                      variant="caption"
                                                      color="textPrimary"
                                                    >
                                                      <img
                                                        style={{
                                                          height: 10,
                                                        }}
                                                        alt={e.status.name}
                                                        src={e.status.icon}
                                                      />{" "}
                                                      <span
                                                        style={{
                                                          color: e.status.color,
                                                        }}
                                                      >
                                                        {e.status.name}
                                                      </span>
                                                      <br />
                                                      by <b>
                                                        {e.creatorName}
                                                      </b>{" "}
                                                      {moment(
                                                        e.creationDate
                                                      ).fromNow()}
                                                    </Typography>
                                                    <br />
                                                    <Typography
                                                      variant="caption"
                                                      color="textSecondary"
                                                    >
                                                      {moment(
                                                        e.creationDate
                                                      ).format(
                                                        "MM/DD/YYYY HH:mm:ss"
                                                      )}
                                                    </Typography>
                                                    {rawNote ? (
                                                      <>
                                                        <br />
                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              e.status.color,
                                                            border: `solid 1px ${e.status.color}`,
                                                            color: "white",
                                                            marginTop: 8,
                                                            borderRadius: 8,
                                                            maxWidth: "100%",
                                                            padding: "2px 8px",
                                                            wordWrap:
                                                              "break-word",
                                                            position:
                                                              "relative",
                                                            display:
                                                              "inline-block",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              top: -5,
                                                              left: 17,
                                                              position:
                                                                "absolute",
                                                              width: 0,
                                                              height: 0,
                                                              borderLeft:
                                                                "3px solid transparent",
                                                              borderRight:
                                                                "3px solid transparent",
                                                              borderBottom: `5px solid ${e.status.color}`,
                                                            }}
                                                          />
                                                          <div>
                                                            <Typography
                                                              variant="caption"
                                                              color="inherit"
                                                            >
                                                              {rawNote.length >
                                                              100
                                                                ? `${rawNote.slice(
                                                                    0,
                                                                    100
                                                                  )}...`
                                                                : rawNote}
                                                              {rawNote.length >
                                                              100 ? (
                                                                <b
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                    fontSize: 10,
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() => {
                                                                    NotificationCenter.sweetAlert(
                                                                      {
                                                                        body: (
                                                                          <div
                                                                            style={{
                                                                              textAlign:
                                                                                "left",
                                                                            }}
                                                                            dangerouslySetInnerHTML={{
                                                                              __html:
                                                                                e.notes,
                                                                            }}
                                                                          />
                                                                        ),
                                                                        timestamp:
                                                                          new Date().getTime(),
                                                                      },
                                                                      {
                                                                        confirm:
                                                                          {
                                                                            label:
                                                                              "Ok",
                                                                          },
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  View Full
                                                                </b>
                                                              ) : (
                                                                []
                                                              )}
                                                            </Typography>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      []
                                                    )}
                                                    <br />
                                                  </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Divider />
                                                </Grid>
                                              </Grid>
                                            );
                                          }
                                        )}
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                              {displayedEvent.status.id === 9 &&
                              !displayedEvent.readReceipt ? (
                                <MenuItem
                                  disabled={
                                    disabled ||
                                    (displayedEvent.status.id === 9 &&
                                      !displayedEvent.readReceipt)
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    this.removeMusician();
                                    this.handleMenuClose();
                                  }}
                                >
                                  <ListItemIcon>
                                    <Close />
                                  </ListItemIcon>
                                  <ListItemText>
                                    Can't be Removed
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                      display="block"
                                    >
                                      Musician Needs to Read Receipt
                                    </Typography>
                                  </ListItemText>
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  disabled={disabled || cantRemove}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    this.removeMusician();
                                    this.handleMenuClose();
                                  }}
                                >
                                  <ListItemIcon>
                                    <Close />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {cantRemove
                                      ? "Can't be Removed"
                                      : "Remove From Session"}
                                    {cantRemove ? (
                                      <Typography
                                        variant="caption"
                                        display="block"
                                      >
                                        Cancel the musician first
                                      </Typography>
                                    ) : (
                                      []
                                    )}
                                  </ListItemText>
                                </MenuItem>
                              )}
                            </Paper>
                          </ClickAwayListener>
                        </Popper>
                      </div>
                    ) : (
                      []
                    )}
                  </div>
                ) : (
                  []
                )}
                {displayedEvent.readReceipt ? (
                  <Grid
                    container
                    alignItems="center"
                    style={{
                      marginTop: -4,
                    }}
                  >
                    {displayedEvent.status.canNotify ? (
                      <Grid item>
                        <VisibilityIcon
                          style={{
                            fontSize: 12,
                            paddingRight: 2,
                            color: "rgba(155,155,155)",
                          }}
                        />
                      </Grid>
                    ) : (
                      []
                    )}
                    <Grid item>
                      <Tooltip
                        title={moment(displayedEvent.readDate).format(
                          "MM/DD/YYYY HH:mm:ss"
                        )}
                      >
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          style={{ fontSize: 10 }}
                        >
                          {moment(displayedEvent.readDate).fromNow()}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
              {!disabled && !lastStatus ? (
                <Grid item>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={(e) => {
                      this.delete();
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <Close
                      style={{
                        height: 15,
                        width: 15,
                        paddingTop: 1,
                        paddingLeft: 4,
                      }}
                    />
                  </IconButton>
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Grid>
        {musician ? (
          <Grid
            container
            justify="space-between"
            style={{
              paddingTop: 4,
            }}
          >
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item style={{ width: 46, position: "relative" }}>
                  {musician.id === null ? (
                    <i class="fad fa-chair" style={{ fontSize: 30 }}></i>
                  ) : (
                    <MusicianAvatar musician={musician} />
                  )}
                </Grid>
                <Grid
                  item
                  style={{ maxWidth: "calc(100% - 46px)", flexGrow: 1 }}
                >
                  {musician && musician.id ? (
                    <Grid
                      container
                      alignItems="flex-end"
                      justify="space-between"
                    >
                      {musician.userUUID && !musician.verified ? (
                        <Grid item style={{ marginRight: 4 }}>
                          <MemberStatus verified={musician.verified} small />
                        </Grid>
                      ) : (
                        []
                      )}
                      <Grid item>
                        <Typography variant="body2">
                          {musician.nickName ||
                            `${musician.firstName} ${musician.lastName}`}
                        </Typography>
                        {(musician.id && musician.email === null) ||
                        musician.email === "" ? (
                          <Typography style={{ color: "#f44336" }}>
                            <Warning
                              fontSize="small"
                              color="inherit"
                              style={{ height: 15 }}
                            />{" "}
                            No email
                          </Typography>
                        ) : (
                          []
                        )}
                        <Typography variant="caption" color="textSecondary">
                          {sectionMusician.instrument
                            ? sectionMusician.instrument.name
                            : ""}
                        </Typography>
                      </Grid>
                      {!sectionMusician.memo ? (
                        <Grid item>
                          <Tooltip title="Add a Memo">
                            <NoteAdd
                              style={{
                                color: "#9e9e9e",
                                fontSize: 18,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.setState({
                                  notesOpen: true,
                                  notes: sectionMusician.memo,
                                });
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      ) : (
                        []
                      )}
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justify="space-between"
                      alignItems="flex-end"
                    >
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          <b>Empty Chair</b>
                          <br />
                          {!disabled ? "Click to assign a musician" : ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Add a Memo">
                          <NoteAdd
                            style={{
                              color: "#9e9e9e",
                              fontSize: 18,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.setState({
                                notesOpen: true,
                                notes: sectionMusician.memo,
                              });
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                {musicianFeedback && displayedEvent && displayedEvent.notes ? (
                  <Grid
                    item
                    xs={musicianFeedback ? undefined : 12}
                    style={{
                      backgroundColor: musicianFeedback
                        ? lastStatus.color
                        : undefined,
                      border: musicianFeedback
                        ? undefined
                        : `solid 1px ${lastStatus.color}`,
                      color: musicianFeedback ? "white" : lastStatus.color,
                      marginTop: 8,
                      borderRadius: musicianFeedback ? 8 : 4,
                      maxWidth: "100%",
                      padding: "2px 8px",
                      wordWrap: "break-word",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        top: -5,
                        left: 17,
                        position: "absolute",
                        width: 0,
                        height: 0,
                        borderLeft: "3px solid transparent",
                        borderRight: "3px solid transparent",
                        borderBottom: `5px solid ${lastStatus.color}`,
                      }}
                    />
                    <div>
                      <Typography variant="caption" color="inherit">
                        {displayedEvent.notes.length > 100
                          ? `${displayedEvent.notes.slice(0, 100)}...`
                          : displayedEvent.notes}
                        {musicianFeedback &&
                        displayedEvent.notes.length > 100 ? (
                          <b
                            style={{
                              textDecoration: "underline",
                              fontSize: 10,
                            }}
                            onClick={() => {
                              NotificationCenter.sweetAlert(
                                {
                                  body: !musicianFeedback ? (
                                    <div
                                      style={{ textAlign: "left" }}
                                      dangerouslySetInnerHTML={{
                                        __html: displayedEvent.notes,
                                      }}
                                    />
                                  ) : (
                                    displayedEvent.notes
                                  ),
                                  timestamp: new Date().getTime(),
                                },
                                {
                                  confirm: {
                                    label: "Ok",
                                  },
                                }
                              );
                            }}
                          >
                            View Full
                          </b>
                        ) : (
                          []
                        )}
                      </Typography>
                    </div>
                  </Grid>
                ) : (
                  []
                )}
                {sectionMusician.memo ? (
                  <Grid
                    item
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.setState({
                        notesOpen: true,
                        notes: sectionMusician.memo,
                      });
                    }}
                    xs={12}
                    style={{
                      backgroundColor: lastStatus
                        ? hexToRgbA(lastStatus.color, 0.2)
                        : "rgba(155,155,155,0.1)",
                      marginTop: 8,
                      borderRadius: 4,
                      padding: 4,
                      wordWrap: "break-word",
                    }}
                  >
                    <Typography variant="caption" color="textSecondary">
                      <Tooltip title="Edit Memo">
                        <NoteAdd
                          style={{
                            color: "#9e9e9e",
                            fontSize: 18,
                            float: "right",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.setState({
                              notesOpen: true,
                              notes: sectionMusician.memo,
                            });
                          }}
                        />
                      </Tooltip>
                      {`Memo: ${sectionMusician.memo}`}
                    </Typography>
                  </Grid>
                ) : (
                  []
                )}
                {displayedEvent &&
                displayedEvent.status &&
                displayedEvent.status.id === 2 ? (
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      onClick={() => {
                        this.removeMusician();
                      }}
                      variant="outlined"
                      style={{
                        color: displayedEvent.status.color,
                        borderColor: displayedEvent.status.color,
                        marginTop: 4,
                        width: "100%",
                        textTransform: "none",
                      }}
                    >
                      Remove From Session
                    </Button>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          []
        )}
        <Dialog
          open={notesOpen}
          onMouseDown={(e) => e.stopPropagation()}
          onClose={() => this.setState({ notesOpen: false, notes: "" })}
          aria-labelledby="form-dialog-title"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <DialogTitle id="form-dialog-title">
              <Typography variant="h6" color="textPrimary">
                {sectionMusician.memo ? "Edit the Memo" : "Add a Memo"}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ display: "flex", alignItems: "center" }}
              >
                <VisibilityIcon style={{ fontSize: 16, marginRight: 4 }} /> This
                note will be included in all emails sent to {musician.firstName}{" "}
                {musician.lastName}.
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="notes"
                label="Notes"
                fullWidth
                multiline
                error={notes && 255 - notes.length <= 0}
                helperText={
                  notes &&
                  (notes.length > 255
                    ? "Text Too long"
                    : `${255 - notes.length} char left`)
                }
                value={notes}
                onChange={this.handleChange("notes")}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ notesOpen: false, notes: "" })}
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleNotes.bind(this)}
                variant="contained"
                disabled={notes && notes.length > 255}
                color="secondary"
              >
                {sectionMusician.memo ? "Edit" : "Add"}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        {loading ? (
          <div
            style={{
              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)",
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              borderRadius: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "rgba(155,155,155,0.8)",
            }}
          >
            <CircularProgress size={20} color="inherit" />
          </div>
        ) : (
          []
        )}
        <Popover
          open={Boolean(popperAnchor)}
          disableEnforceFocus
          disableRestoreFocus
          onClose={this.closeMusicianSuggestions.bind(this)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          anchorEl={popperAnchor}
        >
          <MusicianSuggestion
            linkMusician={this.linkMusician.bind(this)}
            loading={musiciansLoading}
            sectionRoles={sectionRoles}
            categories={studioSession.hasAlternates}
            onClose={this.closeMusicianSuggestions.bind(this)}
            search
            showPrime={studioSession.hasAlternates}
            sortAlphabetically={!studioSession.hasAlternates}
            label={"Suggestions"}
            suggestions={sectionMusicians}
            footer={
              <>
                {studioSession.hasAlternates ? (
                  <>
                    <Typography color="textSecondary" variant="caption">
                      The project does not contain any more musicians.
                    </Typography>
                    <br />
                    <Chip
                      onClick={() =>
                        history.push(
                          `${PROJECT_DETAIL}?id=${studioSession.projectID}&index=1`
                        )
                      }
                      size="small"
                      label="Go to project's seating"
                    />
                  </>
                ) : (
                  <>
                    <Typography color="textSecondary" variant="caption">
                      The address book does not contain any other musician for
                      this section.
                    </Typography>
                    <br />
                    <Chip
                      onClick={() => history.push(MUSICIAN)}
                      size="small"
                      label="Go to Musicians"
                    />
                  </>
                )}
              </>
            }
          />
        </Popover>
        <FormMusician
          open={formMusicianOpen}
          musician={this.state.musician}
          close={() => {
            this.setState({
              formMusicianOpen: false,
            });
          }}
          refresh={refresh}
          refreshMusician={this.viewMusicianCard.bind(this)}
          instruments={instruments}
          musicianLoading={musicianLoading}
        />
        <Dialog
          open={changeStatusOpen}
          onClose={this.handleChangeStatusClose.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            You are about to change the musician's status manually
          </DialogTitle>
          <DialogContent>
            <Alert severity="warning">
              Note that the musician WON'T receive any notification about this
              change.
            </Alert>
            <Grid
              container
              spacing={1}
              justify="center"
              style={{ marginTop: 16 }}
            >
              {eventStatuses &&
                eventStatuses
                  .filter((e) => e.canSelect)
                  .map((s) => (
                    <Grid
                      item
                      xs={6}
                      md={3}
                      key={s.id}
                      onClick={this.handleStatusChange(s)}
                    >
                      <div className={classes.widget}>
                        <img
                          src={s.icon}
                          style={{
                            height: 45,
                          }}
                          alt="status"
                        />
                        <Typography variant="body1" style={{ fontWeight: 700 }}>
                          {s.name}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-start" }}>
            <Button
              onClick={this.handleChangeStatusClose.bind(this)}
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SectionMusician);
