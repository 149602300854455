import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormStudio from "components/FormStudio";
// reactor
import Page from "components/Page";
// custom
import StudiosList from "components/StudiosList";
import { removeURLParameter, updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperStudioPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    studios: PropTypes.array,
    createStudio: PropTypes.func,
    deleteStudio: PropTypes.func,
    updateStudio: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedStudio: undefined,
      formStudioOpen: urlParams.newStudio,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.urlParams.newStudio) {
      this.setState({ formStudioOpen: true });
    }
  }

  getStepContent() {
    const { index } = this.state;
    const { studios, history } = this.props;

    switch (index) {
      case 0:
        return (
          <StudiosList
            studios={studios}
            history={history}
            onSelect={(selectedStudio) =>
              this.setState({
                selectedStudio,
                formStudioOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createStudio,
      deleteStudio,
      updateStudio,
      refresh,
    } = this.props;

    const { index, selectedStudio, formStudioOpen } = this.state;

    return (
      <Page
        helmet="Studios"
        loading={loading}
        loadingMessage={"Fetching all Studios / Venues"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormStudio
          open={formStudioOpen}
          history={history}
          studio={selectedStudio}
          close={() => {
            this.setState({
              formStudioOpen: false,
            });
            history.push({ search: removeURLParameter("newStudio") });
            setTimeout(
              () =>
                this.setState({
                  selectedStudio: undefined,
                }),
              200
            );
          }}
          createStudio={createStudio}
          deleteStudio={deleteStudio}
          updateStudio={updateStudio}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperStudioPage);
