export default (theme) => ({
  appGrid: {
    height: 47,
  },
  AppBarGreetings: {
    color: "white",
    padding: 12,
  },
  white: {
    color: "white",
  },
  appBar: {
    background: theme.palette.type === "dark" ? "#212121" : "#001E3C",
  },
});
