export const GET_STUDIO_SESSION_MUSICIAN_BY_ID =
  "GET_STUDIO_SESSION_MUSICIAN_BY_ID";
export const UPDATE_STUDIO_SESSION_MUSICIAN = "UPDATE_STUDIO_SESSION_MUSICIAN";
export const DELETE_STUDIO_SESSION_MUSICIAN = "DELETE_STUDIO_SESSION_MUSICIAN";
export const CREATE_STUDIO_SESSION_MUSICIAN = "CREATE_STUDIO_SESSION_MUSICIAN";
export const NOTIFY_SESSION_MUSICIAN = "NOTIFY_SESSION_MUSICIAN";
export const GET_STUDIO_SESSION_MUSICIAN_STATUSES =
  "GET_STUDIO_SESSION_MUSICIAN_STATUSES";
export const NOTIFY_SESSION_MUSICIAN_PREVIEW =
  "NOTIFY_SESSION_MUSICIAN_PREVIEW";
