import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import HearingIcon from "@material-ui/icons/Hearing";
import HelpIcon from "@material-ui/icons/Help";
import LocalPlayIcon from "@material-ui/icons/LocalPlay";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MicIcon from "@material-ui/icons/Mic";
// material-ui
import { withStyles } from "@material-ui/styles";
import { formatDateRange } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class Engagement extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    engagement: PropTypes.object,
    studios: PropTypes.array,
    studioSession: PropTypes.object,
    updateEngagement: PropTypes.func,
    deleteEngagement: PropTypes.func,
    onSelect: PropTypes.func,
    refresh: PropTypes.func,
    disabled: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { engagement } = this.props;
    this.state = {
      ...engagement,
      hover: false,
      loading: false,
    };
  }

  getAvatarIcon() {
    const { engagement } = this.props;

    switch (engagement.name) {
      case "Recording":
        return <MicIcon />;
      case "Rehearsal":
        return <HearingIcon />;
      case "Performance":
        return <LocalPlayIcon />;
      default:
        return <HelpIcon />;
    }
  }

  getWording() {
    const { engagement } = this.props;

    switch (engagement.name) {
      case "Recording":
        return engagement.name;
      case "Rehearsal":
        return engagement.name;
      case "Performance":
        return "Live";
      default:
        return <HelpIcon />;
    }
  }

  getColor() {
    const { engagement } = this.props;

    switch (engagement.name) {
      case "Recording":
        return "#f44336";
      case "Rehearsal":
        return "#9e9e9e";
      case "Performance":
        return "#2196f3";
      default:
        return "#9e9e9e";
    }
  }

  getVenue() {
    const { engagement, studios } = this.props;
    return studios.find((s) => s.id === engagement.venueID);
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name) {
    const { updateEngagement, refresh, engagement } = this.props;
    if (name) {
      if (engagement[name] === this.state[name]) return;
      this.setState({
        loading: true,
      });
      await updateEngagement(engagement.id, {
        [name]: this.state[name],
      });
    } else {
      await updateEngagement(engagement.id, this.state);
    }

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const { engagement, onSelect } = this.props;

    const { hover } = this.state;

    const venue = this.getVenue();

    return (
      <Paper
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={() => onSelect(engagement)}
        style={{
          position: "relative",
          boxShadow: hover
            ? "0 2px 7px rgba(155,155,155,0.3)"
            : "0 5px 15px rgba(0,0,0,0)",
          cursor: "pointer",
          overflow: "hidden",
          padding: 8,
          borderRadius: 4,
          border: "solid 1px #9e9e9e",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item style={{ textAlign: "center" }}>
            <Avatar style={{ margin: "auto", background: this.getColor() }}>
              {this.getAvatarIcon()}
            </Avatar>
            <Typography variant="caption" color="textSecondary">
              {this.getWording()}
            </Typography>
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h6">
                  {formatDateRange(
                    engagement.dateFromUTC,
                    engagement.dateToUTC,
                    engagement.tzName
                  )}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {engagement.tzName}
                </Typography>
              </Grid>
              <Grid item>
                {engagement.venueID && engagement.venueID > 0 ? (
                  <Chip label={venue && venue.name} icon={<LocationOnIcon />} />
                ) : (
                  <Chip
                    icon={<LocationOnIcon />}
                    label={`${engagement.city}, ${engagement.state}`}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {engagement.description ? (
                <>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      display="block"
                    >
                      DESCRIPTION
                    </Typography>
                    <Typography variant="body2" display="block">
                      {engagement.description}
                    </Typography>
                  </Grid>
                </>
              ) : (
                []
              )}
              {engagement.overtime ? (
                <>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      display="block"
                    >
                      OVERTIME
                    </Typography>
                    <Typography variant="body2" display="block">
                      {engagement.overtime}
                    </Typography>
                  </Grid>
                </>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Engagement);
