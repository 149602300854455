import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class MusicianName extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="body1">
          <span role="img" aria-label="Cat">
            🐱
          </span>{" "}
          Miaou !
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(MusicianName);
