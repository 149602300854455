import { networkAction } from "helpers/network/networkAction";

import {
  GET_STUDIOS,
  GET_STUDIO_BY_ID,
  DELETE_STUDIO,
  UPDATE_STUDIO,
  CREATE_STUDIO,
  GET_STUDIO_STUDIO_SESSIONS,
  GET_STUDIO_PROJECTS,
} from "constants/studios";

import * as Api from "api";

export const getStudios = () => async (dispatch) =>
  networkAction(dispatch, GET_STUDIOS, Api.getStudios, []);

export const getStudioById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_STUDIO_BY_ID, Api.getStudioById, [id]);

export const getStudioStudioSessions = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_STUDIO_SESSIONS,
    Api.getStudioStudioSessions,
    [id]
  );

export const getStudioProjects = (id) => async (dispatch) =>
  networkAction(dispatch, GET_STUDIO_PROJECTS, Api.getStudioProjects, [id]);

export const deleteStudio = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_STUDIO, Api.deleteStudio, [id]);

export const createStudio = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_STUDIO, Api.createStudio, [body]);

export const updateStudio = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_STUDIO, Api.updateStudio, [id, body]);
