import { networkAction } from "helpers/network/networkAction";

import {
  GET_SECTION_INSTRUMENTS,
  GET_SECTION_INSTRUMENT_BY_ID,
  DELETE_SECTION_INSTRUMENT,
  UPDATE_SECTION_INSTRUMENT,
  CREATE_SECTION_INSTRUMENT,
} from "constants/sectionInstruments";

import * as Api from "api";

export const getSectionInstruments = () => async (dispatch) =>
  networkAction(
    dispatch,
    GET_SECTION_INSTRUMENTS,
    Api.getSectionInstruments,
    []
  );

export const getSectionInstrumentById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_SECTION_INSTRUMENT_BY_ID,
    Api.getSectionInstrumentById,
    [id]
  );

export const deleteSectionInstrument = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_SECTION_INSTRUMENT,
    Api.deleteSectionInstrument,
    [id]
  );

export const createSectionInstrument = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_SECTION_INSTRUMENT,
    Api.createSectionInstrument,
    [body]
  );

export const updateSectionInstrument = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_SECTION_INSTRUMENT,
    Api.updateSectionInstrument,
    [id, body]
  );
