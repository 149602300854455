import { Checkbox, Tooltip } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui
import Typography from "@material-ui/core/Typography";
import MoreVert from "@material-ui/icons/MoreVert";
import { withStyles } from "@material-ui/styles";
import FormMusician from "components/FormMusician";
import { META } from "config";
// constants
import { MUSICIAN_DETAIL } from "constants/routes";
import SyncInput from "hooks/SyncInput";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class StudioSessionMusician extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    api: PropTypes.array,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    instruments: PropTypes.array,
    studioSessionMusician: PropTypes.object,
    onEdit: PropTypes.func,
    refresh: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { studioSessionMusician } = this.props;
    this.state = {
      loading: false,
      formMusicianOpen: false,
      musicianLoading: false,
      anchorEl2: null,
      anchorEl: null,
      ...studioSessionMusician,
    };

    this.state.scaleWages = this.state.scaleWages / 100;
    this.state.cartage = this.state.cartage / 100;
    this.state.pension = this.state.pension / 100;
    this.state.welfare = this.state.welfare / 100;
    this.state.rehearsalScale = this.state.rehearsalScale / 100;
    this.state.performanceScale = this.state.performanceScale / 100;
    this.state.doublingPay = this.state.doublingPay / 100;
    this.state.premiumPercent = this.state.premiumPercent / 100;
    this.state.premiumScale = this.state.premiumScale / 100;
    this.state.nonPensionable = this.state.nonPensionable / 100;
    this.state.otherPensionable = this.state.otherPensionable / 100;
    this.state.workDues = this.state.workDues / 100;
  }

  handleChange(name, value) {
    //eslint-disable-line
    this.setState({
      [name]: value,
    });
  }

  handleInstrumentClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleInstrumentClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl2: null });
  };

  async save(name, value, numeric, multiply) {
    const { api, studioSessionMusician } = this.props;

    this.setState({
      loading: true,
    });

    const r = await api.updateStudioSessionMusician(studioSessionMusician.id, {
      [name]: numeric
        ? Math.round(Number(multiply ? Number(value * 100) : value))
        : value,
    });

    this.setState({
      loading: false,
    });

    if (r.success) return value;

    return null;
  }

  async viewMusicianCard(id) {
    const { api } = this.props;
    this.setState({
      formMusicianOpen: true,
    });

    const r = await api.getMusicianById(id);
    this.setState({
      musician: r.payload,
      musicianLoading: false,
    });
  }

  render() {
    const {
      classes,
      api,
      studioSessionMusician,
      refresh,
      variant,
      disabled,
      instruments,
    } = this.props;

    const {
      anchorEl2,
      anchorEl,
      loading,
      tunes,
      scaleWages,
      cartage,
      doubles,
      pension,
      welfare,
      overscale,
      benefitsOnly,
      hoursGuaranteed,
      hoursWorked,
      rehearsalCount,
      excludedFromPayroll,
      rehearsalScale,
      performanceCount,
      performanceScale,
      doublingPay,
      premiumPercent,
      premiumScale,
      workDues,
      nonPensionable,
      otherPensionable,
      formMusicianOpen,
      musicianLoading,
    } = this.state;

    const emptyPosition =
      studioSessionMusician &&
      studioSessionMusician.musician &&
      studioSessionMusician.musician.id === null;
    return (
      <div
        className={classes.paper}
        style={{
          opacity: excludedFromPayroll ? 0.5 : 1,
          borderLeft: loading ? "solid 3px #607d8b" : "solid 3px #ffa000",
        }}
      >
        {/* MUSICIAN */}
        {META[variant].musician ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].musician }}
          >
            <div>
              <IconButton
                size="small"
                className={classes.margin}
                disabled={disabled || emptyPosition}
                onClick={this.handleMenuClick.bind(this)}
              >
                <MoreVert fontSize="inherit" />
              </IconButton>
              <Menu
                id="simple-menu"
                size="small"
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={this.handleMenuClose}
                disabled={disabled}
              >
                {!studioSessionMusician.musician.userUUID ? (
                  <MenuItem
                    dense
                    disabled={disabled}
                    onClick={() => {
                      this.handleMenuClose();
                      this.setState({
                        formMusicianOpen: true,
                        musicianLoading: true,
                      });
                      this.viewMusicianCard(studioSessionMusician.musician.id);
                    }}
                  >
                    <ListItemText
                      primary={
                        <span>
                          <i class="fad fa-edit p-right"></i>Quick Musician Edit
                        </span>
                      }
                    />
                  </MenuItem>
                ) : (
                  []
                )}
                <MenuItem
                  dense
                  disabled={disabled}
                  onClick={async () => {
                    this.handleMenuClose();
                    await api.deleteStudioSessionMusician(
                      studioSessionMusician.id
                    );
                    refresh();
                  }}
                >
                  <ListItemText
                    primary={
                      <span>
                        <i class="fad fa-user-times p-right"></i>Remove Musician
                      </span>
                    }
                  />
                </MenuItem>
                <MenuItem
                  dense
                  onClick={async () => {
                    this.handleMenuClose();
                    window.open(
                      `${MUSICIAN_DETAIL}?id=${studioSessionMusician.musician.id}`
                    );
                  }}
                >
                  <ListItemText
                    primary={
                      <span>
                        <i class="fad fa-user-music p-right"></i> Edit musician
                      </span>
                    }
                  />
                </MenuItem>
                {!excludedFromPayroll ? (
                  <MenuItem
                    dense
                    onClick={async () => {
                      this.handleMenuClose();
                      this.setState({ excludedFromPayroll: true }, () =>
                        this.save("excludedFromPayroll", true).then(refresh)
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        <span>
                          <i class="fad fa-ban p-right"></i> Exclude from
                          Payroll
                        </span>
                      }
                    />
                  </MenuItem>
                ) : (
                  <MenuItem
                    dense
                    onClick={async () => {
                      this.handleMenuClose();
                      this.setState({ excludedFromPayroll: false }, () =>
                        this.save("excludedFromPayroll", false).then(refresh)
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        <span>
                          <i class="fad fa-user-plus p-right"></i> Include in
                          Payroll
                        </span>
                      }
                    />
                  </MenuItem>
                )}
              </Menu>
            </div>

            <Typography variant="body2" color="textPrimary">
              {!emptyPosition ? (
                `${studioSessionMusician.musician.firstName} ${studioSessionMusician.musician.lastName}`
              ) : (
                <Chip
                  variant="outlined"
                  style={{ opacity: 0.8 }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <img
                          alt="chair"
                          src="https://storage.googleapis.com/rhapsody/logos/chair-01.png"
                          style={{ height: 20, marginTop: 2, paddingRight: 2 }}
                        />
                      </div>
                      <div item style={{ flexGrow: 1 }}>
                        <Typography variant="caption" color="textPrimary">
                          Empty Chair
                        </Typography>
                      </div>
                    </div>
                  }
                />
              )}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* INSTRUMENTS */}
        {META[variant].instrument ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].instrument }}
          >
            {studioSessionMusician.instrumentID && !emptyPosition ? (
              <div
                style={{
                  width: 0,
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Tooltip title={studioSessionMusician.instrument?.name}>
                  <Chip
                    size="small"
                    disabled={disabled}
                    label={studioSessionMusician.instrument?.name}
                    style={{ maxWidth: "100%" }}
                    onDelete={
                      disabled
                        ? undefined
                        : () => {
                            this.state.instrumentID = 0; // eslint-disable-line
                            this.save("instrumentID", 0).then(refresh);
                          }
                    }
                  />
                </Tooltip>
              </div>
            ) : (
              <div>
                {!disabled && !emptyPosition ? (
                  <div
                    style={{
                      width: 0,
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <Chip
                      size="small"
                      variant="outlined"
                      label="+ Add"
                      onClick={this.handleInstrumentClick}
                    />
                  </div>
                ) : (
                  []
                )}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleInstrumentClose}
                >
                  {studioSessionMusician.musician &&
                    studioSessionMusician.musician.instruments &&
                    studioSessionMusician.musician.instruments.map((i) => (
                      <MenuItem
                        disabled={disabled}
                        key={`${studioSessionMusician.id}_${i.instrument}`}
                        onClick={() => {
                          this.setState({ instrumentID: i.id });
                          this.save("instrumentID", i.id).then(refresh);
                          this.handleInstrumentClose();
                        }}
                      >
                        {i.name}
                      </MenuItem>
                    ))}
                </Menu>
              </div>
            )}
          </div>
        ) : (
          []
        )}

        {/* ADDRESS */}
        {META[variant].address ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].address }}
          >
            <Typography variant="body2" color="textPrimary">
              {`${studioSessionMusician.musician.address || ""} ${
                studioSessionMusician.musician.zipcode || ""
              } ${studioSessionMusician.musician.city || ""}`}
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* SSN */}
        {META[variant].ssn ? (
          <div
            className={`${classes.rowItem} ${classes.align}`}
            style={{ flex: META[variant].ssn }}
          >
            <div>
              <Typography variant="body2" color="textPrimary">
                {studioSessionMusician.musician.ssn}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {studioSessionMusician.musician.ein}
              </Typography>
            </div>
          </div>
        ) : (
          []
        )}

        {/* REHEARSAL COUNT */}
        {META[variant].rehearsalCount ? (
          <div
            className={classes.rowItem}
            style={{
              flex: META[variant].rehearsalCount,
              textAlign: "left",
            }}
          >
            <div>
              <SyncInput
                refresh={refresh}
                disabled={loading || disabled}
                value={rehearsalCount || ""}
                name={"rehearsalCount"}
                handleChange={this.handleChange.bind(this)}
                save={this.save.bind(this)}
                numeric
              />
              <br />
              <Typography variant="caption" color="textPrimary">
                Scale:
                <b>{` ${rehearsalScale}`}</b>
              </Typography>
            </div>
          </div>
        ) : (
          []
        )}

        {/* PERFORMANCE COUNT */}
        {META[variant].performanceCount ? (
          <div
            className={classes.rowItem}
            style={{
              flex: META[variant].performanceCount,
              textAlign: "left",
            }}
          >
            <div>
              <SyncInput
                refresh={refresh}
                disabled={loading || disabled}
                value={performanceCount || ""}
                name={"performanceCount"}
                handleChange={this.handleChange.bind(this)}
                save={this.save.bind(this)}
                numeric
              />
              <br />
              <Typography variant="caption" color="textPrimary">
                Scale:
                <b>{` ${performanceScale}`}</b>
              </Typography>
            </div>
          </div>
        ) : (
          []
        )}

        {/* HOURS WORKED */}
        {META[variant].hoursWorked ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].hoursWorked }}
          >
            <SyncInput
              refresh={refresh}
              disabled={loading || disabled}
              value={hoursWorked || ""}
              name={"hoursWorked"}
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric={false}
              style={{ width: 40 }}
            />
            <SyncInput
              refresh={refresh}
              disabled={loading || disabled}
              value={hoursGuaranteed || ""}
              name={"hoursGuaranteed"}
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric={false}
              style={{ width: 40 }}
            />
          </div>
        ) : (
          []
        )}

        {/* TUNES */}
        {META[variant].tunes ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].tunes }}
          >
            <SyncInput
              refresh={refresh}
              disabled={loading || disabled}
              value={tunes || ""}
              name={"tunes"}
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric={false}
            />
          </div>
        ) : (
          []
        )}

        {/* OVERSCALE */}
        {META[variant].overscale ? (
          <div
            className={classes.rowItem}
            style={{
              flex: META[variant].overscale,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Checkbox
              onKeyPress={(e) => {
                if (e.key === "Enter")
                  this.save("overscale", this.state.overscale);
              }}
              size="small"
              disabled={loading || disabled}
              checked={overscale}
              color="secondary"
              onChange={(e) => {
                this.setState({ overscale: e.target.checked });
                this.save("overscale", e.target.checked);
              }}
            />
          </div>
        ) : (
          []
        )}

        {/* BENEFITS ONLY */}
        {META[variant].benefitsOnly ? (
          <div
            className={classes.rowItem}
            style={{
              flex: META[variant].benefitsOnly,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Checkbox
              onKeyPress={(e) => {
                if (e.key === "Enter")
                  this.save("benefitsOnly", this.state.benefitsOnly);
              }}
              size="small"
              disabled={loading || disabled}
              checked={benefitsOnly}
              color="secondary"
              onChange={(e) => {
                this.setState({ benefitsOnly: e.target.checked });
                this.save("benefitsOnly", e.target.checked);
              }}
            />
          </div>
        ) : (
          []
        )}

        {/* CARTAGE */}
        {META[variant].cartage ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].cartage }}
          >
            <SyncInput
              refresh={refresh}
              value={cartage || ""}
              multiply
              name={"cartage"}
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric
            />
          </div>
        ) : (
          []
        )}

        {/* DOUBLES */}
        {META[variant].doubles ? (
          <div
            className={classes.rowItem}
            style={{
              flex: META[variant].doubles,
              textAlign: "left",
            }}
          >
            <div>
              <SyncInput
                refresh={refresh}
                disabled={loading || disabled}
                value={doubles || ""}
                name={"doubles"}
                handleChange={this.handleChange.bind(this)}
                save={this.save.bind(this)}
                numeric
              />
              {variant === "LV" ? (
                <Typography variant="caption" color="textPrimary">
                  Pay:
                  <b>{` ${doublingPay}`}</b>
                </Typography>
              ) : (
                []
              )}
            </div>
          </div>
        ) : (
          []
        )}

        {/* PREMIUM PERCENT */}
        {META[variant].premiumPercent ? (
          <div
            className={classes.rowItem}
            style={{
              flex: META[variant].premiumPercent,
              textAlign: "left",
            }}
          >
            <div>
              <SyncInput
                refresh={refresh}
                disabled={loading || disabled}
                value={premiumPercent || ""}
                multiply
                name={"premiumPercent"}
                handleChange={this.handleChange.bind(this)}
                save={this.save.bind(this)}
                numeric
              />
              <br />
              <Typography variant="caption" color="textPrimary">
                Scale:
                <b>{` ${premiumScale}`}</b>
              </Typography>
            </div>
          </div>
        ) : (
          []
        )}

        {/* SCALE WAGES */}
        {META[variant].scaleWages ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].scaleWages }}
          >
            <SyncInput
              refresh={refresh}
              disabled={loading || disabled || variant === "LV"}
              value={scaleWages || ""}
              name={"scaleWages"}
              multiply
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric
            />
          </div>
        ) : (
          []
        )}

        {/* WORK DUES */}
        {META[variant].workDues ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].workDues }}
          >
            <SyncInput
              refresh={refresh}
              disabled={loading || disabled || variant === "LV"}
              value={workDues || ""}
              name={"workDues"}
              multiply
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric
            />
          </div>
        ) : (
          []
        )}

        {/* PENSION */}
        {META[variant].pension ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].pension }}
          >
            <SyncInput
              refresh={refresh}
              disabled={loading || disabled || variant === "LV"}
              value={pension || ""}
              name={"pension"}
              multiply
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric
            />
          </div>
        ) : (
          []
        )}

        {/* WELFARE */}
        {META[variant].welfare ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].welfare }}
          >
            <SyncInput
              refresh={refresh}
              disabled={loading || disabled || variant === "LV"}
              value={welfare || ""}
              name={"welfare"}
              multiply
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric
            />
          </div>
        ) : (
          []
        )}

        {/* NON PENSIONNABLE */}
        {META[variant].nonPensionable ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].nonPensionable }}
          >
            <SyncInput
              refresh={refresh}
              disabled={loading || disabled}
              value={nonPensionable || ""}
              name={"nonPensionable"}
              multiply
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric
            />
          </div>
        ) : (
          []
        )}

        {/* OTHER PENSIONNABLE */}
        {META[variant].otherPensionable ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].otherPensionable }}
          >
            <SyncInput
              refresh={refresh}
              disabled={loading || disabled}
              value={otherPensionable || ""}
              name={"otherPensionable"}
              multiply
              handleChange={this.handleChange.bind(this)}
              save={this.save.bind(this)}
              numeric
            />
          </div>
        ) : (
          []
        )}
        <FormMusician
          open={formMusicianOpen}
          musician={this.state.musician}
          close={() => {
            this.setState({
              formMusicianOpen: false,
            });
          }}
          refresh={refresh}
          refreshMusician={this.viewMusicianCard.bind(this)}
          instruments={instruments}
          musicianLoading={musicianLoading}
        />
      </div>
    );
  }
}

export default withStyles(styles)(StudioSessionMusician);
