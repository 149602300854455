import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormPayroll from "components/FormPayroll";
// reactor
import Page from "components/Page";
// custom
import PayrollsList from "components/PayrollsList";
import { removeURLParameter, updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperPayrollPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    payrolls: PropTypes.array,
    createPayroll: PropTypes.func,
    deletePayroll: PropTypes.func,
    updatePayroll: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedPayroll: undefined,
      formPayrollOpen: urlParams.newPayroll,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.urlParams.newPayroll) {
      this.setState({ formPayrollOpen: true });
    }
  }

  getStepContent() {
    const { index } = this.state;
    const { payrolls, history } = this.props;

    switch (index) {
      case 0:
        return (
          <PayrollsList
            history={history}
            payrolls={payrolls}
            onSelect={(selectedPayroll) =>
              this.setState({
                selectedPayroll,
                formPayrollOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createPayroll,
      deletePayroll,
      updatePayroll,
      refresh,
    } = this.props;

    const { index, selectedPayroll, formPayrollOpen } = this.state;

    return (
      <Page
        helmet="Payrolls"
        loading={loading}
        loadingMessage={"Fetching all Payrolls"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormPayroll
          open={formPayrollOpen}
          payroll={selectedPayroll}
          close={() => {
            this.setState({
              formPayrollOpen: false,
            });
            history.push({ search: removeURLParameter("newPayroll") });
            setTimeout(
              () =>
                this.setState({
                  selectedPayroll: undefined,
                }),
              200
            );
          }}
          createPayroll={createPayroll}
          deletePayroll={deletePayroll}
          updatePayroll={updatePayroll}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperPayrollPage);
