import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const links = `/${service}/links`;

export function getLinks(filters) {
  const url = `${links}${filters ? `?filters=${JSON.stringify(filters)}` : ""}`;
  return {
    method: GET,
    url,
  };
}

export function getLinkById(id) {
  const url = `${links}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateLink(id, body) {
  const url = `${links}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteLink(id) {
  const url = `${links}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createLink(body) {
  const url = `${links}`;
  return {
    method: POST,
    url,
    body,
  };
}
