import { Icon } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class TableAvatar extends PureComponent {
  static propTypes = {
    datum: PropTypes.object,
    theme: PropTypes.object,
  };

  render() {
    const { datum } = this.props;
    let background = "#ffc107";
    if (datum.archived) background = "#9e9e9e";
    if (datum.concierge) background = "#03a9f4";

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          style={{
            background,
            color: "white",
          }}
        >
          {datum.concierge ? (
            <Icon className="fad fa-concierge-bell" style={{ fontSize: 16 }} />
          ) : (
            datum.sessionTypeCode || "ND"
          )}
        </Avatar>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TableAvatar);
