import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class WrapperDefaultPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
  };

  render() {
    const { classes } = this.props;

    return (
      <Page helmet="Invite not found">
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.container}
        >
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="h1">
              <span role="img" aria-label="duno">
                💁🏻‍♂️
              </span>
            </Typography>
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              Oooooops !
            </Typography>
            <Typography variant="h6" color="textPrimary">
              Resource not found.
            </Typography>
            <br />
            <Typography variant="body2">
              The intern must have lost it again !!!
            </Typography>
            <Typography variant="body2">
              Contact us at <b>support@rhapsody.la</b> if you think it is a bug.
            </Typography>
          </Grid>
        </Grid>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperDefaultPage);
