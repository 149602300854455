import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperInstrumentConfiguratorPage from "../component/WrapperInstrumentConfiguratorPage";

const mapStateToProps = (state) => ({
  instruments: state.instruments,
  instrumentSections: state.instrumentSections,
  instrumentFamilies: state.instrumentFamilies,
});

const mapDispatchToProps = (dispatch) => ({
  getInstrumentFamilies: (...args) =>
    dispatch(Actions.getInstrumentFamilies(...args)),
  getInstrumentSections: (...args) =>
    dispatch(Actions.getInstrumentSections(...args)),
  getInstruments: (...args) => dispatch(Actions.getInstruments(...args)),
  createSectionInstrument: (...args) =>
    dispatch(Actions.createSectionInstrument(...args)),
  deleteSectionInstrument: (...args) =>
    dispatch(Actions.deleteSectionInstrument(...args)),
  updateInstrument: (...args) => dispatch(Actions.updateInstrument(...args)),
  createInstrumentSection: (...args) =>
    dispatch(Actions.createInstrumentSection(...args)),
});
class InstrumentConfiguratorPage extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    getInstrumentFamilies: PropTypes.func,
    getInstrumentSections: PropTypes.func,
    getInstruments: PropTypes.func,
    createInstrumentSection: PropTypes.func,
    createSectionInstrument: PropTypes.func,
    deleteSectionInstrument: PropTypes.func,
    updateInstrument: PropTypes.func,

    instruments: PropTypes.array,
    instrumentSections: PropTypes.array,
    instrumentFamilies: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const {
      getInstrumentFamilies,
      getInstrumentSections,
      getInstruments,
    } = this.props;

    await Promise.all([
      getInstrumentFamilies(),
      getInstrumentSections(),
      getInstruments(),
    ]);

    this.setState({
      loading: false,
      refreshKey: new Date().getTime(),
    });
  }

  render() {
    const {
      location,
      history,
      instruments,
      instrumentSections,
      instrumentFamilies,
      createSectionInstrument,
      deleteSectionInstrument,
      createInstrumentSection,
      updateInstrument,
    } = this.props;

    const { urlParams, loading, refreshKey } = this.state;

    return (
      <WrapperInstrumentConfiguratorPage
        history={history}
        location={location}
        urlParams={urlParams}
        loading={loading}
        instruments={instruments}
        instrumentSections={instrumentSections}
        createInstrumentSection={createInstrumentSection}
        instrumentFamilies={instrumentFamilies}
        createSectionInstrument={createSectionInstrument}
        deleteSectionInstrument={deleteSectionInstrument}
        updateInstrument={updateInstrument}
        refreshKey={refreshKey}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstrumentConfiguratorPage);
