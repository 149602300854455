import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import img0 from "assets/img/0.png";
import img1 from "assets/img/1.png";
import img2 from "assets/img/2.png";
import img3 from "assets/img/3.png";
import img4 from "assets/img/4.png";
import img5 from "assets/img/5.png";
import img6 from "assets/img/6.png";
import Logo from "assets/img/logo.png";
import { AutoRotatingCarousel } from "material-auto-rotating-carousel";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperPricingsPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      value: 10,
    };
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          background: "linear-gradient(315deg, #616161 0%, #000000 74%)",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={Logo}
          alt="logo"
          style={{ width: 300, position: "absolute", top: 20, zIndex: 9999 }}
        />
        <AutoRotatingCarousel
          open
          mobile
          autoplay={false}
          style={{ position: "absolute" }}
        >
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <div style={{ paddingBottom: 40 }}>
                <div
                  style={{
                    position: "relative",
                    width: `${(744 / 858) * 100}%`,
                    margin: "auto",
                  }}
                >
                  <img src={img0} alt="price" />
                  <div
                    style={{
                      height: "52%",
                      width: 1,
                      background: "#ffc107",
                      position: "absolute",
                      top: "22%",
                      left: `${28.5 + value / 2.08 - 4.16}%`,
                    }}
                  />
                </div>
                <Slider
                  valueLabelDisplay="on"
                  onChange={(e, v) => this.setState({ value: v })}
                  value={value}
                  step={5}
                  style={{
                    maxWidth: 236,
                    marginTop: -50,
                    marginLeft: 20,
                  }}
                  classes={{
                    root: classes.root,
                    thumb: classes.thumb,
                    active: classes.active,
                    valueLabel: classes.valueLabel,
                    track: classes.track,
                    rail: classes.rail,
                  }}
                  min={10}
                  max={100}
                />
                <br />
                <span style={{ fontSize: 20 }}>$</span>{" "}
                <span style={{ fontSize: 34 }}>
                  {Math.round((-25 / 36) * value + 131.9)}
                </span>
                <br />
                per work session
              </div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Pro-rated Price
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                The more you purchase, the less it costs per credit.
                <br /> 1 credit = 1 Work Session. With{" "}
                <b style={{ color: "#ffc107" }}>UNLIMITED</b> emails!
                <br /> <br />
                Credits never expire
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img1} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>Multi Users</div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Invite people to your organization.
                <br />
                Work together simultaneously.
                <br />
                <br />
                You know <span style={{ color: "#ffc107" }}>who</span> did{" "}
                <span style={{ color: "#ffc107" }}>what</span> and{" "}
                <span style={{ color: "#ffc107" }}>when</span>.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img2} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                White Label Service
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Customize the emails with your own branding.
                <br />
                Use a custom logo on the roster.
                <br />
                <br />
                You control the{" "}
                <span
                  style={{
                    color: "#ffc107",
                    fontFamily: "tornac",
                  }}
                >
                  look
                </span>{" "}
                of your communication.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img3} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Priority Support
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Any questions or issues?
                <br />
                Our team responds within 1 hour on phone or live chat.
                <br />
                <br />
                We're here for <span style={{ color: "#ffc107" }}>you</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img4} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Database Import
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                We import your musicians, studios and customers.
                <br />
                Save hundred of hours of data entry.
                <br />
                <br />
                You're off to a{" "}
                <span style={{ color: "#ffc107" }}>good start</span>!
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img5} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>Time Machine</div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Unlimited access to past Projects and Work Sessions.
                <br />
                Keep track of everything.
                <br />
                <br />
                You won't <span style={{ color: "#ffc107" }}>overlook</span> a
                customer!
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img6} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Email Traceability
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Consult the email sent history.
                <br />
                Know the IP address and timestamp of email views.
                <br /> <br />
                You have the{" "}
                <span style={{ color: "#ffc107" }}>information</span> to
                disperse any confusion
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <div style={{ fontSize: 80, fontWeight: 800, textAlign: "left" }}>
                And Much More!
                <ul style={{ color: "#8c8c8c", fontSize: 28 }}>
                  <li>
                    <span style={{ color: "#ffc107" }}>Unlimited</span> Emails{" "}
                  </li>
                  <li>
                    Always <span style={{ color: "#ffc107" }}>Up-To-Date</span>{" "}
                    PDF Rosters
                  </li>
                  <li>
                    Musician <span style={{ color: "#ffc107" }}>Mobile</span>{" "}
                    App
                  </li>
                  <li>
                    <span style={{ color: "#ffc107" }}>Advanced</span>{" "}
                    Notifications
                  </li>
                  <li>
                    On Demand <span style={{ color: "#ffc107" }}>Custom</span>{" "}
                    Features
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </AutoRotatingCarousel>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperPricingsPage);
