// import PropTypes from 'prop-types';
// material-ui
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import Cancel from "@material-ui/icons/Cancel";
import * as Actions from "actions";
import AutoComplete from "components/AutoComplete";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function MusicianInstrumentManager({
  onAdd,
  onDelete,
  musicianInstruments,
}) {
  const [selectedInstruments, setSelectedInstruments] = React.useState([]);
  const [instrumentForVariations, setInstrumentForVariations] =
    React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles(styles);
  const instruments = useSelector((state) => state.nestedInstruments);
  const instrumentMap = instruments?.reduce((map, obj) => {
    map[obj.id] = obj;
    return map;
  }, {});
  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log("React.useEffect []");
    if (instruments.length === 0) {
      dispatch(Actions.getNestedInstruments());
    }
  }, []);

  React.useEffect(() => {
    console.log("React.useEffect musicianInstruments");
    setSelectedInstruments(musicianInstruments ?? []);
  }, [musicianInstruments]);

  function handleOnChange(i) {
    console.log("handleOnChange");
    const _newSelectedInstruments = [...selectedInstruments, i];
    setSelectedInstruments(_newSelectedInstruments);
    if (onAdd) onAdd(i, _newSelectedInstruments);
  }

  function handleOnDelete(i) {
    console.log("handleOnDelete");
    var _newSelectedInstruments = [...selectedInstruments];
    const index = _newSelectedInstruments.indexOf(i);
    if (index > -1) {
      _newSelectedInstruments.splice(index, 1);
    }
    setSelectedInstruments(_newSelectedInstruments);
    if (onDelete) onDelete(i, _newSelectedInstruments);
  }

  return (
    <div className={classes.container}>
      <AutoComplete
        anchor="top"
        placeHolder={"Add Instrument"}
        onSelect={(i) => handleOnChange(i.value)}
        suggestions={
          instruments &&
          instruments
            .filter(
              (i) =>
                selectedInstruments.findIndex((si) => si.id === i.id) === -1
            )
            .map((i) => ({
              value: i,
              label: `${i.name}${
                i.variations ? ` (${i.variations.length} variations)` : []
              }`,
            }))
        }
      />
      {selectedInstruments.length ? (
        <Grid container className={classes.box}>
          {selectedInstruments
            .filter((si) => !si.variation)
            .map((si, i) => [
              <Grid item xs={4} className={classes.item}>
                <IconButton onClick={() => handleOnDelete(si)} size="small">
                  <Cancel fontSize="inherit" className={classes.cancel} />
                </IconButton>
                <Typography variant="body2">{si.name}</Typography>
              </Grid>,
              instrumentMap[si.id]?.variations?.length && (
                <Grid item xs={8} className={classes.item}>
                  <Grid container spacing={1}>
                    {selectedInstruments
                      .filter((e) =>
                        e.relationID
                          ? e.relationID === si.id
                          : e.instrumentID === si.id
                      )
                      ?.map((e) => (
                        <Grid item>
                          <Chip
                            onDelete={() => handleOnDelete(e)}
                            label={e.name}
                            size="small"
                            onClick={(e) => {}}
                          />
                        </Grid>
                      ))}
                    {instrumentMap[si.id]?.variations?.filter(
                      (v) =>
                        selectedInstruments.findIndex((i) => i.id === v.id) ===
                        -1
                    )?.length ? (
                      <Grid item>
                        <Chip
                          label="Variation"
                          size="small"
                          className={classes.variation}
                          icon={<AddCircleOutline />}
                          variant="outlined"
                          onClick={(e) => {
                            setInstrumentForVariations(instrumentMap[si.id]);
                            setAnchorEl(e.currentTarget);
                          }}
                        />
                      </Grid>
                    ) : (
                      []
                    )}
                  </Grid>
                </Grid>
              ),
              Number(i) !== selectedInstruments.length - 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              ),
              ,
            ])}
        </Grid>
      ) : (
        []
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {instrumentForVariations?.variations
          ?.filter(
            (iv) => selectedInstruments.findIndex((i) => i.id === iv.id) === -1
          )
          ?.map((iv) => (
            <MenuItem
              key={iv.id}
              onClick={() => {
                setAnchorEl(null);
                handleOnChange({ ...iv, variation: true });
              }}
              key={iv.id}
            >
              {iv.name}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
