import * as Api from "api";
import {
  CHECK_ACCESS,
  CREATE_USER,
  CREATE_USER_GROUP,
  DELETE_USER,
  DELETE_USER_GROUP,
  GET_CURRENT_USER,
  GET_USERS,
  GET_USER_APPS,
  GET_USER_BY_ID,
  GET_USER_GROUPS,
  IMPERSONATE,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  SEARCH_USERS,
  SIGNUP,
  STOP_IMPERSONATE,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  VALIDATE_EMAIL,
} from "constants/users";
import { networkAction } from "helpers/network/networkAction";

export const checkAccess = (body) => async (dispatch) =>
  networkAction(dispatch, CHECK_ACCESS, Api.checkAccess, [body]);

export const impersonate = (user) => async (dispatch) => {
  dispatch({
    type: IMPERSONATE,
    user,
  });
  await networkAction(dispatch, IMPERSONATE, Api.impersonate, [user.uuid]);
  await networkAction(dispatch, GET_CURRENT_USER, Api.getCurrentUser, []);
};

export const searchUsers = (body) => async (dispatch) =>
  networkAction(dispatch, SEARCH_USERS, Api.searchUsers, [body]);

export const stopImpersonate = (user) => async (dispatch) => {
  dispatch({
    type: STOP_IMPERSONATE,
    user,
  });
  await networkAction(dispatch, STOP_IMPERSONATE, Api.stopImpersonate, []);
  await networkAction(dispatch, GET_CURRENT_USER, Api.getCurrentUser, []);
};

export const signup = (body) => async (dispatch) =>
  networkAction(dispatch, SIGNUP, Api.signup, [body]);

export const validateEmail = (token) => async (dispatch) =>
  networkAction(dispatch, VALIDATE_EMAIL, Api.validateEmail, [token]);

export const requestResetPassword = (body) => async (dispatch) =>
  networkAction(dispatch, REQUEST_RESET_PASSWORD, Api.requestResetPassword, [
    body,
  ]);

export const resetPassword = (body, token) => async (dispatch) =>
  networkAction(dispatch, RESET_PASSWORD, Api.resetPassword, [body, token]);

export const getCurrentUser = (body) => async (dispatch) =>
  networkAction(dispatch, GET_CURRENT_USER, Api.getCurrentUser, [body]);

export const getUserApps = () => async (dispatch) =>
  networkAction(dispatch, GET_USER_APPS, Api.getUserApps, []);

export const getUsers = () => async (dispatch) =>
  networkAction(dispatch, GET_USERS, Api.getUsers, []);

export const getUserById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_USER_BY_ID, Api.getUserById, [id]);

export const deleteUser = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_USER, Api.deleteUser, [id]);

export const createUser = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_USER, Api.createUser, [body]);

export const updateUser = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_USER, Api.updateUser, [id, body]);

export const updateUserPassword = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_USER_PASSWORD, Api.updateUserPassword, [
    id,
    body,
  ]);

export const getUserGroups = (id) => async (dispatch) =>
  networkAction(dispatch, GET_USER_GROUPS, Api.getUserGroups, [id]);

export const createUserGroup = (id, body) => async (dispatch) =>
  networkAction(dispatch, CREATE_USER_GROUP, Api.createUserGroup, [id, body]);

export const deleteUserGroup = (id, idGroup) => async (dispatch) =>
  networkAction(dispatch, DELETE_USER_GROUP, Api.deleteUserGroup, [
    id,
    idGroup,
  ]);
