// import PropTypes from 'prop-types';
// material-ui
import {
  Checkbox,
  Chip,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Search from "@material-ui/icons/Search";
import * as Actions from "actions";
import FormattedPhone from "hooks/FormattedPhone";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

var CLIENT_ID =
  "452924802154-275ee6k1ls50tb2ufkp5l0b6jrtmqa4l.apps.googleusercontent.com";
var API_KEY = "AIzaSyC-hq37rj07Qo-NdhYg3d7ixMI94FvYVNo";
var DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
];
var SCOPES = "https://www.googleapis.com/auth/contacts.readonly";

export default function GoogleContacts({ onSelect }) {
  const [searchText, setSearchText] = React.useState("");
  const [contacts, setContacts] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const primaryColor = theme.palette.primary.color[600];

  const googleContacts = useSelector((state) => state.googleContacts);

  React.useEffect(() => {
    if (open && googleContacts.length === 0) handleClientLoad();
  }, [open]); //eslint-disable-line

  function handleClientLoad() {
    console.log(window.gapi.load("client:auth2", initClient));
    setTimeout(handleAuthClick, 500);
  }

  function initClient() {
    window.gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        function () {
          // Listen for sign-in state changes.
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(updateSigninStatus);

          // Handle the initial sign-in state.
          updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn.get()
          );
          // authorizeButton.onclick = handleAuthClick;
          // signoutButton.onclick = handleSignoutClick;
        },
        function (error) {
          console.log(error);
        }
      );
  }

  function updateSigninStatus(isSignedIn) {
    console.log("updateSigninStatus", isSignedIn);
    if (isSignedIn) {
      // authorizeButton.style.display = "none";
      // signoutButton.style.display = "block";
      listConnectionNames();
    } else {
      // authorizeButton.style.display = "block";
      // signoutButton.style.display = "none";
    }
  }

  function handleAuthClick(event) {
    window.gapi.auth2.getAuthInstance().signIn();
  }

  function listConnectionNames() {
    window.gapi.client.people.people.connections
      .list({
        resourceName: "people/me",
        personFields: "names,emailAddresses,addresses,phoneNumbers",
      })
      .then(function (response) {
        var connections = response.result.connections;
        processConnections(connections);
      });
  }

  const musicianClicked = (m) => () => {
    const index = contacts.indexOf(m);
    console.log(m, index);
    const _contacts = [...contacts];
    if (index === -1) {
      _contacts.push(m);
    } else {
      _contacts.splice(index, 1);
    }
    setContacts(_contacts);
  };

  function processConnections(connections) {
    const tmpMusicians = [];
    for (const key in connections) {
      if (Object.hasOwnProperty.call(connections, key)) {
        const connection = connections[key];

        const names = connection?.names;
        let emails = connection?.emailAddresses;
        let phones = connection?.phoneNumbers;
        let addresses = connection?.addresses;
        let add = {};

        if (Array.isArray(addresses)) {
          const main = addresses[0];
          add.address = main.streetAddress;
          add.city = main.city;
          add.state = main.region;
          add.country = main.country;
          add.zipcode = main.postalCode;
        }

        if (Array.isArray(emails))
          emails = emails.reduce((a, v) => {
            if (v.value) a.push(v.value);
            return a;
          }, []);

        if (Array.isArray(phones))
          phones = phones.reduce((a, v) => {
            if (v.canonicalForm) a.push(v.canonicalForm);
            return a;
          }, []);

        tmpMusicians.push({
          firstName:
            names && names.length && names[0].givenName
              ? names[0].givenName
              : "",
          lastName:
            names && names.length && names[0].familyName
              ? names[0].familyName
              : "",
          emails,
          phones,
          email: emails?.length ? emails[0] : "",
          phone: phones?.length ? phones[0] : "",
          ...add,
        });
      }
    }
    dispatch(
      Actions.setGoogleContacts(
        tmpMusicians.sort((a, b) => {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        })
      )
    );
  }

  let list = googleContacts;
  if (searchText) {
    list = googleContacts.filter(
      (a) =>
        `${a.firstName} ${a.lastName}`
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1
    );
  }

  function submit() {
    onSelect(contacts);
    close();
  }

  function close() {
    setOpen(false);
    setContacts([]);
  }

  return (
    <>
      <Chip
        size="small"
        onClick={() => setOpen(true)}
        style={{ background: "#e0e0e0", color: "#212121" }}
        label={
          <span>
            <i class="fad fa-cloud-download p-right"></i>Google Contacts
          </span>
        }
      />
      <Dialog
        fullScreen={fullScreen}
        open={open && googleContacts.length}
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flex: 1,
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Who would you like to import?</Typography>
          </div>
        </DialogTitle>
        <DialogContent style={{ position: "relative" }}>
          <List
            style={{
              minHeight: "75vh",
              width: "100%",
              minWidth: !fullScreen && 500,
              position: "relative",
            }}
          >
            <div
              style={{
                position: "sticky",
                zIndex: 999,
                top: -8,
                background: "white",
              }}
            >
              <InputBase
                style={{
                  background: "rgba(155,155,155,0.1)",
                  borderRadius: 40,
                  padding: "0px 8px",
                  width: "100%",
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                autoFocus
                value={searchText}
                fullWidth
                startAdornment={
                  <Search style={{ color: "rgba(155,155,155,0.8)" }} />
                }
                placeholder="Search..."
              />
            </div>
            {list.map((m, i) => {
              const JSX = [];
              if (Number(i) === 0) {
                JSX.push(
                  <Typography
                    variant="caption"
                    style={{
                      background: "#e0e0e0",
                      display: "block",
                      position: "sticky",
                      top: 26,
                      zIndex: 99,
                      padding: "0px 10px",
                      width: "calc(100% - 20px)",
                    }}
                  >
                    {m.lastName[0]?.toUpperCase()}
                  </Typography>
                );
              } else if (Number(i) < googleContacts.length - 1) {
                const prev = googleContacts[Number(i - 1)];
                if (
                  prev.lastName[0]?.toUpperCase() !==
                  m.lastName[0]?.toUpperCase()
                ) {
                  JSX.push(
                    <Typography
                      variant="caption"
                      style={{
                        background: "#e0e0e0",
                        display: "block",
                        position: "sticky",
                        top: 26,
                        zIndex: 999,
                        padding: "0px 10px",
                        width: "calc(100% - 20px)",
                      }}
                    >
                      {m.lastName[0]?.toUpperCase()}
                    </Typography>
                  );
                }
              }
              JSX.push(
                <ListItem dense button divider onClick={musicianClicked(m)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={contacts.indexOf(m) !== -1}
                      tabIndex={-1}
                      size="small"
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${m.firstName ?? ""} ${m.lastName ?? ""}`}
                    secondary={
                      <Typography variant="caption" color="textSecondary">
                        {m.emails?.length ? (
                          <>
                            <i class="fas fa-envelope p-right"></i>
                            {m.emails?.join(", ")}
                            <br />
                          </>
                        ) : (
                          []
                        )}
                        {m.phones?.length ? (
                          <>
                            <i class="fad fa-mobile-android p-right"></i>
                            {m.phones
                              .map((t) => <FormattedPhone value={t} />)
                              .reduce((prev, curr) => [prev, ", ", curr])}
                          </>
                        ) : (
                          []
                        )}
                      </Typography>
                    }
                  />
                </ListItem>
              );

              return JSX;
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} variant="contained" autoFocus fullWidth>
            Cancel
          </Button>
          {contacts.length ? (
            <Button
              variant="contained"
              autoFocus
              onClick={submit}
              fullWidth
              style={{ background: primaryColor, color: "white" }}
            >
              Import {contacts.length} musician{contacts.length > 1 ? "s" : ""}
            </Button>
          ) : (
            []
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
