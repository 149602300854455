import { Chip, CircularProgress, Menu, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Favorite } from "@material-ui/icons";
// material-ui
import { withStyles } from "@material-ui/styles";
import * as Actions from "actions";
import AdvancedSearch from "components/AdvancedSearchRefactor";
import TableArrow from "components/TableArrow";
// custom
import Table from "components/TablePagination";
// constants
import { MUSICIAN_DETAIL } from "constants/routes";
import { getJsonFromUrl } from "helpers";
// hooks
import MusicianAvatar from "hooks/MusicianAvatar";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import MemberStatus from "../MemberStatus/MemberStatus";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getGroupById: (...args) => dispatch(Actions.getGroupById(...args)),
  createMusicianGroup: (...args) =>
    dispatch(Actions.createMusicianGroup(...args)),
  deleteMusicianGroup: (...args) =>
    dispatch(Actions.deleteMusicianGroup(...args)),
});

class MusiciansList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    musicians: PropTypes.array,
    noAdvancedSearch: PropTypes.bool,
    onSelect: PropTypes.func,
    getMusicians: PropTypes.func,
    instruments: PropTypes.array,
    history: PropTypes.object,
    noEmptyMessage: PropTypes.bool,
  };

  static contextTypes = {
    TourCenter: PropTypes.array,
  };

  state = {
    bulkSelect: false,
    selection: [],
    anchorEl: null,
  };

  onSelect(selectedMusician) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedMusician);
    }
  }

  addToGroup = (g) => async () => {
    const { selection } = this.state;
    const { createMusicianGroup } = this.props;
    const proms = [];
    this.setState({ loading: true, anchorEl: null });
    for (const key in selection) {
      if (Object.hasOwnProperty.call(selection, key)) {
        const musician = selection[key];
        proms.push(
          createMusicianGroup({
            musicianID: musician.id,
            groupID: g.id,
          })
        );
      }
    }

    await Promise.all(proms);
    this.setState({ loading: false, selection: [] });
  };

  async removeFromGroup() {
    const { deleteMusicianGroup, getGroupById, groupID } = this.props;
    const { selection } = this.state;
    const proms = [];
    this.setState({ loading: true, anchorEl: null });
    for (const key in selection) {
      if (Object.hasOwnProperty.call(selection, key)) {
        const musician = selection[key];
        proms.push(deleteMusicianGroup(musician.groupRelationID));
      }
    }

    await Promise.all(proms);
    getGroupById(groupID);
    this.setState({ loading: false, selection: [] });
  }

  render() {
    const {
      musicians,
      history,
      getMusicians,
      instruments,
      noEmptyMessage,
      noAdvancedSearch,
      groups,
    } = this.props;
    const { bulkSelect, selection, anchorEl, loading } = this.state;
    const { TourCenter } = this.context;
    const urlParams = getJsonFromUrl(window.location);
    if (musicians.length === 0 && !urlParams.filters && !noEmptyMessage) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              <i style={{ fontSize: 70 }} class="fad fa-user-music"></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No Musicians
            </Typography>
            <Typography variant="caption" color="textSecondary">
              These are the musicians you will hire for a work session.
            </Typography>
          </Grid>
          <Grid item>
            <Chip onClick={() => TourCenter.start(2)} label="+ New Musician" />
          </Grid>
        </Grid>
      );
    }

    const table = (
      <Table
        title={"Musicians"}
        onRowSelect={(session) =>
          history.push(`${MUSICIAN_DETAIL}?id=${session.id}`)
        }
        data={musicians}
        groupSelectEnabled={bulkSelect}
        onGroupSelect={(e) => {
          console.log(e);
          this.setState({ selection: e });
        }}
        refreshKey={musicians.refreshKey}
        rowStyle={(d) => ({
          opacity: d.active ? 1 : 0.4,
          borderLeft: d.userUUID ? "solid 4px #ffc107" : undefined,
        })}
        tooltip={(d) => (d.userUUID ? "is a Rhapsody Member" : undefined)}
        meta={[
          {
            path: "id",
            title: "ID",
            width: 30,
          },
          {
            path: "star",
            title: "",
            width: 20,
            render: (t, d) => {
              if (d.star)
                return <Favorite style={{ color: "#e91e63", fontSize: 16 }} />;
            },
          },
          {
            path: "avatar",
            render: (t, d) => <MusicianAvatar musician={d} />,
            width: 30,
          },
          {
            path: "firstName",
            title: "Name",
            numeric: false,
            transform: (t, d) => `${d.firstName} ${d.lastName}`,
            render: (t, d) => (
              <Grid container alignItems="center">
                {d.userUUID ? (
                  <Grid item style={{ marginRight: 4 }}>
                    <MemberStatus verified={d.verified} small />
                  </Grid>
                ) : (
                  []
                )}
                <Grid item>
                  <Typography variant="body2">
                    {d.nickName || `${d.firstName} ${d.lastName}`}
                  </Typography>
                </Grid>
              </Grid>
            ),
          },
          {
            path: "companyName",
            title: "Company",
            numeric: false,
            hidden: { smDown: true },
          },
          {
            path: "instrumentNames",
            title: "Instruments",
            hidden: { smDown: true },
            numeric: false,
          },
          {
            path: "phone",
            title: "Phone",
            numeric: false,
            hidden: { smDown: true },
          },
          {
            path: "email",
            title: "Email",
            numeric: false,
            hidden: { smDown: true },
          },
          {
            path: "city",
            title: "City",
            numeric: false,
            hidden: { smDown: true },
          },
          {
            path: "id",
            numeric: false,
            component: TableArrow,
            width: 30,
          },
        ]}
      />
    );

    const bulkSelectBar = (
      <Grid container style={{ marginBottom: 8 }} spacing={1}>
        <Grid item>
          <Chip
            onClick={() =>
              this.setState((s) => ({
                bulkSelect: !s.bulkSelect,
              }))
            }
            color={bulkSelect ? "secondary" : "default"}
            size="small"
            label={bulkSelect ? "Done" : "Bulk Select"}
          />
        </Grid>
        {bulkSelect && selection.length ? (
          <Grid item>
            <Chip
              variant="outlined"
              size="small"
              onClick={
                noAdvancedSearch
                  ? this.removeFromGroup.bind(this)
                  : (e) => this.setState({ anchorEl: e.currentTarget })
              }
              icon={loading && <CircularProgress size={12} />}
              label={`${noAdvancedSearch ? "Remove" : "Add"} ${
                selection.length
              } ${noAdvancedSearch ? `from group` : `to group ▾`}`}
            />
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => this.setState({ anchorEl: null })}
            >
              {groups.map((g) => (
                <MenuItem onClick={this.addToGroup(g)} key={g.id}>
                  {g.name}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        ) : (
          []
        )}
      </Grid>
    );

    if (noAdvancedSearch)
      return (
        <div>
          {bulkSelectBar}
          {table}
        </div>
      );

    return (
      <div>
        {bulkSelectBar}
        <AdvancedSearch
          history={history}
          rowCount={musicians.length}
          location={window.location}
          refresh={getMusicians}
          keyword="musicians"
          metaDataEndpoint="/rhapsody/musicians/searchMetadata"
          metaDataOveride={{
            instrumentID: {
              type: "autocomplete",
              data: instruments,
              valueKey: "id",
              labelKey: "name",
            },
          }}
        >
          {table}
        </AdvancedSearch>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MusiciansList));
