import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// material-ui
import { withStyles } from "@material-ui/styles";
// constants
import { EMAIL } from "constants/regexp";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class FormBilling extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    customer: PropTypes.object,
    updateCustomer: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { customer } = this.props;
    this.state = {
      hasChanged: false,
      companyName: customer.companyName,
      taxNumber: customer.taxNumber,
      address1: customer.address1,
      address2: customer.address2,
      city: customer.city,
      country: customer.country,
      email: customer.email,
      fullName: customer.fullName,
      state: customer.state,
      autoPayment: customer.autoPayment,
      zip: customer.zip,
    };
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
      hasChanged: true,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
      hasChanged: true,
    });
  };

  isValid() {
    const { hasChanged, email } = this.state;

    return hasChanged && EMAIL.test(email);
  }

  async updateCustomer() {
    const { NotificationCenter } = this.context;
    const { updateCustomer, customer, refresh } = this.props;
    const {
      address1,
      address2,
      city,
      country,
      email,
      fullName,
      autoPayment,
      state,
      zip,
      companyName,
      taxNumber,
    } = this.state;

    const resp = await updateCustomer(customer.id, {
      address1,
      address2,
      city,
      country,
      email,
      fullName,
      autoPayment,
      state,
      companyName,
      taxNumber,
      zip,
    });

    if (resp.success) {
      refresh();
      NotificationCenter.stack(
        {
          title: "Success",
          subtitle: "Your info have been updated.",
          timestamp: new Date().getTime(),
          success: true,
        },
        {
          confirm: {
            label: "Ok",
            level: "success",
          },
        }
      );

      this.setState({
        hasChanged: false,
      });
    }
  }

  render() {
    const {
      address1,
      address2,
      city,
      country,
      email,
      fullName,
      state,
      hasChanged,
      zip,
      companyName,
      taxNumber,
    } = this.state;

    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="companyName"
              label="Company Name"
              value={companyName}
              onChange={this.handleChange("companyName")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="taxNumber"
              label="Tax Number"
              value={taxNumber}
              onChange={this.handleChange("taxNumber")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="fullName"
              label="Full Name"
              value={fullName}
              onChange={this.handleChange("fullName")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              label="Email"
              value={email}
              onChange={this.handleChange("email")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="address1"
              label="Address Line 1"
              value={address1}
              onChange={this.handleChange("address1")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="address2"
              label="Line 2"
              value={address2}
              onChange={this.handleChange("address2")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="zip"
              label="Zip"
              value={zip}
              onChange={this.handleChange("zip")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="city"
              label="City"
              value={city}
              onChange={this.handleChange("city")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="state"
              label="State"
              value={state}
              onChange={this.handleChange("state")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="country"
              label="Country"
              value={country}
              onChange={this.handleChange("country")}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={autoPayment}
                  onChange={this.handleCheckboxChange('autoPayment')}
                  value="Monthly Auto Payment"
                  color="secondary"
                />
              )}
              label="Auto Payment"
            />
            {
              !autoPayment
                ? (
                  <Typography color="secondary">
                    You need to pay manually your monthly invoices.
                  </Typography>
                ) : []
            }
          </Grid> */}
        </Grid>
        <br />
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            disabled={!hasChanged}
            onClick={this.updateCustomer.bind(this)}
          >
            Save
          </Button>
        </DialogActions>
      </div>
    );
  }
}

export default withStyles(styles)(FormBilling);
