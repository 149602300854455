import { withStyles } from "@material-ui/styles";
import chart from "assets/img/chart.png";
import { AutoRotatingCarousel } from "material-auto-rotating-carousel";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperPricingsPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      value: 10,
    };
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          background: "linear-gradient(315deg, #616161 0%, #000000 74%)",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AutoRotatingCarousel
          open
          mobile
          autoplay={false}
          style={{ position: "absolute" }}
        >
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Core <span style={{ color: "#2196f3" }}>Concepts</span>
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                <br />A Quick guide to understanding Projects and Work Sessions
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              backgroundImage:
                "url(https://storage.googleapis.com/rhapsody/media/lalaland.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div
                style={{ fontSize: 80, fontWeight: 800, paddingBottom: "20%" }}
              >
                You contract musicians to record
                <br />
                the <span style={{ color: "#ffc107" }}>La La Land </span>
                Soundtrack
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                La La Land is your{" "}
                <span style={{ color: "#9c27b0" }}>Project</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              background:
                "linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2)), url(https://storage.googleapis.com/rhapsody/media/laland-orchestra.jpg) no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800, paddingTop: "20%" }}>
                <span style={{ color: "#e91e63" }}>48 people</span> need
                <br />
                to be contracted for this project
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                These are your{" "}
                <span style={{ color: "#00bcd4" }}>Project's Musicians</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                The Project is composed of <br />
                <span style={{ color: "#2196f3" }}>Work Sessions</span>
              </div>
              <div
                style={{ color: "#8c8c8c", fontSize: 28, textAlign: "left" }}
              >
                <ul>
                  <li>A Single Roster Using your Project's Musicians</li>
                  <li>A Specific Day and Venue</li>
                </ul>
              </div>
              <div style={{ fontSize: 40, fontWeight: 800 }}>
                <i>These are Events in your Project Calendar</i>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              background:
                "linear-gradient(rgba(13,71,161,0.8), rgba(0, 0, 0, 0.2)), url(https://storage.googleapis.com/rhapsody/media/lala2.jpg) no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800, paddingTop: "10%" }}>
                You start planing the recording of the song
                <span style={{ color: "#ffc107" }}> "Another Day of Sun"</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 50, fontWeight: 800 }}>
                5 <span style={{ color: "#2196f3" }}>Work Sessions</span> are
                added to the Calendar:
              </div>
              <br />
              <img alt="chart" src={chart} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                You are ready to send the{" "}
                <span style={{ color: "#ffc107" }}>invites</span>!
              </div>
              <img
                src="https://www.blog.rhapsody.la/wp-content/uploads/2019/12/plane-02.png"
                width="800"
                alt="plane"
              />
              <br />
              <br />
              <div style={{ fontSize: 40, fontWeight: 800 }}>
                <i>Emails are unlimited!</i>
              </div>
            </div>
          </div>
        </AutoRotatingCarousel>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperPricingsPage);
