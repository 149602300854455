import { GET, PUT, DELETE, POST } from "constants/methods";
const rosterNotes = "/rhapsody/rosterNotes";

export function getRosterNotes(filters) {
  const url = `${rosterNotes}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getRosterNoteById(id) {
  const url = `${rosterNotes}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateRosterNote(id, body) {
  const url = `${rosterNotes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteRosterNote(id) {
  const url = `${rosterNotes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createRosterNote(body) {
  const url = `${rosterNotes}`;
  return {
    method: POST,
    url,
    body,
  };
}
