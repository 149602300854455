import {
  ButtonGroup,
  Grid,
  InputBase,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import * as Actions from "actions";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  groups: state.groups,
  projects: state.projects,
});
const mapDispatchToProps = (dispatch) => ({
  getGroups: (...args) => dispatch(Actions.getGroups(...args)),
  projectAutofill: (...args) => dispatch(Actions.projectAutofill(...args)),
  studioSessionAutofill: (...args) =>
    dispatch(Actions.studioSessionAutofill(...args)),
  getProjects: (...args) => dispatch(Actions.getProjects(...args)),
});

class Autofill extends Component {
  static propTypes = {
    classes: PropTypes.object,
    studioSession: PropTypes.object,
    history: PropTypes.object,
    studioSessionAutofill: PropTypes.func,
    projectAutofill: PropTypes.func,
  };

  static contextTypes = {
    SubscriptionCenter: PropTypes.object,
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { groups, getGroups, projects, getProjects } = this.props;

    const { WebsocketCenter } = this.context;
    this.state = {
      open: false,
      processing: false,
      groupID: "",
      workSessionID: "",
      templateID: "",
      projectID: "",
      projectID: "",
      index: 0,
      projectID: "",
    };

    const projectSub =
      WebsocketCenter.getInstance()?._subs[
        `rhapsody:${this.props.project?.publicToken}`
      ];

    const workSessionSub =
      WebsocketCenter.getInstance()?._subs[
        `rhapsody:${this.props.studioSession?.publicToken}`
      ];

    projectSub?.on("publication", (m) => {
      this.setState({ processing: false, open: false });
      this.props.refresh();
    });

    workSessionSub?.on("publication", (m) => {
      this.setState({ processing: false, open: false });
      this.props.refresh();
    });

    if (!groups || groups?.length === 0) {
      getGroups();
    }

    if (projects?.length === 0) {
      getProjects();
    }
  }

  async autofill() {
    const { studioSession, studioSessionAutofill, project, projectAutofill } =
      this.props;
    const { index, groupID, projectID, workSessionID, templateID } = this.state;
    this.setState({ processing: true });
    if (studioSession) {
      await studioSessionAutofill(studioSession.id);
    }
    if (project) {
      var r;
      switch (index) {
        case 0:
          r = await projectAutofill(project.id, {});
          break;
        case 1:
          r = await projectAutofill(project.id, {
            projectID: Number(templateID),
          });
          break;
        case 2:
          r = await projectAutofill(project.id, { groupID: Number(groupID) });
          break;
        case 3:
          r = await projectAutofill(project.id, {
            workSessionID: workSessionID ? Number(workSessionID) : undefined,
            projectID: projectID ? Number(proFwejectID) : undefined,
          });
          break;
        default:
          break;
      }
    }
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({
      open: false,
      processing: false,
      groupID: "",
      workSessionID: "",
      templateID: "",
      projectID: "",
    });
  }

  handleIndex = (i) => () => {
    this.setState({
      index: i,
    });
  };

  getStepIndex() {
    const { groups, projects } = this.props;
    const { index, projectID, workSessionID, groupID, templateID } = this.state;

    const templates = projects.filter((t) => t.template && !t.archived);
    switch (index) {
      case 0:
        return (
          <Typography variant="caption" color="textSecondary">
            Rhapsody will choose the best matches from your address book.
          </Typography>
        );
      case 1:
        if (projects.length > 0)
          return (
            <TextField
              size="small"
              variant="filled"
              fullWidth
              select
              onChange={(e) => {
                this.setState({
                  templateID: e.target.value,
                });
              }}
              label="Select the template"
              value={templateID}
            >
              {templates.map((t) => (
                <MenuItem value={t.id} key={t.id}>
                  {t.name}
                </MenuItem>
              ))}
            </TextField>
          );
        return (
          <Typography variant="caption" color="textSecondary">
            Looks like you don't have any groups. You can create one in the
            Musicians section.
          </Typography>
        );
      case 2:
        if (groups.length > 0)
          return (
            <TextField
              size="small"
              variant="filled"
              fullWidth
              select
              onChange={(e) => {
                this.setState({
                  groupID: e.target.value,
                });
              }}
              label="Select the group"
              value={groupID}
            >
              {groups.map((g) => (
                <MenuItem value={g.id} key={g.id}>
                  {g.name}
                </MenuItem>
              ))}
            </TextField>
          );
        return (
          <Typography variant="caption" color="textSecondary">
            Looks like you don't have any groups. You can create one in the
            Musicians section.
          </Typography>
        );
      case 2:
        return (
          <div>
            <InputBase
              style={{
                background: "rgba(155,155,155,0.1)",
                borderRadius: 40,
                padding: "0px 8px",
              }}
              onChange={(e) => {
                this.setState({
                  projectID: e.target.value,
                });
              }}
              fullWidth
              value={projectID}
              autoFocus
              type="number"
              startAdornment={
                <i
                  style={{ color: "rgba(155,155,155,0.8)" }}
                  class="fad fa-folder-open p-right"
                ></i>
              }
              placeholder={"Project ID"}
            />
            <Typography variant="caption" color="textSecondary">
              Project ID can be found in its URL.
              <br />
              Ex: https://studio.rhapsody.la/project?id=
              <u>
                <b>123</b>
              </u>
            </Typography>
          </div>
        );
      case 3:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputBase
                style={{
                  background: "rgba(155,155,155,0.1)",
                  borderRadius: 40,
                  padding: "0px 8px",
                }}
                onChange={(e) => {
                  this.setState({
                    projectID: e.target.value,
                    workSessionID: "",
                  });
                }}
                fullWidth
                type="number"
                value={projectID}
                startAdornment={
                  <i
                    style={{ color: "rgba(155,155,155,0.8)" }}
                    class={"fad fa-folder-open p-right"}
                  ></i>
                }
                placeholder={"Project ID"}
              />
            </Grid>
            <Grid item xs={12}>
              <InputBase
                style={{
                  background: "rgba(155,155,155,0.1)",
                  borderRadius: 40,
                  padding: "0px 8px",
                }}
                onChange={(e) => {
                  this.setState({
                    workSessionID: e.target.value,
                    projectID: "",
                  });
                }}
                value={workSessionID}
                fullWidth
                type="number"
                startAdornment={
                  <i
                    style={{ color: "rgba(155,155,155,0.8)" }}
                    class={"fad fa-calendar-day p-right"}
                  ></i>
                }
                placeholder={"Work Session ID"}
              />
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  }

  autofillDisabled() {
    const { groupID, projectID, workSessionID, templateID, index } = this.state;
    switch (index) {
      case 0:
        return false;
      case 1:
        return !templateID;
      case 2:
        return !groupID;
      case 3:
        return !workSessionID && !projectID;
      default:
        break;
    }
  }

  render() {
    const { studioSession, project, projects, groups } = this.props;
    const { open, understood, processing, index, templateID, groupID } =
      this.state;
    const templates = projects.filter((t) => t.template && !t.archived);
    let JSX = [];

    if (project) {
      JSX = (
        <Dialog
          open={open}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ textAlign: "center", minWidth: 350 }}>
            <DialogTitle id="alert-dialog-title">
              <i class="fad fa-chair" style={{ fontSize: 70 }}></i>
              <br />
              Autofill empty chairs
            </DialogTitle>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="body2" color="textPrimary">
                Select the source:
              </Typography>
              <br />
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  onClick={this.handleIndex(0)}
                  variant={index === 0 && "contained"}
                >
                  Magic
                </Button>
                <Button
                  onClick={this.handleIndex(1)}
                  variant={index === 1 && "contained"}
                  style={{
                    display: templates.length === 0 ? "none" : undefined,
                  }}
                >
                  Template
                </Button>
                <Button
                  onClick={this.handleIndex(2)}
                  variant={index === 2 && "contained"}
                  style={{
                    display: groups.length === 0 ? "none" : undefined,
                  }}
                >
                  Group
                </Button>
                <Button
                  variant={index === 3 && "contained"}
                  onClick={this.handleIndex(3)}
                >
                  <i class="fas fa-ellipsis-vertical"></i>
                </Button>
              </ButtonGroup>
              <div style={{ paddingTop: 16 }}>{this.getStepIndex()}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose.bind(this)}
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.autofill.bind(this)}
              color="primary"
              disabled={this.autofillDisabled()}
              autoFocus
              variant="contained"
              style={{ textTransform: "none" }}
            >
              Autofill
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    if (studioSession) {
      JSX = (
        <Dialog
          open={open}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ textAlign: "center" }}>
            <img
              alt="chair"
              src="https://storage.googleapis.com/rhapsody/logos/chair-01.png"
              style={{ width: 100, marginTop: 10 }}
            />
            <DialogTitle id="alert-dialog-title">
              Autofill Empty Chairs
            </DialogTitle>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="body2" color="textPrimary">
                This action will autofill the empty chairs <br />
                while respecting the order of the project's seating.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose.bind(this)}
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.autofill.bind(this)}
              color="primary"
              autoFocus
              variant="contained"
              style={{ textTransform: "none" }}
            >
              Autofill
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    if (processing) {
      JSX = (
        <Dialog
          open={open}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ textAlign: "center" }}>
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h3">
                <i class="fa-solid fa-arrows-rotate fa-spin"></i>
              </Typography>
              <br />
              System Processing
            </DialogTitle>
            <DialogContentText id="alert-dialog-description">
              Please wait, Rhapsody is filling up the chairs.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={this.handleClose.bind(this)}
              style={{ textTransform: "none" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <div>
        <Chip
          style={{
            marginLeft: 8,
          }}
          label={
            <span>
              <i class="fas fa-wand-magic p-right"></i>Autofill
            </span>
          }
          onClick={this.handleClickOpen.bind(this)}
          size="small"
        />
        {JSX}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Autofill));
