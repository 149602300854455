import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const musicians = `/${service}/musicians`;

export function getMusicians(filters) {
  const url = `${musicians}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getMusicianById(id) {
  const url = `${musicians}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getMusicianProjects(id) {
  const url = `${musicians}/${id}/projects`;
  return {
    method: GET,
    url,
  };
}

export function getMusicianStudioSessions(id) {
  const url = `${musicians}/${id}/workSessions`;
  return {
    method: GET,
    url,
  };
}

export function updateMusician(id, body) {
  const url = `${musicians}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function joinRequest(id) {
  const url = `${musicians}/${id || "1"}/joinRequest`;
  return {
    method: PUT,
    url,
    body: {},
  };
}

export function deleteMusician(id) {
  const url = `${musicians}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMusician(body) {
  const url = `${musicians}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function createMusicianInstrument(id, body) {
  const url = `${musicians}/${id}/instruments`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMusicianInstrument(id, body) {
  const url = `${musicians}/${id}/instruments`;
  return {
    method: DELETE,
    url,
    body,
  };
}
