export function getDefaultStyle(theme) {
  return {
    control: {
      fontSize: 14,
      width: "100%",
      fontWeight: "normal",
    },

    "&multiLine": {
      control: {
        width: "100%",
        minHeight: 63,
      },
      highlighter: {
        padding: 9,
        width: "100%",
        border: "1px solid transparent",
      },
      input: {
        padding: 9,
        color: theme.palette.type === "dark" ? "white" : "black",
        width: "100%",
        border: "1px solid rgba(155,155,155,0.3)",
        borderRadius: 4,
      },
    },

    "&singleLine": {
      display: "inline-block",
      width: 180,

      highlighter: {
        width: "100%",
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        width: "100%",
        padding: 1,
        border: "2px inset",
      },
    },

    suggestions: {
      list: {
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        color: "black",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "rgba(255,255,255,0.2)",
        },
      },
    },
  };
}
