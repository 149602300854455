import * as Api from "api";
import {
  ACKNOWLEDGE_STUDIO_SESSION_INVITE,
  CALCULATE_PENSION_WELFARE,
  CREATE_STUDIO_SESSION,
  CREATE_STUDIO_SESSION_CATEGORY,
  DELETE_STUDIO_SESSION,
  DELETE_STUDIO_SESSION_CATEGORY,
  DUPLICATE_SESSION,
  GET_ACTIVE_STUDIO_SESSIONS,
  GET_AGNOSTIC_FORM_DATA,
  GET_ARCHIVED_STUDIO_SESSIONS,
  GET_STUDIO_SESSIONS,
  GET_STUDIO_SESSION_BY_ID,
  GET_STUDIO_SESSION_ENGAGEMENTS,
  GET_STUDIO_SESSION_EVENTS,
  GET_STUDIO_SESSION_IDENTIFICATION,
  GET_STUDIO_SESSION_INVITES,
  GET_STUDIO_SESSION_LAYOUT,
  GET_STUDIO_SESSION_MERCURY,
  GET_STUDIO_SESSION_MUSICIANS,
  GET_STUDIO_SESSION_REHEARSALS,
  GET_STUDIO_SESSION_SECTIONS,
  GET_STUDIO_SESSION_STATUSES,
  GET_STUDIO_SESSION_TUNES,
  NOTIFY,
  NOTIFY_PREVIEW,
  PROCESS_BAZAAR,
  PROCESS_METADATA,
  STUDIO_SESSION_AUTOFILL,
  UPDATE_STUDIO_SESSION,
  UPDATE_WORK_SESSION_MERCURY,
} from "constants/studioSessions";
import { networkAction } from "helpers/network/networkAction";

export const getStudioSessions = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_STUDIO_SESSIONS, Api.getStudioSessions, [
    filters,
  ]);

export const getAgnosticFormData = (token) => async (dispatch) =>
  networkAction(dispatch, GET_AGNOSTIC_FORM_DATA, Api.getAgnosticFormData, [
    token,
  ]);

export const getActiveStudioSessions =
  (filters = []) =>
  async (dispatch) =>
    networkAction(dispatch, GET_ACTIVE_STUDIO_SESSIONS, Api.getStudioSessions, [
      [
        {
          name: "studio_sessions.archived",
          comparison: "eq",
          value: false,
        },
        ...filters,
      ],
    ]);

export const getArchivedStudioSessions =
  (filters = []) =>
  async (dispatch) =>
    networkAction(
      dispatch,
      GET_ARCHIVED_STUDIO_SESSIONS,
      Api.getStudioSessions,
      [
        [
          {
            name: "studio_sessions.archived",
            comparison: "eq",
            value: true,
          },
          ...filters,
        ],
      ]
    );

export const getStudioSessionStatuses = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_STATUSES,
    Api.getStudioSessionStatuses,
    [id]
  );

export const processBazaar = () => async (dispatch) =>
  networkAction(dispatch, PROCESS_BAZAAR, Api.processBazaar, []);

export const calculatePensionWelfare = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    CALCULATE_PENSION_WELFARE,
    Api.calculatePensionWelfare,
    [id, body]
  );

export const getStudioSessionInvites = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_INVITES,
    Api.getStudioSessionInvites,
    [id]
  );

export const processMetadata = (filters) => async (dispatch) =>
  networkAction(dispatch, PROCESS_METADATA, Api.processMetadata, [filters]);

export const updateWorkSessionMercury = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_WORK_SESSION_MERCURY,
    Api.updateWorkSessionMercury,
    [id, body]
  );

export const notify = (id, body) => async (dispatch) =>
  networkAction(dispatch, NOTIFY, Api.notify, [id, body]);

export const notifyPreview = (id, body) => async (dispatch) =>
  networkAction(dispatch, NOTIFY_PREVIEW, Api.notifyPreview, [id, body]);

export const acknowledgeStudioSessionInvite = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    ACKNOWLEDGE_STUDIO_SESSION_INVITE,
    Api.acknowledgeStudioSessionInvite,
    [id, body]
  );

export const getStudioSessionById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_STUDIO_SESSION_BY_ID, Api.getStudioSessionById, [
    id,
  ]);

export const getStudioSessionMercury = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_MERCURY,
    Api.getStudioSessionMercury,
    [id]
  );

export const getStudioSessionLayout = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_LAYOUT,
    Api.getStudioSessionLayout,
    [id]
  );
export const studioSessionAutofill = (id) => async (dispatch) =>
  networkAction(dispatch, STUDIO_SESSION_AUTOFILL, Api.studioSessionAutofill, [
    id,
  ]);

export const duplicateSession = (id) => async (dispatch) =>
  networkAction(dispatch, DUPLICATE_SESSION, Api.duplicateSession, [id]);

export const getStudioSessionTunes = (id) => async (dispatch) =>
  networkAction(dispatch, GET_STUDIO_SESSION_TUNES, Api.getStudioSessionTunes, [
    id,
  ]);

export const getStudioSessionEngagements = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_ENGAGEMENTS,
    Api.getStudioSessionEngagements,
    [id]
  );

export const getStudioSessionIdentification = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_IDENTIFICATION,
    Api.getStudioSessionIdentification,
    [id]
  );

export const getStudioSessionRehearsals = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_REHEARSALS,
    Api.getStudioSessionRehearsals,
    [id]
  );

export const getStudioSessionMusicians = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_MUSICIANS,
    Api.getStudioSessionMusicians,
    [id]
  );

export const getStudioSessionEvents = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_EVENTS,
    Api.getStudioSessionEvents,
    [id]
  );

export const getStudioSessionSections = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_SECTIONS,
    Api.getStudioSessionSections,
    [id]
  );

export const deleteStudioSession = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_STUDIO_SESSION, Api.deleteStudioSession, [id]);

export const createStudioSession = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_STUDIO_SESSION, Api.createStudioSession, [
    body,
  ]);

export const updateStudioSession = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_STUDIO_SESSION, Api.updateStudioSession, [
    id,
    body,
  ]);

export const createStudioSessionCategory = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_STUDIO_SESSION_CATEGORY,
    Api.createStudioSessionCategory,
    [id, body]
  );

export const deleteStudioSessionCategory = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_STUDIO_SESSION_CATEGORY,
    Api.deleteStudioSessionCategory,
    [id, body]
  );
