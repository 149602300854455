import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import AppBar from "components/AppBar";
// reactor
import AuthChecker from "components/AuthChecker";
import Centrifugo from "components/Centrifugo";
import EnvMessage from "components/EnvMessage";
import HelpCenter from "components/HelpCenter";
import KeyboardEvents from "components/KeyboardEvents";
import LeftMenu from "components/LeftMenu";
import MessageDrawer from "components/MessageDrawer";
// components
import NotificationCenter from "components/NotificationCenter";
import SubscriptionCenter from "components/SubscriptionCenter";
import TourCenter from "components/TourCenter";
import UrlActions from "components/UrlActions";
import { ENV_MESSAGE, REACT_APP_ROOT_BACKGROUND } from "config";
import { DEFAULT, SIGNIN } from "constants/routes";
// helpers
import { redirectAccordingToRole } from "helpers/redirect";
import PropTypes from "prop-types";
import React from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// styles
import styles from "./styles";
import RestrictOrganization from "hooks/restrictOrganization/restrictOrganization";

class WrapperRootPage extends React.Component {
  static propTypes = {
    routes: PropTypes.array,
    allRoutes: PropTypes.array,
    cookies: PropTypes.object,
    history: PropTypes.object,
    theme: PropTypes.object,
    location: PropTypes.object,
    classes: PropTypes.object,
    user: PropTypes.object,
    userApps: PropTypes.array,
    getCurrentUser: PropTypes.func,
    signout: PropTypes.func,
    websocketMessageReceived: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    stopImpersonate: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    errors: PropTypes.object,
    storeResetPasswordToken: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
    setDarkMode: PropTypes.func,
    app: PropTypes.object,
    init: PropTypes.func,
    upload: PropTypes.func,
    currentCompany: PropTypes.object,
    subscriptions: PropTypes.array,
    bazaarCustomer: PropTypes.any,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      mobileOpen: false,
      loading: true,
      alert: null,
    };

    window.addEventListener("resize", this.refreshInnerHeight.bind(this));
  }

  componentDidMount() {
    this.refreshInnerHeight();
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  refreshInnerHeight() {
    // We execute the same script as before
    console.log("refreshInnerHeight");
    const vh = window.innerHeight * 0.01;
    this.setState({ vh });
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  injectRoutes() {
    const { routes } = this.props;

    return (
      <div style={{ flexGrow: 1, display: "flex", position: "relative" }}>
        <Switch>
          {routes.map((route) =>
            route.path === DEFAULT ? (
              <Route key={route.path} component={route.component} />
            ) : (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
              />
            )
          )}
        </Switch>
      </div>
    );
  }

  render() {
    const {
      classes,
      location,
      routes,
      history,
      getCurrentUser,
      errors,
      validateEmail,
      signout,
      storeResetPasswordToken,
      app,
      user,
      setDarkMode,
      userApps,
      allRoutes,
      init,
      stopImpersonate,
      setOrganizationByDefault,
      joinOrganization,
      websocketMessageReceived,
      subscriptions,
      bazaarCustomer,
    } = this.props;

    const { loading, mobileOpen, alert } = this.state;

    const route = routes.find((r) => r.path === location.pathname);

    return (
      <div className="root">
        <AuthChecker
          routes={allRoutes}
          history={history}
          location={location}
          user={user}
          getCurrentUser={getCurrentUser}
          onReady={() => {
            this.setState({ loading: false });
          }}
          signinRoute={SIGNIN}
          redirectAccordingToRole={redirectAccordingToRole}
          init={init}
        >
          <NotificationCenter errors={errors}>
            <TourCenter>
              <SubscriptionCenter>
                <Centrifugo websocketMessageReceived={websocketMessageReceived}>
                  <KeyboardEvents>
                    <UrlActions
                      location={location}
                      history={history}
                      validateEmail={validateEmail}
                      storeResetPasswordToken={storeResetPasswordToken}
                    >
                      <div
                        style={{
                          height: "calc(var(--vh, 1vh) * 100)",
                          width: "100vw",
                        }}
                      >
                        <HelpCenter
                          getHelpCenter={(h) =>
                            this.setState({ helpCenter: h.HelpCenter })
                          }
                        >
                          {loading ? (
                            <Grid
                              container
                              className={classes.loading}
                              alignContent="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <CircularProgress
                                  style={{ color: "#ffffff" }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <MessageDrawer
                              background={REACT_APP_ROOT_BACKGROUND}
                            >
                              <EnvMessage envMessage={ENV_MESSAGE} />
                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                }}
                              >
                                {route && route.withSidebar && (
                                  <LeftMenu
                                    routes={routes}
                                    handleDrawerToggle={this.handleDrawerToggle.bind(
                                      this
                                    )}
                                    mobileOpen={mobileOpen}
                                    history={history}
                                    user={user}
                                    location={location}
                                  />
                                )}
                                <div
                                  style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {route && route.withAppBar && (
                                    <AppBar
                                      onMenuOpen={this.handleDrawerToggle.bind(
                                        this
                                      )}
                                      user={user}
                                      userApps={userApps}
                                      history={history}
                                      app={app}
                                      setDarkMode={setDarkMode}
                                      stopImpersonate={stopImpersonate}
                                      signout={signout}
                                      setOrganizationByDefault={
                                        setOrganizationByDefault
                                      }
                                      joinOrganization={joinOrganization}
                                      getCurrentUser={getCurrentUser}
                                      subscriptions={subscriptions}
                                      bazaarCustomer={bazaarCustomer}
                                    />
                                  )}
                                  {this.injectRoutes()}
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: 18,
                                      right: 22,
                                      width: 65,
                                      height: 65,
                                    }}
                                    data-tut="reactour__crisp"
                                  />
                                  {alert}
                                </div>
                              </div>
                            </MessageDrawer>
                          )}
                        </HelpCenter>
                      </div>
                    </UrlActions>
                  </KeyboardEvents>
                </Centrifugo>
              </SubscriptionCenter>
            </TourCenter>
          </NotificationCenter>
        </AuthChecker>
        <RestrictOrganization />
      </div>
    );
  }
}

export default withCookies(
  withRouter(
    connect()(withStyles(styles, { withTheme: true })(WrapperRootPage))
  )
);
