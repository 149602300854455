import { Chip, IconButton } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Edit from "@material-ui/icons/Edit";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormGroup from "components/FormGroup";
import FormMusician from "components/FormMusician";
// components
import MusiciansList from "components/MusiciansList";
// reactor
import Page from "components/Page";
import { removeURLParameter, updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperMusicianPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    musicians: PropTypes.object,
    groupsById: PropTypes.object,
    createMusician: PropTypes.func,
    deleteMusician: PropTypes.func,
    updateMusician: PropTypes.func,
    getMusicianById: PropTypes.func,
    getMusicians: PropTypes.func,
    createMusicianInstrument: PropTypes.func,
    deleteMusicianInstrument: PropTypes.func,
    instruments: PropTypes.array,
    groups: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedMusician: undefined,
      formMusicianOpen: urlParams.newMusician,
      musicianLoading: false,
      selectedGroup: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.urlParams.newMusician) {
      this.setState({ formMusicianOpen: true });
    }
  }

  getStepContent() {
    const { index } = this.state;
    const {
      musicians,
      history,
      getMusicians,
      instruments,
      groupsById,
      groups,
    } = this.props;

    return (
      <MusiciansList
        history={history}
        noAdvancedSearch={index > 0}
        musicians={
          index === 0
            ? musicians.data
            : groupsById[groups[index - 1]?.id]?.musicians ?? []
        }
        groups={groups}
        groupID={groupsById[groups[index - 1]?.id]?.id}
        noEmptyMessage={index}
        getMusicians={getMusicians}
        instruments={instruments}
        onSelect={(selectedMusician) => {
          this.setState({
            musicianLoading: true,
            formMusicianOpen: true,
          });
          this.refreshMusician(selectedMusician.id);
        }}
      />
    );
  }

  async refreshMusician(id) {
    const { getMusicianById } = this.props;
    const resp = await getMusicianById(id);
    const selectedMusician = resp.payload;
    this.setState({
      selectedMusician,
      musicianLoading: false,
    });
  }

  render() {
    const {
      classes,
      history,
      musicians,
      loading,
      createMusician,
      deleteMusician,
      updateMusician,
      createMusicianInstrument,
      deleteMusicianInstrument,
      refresh,
      instruments,
      groups,
      groupsById,
    } = this.props;

    const {
      index,
      selectedMusician,
      formMusicianOpen,
      musicianLoading,
      groupOpen,
      selectedGroup,
    } = this.state;

    return (
      <Page
        helmet="All Musicians"
        loading={loading}
        loadingMessage={"Loading Musicians"}
        tabs={
          <div className={classes.appBar}>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({
                  index: v,
                });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              {groups.map((g) => (
                <Tab
                  label={
                    <div>
                      {g.name}
                      <IconButton
                        size="small"
                        style={{ marginLeft: 20 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.setState({
                            selectedGroup: g,
                            groupOpen: true,
                          });
                        }}
                      >
                        <Edit style={{ fontSize: 16 }} />
                      </IconButton>
                    </div>
                  }
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
              ))}
            </Tabs>
            <div style={{ marginRight: 8 }}>
              <Chip
                size="small"
                onClick={() => this.setState({ groupOpen: true })}
                label="+ New group"
              />
            </div>
          </div>
        }
      >
        {this.getStepContent()}
        <FormMusician
          open={formMusicianOpen}
          musicians={musicians}
          musician={selectedMusician}
          close={() => {
            this.setState({
              formMusicianOpen: false,
            });
            history.push({ search: removeURLParameter("newMusician") });
            setTimeout(
              () =>
                this.setState({
                  selectedMusician: undefined,
                }),
              200
            );
          }}
          refresh={refresh}
          refreshMusician={this.refreshMusician.bind(this)}
          instruments={instruments}
          musicianLoading={musicianLoading}
        />
        <FormGroup
          open={groupOpen}
          close={() =>
            this.setState({ groupOpen: false, selectedGroup: undefined })
          }
          group={selectedGroup}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMusicianPage);
