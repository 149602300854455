import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormQuote from "components/FormQuote";
import Page from "components/Page";
// components
import QuotesList from "components/QuotesList";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperQuotesPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //quotes
    quotes: PropTypes.array,
    getQuoteById: PropTypes.func,
    createQuote: PropTypes.func,
    deleteQuote: PropTypes.func,
    updateQuote: PropTypes.func,
    getQuotes: PropTypes.func,
    searchUsers: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    getCurrentUser: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedQuote: undefined,
      formQuoteOpen: false,
    };
  }

  render() {
    const {
      classes,
      history,
      loading,
      createQuote,
      deleteQuote,
      updateQuote,
      refresh,
      setOrganizationByDefault,
      getCurrentUser,
      quotes,
      getQuotes,
      searchUsers,
    } = this.props;

    const { index, selectedQuote, formQuoteOpen } = this.state;

    const filterMap = {
      0: "ready",
      1: "pending",
      2: "invoiced",
      3: "paid",
    };

    return (
      <Page
        helmet={"Quotes"}
        loadingMessage={"Loading Document"}
        loading={loading}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search:
                  v !== 4
                    ? `filters=[${JSON.stringify({
                        name: "quotes.status",
                        comparison: "like",
                        value: filterMap[v],
                      })}]`
                    : "filters=[]",
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Ready"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Pending"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Invoiced"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Paid"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <QuotesList
          quotes={quotes}
          history={history}
          getQuotes={getQuotes}
          searchUsers={searchUsers}
          onSelect={(selectedQuote) => {
            this.setState({ selectedQuote, formQuoteOpen: true });
          }}
        />
        <FormQuote
          open={formQuoteOpen}
          quote={selectedQuote}
          close={() => {
            this.setState({
              formQuoteOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedQuote: undefined,
                }),
              200
            );
          }}
          history={history}
          createQuote={createQuote}
          deleteQuote={deleteQuote}
          updateQuote={updateQuote}
          setOrganizationByDefault={setOrganizationByDefault}
          getCurrentUser={getCurrentUser}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperQuotesPage);
