// routes
import {
  ACTIONS,
  CUSTOMER,
  EMAILS,
  EVENT_STATUSES,
  INSTRUMENT,
  INSTRUMENT_CONFIGURATOR,
  INSTRUMENT_FAMILIES,
  INSTRUMENT_SECTIONS,
  JOURNAL,
  MUSICIAN,
  PAYROLLS,
  PIPELINE,
  PROJECT,
  QUOTES,
  SESSION_TYPE,
  STUDIOS,
  STUDIO_SESSION,
  TEMPLATES,
} from "constants/routes";
import React from "react";

export const menu = [
  {
    icon: <i class="fad fa-home"></i>,
    label: "Journal",
    dataTut: "reactour__Journal",
    path: JOURNAL,
    divider: false,
  },
  {
    icon: <i class="fad fa-folder-open"></i>,
    label: "Projects",
    dataTut: "reactour__Projects",
    path: PROJECT,
    divider: false,
  },
  {
    icon: <i class="fad fa-calendar-day"></i>,
    label: "Work Sessions",
    dataTut: "reactour__WorkSessions",
    path: STUDIO_SESSION,
    divider: false,
  },
  {
    icon: <i class="fad fa-building"></i>,
    label: "Customers",
    dataTut: "reactour__Customers",
    path: CUSTOMER,
    divider: false,
  },
  {
    icon: <i class="fad fa-location-dot"></i>,
    label: "Studios / Venues",
    dataTut: "reactour__Studio",
    path: STUDIOS,
    divider: false,
  },
  {
    icon: <i class="fad fa-university"></i>,
    label: "Payrolls",
    dataTut: "reactour__Payrolls",
    path: PAYROLLS,
    divider: false,
  },
  {
    icon: <i class="fad fa-user-music"></i>,
    label: "Address Book",
    dataTut: "reactour__Musicians",
    path: MUSICIAN,
    divider: false,
  },
  {
    icon: <i class="fa-duotone fa-square-dashed"></i>,
    label: "Templates",
    dataTut: "reactour__Templates",
    path: TEMPLATES,
    divider: true,
  },
  {
    icon: <i class="fad fa-paper-plane"></i>,
    label: "Sent",
    dataTut: "reactour__Emails",
    path: EMAILS,
    divider: true,
  },
  {
    icon: <i class="fad fa-file-invoice-dollar"></i>,
    label: "Quotes",
    dataTut: "reactour__Quotes",
    path: QUOTES,
    divider: true,
  },
  {
    icon: <i class="fad fa-sliders-v"></i>,
    label: "Admin",
    path: INSTRUMENT,
    divider: true,
    nested: [
      {
        icon: <i class="fad fa-guitar-electric"></i>,
        label: "Instruments",
        path: INSTRUMENT,
      },
      {
        icon: <i class="fad fa-ballot"></i>,
        label: "Configurator",
        path: INSTRUMENT_CONFIGURATOR,
      },
      {
        icon: <i class="fad fa-grip-lines"></i>,
        label: "Sections",
        path: INSTRUMENT_SECTIONS,
      },
      {
        icon: <i class="fad fa-grip-vertical"></i>,
        label: "Families",
        path: INSTRUMENT_FAMILIES,
      },
      {
        icon: <i class="fad fa-file-invoice"></i>,
        label: "Session Types",
        path: SESSION_TYPE,
        divider: true,
      },
      {
        icon: <i class="fad fa-bells"></i>,
        label: "Event Statuses",
        path: EVENT_STATUSES,
        divider: true,
      },
      {
        icon: <i class="fad fa-project-diagram"></i>,
        label: "Pipeline",
        path: `${PIPELINE}?id=1`,
        divider: true,
      },
      {
        icon: <i class="fad fa-user-shield"></i>,
        label: "Admin Actions",
        path: ACTIONS,
        divider: true,
      },
    ],
  },
];
