import { Typography } from "@material-ui/core";
import * as Actions from "actions";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

export default function FileUpload({ onUpload, disabled }) {
  const dispatch = useDispatch();
  const onDrop = useCallback((acceptedFiles) => {
    dispatch(Actions.upload(acceptedFiles[0])).then((r) => {
      onUpload(r.payload);
    });
  }, []); //eslint-disable-line

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  if (disabled) {
    return <div />;
  }

  return (
    <div style={{ display: "flex" }}>
      <div
        {...getRootProps()}
        style={{
          cursor: "pointer",
          border: "solid 1px rgba(155,155,155,0.3)",
          background: "rgba(155,155,155,0.1)",
          padding: "0px 4px",
          display: "block",
          borderRadius: 4,
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="caption">
            <i class="fad fa-chevron-square-down"></i> Drop the files here!
          </Typography>
        ) : (
          <Typography variant="caption">
            <i class="fad fa-cloud-upload-alt p-right"></i>Browse files...
          </Typography>
        )}
      </div>
    </div>
  );
}
