import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import MailIcon from "@material-ui/icons/Mail";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PinDropIcon from "@material-ui/icons/PinDrop";
import RemoveIcon from "@material-ui/icons/Remove";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import StarsIcon from "@material-ui/icons/Star";
import TransferWithinAStationIcon from "@material-ui/icons/SwapCalls";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MusicianSuggestion from "components/MusicianSuggestion";
// constants
import { MUSICIAN } from "constants/routes";
// hooks
import MusicianAvatar from "hooks/MusicianAvatar";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ProjectAlternate({
  projectAlternate,
  api,
  sectionRoles,
  projectSection,
  disabled,
  project,
  history,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popperAnchor, setPopperAnchor] = React.useState(null);
  const [musiciansLoading, setMusiciansLoading] = React.useState(false);
  const [primaryEl, setPrimaryEl] = React.useState(null);
  const [menuEl, setMenuEl] = React.useState(null);
  const [sectionMusicians, setSectionMusicians] = React.useState([]);
  const [notesOpen, setNotesOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [notes, setNotes] = React.useState(projectAlternate.notes || "");
  const classes = useStyles(styles);

  const primary = projectAlternate.prime;

  const emptyPosition =
    projectAlternate.musicianID === undefined ||
    projectAlternate.musicianID === null ||
    projectAlternate.musicianID === -1;
  const label = `${
    projectAlternate.nickName ||
    `${projectAlternate.musician.firstName} ${projectAlternate.musician.lastName}`
  }`;

  const m = projectAlternate.musician || {};

  let background;
  let border = "solid 1px #9e9e9e";
  if (primary && !emptyPosition) background = "rgba(215,183,64, 0.1)";
  if (primary) border = "solid 1px #D7B740";
  if (!primary && !emptyPosition) background = "rgba(155,155,155,0.1)";

  const theme = useTheme();
  const darkMode = theme.palette.type === "dark";

  const getSectionMusicians = async (e) => {
    setMusiciansLoading(true);
    setPopperAnchor(e.currentTarget);
    const r = await api.getInstrumentSectionProjectMusicians(
      projectSection.sectionID,
      project.id
    );
    if (r.success) {
      setMusiciansLoading(false);
      setSectionMusicians(r.payload);
    }
  };

  const closeMusicianSuggestions = () => {
    setPopperAnchor(null);
  };

  const linkMusician = async (musician) => {
    closeMusicianSuggestions();
    setLoading(true);
    await api.updateProjectAlternate(projectAlternate.id, {
      musicianID: musician.id,
    });
    await api.refresh();
    setLoading(false);
  };

  const popperOpen = Boolean(popperAnchor);

  return (
    <Paper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-tut="reactour__musician"
      onClick={(e) => {
        e.preventDefault();
        if (emptyPosition && !popperOpen) {
          getSectionMusicians(e);
        }
      }}
      style={{
        background,
        border,
        width: 300,
        position: "relative",
        boxShadow: hover
          ? "0 2px 7px rgba(155,155,155,0.3)"
          : "0 5px 15px rgba(0,0,0,0)",
        cursor: "pointer",
        overflow: "hidden",
        borderRadius: 4,
        minHeight: 70,
        display: "flex",
        alignItems: "stretch",
      }}
    >
      <Grid container justify="space-between" alignItems="stretch">
        <Grid
          item
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!disabled && !Boolean(primaryEl)) {
              setPrimaryEl(e.currentTarget);
            }
          }}
          style={{
            cursor: "pointer",
            width: 30,
            background: primary ? "#D7B740" : "#607d8b",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <Tooltip title={primary ? "Primary" : "Alternate"}>
              {primary ? (
                <StarsIcon style={{ fontSize: 18, color: "white" }} />
              ) : (
                <TransferWithinAStationIcon
                  style={{ fontSize: 18, color: "white" }}
                />
              )}
            </Tooltip>
            <Popover
              id="simple-menu"
              disableEnforceFocus
              disableRestoreFocus
              anchorEl={primaryEl}
              open={Boolean(primaryEl)}
              onClose={() => {
                console.log("Closing");
                setPrimaryEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <ListItem
                divider
                selected={primary}
                button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!primary) {
                    api
                      .updateProjectAlternate(projectAlternate.id, {
                        prime: true,
                      })
                      .then(api.refresh);
                  }
                  setPrimaryEl(null);
                }}
              >
                <ListItemIcon>
                  <StarsIcon />
                </ListItemIcon>
                <ListItemText primary={"Primary"} />
              </ListItem>
              <ListItem
                button
                selected={!primary}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (primary) {
                    api
                      .updateProjectAlternate(projectAlternate.id, {
                        prime: false,
                      })
                      .then(api.refresh);
                  }
                  setPrimaryEl(null);
                }}
              >
                <ListItemIcon>
                  <TransferWithinAStationIcon />
                </ListItemIcon>
                <ListItemText primary={"Alternate"} />
              </ListItem>
            </Popover>
            <Typography display="block" color="inherit" variant="caption">
              <b>{projectAlternate.sectionOrder + 1}</b>
            </Typography>
          </div>
        </Grid>
        <Grid item style={{ padding: "4px 2px 4px 4px", display: "flex" }}>
          <Grid container spacing={1}>
            <Grid
              item
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              {emptyPosition ? (
                <i class="fad fa-chair" style={{ fontSize: 30 }}></i>
              ) : (
                <MusicianAvatar musician={projectAlternate.musician} />
              )}
            </Grid>
            <Grid item style={{ padding: "4px 8px 4px 8px", width: 170 }}>
              <Chip
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                disabled={disabled}
                onDelete={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }}
                label={
                  sectionRoles.find(
                    (sr) => sr.id === projectAlternate.sectionRoleID
                  ).name
                }
                deleteIcon={<ArrowDropDownIcon />}
                className={!emptyPosition ? classes.chip : classes.emptyChip}
              />
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  disableRestoreFocus
                  disableEnforceFocus
                  onClose={() => setAnchorEl(null)}
                >
                  {sectionRoles.map((sr) => (
                    <MenuItem
                      key={sr.id}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setAnchorEl(null);
                        api
                          .updateProjectAlternate(projectAlternate.id, {
                            sectionRoleID: sr.id,
                          })
                          .then(api.refresh);
                      }}
                    >
                      {sr.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
              {!emptyPosition ? (
                <Typography
                  color={!emptyPosition ? "textPrimary" : "textSecondary"}
                  display="block"
                  variant="body2"
                >
                  {label}
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  <b>Empty Chair</b>
                  <br />
                  Click to assign a musician
                </Typography>
              )}
              {notes ? (
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    fontSize: 10,
                  }}
                >
                  <Tooltip title={"Click to view and edit."}>
                    <Typography
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setNotesOpen(true);
                      }}
                      color="textSecondary"
                      variant="caption"
                      style={{
                        background: "rgba(255,235,59,0.1)",
                        padding: 2,
                        fontSize: 10,
                        border: "solid 1px rgba(255,235,59,0.3)",
                      }}
                      noWrap
                    >
                      {notes}
                    </Typography>
                  </Tooltip>
                </div>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            width: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MoreVertIcon
            style={{ fontSize: 18 }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setMenuEl(e.currentTarget);
            }}
          />
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Popover
              id="musician-info"
              disableEnforceFocus
              disableRestoreFocus
              anchorEl={menuEl}
              open={Boolean(menuEl)}
              onClose={() => {
                setMenuEl(null);
              }}
            >
              <List
                dense
                style={{
                  width: 250,
                }}
                id="simple-menu"
              >
                {!emptyPosition ? (
                  <ListItem>
                    <ListItemIcon>
                      <MailIcon />
                    </ListItemIcon>
                    <ListItemText primary={m.email} />
                  </ListItem>
                ) : (
                  []
                )}
                {m.phone ? (
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIphoneIcon />
                    </ListItemIcon>
                    <ListItemText primary={m.phone} />
                  </ListItem>
                ) : (
                  []
                )}
                {m.address ? (
                  <ListItem>
                    <ListItemIcon>
                      <PinDropIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${m.address || ""}`}
                      secondary={`${m.zipcode || ""} ${m.city || ""}`}
                    />
                  </ListItem>
                ) : (
                  []
                )}
                {m.comments ? (
                  <ListItem>
                    <ListItemIcon>
                      <SpeakerNotesIcon />
                    </ListItemIcon>
                    <Tooltip title="Musician's Comments">
                      <ListItemText
                        style={{
                          background: "rgba(255,235,59,0.1)",
                          padding: 2,
                        }}
                        secondary={<div>{m.comments}</div>}
                      />
                    </Tooltip>
                  </ListItem>
                ) : (
                  []
                )}
                <Divider />
                <ListItem
                  button
                  disabled={disabled}
                  divider
                  onClick={() => {
                    setMenuEl(null);
                    setNotesOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <NoteAddIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {notes ? "Edit Project Note" : "Add a Project Note"}
                  </ListItemText>
                </ListItem>
                <ListItem
                  button
                  divider
                  disabled={disabled}
                  onClick={() => {
                    setMenuEl(null);
                    api
                      .updateProjectAlternate(projectAlternate.id, {
                        prime: !projectAlternate.prime,
                      })
                      .then(api.refresh);
                  }}
                >
                  <ListItemIcon>
                    {projectAlternate.prime ? (
                      <TransferWithinAStationIcon />
                    ) : (
                      <StarsIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    {projectAlternate.prime
                      ? "Set as Alternate"
                      : "Set as Primary"}
                  </ListItemText>
                </ListItem>
                {!emptyPosition ? (
                  <ListItem
                    button
                    disabled={disabled}
                    divider
                    onClick={() => {
                      setMenuEl(null);
                      setLoading(true);
                      api
                        .updateProjectAlternate(projectAlternate.id, {
                          musicianID: -1,
                        })
                        .then(async () => {
                          await api.refresh();
                          setLoading(false);
                        });
                    }}
                  >
                    <ListItemIcon>
                      <RemoveIcon />
                    </ListItemIcon>
                    <ListItemText>Remove Musician</ListItemText>
                  </ListItem>
                ) : (
                  []
                )}
                <ListItem
                  button
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setMenuEl(null);
                    setLoading(true);
                    api
                      .deleteProjectAlternate(projectAlternate.id)
                      .then(async () => {
                        await api.refresh();
                        setLoading(false);
                      });
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>Delete Position</ListItemText>
                </ListItem>
              </List>
            </Popover>
          </div>
        </Grid>
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Dialog
            open={notesOpen}
            onClose={(e) => {
              setNotesOpen(false);
            }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <Typography variant="h6" color="textPrimary">
                {projectAlternate.notes
                  ? "Edit Project Note"
                  : "Add a Project Note"}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
                style={{ display: "flex", alignItems: "center" }}
              >
                <VisibilityIcon style={{ fontSize: 16, marginRight: 4 }} /> This
                note will be included in all emails sent to the musician.
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                disabled={disabled}
                id="notes"
                label="Notes"
                fullWidth
                multiline
                value={notes}
                error={255 - notes.length <= 0}
                helperText={
                  notes.length > 255
                    ? "Text Too long"
                    : `${255 - notes.length} char left`
                }
                onChange={(e) => setNotes(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setNotesOpen(false);
                  if (!projectAlternate.notes) setNotes("");
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setNotesOpen(false);
                  setMenuEl(null);
                  api
                    .updateProjectAlternate(projectAlternate.id, { notes })
                    .then(api.refresh);
                }}
                disabled={255 - notes.length <= 0 || disabled}
                color="secondary"
                variant="contained"
              >
                {projectAlternate.notes ? "Edit" : "Add"}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Popover
          open={popperOpen}
          onClose={closeMusicianSuggestions}
          disableEnforceFocus
          disableRestoreFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          anchorEl={popperAnchor}
        >
          <MusicianSuggestion
            linkMusician={linkMusician}
            loading={musiciansLoading}
            sectionRoles={sectionRoles}
            onClose={closeMusicianSuggestions}
            categories={false}
            search
            showPrime={false}
            sortAlphabetically
            label={"Suggestions"}
            suggestions={sectionMusicians}
            footer={
              <>
                <Typography color="textSecondary" variant="caption">
                  The address book does not contain any other musician for this
                  section.
                </Typography>
                <br />
                <Chip
                  onClick={() => history.push(MUSICIAN)}
                  size="small"
                  label="Go to Musicians"
                />
              </>
            }
          />
        </Popover>
      </Grid>
      {loading ? (
        <div
          style={{
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            borderRadius: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgba(155,155,155,0.8)",
          }}
        >
          <CircularProgress size={20} color="inherit" />
        </div>
      ) : (
        []
      )}
    </Paper>
  );
}
