import { PRICINGS } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import PricingsPage from "./container/PricingsPage";

export default function getSettingsRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: PRICINGS,
    withSidebar: false,
    withAppBar: false,
    component: PricingsPage,
  };
}
