import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const sessionTypes = `/${service}/sessionTypes`;

export function getSessionTypes() {
  const url = `${sessionTypes}`;
  return {
    method: GET,
    url,
  };
}

export function getSessionTypeById(id) {
  const url = `${sessionTypes}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getSessionTypeCategories(id) {
  const url = `${sessionTypes}/${id}/categories`;
  return {
    method: GET,
    url,
  };
}

export function getSessionTypeRates(id) {
  const url = `${sessionTypes}/${id}/rates`;
  return {
    method: GET,
    url,
  };
}

export function getSessionTypeSessionMedia(id) {
  const url = `${sessionTypes}/${id}/sessionMedia`;
  return {
    method: GET,
    url,
  };
}

export function getSessionTypeProductionTypes(id) {
  const url = `${sessionTypes}/${id}/productionTypes`;
  return {
    method: GET,
    url,
  };
}

export function updateSessionType(id, body) {
  const url = `${sessionTypes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSessionType(id) {
  const url = `${sessionTypes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSessionType(body) {
  const url = `${sessionTypes}`;
  return {
    method: POST,
    url,
    body,
  };
}
