import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class Rehearsal extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    rehearsal: PropTypes.object,
    updateRehearsal: PropTypes.func,
    deleteRehearsal: PropTypes.func,
    refresh: PropTypes.func,
    disabled: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { rehearsal } = this.props;
    this.state = {
      ...rehearsal,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name) {
    const { updateRehearsal, refresh, rehearsal } = this.props;
    if (rehearsal[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateRehearsal(rehearsal.id, {
      [name]: this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const {
      classes,
      deleteRehearsal,
      refresh,
      rehearsal,
      disabled,
    } = this.props;
    const { minutes, start, dismd, hours, span, loading } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("minutes");
            }}
            disabled={loading || disabled}
            onBlur={() => this.save("minutes")}
            className={disabled ? undefined : classes.inputBase}
            fullWidth
            defaultValue={minutes}
            onChange={this.handleChange("minutes")}
            hintText="hello"
          />
        </Grid>
        <Grid item xs={2}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("start");
            }}
            disabled={loading || disabled}
            onBlur={() => this.save("start")}
            className={disabled ? undefined : classes.inputBase}
            fullWidth
            defaultValue={start}
            onChange={this.handleChange("start")}
          />
        </Grid>
        <Grid item xs={2}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("dismd");
            }}
            disabled={loading || disabled}
            onBlur={() => this.save("dismd")}
            className={disabled ? undefined : classes.inputBase}
            fullWidth
            defaultValue={dismd}
            onChange={this.handleChange("dismd")}
          />
        </Grid>
        <Grid item xs={2}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("hours");
            }}
            disabled={loading || disabled}
            onBlur={() => this.save("hours")}
            className={disabled ? undefined : classes.inputBase}
            fullWidth
            defaultValue={hours}
            onChange={this.handleChange("hours")}
          />
        </Grid>
        <Grid item xs={2}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "span") this.save("span");
            }}
            disabled={loading || disabled}
            onBlur={() => this.save("span")}
            className={disabled ? undefined : classes.inputBase}
            fullWidth
            defaultValue={span}
            onChange={this.handleChange("span")}
          />
        </Grid>
        <Grid item xs={1}>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <Tooltip title="Delete Rehearsal">
              <IconButton
                disabled={disabled}
                aria-label="Delete"
                className={classes.margin}
                onClick={async () => {
                  await deleteRehearsal(rehearsal.id);
                  refresh();
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Rehearsal);
