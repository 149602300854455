import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import HearingIcon from "@material-ui/icons/Hearing";
import HelpIcon from "@material-ui/icons/Help";
import LocalPlayIcon from "@material-ui/icons/LocalPlay";
import MicIcon from "@material-ui/icons/Mic";
import WarningIcon from "@material-ui/icons/Warning";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
// material-ui
import { withStyles } from "@material-ui/styles";
// component
import DateInput from "components/DateInput";
// helpers
import { getLatLgn, getTimezone } from "helpers";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormEngagement extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    engagement: PropTypes.object,
    studioSession: PropTypes.object,

    createEngagement: PropTypes.func,
    updateEngagement: PropTypes.func,
    deleteEngagement: PropTypes.func,
    conciergeReadOnly: PropTypes.bool,

    studios: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    SubscriptionCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      dateFromUTC: "",
      name: "",
      dateToUTC: "",
      description: "",
      noTimeRange: false,
      error: false,
      overtime: "",
      state: "",
      tzName: "",
      venueID: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.engagement);
    }
  }

  async getTzname() {
    const { city, state } = this.state;

    if (city && state) {
      this.setState({ tzNameLoading: true });
      const a = `${city} ${state}`;
      const coordinates = await getLatLgn(a);

      if (coordinates.lat && coordinates.lng) {
        const tzName = await getTimezone(coordinates.lat, coordinates.lng);
        this.setState({ tzName });
      }
      this.setState({ tzNameLoading: false });
    }
  }

  getAvatarIcon() {
    const { name } = this.state;

    switch (name) {
      case "Recording":
        return <MicIcon />;
      case "Rehearsal":
        return <HearingIcon />;
      case "Performance":
        return <LocalPlayIcon />;
      default:
        return <HelpIcon />;
    }
  }

  getColor() {
    const { name } = this.state;

    switch (name) {
      case "Recording":
        return "#f44336";
      case "Rehearsal":
        return "#9e9e9e";
      case "Performance":
        return "#2196f3";
      default:
        return "#9e9e9e";
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(engagement) {
    this.setState({
      loading: false,
      dateFromUTC: "",
      name: "Rehearsal",
      dateToUTC: "",
      description: "",
      noTimeRange: false,
      error: false,
      overtime: "",
      state: "",
      city: "",
      tzName: "",
      venueID: "",
      noVenue: false,
    });
    if (engagement) {
      this.setState({
        loading: false,
        ...engagement,
        noVenue: !engagement.venueID || engagement.venueID <= 0,
      });
    }
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle:
          "If you delete this engagement, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: () => this.confirmDelete(),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteEngagement, close, refresh, engagement } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteEngagement(engagement.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Engagement has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateEngagement,
      createEngagement,
      engagement,
      close,
      refresh,
      studioSession,
    } = this.props;

    const {
      name,
      dateFromUTC,
      dateToUTC,
      description,
      loading,
      overtime,
      venueID,
      noTimeRange,
      tzName,
      city,
      state,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (engagement) {
      resp = await updateEngagement(engagement.id, {
        name,
        dateFromUTC,
        dateToUTC,
        description,
        loading,
        overtime,
        venueID: venueID || 0,
        noTimeRange,
        tzName,
        city,
        state,
        sessionID: studioSession.id,
      });
    } else {
      resp = await createEngagement({
        name,
        dateFromUTC,
        dateToUTC,
        description,
        loading,
        overtime,
        venueID: venueID || 0,
        noTimeRange,
        tzName,
        city,
        state,
        sessionID: studioSession.id,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: engagement
          ? "Engagement has been updated."
          : "Engagement has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      setTimeout(NotificationCenter.hide, 2000);
    }
    close();
    refresh();
  }

  valid() {
    const {
      tzName,
      dateFromUTC,
      dateToUTC,
      noTimeRange,
      venueID,
      city,
      state,
    } = this.state;
    return (
      tzName &&
      (venueID || (city && state)) &&
      ((noTimeRange && dateFromUTC) ||
        (dateFromUTC &&
          dateToUTC &&
          moment(dateFromUTC).isSameOrBefore(moment(dateToUTC))))
    );
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      engagement,
      close,
      studios,
      studioSession,
      conciergeReadOnly,
    } = this.props;

    const {
      dateFromUTC,
      dateToUTC,
      description,
      loading,
      overtime,
      venueID,
      error,
      noTimeRange,
      tzName,
      city,
      state,
      name,
      noVenue,
      tzNameLoading,
    } = this.state;

    const archived = studioSession.archived;
    const recorded = studioSession.recorded;

    const venue = studios && studios.find((s) => s.id === venueID);

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              <Avatar style={{ background: this.getColor() }}>
                {this.getAvatarIcon()}
              </Avatar>
              {engagement ? `${engagement.name}` : `Let's Create a New ${name}`}
              {engagement && engagement.isEvent ? (
                <Typography
                  variant="caption"
                  style={{
                    color: "#f44336",
                    display: "flex",
                    alignItems: "center",
                  }}
                  display="block"
                >
                  <WarningIcon style={{ color: "#f44336", fontSize: 14 }} />{" "}
                  This is the primary engagement. It cannot be deleted.
                </Typography>
              ) : (
                []
              )}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {noVenue ? (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled={archived || recorded || conciergeReadOnly}
                        multiline
                        variant="outlined"
                        placeholder="City"
                        label="City"
                        helperText="Required"
                        error={error && !city}
                        defaultValue={city}
                        onChange={this.handleChange("city")}
                        onBlur={this.getTzname.bind(this)}
                      />
                      <FormHelperText>
                        {tzNameLoading ? <CircularProgress size={20} /> : []}
                        {!tzNameLoading && `Timezone: ${tzName}`}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled={archived || recorded || conciergeReadOnly}
                        multiline
                        helperText="Required"
                        error={error && !state}
                        variant="outlined"
                        label="State"
                        placeholder="State"
                        defaultValue={state}
                        onChange={this.handleChange("state")}
                        onBlur={this.getTzname.bind(this)}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Autocomplete
                      id="Venue"
                      value={venue}
                      disabled={archived || recorded || conciergeReadOnly}
                      options={studios}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      onChange={(e, i) => {
                        if (i) {
                          this.setState({
                            venueID: i.id,
                            tzName: i.tzName,
                          });
                        } else {
                          this.setState({
                            venueID: null,
                            tzName: null,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Venue"
                          variant="outlined"
                        />
                      )}
                    />
                    <FormHelperText error={error && (!venueID || venueID < -1)}>
                      Required
                    </FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={noVenue}
                        disabled={archived || recorded || conciergeReadOnly}
                        size="small"
                        color="primary"
                        onChange={(e) => {
                          const checked = e.target.checked;
                          if (checked) {
                            this.setState({
                              noVenue: true,
                              venueID: 0,
                              tzName: "",
                            });
                          } else {
                            this.setState({
                              noVenue: false,
                              city: "",
                              state: "",
                              tzName: "",
                            });
                          }
                        }}
                        value="noVenue"
                      />
                    }
                    label="No Venue"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    Local Date and Time
                  </Typography>
                  <Grid container>
                    <Grid item>
                      <DateInput
                        dateFromUTC={dateFromUTC}
                        dateToUTC={dateToUTC}
                        tzName={tzName}
                        noTimeRange={noTimeRange}
                        disabled={archived || recorded || conciergeReadOnly}
                        onChange={(from, to) => {
                          console.log(from, to);
                          this.setState({
                            dateFromUTC: from,
                            dateToUTC: to,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={noTimeRange}
                        disabled={archived || recorded || conciergeReadOnly}
                        size="small"
                        onChange={(e) => {
                          this.setState({ noTimeRange: e.target.checked });
                        }}
                        value="noTimeRange"
                      />
                    }
                    label="No Time Range"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    disabled={archived || recorded || conciergeReadOnly}
                    variant="outlined"
                    label="Overtime"
                    placeholder="Overtime"
                    defaultValue={overtime}
                    onChange={this.handleChange("overtime")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    disabled={archived || recorded || conciergeReadOnly}
                    variant="outlined"
                    label="Description"
                    placeholder="Description"
                    defaultValue={description}
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                {this.props.engagement ? (
                  <Grid item>
                    <Alert severity="error">
                      Editing an engagement can be dangerous. Confirmed
                      musicians won't be notified automatically of the changes.
                    </Alert>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {engagement ? (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                  disabled={(engagement && engagement.isEvent) || archived}
                  style={{ textTransform: "none" }}
                >
                  <DeleteIcon className={classes.leftIcon} /> Delete
                </Button>
              ) : (
                []
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              <Button
                color="primary"
                autoFocus
                disabled={archived || recorded || conciergeReadOnly}
                style={{
                  background: this.getColor(),
                  color: "white",
                  textTransform: "none",
                  opacity: !this.valid() || archived ? 0.6 : 1,
                }}
                onClick={() => {
                  if (this.valid()) {
                    this.save();
                  } else {
                    this.setState({ error: true });
                  }
                }}
              >
                <SaveIcon className={classes.leftIcon} />
                {engagement ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormEngagement));
