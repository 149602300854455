import { Button, ButtonGroup, Hidden, Tooltip } from "@material-ui/core";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  setPreferedWorkSessionPresentation: (...args) =>
    dispatch(Actions.setPreferedWorkSessionPresentation(...args)),
});

class WorkSessionPresentationSelect extends Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  render() {
    const { app, setPreferedWorkSessionPresentation } = this.props;

    return (
      <Hidden smDown>
        <Tooltip title="Presentation">
          <ButtonGroup
            size="small"
            style={{ background: "#E4E6EB", marginRight: 12 }}
            aria-label="small outlined button group"
          >
            <Button
              style={{
                background:
                  app.workSessionPresentation === "calendar"
                    ? "#1D1F23"
                    : undefined,
                color:
                  app.workSessionPresentation === "calendar"
                    ? "white"
                    : undefined,
              }}
              onClick={() => setPreferedWorkSessionPresentation("calendar")}
            >
              <i class="fad fa-calendar p-right"></i> Calendar
            </Button>
            <Button
              style={{
                background:
                  app.workSessionPresentation === "list"
                    ? "#1D1F23"
                    : undefined,
                color:
                  app.workSessionPresentation === "list" ? "white" : undefined,
              }}
              onClick={() => setPreferedWorkSessionPresentation("list")}
            >
              <i class="fad fa-th-list p-right"></i> List
            </Button>
          </ButtonGroup>
        </Tooltip>
      </Hidden>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WorkSessionPresentationSelect));
