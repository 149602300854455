import { networkAction } from "helpers/network/networkAction";

import {
  GET_POSTS,
  GET_POST_BY_ID,
  DELETE_POST,
  UPDATE_POST,
  CREATE_POST,
} from "constants/posts";

import * as Api from "api";

export const getPosts = () => async (dispatch) =>
  networkAction(dispatch, GET_POSTS, Api.getPosts, []);

export const getPostById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_POST_BY_ID, Api.getPostById, [id]);

export const deletePost = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_POST, Api.deletePost, [id]);

export const createPost = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_POST, Api.createPost, [body]);

export const updatePost = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_POST, Api.updatePost, [id, body]);
