import { Hidden } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import DnsIcon from "@material-ui/icons/Dns";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
// material-ui
import { withStyles } from "@material-ui/styles";
import Autofill from "components/Autofill";
import ChoiceModal from "components/ChoiceModal";
import HiringDashboard from "components/HiringDashboard";
// custom
import StudioSessionFamily from "components/StudioSessionFamily";
// constants
import { PROJECT_DETAIL } from "constants/routes";
// hooks
import InstrumentFamiliesMenu from "hooks/InstrumentFamiliesMenu";
import MercuryHiringDashboard from "hooks/MercuryHiringDashboard";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { withCookies } from "react-cookie";
// styles
import styles from "./styles";

class Hiring extends PureComponent {
  static propTypes = {
    width: PropTypes.string,
    disabled: PropTypes.bool,
    conciergeReadOnly: PropTypes.bool,
    api: PropTypes.object,
    onMusicianEdit: PropTypes.func,
    sessionRefresh: PropTypes.func,
    history: PropTypes.object,
    instrumentSections: PropTypes.array,
    layoutPresets: PropTypes.array,
    instrumentFamilies: PropTypes.array,
    studioSessionDashboard: PropTypes.array,
    studioSession: PropTypes.object,
    sectionRoles: PropTypes.array,
    eventStatuses: PropTypes.array,
    instruments: PropTypes.array,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
    WebsocketCenter: PropTypes.object,
    KeyboardEventCenter: PropTypes.object,
    NotificationCenter: PropTypes.object,
    TourCenter: PropTypes.object,
    SubscriptionCenter: PropTypes.object,
    MercuryNotify: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      subscription: null,
      projectMusicianAcknowledge: false,
      loading: true,
      layoutLoading: false,
    };
    this.instrumentFamiliesMenu = React.createRef();
    this.refresh();
  }

  async refresh() {
    return this.refreshSections();
  }

  async refreshSections() {
    const { studioSession, api } = this.props;

    const { subscription } = this.state;

    const { WebsocketCenter } = this.context;

    const r = await api.getStudioSessionSections(studioSession.id);
    if (r.success) {
      const sessionSections = r.payload;
      const sessionFamilies = [];
      let jobs = [];

      if (!subscription) {
        let s;
        if (studioSession.project.mercuryEnabled) {
          s = WebsocketCenter.subscribe(
            `rhapsody:${studioSession.publicToken}`,
            ({ data }) => {
              if (
                data.method === "UPDATE" &&
                data.resource === "workSessions"
              ) {
                this.refresh();
              }
            }
          );
        } else {
          s = WebsocketCenter.subscribe(
            `rhapsody:${studioSession.publicToken}`,
            this.refresh.bind(this)
          );
        }

        this.setState({ subscription: s });
      }

      for (const key in sessionSections) {
        if (sessionSections.hasOwnProperty(key)) {
          const element = sessionSections[key];
          const familyIndex = sessionFamilies.findIndex(
            (f) => f.id === element.familyID
          );
          jobs = jobs.concat(element.jobs);
          if (familyIndex === -1) {
            sessionFamilies.push({
              name: element.familyName,
              id: element.familyID,
              sections: [element],
              musicians: element.jobs ? element.jobs : 0,
            });
          } else {
            sessionFamilies[familyIndex].sections.push(element);
            if (element.jobs && element.jobs.length) {
              sessionFamilies[familyIndex].musicians = sessionFamilies[
                familyIndex
              ].musicians.concat(
                //eslint-disable-line
                element.jobs
              );
            }
          }
        }
      }

      this.setState({
        loading: false,
        sessionFamilies,
        refreshKey: new Date().getTime(),
      });
    }
  }

  async addMusicians(e) {
    const { api, studioSession } = this.props;

    const { TourCenter } = this.context;
    const body = e.slice(0);
    this.setState({ layoutLoading: true });

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        body[key].sessionID = studioSession.id;
      }
    }

    await api.createStudioSessionMusician(body);

    await this.refreshSections();
    this.setState({ layoutLoading: false });
    TourCenter.next();
  }

  startTour() {
    const { TourCenter } = this.context;
    TourCenter.start(0);
  }

  render() {
    const {
      api,
      sectionRoles,
      eventStatuses,
      studioSession,
      disabled,
      instrumentSections,
      instrumentFamilies,
      instruments,
      layoutPresets,
      studioSessionDashboard,
      history,
      width,
      onMusicianEdit,
      conciergeReadOnly,
    } = this.props;

    const {
      loading,
      sessionFamilies,
      projectMusicianAcknowledge,
      refreshKey,
      layoutLoading,
      subscription,
    } = this.state;

    const { TourCenter, NotificationCenter, MercuryNotify } = this.context;

    if (loading) {
      return (
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
          <Grid item>
            <Typography color="textSecondary">Loading</Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <div
        style={{
          display: !(width === "sm" || width === "xs") && "flex",
          flex: 1,
          position: "relative",
        }}
      >
        <div
          style={{
            width:
              width === "sm" || width === "xs"
                ? "calc(100% - 40px)"
                : "calc(100% - 320px)",
            overflowY: "scroll",
            overflowX: "hidden",
            padding: "20px 2px 20px 20px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Grid item style={{ display: "flex" }}>
                  {sessionFamilies &&
                  instrumentFamilies.length &&
                  sessionFamilies.length !== instrumentFamilies.length &&
                  !disabled &&
                  !conciergeReadOnly ? (
                    <div
                      onClick={() => {
                        if (
                          !studioSession.hasAlternates &&
                          sessionFamilies.length === 0 &&
                          !projectMusicianAcknowledge
                        ) {
                          this.setState({ choiceOpen: true });
                        }
                      }}
                    >
                      <InstrumentFamiliesMenu
                        api={api}
                        history={history}
                        projectID={
                          studioSession.hasAlternates && studioSession.projectID
                        }
                        ref={this.instrumentFamiliesMenu}
                        TourCenter={TourCenter}
                        NotificationCenter={NotificationCenter}
                        layoutPresets={layoutPresets}
                        instrumentFamilies={instrumentFamilies}
                        instrumentSections={instrumentSections}
                        onSelect={this.addMusicians.bind(this)}
                      />
                    </div>
                  ) : (
                    []
                  )}
                  {!disabled &&
                  !conciergeReadOnly &&
                  studioSession.hasAlternates ? (
                    <Hidden smDown>
                      <Autofill
                        refresh={this.refreshSections.bind(this)}
                        history={history}
                        studioSession={studioSession}
                      />
                    </Hidden>
                  ) : (
                    []
                  )}
                </Grid>
                {studioSession.project.mercuryEnabled ? (
                  <Hidden mdUp>
                    <div>
                      <Chip
                        label={
                          <span>
                            <i class="fas fa-bullhorn p-right"></i> Nudge!
                          </span>
                        }
                        onClick={() =>
                          MercuryNotify.nudgeWorkSession(studioSession.id)
                        }
                        size="small"
                      />
                      <Chip
                        data-tut="reactour__notify"
                        label={
                          <span>
                            <i class="fad fa-paper-plane p-right"></i> Notify
                          </span>
                        }
                        onClick={() =>
                          MercuryNotify.workSession(studioSession.id)
                        }
                        size="small"
                        style={{
                          marginLeft: 8,
                          background: "#2196f3",
                          color: "white",
                        }}
                      />
                    </div>
                  </Hidden>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
            {layoutLoading && sessionFamilies.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  height: "60vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <CircularProgress color="secondary" />
                  <Typography variant="h6" color="textPrimary">
                    Please Wait
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    We are creating the positions
                  </Typography>
                </div>
              </Grid>
            ) : (
              []
            )}
            {sessionFamilies &&
            sessionFamilies.length === 0 &&
            !layoutLoading ? (
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  height: "60vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <Typography variant="h6" color="textPrimary">
                    Your Session is Empty
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    Get started by adding a family
                  </Typography>
                  <br />
                  <Chip
                    variant="outlined"
                    onClick={this.startTour.bind(this)}
                    label="Need a Quick Tour?"
                  />
                </div>
              </Grid>
            ) : (
              []
            )}
            <Grid item xs={12}>
              {sessionFamilies &&
                sessionFamilies.map((sf) => (
                  <div
                    data-tut="reactour__expandFamily"
                    style={{ marginBottom: 12 }}
                    key={sf}
                  >
                    <StudioSessionFamily
                      api={api}
                      subscription={subscription}
                      disabled={disabled}
                      conciergeReadOnly={conciergeReadOnly}
                      history={history}
                      onMusicianEdit={onMusicianEdit}
                      instruments={instruments}
                      layoutPresets={layoutPresets}
                      refresh={this.refresh.bind(this)}
                      refreshFuncs={{
                        refreshSections: this.refreshSections.bind(this),
                        // refreshDashboard: this.refreshDashboard.bind(this),
                      }}
                      sectionRoles={sectionRoles}
                      sessionFamily={sf}
                      studioSession={studioSession}
                      eventStatuses={eventStatuses}
                      instrumentSections={instrumentSections}
                      refreshKey={refreshKey}
                    />
                  </div>
                ))}
            </Grid>
          </Grid>
        </div>
        {studioSession.project.mercuryEnabled ? (
          <div
            style={{
              width: width === "sm" || width === "xs" ? "100%" : 320,
              overflowY: "scroll",
            }}
          >
            <MercuryHiringDashboard
              api={api}
              studioSession={studioSession}
              subscription={subscription}
            />
          </div>
        ) : (
          <div
            style={{
              width: width === "sm" || width === "xs" ? "100%" : 320,
              overflowY: "scroll",
            }}
          >
            {studioSessionDashboard && studioSessionDashboard.length ? (
              <HiringDashboard
                studioSession={studioSession}
                studioSessionDashboard={studioSessionDashboard}
              />
            ) : (
              []
            )}
          </div>
        )}
        <ChoiceModal
          open={this.state.choiceOpen}
          onClose={() => this.setState({ choiceOpen: false })}
          title={
            <>
              <Typography variant="h6" display="block">
                You haven't set up your project's seating!
              </Typography>
              <Typography variant="body2" display="block" color="textSecondary">
                Save time using the Autofill feature with pre-defined musicians
                for this project.
              </Typography>
            </>
          }
          options={[
            {
              icon: <EmojiPeopleIcon />,
              color: "#2196f3",
              title: "Let's do it!",
              subtitle: "Set up the seating now",
              callback: () =>
                history.push(
                  `${PROJECT_DETAIL}?id=${studioSession.projectID}?index=1&instrumentFamiliesMenu`
                ),
            },
            {
              icon: <DnsIcon />,
              color: "#9e9e9e",
              title: "Not Interested",
              subtitle:
                "I want add select people manually for each work session.",
              callback: () =>
                this.setState({
                  choiceOpen: false,
                  projectMusicianAcknowledge: true,
                }),
            },
          ]}
        />
      </div>
    );
  }
}

export default withCookies(
  withWidth()(withStyles(styles, { withTheme: true })(Hiring))
);
