import { GET_GROUP_BY_ID } from "constants/groups";

export default (state = {}, action) => {
  switch (action.type) {
    case `${GET_GROUP_BY_ID}_SUCCESS`:
      const { payload } = action;
      return { ...state, [payload.id]: payload };
    default:
      return state;
  }
};
