import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// material-ui
import { withStyles } from "@material-ui/styles";
import PhoneInput from "hooks/PhoneInput";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class FormCustomer extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    customer: PropTypes.object,

    createCustomer: PropTypes.func,
    updateCustomer: PropTypes.func,
    deleteCustomer: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      address: "",
      state: "",
      city: "",
      zipcode: "",
      contact: "",
      phone: "",
      email: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.customer);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(customer) {
    this.setState({
      loading: false,
      name: customer ? customer.name : "",
      address: customer ? customer.address : "",
      state: customer ? customer.state : "",
      city: customer ? customer.city : "",
      zipcode: customer ? customer.zipcode : "",
      contact: customer ? customer.contact : "",
      phone: customer ? customer.phone : "",
      email: customer ? customer.email : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this customer, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteCustomer, close, refresh, customer } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteCustomer(customer.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Customer has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateCustomer, createCustomer, customer, refresh, close } =
      this.props;

    const { name, address, state, city, zipcode, contact, phone, email } =
      this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (customer) {
      resp = await updateCustomer(customer.id, {
        name,
        address,
        state,
        city,
        zipcode,
        contact,
        phone,
        email,
      });
    } else {
      resp = await createCustomer({
        name,
        address,
        state,
        city,
        zipcode,
        contact,
        phone,
        email,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: customer
          ? "Customer has been updated."
          : "Customer has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, customer, close } = this.props;

    const {
      name,
      address,
      city,
      zipcode,
      state,
      contact,
      phone,
      email,
      loading,
    } = this.state;

    const disabled = name.length === 0;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <DialogContent>
              <div
                style={{
                  background: "#e3f2fd",
                  padding: 16,
                  borderRadius: 4,
                  textAlign: "center",
                }}
              >
                <i
                  style={{ fontSize: 80, color: "#001E3C" }}
                  class="fad fa-building"
                ></i>
                <Typography style={{ color: "#001E3C" }} variant="h6">
                  New Customer
                </Typography>
                <Typography
                  style={{ color: "#001E3C", opacity: 0.8 }}
                  variant="body2"
                >
                  A customer is an entity you are working with.
                  <br />
                  You do projects for them.
                </Typography>
              </div>
              <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    autoFocus
                    helperText="Required"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="address"
                    label="Address"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={address}
                    onChange={this.handleChange("address")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="city"
                    label="City"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={city}
                    onChange={this.handleChange("city")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="zipcode"
                    label="Zipcode"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={zipcode}
                    onChange={this.handleChange("zipcode")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="state"
                    label="State"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={state}
                    onChange={this.handleChange("state")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="contact"
                    label="Contact"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={contact}
                    onChange={this.handleChange("contact")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    id="phone"
                    label="Phone"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={phone}
                    onChange={this.handleChange("phone")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={email}
                    onChange={this.handleChange("email")}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {customer && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                Cancel
              </Button>
              <Button
                style={{
                  background: "#2196f3",
                  color: "white",
                  opacity: disabled ? 0.5 : 1,
                }}
                autoFocus
                variant="contained"
                disabled={name.length === 0}
                onClick={this.save.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} />
                {customer ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormCustomer));
