import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// material-ui
import { withStyles } from "@material-ui/styles";
// config
import { GOOGLE_MAPS_GEOCODING_KEY } from "config";
// helpers
import { formatDate } from "helpers";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
// styles
import styles from "./styles";

class HiringDashboard extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    studioSessionDashboard: PropTypes.array,
    studioSession: PropTypes.object,
    app: PropTypes.object,
  };

  static contextTypes = {
    Notify: PropTypes.object,
  };

  render() {
    const {
      classes,
      studioSession,
      studioSessionDashboard,
      theme,
    } = this.props;

    const { Notify } = this.context;

    const hiring = studioSessionDashboard.find((ss) => ss.title === "Hiring");

    const hiringLabels = [];
    const hiringColors = [];
    const hiringSeries = [];

    if (hiring) {
      hiring.percent.forEach((h) => {
        hiringLabels.push(h.label);
        hiringColors.push(h.color);
        hiringSeries.push(h.value);
      });
    }

    const darkMode = theme.palette.type === "dark";

    return (
      <Grid container style={{ padding: "20px 20px 20px 18px" }}>
        <Grid item xs={12}>
          <Chip
            label={
              <span>
                <i class="fad fa-paper-plane p-right"></i> Notify Musicians
              </span>
            }
            disabled={studioSession.archived}
            onClick={() => Notify.open()}
            data-tut="reactour__notify"
            color="secondary"
            size="small"
            style={{ marginBottom: 16 }}
          />
        </Grid>
        <Grid item xs={12} className={classes.paper}>
          <Typography variant="caption" color="textSecondary">
            Recording
          </Typography>
          <Grid container justify="space-between" alignItems="baseline">
            <Grid item xs={6}>
              <Typography variant="h6" color="textPrimary">
                {moment(studioSession.dateFromUTC).fromNow()}
              </Typography>
            </Grid>
            {studioSession.tzName ||
            (studioSession.studio && studioSession.studio.tzName) ? (
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography variant="caption" color="textSecondary">
                  {formatDate(
                    studioSession.dateFromUTC,
                    studioSession.tzName || studioSession.studio.tzName
                  )}
                </Typography>
              </Grid>
            ) : (
              []
            )}
          </Grid>
          <Grid container justify="space-between">
            {studioSession.studio ? (
              <Grid item style={{ width: 80 }}>
                <div
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 4,
                    overflow: "hidden",
                  }}
                >
                  <img
                    alt="google-maps"
                    style={{ width: 80, height: 80 }}
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${studioSession.studio.latitude},${studioSession.studio.longitude}&zoom=15&size=200x230&maptype=roadmap&markers=color:red%7C${studioSession.studio.latitude},${studioSession.studio.longitude}&key=${GOOGLE_MAPS_GEOCODING_KEY}`}
                    x
                  />
                </div>
              </Grid>
            ) : (
              []
            )}
            <Grid
              item
              style={{ textAlign: "right", width: "calc(100% - 100px)" }}
            >
              {studioSession.studio ? (
                <div>
                  <Typography variant="body2" color="textPrimary">
                    {studioSession.studio.name}
                    <br />
                    {studioSession.studio.address}
                    <br />
                    {studioSession.studio.zipcode} {studioSession.studio.city}{" "}
                    {studioSession.studio.state}
                  </Typography>
                </div>
              ) : (
                <Typography variant="body2" color="textPrimary">
                  No Studio Selected
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.paper}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="caption" color="textSecondary">
                {studioSessionDashboard[0].title}
              </Typography>
            </Grid>
            <Grid item>
              {studioSessionDashboard[0].percent[0].value === 100 ? (
                <CheckCircleIcon style={{ color: "#4caf50" }} />
              ) : (
                []
              )}
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <div className={classes.circularContainer}>
                <CircularProgress
                  variant="static"
                  value={100}
                  className={classes.background}
                  size={75}
                  thickness={4}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className={classes.foreground}
                  size={75}
                  thickness={4}
                  value={studioSessionDashboard[0].percent[0].value}
                  style={{ color: studioSessionDashboard[0].percent[0].color }}
                />
                <img
                  alt="seat"
                  src={
                    "https://storage.googleapis.com/rhapsody/logos/chair-01.png"
                  }
                  style={{
                    height: 35,
                    filter: darkMode ? "invert(100%)" : undefined,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{
                      color: studioSessionDashboard[0].percent[0].color,
                    }}
                  >
                    {studioSessionDashboard[0].percent[0].value}%
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    Completed
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {studioSessionDashboard[0].count[0].value}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    {studioSessionDashboard[0].count[0].label}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {studioSessionDashboard[0].count[1].value}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    {studioSessionDashboard[0].count[1].label}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* {
            studioSessionDashboard[0].willNotify && studioSessionDashboard[0].willNotify.length
              ? (
                <Grid item xs={12}>
                  <Button
                    style={{
                      textTransform: 'initial',
                      padding: 6,
                      background: '#607d8b',
                      fontSize: 12,
                      width: '100%',
                      marginTop: 10,
                    }}
                    color="secondary"
                    variant={(studioSessionDashboard[0].willNotify && studioSessionDashboard[0].willNotify.length) ? 'contained' : 'text'}
                    onClick={() => onNotify(studioSessionDashboard[0].notifyID)}
                  >
                    {`${studioSessionDashboard[0].next.value} ${studioSessionDashboard[0].next.label} ${studioSessionDashboard[0].next.action}`}
                  </Button>
                </Grid>
              ) : []
          } */}
        </Grid>
        <Grid item xs={12} className={classes.paper}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="caption" color="textSecondary">
                {studioSessionDashboard[1].title}
              </Typography>
            </Grid>
            <Grid item>
              {studioSessionDashboard[1].percent[1].value === 100 ? (
                <CheckCircleIcon style={{ color: "#4caf50" }} />
              ) : (
                []
              )}
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <div className={classes.circularContainer}>
                <CircularProgress
                  variant="static"
                  value={100}
                  className={classes.background}
                  size={75}
                  thickness={4}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className={classes.foreground}
                  size={75}
                  thickness={4}
                  value={studioSessionDashboard[1].percent[0].value}
                  style={{
                    color: studioSessionDashboard[1].percent[0].color,
                    filter: "brightness(130%)",
                  }}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className={classes.foreground}
                  size={75}
                  thickness={4}
                  value={studioSessionDashboard[1].percent[1].value}
                  style={{
                    color: studioSessionDashboard[1].percent[1].color,
                  }}
                />
                <img
                  alt="invitation"
                  src={
                    "https://storage.googleapis.com/rhapsody/logos/paper-plane-01.png"
                  }
                  style={{
                    height: 28,
                    filter: darkMode ? "invert(100%)" : undefined,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{
                      color: studioSessionDashboard[1].percent[0].color,
                      filter: "brightness(130%)",
                    }}
                  >
                    {studioSessionDashboard[1].percent[0].value}%
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    Invited
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {studioSessionDashboard[1].count[0].value}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    {studioSessionDashboard[1].count[0].label}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{
                      color: studioSessionDashboard[1].percent[0].color,
                    }}
                  >
                    {studioSessionDashboard[1].percent[1].value}%
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    Responded
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {studioSessionDashboard[1].next.value}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    {studioSessionDashboard[1].next.action}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* {
            studioSessionDashboard[1].willNotify && studioSessionDashboard[1].willNotify.length
              ? (
                <Grid item xs={12}>
                  <Button
                    style={{
                      textTransform: 'initial',
                      padding: 6,
                      background: studioSessionDashboard[1].percent[0].color,
                      fontSize: 12,
                      width: '100%',
                      marginTop: 10,
                    }}
                    color="secondary"
                    variant={(studioSessionDashboard[1].willNotify && studioSessionDashboard[1].willNotify.length) ? 'contained' : 'text'}
                    onClick={() => onNotify(studioSessionDashboard[1].notifyID)}
                  >
                    {`${studioSessionDashboard[1].next.value} ${studioSessionDashboard[1].next.label} ${studioSessionDashboard[1].next.action}`}
                  </Button>
                </Grid>
              ) : []
          } */}
        </Grid>
        <Grid item xs={12} className={classes.paper}>
          <Typography variant="caption" color="textSecondary">
            {studioSessionDashboard[2].title}
          </Typography>
          <Chart
            options={{
              labels: hiringLabels,
              dataLabels: {
                enabled: false,
              },
              plotOptions: {
                pie: {
                  expandOnClick: false,
                  customScale: 0.8,
                },
              },
              stroke: {
                show: false,
                width: 0,
              },
              tooltip: {
                enabled: true,
                y: {
                  formatter: (val) => `${val}%`,
                },
              },
              legend: {
                position: "right",
                show: false,
                offsetY: 0,
                color: "right",
                labels: {
                  colors: [
                    theme.palette.type === "darkMode" ? "white" : "black",
                  ],
                },
              },
              colors: hiringColors,
            }}
            series={hiringSeries}
            type={"donut"}
            height={200}
          />
          <div style={{ marginTop: -40 }}>
            {studioSessionDashboard[2].count.map((e) => (
              <Grid container justify="space-between">
                <Grid item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border:
                        e.label === "Pending"
                          ? "solid 1px rgba(155,155,155, 0.3)"
                          : undefined,
                      borderRadius: 20,
                      padding: "0px 6px 0px 6px",
                      filter:
                        darkMode && e.label === "Pending"
                          ? "invert(100%)"
                          : undefined,
                    }}
                  >
                    <img
                      src={e.icon}
                      style={{ height: 14, marginRight: 4 }}
                      alt="seat"
                    />
                    <Typography
                      style={{
                        color: e.color,
                      }}
                    >
                      {e.label}
                    </Typography>
                  </div>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 600 }} color="textPrimary">
                    {e.value} Musician{e.value > 1 ? "s" : ""}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </div>
          {/* {
            studioSessionDashboard[2].willNotify && studioSessionDashboard[2].willNotify.length
              ? (
                <Grid item xs={12}>
                  <Button
                    style={{
                      textTransform: 'initial',
                      padding: 6,
                      background: '#009688',
                      fontSize: 12,
                      width: '100%',
                      marginTop: 10,
                    }}
                    color="secondary"
                    variant={(studioSessionDashboard[2].willNotify && studioSessionDashboard[2].willNotify.length) ? 'contained' : 'text'}
                    onClick={() => onNotify(studioSessionDashboard[2].notifyID)}
                  >
                    {`${studioSessionDashboard[2].next.value} ${studioSessionDashboard[2].next.label} ${studioSessionDashboard[2].next.action}`}
                  </Button>
                </Grid>
              ) : []
          } */}
        </Grid>
        <Grid item xs={12} className={classes.paper}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="caption" color="textSecondary">
                {studioSessionDashboard[3].title}
              </Typography>
            </Grid>
            <Grid item>
              {studioSessionDashboard[3].percent[1].value === 100 ? (
                <CheckCircleIcon style={{ color: "#4caf50" }} />
              ) : (
                []
              )}
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <div className={classes.circularContainer}>
                <CircularProgress
                  variant="static"
                  value={100}
                  className={classes.background}
                  size={75}
                  thickness={4}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className={classes.foreground}
                  size={75}
                  thickness={4}
                  value={studioSessionDashboard[3].percent[0].value}
                  style={{
                    color: studioSessionDashboard[3].percent[0].color,
                  }}
                />
                <CircularProgress
                  variant="static"
                  disableShrink
                  className={classes.foreground}
                  size={75}
                  thickness={4}
                  value={studioSessionDashboard[3].percent[1].value}
                  style={{
                    color: studioSessionDashboard[3].percent[1].color,
                    filter: "brightness(130%)",
                  }}
                />
                <img
                  alt="seat"
                  src={
                    "https://storage.googleapis.com/rhapsody/logos/reminder-01.png"
                  }
                  style={{
                    height: 35,
                    filter: darkMode ? "invert(100%)" : undefined,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{
                      color: studioSessionDashboard[3].percent[0].color,
                    }}
                  >
                    {studioSessionDashboard[3].percent[0].value}%
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    {studioSessionDashboard[3].percent[0].label}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {studioSessionDashboard[3].count[0].value}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    {studioSessionDashboard[3].count[0].label}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="baseline">
                <Grid item style={{ filter: "brightness(130%)" }}>
                  <Typography
                    variant="h6"
                    style={{
                      color: studioSessionDashboard[3].percent[0].color,
                    }}
                  >
                    {studioSessionDashboard[3].percent[1].value}%
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    {studioSessionDashboard[3].percent[1].label}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {studioSessionDashboard[3].count[1].value}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ paddingLeft: 4 }}
                  >
                    {studioSessionDashboard[3].count[1].label}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* {
            studioSessionDashboard[3].willNotify && studioSessionDashboard[3].willNotify.length
              ? (
                <Grid item xs={12}>
                  <Button
                    style={{
                      textTransform: 'initial',
                      padding: 6,
                      background: studioSessionDashboard[3].percent[0].color,
                      fontSize: 12,
                      width: '100%',
                      marginTop: 10,
                    }}
                    color="secondary"
                    variant={(studioSessionDashboard[3].willNotify && studioSessionDashboard[3].willNotify.length) ? 'contained' : 'text'}
                    onClick={() => onNotify(studioSessionDashboard[3].notifyID)}
                  >
                    {`${studioSessionDashboard[3].next.value} ${studioSessionDashboard[3].next.label} ${studioSessionDashboard[3].next.action}`}
                  </Button>
                </Grid>
              ) : []
          } */}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HiringDashboard);
