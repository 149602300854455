// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import TableCoupon from "components/TableCoupon";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class CouponsList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    coupons: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
    noPaper: PropTypes.bool,
    noTitle: PropTypes.bool,
  };

  onSelect(selectedCoupon) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCoupon);
    }
  }

  render() {
    const { coupons, noTitle, noPaper } = this.props;

    return (
      <Table
        data={coupons}
        meta={[
          {
            path: "token",
            title: "Token",
            numeric: false,
            component: TableCoupon,
          },
          {
            path: "percent",
            title: "Percent Off",
            numeric: false,
            postfix: "% Off",
          },
          {
            path: "maxCount",
            title: "Usage",
            numeric: false,
            transform: (maxCount, datum) => `${datum.useCount}/${maxCount}`,
          },
          {
            path: "expirationDate",
            title: "Expiration Date",
            transform: (text) => moment(text).calendar(),
            numeric: false,
          },
        ]}
        noPaper={noPaper}
        title={noTitle ? undefined : "Coupons"}
        onRowSelect={(p) => console.log(p)}
      />
    );
  }
}

export default withStyles(styles)(CouponsList);
