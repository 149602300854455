// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperPayrollPage from "../component/WrapperPayrollPage";

const mapStateToProps = (state) => ({
  payrolls: state.payrolls,
});

const mapDispatchToProps = (dispatch) => ({
  getPayrolls: () => dispatch(Actions.getPayrolls()),
  getPayrollById: (...args) => dispatch(Actions.getPayrollById(...args)),
  createPayroll: (...args) => dispatch(Actions.createPayroll(...args)),
  deletePayroll: (...args) => dispatch(Actions.deletePayroll(...args)),
  updatePayroll: (...args) => dispatch(Actions.updatePayroll(...args)),
});
class PayrollPage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    payrolls: PropTypes.array,
    getPayrolls: PropTypes.func,
    getPayrollById: PropTypes.func,
    createPayroll: PropTypes.func,
    deletePayroll: PropTypes.func,
    updatePayroll: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, payrolls } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: payrolls.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getPayrolls } = this.props;
    await Promise.all([getPayrolls()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      payrolls,
      getPayrollById,
      createPayroll,
      deletePayroll,
      updatePayroll,
    } = this.props;

    const { loading } = this.state;

    return (
      <WrapperPayrollPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        payrolls={payrolls}
        getPayrollById={getPayrollById}
        createPayroll={createPayroll}
        deletePayroll={deletePayroll}
        updatePayroll={updatePayroll}
        urlParams={getJsonFromUrl(window.location)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PayrollPage);
