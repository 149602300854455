import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "rhapsody";
const layoutPresets = `${REACT_APP_API_PREFIX}/${service}/layoutPresets`;

export function getLayoutPresets(filters) {
  const url = `${layoutPresets}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getLayoutPresetById(id) {
  const url = `${layoutPresets}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateLayoutPreset(id, body) {
  const url = `${layoutPresets}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteLayoutPreset(id) {
  const url = `${layoutPresets}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createLayoutPreset(body) {
  const url = `${layoutPresets}`;
  return {
    method: POST,
    url,
    body,
  };
}
