// import PropTypes from 'prop-types';
// material-ui
import { Popover, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import * as Actions from "actions";
import { WIDGET } from "config";
import IframeResizer from "iframe-resizer-react";
import React from "react";
import { useDispatch } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function MercurySessionGlance({ workSession, centrifuge }) {
  const [stats, setStats] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const classes = useStyles(styles);
  const theme = useTheme();

  React.useEffect(() => {
    refresh();
    centrifuge.subscribe(`rhapsody:${workSession.publicToken}`, refresh);
  }, []); //eslint-disable-line

  function refresh() {
    dispatch(Actions.getStudioSessionMercury(workSession.id)).then((r) =>
      setStats(r.payload)
    );
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (workSession.standalone) {
    return (
      <Typography variant="caption">N/A - Standalone Payroll Form</Typography>
    );
  }

  return (
    <div
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      style={{ cursor: "pointer", display: "inline-block" }}
    >
      <Typography variant="caption" color="textSecondary">
        {workSession.musicianCount} Musician
        {workSession.musicianCount > 1 ? "s" : ""}
      </Typography>
      <div style={{ display: "flex" }}>
        {stats?.currentStages
          .filter((s) => s.jobCount)
          .map((s) => (
            <Tooltip title={`${s.jobCount} ${s.name}`}>
              <div
                key={s.id}
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  marginRight: 4,
                  background: s.color,
                }}
              />
            </Tooltip>
          ))}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div style={{ width: 400, position: "relative" }}>
          <IframeResizer
            frameborder="0"
            src={`${WIDGET}?workSessionID=${workSession.id}&token=${
              workSession.publicToken
            }${theme.palette.type === "dark" ? "&dark" : ""}`}
            style={{ width: "1px", minWidth: "100%" }}
          />
        </div>
      </Popover>
    </div>
  );
}
