export const serviceRoles = {
  account: {
    name: "account",
    roles: {
      any: "any",
      anon: "anon",
      user: "user",
      admin: "admin",
      superadmin: "superadmin",
    },
  },
  rhapsody: {
    name: "rhapsody",
    roles: {
      any: "any",
      anon: "anon",
      user: "user",
      scheduler: "scheduler",
      admin: "admin",
      superadmin: "superadmin",
      concierge: "concierge",
    },
  },
};
