import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import OpenInNew from "@material-ui/icons/OpenInNew";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class LinkChip extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    link: PropTypes.string,
    icon: PropTypes.node,
    label: PropTypes.string,
    noWrap: PropTypes.bool,
  };

  state = {
    hover: false,
  };

  render() {
    const { classes, link, history, icon, label, noWrap } = this.props;

    const { hover } = this.state;

    return (
      <Chip
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        label={
          hover //eslint-disable-line
            ? label
            : label && label.length > 20 && !noWrap
            ? `${label.substring(0, 20)}...`
            : label
        }
        className={classes.chip}
        classes={{
          avatar: classes.root,
          label: classes.root,
        }}
        onClick={link ? () => history.push(link) : undefined}
        variant="outlined"
        avatar={<Avatar className={classes.avatar}>{icon}</Avatar>}
        onDelete={hover && link ? () => window.open(link, "_blank") : undefined}
        deleteIcon={
          <OpenInNew style={{ height: 20, width: 20, color: "#9e9e9e" }} />
        }
      />
    );
  }
}

export default withStyles(styles)(LinkChip);
