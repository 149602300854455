import { Chip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import { PAYROLL } from "constants/routes";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class PayrollsList extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    payrolls: PropTypes.array,
    onSelect: PropTypes.func,
  };

  static contextTypes = {
    TourCenter: PropTypes.array,
  };

  onSelect(selectedPayroll) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedPayroll);
    }
  }

  render() {
    const { payrolls, history } = this.props;
    const { TourCenter } = this.context;

    if (payrolls.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              <i style={{ fontSize: 70 }} class="fad fa-university"></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No Payrolls
            </Typography>
            <Typography variant="caption" color="textSecondary">
              This is the entity that handles your payroll.
            </Typography>
          </Grid>
          <Grid item>
            <Chip onClick={() => TourCenter.start(2)} label="+ New Payroll" />
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={payrolls}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "address",
            title: "Address",
            hidden: { smDown: true },
            numeric: false,
            render: (t, d) => (
              <React.Fragment>
                <Typography variant="body2">{d.address}</Typography>
                <Typography variant="body2">
                  {d.zipcode} {d.city} {d.state}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "contact",
            title: "Contact",
            hidden: { smDown: true },
            numeric: false,
            render: (t, d) => (
              <React.Fragment>
                <Typography variant="body2">{d.contact}</Typography>
                <Typography variant="body2">{d.email}</Typography>
              </React.Fragment>
            ),
          },
        ]}
        title={"Payrolls"}
        onRowSelect={(project) => history.push(`${PAYROLL}?id=${project.id}`)}
      />
    );
  }
}

export default withStyles(styles)(PayrollsList);
