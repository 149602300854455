import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GraphicEq from "@material-ui/icons/GraphicEqTwoTone";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/TablePagination";
// constants
import { STATEMENT } from "constants/routes";
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class StatementsList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    statements: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
  };

  onSelect(selectedStatement) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedStatement);
    }
  }

  render() {
    const { statements, history } = this.props;

    if (statements.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              <i style={{ fontSize: 70 }} class="fad fa-analytics"></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No Statements
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Keep track of your usage of Rhapsody.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={statements}
        rowStyle={(d) => ({
          borderLeft:
            d.invoiceID === null ? "solid 3px #ffc107" : "solid 3px #9e9e9e",
        })}
        meta={[
          {
            path: "id",
            title: "ID",
            width: 30,
          },
          {
            path: "id",
            numeric: false,
            render: () => <GraphicEq />,
            hidden: { smDown: true },
            width: 20,
          },
          {
            path: "creationDate",
            title: "Creation",
            hidden: { smDown: true },
            numeric: false,
            transform: (t) => moment(t).format("MMMM YYYY"),
            render: (t, d) => (
              <React.Fragment>
                <Typography variant="body2">{t}</Typography>
                <Typography variant="caption" color="textSecondary">
                  Created on {moment(d.creationDate).calendar()}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "total",
            numeric: true,
            transform: (t) => `USD ${formattedPrice(t)}`,
          },
        ]}
        title={"Statements"}
        onRowSelect={(p) => history.push(`${STATEMENT}?id=${p.id}`)}
      />
    );
  }
}

export default withStyles(styles)(StatementsList);
