import Popover from "@material-ui/core/Popover";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { TwitterPicker } from "react-color";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ColorPicker({ onChange, label, color, disabled }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [col, setCol] = React.useState(
    color && color.length && color !== "none" ? color : "#000000"
  );
  const classes = useStyles(styles);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {label
        ? [
            <Typography color="textSecondary" variant="caption">
              {label}
            </Typography>,
            <br />,
          ]
        : []}
      <div
        className={classes.swatch}
        style={{
          marginTop: label ? 6 : 0,
        }}
        onClick={handleClick}
      >
        <div className={classes.color} style={{ background: col }} />
      </div>
      <Popover
        id={id}
        open={open && !disabled}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <TwitterPicker
          color={col}
          colors={[
            "#3F51B5",
            "#2196F3",
            "#009688",
            "#4CAF50",
            "#CDDC39",
            "#FF9800",
            "#E91E63",
            "#F44336",
            "#607D8B",
            "#00BCD4",
          ]}
          onChangeComplete={(c) => onChange(c.hex)}
          onChange={(c) => {
            setCol(c.hex);
          }}
        />
      </Popover>
    </div>
  );
}
