import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class FormEventStatuse extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    eventStatuse: PropTypes.object,

    createEventStatuse: PropTypes.func,
    updateEventStatuse: PropTypes.func,
    deleteEventStatuse: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      color: "",
      colorRead: "",
      mercuryStageID: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.eventStatuse);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  init(eventStatuse) {
    this.setState({
      loading: false,
      name: eventStatuse ? eventStatuse.name : "",
      icon: eventStatuse ? eventStatuse.icon : "",
      mercuryStageID: eventStatuse ? eventStatuse.mercuryStageID : "",
      color: eventStatuse ? eventStatuse.color : "",
      colorRead: eventStatuse ? eventStatuse.colorRead : "",
      showDetails: eventStatuse ? eventStatuse.showDetails : false,
      title: eventStatuse ? eventStatuse.title : "",
      actionName: eventStatuse ? eventStatuse.actionName : "",
      actionMessage: eventStatuse ? eventStatuse.actionMessage : "",
      actionText: eventStatuse ? eventStatuse.actionText : "",
      actionLink: eventStatuse ? eventStatuse.actionLink : "",
      defaultGreeting: eventStatuse ? eventStatuse.defaultGreeting : "",
      minSubscriptionID: eventStatuse ? eventStatuse.minSubscriptionID : "",
      defaultNotes: eventStatuse ? eventStatuse.defaultNotes : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this eventStatuse, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteEventStatuse, close, refresh, eventStatuse } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteEventStatuse(eventStatuse.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "EventStatuse has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateEventStatuse,
      createEventStatuse,
      eventStatuse,
      refresh,
      close,
    } = this.props;

    const {
      name,
      icon,
      mercuryStageID,
      color,
      colorRead,
      showDetails,
      title,
      actionName,
      actionMessage,
      actionText,
      actionLink,
      minSubscriptionID,
      defaultGreeting,
      defaultNotes,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (eventStatuse) {
      resp = await updateEventStatuse(eventStatuse.id, {
        name,
        icon,
        color,
        colorRead,
        showDetails,
        title,
        actionName,
        actionMessage,
        actionText,
        actionLink,
        defaultGreeting,
        defaultNotes,
        mercuryStageID: Number(mercuryStageID),
        minSubscriptionID: Number(minSubscriptionID),
      });
    } else {
      resp = await createEventStatuse({
        name,
        icon,
        color,
        colorRead,
        showDetails,
        title,
        actionName,
        actionMessage,
        actionText,
        minSubscriptionID: Number(minSubscriptionID),
        actionLink,
        defaultGreeting,
        defaultNotes,
        mercuryStageID: Number(mercuryStageID),
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: eventStatuse
          ? "EventStatuse has been updated."
          : "EventStatuse has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, eventStatuse, close } = this.props;

    const {
      name,
      icon,
      mercuryStageID,
      color,
      colorRead,
      showDetails,
      title,
      actionName,
      actionMessage,
      actionText,
      actionLink,
      defaultGreeting,
      defaultNotes,
      minSubscriptionID,
      loading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {eventStatuse
                ? `${eventStatuse.name}`
                : "Let's Create a New EventStatuse"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {eventStatuse
                  ? "To update the eventStatuse, fill the form below :"
                  : "To create a new eventStatuse, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="actionName"
                    label="Action"
                    className={classes.textField}
                    value={actionName}
                    onChange={this.handleChange("actionName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="icon"
                    label="Icon"
                    className={classes.textField}
                    value={icon}
                    onChange={this.handleChange("icon")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="color"
                    label="Color"
                    className={classes.textField}
                    value={color}
                    onChange={this.handleChange("color")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="colorRead"
                    label="Read Color"
                    className={classes.textField}
                    value={colorRead}
                    onChange={this.handleChange("colorRead")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="minSubscriptionID"
                    label="Min Subscription ID"
                    className={classes.textField}
                    value={minSubscriptionID}
                    onChange={this.handleChange("minSubscriptionID")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="mercuryStageID"
                    label="Stage ID"
                    className={classes.textField}
                    value={mercuryStageID}
                    onChange={this.handleChange("mercuryStageID")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" color="textPrimary">
                    Email Content
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="title"
                    label="Subject"
                    className={classes.textField}
                    value={title}
                    onChange={this.handleChange("title")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="defaultGreeting"
                    label="Default Greeting"
                    className={classes.textField}
                    value={defaultGreeting}
                    multiline
                    onChange={this.handleChange("defaultGreeting")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="actionMessage"
                    label="Body"
                    className={classes.textField}
                    value={actionMessage}
                    multiline
                    onChange={this.handleChange("actionMessage")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="defaultNotes"
                    label="Default Notes"
                    className={classes.textField}
                    value={defaultNotes}
                    multiline
                    onChange={this.handleChange("defaultNotes")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="actionText"
                    label="Button Label"
                    className={classes.textField}
                    value={actionText}
                    multiline
                    onChange={this.handleChange("actionText")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="actionLink"
                    label="Button Route"
                    className={classes.textField}
                    value={actionLink}
                    multiline
                    onChange={this.handleChange("actionLink")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showDetails}
                        onChange={this.handleChange("showDetails")}
                        value="showDetails"
                      />
                    }
                    label="Show Details"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {eventStatuse && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {eventStatuse ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormEventStatuse));
