import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Hidden,
  Paper,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/TablePagination";
import { getJsonFromUrl, updateURLParameter } from "helpers";
import OrganizationDetail from "hooks/OrganizationDetail";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class OrganizationsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    joinOrganization: PropTypes.func,
    organizations: PropTypes.array,
    user: PropTypes.array,
    admin: PropTypes.bool,
    selectedOrganization: PropTypes.object,
    onSelect: PropTypes.func,
    history: PropTypes.object,
    refresh: PropTypes.func,
    getOrganizations: PropTypes.func,
    api: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      selectedOrganization: undefined,
      organizationLoading: urlParams.organizationID,
    };
  }

  componentWillReceiveProps(nextProps) {
    const urlParams = getJsonFromUrl(window.location);

    if (
      urlParams.organizationID &&
      this.state.selectedOrganization === undefined
    ) {
      this.setState({ organizationLoading: true });
      nextProps.api
        .getOrganizationById(Number(urlParams.organizationID))
        .then((r) =>
          this.setState({
            selectedOrganization: r.payload,
            organizationLoading: false,
          })
        );
    }
  }

  onSelect(selectedOrganization) {
    this.setState({
      selectedOrganization,
    });
  }

  render() {
    const { organizations, user, api, history } = this.props;
    const { organizationLoading, selectedOrganization } = this.state;

    const { NotificationCenter } = this.context;

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Table
              data={organizations}
              rowStyle={(d) => ({
                borderLeft:
                  d && d.ownerID === user.id
                    ? "solid 3px #2196f3"
                    : "solid 3px #9e9e9e",
                background:
                  selectedOrganization && selectedOrganization.id === d.id
                    ? "rgba(33,150,243, 0.1)"
                    : undefined,
              })}
              meta={[
                {
                  path: "id",
                  title: "ID",
                  width: 30,
                },
                {
                  path: "logo",
                  numeric: false,
                  width: 25,
                  render: (t, d) => (
                    <>
                      {d.logo ? (
                        <img src={d.logo} alt="logo" style={{ height: 25 }} />
                      ) : (
                        <i class="fad fa-globe"></i>
                      )}
                    </>
                  ),
                },
                {
                  path: "name",
                  title: "Name",
                  numeric: false,
                  render: (t) => (
                    <Typography display="block" variant="body2">
                      {t}
                    </Typography>
                  ),
                },
              ]}
              title={"Organizations"}
              onRowSelect={(e) => {
                this.setState({
                  selectedOrganization: e,
                  organizationDetailOpen: true,
                });
                history.push({
                  search: updateURLParameter("organizationID", e.id),
                });
              }}
            />
          </Grid>
          <Hidden smDown>
            <Grid item xs={6}>
              {selectedOrganization ? (
                <Paper style={{ padding: 20 }}>
                  <OrganizationDetail
                    organization={selectedOrganization}
                    key={selectedOrganization && selectedOrganization.id}
                    api={api}
                    NotificationCenter={NotificationCenter}
                    loading={organizationLoading}
                    history={history}
                  />
                </Paper>
              ) : (
                []
              )}
            </Grid>
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Dialog
            open={this.state.organizationDetailOpen}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <OrganizationDetail
                organization={selectedOrganization}
                key={selectedOrganization && selectedOrganization.id}
                api={api}
                NotificationCenter={NotificationCenter}
                loading={organizationLoading}
                history={history}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => this.setState({ organizationDetailOpen: false })}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(OrganizationsList);
