// actions
import * as Actions from "actions";
import MercuryNotify from "components/MercuryNotify";
// helpers
import { getJsonFromUrl, updateURLParameter } from "helpers";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperProjectPage from "../component/WrapperProjectPage";

const mapStateToProps = (state) => ({
  user: state.user,
  sessionTypes: state.sessionTypes,
  payrolls: state.payrolls,
  customers: state.customers,
  studios: state.studios,
  sectionRoles: state.sectionRoles,
  layoutPresets: state.layoutPresets,
  currentCompany: state.currentCompany,
  configurations: state.configurations,
  app: state.app,
  projectHiring: state.projectHiring,
});

const mapDispatchToProps = (dispatch) => ({
  getStages: (...args) => dispatch(Actions.getStages(...args)),
  getProjectById: (...args) => dispatch(Actions.getProjectById(...args)),
  getProjectStatuses: (...args) =>
    dispatch(Actions.getProjectStatuses(...args)),
  getCustomers: (...args) => dispatch(Actions.getCustomers(...args)),
  getPayrolls: (...args) => dispatch(Actions.getPayrolls(...args)),
  getProjectStudioSessions: (...args) =>
    dispatch(Actions.getProjectStudioSessions(...args)),
  updateProject: (...args) => dispatch(Actions.updateProject(...args)),
  getSessionTypes: (...args) => dispatch(Actions.getSessionTypes(...args)),
  getSectionRoles: (...args) => dispatch(Actions.getSectionRoles(...args)),
  getStudios: (...args) => dispatch(Actions.getStudios(...args)),
  uploadProjectLogo: (...args) => dispatch(Actions.uploadProjectLogo(...args)),
  getProjects: (...args) => dispatch(Actions.getProjects(...args)),
  getProjectAlternates: (...args) =>
    dispatch(Actions.getProjectAlternates(...args)),
  createProjectAlternate: (...args) =>
    dispatch(Actions.createProjectAlternate(...args)),
  deleteProjectAlternate: (...args) =>
    dispatch(Actions.deleteProjectAlternate(...args)),
  updateProjectAlternate: (...args) =>
    dispatch(Actions.updateProjectAlternate(...args)),
  getProjectSections: (...args) =>
    dispatch(Actions.getProjectSections(...args)),
  getInstrumentSections: (...args) =>
    dispatch(Actions.getInstrumentSections(...args)),
  getInstrumentFamilies: (...args) =>
    dispatch(Actions.getInstrumentFamilies(...args)),
  deleteProjectSection: (...args) =>
    dispatch(Actions.deleteProjectSection(...args)),
  getLayoutPresets: (...args) => dispatch(Actions.getLayoutPresets(...args)),
  createLayoutPreset: (...args) =>
    dispatch(Actions.createLayoutPreset(...args)),
  deleteLayoutPreset: (...args) =>
    dispatch(Actions.deleteLayoutPreset(...args)),
  getLayoutPresetById: (...args) =>
    dispatch(Actions.getLayoutPresetById(...args)),
  createStudioSession: (...args) =>
    dispatch(Actions.createStudioSession(...args)),
  createEngagement: (...args) => dispatch(Actions.createEngagement(...args)),
  createStudio: (...args) => dispatch(Actions.createStudio(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),
  getConfigurations: (...args) => dispatch(Actions.getConfigurations(...args)),
  projectAutofill: (...args) => dispatch(Actions.projectAutofill(...args)),
  getInstrumentSectionProjectMusicians: (...args) =>
    dispatch(Actions.getInstrumentSectionProjectMusicians(...args)),
  setSelectedCalendarDay: (...args) =>
    dispatch(Actions.setSelectedCalendarDay(...args)),
  setProjectHiringSelection: (...args) =>
    dispatch(Actions.setProjectHiringSelection(...args)),
});
class ProjectPage extends PureComponent {
  static propTypes = {
    getProjectById: PropTypes.func,
    setSelectedCalendarDay: PropTypes.func,
    getProjectStudioSessions: PropTypes.func,
    updateProject: PropTypes.func,
    history: PropTypes.object,
    getLayoutPresets: PropTypes.func,
    getProjectStatuses: PropTypes.func,
    createLayoutPreset: PropTypes.func,
    deleteLayoutPreset: PropTypes.func,
    getLayoutPresetById: PropTypes.func,
    layoutPresets: PropTypes.array,
    location: PropTypes.object,
    customers: PropTypes.array,
    sessionTypes: PropTypes.array,
    payrolls: PropTypes.array,
    studios: PropTypes.array,
    getStudios: PropTypes.func,
    uploadProjectLogo: PropTypes.func,
    projects: PropTypes.array,
    getInstrumentSections: PropTypes.func,
    getInstrumentFamilies: PropTypes.func,
    getCustomers: PropTypes.func,
    getProjectAlternates: PropTypes.func,
    projectAutofill: PropTypes.func,
    createProjectAlternate: PropTypes.func,
    deleteProjectAlternate: PropTypes.func,
    deleteProjectSection: PropTypes.func,
    updateProjectAlternate: PropTypes.func,
    createStudioSession: PropTypes.func,
    createEngagement: PropTypes.func,
    createStudio: PropTypes.func,
    getInstrumentSectionProjectMusicians: PropTypes.func,
    getProjectSections: PropTypes.func,
    getSectionRoles: PropTypes.func,
    getCurrentCompany: PropTypes.func,
    currentCompany: PropTypes.object,
    user: PropTypes.object,
    getConfigurations: PropTypes.array,
    sectionRoles: PropTypes.array,
    configurations: PropTypes.array,
    app: PropTypes.object,
    setProjectHiringSelection: PropTypes.func,
    projectHiring: PropTypes.object,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const {
      getLayoutPresets,
      getConfigurations,
      getStudios,
      history,
      app,
      getStages,
      getSessionTypes,
      getPayrolls,
      getCustomers,
      getSectionRoles,
      getCurrentCompany,
    } = this.props;

    const { WebsocketCenter } = this.context;

    const urlParams = getJsonFromUrl(window.location);
    if (app.selectedCalendarDay[Number(urlParams.id)]) {
      history.push({
        search: updateURLParameter(
          "date",
          app.selectedCalendarDay[urlParams.id]
        ),
      });
    }
    this.state = {
      projectID: urlParams.id,
      project: {},
      studioSessions: [],
      urlParams,
      loading: true,
    };
    getLayoutPresets();
    getConfigurations();
    getStages();
    getStudios();
    getSessionTypes(),
      getPayrolls(),
      getCustomers(),
      getSectionRoles(),
      getCurrentCompany(),
      this.refresh();
  }

  componentWillUnmount() {
    const { setProjectHiringSelection } = this.props;
    setProjectHiringSelection({
      selectedMusicians: [],
      selectedSessions: [],
    });
  }

  async refresh() {
    const { history, getProjectById, getProjectStudioSessions } = this.props;
    const { WebsocketCenter } = this.context;
    const { projectID } = this.state;
    const resps = await Promise.all([
      getProjectById(projectID),
      getProjectStudioSessions(projectID),
    ]);

    const project = resps[0].payload;

    if (!this.subscription) {
      this.subscription = WebsocketCenter.subscribe(
        `rhapsody:${project.publicToken}`
      );
    }

    const urlParams = getJsonFromUrl(window.location);
    let studiosSessions = resps[1].payload;
    if (!urlParams.date && studiosSessions.length) {
      studiosSessions = studiosSessions.sort(
        (a, b) => moment(a.dateFromUTC).unix() - moment(b.dateFromUTC).unix()
      );
      let upcomingDate = studiosSessions[0].dateFromUTC || moment().utc();
      const nowUTC = moment().utc();
      for (const key in studiosSessions) {
        if (studiosSessions.hasOwnProperty(key)) {
          const ss = studiosSessions[key];
          if (nowUTC.isBefore(moment(ss.dateFromUTC))) {
            upcomingDate = ss.dateFromUTC;
            break;
          }
        }
      }

      urlParams.date = upcomingDate;
      const search = Object.keys(urlParams)
        .map(
          (k) => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`
        )
        .join("&");
      history.push({
        search,
      });
    }

    this.setState({
      project: project,
      studioSessions: resps[1].payload,
      refreshKey: Date.now(),
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      updateProject,
      customers,
      sessionTypes,
      payrolls,
      studios,
      projectAutofill,
      user,
      projects,
      deleteProjectSection,
      getProjectAlternates,
      createProjectAlternate,
      deleteProjectAlternate,
      updateProjectAlternate,
      getInstrumentSections,
      getInstrumentFamilies,
      uploadProjectLogo,
      getProjectSections,
      sectionRoles,
      getInstrumentSectionProjectMusicians,
      getLayoutPresets,
      createLayoutPreset,
      deleteLayoutPreset,
      getLayoutPresetById,
      layoutPresets,
      createStudioSession,
      createEngagement,
      createStudio,
      getStudios,
      configurations,
      currentCompany,
      setSelectedCalendarDay,
      projectHiring,
    } = this.props;

    const { urlParams, project, studioSessions, loading, refreshKey } =
      this.state;

    const api = {
      getProjectAlternates,
      createProjectAlternate,
      deleteProjectAlternate,
      updateProjectAlternate,
      deleteProjectSection,
      uploadProjectLogo,
      updateProject,
      projectAutofill,
      getInstrumentSections,
      getInstrumentFamilies,
      getProjectSections,
      getInstrumentSectionProjectMusicians,
      getLayoutPresets,
      createLayoutPreset,
      deleteLayoutPreset,
      getLayoutPresetById,
      getStudios,
      createStudioSession,
      createEngagement,
      createStudio,
      setSelectedCalendarDay,
    };

    let conciergeReadOnly =
      project?.concierge === true &&
      !(
        user.services.account.indexOf("superadmin") !== -1 ||
        user.services.rhapsody.indexOf("concierge") !== -1
      );

    return (
      <MercuryNotify
        project={project}
        refresh={() => {
          console.log("MercuryNotifier refresh request");
        }}
      >
        <WrapperProjectPage
          studios={studios}
          sessionTypes={sessionTypes}
          history={history}
          location={location}
          urlParams={urlParams}
          customers={customers}
          projectHiring={projectHiring}
          project={project}
          conciergeReadOnly={conciergeReadOnly}
          sectionRoles={sectionRoles}
          layoutPresets={layoutPresets}
          configurations={configurations}
          studioSessions={studioSessions}
          currentCompany={currentCompany}
          loading={loading}
          refresh={this.refresh.bind(this)}
          organizations={user.organizations}
          payrolls={payrolls}
          subscription={this.subscription}
          refreshKey={refreshKey}
          projects={projects}
          api={api}
        />
      </MercuryNotify>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
