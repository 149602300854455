import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const sessionFamilies = `/${service}/sessionFamilies`;

export function getSessionFamilies() {
  const url = `${sessionFamilies}`;
  return {
    method: GET,
    url,
  };
}

export function getSessionFamilieById(id) {
  const url = `${sessionFamilies}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSessionFamilie(id, body) {
  const url = `${sessionFamilies}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSessionFamilie(id) {
  const url = `${sessionFamilies}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSessionFamilie(body) {
  const url = `${sessionFamilies}`;
  return {
    method: POST,
    url,
    body,
  };
}
