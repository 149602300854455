// material-ui
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
// styles
import styles from "./styles";

class DatetimeGraph extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    dataKey: PropTypes.string,
    dateKey: PropTypes.string,
    height: PropTypes.number,
    color: PropTypes.string,
    noHistory: PropTypes.bool,
    min: PropTypes.any,
    max: PropTypes.any,
    label: PropTypes.string,
  };

  state = {
    graph: 30,
  };

  getGraphConfig(range) {
    const {
      data,
      dataKey,
      dateKey,
      height,
      color,
      min,
      max,
      label,
    } = this.props;
    const graphData = [];
    const colors = [];

    const currentDay = min ? moment(min) : moment();
    for (let index = 0; index < range; index++) {
      graphData.push([currentDay.toDate().getTime(), 0]);
      currentDay.add(1, "d");
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        const begin = min ? moment(min) : moment();
        const end = moment(element[dateKey]); // another date
        const duration = moment.duration(end.diff(begin));
        const days = Math.round(duration.asDays());

        if (graphData[days] !== undefined) {
          graphData[days][1] += element[dataKey];
          colors.push("#777777");
        }
      }
    }

    const options = {
      chart: {
        height: height || 200,
      },
      colors: [color || "#FF7F00"],
      stroke: {
        width: 0,
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
        type: "solid",
      },
      yaxis: {
        tickAmount: 3,
        labels: {
          show: false,
        },
      },
      xaxis: {
        type: "datetime",
        min,
        max,
      },
    };
    const series = [
      {
        name: label || "Usage",
        data: graphData,
      },
    ];

    const config = { options, series, type: "area" };
    return config;
  }

  render() {
    const { height } = this.props;
    const { graph } = this.state;
    const config = this.getGraphConfig(graph);

    return (
      <div>
        <Chart
          options={config.options}
          series={config.series}
          type={config.type}
          height={height || 200}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DatetimeGraph);
