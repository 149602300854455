// import PropTypes from 'prop-types';
// material-ui
import {
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  Hidden,
  Icon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import StarsIcon from "@material-ui/icons/Star";
import TransferWithinAStationIcon from "@material-ui/icons/SwapCalls";
import * as Actions from "actions";
import Job from "components/Job";
import { STUDIO_SESSION_DETAIL } from "constants/routes";
import { buildForURL, formatDate } from "helpers";
import MusicianActions from "hooks/MusicianActions";
import MusicianAvatar from "hooks/MusicianAvatar";
import ReplaceMusician from "hooks/ReplaceMusician";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const arrayToMap = (array) => {
  const _map = {};
  for (const key in array) {
    if (Object.hasOwnProperty.call(array, key)) {
      const item = array[key];
      _map[item.id] = item;
    }
  }
  return _map;
};

export default function ProjectHiring({
  project,
  history,
  conciergeReadOnly,
  subscription,
}) {
  const [projectHiring, setProjectHiring] = React.useState();
  const [arrayMatrix, setArrayMatrix] = React.useState([]);
  const [refreshKey, setRefreshKey] = React.useState(0);
  const [sectionMap, setSectionMap] = React.useState({});
  const [sortedArrayMatrix, setSortedArrayMatrix] = React.useState([]);
  const [sectionRoleMap, setSectionRoleMap] = React.useState({});
  const dispatch = useDispatch();
  const sectionRoles = useSelector((state) => state.sectionRoles);
  const classes = useStyles(styles);

  const selectedMusicians = useSelector(
    (s) => s.projectHiring.selectedMusicians
  );

  const selectedSessionMap = useSelector(
    (s) => s.projectHiring.selectedSessionMap
  );

  const selectedMusicianMap = useSelector(
    (s) => s.projectHiring.selectedMusicianMap
  );

  const sortBy = useSelector((s) => s.app.projectHiringSortBy);
  const selectedSessions = useSelector((s) => s.projectHiring.selectedSessions);

  React.useEffect(() => {
    if (sectionRoles) {
      setSectionRoleMap(arrayToMap(sectionRoles));
    }
  }, [sectionRoles]);

  React.useEffect(() => {
    refresh();
  }, []);

  React.useEffect(() => {
    if (arrayMatrix.length) getSortedMatrix();
  }, [arrayMatrix, sortBy]);

  React.useEffect(() => {
    if (projectHiring) genMatrix();
  }, [projectHiring]);

  function refresh() {
    dispatch(Actions.getProjectHiring(project.id)).then((r) => {
      setProjectHiring(r.payload);
      setRefreshKey(new Date().getTime());
    });
  }

  function genMatrix() {
    console.log("genMatrix");
    const _matrix = {};
    const _sessions = {};
    const _sectionMap = {};
    const _selectedSessions = [];
    const _selectedMusicians = [];
    const { sessions, jobs, musicians, sections } = projectHiring;

    // map<sessionID, session> to avoid search in an array
    for (const key in sessions) {
      if (Object.hasOwnProperty.call(sessions, key)) {
        const s = sessions[key];
        _selectedSessions.push(s);
        _sessions[s.id] = s;
      }
    }

    for (const key in jobs) {
      if (Object.hasOwnProperty.call(jobs, key)) {
        const job = jobs[key];
        job.fullSession = _sessions[job.sessionID];
        const { musician } = job;
        if (!musician?.id) continue;
        if (musician) {
          if (_matrix[musician.id]) {
            _matrix[musician.id].sessions[_sessions[job.sessionID].id] = job;
          } else {
            _selectedMusicians.push(musician);
            _matrix[musician.id] = {
              sessions: {
                [_sessions[job.sessionID].id]: job,
              },
              musician: musician,
            };
          }
        }
      }
    }

    for (const key in musicians) {
      if (Object.hasOwnProperty.call(musicians, key)) {
        const projectMusician = musicians[key];
        if (_matrix[projectMusician.musicianID]) {
          _matrix[projectMusician.musicianID].musician.projectMusician =
            projectMusician;
        }
      }
    }

    if (selectedMusicians.length === 0 && selectedSessions.length === 0)
      dispatch(
        Actions.setProjectHiringSelection({
          selectedMusicians: _selectedMusicians,
          selectedSessions: _selectedSessions,
        })
      );

    const _arrayMatrix = [];

    for (const key in _matrix) {
      if (Object.hasOwnProperty.call(_matrix, key)) {
        const element = _matrix[key];
        _arrayMatrix.push(element);
      }
    }

    for (const key in sections) {
      if (Object.hasOwnProperty.call(sections, key)) {
        const section = sections[key];
        _sectionMap[section.sectionID] = section;
      }
    }

    setSectionMap(_sectionMap);
    setArrayMatrix(_arrayMatrix);
  }

  function getMusicians() {
    console.log("getMusicians");
    const musicians = [];
    for (const key in arrayMatrix) {
      if (Object.hasOwnProperty.call(arrayMatrix, key)) {
        const item = arrayMatrix[key];
        musicians.push(item.musician);
      }
    }

    return musicians;
  }

  function selectAll() {
    selectAllMusicians();
    selectAllSessions();
  }

  function selectAllMusicians() {
    dispatch(
      Actions.setProjectHiringSelection({
        selectedMusicians: getMusicians(),
      })
    );
  }

  function selectAllSessions() {
    dispatch(
      Actions.setProjectHiringSelection({
        selectedSessions: projectHiring.sessions,
      })
    );
  }

  function selectSection(sectionID) {
    const _sectionMusicians = sortedArrayMatrix.filter(
      (m) => m.musician.projectMusician?.sectionID === sectionID
    );

    const _selectedMusicians = [...selectedMusicians];
    for (const key in _sectionMusicians) {
      if (Object.hasOwnProperty.call(_sectionMusicians, key)) {
        const _sectionMusician = _sectionMusicians[key].musician;
        if (_selectedMusicians.indexOf(_sectionMusician) === -1) {
          _selectedMusicians.push(_sectionMusician);
        }
      }
    }

    dispatch(
      Actions.setProjectHiringSelection({
        selectedMusicians: _selectedMusicians,
      })
    );
  }

  function unselectAll() {
    dispatch(
      Actions.setProjectHiringSelection({
        selectedMusicians: [],
        selectedSessions: [],
      })
    );
  }

  const handleOrderTypeClicked = (type) => () => {
    dispatch(Actions.setProjectHiringSortBy(type));
  };

  function getToolbar() {
    console.log("getToolbar");
    return (
      <div className={classes.toolbar}>
        <div className={classes.toolbarGroup1}>
          <ButtonGroup
            variant="text"
            size="small"
            aria-label="text primary button group"
          >
            <Button
              color={sortBy === "Alphabetical" && "secondary"}
              onClick={handleOrderTypeClicked("Alphabetical")}
            >
              Alphabetical
            </Button>
            <Button
              color={sortBy === "Section" && "secondary"}
              onClick={handleOrderTypeClicked("Section")}
            >
              Project Sections
            </Button>
          </ButtonGroup>
        </div>
        <Hidden smDown>
          <div className={classes.toolbarGroup2}>
            <Typography variant="caption" color="textSecondary">
              <i class="fad fa-lasso p-right"></i> Choose your targets by
              selecting musicians and work sessions. Click{" "}
              <i class="fad fa-paper-plane p-right"></i>Notify to preview &
              send.
            </Typography>
          </div>
        </Hidden>
        <div className={classes.toolbarGroup3}>
          <Typography
            variant="caption"
            className={classes.link}
            onClick={selectAll}
          >
            Select All
          </Typography>
          <Typography
            variant="caption"
            className={classes.link}
            onClick={unselectAll}
          >
            Unselect All
          </Typography>
          <Tooltip title="Refresh">
            <IconButton size="small" onClick={refresh}>
              <Icon
                style={{ fontSize: 14 }}
                class="fa-solid fa-arrow-rotate-right"
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  }

  const handleSessionChecked = (session) => (event) => {
    const checked = event.target.checked;
    var _selectedSessions = [...selectedSessions];

    if (checked) {
      _selectedSessions.push(session);
    } else {
      _selectedSessions = _selectedSessions.filter((s) => s.id !== session.id);
    }

    dispatch(
      Actions.setProjectHiringSelection({
        selectedSessions: _selectedSessions,
      })
    );
  };

  const handleMusicianChecked = (musician) => (event) => {
    const checked = event.target.checked;
    var _selectedMusicians = [...selectedMusicians];
    if (checked) {
      _selectedMusicians.push(musician);
    } else {
      _selectedMusicians = _selectedMusicians.filter(
        (s) => s.id !== musician.id
      );
    }
    dispatch(
      Actions.setProjectHiringSelection({
        selectedMusicians: _selectedMusicians,
      })
    );
  };

  function getEngagementHeader() {
    return projectHiring?.sessions?.map((s) => (
      <div key={s.id} className={classes.engagementHeaderCell}>
        <Checkbox
          size="small"
          onChange={handleSessionChecked(s)}
          checked={selectedSessionMap[s.id] ?? false}
        />
        <Tooltip title={s.title}>
          <div style={{ flexGrow: 1, width: 0 }}>
            <Typography noWrap>{s.title}</Typography>
            <Typography noWrap variant="caption" color="textSecondary">
              {formatDate(s.dateFromUTC, s.tzName)}
            </Typography>
          </div>
        </Tooltip>
        <Tooltip title={"Open in new tab"}>
          <div
            onClick={(e) => {
              window.open(
                `${STUDIO_SESSION_DETAIL}?${buildForURL({
                  id: s.id,
                  back: `${window.location.pathname}${window.location.search}`,
                })}`,
                "_blank"
              );
            }}
          >
            <i class="far fa-external-link" style={{ fontSize: 12 }}></i>
          </div>
        </Tooltip>
      </div>
    ));
  }

  function getSortedMatrix() {
    getEngagementHeader;
    var _sortedArrayMatrix = [...arrayMatrix];
    console.log("getSortedMatrix");
    switch (sortBy) {
      case "Alphabetical":
        _sortedArrayMatrix = _sortedArrayMatrix.sort((a, b) => {
          if (a.musician.lastName < b.musician.lastName) {
            return -1;
          }
          if (a.musician.lastName > b.musician.lastName) {
            return 1;
          }
          return 0;
        });
        break;
      case "Section":
        _sortedArrayMatrix = _sortedArrayMatrix.sort((a, b) => {
          const aProjectMusician = a.musician.projectMusician;
          const bProjectMusician = b.musician.projectMusician;

          const aSection = sectionMap[aProjectMusician?.sectionID];
          const bSection = sectionMap[bProjectMusician?.sectionID];

          console.log(aSection, bSection);

          if (aSection?.familyPos === bSection?.familyPos) {
            if (
              aSection?.sectionPos < bSection?.sectionPos ||
              (!bProjectMusician && aProjectMusician)
            ) {
              return -1;
            } else if (
              aSection?.sectionPos > bSection?.sectionPos ||
              (!aProjectMusician && bProjectMusician)
            ) {
              return 1;
            }
          }
          if (
            aSection?.familyPos < bSection?.familyPos ||
            (!bProjectMusician && aProjectMusician)
          ) {
            return -1;
          }
          if (
            aSection?.familyPos > bSection?.familyPos ||
            (!aProjectMusician && bProjectMusician)
          ) {
            return 1;
          }

          // same section
          if (
            aProjectMusician &&
            bProjectMusician &&
            aProjectMusician.sectionID === bProjectMusician.sectionID
          ) {
            if (aProjectMusician.sectionOrder < bProjectMusician.sectionOrder) {
              return -1;
            }
            if (aProjectMusician.sectionOrder > bProjectMusician.sectionOrder) {
              return 1;
            }
          }

          return 0;
        });
        break;
      default:
        break;
    }

    setSortedArrayMatrix(_sortedArrayMatrix);
  }

  function getMusicianList() {
    console.log("getMusicianList");
    const JSX = [
      <div className={classes.tableSplitBlock}>
        <Tooltip title="Select all Sessions">
          <div
            style={{
              position: "absolute",
              right: 4,
              top: 18,
              fontSize: 12,
              cursor: "pointer",
            }}
            onClick={selectAllSessions}
          >
            <i class="fal fa-arrow-to-right"></i>
          </div>
        </Tooltip>
        <Tooltip title="Select all Musicians">
          <div
            style={{
              position: "absolute",
              left: 14,
              bottom: 4,
              fontSize: 12,
              cursor: "pointer",
            }}
            onClick={selectAllMusicians}
          >
            <i class="fal fa-arrow-to-bottom"></i>
          </div>
        </Tooltip>
      </div>,
    ];
    var _previousMusician;
    for (const key in sortedArrayMatrix) {
      if (Object.hasOwnProperty.call(sortedArrayMatrix, key)) {
        const musician = sortedArrayMatrix[key].musician;
        if (musician.id)
          if (
            sortBy === "Section" &&
            _previousMusician?.projectMusician?.sectionID !==
              musician?.projectMusician?.sectionID
          ) {
            JSX.push(
              <div className={classes.tableHeader}>
                <Tooltip title="Select this section">
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    onClick={() =>
                      selectSection(musician.projectMusician?.sectionID)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      class="fal fa-arrow-to-bottom"
                      style={{
                        fontSize: 12,
                        padding: "0px 12px",
                      }}
                    />
                    {sectionMap[musician?.projectMusician?.sectionID]
                      ?.sectionName ?? "Not in project's seating"}
                  </Typography>
                </Tooltip>
              </div>
            );
          }
        JSX.push(
          <ListItem dense disableGutters divider style={{ height: 72 }}>
            <ListItemAvatar>
              <Checkbox
                size="small"
                checked={selectedMusicianMap[musician.id] ?? false}
                onChange={handleMusicianChecked(musician)}
              />
              <MusicianAvatar musician={musician} />
            </ListItemAvatar>
            <ListItemText
              style={{ padding: "0px 8px" }}
              primary={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography display="block" variant="body2">
                      {musician.nickName ||
                        `${musician.firstName} ${musician.lastName}`}
                    </Typography>
                    {musician.projectMusician ? (
                      <Tooltip
                        title={
                          musician.projectMusician.prime
                            ? "Primary"
                            : "Alternate"
                        }
                      >
                        <Chip
                          size="small"
                          icon={
                            musician.projectMusician.prime ? (
                              <StarsIcon
                                style={{ fontSize: 12, color: "white" }}
                              />
                            ) : (
                              <TransferWithinAStationIcon
                                style={{ fontSize: 12, color: "white" }}
                              />
                            )
                          }
                          style={{
                            background: musician.projectMusician.prime
                              ? "#D7B740"
                              : "#607d8b",
                            color: "white",
                          }}
                          label={musician.projectMusician.sectionOrder + 1}
                        />
                      </Tooltip>
                    ) : (
                      []
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ReplaceMusician
                      item={sortedArrayMatrix[key]}
                      projectID={project.id}
                      refresh={refresh}
                    />
                    <MusicianActions
                      musician={musician}
                      projectID={project.id}
                      selectedSessions={selectedSessions}
                    />
                  </div>
                </div>
              }
            />
          </ListItem>
        );
        _previousMusician = musician;
      }
    }
    return JSX;
  }

  function getMatrixContent() {
    console.log("getMatrixContent");
    if (!sortedArrayMatrix || !projectHiring) return <div />;
    const { sessions } = projectHiring;
    const JSX = [];

    var _previousMusician;

    for (const key in sortedArrayMatrix) {
      if (Object.hasOwnProperty.call(sortedArrayMatrix, key)) {
        const item = sortedArrayMatrix[key];
        const musician = sortedArrayMatrix[key].musician;
        if (
          sortBy === "Section" &&
          _previousMusician?.projectMusician?.sectionID !==
            musician?.projectMusician?.sectionID
        ) {
          JSX.push(
            <div key={item.id} className={classes.tableHeaderSec}>
              <span style={{ color: "#e0e0e0" }}>H</span>
            </div>
          );
          JSX.push(
            <div key={`newline_${item.id}`} className={classes.newLine} />
          );
        }
        for (const key in sessions) {
          if (Object.hasOwnProperty.call(sessions, key)) {
            const session = sessions[key];
            const job = item.sessions[session.id];

            const sectionRole = sectionRoleMap[job?.sectionRoleID];

            const instrument =
              musician &&
              musician.instruments &&
              musician.instruments.find((i) => i.id === job?.instrumentID);
            JSX.push(
              <MatrixCell
                key={`${session.id}-${musician.id}`}
                conciergeReadOnly={conciergeReadOnly}
                subscription={subscription}
                refresh={refresh}
                musician={musician}
                session={session}
                classes={classes}
                sectionRole={sectionRole}
                job={job}
                history={history}
                instrument={instrument}
              />
            );
          }
        }
        JSX.push(
          <div key={`newline2_${item.id}`} className={classes.newLine} />
        );
        _previousMusician = musician;
      }
    }
    return JSX;
  }

  const child1 = React.useMemo(() => {
    console.log("Big render");
    return (
      <Paper className={classes.table} elevation={2}>
        <div className={classes.musicianColumn}>{getMusicianList()}</div>
        <div className={classes.engagementColumn}>
          <div className={classes.header}>{getEngagementHeader()}</div>
          <div className={classes.matrixContent}>{getMatrixContent()}</div>
        </div>
      </Paper>
    );
  }, [refreshKey, selectedMusicianMap, selectedSessionMap, sortedArrayMatrix]);

  if (!projectHiring) {
    return (
      <div className={classes.loading}>
        <CircularProgress style={{ color: "#ffc107" }} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {getToolbar()}
      {child1}
    </div>
  );
}

function MatrixCell({
  job,
  instrument,
  classes,
  session,
  musician,
  conciergeReadOnly,
  subscription,
  sectionRole,
  refresh,
  history,
}) {
  const [highlighted, setHighlighted] = React.useState(false);
  const selectedSessionMap = useSelector(
    (s) => s.projectHiring.selectedSessionMap
  );

  const selectedMusicianMap = useSelector(
    (s) => s.projectHiring.selectedMusicianMap
  );

  React.useEffect(() => {
    if (selectedSessionMap[session.id] && selectedMusicianMap[musician.id]) {
      setHighlighted(true);
    } else {
      setHighlighted(false);
    }
  }, [selectedSessionMap, selectedMusicianMap]);

  const child1 = React.useMemo(() => {
    return (
      <Job
        job={job}
        conciergeReadOnly={conciergeReadOnly}
        history={history}
        small
        subscription={subscription}
        studioSession={session}
        refresh={refresh}
      />
    );
  }, [job]);

  if (!job) {
    return (
      <Tooltip title="Does not play in this session">
        <div
          style={{
            filter: !highlighted ? "grayscale(100%)" : undefined,
            opacity: !highlighted ? 0.2 : 1,
          }}
          className={classes.cellCrossed}
        ></div>
      </Tooltip>
    );
  }

  return (
    <div
      key={job.id}
      style={{
        filter: !highlighted ? "grayscale(100%)" : undefined,
        opacity: !highlighted ? 0.2 : 1,
      }}
      className={classes.cell}
    >
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <div style={{ display: "inline-block" }}>{child1}</div>
        <Typography display="block"></Typography>
        {instrument ? (
          <Typography variant="caption" color="textSecondary">
            {instrument.name} - {sectionRole?.name}
          </Typography>
        ) : (
          []
        )}
      </div>
    </div>
  );
}
