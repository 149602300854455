import gravatar from "gravatar";

export function getJsonFromUrl(location) {
  const query = location.search.substr(1);
  const result = {};
  query.split("&").forEach((part) => {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function goBack(history, fallback) {
  const urlParams = getJsonFromUrl(window.location);
  console.log(urlParams);
  if (urlParams.back) {
    console.log(decodeURIComponent(urlParams.back));
    history.push(decodeURIComponent(urlParams.back));
  } else {
    history.push(fallback);
  }
}

export function buildForURL(search) {
  return Object.keys(search)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(search[k])}`)
    .join("&");
}

export function updateURLParameter(key, value) {
  const urlParams = getJsonFromUrl(window.location);
  delete urlParams[""];
  urlParams[key] = value;
  return buildForURL(urlParams);
}

export function removeURLParameter(v) {
  const urlParams = getJsonFromUrl(window.location);
  if (Array.isArray(v)) {
    for (const key in v) {
      if (Object.hasOwnProperty.call(v, key)) {
        const element = v[key];
        delete urlParams[element];
      }
    }
  } else {
    delete urlParams[v];
  }

  return buildForURL(urlParams);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export function setFavicon(icon) {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = icon;
  document.getElementsByTagName("head")[0].appendChild(link);
}

// First a couple helper functions
function $(id) {
  return !id || id.nodeType === 1 ? id : document.getElementById(id);
}
function isType(o, t) {
  return (typeof o).indexOf(t.charAt(0).toLowerCase()) === 0;
}

// Here's the meat and potatoes
export function image(src, cfg) {
  var img, prop, target;
  cfg = cfg || (isType(src, "o") ? src : {});

  img = $(src);
  if (img) {
    src = cfg.src || img.src;
  } else {
    img = document.createElement("img");
    src = src || cfg.src;
  }

  if (!src) {
    return null;
  }

  prop = isType(img.naturalWidth, "u") ? "width" : "naturalWidth";
  img.alt = cfg.alt || img.alt;

  // Add the image and insert if requested (must be on DOM to load or
  // pull from cache)
  img.src = src;

  target = $(cfg.target);
  if (target) {
    target.insertBefore(img, $(cfg.insertBefore) || null);
  }

  // Loaded?
  if (img.complete) {
    if (img[prop]) {
      if (isType(cfg.success, "f")) {
        cfg.success.call(img);
      }
    } else {
      if (isType(cfg.failure, "f")) {
        cfg.failure.call(img);
      }
    }
  } else {
    if (isType(cfg.success, "f")) {
      img.onload = cfg.success;
    }
    if (isType(cfg.failure, "f")) {
      img.onerror = cfg.failure;
    }
  }

  return img;
}

export function getGravatar(email) {
  let gravatarURL;
  const urlToTest = gravatar.url(email, { s: "200", r: "pg", d: "404" });
  image(urlToTest, {
    success: () => {
      gravatarURL = urlToTest;
    },
  });

  return gravatarURL;
}
