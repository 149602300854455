import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// images
import Numb from "./number.png";
import NumbWhite from "./number_white.png";
import Percentage from "./percentage.png";
import PercentageWhite from "./percentage_white.png";
// styles
import styles from "./styles";

class FormMusicianSettings extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    close: PropTypes.func,
    open: PropTypes.bool,
    studioSession: PropTypes.object,
    updateStudioSession: PropTypes.func,
    calculatePensionWelfare: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { studioSession } = this.props;
    this.state = {
      loading: false,
      pensionPercentage: "",
      welfarePercent: studioSession.sessionType.code === "LV",
      welfareValue: "",
      workDuesPercent: "",
      performanceScale: "",
      rehearsalScale: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.studioSession);
    }
  }

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(studioSession) {
    this.setState({
      loading: false,
      pensionPercentage: studioSession
        ? studioSession.pensionPercentage / 100
        : "",
      welfarePercent: studioSession ? studioSession.welfarePercent : false,
      welfareValue: studioSession ? studioSession.welfareValue / 100 : "",
      workDuesPercent: studioSession ? studioSession.workDuesPercent / 100 : "",
      performanceScale: studioSession
        ? studioSession.performanceScale / 100
        : "",
      rehearsalScale: studioSession ? studioSession.rehearsalScale / 100 : "",
    });
  }

  async save(calculate) {
    const {
      updateStudioSession,
      calculatePensionWelfare,
      studioSession,
      refresh,
      close,
    } = this.props;

    const {
      pensionPercentage,
      welfarePercent,
      welfareValue,
      workDuesPercent,
      performanceScale,
      rehearsalScale,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (studioSession) {
      if (calculate) {
        resp = await calculatePensionWelfare(studioSession.id, {
          pensionPercentage: pensionPercentage.length
            ? Math.round(Number(pensionPercentage) * 100)
            : undefined,
          welfarePercent,
          welfareValue: welfareValue.length
            ? Math.round(Number(welfareValue) * 100)
            : undefined,
          workDuesPercent: workDuesPercent.length
            ? Math.round(Number(workDuesPercent) * 100)
            : undefined,
          performanceScale: performanceScale.length
            ? Math.round(Number(performanceScale) * 100)
            : undefined,
          rehearsalScale: rehearsalScale.length
            ? Math.round(Number(rehearsalScale) * 100)
            : undefined,
        });
      } else {
        resp = await updateStudioSession(studioSession.id, {
          pensionPercentage: pensionPercentage.length
            ? Math.round(Number(pensionPercentage) * 100)
            : undefined,
          welfarePercent,
          welfareValue: welfareValue.length
            ? Math.round(Number(welfareValue) * 100)
            : undefined,
          workDuesPercent: workDuesPercent.length
            ? Math.round(Number(workDuesPercent) * 100)
            : undefined,
          performanceScale: performanceScale.length
            ? Math.round(Number(performanceScale) * 100)
            : undefined,
          rehearsalScale: rehearsalScale.length
            ? Math.round(Number(rehearsalScale) * 100)
            : undefined,
        });
      }
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Settings have been updated.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, close, studioSession } = this.props;

    const {
      pensionPercentage,
      welfarePercent,
      welfareValue,
      workDuesPercent,
      performanceScale,
      rehearsalScale,
      loading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              Pensions & Welfares
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} alignItems="center">
                {studioSession && studioSession.sessionType.code === "LV" ? (
                  <Grid item xs={12}>
                    <Typography variant="body2">Rehearsal Scale</Typography>
                    <TextField
                      label="Rehearsal Scale #"
                      fullWidth
                      className={classes.textField}
                      value={rehearsalScale}
                      onChange={this.handleChange("rehearsalScale")}
                    />
                  </Grid>
                ) : (
                  []
                )}
                {studioSession && studioSession.sessionType.code === "LV" ? (
                  <Grid item xs={12}>
                    <Typography variant="body2">Performance Scale</Typography>
                    <TextField
                      label="Performance Scale #"
                      fullWidth
                      className={classes.textField}
                      value={performanceScale}
                      onChange={this.handleChange("performanceScale")}
                    />
                  </Grid>
                ) : (
                  []
                )}
                {studioSession && studioSession.sessionType.code === "LV" ? (
                  <Grid item xs={12}>
                    <Typography variant="body2">Work Dues</Typography>
                    <TextField
                      label="Work Dues %"
                      fullWidth
                      className={classes.textField}
                      value={workDuesPercent}
                      onChange={this.handleChange("workDuesPercent")}
                    />
                  </Grid>
                ) : (
                  []
                )}
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Pension Automatic percentage of the wage (except Overscale)
                  </Typography>
                  <TextField
                    label="Pension %"
                    fullWidth
                    className={classes.textField}
                    value={pensionPercentage}
                    onChange={this.handleChange("pensionPercentage")}
                  />
                </Grid>
                {studioSession.sessionType.code === "LV" ? (
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Welfare is the same per session
                    </Typography>
                    <TextField
                      label="Welfare Value %"
                      style={{ width: "calc(100% - 100px)" }}
                      className={classes.textField}
                      value={welfareValue}
                      onChange={this.handleChange("welfareValue")}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Welfare is the same per session
                    </Typography>
                    <TextField
                      label="Welfare Value"
                      style={{ width: "calc(100% - 100px)" }}
                      className={classes.textField}
                      value={welfareValue}
                      onChange={this.handleChange("welfareValue")}
                    />
                    <Tooltip title="Absolute Value">
                      <Fab
                        color={!welfarePercent ? "primary" : undefined}
                        size="small"
                        onClick={() => this.setState({ welfarePercent: false })}
                      >
                        <img
                          alt="Absolute"
                          src={!welfarePercent ? NumbWhite : Numb}
                          style={{ height: 16 }}
                        />
                      </Fab>
                    </Tooltip>
                    <Tooltip title="Percentage">
                      <Fab
                        color={welfarePercent ? "primary" : undefined}
                        size="small"
                        style={{ marginLeft: 10 }}
                        onClick={() => this.setState({ welfarePercent: true })}
                      >
                        <img
                          alt="Absolute"
                          src={welfarePercent ? PercentageWhite : Percentage}
                          style={{ height: 16 }}
                        />
                      </Fab>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="secondary" onClick={() => this.save(false)}>
                <SaveIcon className={classes.leftIcon} />
                Save & Don't Apply
              </Button>
              <Button
                color="primary"
                variant="contained"
                autoFocus
                onClick={() => this.save(true)}
              >
                <SaveIcon className={classes.leftIcon} />
                Save & Apply
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormMusicianSettings));
