// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import TableEdit from "../TableEdit";
// styles
import styles from "./styles";

class SessionTypesList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    sessionTypes: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedSessionType) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedSessionType);
    }
  }

  render() {
    const { sessionTypes } = this.props;

    return (
      <Table
        data={sessionTypes}
        meta={[
          {
            path: "id",
            title: "ID",
            width: 30,
          },
          {
            path: "code",
            title: "Code",
            numeric: false,
            width: 50,
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "description",
            title: "Description",
            numeric: false,
          },
          {
            path: "version",
            title: "Version",
            numeric: false,
            width: 50,
          },
          {
            path: "id",
            numeric: false,
            component: TableEdit,
            inject: {
              onEdit: this.onSelect.bind(this),
            },
            width: 30,
          },
        ]}
        title={"Session Types"}
        onRowSelect={() => console.log("Clicked")}
      />
    );
  }
}

export default withStyles(styles)(SessionTypesList);
