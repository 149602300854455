import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import img0 from "assets/img/0.png";
import img1 from "assets/img/1.png";
import img2 from "assets/img/2.png";
import img3 from "assets/img/3.png";
import img4 from "assets/img/4.png";
import img5 from "assets/img/5.png";
import img6 from "assets/img/6.png";
import chart from "assets/img/chart.png";
import Logo from "assets/img/dateline.png";
import { AutoRotatingCarousel } from "material-auto-rotating-carousel";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperPricingsPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      value: 10,
    };
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          background: "linear-gradient(315deg, #616161 0%, #000000 74%)",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AutoRotatingCarousel
          open
          mobile
          autoplay={false}
          style={{ position: "absolute" }}
        >
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              background: "linear-gradient(180deg, #272838 0%, #000000 100%)",
            }}
          >
            <div>
              <img
                src="https://www.blog.rhapsody.la/wp-content/uploads/2019/03/logo_main-white.png"
                height="180"
                alt="logo"
              />
              <div style={{ fontFamily: "poppins", fontSize: 28 }}>
                <br />A Robust and Purpose-Built Contracting App
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              background: "linear-gradient(180deg, #272838 0%, #000000 100%)",
            }}
          >
            <div>
              <img
                src="https://storage.googleapis.com/rhapsody/media/photos2.png"
                height="220"
                alt="photos"
              />
              <div style={{ fontFamily: "poppins", fontSize: 28 }}>
                <br />
                We are a team of working professionals in the music industry
                ecosystem
                <br />
                With diverse backgrounds, from computer sciences to music
                performance
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Core <span style={{ color: "#2196f3" }}>Concepts</span>
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                <br />A Quick guide to understanding Projects and Work Sessions
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              backgroundImage:
                "url(https://storage.googleapis.com/rhapsody/media/lalaland.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div
                style={{ fontSize: 80, fontWeight: 800, paddingBottom: "20%" }}
              >
                You contract musicians to record
                <br />
                the <span style={{ color: "#ffc107" }}>La La Land </span>
                Soundtrack
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                La La Land is your{" "}
                <span style={{ color: "#9c27b0" }}>Project</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              background:
                "linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2)), url(https://storage.googleapis.com/rhapsody/media/laland-orchestra.jpg) no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800, paddingTop: "20%" }}>
                <span style={{ color: "#e91e63" }}>48 people</span> need
                <br />
                to be contracted for this project
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                These are your{" "}
                <span style={{ color: "#00bcd4" }}>Project's Musicians</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                The Project is composed of <br />
                <span style={{ color: "#2196f3" }}>Work Sessions</span>
              </div>
              <div
                style={{ color: "#8c8c8c", fontSize: 28, textAlign: "left" }}
              >
                <ul>
                  <li>A Single Roster Using your Project's Musicians</li>
                  <li>A Specific Day and Venue</li>
                </ul>
              </div>
              <div style={{ fontSize: 40, fontWeight: 800 }}>
                <i>These are Events in your Project Calendar</i>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              background:
                "linear-gradient(rgba(13,71,161,0.8), rgba(0, 0, 0, 0.2)), url(https://storage.googleapis.com/rhapsody/media/lala2.jpg) no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800, paddingTop: "10%" }}>
                You start planing the recording of the song
                <span style={{ color: "#ffc107" }}> "Another Day of Sun"</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 50, fontWeight: 800 }}>
                5 <span style={{ color: "#2196f3" }}>Work Sessions</span> are
                added to the Calendar:
              </div>
              <br />
              <img src={chart} alt="chart" />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                You are ready to send the{" "}
                <span style={{ color: "#ffc107" }}>invites</span>!
              </div>
              <img
                src="https://www.blog.rhapsody.la/wp-content/uploads/2019/12/plane-02.png"
                width="800"
                alt="plane"
              />
              <br />
              <br />
              <div style={{ fontSize: 40, fontWeight: 800 }}>
                <i>Emails are unlimited!</i>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <div style={{ fontSize: 120, fontWeight: 800 }}>
                <img
                  src="https://www.blog.rhapsody.la/wp-content/uploads/2019/03/logo_main-white.png"
                  height="100"
                  alt="logo"
                />
                <br />
                <i>Demo</i>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <div style={{ paddingBottom: 40 }}>
                <div
                  style={{
                    position: "relative",
                    width: `${(744 / 858) * 100}%`,
                    margin: "auto",
                  }}
                >
                  <img src={img0} alt="price" />
                  <div
                    style={{
                      height: "52%",
                      width: 1,
                      background: "#ffc107",
                      position: "absolute",
                      top: "22%",
                      left: `${28.5 + value / 2.08 - 4.16}%`,
                    }}
                  />
                </div>
                <Slider
                  valueLabelDisplay="on"
                  onChange={(e, v) => this.setState({ value: v })}
                  value={value}
                  step={5}
                  style={{
                    maxWidth: 236,
                    marginTop: -50,
                    marginLeft: 20,
                  }}
                  classes={{
                    root: classes.root,
                    thumb: classes.thumb,
                    active: classes.active,
                    valueLabel: classes.valueLabel,
                    track: classes.track,
                    rail: classes.rail,
                  }}
                  min={10}
                  max={100}
                />
                <br />
                <span style={{ fontSize: 20 }}>$</span>{" "}
                <span style={{ fontSize: 34 }}>
                  {Math.round((-25 / 36) * value + 131.9)}
                </span>
                <br />
                per work session
              </div>
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Pro-rated Price
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                The more you purchase, the less it costs per credit.
                <br /> 1 credit = 1 Work Session. With{" "}
                <b style={{ color: "#ffc107" }}>UNLIMITED</b> emails!
                <br /> <br />
                Credits never expire
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img1} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>Multi Users</div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Invite people to your organization.
                <br />
                Work together simultaneously.
                <br />
                <br />
                You know <span style={{ color: "#ffc107" }}>who</span> did{" "}
                <span style={{ color: "#ffc107" }}>what</span> and{" "}
                <span style={{ color: "#ffc107" }}>when</span>.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img2} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                White Label Service
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Customize the emails with your own branding.
                <br />
                Use a custom logo on the roster.
                <br />
                <br />
                You control the{" "}
                <span
                  style={{
                    color: "#ffc107",
                    fontFamily: "tornac",
                  }}
                >
                  look
                </span>{" "}
                of your communication.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img3} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Priority Support
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Any questions or issues?
                <br />
                Our team responds within 1 hour on phone or live chat.
                <br />
                <br />
                We're here for <span style={{ color: "#ffc107" }}>you</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img4} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Database Import
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                We import your musicians, studios and customers.
                <br />
                Save hundred of hours of data entry.
                <br />
                <br />
                You're off to a{" "}
                <span style={{ color: "#ffc107" }}>good start</span>!
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img5} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>Time Machine</div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Unlimited access to past Projects and Work Sessions.
                <br />
                Keep track of everything.
                <br />
                <br />
                You won't <span style={{ color: "#ffc107" }}>overlook</span> a
                customer!
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <img src={img6} alt="multi" />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                Email Traceability
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                Consult the email sent history.
                <br />
                Know the IP address and timestamp of email views.
                <br /> <br />
                You have the{" "}
                <span style={{ color: "#ffc107" }}>information</span> to
                disperse any confusion
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ maxWidth: 800 }}>
              <div style={{ fontSize: 80, fontWeight: 800, textAlign: "left" }}>
                And Much More!
                <ul style={{ color: "#8c8c8c", fontSize: 28 }}>
                  <li>
                    <span style={{ color: "#ffc107" }}>Unlimited</span> Emails{" "}
                  </li>
                  <li>
                    Always <span style={{ color: "#ffc107" }}>Up-To-Date</span>{" "}
                    PDF Rosters
                  </li>
                  <li>
                    Musician <span style={{ color: "#ffc107" }}>Mobile</span>{" "}
                    App
                  </li>
                  <li>
                    <span style={{ color: "#ffc107" }}>Advanced</span>{" "}
                    Notifications
                  </li>
                  <li>
                    On Demand <span style={{ color: "#ffc107" }}>Custom</span>{" "}
                    Features
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <img
                src={Logo}
                alt="logo"
                style={{
                  width: 400,
                  paddingBottom: 80,
                }}
              />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                The Missing Human Element
              </div>
              <div style={{ color: "#8c8c8c", fontSize: 28 }}>
                <br />
                No machine can replace the human touch.
                <br />
                <br />
                Rhapsody, combined with your 50+ years of experience in Los
                Angeles, will transcend the contracting business.
                <br />
                <br />
                We believe that the tools Rhapsody offers will help build and
                grow The Dateline <br />
                business and the essential services that it continues to offer
                to its clients!
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div>
              <img
                src={Logo}
                alt="logo"
                style={{
                  width: 400,
                  paddingBottom: 80,
                }}
              />
              <div style={{ fontSize: 80, fontWeight: 800 }}>
                How We Envision the Transition
              </div>
              <div
                style={{ color: "#8c8c8c", fontSize: 28, textAlign: "left" }}
              >
                <u>Step 1:</u>
                <ul>
                  <li>
                    We offer you 25 free credits with training ($2,875 value)
                  </li>
                  <li>
                    We train you side by side on Rhapsody with 2 pre-existing
                    projects
                  </li>
                </ul>
                <u>Step 2:</u>
                <ul>
                  <li>
                    You commit to a 100 Credits Purchase ($62 per credits)
                  </li>
                  <li>We import your database</li>
                  <li>We guide you on building 2 upcoming projects</li>
                </ul>
                <div
                  style={{
                    paddingTop: 40,
                    color: "#ffc107",
                    textAlign: "center",
                  }}
                >
                  You'll then be able to use Rhapsody to its full potential. We
                  are always here to help.
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ fontSize: 80, fontWeight: 800 }}>
              Some people view the{" "}
              <span style={{ color: "#e91e63" }}>Covid19</span>
              <br />
              situation as an{" "}
              <span style={{ color: "#e91e63" }}>Opportunity</span>
              <div
                style={{
                  color: "#8c8c8c",
                  fontSize: 28,
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                Let's use this moment to prepare your workflow for the future
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <div style={{ fontSize: 80, fontWeight: 800 }}>
              Ready to send out
              <br />
              your first <span style={{ color: "#ffc107" }}>
                Work Call
              </span>{" "}
              <br />
              using Rhapsody?
            </div>
          </div>
        </AutoRotatingCarousel>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperPricingsPage);
