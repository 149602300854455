import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Lock from "@material-ui/icons/Lock";
import { withStyles } from "@material-ui/styles";
import B10 from "components/B10";
import B11 from "components/B11";
import B12 from "components/B12";
import B3 from "components/B3";
import B4 from "components/B4";
import B5 from "components/B5";
import B6 from "components/B6";
import B7 from "components/B7";
import B8 from "components/B8";
import B9 from "components/B9";
import Engagments from "components/Engagments";
import Hiring from "components/HiringRefactor";
// custom
import LinkChip from "components/LinkChip";
import Loading from "components/Loading";
import LV from "components/LV";
import Roster from "components/Roster";
import ST from "components/ST";
import StudioSessionFiles from "components/StudioSessionFiles";
import {
  PROJECT_DETAIL,
  STUDIO,
  STUDIO_SESSION,
  STUDIO_SESSION_DETAIL,
} from "constants/routes";
// helpers
import { getJsonFromUrl, goBack, updateURLParameter } from "helpers";
import Payroll from "hooks/Payroll";
import PayrollFormBuilder from "hooks/PayrollFormBuilder";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
// styles
import styles from "./styles";

const BForms = {
  1: ST,
  2: LV,
  3: B3,
  4: B4,
  5: B5,
  6: B6,
  7: B7,
  8: B8,
  9: B9,
  10: B10,
  11: B11,
  12: B12,
};

class WrapperStudioSessionPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    api: PropTypes.array,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    studioSession: PropTypes.object,
    refreshKey: PropTypes.number,
    loading: PropTypes.bool,
    refresh: PropTypes.func,
    hiringRefresh: PropTypes.func,
    tuto: PropTypes.bool,
    app: PropTypes.object,
    sessionTypes: PropTypes.array,
    currentCompany: PropTypes.object,
    studioSessionStatuses: PropTypes.array,
    studioSessionDashboard: PropTypes.array,
    studios: PropTypes.array,
    payrolls: PropTypes.array,
    projects: PropTypes.array,
    instrumentSections: PropTypes.array,
    studioSessionIdentifications: PropTypes.array,
    instrumentFamilies: PropTypes.array,
    sectionRoles: PropTypes.array,
    studioSessionMusicians: PropTypes.array,
    instruments: PropTypes.array,
    studioSessionEngagements: PropTypes.array,
    studioSessionTunes: PropTypes.array,
    studioSessionRehearsals: PropTypes.array,
    sessionCategories: PropTypes.array,
    layoutPresets: PropTypes.array,
    eventStatuses: PropTypes.array,
    conciergeReadOnly: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    MercuryNotify: PropTypes.object,
    TourCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      formMusicianOpen: false,
      generateBFormOpen: false,
      musicianLoading: false,
      formMusicianSettingsOpen: false,
      tipsOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.studioSession.id && !this.props.studioSession.id) {
      if (nextProps.studioSession.standalone) {
        this.setState({ index: 4 });
      }
    }
  }

  getStepContent() {
    const {
      api,
      studioSessionMusicians,
      studios,
      studioSessionIdentifications,
      studioSessionTunes,
      studioSessionEngagements,
      studioSessionRehearsals,
      refresh,
      studioSession,
      loading,
      sessionTypes,
      sessionCategories,
      classes,
      instrumentSections,
      instrumentFamilies,
      sectionRoles,
      eventStatuses,
      history,
      projects,
      layoutPresets,
      hiringRefresh,
      instruments,
      studioSessionDashboard,
      conciergeReadOnly,
    } = this.props;
    const { index } = this.state;
    const { NotificationCenter } = this.context;
    const { archived } = studioSession;

    const BForm = BForms[studioSession.sessionTypeID];
    const pendingMusicians = [];
    for (const key in studioSessionMusicians) {
      if (studioSessionMusicians.hasOwnProperty(key)) {
        const musician = studioSessionMusicians[key];
        if (musician.musicianID === null || musician.musicianID === -1) {
        } else if (!musician.active) {
          pendingMusicians.push(musician.musician);
        }
      }
    }

    const _filledStudioSessionMusicians = studioSessionMusicians
      .filter((a) => a.musician?.lastName)
      .sort((a, b) => {
        if (a.musician?.lastName[0] > b.musician?.lastName[0]) return 1;
        if (a.musician?.lastName[0] < b.musician?.lastName[0]) return -1;
        return 0;
      });
    const _emptyStudioSessionMusicians = studioSessionMusicians
      .filter((a) => a.musician?.lastName === null)
      .sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });

    const _studioSessionMusicians = [
      ..._filledStudioSessionMusicians,
      ..._emptyStudioSessionMusicians,
    ];

    switch (index) {
      case 5:
        console.log(studioSession);
        if (BForm) {
          return (
            <PayrollFormBuilder
              token={studioSession?.publicToken}
              id={studioSession?.id}
              refresh={refresh}
            >
              <BForm
                studios={studios}
                sessionCategories={sessionCategories}
                studioSession={studioSession}
                studioSessionTunes={studioSessionTunes}
                api={api}
                studioSessionRehearsals={studioSessionRehearsals}
                studioSessionEngagements={studioSessionEngagements}
                sessionTypes={sessionTypes}
                conciergeReadOnly={conciergeReadOnly}
                studioSessionIdentifications={studioSessionIdentifications}
              />
            </PayrollFormBuilder>
          );
        }
        return (
          <Grid container>
            <Grid item>
              <Typography variant="h6" color="textPrimary">
                Unkown Form
              </Typography>
            </Grid>
          </Grid>
        );
      case 4:
        return (
          <Payroll
            studioSession={studioSession}
            conciergeReadOnly={conciergeReadOnly}
            loading={loading}
            _studioSessionMusicians={_studioSessionMusicians}
            archived={archived}
            refresh={refresh}
            instruments={instruments}
            api={api}
          />
        );
      case 0:
        return (
          <Hiring
            disabled={studioSession.recorded || studioSession.archived}
            conciergeReadOnly={conciergeReadOnly}
            api={api}
            studios={studios}
            layoutPresets={layoutPresets}
            instruments={instruments}
            refresh={hiringRefresh}
            history={history}
            instrumentSections={instrumentSections}
            instrumentFamilies={instrumentFamilies}
            studioSessionDashboard={studioSessionDashboard}
            studioSession={studioSession}
            sectionRoles={sectionRoles}
            eventStatuses={eventStatuses}
          />
        );
      case 3:
        return (
          <Roster
            studioSession={studioSession}
            studioSessionMusicians={studioSessionMusicians}
            studioSessionEngagements={studioSessionEngagements}
            api={api}
            refresh={refresh}
          />
        );
      case 1:
        return (
          <div style={{ padding: 20, width: "100%" }}>
            <Engagments
              api={api}
              conciergeReadOnly={conciergeReadOnly}
              studios={studios}
              studioSessionRehearsals={studioSessionRehearsals}
              studioSessionEngagements={studioSessionEngagements}
              studioSession={studioSession}
              refresh={refresh}
            />
          </div>
        );
      case 2:
        return (
          <StudioSessionFiles
            api={api}
            conciergeReadOnly={conciergeReadOnly}
            studioSession={studioSession}
            instrumentSections={instrumentSections}
            instrumentFamilies={instrumentFamilies}
          />
        );
      default:
        return (
          <div style={{ padding: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography variant="h6" color="textPrimary">
                        {studioSession.archived ? "Archived" : "Archive"}
                      </Typography>
                      <Typography color="textSecondary">
                        {studioSession.archived
                          ? "Your work session is archived."
                          : "If your Work Session is done, you can archive it. It will show in the archive tab on the main list."}
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <div style={{ width: "100%" }}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        style={{ width: "100%" }}
                      >
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            disabled={conciergeReadOnly}
                            onClick={async () => {
                              await api.updateStudioSession(studioSession.id, {
                                archived: !studioSession.archived,
                              });
                              refresh();
                            }}
                          >
                            {!studioSession.archived
                              ? "Archive Session"
                              : "Unarchive Session"}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </ExpansionPanelDetails>
                  <Divider />
                </ExpansionPanel>
              </Grid>
              {studioSession.recorded ? (
                <Grid item xs={12}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div>
                        <Typography variant="h6" color="textPrimary">
                          Unlock Session
                        </Typography>
                        <Typography color="textSecondary">
                          Your session is locked.
                        </Typography>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                      <div style={{ width: "100%" }}>
                        <Grid
                          container
                          alignItems="center"
                          spacing={2}
                          style={{ width: "100%" }}
                        >
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              disabled={conciergeReadOnly}
                              onClick={async () => {
                                await api.updateStudioSession(
                                  studioSession.id,
                                  {
                                    recorded: false,
                                  }
                                );
                                refresh();
                              }}
                            >
                              Unlock Session
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </ExpansionPanelDetails>
                    <Divider />
                  </ExpansionPanel>
                </Grid>
              ) : (
                []
              )}
              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography variant="h6" color="textPrimary">
                        Transfer to another Project
                      </Typography>
                      <Typography color="textSecondary">
                        {"Change the Work Session's project."}
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <div style={{ width: "100%" }}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        style={{ width: "100%" }}
                      >
                        <Grid item xs={12} style={{ maxWidth: 300 }}>
                          <FormControl
                            disabled={conciergeReadOnly}
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel htmlFor="project-simple">
                              Project
                            </InputLabel>
                            <Select
                              inputProps={{
                                name: "Project",
                                id: "project-simple",
                              }}
                              value={studioSession.projectID || ""}
                              onChange={async (e) => {
                                await api.updateStudioSession(
                                  studioSession.id,
                                  {
                                    projectID: e.target.value,
                                  }
                                );
                                refresh();
                              }}
                            >
                              {projects
                                .filter((p) => !p.archived)
                                .map((p) => (
                                  <MenuItem
                                    key={`project_${p.id}`}
                                    value={p.id}
                                  >
                                    {p.name}
                                  </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Required</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </ExpansionPanelDetails>
                  <Divider />
                </ExpansionPanel>
              </Grid>
              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography variant="h6" color="textPrimary">
                        Duplicate Session
                      </Typography>
                      <Typography color="textSecondary">
                        Create a new work session that would be a copy of this
                        one. Musicians would be ready to invite.
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <div>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                          <Button
                            disabled={conciergeReadOnly}
                            variant="contained"
                            onClick={async () => {
                              const r = await api.duplicateSession(
                                studioSession.id
                              );
                              NotificationCenter.sweetAlert(
                                {
                                  title: "Success",
                                  subtitle: "Session has been duplicated",
                                  timestamp: new Date().getTime(),
                                  success: true,
                                },
                                {
                                  cancel: {
                                    label: "Close",
                                    level: "default",
                                    callback: () => console.log("Callback"),
                                  },
                                  confirm: {
                                    label: "Go to Duplicate",
                                    level: "success",
                                    callback: () =>
                                      window.location.replace(
                                        `${STUDIO_SESSION_DETAIL}?id=${r.payload.id}`
                                      ),
                                  },
                                }
                              );
                            }}
                          >
                            Duplicate (1 Credit)
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </ExpansionPanelDetails>
                  <Divider />
                </ExpansionPanel>
              </Grid>
              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography variant="h6" color="textPrimary">
                        Change Session Type
                      </Typography>
                      <Typography color="textSecondary">
                        Change the type of the Work Session.
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <div>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                          <FormControl
                            className={classes.formControl}
                            disabled={conciergeReadOnly}
                            fullWidth
                          >
                            <InputLabel htmlFor="session-simple">
                              Session Type
                            </InputLabel>
                            <Select
                              inputProps={{
                                name: "Project",
                                id: "session-simple",
                              }}
                              value={studioSession.sessionTypeID || ""}
                              onChange={async (e) => {
                                await api.updateStudioSession(
                                  studioSession.id,
                                  {
                                    sessionTypeID: e.target.value,
                                  }
                                );
                                refresh();
                              }}
                            >
                              {sessionTypes.map((st) => (
                                <MenuItem key={`st_${st.id}`} value={st.id}>
                                  {`${st.name} (${st.code})`}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Required</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </ExpansionPanelDetails>
                  <Divider />
                </ExpansionPanel>
              </Grid>
            </Grid>
          </div>
        );
    }
  }

  openGenerateBForm() {
    this.setState({ generateBFormOpen: true });
  }

  closeGenerateBForm() {
    this.setState({ generateBFormOpen: false });
  }

  openTips() {
    this.setState({ tipsOpen: true });
  }

  closeTips() {
    this.setState({ tipsOpen: false });
  }

  render() {
    const {
      classes,
      history,
      studioSession,
      loading,
      conciergeReadOnly,
      user,
    } = this.props;

    const { MercuryNotify, TourCenter } = this.context;

    const standalone = studioSession.standalone;

    const { index } = this.state;

    if (loading) {
      return (
        <div className={`${classes.background} page`}>
          <Loading message={"Loading Work Session"} />
        </div>
      );
    }

    return (
      <div className="page">
        <Helmet>
          <title>
            {`${studioSession.sessionType.code} - ${moment(
              studioSession.dateFromUTC
            ).format("LLL")}`}
          </title>
          <meta name="StudioSession" content="StudioSession page" />
        </Helmet>
        <Paper
          elevation={10}
          style={{
            borderRadius: 0,
          }}
          className={classes.subHeader}
        >
          <Hidden smDown>
            <Grid
              container
              style={{ padding: 10 }}
              justify="space-between"
              alignItems="center"
            >
              <Grid item style={{ padding: 6 }}>
                <Breadcrumbs
                  arial-label="Breadcrumb"
                  style={{
                    color: "white",
                  }}
                  separator="-"
                >
                  <Chip
                    label={"Back"}
                    className={classes.backChip}
                    onClick={() => goBack(history, STUDIO_SESSION)}
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Back
                          className={classes.icon}
                          style={{ color: "white" }}
                        />
                      </Avatar>
                    }
                  />
                  <LinkChip
                    icon={<i class="fad fa-folder-open"></i>}
                    link={`${PROJECT_DETAIL}?id=${studioSession.project.id}`}
                    label={studioSession.project.name}
                    history={history}
                  />
                  <LinkChip
                    icon={<i class="fad fa-calendar-day"></i>}
                    // label={`${studioSession.title || 'Untitled'} - ${moment.tz(studioSession.dateFromUTC, studioSession.tzName).format('MM/DD/YY h:mm a')}`}
                    label={`${studioSession.title || "Untitled"}`}
                    history={history}
                    noWrap
                  />
                  {studioSession && studioSession.studioID ? (
                    <LinkChip
                      icon={<i class="fad fa-location-dot"></i>}
                      label={studioSession.studioName}
                      link={`${STUDIO}?id=${studioSession.studioID}`}
                      history={history}
                      noWrap
                    />
                  ) : (
                    []
                  )}
                </Breadcrumbs>
              </Grid>
              {studioSession?.mercuryEnabled ? (
                <Grid item>
                  {conciergeReadOnly ? (
                    <div
                      style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        background: "#03a9f4",
                        padding: 4,
                        borderRadius: 4,
                        fontSize: 12,
                      }}
                    >
                      <i
                        style={{ marginRight: 8, fontSize: 18 }}
                        class="fad fa-concierge-bell"
                      ></i>
                      <div>
                        <b>Concierge Enabled</b>
                        <br />
                        <span style={{ fontSize: 10 }}>
                          Project is read only
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Chip
                        label={
                          <span>
                            <i class="fas fa-bullhorn p-right"></i> Nudge!
                          </span>
                        }
                        onClick={() =>
                          MercuryNotify.nudgeWorkSession(studioSession.id)
                        }
                        size="small"
                      />
                      <Chip
                        data-tut="reactour__notify"
                        label={
                          <span>
                            <i class="fas fa-paper-plane p-right"></i> Notify
                          </span>
                        }
                        onClick={() => {
                          if (TourCenter) TourCenter.hide();
                          MercuryNotify.workSession(studioSession.id);
                        }}
                        size="small"
                        style={{
                          marginLeft: 8,
                          background: "#2196f3",
                          color: "white",
                        }}
                      />
                    </>
                  )}
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Hidden>
          <Tabs
            value={index}
            className={classes.subHeader}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            <Tab
              data-tut="reactour__hiring"
              style={{ display: standalone ? "none" : undefined }}
              label={
                <div>
                  Hiring{" "}
                  {
                    //eslint-disable-line
                    studioSession.recorded || studioSession.archived ? (
                      <Tooltip title="Locked because Work Session has already been recorded or is archived.">
                        <Lock style={{ fontSize: 12 }} />
                      </Tooltip>
                    ) : (
                      []
                    )
                  }
                </div>
              }
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
                label: index === 0 ? classes.labelSelected : classes.label,
              }}
            />
            <Tab
              label={
                <div>
                  Engagements{" "}
                  <span style={{ fontSize: 10, opacity: 0.6 }}>
                    [Deprecated]
                  </span>
                </div>
              }
              style={{
                display:
                  !(
                    user?.uuid === "870ed2d4-ff26-43ca-8159-123c1d9bb980" ||
                    user?.services?.account?.indexOf("superadmin") !== -1
                  ) && "none",
              }}
              classes={{
                root: classes.tabRoot,
                label: index === 1 ? classes.labelSelected : classes.label,
              }}
            />
            <Tab
              label="Links"
              style={{ display: standalone ? "none" : undefined }}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
                label: index === 2 ? classes.labelSelected : classes.label,
              }}
            />
            <Tab
              label="Roster"
              style={{ display: standalone ? "none" : undefined }}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
                label: index === 3 ? classes.labelSelected : classes.label,
              }}
            />
            {studioSession.sessionTypeID === 13 ? (
              <CloneProps>
                {(tabProps) => (
                  <Tooltip title="Not available for rehearsals">
                    <div>
                      <Tab
                        {...tabProps}
                        disabled
                        label={<span>Payroll</span>}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                          disabled: classes.tabDisabled,
                          label:
                            index === 5 ? classes.labelSelected : classes.label,
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
              </CloneProps>
            ) : (
              <Tab
                label={<span>Payroll</span>}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  disabled: classes.tabDisabled,
                  label: index === 5 ? classes.labelSelected : classes.label,
                }}
              />
            )}
            {studioSession.sessionTypeID === 13 ? (
              <CloneProps>
                {(tabProps) => (
                  <Tooltip title="Not available for rehearsals">
                    <div>
                      <Tab
                        {...tabProps}
                        label={"Form Builder"}
                        disabled
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                          disabled: classes.tabDisabled,
                          label:
                            index === 4 ? classes.labelSelected : classes.label,
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
              </CloneProps>
            ) : (
              <Tab
                label={<span>Form Builder</span>}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  disabled: classes.tabDisabled,
                  label: index === 4 ? classes.labelSelected : classes.label,
                }}
              />
            )}
            <Tab
              label="Danger Zone"
              style={{ color: "#f44336" }}
              classes={{
                root: classes.tabRoot,
                selected: classes.dangerTabSelected,
                disabled: classes.tabDisabled,
                label:
                  index === 6
                    ? classes.dangerLabelSelected
                    : classes.dangerLabel,
              }}
            />
          </Tabs>
        </Paper>
        <div
          className={`${classes.background}`}
          style={{
            // height: 'calc(calc(var(--vh, 1vh) * 100) - 151px)',
            flexGrow: 1,
            overflowY: "scroll",
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {this.getStepContent()}
        </div>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(
  WrapperStudioSessionPage
);

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}
