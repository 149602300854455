import {
  GET_MUSICIAN_GROUPS,
} from 'constants/musicianGroups';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_MUSICIAN_GROUPS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
