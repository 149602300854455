export default (theme) => ({
  container: {
    padding: 5,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    border: "solid 1px rgba(155,155,155,0.3)",
    padding: 8,
    textAlign: "center",
    marginTop: -1,
  },
});
