// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperCustomerPage from "../component/WrapperCustomerPage";

const mapStateToProps = (state) => ({
  user: state.user,
  sessionTypes: state.sessionTypes,
  payrolls: state.payrolls,
  studios: state.payrolls,
  customers: state.customers,
  currentCompany: state.customers,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerById: (...args) => dispatch(Actions.getCustomerById(...args)),
  getCustomerStudioSessions: (...args) =>
    dispatch(Actions.getCustomerStudioSessions(...args)),
  getCustomerProjects: (...args) =>
    dispatch(Actions.getCustomerProjects(...args)),
  updateCustomer: (...args) => dispatch(Actions.updateCustomer(...args)),
  createProject: (...args) => dispatch(Actions.createProject(...args)),
  getSessionTypes: (...args) => dispatch(Actions.getSessionTypes(...args)),
  createStudioSession: (...args) =>
    dispatch(Actions.createStudioSession(...args)),
  getPayrolls: (...args) => dispatch(Actions.getPayrolls(...args)),
  getStudios: (...args) => dispatch(Actions.getStudios(...args)),
  createCustomer: (...args) => dispatch(Actions.createCustomer(...args)),
  createStudio: (...args) => dispatch(Actions.createStudio(...args)),
  createEngagement: (...args) => dispatch(Actions.createEngagement(...args)),
  getCustomers: (...args) => dispatch(Actions.getCustomers(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),
});
class CustomerPage extends PureComponent {
  static propTypes = {
    getCustomerById: PropTypes.func,
    getCustomerStudioSessions: PropTypes.func,
    getCustomerProjects: PropTypes.func,
    updateCustomer: PropTypes.func,
    createProject: PropTypes.func,
    getSessionTypes: PropTypes.func,
    createStudioSession: PropTypes.func,
    sessionTypes: PropTypes.array,
    history: PropTypes.object,
    location: PropTypes.object,
    getPayrolls: PropTypes.func,
    payrolls: PropTypes.array,
    getStudios: PropTypes.func,
    createEngagement: PropTypes.func,
    user: PropTypes.object,
    studios: PropTypes.array,
    createCustomer: PropTypes.func,
    customers: PropTypes.array,
    createStudio: PropTypes.func,
    currentCompany: PropTypes.object,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    const urlParams = getJsonFromUrl(location);
    this.state = {
      customerID: urlParams.id,
      customer: {},
      studioSessions: [],
      urlParams,
      loading: true,
    };
    this.refresh();
  }

  async refresh() {
    const {
      getCustomerById,
      getCustomerStudioSessions,
      getCustomerProjects,
      getSessionTypes,
      getPayrolls,
      getStudios,
      getCustomers,
      getCurrentCompany,
    } = this.props;
    const { customerID } = this.state;
    const resps = await Promise.all([
      getCustomerById(customerID),
      getCustomerStudioSessions(customerID),
      getCustomerProjects(customerID),
      getSessionTypes(),
      getPayrolls(),
      getStudios(),
      getCustomers(),
      getCurrentCompany(),
    ]);

    this.setState({
      customer: resps[0].payload,
      studioSessions: resps[1].payload,
      projects: resps[2].payload,
      refreshKey: Date.now(),
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      updateCustomer,
      createProject,
      sessionTypes,
      createStudioSession,
      payrolls,
      studios,
      user,
      createCustomer,
      customers,
      createEngagement,
      createStudio,
      currentCompany,
    } = this.props;

    const {
      urlParams,
      customer,
      studioSessions,
      projects,
      loading,
      refreshKey,
    } = this.state;

    return (
      <WrapperCustomerPage
        studios={studios}
        createEngagement={createEngagement}
        createProject={createProject}
        currentCompany={currentCompany}
        createStudioSession={createStudioSession}
        sessionTypes={sessionTypes}
        history={history}
        location={location}
        urlParams={urlParams}
        updateCustomer={updateCustomer}
        customer={customer}
        organizations={user.organizations}
        studioSessions={studioSessions}
        projects={projects}
        loading={loading}
        payrolls={payrolls}
        refresh={this.refresh.bind(this)}
        refreshKey={refreshKey}
        createStudio={createStudio}
        customers={customers}
        createCustomer={createCustomer}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
