import { networkAction } from "helpers/network/networkAction";

import {
  GET_TIES,
  GET_TIE_BY_ID,
  DELETE_TIE,
  UPDATE_TIE,
  CREATE_TIE,
} from "constants/ties";

import * as Api from "api";

export const getTies = () => async (dispatch) =>
  networkAction(dispatch, GET_TIES, Api.getTies, []);

export const getTieById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TIE_BY_ID, Api.getTieById, [id]);

export const deleteTie = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TIE, Api.deleteTie, [id]);

export const createTie = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TIE, Api.createTie, [body]);

export const updateTie = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TIE, Api.updateTie, [id, body]);
