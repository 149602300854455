export default (theme) => ({
  background: {
    background: theme.palette.background.default,
    padding: 20,
    flex: 1,
    display: "flex",
    height: 0,
    overflow: "scroll",
  },
  subHeader: {
    background: theme.palette.type === "dark" ? "#424242" : "#001E3C",
  },
  new: {
    fontSize: 12,
    padding: "0px 4px",
    background: "#ffc107",
    color: "#001E3C",
    borderRadius: 8,
    marginLeft: 6,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1) * 4,
    color: "white",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#ffc107",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#ffc107",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&$tabDisabled": {
      color: "rgba(255,255,255,0.3)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#ffc107",
    },
  },
  tabDisabled: {
    color: "rgba(255,255,255,0.3)",
  },
  floatingActionButton: {
    position: "fixed",
    right: 20,
    bottom: 20,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    border: "solid 1px rgba(155,155,155,0.3)",
    padding: 8,
    textAlign: "center",
    marginTop: -1,
  },
  labelSelected: {
    color: `${theme.palette.primary.color[600]} !important`,
  },
  label: {
    color: "white",
  },
  icon: {
    marginRight: theme.spacing(1) / 2,
    width: 20,
    height: 20,
  },
  link: {
    display: "flex",
  },
  chip: {
    backgroundColor: theme.palette.grey[100],
    height: 24,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
    },
  },
  backChip: {
    backgroundColor: "none",
    borderColor: "white",
    height: 24,
    color: "white",
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing(1) * 1.5,
  },
  inputPaper: {
    padding: 20,
    borderLeft: `solid 3px #ffc107`,
  },
  paperLoading: {
    padding: 20,
    borderLeft: "solid 3px #212121",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 4,
  },
});
