import { networkAction } from "helpers/network/networkAction";

import {
  GET_TRACKERS,
  GET_TRACKER_BY_ID,
  DELETE_TRACKER,
  UPDATE_TRACKER,
  CREATE_TRACKER,
} from "constants/trackers";

import * as Api from "api";

export const getTrackers = () => async (dispatch) =>
  networkAction(dispatch, GET_TRACKERS, Api.getTrackers, []);

export const getTrackerById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TRACKER_BY_ID, Api.getTrackerById, [id]);

export const deleteTracker = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TRACKER, Api.deleteTracker, [id]);

export const createTracker = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TRACKER, Api.createTracker, [body]);

export const updateTracker = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TRACKER, Api.updateTracker, [id, body]);
