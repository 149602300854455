import { GET, PUT, DELETE, POST, DATA } from "constants/methods";

const service = "rhapsody";
const companies = `/${service}/companies`;

export function getCompanies() {
  const url = `${companies}`;
  return {
    method: GET,
    url,
  };
}

export function uploadSignature(signatureImage) {
  const data = new FormData();
  data.append("file", signatureImage, "signature.png");

  const url = `${companies}/current/uploadSignature`;
  return {
    method: DATA,
    url,
    body: data,
  };
}

export function uploadLogo(logo) {
  const data = new FormData();
  data.append("file", logo, "logo.png");

  const url = `${companies}/current/uploadLogo`;
  return {
    method: DATA,
    url,
    body: data,
  };
}

export function getCurrentCompany() {
  const url = `${companies}/current`;
  return {
    method: GET,
    url,
  };
}

export function updateCurrentCompany(body) {
  const url = `${companies}/current`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function getCompanyById(id) {
  const url = `${companies}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCompany(id, body) {
  const url = `${companies}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCompany(id) {
  const url = `${companies}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCompany(body) {
  const url = `${companies}`;
  return {
    method: POST,
    url,
    body,
  };
}
