import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
// material-ui
import { withStyles } from "@material-ui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
// open source
import SweetAlert from "react-bootstrap-sweetalert";
// styles
import styles from "./styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

class NotificationCenter extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    errors: PropTypes.object,
    children: PropTypes.node,
  };

  static childContextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      alert: null,
      snackBarOpen: false,
    };
  }

  getChildContext() {
    return {
      NotificationCenter: {
        sweetAlert: this.sweetAlert.bind(this),
        stack: this.stack.bind(this),
        hide: this.hide.bind(this),
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { errors } = this.props;
    const nextErrors = nextProps.errors;

    try {
      if (nextErrors.notification) {
        if (errors && errors.notification) {
          if (
            errors.notification.message.timestamp !==
            nextErrors.notification.message.timestamp
          ) {
            this.stack(
              nextErrors.notification.message,
              nextErrors.notification.actions
            );
          }
        } else {
          this.stack(
            nextErrors.notification.message,
            nextErrors.notification.actions
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  sweetAlert(message, actions) {
    const { classes } = this.props;
    let confirmLevel = "default";
    let cancelLevel = "default";

    if (actions && actions.confirm && actions.confirm.level) {
      confirmLevel = actions.confirm.level;
    }

    if (actions && actions.cancel && actions.cancel.level) {
      cancelLevel = actions.cancel.level;
    }

    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={message.title}
          success={message.success}
          warning={message.warning}
          error={message.error}
          info={message.info}
          onConfirm={() => {
            this.hide();
            if (actions && actions.confirm && actions.confirm.callback) {
              actions.confirm.callback();
            }
          }}
          onCancel={() => {
            this.hide();
            if (actions && actions.cancel && actions.cancel.callback) {
              actions.cancel.callback();
            }
          }}
          confirmBtnCssClass={`${classes.button} ${classes[confirmLevel]}`}
          cancelBtnCssClass={`${classes.button} ${classes[cancelLevel]}`}
          confirmBtnText={
            actions && actions.confirm
              ? actions && actions.confirm.label
              : undefined
          }
          cancelBtnText={
            actions && actions.cancel
              ? actions && actions.cancel.label
              : undefined
          }
          showCancel={actions !== undefined && actions.cancel !== undefined}
          showConfirm={actions !== undefined && actions.confirm !== undefined}
        >
          <Typography variant="caption" gutterBottom align="center">
            {message.subtitle}
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            {message.body}
          </Typography>
        </SweetAlert>
      ),
    });
  }

  stack(message, actions) {
    this.setState({
      snackBarOpen: true,
      snackbarMessage: message,
      snackbarActions: actions,
    });
  }

  hide() {
    this.setState({
      alert: null,
      snackBarOpen: false,
    });
  }

  render() {
    const {
      alert,
      snackbarMessage,
      snackbarActions,
      snackBarOpen,
    } = this.state;

    const { children, classes } = this.props;

    let message = "";
    const actions = [];

    if (snackbarActions) {
      if (snackbarActions.cancel) {
        actions.push(
          <Button
            key="snack_cancel"
            className={classes[`${snackbarActions.cancel.level}Button`]}
            size="small"
            onClick={() => {
              this.hide();
              if (snackbarActions.cancel.callback) {
                snackbarActions.cancel.callback();
              }
            }}
          >
            {snackbarActions.cancel.label || "CANCEL"}
          </Button>
        );
      }

      if (snackbarActions.confirm) {
        actions.push(
          <Button
            key="snack_confirm"
            className={classnames(
              classes[`${snackbarActions.confirm.level}Button`],
              classes.leftMargin
            )}
            size="small"
            onClick={() => {
              this.hide();
              if (snackbarActions.confirm.callback) {
                snackbarActions.confirm.callback();
              }
            }}
          >
            {snackbarActions.confirm.label || "OK"}
          </Button>
        );
      }
    }

    let background;
    let Icon;
    if (snackbarMessage) {
      if (snackbarMessage.warning) {
        Icon = variantIcon.warning;
        background = "#ff9800";
      }

      if (snackbarMessage.success) {
        Icon = variantIcon.success;
        background = "#4caf50";
      }

      if (snackbarMessage.error) {
        Icon = variantIcon.error;
        background = "#f44336";
      }

      if (snackbarMessage.info) {
        Icon = variantIcon.info;
        background = "#2196f3";
      }

      message = (
        <div style={{ color: "white" }}>
          <Typography
            style={{ color: "white" }}
            variant="h6"
            gutterBottom
            align="left"
          >
            {Icon ? <Icon /> : []}
            {snackbarMessage.title}
          </Typography>
          <Typography
            style={{ color: "white" }}
            variant="caption"
            gutterBottom
            align="left"
          >
            {snackbarMessage.subtitle}
          </Typography>
          <Typography
            style={{ color: "white" }}
            variant="body1"
            gutterBottom
            align="left"
          >
            {snackbarMessage.body}
          </Typography>
        </div>
      );
    }

    return (
      <div>
        {children}
        {alert}
        {snackbarMessage && (
          <Snackbar
            key={snackbarMessage.timestamp}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            autoHideDuration={null}
            open={snackBarOpen}
            onClose={this.hide.bind(this)}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
          >
            <SnackbarContent
              message={message}
              action={actions}
              style={{
                background,
              }}
            />
          </Snackbar>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(NotificationCenter);
