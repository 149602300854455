// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
// component
import WrapperMyAccountPage from "../component/WrapperMyAccountPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  updateBazaarCustomer: (...args) =>
    dispatch(Actions.updateBazaarCustomer(...args)),
  getBazaarCurrentCustomer: (...args) =>
    dispatch(Actions.getBazaarCurrentCustomer(...args)),
  getCards: (...args) => dispatch(Actions.getCards(...args)),
  updateCard: (...args) => dispatch(Actions.updateCard(...args)),
  deleteCard: (...args) => dispatch(Actions.deleteCard(...args)),
  createCard: (...args) => dispatch(Actions.createCard(...args)),
  getPayments: (...args) => dispatch(Actions.getPayments(...args)),
  setCardAsFavorite: (...args) => dispatch(Actions.setCardAsFavorite(...args)),
  updateUser: (...args) => dispatch(Actions.updateUser(...args)),
  updateUserPassword: (...args) =>
    dispatch(Actions.updateUserPassword(...args)),
  getBazaarCustomerFull: (...args) =>
    dispatch(Actions.getBazaarCustomerFull(...args)),
  updateCurrentCompany: (...args) =>
    dispatch(Actions.updateCurrentCompany(...args)),
  upload: (...args) => dispatch(Actions.upload(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),

  createFileImport: (...args) => dispatch(Actions.createFileImport(...args)),
  deleteFileImport: (...args) => dispatch(Actions.deleteFileImport(...args)),
  updateFileImport: (...args) => dispatch(Actions.updateFileImport(...args)),
  getFileImports: (...args) => dispatch(Actions.getFileImports(...args)),

  updateOrganizationUser: (...args) =>
    dispatch(Actions.updateOrganizationUser(...args)),
  createOrganizationUser: (...args) =>
    dispatch(Actions.createOrganizationUser(...args)),
  getOrganizations: (...args) => dispatch(Actions.getOrganizations(...args)),
  getOrganizationById: (...args) =>
    dispatch(Actions.getOrganizationById(...args)),
  createOrganization: (...args) =>
    dispatch(Actions.createOrganization(...args)),
  deleteOrganization: (...args) =>
    dispatch(Actions.deleteOrganization(...args)),
  deleteOrganizationUser: (...args) =>
    dispatch(Actions.deleteOrganizationUser(...args)),
  updateOrganization: (...args) =>
    dispatch(Actions.updateOrganization(...args)),
  getOrganizationUsers: (...args) =>
    dispatch(Actions.getOrganizationUsers(...args)),
  addUserToOrganization: (...args) =>
    dispatch(Actions.addUserToOrganization(...args)),

  getTitlePatterns: (...args) => dispatch(Actions.getTitlePatterns(...args)),
  updateTitlePattern: (...args) =>
    dispatch(Actions.updateTitlePattern(...args)),
  deleteTitlePattern: (...args) =>
    dispatch(Actions.deleteTitlePattern(...args)),
  createTitlePattern: (...args) =>
    dispatch(Actions.createTitlePattern(...args)),
});
class MyAccountPage extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    app: PropTypes.object,
    getCards: PropTypes.func,
    updateBazaarCustomer: PropTypes.func,
    deleteCard: PropTypes.func,
    createCard: PropTypes.func,
    updateCard: PropTypes.func,
    getPayments: PropTypes.func,
    payments: PropTypes.func,
    getBazaarCustomerFull: PropTypes.func,
    updateCurrentCompany: PropTypes.func,
    setCardAsFavorite: PropTypes.func,
    cards: PropTypes.array,
    updateUser: PropTypes.func,
    updateUserPassword: PropTypes.func,
    getBazaarCurrentCustomer: PropTypes.func,
    upload: PropTypes.func,
    getCurrentCompany: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    createFileImport: PropTypes.func,
    deleteFileImport: PropTypes.func,
    updateFileImport: PropTypes.func,
    getFileImports: PropTypes.func,
    getOrganizations: PropTypes.func,
    getOrganizationById: PropTypes.func,
    createOrganization: PropTypes.func,
    deleteOrganization: PropTypes.func,
    updateOrganization: PropTypes.func,
    updateOrganizationUser: PropTypes.func,
    createOrganizationUser: PropTypes.func,
    getOrganizationUsers: PropTypes.func,
    addUserToOrganization: PropTypes.func,
    deleteOrganizationUser: PropTypes.func,

    getTitlePatterns: PropTypes.func,
    updateTitlePattern: PropTypes.func,
    deleteTitlePattern: PropTypes.func,
    createTitlePattern: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const {
      getCards,
      getCurrentUser,
      getBazaarCurrentCustomer,
      getBazaarCustomerFull,
      getCurrentCompany,
      getOrganizations,
      getTitlePatterns,
    } = this.props;

    const resps = await Promise.all([
      getCards(),
      getCurrentUser(),
      getBazaarCurrentCustomer(),
      getCurrentCompany(),
      getOrganizations(),
      getTitlePatterns(),
    ]);

    const resp = await getBazaarCustomerFull(resps[2].payload.id);

    this.setState({
      loading: false,
      customer: resp.payload,
      company: resps[3].payload,
      titlePatterns: resps[5].payload,
    });
  }

  render() {
    const { loading, customer, company, titlePatterns } = this.state;

    const {
      user,
      history,
      cards,
      app,
      organizations,
      updateUser,
      updateUserPassword,
      updateBazaarCustomer,
      updateCard,
      deleteCard,
      createCard,
      setCardAsFavorite,
      upload,
      updateCurrentCompany,
      createFileImport,
      deleteFileImport,
      updateFileImport,
      getFileImports,
      getOrganizationById,
      createOrganization,
      deleteOrganization,
      updateOrganization,
      getOrganizationUsers,
      deleteOrganizationUser,
      createOrganizationUser,
      getOrganizations,
      updateOrganizationUser,
      addUserToOrganization,

      getTitlePatterns,
      updateTitlePattern,
      deleteTitlePattern,
      createTitlePattern,
    } = this.props;

    const api = {
      updateUser,
      updateUserPassword,
      updateBazaarCustomer,
      updateCard,
      deleteCard,
      createCard,
      setCardAsFavorite,
      upload,
      updateCurrentCompany,
      createFileImport,
      deleteFileImport,
      updateFileImport,
      getFileImports,
      getOrganizationById,
      createOrganization,
      deleteOrganization,
      updateOrganization,
      getOrganizationUsers,
      deleteOrganizationUser,
      createOrganizationUser,
      getOrganizations,
      updateOrganizationUser,
      addUserToOrganization,
      getTitlePatterns,
      updateTitlePattern,
      deleteTitlePattern,
      createTitlePattern,
    };

    return (
      <WrapperMyAccountPage
        history={history}
        api={api}
        customer={customer}
        refresh={this.refresh.bind(this)}
        user={user}
        cards={cards}
        urlParams={getJsonFromUrl(window.location)}
        loading={loading}
        app={app}
        company={company}
        titlePatterns={titlePatterns}
        organizations={organizations}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage);
