// material-ui
import { withStyles } from "@material-ui/styles";
import { Centrifuge } from "centrifuge";
// constants
import { CENTRIFUGO_TOKEN, CENTRIFUGO_URL } from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class Centrifugo extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    websocketMessageReceived: PropTypes.func,
    children: PropTypes.node,
  };

  static childContextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    console.log(CENTRIFUGO_URL, CENTRIFUGO_TOKEN);
    const _this = this;
    const centrifuge = new Centrifuge(CENTRIFUGO_URL, {
      token: CENTRIFUGO_TOKEN,
      debug: true,
    });
    // centrifuge.on("disconnected", function (ctx) {
    //   console.log("disconnected");
    //   console.log(ctx);
    //   // debugger;
    // });
    // centrifuge.on("connecting", function (ctx) {
    //   console.log("connecting");
    //   // debugger;
    // });
    centrifuge.on("connected", function (ctx) {
      _this.setState({ centrifuge });
    });
    // centrifuge.on("error", function (ctx) {
    //   console.log("error");
    //   console.log(ctx);
    //   // debugger;
    // });
    centrifuge.connect();
    // setInterval(() => console.log(centrifuge), 1000);
    // debugger;

    this.state = {
      centrifuge: null,
    };
  }

  getChildContext() {
    return {
      WebsocketCenter: {
        subscribe: this.subscribe.bind(this),
        getInstance: this.getInstance.bind(this),
      },
    };
  }

  getInstance() {
    return this.state.centrifuge;
  }

  subscribe(channel, onMessageReceived) {
    if (this.state.centrifuge._subs[channel]) return;
    const { websocketMessageReceived } = this.props;
    const subscription = this.state.centrifuge?.newSubscription(channel);
    subscription.on("publication", (m) => {
      // console.log(m);
      // debugger;
      if (onMessageReceived) onMessageReceived(m);
      websocketMessageReceived(m);
    });
    subscription.subscribe();
    return subscription;
  }

  render() {
    const { children } = this.props;

    return (
      <React.Fragment>{this.state.centrifuge ? children : []}</React.Fragment>
    );
  }
}

export default withStyles(styles)(Centrifugo);
