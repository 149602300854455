import { networkAction } from "helpers/network/networkAction";

import {
  GET_MUSICIANS,
  GET_MUSICIAN_BY_ID,
  GET_MUSICIAN_STUDIO_SESSIONS,
  GET_MUSICIAN_PROJECTS,
  DELETE_MUSICIAN,
  UPDATE_MUSICIAN,
  CREATE_MUSICIAN,
  CREATE_MUSICIAN_INSTRUMENT,
  DELETE_MUSICIAN_INSTRUMENT,
  JOIN_REQUEST,
} from "constants/musicians";

import * as Api from "api";

export const getMusicians = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_MUSICIANS, Api.getMusicians, [filters]);

export const getMusicianById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MUSICIAN_BY_ID, Api.getMusicianById, [id]);

export const getMusicianStudioSessions = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_MUSICIAN_STUDIO_SESSIONS,
    Api.getMusicianStudioSessions,
    [id]
  );

export const getMusicianProjects = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MUSICIAN_PROJECTS, Api.getMusicianProjects, [id]);

export const deleteMusician = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MUSICIAN, Api.deleteMusician, [id]);

export const createMusician = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MUSICIAN, Api.createMusician, [body]);

export const updateMusician = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MUSICIAN, Api.updateMusician, [id, body]);

export const joinRequest = (id) => async (dispatch) =>
  networkAction(dispatch, JOIN_REQUEST, Api.joinRequest, [id]);

export const createMusicianInstrument = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_MUSICIAN_INSTRUMENT,
    Api.createMusicianInstrument,
    [id, body]
  );

export const deleteMusicianInstrument = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_MUSICIAN_INSTRUMENT,
    Api.deleteMusicianInstrument,
    [id, body]
  );
