import { S } from "constants/routes";
import { checkAccess } from "helpers/auth";
import SPage from "./container/SPage";

export default function getSettingsRoute(userAuth) {
  const requireAuth = {};
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: S,
    withSidebar: false,
    withAppBar: false,
    component: SPage,
  };
}
