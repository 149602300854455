// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperStudioPage from "../component/WrapperStudioPage";

const mapStateToProps = (state) => ({
  studios: state.studios,
});

const mapDispatchToProps = (dispatch) => ({
  getStudios: () => dispatch(Actions.getStudios()),
  getStudioById: (...args) => dispatch(Actions.getStudioById(...args)),
  createStudio: (...args) => dispatch(Actions.createStudio(...args)),
  deleteStudio: (...args) => dispatch(Actions.deleteStudio(...args)),
  updateStudio: (...args) => dispatch(Actions.updateStudio(...args)),
});
class StudioPage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    studios: PropTypes.array,
    getStudios: PropTypes.func,
    getStudioById: PropTypes.func,
    createStudio: PropTypes.func,
    deleteStudio: PropTypes.func,
    updateStudio: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, studios } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: studios.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getStudios } = this.props;
    await Promise.all([getStudios()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      studios,
      getStudioById,
      createStudio,
      deleteStudio,
      updateStudio,
    } = this.props;

    const { loading } = this.state;

    return (
      <WrapperStudioPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        studios={studios}
        getStudioById={getStudioById}
        createStudio={createStudio}
        deleteStudio={deleteStudio}
        updateStudio={updateStudio}
        urlParams={getJsonFromUrl(window.location)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudioPage);
