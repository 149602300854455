import { Avatar, Chip, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import * as Actions from "actions";
import AdvancedSearch from "components/AdvancedSearchRefactor";
import Table from "components/TablePagination";
import { PROJECT_DETAIL, TEMPLATE } from "constants/routes";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  customers: state.customers,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: (...args) => dispatch(Actions.getProjects(...args)),
});

class ProjectsList extends PureComponent {
  static propTypes = {
    // classes: PropTypes.object,
    projects: PropTypes.array,
    theme: PropTypes.object,
    history: PropTypes.object,
    addButton: PropTypes.node,
    getProjects: PropTypes.function,
    customers: PropTypes.array,
    currentCompany: PropTypes.object,
    template: PropTypes.bool,
  };

  static contextTypes = {
    // classes: PropTypes.object,
    TourCenter: PropTypes.array,
  };

  render() {
    const {
      projects,
      history,
      addButton,
      currentCompany,
      getProjects,
      customers,
      template,
    } = this.props;
    const { TourCenter } = this.context;

    if (projects.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              {" "}
              <i
                style={{ fontSize: 70 }}
                class={
                  template
                    ? "fa-duotone fa-square-dashed"
                    : "fad fa-folder-open"
                }
              ></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No {template ? "Templates" : "Projects"}
            </Typography>
            {template ? (
              <Typography variant="caption" color="textSecondary">
                A template is a call order you can use to autofill new projects
              </Typography>
            ) : (
              <Typography variant="caption" color="textSecondary">
                A project is a group of work sessions
                <br />
                for a specific customer.
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Chip
              onClick={() => TourCenter.start(2)}
              label={template ? "+ New Template" : "+ New Project"}
            />
          </Grid>
        </Grid>
      );
    }

    const regularMeta = [
      {
        path: "id",
        title: "ID",
        width: 20,
      },
      {
        path: "name",
        title: "Project Name",
        numeric: false,
        transform: (t, d) => `${d.name} ${d.customerName}`,
        render: (t, d) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {d.concierge ? (
              <Avatar
                style={{
                  background: "#03a9f4",
                  height: 28,
                  width: 28,
                  marginRight: 8,
                }}
              >
                <Icon
                  className="fad fa-concierge-bell"
                  style={{ fontSize: 14 }}
                />
              </Avatar>
            ) : (
              []
            )}
            <div style={{ lineHeight: 0 }}>
              <Typography variant="body2">{d.name}</Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ lineHeight: 1 }}
              >
                {d.customerName}
                {d.concierge ? "Concierge Enabled" : []}
              </Typography>
            </div>
          </div>
        ),
      },
      {
        path: "logoURL",
        title: "",
        hidden: { smDown: true },
        numeric: false,
        render: (t) =>
          t !== "undefined" ? (
            <div
              style={{
                height: 40,
                width: 204,
                borderRadius: 4,
                border: "solid 1px rgba(155,155,155,0.3)",
                textAlign: "center",
              }}
            >
              <img src={t} alt="project logo" style={{ height: 40 }} />
            </div>
          ) : (
            <div
              style={{
                height: 40,
                width: 204,
                borderRadius: 4,
                border: "solid 1px rgba(155,155,155,0.3)",
                background: "rgba(155,155,155,0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {currentCompany && currentCompany.logoURL ? (
                <img
                  src={currentCompany.logoURL}
                  alt="project logo"
                  style={{ height: 40 }}
                />
              ) : (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ opacity: 0.5 }}
                >
                  No logo
                </Typography>
              )}
            </div>
          ),
      },
      {
        path: "id",
        hidden: { smDown: true },
        numeric: false,
        render: (t, d) => (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ArrowForwardIosIcon style={{ fontSize: 14 }} />
          </div>
        ),
      },
    ];

    const templateMeta = [
      {
        path: "id",
        title: "ID",
        width: 20,
      },
      {
        path: "name",
        title: "Template Name",
        numeric: false,
        transform: (t, d) => `${d.name} ${d.customerName}`,
        render: (t, d) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ lineHeight: 0 }}>
              <Typography variant="body2">{d.name}</Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ lineHeight: 1 }}
              >
                {d.customerName}
              </Typography>
            </div>
          </div>
        ),
      },
      {
        path: "id",
        hidden: { smDown: true },
        numeric: false,
        render: (t, d) => (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ArrowForwardIosIcon style={{ fontSize: 14 }} />
          </div>
        ),
      },
    ];

    return (
      <Grid container>
        <Grid item xs={12}>
          {addButton}
        </Grid>
        <Grid item xs={12}>
          <AdvancedSearch
            history={history}
            rowCount={projects.length}
            location={window.location}
            refresh={getProjects}
            keyword="projects"
            metaDataEndpoint="/rhapsody/projects/searchMetadata"
            metaDataOveride={{
              customerID: {
                type: "autocomplete",
                data: customers,
                valueKey: "id",
                labelKey: "name",
              },
            }}
          >
            <Table
              data={projects && projects.sort((a, b) => b.id - a.id)}
              rowStyle={(d) => ({
                borderLeft: `solid 4px ${d.concierge ? "#03a9f4" : d.color}`,
              })}
              meta={template ? templateMeta : regularMeta}
              title={template ? "Templates" : "Projects"}
              onRowSelect={(project) =>
                history.push(
                  template
                    ? `${TEMPLATE}?id=${project.id}`
                    : `${PROJECT_DETAIL}?id=${project.id}`
                )
              }
            />
          </AdvancedSearch>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(ProjectsList))
);
