import {
  RESTRICTION_440,
  RESTRICTION_RESPONSE_TEST,
} from "constants/restriction440";

import { networkAction } from "helpers/network/networkAction";

import * as Api from "api";

export const restriction440 = (body) => async (dispatch) =>
  dispatch({
    type: RESTRICTION_440,
    payload: body,
  });

export const responseTest = (id) => async (dispatch) =>
  networkAction(dispatch, RESTRICTION_RESPONSE_TEST, Api.responseTest, [id]);
