export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  container: {
    background: theme.palette.type === "dark" ? "#212121" : "#EEF2F6",
    flexGrow: 1,
    padding: 16,
  },
  h5: {
    fontFamily: "poppins, sans-serif",
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 1,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  chip: {
    backgroundColor: theme.palette.grey[100],
    height: 24,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
    },
  },
  backChip: {
    backgroundColor: "none",
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing(1) * 1.5,
  },
  paper: {
    padding: 16,
    margin: "16px 0px 0px 0px",
    boxShadow:
      "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
    textAlign: "center",
    borderRadius: 4,
    cursor: "pointer",
  },
});
