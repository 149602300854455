import { Grid, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import { typeOptions } from "constants/workSessionTypeOptions";
import { getJsonFromUrl } from "helpers";
import { isNumeric } from "helpers/form/typeCheckers";
import AdjustableNumber from "hooks/AdjustableNumber";
import WorkSession from "hooks/WorkSession";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function AddWorkSessions({ open, close, project }) {
  const dispatch = useDispatch();
  const [workSessions, setWorkSessions] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [workSessionNeeds, setWorkSessionNeeds] = React.useState({});
  const [checkForErrors, setCheckForErrors] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);
  const [errorFeedbackCount, setErrorFeedbackCount] = React.useState(0);
  const [closeRequested, setCloseRequested] = React.useState(false);
  const classes = useStyles(styles);
  const urlParams = getJsonFromUrl(window.location);
  const standalone = urlParams.standalone !== undefined;

  const projectSections = useSelector((state) => state.projectSections);

  React.useEffect(() => {
    if (!projectSections) {
      dispatch(Actions.getProjectSections(project.id));
    }
  }, [open]);

  React.useEffect(() => {
    if (!open && workSessions.length) {
      processMetaData();
      setWorkSessions([]);
    }
  }, [open]); //eslint-disable-line

  React.useEffect(() => {
    if (
      closeRequested &&
      errorFeedbackCount === workSessions.length &&
      !hasError
    ) {
      onClose();
    } else if (errorFeedbackCount === workSessions.length) {
      setCloseRequested(false);
    }
  }, [errorFeedbackCount]); //eslint-disable-line

  function addWorkSession(title, sessionTypeID) {
    const _workSessions = [...workSessions];
    _workSessions.push({
      tmpID: new Date().getTime(),
      title,
      sessionTypeID,
    });
    setWorkSessions(_workSessions);
  }

  const onDelete = (i) => () => {
    const _workSessions = [...workSessions];
    _workSessions.splice(i, 1);
    setWorkSessions(_workSessions);
  };

  const createCallBack = (tmpID) => (workSession) => {
    const _workSessions = [...workSessions];
    const index = _workSessions.findIndex((e) => e.tmpID === tmpID);
    if (index !== -1) {
      _workSessions[index] = {
        tmpID: new Date().getTime(),
        id: workSession.id,
        icon: workSession.icon,
      };
    }
    setWorkSessions(_workSessions);
  };

  function processMetaData() {
    for (const key in workSessions) {
      if (Object.hasOwnProperty.call(workSessions, key)) {
        const ws = workSessions[key];
        if (ws.id) {
          dispatch(Actions.processMetadata(ws.id));
        }
      }
    }
  }

  function errorFeedback(v) {
    if (v) setHasError(true);
    console.log("errorFeedback", errorFeedbackCount);
    setErrorFeedbackCount((prev) => prev + 1);
  }

  function proceed() {
    if (activeStep === 0) {
      const _wsToAdd = [];
      for (const key in workSessionNeeds) {
        if (Object.hasOwnProperty.call(workSessionNeeds, key)) {
          const workSessionNeed = workSessionNeeds[key];
          const typeOption = typeOptions.find((t) => t.id === Number(key));

          for (let index = 0; index < Number(workSessionNeed); index++) {
            _wsToAdd.push({
              tmpID: new Date().getTime(),
              title: `${typeOption.title} #${index + 1}`,
              sessionTypeID: typeOption.sessionTypeID,
              icon: typeOption.icon,
            });
          }
        }
      }
      setWorkSessions(_wsToAdd);
      setActiveStep((s) => s + 1);
    }

    if (activeStep === 1) {
      setCloseRequested(true);
      setHasError(false);
      setErrorFeedbackCount(0);
      setCheckForErrors((prev) => prev + 1);
    }
  }

  function onClose() {
    setCheckForErrors(0);
    setActiveStep(0);
    setWorkSessionNeeds({});
    close();
  }

  const onWorkSessionNeedsChange = (id) => (e) => {
    if (isNumeric(e.target.value)) {
      setWorkSessionNeeds({ ...workSessionNeeds, [id]: e.target.value });
    }
  };

  const onWorkSessionNeedsAdd = (id) => () => {
    if (isNumeric(workSessionNeeds[id])) {
      setWorkSessionNeeds({
        ...workSessionNeeds,
        [id]: `${parseInt(workSessionNeeds[id], 10) + 1}`,
      });
    } else {
      setWorkSessionNeeds({
        ...workSessionNeeds,
        [id]: "1",
      });
    }
  };

  const onWorkSessionNeedsRemove = (id) => () => {
    if (isNumeric(workSessionNeeds[id])) {
      const value = parseInt(workSessionNeeds[id], 10);
      if (value > 1) {
        setWorkSessionNeeds({
          ...workSessionNeeds,
          [id]: `${parseInt(workSessionNeeds[id], 10) - 1}`,
        });
      } else {
        const _workSessionNeeds = { ...workSessionNeeds };
        delete _workSessionNeeds[id];
        setWorkSessionNeeds(_workSessionNeeds);
      }
    }
  };

  const getActiveStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="info">
                Select the number of Work Sessions for your Project from the
                selection below:
              </Alert>
            </Grid>
            {typeOptions.map((o) => (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px 16px",
                }}
              >
                <Typography variant="body1">
                  <i style={{ width: 40, fontSize: 20 }} class={o.icon}></i>
                  {o.title}
                </Typography>
                <AdjustableNumber
                  background={"#2196f3"}
                  add={onWorkSessionNeedsAdd(o.id)}
                  remove={onWorkSessionNeedsRemove(o.id)}
                  onChange={onWorkSessionNeedsChange(o.id)}
                  value={workSessionNeeds[o.id] ?? "0"}
                />
              </Grid>
            ))}
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Alert severity="info">
                Click on the work session(s) to configure the date, location,
                and the instruments.
              </Alert>
            </Grid>
            {workSessions.map((d, i) => (
              <Grid item xs={12}>
                <WorkSession
                  checkForErrors={checkForErrors}
                  workSessionID={d.id}
                  projectID={project.id}
                  defaultTitle={d.title}
                  defaultSessionTypeID={d.sessionTypeID}
                  onCreate={createCallBack(d.tmpID)}
                  errorFeedback={errorFeedback}
                  icon={d.icon}
                  // onDelete={!d.id && onDelete(i)}
                  // standalone={standalone}
                />
              </Grid>
            ))}
            {/* <Grid item>
              <Chip
                label={`+ Another ${
                  standalone ? "Payroll Form" : "Work Session"
                }`}
                variant="outlined"
                size="small"
                onClick={addWorkSession}
              />
            </Grid> */}
          </Grid>
        );
      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ margin: 30 }}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogContent>
        <Grid container spacing={1} style={{ maxWidth: 600, margin: "auto" }}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6">
              {standalone ? "Payroll Form" : "Add Work Session(s)"} for{" "}
              {project.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stepper
              style={{ background: "none" }}
              alternativeLabel
              activeStep={activeStep}
            >
              <Step>
                <StepLabel>What do you need?</StepLabel>
              </Step>
              <Step>
                <StepLabel>Work Session Details</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            {getActiveStepContent()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        {!workSessions.find((s) => s.id) ? (
          <Button onClick={onClose} variant="contained" fullWidth>
            Cancel
          </Button>
        ) : (
          []
        )}
        <Button onClick={proceed} color="primary" variant="contained" fullWidth>
          {activeStep === 0 ? (
            <>
              Next <i class="far fa-chevron-right p-left"></i>
            </>
          ) : (
            <>Done</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
