import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import MobileStepper from "@material-ui/core/MobileStepper";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class Tips extends React.Component {
  static propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object,
    fullScreen: PropTypes.bool.isRequired,
    close: PropTypes.bool,
    open: PropTypes.func,
  };

  state = {
    activeStep: 0,
  };

  getStepContent() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    switch (activeStep) {
      case 0:
        return (
          <Grid
            container
            justify="center"
            spacing={32}
            style={{ textAlign: "center", marginBottom: 20 }}
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">
                Edit Multiple Cell at the Same Time
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body" color="textSecondary">
                Press
              </Typography>
              <Typography variant="h3" className={classes.alt}>
                alt
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h3" color="textSecondary">
                +
              </Typography>
            </Grid>
            <Grid xs={8}>
              <video width="324" height="290" loop="true" autoplay="autoplay">
                <source
                  src="https://storage.googleapis.com/rhapsody/media/multiple_cell_edit.mov"
                  type="video/mp4"
                />
              </video>
              <Typography variant="caption" color="textSecondary">
                Press enter when edits are done to save
              </Typography>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid
            container
            justify="center"
            spacing={32}
            style={{ textAlign: "center", marginBottom: 20 }}
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                Click on the Pension & Welfare Button to edit all
              </Typography>
            </Grid>
            <Grid xs={12}>
              <video width="400" loop="true" autoplay="autoplay">
                <source
                  src="https://storage.googleapis.com/rhapsody/media/welfare_edits.mov"
                  type="video/mp4"
                />
              </video>
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  }

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  render() {
    const { fullScreen, open, close, theme } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          {this.getStepContent()}
          <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={this.state.activeStep}
            nextButton={
              <Button
                size="small"
                onClick={this.handleNext}
                disabled={this.state.activeStep === 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={this.handleBack}
                disabled={this.state.activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles, { withTheme: true })(Tips)
);
