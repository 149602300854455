import { Collapse, Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
// material-ui
import { withStyles } from "@material-ui/styles";
import { PROJECT_DETAIL, TEMPLATE } from "constants/routes";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class FormProject extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    history: PropTypes.object,
    close: PropTypes.func,
    open: PropTypes.bool,

    project: PropTypes.object,
    customers: PropTypes.array,

    createProject: PropTypes.func,
    updateProject: PropTypes.func,
    deleteProject: PropTypes.func,
    refresh: PropTypes.func,

    template: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      customerID: "",
      dateFrom: "",
      dateTo: "",
      composer: "",
      agreement: "",
      contractor: "",
      mercuryEnabled: false,
      collapsed: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.project);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(project) {
    this.setState({
      loading: false,
      name: project ? project.name : "",
      customerID: project ? project.customerID : "",
      dateFrom: project ? project.dateFrom : "",
      dateTo: project ? project.dateTo : "",
      composer: project ? project.composer : "",
      agreement: project ? project.agreement : "",
      contractor: project ? project.contractor : "",
      mercuryEnabled: project ? project.mercuryEnabled : false,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this project, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteProject, close, refresh, project } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteProject(project.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Project has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateProject, createProject, project, history, template } =
      this.props;

    const {
      name,
      customerID,
      dateFrom,
      dateTo,
      composer,
      agreement,
      contractor,
      mercuryEnabled,
    } = this.state;

    let resp;
    if (project) {
      resp = await updateProject(project.id, {
        name,
        customerID: customerID?.length ? customerID : undefined,
        dateFrom,
        dateTo,
        composer,
        contractor,
        agreement,
        mercuryEnabled,
        template,
      });
    } else {
      resp = await createProject({
        name,
        customerID: customerID?.length ? customerID : undefined,
        dateFrom,
        dateTo,
        contractor,
        composer,
        agreement,
        mercuryEnabled,
        template,
      });
    }

    this.setState({ loading: false });

    if (resp && resp.success) {
      const projectID = resp.payload.id;
      if (template) {
        history.push(
          `${TEMPLATE}?id=${projectID}&index=0&instrumentFamiliesMenu`
        );
      } else {
        history.push(
          `${PROJECT_DETAIL}?id=${projectID}&index=0&instrumentFamiliesMenu&instrumentationRequired=true`
        );
      }
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      project,
      close,
      customers,
      history,
      template,
    } = this.props;

    const {
      loading,
      name,
      contractor,
      composer,
      agreement,
      projectID,
      mercuryEnabled,
      collapsed,
    } = this.state;

    const disabled = name.length === 0;

    return (
      <>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          scroll="paper"
          aria-labelledby="responsive-dialog-title"
        >
          {loading ? (
            <div className={classes.progressContainer}>
              <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <DialogContent>
                <div
                  style={{
                    background: "#e3f2fd",
                    padding: 16,
                    borderRadius: 4,
                    textAlign: "center",
                  }}
                >
                  <i
                    style={{ fontSize: 80, color: "#001E3C" }}
                    class={
                      template
                        ? "fa-duotone fa-square-dashed"
                        : "fad fa-folder-open"
                    }
                  ></i>
                  <Typography variant="h6" style={{ color: "#001E3C" }}>
                    {template ? "New Template" : "New Project"}
                  </Typography>
                  <Typography
                    style={{ color: "#001E3C", opacity: 0.8 }}
                    variant="body2"
                  >
                    {template
                      ? "A template is a call order you can use to autofill new projects"
                      : "Create a new project to start the contracting process."}
                  </Typography>
                </div>
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                  <Grid item xs={12}>
                    <TextField
                      id="name"
                      autoFocus
                      helperText="Required"
                      label={template ? "Template Name" : "Project Name"}
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={name}
                      onChange={this.handleChange("name")}
                    />
                  </Grid>
                  {!template ? (
                    <>
                      <Grid item xs={12}>
                        <Divider />
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState((s) => ({ collapsed: !s.collapsed }))
                          }
                        >
                          <i class="fas fa-sliders-v"></i> Advanced Settings{" "}
                          {collapsed ? (
                            <i class="fas fa-sort-down"></i>
                          ) : (
                            <i class="fas fa-sort-up"></i>
                          )}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    []
                  )}
                </Grid>
                <Collapse in={!collapsed}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={customers}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        onChange={(e, v) =>
                          this.setState({ customerID: v ? v.id : "" })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            size="small"
                            placeholder="Customer / Billing Entity"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="composer"
                        label="Composer"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={composer}
                        onChange={this.handleChange("composer")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="contractor"
                        label="Contractor"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={contractor}
                        onChange={this.handleChange("contractor")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="agreement"
                        label="Wage Scale Agreement"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={agreement}
                        onChange={this.handleChange("agreement")}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                {project && (
                  <Button
                    className={classes.delete}
                    onClick={this.delete.bind(this)}
                  >
                    <DeleteIcon className={classes.leftIcon} />
                    &nbsp;Delete
                  </Button>
                )}
                <Button onClick={close}>
                  <CloseIcon className={classes.leftIcon} />
                  &nbsp;Cancel
                </Button>
                <Button
                  color="secondary"
                  autoFocus
                  style={{
                    background: "#2196f3",
                    color: "white",
                    opacity: disabled ? 0.5 : 1,
                  }}
                  onClick={this.save.bind(this)}
                  disabled={disabled}
                >
                  Next <i class="far fa-chevron-right p-left"></i>
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormProject));
