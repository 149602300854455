export default (theme) => ({
  container: {
    padding: 5,
    fontFamily: "poppins, sans-serif !important",
  },
  alphapipe: {
    fontFamily: "bc-alphapipe, sans-serif",
    padding: "4px 6px",
    minWidth: 30,
    width: 30,
    margin: "auto",
    height: 30,
    border: `solid ${30 * 0.05}px #ffc107`,
    color: "#ffc107",
    background: "#001E3C",
    fontSize: 30 * 0.5,
    borderTopRightRadius: 30 * 0.5,
    borderBottomLeftRadius: 30 * 0.5,
  },
  plan: {
    boxShadow: "0 6px 28px 0 rgba(24,52,117,0.2)",
    border:
      theme.palette.type === "dark" ? "solid 1px black" : "solid 1px white",
    transition: "box-shadow 0.3s ease-in-out",
    color: theme.palette.type === "dark" ? "white" : "black",
    textAlign: "center",
    minHeight: 60,
    padding: 12,
  },
  alpha: {
    fontFamily: "bc-alphapipe, sans-serif",
    fontSize: 30,
  },
  slider: {
    background: "#2196f3 !important",
    backgroundColor: "#2196f3 !important",
    color: "#2196f3 !important",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  cssLabel: {
    "&$cssFocused": {
      color: "#2196f3",
    },
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: "#2196f3",
    },
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#2196f3",
    },
  },
  notchedOutline: {},
  bootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing(1) * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  sliderroot: {
    color: "#2196f3",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid #2196f3",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
});
