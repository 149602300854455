import { networkAction } from "helpers/network/networkAction";

import {
  GET_INSTRUMENT_FAMILIES,
  GET_INSTRUMENT_FAMILY_BY_ID,
  DELETE_INSTRUMENT_FAMILY,
  UPDATE_INSTRUMENT_FAMILY,
  CREATE_INSTRUMENT_FAMILY,
} from "constants/instrumentFamilies";

import * as Api from "api";

export const getInstrumentFamilies = () => async (dispatch) =>
  networkAction(
    dispatch,
    GET_INSTRUMENT_FAMILIES,
    Api.getInstrumentFamilies,
    []
  );

export const getInstrumentFamilyById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_INSTRUMENT_FAMILY_BY_ID,
    Api.getInstrumentFamilyById,
    [id]
  );

export const deleteInstrumentFamily = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_INSTRUMENT_FAMILY,
    Api.deleteInstrumentFamily,
    [id]
  );

export const createInstrumentFamily = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_INSTRUMENT_FAMILY,
    Api.createInstrumentFamily,
    [body]
  );

export const updateInstrumentFamily = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_INSTRUMENT_FAMILY,
    Api.updateInstrumentFamily,
    [id, body]
  );
