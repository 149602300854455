export default (theme) => ({
  pdfToolBox: {
    position: "fixed",
    padding: 20,
    background: "rgba(0,0,0,0.4)",
    bottom: "20px",
    color: "white",
    borderRadius: 8,
    right: 0,
    left: 0,
    marginRight: "auto",
    marginLeft: "auto",
    width: 150,
  },
});
