import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const projectSections = `/${service}/projectSections`;

export function getProjectSections() {
  const url = `${projectSections}`;
  return {
    method: GET,
    url,
  };
}

export function getProjectSectionById(id) {
  const url = `${projectSections}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProjectSection(id, body) {
  const url = `${projectSections}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProjectSection(id) {
  const url = `${projectSections}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProjectSection(body) {
  const url = `${projectSections}`;
  return {
    method: POST,
    url,
    body,
  };
}
