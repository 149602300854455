// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

// styles
const styles = (theme) => ({
  root: {
    textAlign: "center",
    cursor: "pointer",
  },
  container: {
    padding: 5,
    cursor: "pointer",
  },
  tag: {
    background: theme.palette.background.paper,
    marginTop: 4,
    borderRadius: 12,
  },
});

class App extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    creditLeft: PropTypes.any,
  };

  static contextTypes = {
    SubscriptionCenter: PropTypes.object,
  };

  render() {
    const { creditLeft } = this.props;

    const { SubscriptionCenter } = this.context;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <div
          style={{
            marginLeft: 8,
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => SubscriptionCenter.buyCredits()}
        >
          <Typography color="inherit" display="block">
            {`${creditLeft || "-"}`} Credits Left
          </Typography>
          <Typography
            color="inherit"
            variant="caption"
            display="block"
            style={{ opacity: 0.8, marginTop: -4 }}
          >
            Buy More ➝
          </Typography>
        </div> */}
      </div>
    );
  }
}

export default withStyles(styles)(App);
