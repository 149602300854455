import { networkAction } from "helpers/network/networkAction";

import {
  GET_STAGES,
  GET_STAGE_BY_ID,
  DELETE_STAGE,
  UPDATE_STAGE,
  CREATE_STAGE,
} from "constants/stages";

import * as Api from "api";

export const getStages = () => async (dispatch) =>
  networkAction(dispatch, GET_STAGES, Api.getStages, []);

export const getStageById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_STAGE_BY_ID, Api.getStageById, [id]);

export const deleteStage = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_STAGE, Api.deleteStage, [id]);

export const createStage = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_STAGE, Api.createStage, [body]);

export const updateStage = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_STAGE, Api.updateStage, [id, body]);
