import Chip from "@material-ui/core/Chip";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormInstrumentSection from "components/FormInstrumentSection";
// custom
import InstrumentSectionsList from "components/InstrumentSectionsList";
// reactor
import Page from "components/Page";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperInstrumentSectionPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    instrumentSections: PropTypes.array,
    instrumentFamilies: PropTypes.array,
    createInstrumentSection: PropTypes.func,
    deleteInstrumentSection: PropTypes.func,
    updateInstrumentSection: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedInstrumentSection: undefined,
      formInstrumentSectionOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { instrumentSections, instrumentFamilies } = this.props;

    let data = instrumentSections;
    const family = instrumentFamilies[index - 1];
    if (family) {
      data = instrumentSections.filter((i) => i.familyID === family.id);
    }

    return (
      <InstrumentSectionsList
        instrumentSections={data}
        onSelect={(selectedInstrumentSection) =>
          this.setState({
            selectedInstrumentSection,
            formInstrumentSectionOpen: true,
          })
        }
      />
    );
  }

  render() {
    const {
      classes,
      history,
      loading,
      createInstrumentSection,
      deleteInstrumentSection,
      updateInstrumentSection,
      instrumentFamilies,
      refresh,
    } = this.props;

    const {
      index,
      selectedInstrumentSection,
      formInstrumentSectionOpen,
    } = this.state;

    return (
      <Page
        helmet="InstrumentSections"
        loading={loading}
        loadingMessage={"Fetching all InstrumentSections"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            {instrumentFamilies.map((i) => (
              <Tab
                label={i.name}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            ))}
          </Tabs>
        }
      >
        <div>
          <Chip
            onClick={() => this.setState({ formInstrumentSectionOpen: true })}
            color="secondary"
            label="+ Instrument Section"
            style={{ marginBottom: 8 }}
          />
          {this.getStepContent()}
        </div>
        <FormInstrumentSection
          open={formInstrumentSectionOpen}
          instrumentSection={selectedInstrumentSection}
          close={() => {
            this.setState({
              formInstrumentSectionOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedInstrumentSection: undefined,
                }),
              200
            );
          }}
          createInstrumentSection={createInstrumentSection}
          deleteInstrumentSection={deleteInstrumentSection}
          updateInstrumentSection={updateInstrumentSection}
          instrumentFamilies={instrumentFamilies}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperInstrumentSectionPage);
