import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
// material-ui
import { withStyles } from "@material-ui/styles";
import Envelope from "assets/img/envelope.png";
// images
import Keys from "assets/img/keys.png";
// config
import { RESET_PASSWORD_ENABLED } from "config";
// constants
import { EMAIL } from "constants/regexp";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class ForgotPassword extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    requestResetPassword: PropTypes.func,
    fullScreen: PropTypes.bool,
    classes: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  static childContextTypes = {
    openForgotPassword: PropTypes.func,
    closeForgotPassword: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      requestResetPasswordOpen: false,
      email: "",
      validEmail: false,
      emailBlur: false,
      validationPending: false,
    };
  }

  getChildContext() {
    return {
      openForgotPassword: this.openForgotPassword.bind(this),
      closeForgotPassword: this.closeForgotPassword.bind(this),
    };
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;
    if (name === "email") {
      let validEmail = false;
      if (EMAIL.test(value)) {
        validEmail = true;
      }

      this.setState({ validEmail });
    }

    this.setState({
      [name]: value,
    });
  };

  init() {
    this.setState({
      loading: false,
      requestResetPasswordOpen: false,
      email: "",
      validEmail: false,
      emailBlur: false,
      validationPending: false,
    });
  }

  openForgotPassword() {
    this.setState({ requestResetPasswordOpen: true });
  }

  closeForgotPassword() {
    this.setState({ requestResetPasswordOpen: false });
  }

  isValid() {
    const { email, validEmail } = this.state;

    return email.length && validEmail;
  }

  async requestResetPassword() {
    const { requestResetPassword } = this.props;
    const { email } = this.state;

    const body = {
      email,
    };

    this.setState({ loading: true });
    const resp = await requestResetPassword(body);
    if (resp.success) {
      this.setState({ loading: false, validationPending: true });
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { children, fullScreen, classes } = this.props;
    const {
      requestResetPasswordOpen,
      email,
      validEmail,
      emailBlur,
      loading,
      validationPending,
    } = this.state;

    if (RESET_PASSWORD_ENABLED !== true) {
      return children;
    }

    return (
      <div>
        {children}
        <Dialog
          fullScreen={fullScreen}
          open={requestResetPasswordOpen}
          onClose={this.closeForgotPassword.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!loading && !validationPending ? (
            <div>
              <div className={classes.userImage}>
                <img alt="" src={Keys} />
              </div>
              <DialogTitle id="alert-dialog-title">
                I Forgot my Password
              </DialogTitle>
              <DialogContent>
                <Typography variant="subtitle1">
                  Please, provide your email. We will send you a link to reset
                  your password.
                </Typography>
                <br />
                <div className={classes.formContent}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={!validEmail && emailBlur}
                        onBlur={() => this.setState({ emailBlur: true })}
                        id="email"
                        label="Email *"
                        className={classes.textField}
                        value={email}
                        onChange={this.handleChange("email")}
                      />
                    </Grid>
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.closeForgotPassword.bind(this)}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  autoFocus
                  disabled={!this.isValid()}
                  variant="contained"
                  onClick={this.requestResetPassword.bind(this)}
                >
                  Submit
                </Button>
              </DialogActions>
            </div>
          ) : (
            []
          )}
          {loading && (
            <div className={classes.progressContainer}>
              <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
              <CircularProgress color="secondary" />
            </div>
          )}
          {validationPending ? (
            <div>
              <div className={classes.userImage}>
                <img alt="" src={Envelope} />
              </div>
              <DialogTitle id="alert-dialog-title">
                Check your Mail!
              </DialogTitle>
              <DialogContent>
                <Typography variant="subtitle1">
                  You should have received a reset password link
                </Typography>
                <br />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.closeForgotPassword.bind(this)}
                  color="secondary"
                >
                  Close
                </Button>
              </DialogActions>
            </div>
          ) : (
            []
          )}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(ForgotPassword));
