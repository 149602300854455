// import PropTypes from 'prop-types';
// material-ui
import {
  Chip,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import MercuryJobStage from "hooks/MercuryJobStage";
import React from "react";
import { animateScroll } from "react-scroll";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

function NextActionChip(props) {
  const [hover, setHover] = React.useState(false);
  const theme = useTheme();

  return (
    <Chip
      onClick={props.onClick}
      variant={hover ? "default" : "outlined"}
      onMouseEnter={() => setHover(true)}
      style={{
        background: hover ? props.s.color : undefined,
        color: hover ? "white" : undefined,
      }}
      onMouseLeave={() => setHover(false)}
      label={
        <span>
          <img
            alt={`${props.s.name}-icon`}
            src={props.s.icon}
            style={{
              height: 12,
              filter: hover
                ? `brightness(0) invert(1)`
                : `brightness(0)${
                    theme.palette.type === "dark" ? " invert(1)" : ""
                  }`,
            }}
          />{" "}
          {props.s.id === 1 ? "Invite" : props.s.name}
        </span>
      }
      size="small"
    />
  );
}

export default function MercuryJobHistory({
  mercuryJob,
  rhapsodyJob,
  api,
  refresh,
  musician,
  MercuryNotify,
  refreshJob,
  conciergeReadOnly,
  setPrevent,
}) {
  const [loading, setLoading] = React.useState(true);
  const [chronological, setChronological] = React.useState(
    window.localStorage.getItem("chronologicalJobHistory") === "true"
  );
  const classes = useStyles(styles);

  const onInteraction = (mercuryInteractorID) => {
    api.updateMercuryJob(rhapsodyJob.id, { mercuryInteractorID }).then(() => {
      refreshJob();
      // refresh();
    });
  };

  const onNextStage = (mercuryStage) => {
    MercuryNotify.job(rhapsodyJob, mercuryJob, mercuryStage);
  };

  React.useEffect(() => {
    if (mercuryJob) {
      setLoading(false);
      if (chronological) {
        setTimeout(
          () =>
            animateScroll.scrollToBottom({
              containerId: "mercuryJobHistoryContainer",
              duration: 0,
            }),
          100
        );
      }
    }
  }, [mercuryJob, chronological]);

  return (
    <div className={classes.root}>
      {loading ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Skeleton height={57} />
          </Grid>
          <Grid item xs={12} style={{ opacity: 0.4 }}>
            <Skeleton height={57} />
          </Grid>
        </Grid>
      ) : (
        <div>
          <div
            style={{
              position: "sticky",
              textAlign: "left",
              top: 0,
            }}
          >
            <Tooltip
              title={chronological ? "Reverse Chronological" : "Chronological"}
            >
              <IconButton
                size="small"
                style={{ marginLeft: -20 }}
                onClick={() => {
                  window.localStorage.setItem(
                    "chronologicalJobHistory",
                    !chronological
                  );
                  if (!chronological) {
                    animateScroll.scrollToBottom({
                      containerId: "mercuryJobHistoryContainer",
                    });
                  } else {
                    animateScroll.scrollToTop({
                      containerId: "mercuryJobHistoryContainer",
                    });
                  }
                  setChronological(!chronological);
                }}
              >
                {!chronological ? (
                  <Icon style={{ fontSize: 12 }} class="fad fa-sort-size-up" />
                ) : (
                  <Icon
                    style={{ fontSize: 12 }}
                    class="fad fa-sort-size-down-alt"
                  />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <Grid
            container
            direction={chronological ? "column" : "column-reverse"}
          >
            {mercuryJob?.stages?.map((s) => (
              <Grid item xs={12} key={s.id}>
                <MercuryJobStage
                  stage={s}
                  onNudge={() => {
                    MercuryNotify.nudgeJob(rhapsodyJob, mercuryJob, s);
                  }}
                  current={s.id === mercuryJob?.currentStage?.id}
                  validInteractors={mercuryJob.validInteractors}
                  onInteraction={onInteraction}
                  chronological={chronological}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <div
                style={{
                  background: "#e0e0e0",
                  margin: "4px 0px",
                  borderRadius: 20,
                }}
              >
                <Typography
                  variant="caption"
                  style={{ padding: 0, lineHeight: 0.8, color: "black" }}
                >
                  {mercuryJob?.validUpcomingStages?.length &&
                  !conciergeReadOnly ? (
                    <span>
                      Possible next actions{" "}
                      {!chronological ? (
                        <i class="fas fa-long-arrow-up"></i>
                      ) : (
                        <i class="fas fa-long-arrow-down"></i>
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </Typography>
              </div>
            </Grid>
            {!conciergeReadOnly &&
              mercuryJob?.validUpcomingStages?.map((s) => (
                <Grid item style={{ margin: "4px 0px" }}>
                  <NextActionChip s={s} onClick={() => onNextStage(s)} />
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </div>
  );
}
