// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperMusicianPage from "../component/WrapperMusicianPage";

const mapStateToProps = (state) => ({
  customers: state.customers,
  user: state.user,
  instruments: state.instruments,
  currentCompany: state.currentCompany,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch) => ({
  getGroups: (...args) => dispatch(Actions.getGroups(...args)),
  createMusicianGroup: (...args) =>
    dispatch(Actions.createMusicianGroup(...args)),
  deleteMusicianGroup: (...args) =>
    dispatch(Actions.deleteMusicianGroup(...args)),
  getMusicianById: (...args) => dispatch(Actions.getMusicianById(...args)),
  getMusicianStudioSessions: (...args) =>
    dispatch(Actions.getMusicianStudioSessions(...args)),
  updateMusician: (...args) => dispatch(Actions.updateMusician(...args)),
  getInstruments: (...args) => dispatch(Actions.getInstruments(...args)),
  createMusicianInstrument: (...args) =>
    dispatch(Actions.createMusicianInstrument(...args)),
  deleteMusicianInstrument: (...args) =>
    dispatch(Actions.deleteMusicianInstrument(...args)),
  getMusicianProjects: (...args) =>
    dispatch(Actions.getMusicianProjects(...args)),
  joinRequest: (...args) => dispatch(Actions.joinRequest(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),
});
class MusicianPage extends PureComponent {
  static propTypes = {
    getGroups: PropTypes.func,
    createMusicianGroup: PropTypes.func,
    deleteMusicianGroup: PropTypes.func,
    getMusicianById: PropTypes.func,
    getMusicianStudioSessions: PropTypes.func,
    getMusicianProjects: PropTypes.func,
    getCustomers: PropTypes.func,
    createMusicianInstrument: PropTypes.func,
    deleteMusicianInstrument: PropTypes.func,
    updateMusician: PropTypes.func,
    getInstruments: PropTypes.func,
    joinRequest: PropTypes.func,
    getCurrentCompany: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    customers: PropTypes.array,
    user: PropTypes.object,
    instruments: PropTypes.array,
    currentCompany: PropTypes.object,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, getInstruments, getCurrentCompany } = this.props;

    const urlParams = getJsonFromUrl(location);
    this.state = {
      musicianID: urlParams.id,
      musician: {},
      studioSessions: [],
      urlParams,
      loading: true,
    };
    getCurrentCompany();
    this.refresh();
  }

  async refresh() {
    const { getMusicianById, getMusicianStudioSessions, getMusicianProjects } =
      this.props;
    const { musicianID } = this.state;
    const resps = await Promise.all([
      getMusicianById(musicianID),
      getMusicianStudioSessions(musicianID),
      getMusicianProjects(musicianID),
    ]);

    this.setState({
      musician: resps[0].payload,
      studioSessions: resps[1].payload,
      projects: resps[2].payload,
      refreshKey: Date.now(),
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      updateMusician,
      customers,
      instruments,
      createMusicianInstrument,
      deleteMusicianInstrument,
      joinRequest,
      currentCompany,
      user,
      getGroups,
      createMusicianGroup,
      deleteMusicianGroup,
    } = this.props;

    const {
      urlParams,
      musician,
      studioSessions,
      projects,
      loading,
      refreshKey,
    } = this.state;

    return (
      <WrapperMusicianPage
        history={history}
        location={location}
        urlParams={urlParams}
        joinRequest={joinRequest}
        updateMusician={updateMusician}
        createMusicianInstrument={createMusicianInstrument}
        deleteMusicianInstrument={deleteMusicianInstrument}
        currentCompany={currentCompany}
        getGroups={getGroups}
        createMusicianGroup={createMusicianGroup}
        deleteMusicianGroup={deleteMusicianGroup}
        musician={musician}
        studioSessions={studioSessions}
        projects={projects}
        loading={loading}
        refresh={this.refresh.bind(this)}
        customers={customers}
        organizations={user.organizations}
        instruments={instruments}
        refreshKey={refreshKey}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MusicianPage);
