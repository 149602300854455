import { Chip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
// material-ui
import { withStyles } from "@material-ui/styles";
import Job from "components/Job";
import MusicianSuggestion from "components/MusicianSuggestion";
// custom
import SectionMusician from "components/SectionMusician";
// constants
import { MUSICIAN, PROJECT_DETAIL } from "constants/routes";
import { getSectionDragType } from "helpers";
// hooks
import InstrumentFamiliesMenu from "hooks/InstrumentFamiliesMenu";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
// styles
import styles from "./styles";

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  width: "100%",
  padding: 4,
});

class StudioSessionSection extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    api: PropTypes.object,
    refreshKey: PropTypes.number,
    disabled: PropTypes.bool,
    conciergeReadOnly: PropTypes.bool,
    history: PropTypes.object,
    sectionRoles: PropTypes.array,
    jobs: PropTypes.array,
    sessionSection: PropTypes.object,
    refresh: PropTypes.func,
    refreshFuncs: PropTypes.object,
    instruments: PropTypes.array,
    eventStatuses: PropTypes.array,
    layoutPresets: PropTypes.array,
    studioSession: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      popperAnchor: null,
      loading: false,
      sectionMusicians: [],
    };
  }

  async getSectionMusicians(e) {
    const { api, sessionSection, studioSession } = this.props;
    this.setState({ loading: true, popperAnchor: e.currentTarget });
    const r = await api.getInstrumentSectionMusicians(
      sessionSection.sectionID,
      studioSession.id,
      undefined
    );
    if (r.success) {
      this.setState({ sectionMusicians: r.payload, loading: false });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "Please confirm you action. Make sure all musicians have been removed from the section.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { api, sessionSection, refresh } = this.props;

    await api.deleteSessionSection(sessionSection.id);
    refresh();
  }

  isNormalInteger(str) {
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  async addMusicians(e) {
    const { api, studioSession, refresh } = this.props;

    await api.createStudioSessionMusician([
      {
        sectionID: e.section.sectionID,
        sessionID: studioSession.id,
        createCount: e.quantity,
      },
    ]);

    refresh();
  }

  closeMusicianSuggestions() {
    this.setState({
      popperAnchor: null,
    });
  }

  render() {
    const {
      sessionSection,
      sectionRoles,
      refresh,
      eventStatuses,
      studioSession,
      layoutPresets,
      disabled,
      onMusicianEdit,
      history,
      instruments,
      api,
      subscription,
      refreshKey,
      conciergeReadOnly,
      jobs,
    } = this.props;

    const { popperAnchor, sectionMusicians, loading } = this.state;

    const { NotificationCenter } = this.context;

    return (
      <div style={{ position: "relative" }}>
        <Grid container justify="space-between">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="body" style={{ fontWeight: 600 }}>
                  {sessionSection.sectionName}
                </Typography>
              </Grid>
              {!disabled ? (
                <Grid item>
                  <Close
                    style={{
                      height: 15,
                      width: 15,
                      paddingLeft: 8,
                      paddingTop: 4,
                      cursor: "pointer",
                    }}
                    onClick={this.delete.bind(this)}
                  />
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Grid>
          {studioSession.hasAlternates ? (
            <Grid item>
              <Tooltip title="Seating">
                <PeopleAltIcon
                  style={{ fontSize: 16, color: "#9e9e9e", cursor: "pointer" }}
                  onClick={this.getSectionMusicians.bind(this)}
                />
              </Tooltip>
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <br />
        <Grid container direction="column">
          {/* <DragDropContext onDragEnd={this.onDragEnd.bind(this)}> */}

          <Droppable
            droppableId={`${sessionSection.sectionID}`}
            type={getSectionDragType(sessionSection)}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {jobs &&
                  jobs.map((m, index) => (
                    <Draggable
                      key={m.id}
                      draggableId={`${m.id}`}
                      index={index}
                      type={getSectionDragType(sessionSection)}
                      isDragDisabled={disabled || false}
                    >
                      {(p, s) => (
                        <div
                          ref={p.innerRef}
                          {...p.draggableProps}
                          {...p.dragHandleProps}
                          style={getItemStyle(
                            s.isDragging,
                            p.draggableProps.style
                          )}
                        >
                          <Grid
                            item
                            key={`SectionMusician_${m.id}`}
                            style={{ padding: 5 }}
                          >
                            {studioSession.project.mercuryEnabled ? (
                              <Job
                                key={refreshKey}
                                subscription={subscription}
                                job={m}
                                disabled={disabled}
                                conciergeReadOnly={conciergeReadOnly}
                                history={history}
                                studioSession={studioSession}
                                refresh={refresh}
                                sessionSection={sessionSection}
                              />
                            ) : (
                              <SectionMusician
                                api={api}
                                sectionMusician={m}
                                disabled={disabled}
                                instruments={instruments}
                                history={history}
                                sectionRoles={sectionRoles}
                                eventStatuses={eventStatuses}
                                onMusicianEdit={onMusicianEdit}
                                studioSession={studioSession}
                                refresh={refresh}
                                sessionSection={sessionSection}
                              />
                            )}
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {/* </DragDropContext> */}
          {!disabled && !conciergeReadOnly ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <InstrumentFamiliesMenu
                api={api}
                history={history}
                NotificationCenter={NotificationCenter}
                layoutPresets={layoutPresets}
                sessionSection={sessionSection}
                onSelect={this.addMusicians.bind(this)}
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <Popover
          open={Boolean(popperAnchor)}
          disableEnforceFocus
          disableRestoreFocus
          onClose={this.closeMusicianSuggestions.bind(this)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          anchorEl={popperAnchor}
        >
          <MusicianSuggestion
            loading={loading}
            sectionRoles={sectionRoles}
            close={this.closeMusicianSuggestions.bind(this)}
            search={false}
            showPrime={studioSession.hasAlternates}
            sortAlphabetically={!studioSession.hasAlternates}
            label={"Seating"}
            suggestions={sectionMusicians}
            disabled
            footer={
              <>
                {studioSession.hasAlternates ? (
                  <>
                    <Typography color="textSecondary" variant="caption">
                      The project does not contain any more musicians.
                    </Typography>
                    <br />
                    <Chip
                      onClick={() =>
                        history.push(
                          `${PROJECT_DETAIL}?id=${studioSession.projectID}&index=1`
                        )
                      }
                      size="small"
                      label="Go to project's seating"
                    />
                  </>
                ) : (
                  <>
                    <Typography color="textSecondary" variant="caption">
                      The address book does not contain any other musician for
                      this section.
                    </Typography>
                    <br />
                    <Chip
                      onClick={() => history.push(MUSICIAN)}
                      size="small"
                      label="Go to Musicians"
                    />
                  </>
                )}
              </>
            }
          />
        </Popover>
      </div>
    );
  }
}

export default withStyles(styles)(StudioSessionSection);
