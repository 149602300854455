import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import Place from "@material-ui/icons/Place";
import { withStyles } from "@material-ui/styles";
import LinkChip from "components/LinkChip";
// custom
import Loading from "components/Loading";
import ProjectsList from "components/ProjectsList";
import WorkSessionPresentationSelect from "components/WorkSessionPresentationSelect";
import WorkSessions from "components/WorkSessions";
import { GOOGLE_MAPS_GEOCODING_KEY, ORGANIZATION_ENABLED } from "config";
// constants
import { STUDIOS, STUDIO_SESSION_DETAIL } from "constants/routes";
import GoogleMapReact from "google-map-react";
// helpers
import {
  buildForURL,
  getLatLgn,
  getTimezone,
  updateURLParameter,
} from "helpers";
import PhoneInput from "hooks/PhoneInput";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
// styles
import styles from "./styles";

class WrapperStudioPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    studioSessions: PropTypes.array,
    projects: PropTypes.array,
    refresh: PropTypes.func,
    updateStudio: PropTypes.func,
    loading: PropTypes.bool,
    studio: PropTypes.object,
    organizations: PropTypes.array,
    createProject: PropTypes.func,
    createStudioSession: PropTypes.func,
    sessionTypes: PropTypes.array,
    customers: PropTypes.array,
    refreshKey: PropTypes.number,
    currentCompany: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams, studio } = this.props;
    this.state = {
      ...studio,
      index: urlParams.index ? Number(urlParams.index) : 0,
      anchorEl: null,
      loading: false,
      formProjectOpen: false,
      formStudioSessionOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.setState({ ...nextProps.studio });
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async getStudioMapsInfo() {
    const { studio, updateStudio, refresh } = this.props;
    const { address, zipcode, city, state } = this.state;
    let tzName;

    const a = `${address} ${zipcode} ${city} ${state}`;
    const coordinates = await getLatLgn(a);

    if (coordinates.lat && coordinates.lng) {
      tzName = await getTimezone(coordinates.lat, coordinates.lng);
    }

    updateStudio(studio.id, {
      latitude: coordinates ? `${coordinates.lat}` : undefined,
      longitude: coordinates ? `${coordinates.lng}` : undefined,
      tzName,
    }).then(refresh);
  }

  async save(name, numeric) {
    const { updateStudio, refresh, studio } = this.props;

    if (studio[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateStudio(studio.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  getStepContent() {
    const {
      classes,
      organizations,
      studioSessions,
      projects,
      history,
      studio,
      currentCompany,
    } = this.props;

    const {
      index,
      loading,
      name,
      email,
      contact,
      phone,
      address,
      zipcode,
      city,
      state,
      organizationID,
      lat,
      lng,
      liveVenue,
    } = this.state;

    switch (index) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Typography variant="h6" color="textPrimary">
                      About the Studio / Venue
                    </Typography>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Name:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("name");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("name")}
                          className={classes.inputBase}
                          value={name || ""}
                          onChange={this.handleChange("name")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Contact:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("contact");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("contact")}
                          className={classes.inputBase}
                          value={contact || ""}
                          onChange={this.handleChange("contact")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Email:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("email");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("email")}
                          className={classes.inputBase}
                          value={email || ""}
                          onChange={this.handleChange("email")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Phone:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <PhoneInput
                          onChange={this.handleChange("phone")}
                          value={phone}
                          variant="outlined"
                          fullWidth
                          label="Phone"
                          size="small"
                          className={classes.textField}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("phone");
                          }}
                          InputProps={{ style: { fontSize: "0.875rem" } }}
                          disabled={loading}
                          onBlur={() => this.save("phone")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Address:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("address");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => {
                            this.save("address");
                            this.getStudioMapsInfo();
                          }}
                          className={classes.inputBase}
                          value={address || ""}
                          onChange={this.handleChange("address")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Zip:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("zipcode");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => {
                            this.save("zipcode");
                            this.getStudioMapsInfo();
                          }}
                          className={classes.inputBase}
                          value={zipcode || ""}
                          onChange={this.handleChange("zipcode")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">City:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("city");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => {
                            this.save("city");
                            this.getStudioMapsInfo();
                          }}
                          className={classes.inputBase}
                          value={city || ""}
                          onChange={this.handleChange("city")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">State:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("state");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => {
                            this.save("state");
                            this.getStudioMapsInfo();
                          }}
                          className={classes.inputBase}
                          value={state || ""}
                          onChange={this.handleChange("state")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">Live Venue:</Typography>
                        <Typography variant="caption" color="textSecondary">
                          Place can host Live Events (LV Reports)
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Switch
                          checked={liveVenue}
                          onChange={(e) => {
                            this.state.liveVenue = e.target.checked; //eslint-disable-line
                            this.save("liveVenue");
                          }}
                          color="secondary"
                        />
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        height: 300,
                        borderRadius: 6,
                        overflow: "hidden",
                        marginTop: 16,
                      }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAPS_GEOCODING_KEY }}
                        center={{
                          lat: Number(studio.latitude) || lat,
                          lng: Number(studio.longitude) || lng,
                        }}
                        defaultZoom={14}
                      >
                        <Place
                          lat={Number(studio.latitude) || lat}
                          lng={Number(studio.longitude) || lng}
                          text={"Here"}
                          style={{
                            color: "#f44336",
                            marginTop: -45,
                            marginLeft: -17,
                          }}
                          fontSize="large"
                        />
                      </GoogleMapReact>
                    </div>
                    <Typography variant="caption" color="textSecondary">
                      {studio.latitude},{studio.longitude} - {studio.tzName}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {ORGANIZATION_ENABLED ? (
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper
                      className={
                        loading ? classes.paperLoading : classes.inputPaper
                      }
                    >
                      <Typography variant="h6" color="textPrimary">
                        Related
                      </Typography>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="body2">Organization:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <Select
                              inputProps={{
                                name: "Studio",
                                id: "studio-simple",
                              }}
                              value={organizationID}
                              onChange={(e) => {
                                this.state.organizationID = e.target.value; // eslint-disable-line
                                this.save("organizationID");
                              }}
                            >
                              {organizations.map((w) => (
                                <MenuItem
                                  key={`organization_${w.id}`}
                                  value={w.id}
                                >
                                  {w.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        );
      case 1:
        return (
          <div style={{ width: "100%" }}>
            <ProjectsList
              history={history}
              projects={projects}
              currentCompany={currentCompany}
            />
          </div>
        );
      case 2:
        return (
          <WorkSessions
            workSessions={studioSessions}
            history={history}
            onSelect={(session) =>
              history.push({
                pathname: STUDIO_SESSION_DETAIL,
                search: buildForURL({
                  id: session.id,
                  back: `${window.location.pathname}${window.location.search}`,
                }),
              })
            }
          />
        );
      default:
        return (
          <Typography variant="h6" color="textPrimary">
            Coming Soon
          </Typography>
        );
    }
  }

  openProject() {
    this.setState({ formProjectOpen: true });
  }

  closeProject() {
    this.setState({ formProjectOpen: false });
  }

  openStudioSession() {
    this.setState({ formStudioSessionOpen: true });
  }

  closeStudioSession() {
    this.setState({ formStudioSessionOpen: false });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, history, loading, studio } = this.props;

    const { index } = this.state;

    if (loading) {
      return (
        <div className="page">
          <div className={`${classes.background}`}>
            <Loading message={"Loading Studio"} />
          </div>
        </div>
      );
    }

    return (
      <div className="page">
        <Helmet>
          <title>{studio.name}</title>
          <meta name="studio" content="studio page" />
        </Helmet>
        <Paper
          elevation={10}
          style={{
            borderRadius: 0,
          }}
          className={classes.subHeader}
        >
          <Hidden smDown>
            <Grid container style={{ padding: 10 }} justify="space-between">
              <Grid item style={{ padding: 6 }}>
                <Breadcrumbs
                  arial-label="Breadcrumb"
                  separator={"-"}
                  style={{
                    color: "white",
                  }}
                >
                  <Chip
                    label={"All Studios / Venues"}
                    className={classes.backChip}
                    onClick={() => history.push(STUDIOS)}
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Back
                          className={classes.icon}
                          style={{ color: "white" }}
                        />
                      </Avatar>
                    }
                  />
                  <LinkChip
                    icon={<i class="fad fa-location-dot"></i>}
                    label={studio.name}
                    history={history}
                  />
                </Breadcrumbs>
              </Grid>
            </Grid>
          </Hidden>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={index}
              className={classes.subHeader}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
            >
              <Tab
                label="General"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  label: index === 0 ? classes.labelSelected : classes.label,
                }}
              />
              <Tab
                label="Projects"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  label: index === 1 ? classes.labelSelected : classes.label,
                }}
              />
              <Tab
                label="Calendar"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  label: index === 2 ? classes.labelSelected : classes.label,
                }}
              />
            </Tabs>
            {index === 2 ? <WorkSessionPresentationSelect /> : []}
          </div>
        </Paper>
        <div className={`${classes.background}`}>{this.getStepContent()}</div>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperStudioPage);
