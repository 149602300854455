import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const sessionCategories = `/${service}/sessionCategories`;

export function getSessionCategories() {
  const url = `${sessionCategories}`;
  return {
    method: GET,
    url,
  };
}

export function getSessionCategoryById(id) {
  const url = `${sessionCategories}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSessionCategory(id, body) {
  const url = `${sessionCategories}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSessionCategory(id) {
  const url = `${sessionCategories}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSessionCategory(body) {
  const url = `${sessionCategories}`;
  return {
    method: POST,
    url,
    body,
  };
}
