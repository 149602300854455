export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  chip: {
    backgroundColor: theme.palette.grey[100],
    height: 24,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:active": {
      boxShadow: theme.shadows[1],
    },
  },
  backChip: {
    backgroundColor: "none",
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing(1) * 1.5,
  },
  container: {
    backgroundColor: "#424242",
    padding: 20,
    color: "white",
  },
});
