export default (theme) => ({
  root: {
    background: theme.palette.background.paper,
    border: "solid 1px rgba(155,155,155,0.3)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "left",
    padding: 8,
    borderRadius: 4,
    flexWrap: "wrap",
  },
  break: {
    flexBasis: "100%",
    height: 0,
  },
  lock: {
    border: "solid 1px rgba(255,255,255,0.3)",
    padding: 6,
    borderRadius: 8,
    flex: 1,
    marginTop: 8,
    position: "relative",
    overflow: "hidden",
  },
  overlay: {
    backdropFilter: "blur(10px)",
    borderRadius: 8,
    BackdropFilter: "blur(10px) saturate(100%)",
    background: "rgba(0,0,0,0.1)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  interactor: {
    background: theme.palette.type === "dark" ? "#757575" : "#e0e0e0",
    padding: "2px 6px",
    borderRadius: 8,
    display: "inline-block",
  },
  signature: {
    filter:
      theme.palette.type === "dark"
        ? "contrast(0) invert(100%)"
        : "contrast(0)",
  },
});
