import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Folder from "@material-ui/icons/FolderOpenTwoTone";
import * as Actions from "actions";
import AdvancedSearch from "components/AdvancedSearchRefactor";
import TableArrow from "components/TableArrow";
import TableAvatar from "components/TableAvatar";
import Table from "components/TablePagination";
// helpers
import { formatDateRange } from "helpers";
import MercurySessionGlance from "hooks/MercurySessionGlance";
import React from "react";
import { useDispatch } from "react-redux";

export default function WorkSessionTable({
  workSessions,
  onSelect,
  refresh,
  centrifuge,
  app,
  setPreferedWorkSessionPresentation,
  getStudioSessions,
  history,
}) {
  // const [ state, setState ] = React.useState(initialValue);

  // const _workSessions = workSessions?.sort((a, b) => {
  //   return moment(b.dateFromUTC).diff(a.dateFromUTC);
  // });

  const dispatch = useDispatch();

  const searchProject = (text) => {
    return dispatch(
      Actions.getProjects([
        {
          name: "name",
          comparison: "like",
          value: text.text,
        },
      ])
    );
  };

  const searchStudio = (text) => {
    return dispatch(
      Actions.getStudios([
        {
          name: "name",
          comparison: "like",
          value: text.text,
        },
      ])
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {refresh ? (
        <AdvancedSearch
          history={history}
          rowCount={workSessions.length}
          location={window.location}
          refresh={refresh}
          keyword="workSessions"
          metaDataEndpoint="/rhapsody/workSessions/searchMetadata"
          metaDataOveride={{
            projectID: {
              type: "autoFill",
              label: "Project",
              getData: (search) =>
                searchProject({
                  text: search,
                }),
              valueKey: "id",
              labelTransform: (t) => `${t.name})`,
            },
            studioID: {
              type: "autoFill",
              label: "Studio",
              getData: (search) =>
                searchStudio({
                  text: search,
                }),
              valueKey: "id",
              labelTransform: (t) => `${t.name})`,
            },
          }}
        >
          <Table
            data={workSessions}
            meta={[
              {
                path: "id",
                title: "ID",
                width: 20,
              },
              {
                path: "sessionTypeCode",
                title: "",
                numeric: false,
                hidden: { smDown: true },
                component: TableAvatar,
                width: 50,
              },
              {
                path: "title",
                title: "Title",
                numeric: false,
                render: (t, d) => (
                  <>
                    <Grid container direction="column">
                      <Grid item>
                        {t ? (
                          <Typography variant="body2">{t}</Typography>
                        ) : (
                          <Typography color="textSecondary">
                            <i>Untitled</i>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    {d.projectName ? (
                      <div
                        variant="caption"
                        color="textSecondary"
                        style={{
                          color: d.concierge ? "#03a9f4" : d.projectColor,
                          border: `solid 1px ${
                            d.concierge ? "#03a9f4" : d.projectColor
                          }`,
                          borderRadius: 12,
                          display: "inline-block",
                          fontSize: 10,
                          padding: "2px 4px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Folder
                            style={{
                              color: d.concierge ? "#03a9f4" : d.projectColor,
                              fontSize: 14,
                            }}
                          />
                          {d.projectName}
                        </div>
                      </div>
                    ) : (
                      []
                    )}
                  </>
                ),
              },
              {
                path: "dateFromUTC",
                hidden: { smDown: true },
                title: "Date",
                numeric: false,
                render: (t, d) => (
                  <Tooltip title={d.recorded ? "Recorded" : "Not Recorded"}>
                    <Typography variant="body2">
                      <i class="fa-regular fa-calendar p-right"></i>
                      {formatDateRange(d.dateFromUTC, d.dateToUTC, d.tzName)}
                    </Typography>
                  </Tooltip>
                ),
              },
              {
                path: "studioName",
                title: "Studio/Venue Name",
                hidden: { smDown: true },
                numeric: false,
                render: (t, d) => (
                  <Typography variant="body2">
                    <i class="fa-solid fa-location-dot p-right"></i>
                    {t}
                  </Typography>
                ),
              },
              {
                path: "musicianCount",
                title: "Hiring",
                render: (t, g) =>
                  g.mercuryEnabled ? (
                    <MercurySessionGlance
                      workSession={g}
                      centrifuge={centrifuge}
                    />
                  ) : (
                    <div />
                  ),
              },
              {
                path: "id",
                hidden: { smDown: true },
                numeric: false,
                component: TableArrow,
                width: 30,
              },
            ]}
            title={"Work Sessions"}
            onRowSelect={onSelect}
          />
        </AdvancedSearch>
      ) : (
        <Table
          data={workSessions}
          meta={[
            {
              path: "sessionTypeCode",
              title: "",
              numeric: false,
              hidden: { smDown: true },
              component: TableAvatar,
              width: 50,
            },
            {
              path: "title",
              title: "Title",
              numeric: false,
              render: (t, d) => (
                <>
                  <Grid container direction="column">
                    <Grid item>
                      {t ? (
                        <Typography variant="body2">{t}</Typography>
                      ) : (
                        <Typography color="textSecondary">
                          <i>Untitled</i>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  {d.projectName ? (
                    <div
                      variant="caption"
                      color="textSecondary"
                      style={{
                        color: d.concierge ? "#03a9f4" : d.projectColor,
                        border: `solid 1px ${
                          d.concierge ? "#03a9f4" : d.projectColor
                        }`,
                        borderRadius: 12,
                        display: "inline-block",
                        fontSize: 10,
                        padding: "2px 4px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Folder
                          style={{
                            color: d.concierge ? "#03a9f4" : d.projectColor,
                            fontSize: 14,
                          }}
                        />
                        {d.projectName}
                      </div>
                    </div>
                  ) : (
                    []
                  )}
                </>
              ),
            },
            {
              path: "dateFromUTC",
              hidden: { smDown: true },
              title: "Date",
              numeric: false,
              render: (t, d) => (
                <Tooltip title={d.recorded ? "Recorded" : "Not Recorded"}>
                  <Typography variant="body2">
                    <i class="fa-regular fa-calendar p-right"></i>
                    {formatDateRange(d.dateFromUTC, d.dateToUTC, d.tzName)}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              path: "studioName",
              title: "Studio/Venue Name",
              hidden: { smDown: true },
              numeric: false,
              render: (t, d) => (
                <Typography variant="body2">
                  <i class="fa-solid fa-location-dot p-right"></i>
                  {t}
                </Typography>
              ),
            },
            {
              path: "musicianCount",
              title: "Hiring",
              render: (t, g) =>
                g.mercuryEnabled ? (
                  <MercurySessionGlance
                    workSession={g}
                    centrifuge={centrifuge}
                  />
                ) : (
                  <div />
                ),
            },
            {
              path: "id",
              hidden: { smDown: true },
              numeric: false,
              component: TableArrow,
              width: 30,
            },
          ]}
          title={"Work Sessions"}
          onRowSelect={onSelect}
        />
      )}
    </div>
  );
}
