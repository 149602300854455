/*
    @flow
    global document MouseEvent SyntheticMouseEvent HTMLDivElement
*/

import type { ElementRef as ReactElementRef, Node as ReactNode } from "react";
import React, { Component } from "react";
import Edge from "../Edge";
import styles from "./styles.js";

export default class Node extends Component {
  static defaultProps = {
    x: 200,
    y: 200,
    width: 160,
    height: 35,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      id: props.id,
      x: props.x,
      y: props.y,
      scale: props.scale,
      data: props.data,
      groupID: props.groupID,
      relationID: props.relationID,
      width: props.width,
      height: props.height,
      isDragging: false,
      isCompactView: true,
    };

    this._onMouseUp = this._onMouseUp.bind(this);
    this._onMouseMove = this._onMouseMove.bind(this);
  }

  componentDidMount() {
    if (this.props.shouldFitContent === false) {
      return;
    }

    const nextState = {};
    const { width, height } = this.state;
    const { clientWidth: dataWidth = 0, clientHeight: dataHeight = 0 } =
      this.dataEl || {};

    if (width - 30 < dataWidth) {
      nextState.width = dataWidth + 30;
    }

    if (height - 20 < dataWidth) {
      nextState.height = dataHeight + 20;
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { scale, data } = this.state;
    const { width, height } = nextProps.size || {};
    const nextState = {};

    if (nextProps.scale && nextProps.scale !== scale) {
      nextState.scale = nextProps.scale;
    }

    if (nextProps.data !== data) {
      Object.assign(nextState, {
        id: nextProps.id,
        x: nextProps.x,
        y: nextProps.y,
        scale: nextProps.scale,
        data: nextProps.data,
        groupID: nextProps.groupID,
        relationID: nextProps.relationID,
        width: width || Node.defaultProps.width,
        height: height || Node.defaultProps.height,
      });
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { x, y, width, height, isDragging } = this.state;

    return (
      isDragging !== nextState.isDragging ||
      x !== nextState.x ||
      y !== nextState.y ||
      width !== nextState.width ||
      height !== nextState.height
    );
  }

  componentDidUpdate(props: Props, state: State) {
    if (this.props.isStatic) {
      return;
    }

    if (this.state.isDragging && !state.isDragging) {
      document.addEventListener("mousemove", this._onMouseMove);
      document.addEventListener("mouseup", this._onMouseUp);
    } else if (!this.state.isDragging && state.isDragging) {
      document.removeEventListener("mousemove", this._onMouseMove);
      document.removeEventListener("mouseup", this._onMouseUp);
    }
  }

  getGraph(): ?GraphRect {
    const graph = this.props.getGraph();

    if (!graph) {
      return null;
    }

    const { offsetTop, offsetLeft, clientWidth, clientHeight } = graph;

    return {
      offsetTop,
      offsetLeft,
      width: clientWidth,
      height: clientHeight,
    };
  }

  toJSON(): NodeJsonType {
    const { id, data, groupID, relationID, x, y, width, height } = this.state;

    return {
      id,
      data,
      groupID,
      relationID,
      position: { x, y },
      size: { width, height },
    };
  }

  /* Event Handlers */

  _onMouseDown(event: SyntheticMouseEvent<>) {
    // only left mouse button
    if (event.button !== 0) return;

    this.setState({ isDragging: true });

    event.stopPropagation();
    event.preventDefault();
  }

  _onMouseUp(event: MouseEvent) {
    const { onChange } = this.props;

    this.setState({ isDragging: false });

    if (typeof onChange === "function") {
      onChange(this.toJSON());
    }

    event.stopPropagation();
    event.preventDefault();
  }

  move(movementX, movementY) {
    const { x, y, scale } = this.state;
    const { onChange } = this.props;
    const nextX = x + movementX / scale;
    const nextY = y + movementY / scale;

    const nextState = { x: nextX, y: nextY };
    this.setState(nextState, () => {
      if (typeof onChange === "function") {
        onChange(this.toJSON());
      }
    });
  }

  _onMouseMove(event: MouseEvent) {
    const graph = this.getGraph();
    const { moveGroup } = this.props;
    const { x, y, width, height, scale, isDragging, groupID } = this.state;

    if (!isDragging || !graph) return;

    const nextX = x + event.movementX / scale;
    const nextY = y + event.movementY / scale;

    if (
      nextX < 0 ||
      nextY < 0 ||
      nextX + width > graph.width ||
      nextY + height > graph.height
    ) {
      return;
    }

    if (groupID) {
      moveGroup(groupID, event.movementX, event.movementY);
    } else {
      this.move(event.movementX, event.movementY);
    }

    event.stopPropagation();
    event.preventDefault();
  }

  /* Render Methods */

  renderJoint(type: string, edge: ReactElementRef<typeof Edge>): ReactNode {
    const className =
      type === "input" ? styles.edgeJoint_input : styles.edgeJoint_ouput;
    let data = null;

    if (type === "input" && typeof edge.targetId !== "string") {
      data = edge.targetId.data || type;
    }

    if (type === "output" && typeof edge.sourceId !== "string") {
      data = edge.sourceId.data || type;
    }

    const Joint = <span className={styles.edgeJointPoint} />;

    return (
      <div
        key={`joint_${type}_${edge.sourceId}_${edge.targetId}`}
        className={className}
      >
        {type === "input" && Joint}
        {data && <span>{data}</span>}
        {type === "output" && Joint}
      </div>
    );
  }

  renderContainer({ isDragging, content }) {
    const { width, height } = this.state;
    const className = `${styles.container} ${
      isDragging ? styles.container_dragging_yes : ""
    }`;

    return (
      <div style={{ width, height }} className={className}>
        {Boolean(content) && this.renderContent(content)}
      </div>
    );
  }

  renderContent(data: string) {
    return (
      <div
        style={styles.data}
        ref={(element) => {
          this.dataEl = element;
        }}
      >
        {data}
      </div>
    );
  }

  roundToSnap(x) {
    const { snap } = this.props;
    if (snap) {
      return Math.ceil(x / snap) * snap;
    }
    return x;
  }

  render() {
    const { isStatic, onNodeSelect, json } = this.props;
    const { x, y } = this.state;

    return (
      <div
        style={{
          ...styles.root,
          left: this.roundToSnap(x),
          top: this.roundToSnap(y),
        }}
        ref={(element) => {
          this.element = element;
        }}
        onMouseDown={(event: SyntheticMouseEvent<>) =>
          !isStatic && this._onMouseDown(event)
        }
      >
        {this.renderContainer({ json, node: this.state, onNodeSelect })}
      </div>
    );
  }
}
