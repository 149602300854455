// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperCustomerPage from "../component/WrapperCustomerPage";

const mapStateToProps = (state) => ({
  customers: state.customers,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => dispatch(Actions.getCustomers()),
  getCustomerById: (...args) => dispatch(Actions.getCustomerById(...args)),
  createCustomer: (...args) => dispatch(Actions.createCustomer(...args)),
  deleteCustomer: (...args) => dispatch(Actions.deleteCustomer(...args)),
  updateCustomer: (...args) => dispatch(Actions.updateCustomer(...args)),
});
class CustomerPage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    customers: PropTypes.array,
    getCustomers: PropTypes.func,
    getCustomerById: PropTypes.func,
    createCustomer: PropTypes.func,
    deleteCustomer: PropTypes.func,
    updateCustomer: PropTypes.func,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, customers } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: customers.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getCustomers } = this.props;
    await Promise.all([getCustomers()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      customers,
      getCustomerById,
      createCustomer,
      deleteCustomer,
      updateCustomer,
      history,
    } = this.props;

    const { loading } = this.state;

    return (
      <WrapperCustomerPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        customers={customers}
        getCustomerById={getCustomerById}
        createCustomer={createCustomer}
        deleteCustomer={deleteCustomer}
        updateCustomer={updateCustomer}
        urlParams={getJsonFromUrl(window.location)}
        history={history}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
