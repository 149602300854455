import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function AsyncAutocomplete({
  label,
  searchEndpoint,
  bodyTransform,
  selectedLabelText,
  onSelect,
  size,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (inputValue.length >= 3) {
      fetch(searchEndpoint, {
        body: JSON.stringify(bodyTransform(inputValue)),
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        method: "POST",
      }).then(async (r) => {
        const json = await r.json();
        setOptions(json);
      });
    }
  }, [value, inputValue, fetch]); //eslint-disable-line

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(selectedLabelText(newValue));
        onSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          size={size}
        />
      )}
      renderOption={(option) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid container alignItems="center">
              <Grid item>
                <PersonIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <Typography variant="body2">
                  {option.firstName} {option.lastName}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {option.email}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ width: 100, textAlign: "right" }}>
              <Typography variant="caption" color="textSecondary">
                Org {option.organizationID}
              </Typography>
            </div>
          </div>
        );
      }}
    />
  );
}
