import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const tunes = `/${service}/tunes`;

export function getTunes() {
  const url = `${tunes}`;
  return {
    method: GET,
    url,
  };
}

export function getTuneById(id) {
  const url = `${tunes}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTune(id, body) {
  const url = `${tunes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTune(id) {
  const url = `${tunes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTune(body) {
  const url = `${tunes}`;
  return {
    method: POST,
    url,
    body,
  };
}
