import { GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const jobs = `/${service}/jobs`;

export function getMercuryJob(id) {
  const url = `${jobs}/${id}/mercury`;
  return {
    method: GET,
    url,
  };
}

export function getRhapsodyJob(id) {
  const url = `${jobs}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateMercuryJob(id, body) {
  const url = `${jobs}/${id}/mercury`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function createMercuryJob(body) {
  const url = `${jobs}`;
  return {
    method: POST,
    url,
    body,
  };
}
