export default (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(1) * 2,
    paddingBottom: theme.spacing(1) * 2,
  },
  container: {
    maxWidth: 1200,
    margin: "auto",
  },
});
