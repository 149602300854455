/* @flow */
import React, { PureComponent } from "react";
import styles from "./styles.js";

class Edge extends PureComponent {
  static vertiacalLinkPath(source, target) {
    return (
      "M" +
      source.x +
      "," +
      source.y +
      "C" +
      source.x +
      "," +
      (source.y + target.y) / 2 +
      " " +
      target.x +
      "," +
      (source.y + target.y) / 2 +
      " " +
      target.x +
      "," +
      target.y
    );
  }

  static horizontalLinkPath(source, target) {
    return (
      "M" +
      source.x +
      "," +
      source.y +
      "C" +
      (source.x + target.x) / 2 +
      "," +
      source.y +
      " " +
      (source.x + target.x) / 2 +
      "," +
      target.y +
      " " +
      target.x +
      "," +
      target.y
    );
  }

  static isNodePositionChanged(currNode, nextNode) {
    return (
      currNode.position.x !== nextNode.position.x ||
      currNode.position.y !== nextNode.position.y
    );
  }

  static isNodeSizeChanged({ size: currSize = {} }, { size: nextSize = {} }) {
    return (
      currSize.width !== nextSize.width || currSize.height !== nextSize.height
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      isVertical: props.isVertical,
      isDirected: props.isDirected,
      source: props.source,
      target: props.target,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { source, target } = this.state;
    const { source: nextSource, target: nextTarget } = nextProps;
    const nextState = {};

    if (
      Edge.isNodePositionChanged(source, nextSource) ||
      Edge.isNodeSizeChanged(source, nextSource)
    ) {
      nextState.source = nextSource;
    }

    if (
      Edge.isNodePositionChanged(target, nextTarget) ||
      Edge.isNodeSizeChanged(target, nextTarget)
    ) {
      nextState.target = nextTarget;
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState);
    }
  }

  getPath(sourcePoint, targetPoint) {
    if (this.state.isVertical) {
      return Edge.vertiacalLinkPath(sourcePoint, targetPoint);
    }

    return Edge.horizontalLinkPath(sourcePoint, targetPoint);
  }

  getJointPoint(node) {
    const { isDirected, isVertical } = this.state;
    const { source, target } = this.props;

    const point = {
      x: node.position.x,
      y: node.position.y,
    };
    const { width = 0, height = 0 } = node.size || {};

    if (isVertical) {
      if (isDirected) {
        if (node.id === source.id) {
          point.x = node.position.x + width / 2;
          point.y = node.position.y + height;
        } else {
          point.x = node.position.x + width / 2;
        }
      }
    } else {
      if (isDirected) {
        if (node.id === source.id) {
          point.x = node.position.x + width;
          point.y = node.position.y + height / 2;
        } else {
          point.y = node.position.y + height / 2;
        }
      } else if (node.id === source.id) {
        if (node.position.x + width / 2 < target.position.x) {
          point.x = node.position.x + width;
          point.y = node.position.y + height / 2;
        } else {
          point.y = node.position.y + height / 2;
        }

        return point;
      } else {
        if (node.position.x + width / 2 < source.position.x) {
          point.x = node.position.x + width;
          point.y = node.position.y + height / 2;
        } else {
          point.y = node.position.y + height / 2;
        }
      }
    }

    return point;
  }

  toJSON() {
    const { source, target } = this.state;

    return {
      source: source.id,
      target: target.id,
    };
  }

  renderPath(path) {
    const { highlighted } = this.props;
    const { source, target } = this.state;
    let customStyles = {};

    if (typeof this.getStyles === "function") {
      customStyles = this.getStyles(source, target) || {};
    }
    let s = { ...styles.root, ...customStyles };
    s.opacity = 0.5;
    if (highlighted) {
      s.stroke = "#2196f3";
      s.strokeWidth = "2px";
      s.opacity = 1;
    }
    // if (lowOpacity && !highlighted) {
    //   s.opacity = 0.3;
    // }
    return <path pointer-events="visiblePainted" style={s} d={path} />;
  }

  render() {
    const { source, target } = this.state;
    const sourceJoint = this.getJointPoint(source);
    const targetJoint = this.getJointPoint(target);

    const path = this.getPath(sourceJoint, targetJoint);

    return this.renderPath(path);
  }
}

export default Edge;
