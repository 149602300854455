import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperEventStatusePage from "../component/WrapperEventStatusePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  eventStatuses: state.eventStatuses,
});

const mapDispatchToProps = (dispatch) => ({
  getEventStatuses: () => dispatch(Actions.getEventStatuses()),
  getEventStatuseById: (...args) =>
    dispatch(Actions.getEventStatuseById(...args)),
  createEventStatuse: (...args) =>
    dispatch(Actions.createEventStatuse(...args)),
  deleteEventStatuse: (...args) =>
    dispatch(Actions.deleteEventStatuse(...args)),
  updateEventStatuse: (...args) =>
    dispatch(Actions.updateEventStatuse(...args)),
});
class EventStatusePage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    eventStatuses: PropTypes.array,
    getEventStatuses: PropTypes.func,
    getEventStatuseById: PropTypes.func,
    createEventStatuse: PropTypes.func,
    deleteEventStatuse: PropTypes.func,
    updateEventStatuse: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, eventStatuses } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: eventStatuses.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getEventStatuses } = this.props;
    await Promise.all([getEventStatuses()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      eventStatuses,
      getEventStatuseById,
      createEventStatuse,
      deleteEventStatuse,
      updateEventStatuse,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperEventStatusePage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        eventStatuses={eventStatuses}
        getEventStatuseById={getEventStatuseById}
        createEventStatuse={createEventStatuse}
        deleteEventStatuse={deleteEventStatuse}
        updateEventStatuse={updateEventStatuse}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventStatusePage);
