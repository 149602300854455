// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperMusicianPage from "../component/WrapperMusicianPage";

const mapStateToProps = (state) => ({
  musicians: state.musicians,
  instruments: state.instruments,
  groups: state.groups,
  groupsById: state.groupsById,
});

const mapDispatchToProps = (dispatch) => ({
  getGroups: (...args) => dispatch(Actions.getGroups(...args)),
  getGroupById: (...args) => dispatch(Actions.getGroupById(...args)),
  getMusicians: (...args) => dispatch(Actions.getMusicians(...args)),
  getMusicianById: (...args) => dispatch(Actions.getMusicianById(...args)),
  createMusician: (...args) => dispatch(Actions.createMusician(...args)),
  deleteMusician: (...args) => dispatch(Actions.deleteMusician(...args)),
  updateMusician: (...args) => dispatch(Actions.updateMusician(...args)),
  getInstruments: (...args) => dispatch(Actions.getInstruments(...args)),
  createMusicianInstrument: (...args) =>
    dispatch(Actions.createMusicianInstrument(...args)),
  deleteMusicianInstrument: (...args) =>
    dispatch(Actions.deleteMusicianInstrument(...args)),
});
class MusicianPage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    musicians: PropTypes.object,
    getMusicians: PropTypes.func,
    getMusicianById: PropTypes.func,
    createMusician: PropTypes.func,
    deleteMusician: PropTypes.func,
    updateMusician: PropTypes.func,
    createMusicianInstrument: PropTypes.func,
    deleteMusicianInstrument: PropTypes.func,

    getGroups: PropTypes.func,
    getGroupById: PropTypes.func,
    groups: PropTypes.array,
    groupsById: PropTypes.object,

    getInstruments: PropTypes.func,
    instruments: PropTypes.array,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, musicians } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      filters: [],
      loading: musicians.data.length === 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { getGroupById, groups } = this.props;

    const urlParams = getJsonFromUrl(this.props.location);
    const nextUrlParams = getJsonFromUrl(nextProps.location);
    if (urlParams.index !== nextUrlParams.index) {
      if (Number(nextUrlParams.index) > 0) {
        getGroupById(groups[Number(nextUrlParams.index) - 1].id);
      }
    }
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getMusicians, getGroups } = this.props;
    const { urlParams } = this.state;

    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getMusicians(filters), getGroups()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      musicians,
      getMusicianById,
      createMusician,
      deleteMusician,
      updateMusician,
      getMusicians,
      instruments,
      createMusicianInstrument,
      deleteMusicianInstrument,
      history,
      groups,
      getGroupById,
      groupsById,
    } = this.props;

    const { loading } = this.state;

    return (
      <WrapperMusicianPage
        refresh={this.refresh.bind(this)}
        history={history}
        groups={groups}
        getGroupById={getGroupById}
        loading={loading}
        musicians={musicians}
        groupsById={groupsById}
        getMusicians={getMusicians}
        getMusicianById={getMusicianById}
        createMusician={createMusician}
        deleteMusician={deleteMusician}
        updateMusician={updateMusician}
        createMusicianInstrument={createMusicianInstrument}
        deleteMusicianInstrument={deleteMusicianInstrument}
        urlParams={getJsonFromUrl(window.location)}
        instruments={instruments}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MusicianPage);
