import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const rehearsals = `/${service}/rehearsals`;

export function getRehearsals() {
  const url = `${rehearsals}`;
  return {
    method: GET,
    url,
  };
}

export function getRehearsalById(id) {
  const url = `${rehearsals}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateRehearsal(id, body) {
  const url = `${rehearsals}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteRehearsal(id) {
  const url = `${rehearsals}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createRehearsal(body) {
  const url = `${rehearsals}`;
  return {
    method: POST,
    url,
    body,
  };
}
