import { networkAction } from "helpers/network/networkAction";

import {
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_STUDIO_SESSIONS,
  GET_CUSTOMER_PROJECTS,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  CREATE_CUSTOMER,
} from "constants/customers";

import * as Api from "api";

export const getCustomers = () => async (dispatch) =>
  networkAction(dispatch, GET_CUSTOMERS, Api.getCustomers, []);

export const getCustomerById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CUSTOMER_BY_ID, Api.getCustomerById, [id]);

export const getCustomerStudioSessions = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CUSTOMER_STUDIO_SESSIONS,
    Api.getCustomerStudioSessions,
    [id]
  );

export const getCustomerProjects = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CUSTOMER_PROJECTS, Api.getCustomerProjects, [id]);

export const deleteCustomer = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CUSTOMER, Api.deleteCustomer, [id]);

export const createCustomer = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CUSTOMER, Api.createCustomer, [body]);

export const updateCustomer = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CUSTOMER, Api.updateCustomer, [id, body]);
