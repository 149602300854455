export function getCurrentStageName(stage) {
  return stage?.name.toLowerCase() === "invite" ? "Invited" : stage?.name;
}

export function getSectionDragType(section) {
  if (
    section.sectionID === 80 ||
    section.sectionID === 1 ||
    section.sectionID === 2
  )
    return "violins";

  return section.sectionName;
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function getFullInstrumentationMeta(projectSections) {
  const meta = {};
  for (const k in projectSections) {
    if (Object.hasOwnProperty.call(projectSections, k)) {
      const projectSection = projectSections[k];
      meta[`f-${projectSection.familyID}`] = true;
      meta[`s-${projectSection.id}`] = true;
      for (const j in projectSection.projectMusicians) {
        if (Object.hasOwnProperty.call(projectSection.projectMusicians, j)) {
          const projectMusician = projectSection.projectMusicians[j];
          if (projectMusician.prime) meta[`m-${projectMusician.id}`] = true;
        }
      }
    }
  }

  return meta;
}

export function getMusicianCount(whosPlayingMeta) {
  var count = 0;
  var meta = {};
  try {
    meta = JSON.parse(whosPlayingMeta) || {};
  } catch (error) {
    console.log("Couldn't parse whosPlayingMeta");
  }
  for (const key in meta) {
    if (Object.hasOwnProperty.call(meta, key)) {
      const element = meta[key];
      if (key[0] === "m" && element) {
        count += 1;
      }
    }
  }

  return count;
}

export function isFullInstrumentation(whosPlayingMeta, projectSections) {
  var _whoIsPlayingMeta = {};
  var _fullInstrumentationMeta = {};
  try {
    _whoIsPlayingMeta = JSON.parse(whosPlayingMeta) || {};
    _fullInstrumentationMeta = JSON.parse(
      getFullInstrumentationMeta(projectSections)
    );
  } catch (error) {
    console.log("Couldn't parse whosPlayingMeta");
    return false;
  }

  for (const key in _fullInstrumentationMeta) {
    if (!_whoIsPlayingMeta[key]) {
      return false;
    }
  }

  return true;
}

export function move(
  source,
  destination,
  droppableSource,
  droppableDestination
) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}
