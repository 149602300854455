import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Hidden,
  Icon,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import withWidth from "@material-ui/core/withWidth";
// material-ui
import { withStyles } from "@material-ui/styles";
import RosterConfig from "components/RosterConfig";
import { REACT_APP_API } from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class Roster extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    studioSession: PropTypes.object,
    studioSessionEngagements: PropTypes.array,
    studioSessionMusicians: PropTypes.array,
    api: PropTypes.object,
    refresh: PropTypes.func,
  };

  state = {
    rKey: new Date().getTime(),
    config: "",
    rosterConfigOpen: false,
  };

  render() {
    const {
      classes,
      width,
      studioSession,
      api,
      studioSessionMusicians,
      studioSessionEngagements,
    } = this.props;
    const { rKey, config } = this.state;
    return (
      <div
        className={classes.container}
        style={{
          overflowY: "scroll",
          position: "relative",
          display: "flex",
          flex: 1,
        }}
        ref={this.myRef}
      >
        {studioSession.id ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
            key={1}
          >
            <iFrame
              key={rKey}
              style={{ flex: 1, height: "100%", width: "100%" }}
              src={`${REACT_APP_API}/rhapsody/workSessions/${studioSession.id}/roster${config}`}
            />
          </div>
        ) : (
          []
        )}
        {width !== "sm" && width !== "xs" ? (
          <>
            <Divider orientation="vertical" />
            <div
              style={{
                width: 280,
                overflowX: "hidden",
                marginRight: 16,
                padding: "20px 2px 0px 20px",
                height: "calc(100% - 20px)",
                overflowY: "scroll",
                borderLeft: "solid 1px rgba(155,155,155,0.3)",
                position: "relative",
              }}
            >
              <RosterConfig
                studioSession={studioSession}
                studioSessionMusicians={studioSessionMusicians}
                studioSessionEngagements={studioSessionEngagements}
                api={api}
                onNewConfig={(c) => this.setState({ config: c })}
                refresh={() => this.setState({ rKey: new Date().getTime() })}
              />
            </div>
          </>
        ) : (
          []
        )}
        <Hidden mdUp>
          <Fab
            color="secondary"
            onClick={() =>
              this.setState((p) => ({ rosterConfigOpen: !p.rosterConfigOpen }))
            }
            style={{ position: "fixed", bottom: 20, right: 20, zIndex: 999 }}
          >
            <Icon className="fad fa-cog" />
          </Fab>
          <Dialog open={this.state.rosterConfigOpen} fullScreen>
            <DialogContent>
              <RosterConfig
                studioSession={studioSession}
                studioSessionMusicians={studioSessionMusicians}
                studioSessionEngagements={studioSessionEngagements}
                api={api}
                onNewConfig={(c) => this.setState({ config: c })}
                refresh={() => this.setState({ rKey: new Date().getTime() })}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState((p) => ({
                    rosterConfigOpen: !p.rosterConfigOpen,
                  }))
                }
                fullWidth
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Hidden>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(Roster));
