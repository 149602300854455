import { Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
// material-ui
import { withStyles } from "@material-ui/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class EmailsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    emails: PropTypes.array,
    getEmails: PropTypes.func,
    onSelect: PropTypes.func,
  };

  onSelect(selectedEmail) {
    const { onSelect } = this.props;
    this.setState({ selectedEmail });
    if (onSelect) {
      onSelect(selectedEmail);
    }
  }

  render() {
    const { emails, history, getEmails } = this.props;

    if (emails.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "50vh", textAlign: "center" }}
        >
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              <i style={{ fontSize: 70 }} class="fad fa-paper-plane"></i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              No Emails
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Keep track of all the emails Rhapsody sent.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <AdvancedSearch
        history={history}
        rowCount={emails.length}
        location={window.location}
        refresh={getEmails}
        keyword="emails"
        metaDataEndpoint="/postal/emails/searchMetadata"
      >
        <Table
          data={emails}
          meta={[
            {
              path: "sent",
              render: (t, d) => (!d.sent ? "⚠️" : "✔️"),
            },
            {
              path: "recipients",
              title: "To",
              numeric: false,
              render: (t, d) => (
                <Grid container spacing={1}>
                  {d &&
                    d.recipients &&
                    d.recipients.slice(0, 1).map((r) => (
                      <Grid item>
                        <Chip label={r.emailAddress} size="mini" />
                      </Grid>
                    ))}
                  {d && d.recipients && d.recipients.length > 1 ? (
                    <Tooltip
                      title={`${d.recipients
                        .slice(1, d.recipients.length - 1)
                        .map((r) => ` ${r.emailAddress}`)}`}
                    >
                      <Grid item>
                        <Chip
                          label={`...${d.recipients.length - 1} more`}
                          size="mini"
                          variant="outlined"
                          style={{ cursor: "pointer" }}
                        />
                      </Grid>
                    </Tooltip>
                  ) : (
                    []
                  )}
                </Grid>
              ),
            },
            {
              path: "subject",
              title: "Subject",
              numeric: false,
            },
            {
              path: "sentFromEmail",
              title: "Sent From Email",
              numeric: false,
            },
            {
              path: "sentFromName",
              title: "Sent From Name",
              numeric: false,
            },
            {
              path: "createdAt",
              title: "Date",
              numeric: false,
              transform: (t) => moment(t).format("lll"),
            },
          ]}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(EmailsList);
