import { SET_PROJECT_HIRING_SELECTION } from "constants/projects";

export default (
  state = {
    selectedMusicians: [],
    selectedSessions: [],
    selectedMusicianMap: {},
    selectedSessionMap: {},
  },
  action
) => {
  switch (action.type) {
    case SET_PROJECT_HIRING_SELECTION:
      var _state = JSON.parse(JSON.stringify(state));
      _state = { ..._state, ...action.payload };

      if (action.payload.selectedMusicians) {
        _state.selectedMusicianMap = arrayToMap(
          action.payload.selectedMusicians
        );
      }

      if (action.payload.selectedSessions) {
        _state.selectedSessionMap = arrayToMap(action.payload.selectedSessions);
      }
      return _state;
    default:
      return state;
  }
};

const arrayToMap = (array) => {
  const _map = {};
  for (const key in array) {
    if (Object.hasOwnProperty.call(array, key)) {
      const item = array[key];
      _map[item.id] = true;
    }
  }
  return _map;
};
