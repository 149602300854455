import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class TieCreator extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    instrumentFamilies: PropTypes.array,
    instrumentSections: PropTypes.array,
    updateLink: PropTypes.func,
    createTie: PropTypes.func,
    refresh: PropTypes.func,
    link: PropTypes.object,
  };

  state = {
    anchorEl: null,
    menuOpen: false,
    value: 0,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, menuOpen: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  async createTie(model) {
    const { value } = this.state;
    const { createTie, link, refresh } = this.props;
    await createTie({
      modelName: value === 0 ? "family" : "section",
      modelID: model.id,
      linkID: link.id,
    });
    this.handleClose();
    refresh();
  }

  render() {
    const {
      instrumentFamilies,
      instrumentSections,
      updateLink,
      refresh,
      link,
    } = this.props;

    const { anchorEl, menuOpen, value } = this.state;

    return (
      <div
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <Chip
          onClick={this.handleClick.bind(this)}
          size="small"
          label={"+ Tie"}
        />
        {menuOpen ? (
          <Popper
            anchorEl={anchorEl}
            open={menuOpen}
            transition
            style={{ zIndex: 1100 }}
          >
            <ClickAwayListener onClickAway={this.handleClose.bind(this)}>
              <Paper>
                <Tabs
                  value={value}
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={this.handleChange}
                >
                  <Tab label="Families" />
                  <Tab label="Sections" />
                </Tabs>
                <Divider />
                <div style={{ maxHeight: 300, overflowY: "scroll" }}>
                  {value === 0
                    ? instrumentFamilies &&
                      instrumentFamilies.map((f) => (
                        <MenuItem
                          key={`family_${f.id}`}
                          onClick={() => {
                            this.createTie(f);
                          }}
                        >
                          {f.name}
                        </MenuItem>
                      ))
                    : instrumentSections &&
                      instrumentSections.map((s) => (
                        <MenuItem
                          key={`section_${s.id}`}
                          onClick={() => {
                            this.createTie(s);
                          }}
                        >
                          {s.name}
                        </MenuItem>
                      ))}
                </div>
                <Divider />
                <div style={{ padding: 5, textAlign: "center" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={async () => {
                      this.handleClose();
                      await updateLink(link.id, { global: true });
                      refresh();
                    }}
                  >
                    Make it Global
                  </Button>
                </div>
              </Paper>
            </ClickAwayListener>
          </Popper>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TieCreator);
