import { Collapse, Icon, InputBase } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import { useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import StarsIcon from "@material-ui/icons/Star";
import TransferWithinAStationIcon from "@material-ui/icons/SwapCalls";
import { Alert } from "@material-ui/lab";
import * as Actions from "actions";
import * as actions from "actions";
import { getJsonFromUrl } from "helpers";
import AdjustableNumber from "hooks/AdjustableNumber";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import projects from "reducers/projects";

export default function InstrumentFamiliesMenu({
  layoutPresets,
  instrumentFamilies,
  instrumentSections,
  sessionSection,
  TourCenter,
  onSelect,
  history,
  SubscriptionCenter,
  projectID,
  NotificationCenter,
  primeEnabled = false,
  template = false,
  sortAlphabetically = false,
}) {
  const urlParams = getJsonFromUrl(window.location);
  const [open, setOpen] = React.useState(
    urlParams.instrumentFamiliesMenu && !sessionSection ? true : false
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tab, setTab] = React.useState(0);
  const [autofillID, setAutofillID] = React.useState();
  const [prime, setPrime] = React.useState(true);
  const [nameOpen, setNameOpen] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [name, setName] = React.useState("");
  const [popOverType, setPopOverType] = React.useState("");
  const [sameAsWorkSessionID, setSameAsWorkSessionID] = React.useState("");
  const [sameAsprojectID, setSameAsProjectID] = React.useState("");
  const [color, setColor] = React.useState(
    primeEnabled ? "#D7B740" : "#2196f3"
  );
  const dispatch = useDispatch();
  const [musicianCount, setMusicianCount] = React.useState();
  const [selectedFamily, setSelectedFamily] = React.useState(
    instrumentFamilies && instrumentFamilies.length
      ? instrumentFamilies[0]
      : undefined
  );
  const [pendingSection, setPendingSection] = React.useState();
  const [positions, setPositions] = React.useState([]);
  const [musicianCountOpen, setMusicianCountOpen] = React.useState(false);
  const inputEl = React.useRef(null);

  const templates = useSelector((state) =>
    state.projects.filter((p) => p.template && !p.archived)
  );

  const instrumentationRequired = urlParams.instrumentationRequired === "true";

  React.useEffect(() => {
    if (open && projects.length === 0) {
      dispatch(Actions.getProjects());
    }
    if (open == false) {
      setAutofillID();
    }
  }, [open]);

  let label = (
    <span>
      <i class="fal fa-plus p-right"></i> Chairs
    </span>
  );

  if (sessionSection) label = "+";

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const clearUrlParams = () => {
    const _urlParams = getJsonFromUrl(window.location);
    delete _urlParams.instrumentFamiliesMenu;
    const search = Object.keys(_urlParams)
      .map(
        (k) => `${encodeURIComponent(k)}=${encodeURIComponent(_urlParams[k])}`
      )
      .join("&");
    history.push({
      search,
    });
  };

  const add = (sectionID, familyID, prime) => {
    const _positions = positions.slice(0); // eslint-disable-line
    const existingIndex = _positions.findIndex(
      (p) => p.sectionID === sectionID
    );
    if (existingIndex !== -1) {
      if (prime) {
        _positions[existingIndex].createCount += 1;
      } else {
        _positions[existingIndex].altCount += 1;
      }
    } else {
      _positions.push({
        sectionID,
        familyID,
        createCount: prime ? 1 : 0,
        altCount: !prime ? 1 : 0,
      });
    }

    setPositions(_positions);
  };

  const remove = (sectionID, prime) => {
    let _positions = positions.slice(0); // eslint-disable-line
    const existingIndex = _positions.findIndex(
      (p) => p.sectionID === sectionID
    );
    if (existingIndex !== -1) {
      if (prime) {
        _positions[existingIndex].createCount -= 1;
      } else {
        _positions[existingIndex].altCount -= 1;
      }

      if (
        _positions[existingIndex].createCount === 0 &&
        _positions[existingIndex].altCount === 0
      ) {
        _positions.splice(existingIndex, 1);
      }
    }
    setPositions(_positions);
  };

  const removeFamily = (family) => {
    let _positions = []; // eslint-disable-line
    for (const key in positions) {
      if (positions.hasOwnProperty(key)) {
        const p = positions[key];
        if (p.familyID !== family.id) {
          _positions.push(p);
        }
      }
    }
    setPositions(_positions);
  };

  const onChange = ({ sectionID, familyID, createCount, altCount }) => {
    let _positions = positions.slice(0); // eslint-disable-line
    const existingIndex = _positions.findIndex(
      (p) => p.sectionID === sectionID
    );
    if (existingIndex !== -1) {
      _positions[existingIndex].createCount = Number(createCount);
      _positions[existingIndex].altCount = Number(altCount);
      if (
        _positions[existingIndex].createCount === 0 &&
        _positions[existingIndex].altCount === 0
      ) {
        _positions.splice(existingIndex, 1);
      }
    } else {
      _positions.push({
        sectionID,
        familyID,
        createCount: Number(createCount),
        altCount: Number(altCount),
      });
    }
    setPositions(_positions);
  };

  let sortedInstrumentFamilies = instrumentFamilies || [];
  let sortedInstrumentSection = [];

  if (sortAlphabetically && instrumentFamilies) {
    sortedInstrumentFamilies = instrumentFamilies.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  if (selectedFamily) {
    sortedInstrumentSection =
      instrumentSections &&
      instrumentSections.filter((s) => s.familyID === selectedFamily.id);
    if (sortAlphabetically) {
      sortedInstrumentSection = sortedInstrumentSection.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
  }

  const save = () => {
    onSelect(positions, autofillID);
    setPositions([]);
    clearUrlParams();
    setOpen(false);
  };

  const saveLayout = async () => {
    setNameOpen(false);
    const r = await dispatch(
      actions.createLayoutPreset({
        name,
        layout: positions,
      })
    );
    if (r.success) {
      NotificationCenter.sweetAlert({
        title: "Preset has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });
      dispatch(actions.getLayoutPresets());
      setTimeout(NotificationCenter.hide, 1500);
    }
    setName("");
  };

  const fetchProjectLayout = async () => {
    const r = await dispatch(actions.getProjectLayout(projectID));
    if (r.success) {
      setPositions(r.payload.layout);
    }
  };

  const confirmDelete = async (i) => {
    const resp = await dispatch(actions.deleteLayoutPreset(i.id));
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Layout has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      dispatch(actions.getLayoutPresets());
      setTimeout(NotificationCenter.hide, 1500);
    }
  };

  const askDeleteConfirmation = (p) => {
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this layout, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: () => confirmDelete(p),
        },
      }
    );
  };

  const handleClick = (e) => {
    if (primeEnabled && SubscriptionCenter) {
      if (TourCenter) TourCenter.next();
      if (sessionSection) {
        setPendingSection(sessionSection);
        setMusicianCountOpen(true);
      } else {
        setOpen(true);
      }
    } else {
      if (TourCenter) TourCenter.next();
      if (sessionSection) {
        setPendingSection(sessionSection);
        setMusicianCountOpen(true);
      } else {
        setOpen(true);
      }
    }
  };

  const popOverContent = () => {
    switch (popOverType) {
      case "sameAs":
        return sameAs();
      case "preset":
        return preset();
      default:
    }
  };

  const handleSameAs = async (tab, id) => {
    var r;
    setAnchorEl(null);
    if (tab === 0) {
      r = await dispatch(actions.getProjectLayout(Number(id)));
    }
    if (tab === 1) {
      r = await dispatch(actions.getStudioSessionLayout(Number(id)));
    }
    if (r.success) {
      setPositions(r.payload.layout);
    }
  };

  const sameAs = () => {
    return (
      <Paper>
        {/* <Tabs
          value={tab}
          onChange={(event, value) => setTab(value)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Projects" />
          <Tab label="Work Sessions" />
        </Tabs>
        <Divider /> */}
        <div
          style={{
            maxWidth: 300,
            overflowY: "scroll",
            padding: 16,
            textAlign: "center",
          }}
        >
          <Grid container spacing={2}>
            {templates.length ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    This action will copy the chairs from:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List dense style={{ maxHeight: 200, overflow: "scroll" }}>
                    {templates?.map((t) => (
                      <ListItem
                        onClick={() => handleSameAs(0, t.id)}
                        button
                        divider
                        key={t.id}
                      >
                        <ListItemText>{t.name}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </>
            ) : (
              <Paper
                variant="outlined"
                style={{ borderStyle: "dashed", padding: 8, margin: 4 }}
              >
                <Typography variant="h6" color="textSecondary">
                  <i class="fa-duotone fa-square-dashed"></i>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Tip: Create templates to save time on your instrumentation
                  setup.
                </Typography>
              </Paper>
            )}
            <Grid item xs={12}>
              {templates.length ? (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setCollapsed((s) => !s)}
                >
                  <i class="fas fa-sliders-v"></i> Advanced{" "}
                  {collapsed ? (
                    <i class="fas fa-sort-down"></i>
                  ) : (
                    <i class="fas fa-sort-up"></i>
                  )}
                </Typography>
              ) : (
                []
              )}
              <Collapse in={collapsed || templates.length === 0}>
                <Grid container spacing={1} style={{ marginTop: 4 }}>
                  <Grid item xs={12}>
                    <InputBase
                      style={{
                        background: "rgba(155,155,155,0.1)",
                        borderRadius: 40,
                        padding: "0px 8px",
                      }}
                      onChange={(e) => {
                        setSameAsProjectID(e.target.value);
                        setSameAsWorkSessionID("");
                      }}
                      fullWidth
                      type="number"
                      value={sameAsprojectID}
                      startAdornment={
                        <i
                          style={{ color: "rgba(155,155,155,0.8)" }}
                          class={"fad fa-folder-open p-right"}
                        ></i>
                      }
                      placeholder={"Project ID"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      style={{
                        background: "rgba(155,155,155,0.1)",
                        borderRadius: 40,
                        padding: "0px 8px",
                      }}
                      onChange={(e) => {
                        setSameAsWorkSessionID(e.target.value);
                        setSameAsProjectID("");
                      }}
                      value={sameAsWorkSessionID}
                      fullWidth
                      type="number"
                      startAdornment={
                        <i
                          style={{ color: "rgba(155,155,155,0.8)" }}
                          class={"fad fa-calendar-day p-right"}
                        ></i>
                      }
                      placeholder={"Work Session ID"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      variant="contained"
                      fullWidth
                      color="primary"
                      disabled={!sameAsWorkSessionID && !sameAsprojectID}
                      onClick={() => {
                        if (sameAsWorkSessionID)
                          handleSameAs(1, sameAsWorkSessionID);
                        if (sameAsprojectID) handleSameAs(0, sameAsprojectID);
                      }}
                    >
                      OK
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                {tab === 0 ? "Project" : "Work Session"} ID can be found in its
                URL.
                <br />
                Ex: https://studio.rhapsody.la/{}
                {tab === 0 ? "project" : "workSession"}?id=
                <u>
                  <b>123</b>
                </u>
              </Typography>
            </Grid> */}
          </Grid>
        </div>
      </Paper>
    );
  };

  const preset = () => {
    return (
      <Paper>
        <div style={{ maxHeight: 300, width: 280, overflowY: "scroll" }}>
          <List>
            {layoutPresets.map((p) => (
              <ListItem
                key={p.id}
                divider
                button
                onClick={async () => {
                  setAnchorEl(null);
                  const r = await dispatch(actions.getLayoutPresetById(p.id));
                  setPositions(r.payload.layout);
                  if (primeEnabled) {
                    setPrime(true);
                    setColor("#D7B740");
                  }
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary={p.name} />
                {!p.global ? (
                  <ListItemSecondaryAction>
                    <Tooltip title="Delete Preset">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          askDeleteConfirmation(p);
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                ) : (
                  []
                )}
              </ListItem>
            ))}
          </List>
          {layoutPresets.filter(
            (l) => (tab === 0 && l.global) || (tab === 1 && !l.global)
          ).length === 0 ? (
            <div
              style={{
                padding: 16,
                textAlign: "center",
                maxWidth: 200,
                margin: "auto",
              }}
            >
              <CalendarViewDayIcon
                style={{ color: "rgba(155,155,155)", fontSize: 28 }}
              />
              <Typography
                color="textSecondary"
                variant="caption"
                display="block"
              >
                Click on the Save as Layout button to create a custom preset
              </Typography>
            </div>
          ) : (
            []
          )}
        </div>
      </Paper>
    );
  };

  return (
    <>
      <Chip
        variant={sessionSection ? "outlined" : "default"}
        data-tut="reactour__addFamily"
        size="small"
        label={label}
        onClick={handleClick}
      />
      <Dialog
        open={musicianCountOpen}
        onClose={() => {
          setMusicianCount("");
          setMusicianCountOpen(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          How many additional {pendingSection && pendingSection.name} Chairs ?
          {primeEnabled ? (
            <div style={{ display: "flex" }}>
              <Button
                variant={prime ? "contained" : "outlined"}
                style={{
                  textTransform: "none",
                  borderRadius: "4px 0px 0px 4px",
                  boxShadow: "none",
                  background: prime && color,
                  color: prime && "white",
                }}
                size="small"
                onClick={() => {
                  setPrime(true);
                  setColor("#D7B740");
                }}
              >
                <StarsIcon style={{ fontSize: 16, marginRight: 4 }} /> Primary
              </Button>
              <Button
                variant={!prime ? "contained" : "outlined"}
                style={{
                  borderRadius: "0px 4px 4px 0px",
                  textTransform: "none",
                  marginLeft: -1,
                  boxShadow: "none",
                  background: !prime && color,
                  color: !prime && "white",
                }}
                size="small"
                onClick={() => {
                  setPrime(false);
                  setColor("#607d8b");
                }}
              >
                <TransferWithinAStationIcon
                  style={{ fontSize: 16, marginRight: 4 }}
                />{" "}
                Alternate
              </Button>
            </div>
          ) : (
            []
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="count"
            label="Count"
            fullWidth
            autoFocus
            value={musicianCount}
            onChange={(e) => setMusicianCount(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-between">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Button
                    onClick={() => {
                      setMusicianCount("");
                      setMusicianCountOpen(false);
                      clearUrlParams();
                    }}
                    variant="contained"
                    style={{ textTransform: "none", marginRight: 8 }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      onSelect({
                        section: { ...pendingSection },
                        quantity: Number(musicianCount),
                        prime,
                      });
                      setMusicianCountOpen(false);
                      setPendingSection();
                      clearUrlParams();
                    }}
                    variant="contained"
                    autoFocus
                    disbled={positions.length === 0}
                    style={{
                      background: color,
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    Add{" "}
                    {positions.length
                      ? `${positions.reduce((a, p) => a + p.createCount, 0)}${
                          primeEnabled
                            ? ` ${prime ? "Primary" : "Alternate"}`
                            : ""
                        } Chairs`
                      : ""}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth
        scroll={"paper"}
        maxWidth={"sm"}
        onClose={!instrumentationRequired ? () => setOpen(false) : undefined}
        aria-labelledby="responsive-dialog-title"
      >
        <div data-tut="reactour__selectFamily">
          <DialogTitle id="responsive-dialog-title">
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h6" display="block">
                  {instrumentationRequired
                    ? "Instrumentation"
                    : "Add additional Chairs"}
                </Typography>
                {projectID ? (
                  <Chip
                    variant="outlined"
                    label="Use Project's Layout"
                    onClick={fetchProjectLayout}
                  />
                ) : (
                  []
                )}
              </Grid>
              <Grid item>
                <Chip
                  label="Copy from"
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setPopOverType("sameAs");
                  }}
                  size="small"
                  style={{ marginRight: 4 }}
                  deleteIcon={<ArrowDropDownIcon />}
                  onDelete={(e) => {
                    setAnchorEl(e.currentTarget);
                    setPopOverType("sameAs");
                  }}
                />
                <Chip
                  label="Presets"
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setPopOverType("preset");
                  }}
                  size="small"
                  deleteIcon={<ArrowDropDownIcon />}
                  onDelete={(e) => {
                    setAnchorEl(e.currentTarget);
                    setPopOverType("preset");
                  }}
                />
                {positions.length ? (
                  <Tooltip title="Save as Preset">
                    <IconButton onClick={() => setNameOpen(true)} size="small">
                      <Icon
                        style={{ fontSize: 16, marginLeft: 4 }}
                        class="fa-solid fa-floppy-disk"
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  []
                )}
              </Grid>
              {instrumentationRequired && !template ? (
                <Grid item xs={12} style={{ marginTop: 8 }}>
                  <Alert severity="info">
                    Determine how many musicians you will need to contact for
                    your Project by selecting the appropriate number of Chairs
                    for each Instrument. Chairs that are required to play on
                    multiple/secondary Instruments should be counted as a single
                    Chair under the most appropriate Instrument.
                  </Alert>
                </Grid>
              ) : (
                []
              )}
              <Popover
                open={Boolean(anchorEl)}
                disableEnforceFocus
                disableRestoreFocus
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={anchorEl}
              >
                {popOverContent()}
              </Popover>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ padding: 10 }}>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="overline">Families</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="overline">Sections</Typography>
              </Grid>
              <Grid
                item
                xs={primeEnabled ? 2 : 4}
                style={
                  !primeEnabled
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        paddingRight: 30,
                      }
                    : {}
                }
              >
                <Typography variant="overline">Chairs</Typography>
              </Grid>
              {primeEnabled ? (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      "Select the appropriate number of Alternate Chairs needed for your Project. An Alternate Chair represents a musician who can replace a specific Chair in your Instrumentation if need be."
                    }
                  >
                    <Typography variant="overline">
                      Alternates <i class="far fa-question-circle"></i>
                    </Typography>
                  </Tooltip>
                </Grid>
              ) : (
                []
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  borderRight: "solid 1px rgba(155,155,155,0.3)",
                  height: !fullScreen ? 500 : undefined,
                  overflow: "scroll",
                }}
              >
                <List dense style={{ marginRight: 10 }}>
                  {sortedInstrumentFamilies.map((f) => {
                    const instrumentsSectionCount =
                      instrumentSections &&
                      instrumentSections.filter((s) => s.familyID === f.id)
                        .length;
                    let selectedInstrumentsSectionCount = 0;
                    let selectedInstrumentsSectionAltCount = 0;

                    if (positions.length) {
                      selectedInstrumentsSectionCount = positions.reduce(
                        (a, p) => {
                          const toAdd = p.familyID === f.id ? p.createCount : 0;
                          return a + toAdd;
                        },
                        0
                      );

                      selectedInstrumentsSectionAltCount = positions.reduce(
                        (a, p) => {
                          const toAdd = p.familyID === f.id ? p.altCount : 0;
                          return a + toAdd;
                        },
                        0
                      );
                    }

                    if (instrumentsSectionCount) {
                      return (
                        <ListItem
                          button
                          key={f.id}
                          style={{
                            background:
                              selectedFamily && selectedFamily.id === f.id
                                ? color
                                : undefined,
                            color:
                              selectedFamily && selectedFamily.id === f.id
                                ? "white"
                                : undefined,
                            borderRadius: 4,
                          }}
                          onClick={() => {
                            if (TourCenter) TourCenter.hide();
                            setSelectedFamily(f);
                          }}
                        >
                          <ListItemText
                            primary={f.name}
                            secondary={
                              selectedInstrumentsSectionCount ||
                              selectedInstrumentsSectionAltCount ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    style={{
                                      color:
                                        selectedFamily &&
                                        selectedFamily.id === f.id
                                          ? "rgba(255,255,255,0.6)"
                                          : color,
                                    }}
                                  >
                                    {`${
                                      selectedInstrumentsSectionCount
                                        ? `${selectedInstrumentsSectionCount} Chair${
                                            selectedInstrumentsSectionCount > 1
                                              ? "s"
                                              : ""
                                          }`
                                        : ""
                                    } ${
                                      selectedInstrumentsSectionAltCount
                                        ? `${selectedInstrumentsSectionAltCount} Alternate${
                                            selectedInstrumentsSectionAltCount >
                                            1
                                              ? "s"
                                              : ""
                                          }`
                                        : ""
                                    }`}
                                  </Typography>
                                  {/* <Tooltip title={`Remove ${f.name} Chairs`}>
                                    <Close
                                      style={{
                                        fontSize: 14,
                                        border: "solid 1px rgba(0,0,0,0.3)",
                                        borderRadius: 30,
                                        marginLeft: 4,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        removeFamily(f);
                                      }}
                                    />
                                  </Tooltip> */}
                                </div>
                              ) : (
                                ""
                              )
                            }
                          />
                        </ListItem>
                      );
                    }
                    return [];
                  })}
                </List>
              </Grid>
              <Grid item xs={8}>
                {selectedFamily ? (
                  <List>
                    {sortedInstrumentSection.map((s) => {
                      const existingPostions = positions.find(
                        (p) => p.sectionID === s.id
                      );
                      return (
                        <>
                          <Grid container style={{ padding: 8 }}>
                            <Grid item xs={6}>
                              <Typography variant="body2">{s.name}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={primeEnabled ? 3 : 6}
                              style={
                                !primeEnabled
                                  ? {
                                      display: "flex",
                                      justifyContent: "end",
                                    }
                                  : {}
                              }
                            >
                              <AdjustableNumber
                                value={`${
                                  (existingPostions &&
                                    existingPostions.createCount) ||
                                  0
                                }`}
                                background={
                                  primeEnabled ? "#D7B740" : "#2196f3"
                                }
                                onChange={(e) =>
                                  onChange({
                                    sectionID: s.id,
                                    familyID: s.familyID,
                                    createCount: e.target.value,
                                    altCount: existingPostions?.altCount ?? 0,
                                  })
                                }
                                remove={() => remove(s.id, true)}
                                add={() => add(s.id, s.familyID, true)}
                              />
                            </Grid>
                            {primeEnabled ? (
                              <Grid item xs={3}>
                                <AdjustableNumber
                                  value={`${
                                    (existingPostions &&
                                      existingPostions.altCount) ||
                                    0
                                  }`}
                                  background={"#607d8b"}
                                  onChange={(e) =>
                                    onChange({
                                      sectionID: s.id,
                                      familyID: s.familyID,
                                      altCount: e.target.value,
                                      createCount:
                                        existingPostions?.createCount ?? 0,
                                    })
                                  }
                                  remove={() => remove(s.id, false)}
                                  add={() => add(s.id, s.familyID, false)}
                                />
                              </Grid>
                            ) : (
                              []
                            )}
                          </Grid>
                          <Divider />
                        </>
                      );
                    })}
                  </List>
                ) : (
                  []
                )}
              </Grid>
              {!projectID && !template && templates?.length ? (
                <Grid item xs={12}>
                  <Divider />
                  <br />
                  <Typography variant="body2" display="inline">
                    {" "}
                    <i class="fas fa-wand-magic p-right"></i>Autofill with
                    Template:
                  </Typography>
                  {templates.map((t) => (
                    <Chip
                      style={{
                        marginLeft: 4,
                        background: autofillID == t.id && "#D7B740",
                        color: autofillID == t.id && "white",
                      }}
                      size="small"
                      onClick={() =>
                        setAutofillID(autofillID == t.id ? null : t.id)
                      }
                      variant={autofillID == t.id ? "default" : "outlined"}
                      label={t.name}
                    />
                  ))}
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justify="space-between">
              <Grid item>
                <Grid container>
                  <Grid item>
                    {positions.length ? (
                      <Button
                        variant="outlined"
                        style={{ textTransform: "none" }}
                        onClick={() => setPositions([])}
                      >
                        Clear All
                      </Button>
                    ) : (
                      []
                    )}
                  </Grid>
                  {/* <Grid item>
                    {positions.length ? (
                      <Button
                        variant="outlined"
                        style={{ textTransform: "none", marginLeft: 16 }}
                        onClick={() => setNameOpen(true)}
                      >
                        Save as Preset
                      </Button>
                    ) : (
                      []
                    )}
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  {!instrumentationRequired ? (
                    <Grid item>
                      <Button
                        autoFocus
                        onClick={() => {
                          setOpen(false);
                          clearUrlParams();
                        }}
                        variant="contained"
                        style={{ textTransform: "none" }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  ) : (
                    []
                  )}
                  <Grid item>
                    <Button
                      onClick={positions.length ? save : undefined}
                      variant="contained"
                      autoFocus
                      disabled={positions.length === 0}
                      style={{
                        background: color,
                        opacity: positions.length === 0 ? 0.5 : 1,
                        color: "white",
                        textTransform: "none",
                        marginLeft: 16,
                      }}
                    >
                      {instrumentationRequired
                        ? "Next"
                        : `Add ${autofillID ? "and autofill" : ""}`}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={nameOpen}
        onClose={() => setNameOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">New Preset</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item>
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                autoFocus
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item>
              <Button onClick={() => setNameOpen(false)} color="secondary">
                Cancel
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              onClick={saveLayout}
              variant="contained"
              color="secondary"
              autoFocus
            >
              Create
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
