import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const sessionSections = `/${service}/sessionSections`;

export function getSessionSections() {
  const url = `${sessionSections}`;
  return {
    method: GET,
    url,
  };
}

export function getSessionSectionById(id) {
  const url = `${sessionSections}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSessionSection(id, body) {
  const url = `${sessionSections}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSessionSection(id) {
  const url = `${sessionSections}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSessionSection(body) {
  const url = `${sessionSections}`;
  return {
    method: POST,
    url,
    body,
  };
}
