// material-ui
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { withStyles } from "@material-ui/styles";
// components
import EmailsList from "components/EmailsList";
import Page from "components/Page";
// config
import { EMAIL } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperEmailsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    dashboard: PropTypes.any,

    //emails
    emails: PropTypes.array,
    getEmails: PropTypes.func,
    getEmailById: PropTypes.func,
    createEmail: PropTypes.func,
    deleteEmail: PropTypes.func,
    updateEmail: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedEmail: undefined,
      formEmailOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { emails, history, getEmails } = this.props;

    switch (index) {
      case 0:
        return (
          <EmailsList
            history={history}
            emails={emails}
            getEmails={getEmails}
            // onSelect={(selectedEmail) => window.open(`${REACT_APP_API}/postal/emails/${selectedEmail.id}/HTML`, '_blank')}
            onSelect={(selectedEmail) =>
              history.push(`${EMAIL}?id=${selectedEmail.id}`)
            }
          />
        );
      default:
        return <div />;
    }
  }

  render() {
    const { classes, history, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet={"Emails"}
        loadingMessage={"Loading Document"}
        loading={loading}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Emails"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div style={{ marginTop: 16 }}>{this.getStepContent()}</div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEmailsPage);
