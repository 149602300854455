import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperInstrumentSectionPage from "../component/WrapperInstrumentSectionPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  instrumentSections: state.instrumentSections,
  instrumentFamilies: state.instrumentFamilies,
});

const mapDispatchToProps = (dispatch) => ({
  getInstrumentFamilies: () => dispatch(Actions.getInstrumentFamilies()),
  getInstrumentSections: () => dispatch(Actions.getInstrumentSections()),
  getInstrumentSectionById: (...args) =>
    dispatch(Actions.getInstrumentSectionById(...args)),
  createInstrumentSection: (...args) =>
    dispatch(Actions.createInstrumentSection(...args)),
  deleteInstrumentSection: (...args) =>
    dispatch(Actions.deleteInstrumentSection(...args)),
  updateInstrumentSection: (...args) =>
    dispatch(Actions.updateInstrumentSection(...args)),
});
class InstrumentSectionPage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    instrumentSections: PropTypes.array,
    instrumentFamilies: PropTypes.array,
    getInstrumentFamilies: PropTypes.func,
    getInstrumentSections: PropTypes.func,
    getInstrumentSectionById: PropTypes.func,
    createInstrumentSection: PropTypes.func,
    deleteInstrumentSection: PropTypes.func,
    updateInstrumentSection: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, instrumentSections } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: instrumentSections.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getInstrumentSections, getInstrumentFamilies } = this.props;
    await Promise.all([getInstrumentSections(), getInstrumentFamilies()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      instrumentSections,
      instrumentFamilies,
      getInstrumentSectionById,
      createInstrumentSection,
      deleteInstrumentSection,
      updateInstrumentSection,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperInstrumentSectionPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        instrumentSections={instrumentSections}
        instrumentFamilies={instrumentFamilies}
        getInstrumentSectionById={getInstrumentSectionById}
        createInstrumentSection={createInstrumentSection}
        deleteInstrumentSection={deleteInstrumentSection}
        updateInstrumentSection={updateInstrumentSection}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstrumentSectionPage);
