import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
// material-ui
import { withStyles } from "@material-ui/styles";
import { getSectionDragType } from "helpers";
import InstrumentFamiliesMenu from "hooks/InstrumentFamiliesMenu";
// hook
import ProjectAlternate from "hooks/ProjectAlternate";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
// styles
import styles from "./styles";

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  width: "100%",
});

class ProjectSection extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    api: PropTypes.object,
    sectionRoles: PropTypes.array,
    project: PropTypes.object,
    refreshKey: PropTypes.number,
    projectSection: PropTypes.object,
    layoutPresets: PropTypes.array,
    disabled: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "Please confirm you action. Make sure all musicians have been removed from the section.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { api, projectSection } = this.props;

    await api.deleteProjectSection(projectSection.id);
    api.refresh();
  }

  async addMusicians(e) {
    const { api, project } = this.props;

    await api.createProjectAlternate([
      {
        sectionID: e.section.sectionID,
        projectID: project.id,
        createCount: e.prime ? e.quantity : 0,
        altCount: e.prime ? 0 : e.quantity,
      },
    ]);

    api.refresh();
  }

  render() {
    const {
      projectSection,
      api,
      sectionRoles,
      project,
      history,
      layoutPresets,
      disabled,
      projectMusicians,
    } = this.props;
    const { NotificationCenter } = this.context;

    return (
      <div style={{ position: "relative" }}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography
              variant="body"
              style={{ fontWeight: 600 }}
              color="secondary"
            >
              {projectSection.sectionName}
            </Typography>
          </Grid>
          {!disabled ? (
            <Grid item>
              <Close
                style={{
                  height: 15,
                  width: 15,
                  paddingTop: 4,
                  cursor: "pointer",
                }}
                color="secondary"
                onClick={this.delete.bind(this)}
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <Grid container direction="column">
          {/* <DragDropContext onDragEnd={this.onDragEnd.bind(this)}> */}
          <Droppable
            droppableId={`${projectSection.sectionID}`}
            type={getSectionDragType(projectSection)}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {projectMusicians &&
                  projectMusicians.map((m, index) => (
                    <Draggable
                      key={m.id}
                      draggableId={`${m.id}`}
                      index={index}
                      type={getSectionDragType(projectSection)}
                      isDragDisabled={disabled || this.props.disabled || false}
                    >
                      {(p, s) => (
                        <div
                          ref={p.innerRef}
                          {...p.draggableProps}
                          {...p.dragHandleProps}
                          style={getItemStyle(
                            s.isDragging,
                            p.draggableProps.style
                          )}
                        >
                          <Grid
                            item
                            key={`SectionMusician_${m.id}`}
                            style={{ padding: 5 }}
                          >
                            <ProjectAlternate
                              projectSection={projectSection}
                              projectAlternate={m}
                              disabled={this.props.disabled}
                              sectionRoles={sectionRoles}
                              history={history}
                              project={project}
                              api={api}
                            />
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {/* </DragDropContext> */}
          {!this.props.disabled && !disabled ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <InstrumentFamiliesMenu
                api={api}
                history={history}
                layoutPresets={layoutPresets}
                primeEnabled
                NotificationCenter={NotificationCenter}
                sessionSection={projectSection}
                onSelect={this.addMusicians.bind(this)}
              />
            </Grid>
          ) : (
            <Grid />
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ProjectSection);
