import { networkAction } from "helpers/network/networkAction";

import {
  GET_REHEARSALS,
  GET_REHEARSAL_BY_ID,
  DELETE_REHEARSAL,
  UPDATE_REHEARSAL,
  CREATE_REHEARSAL,
} from "constants/rehearsals";

import * as Api from "api";

export const getRehearsals = () => async (dispatch) =>
  networkAction(dispatch, GET_REHEARSALS, Api.getRehearsals, []);

export const getRehearsalById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_REHEARSAL_BY_ID, Api.getRehearsalById, [id]);

export const deleteRehearsal = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_REHEARSAL, Api.deleteRehearsal, [id]);

export const createRehearsal = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_REHEARSAL, Api.createRehearsal, [body]);

export const updateRehearsal = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_REHEARSAL, Api.updateRehearsal, [id, body]);
