import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import { Help } from "components";
// custom
import DateTime from "components/DateTime";
import FormattedPhone from "hooks/FormattedPhone";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class B4 extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    api: PropTypes.array,
    studioSession: PropTypes.object,
    refresh: PropTypes.func,
    conciergeReadOnly: PropTypes.bool,
    archived: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { studioSession } = this.props;
    this.state = {
      ...studioSession,
      loading: false,
      newTuneLoading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { api, refresh, studioSession } = this.props;

    if (studioSession[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    if (name === "date") {
      this.state.date = moment(this.state.date).format("YYYY-MM-DD HH:mm:ss");
    }

    if (name === "originalRecordingDate") {
      this.state.originalRecordingDate = moment(
        this.state.originalRecordingDate
      ).format("YYYY-MM-DD HH:mm:ss");
    }

    await api.updateStudioSession(studioSession.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const { classes, studioSession, conciergeReadOnly } = this.props;

    const {
      loading,
      wageAgreement,
      producer,
      date,
      engineer,
      conductor,
      payrollNumber,
      title,
      archived,
      description,
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2">Title:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      fullWidth
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("title")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("title");
                      }}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={title}
                      onChange={this.handleChange("title")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2">Payroll #:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      fullWidth
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("payrollNumber");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("payrollNumber")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={payrollNumber}
                      onChange={this.handleChange("payrollNumber")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Project Description
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Contract Date:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DateTime
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("date");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("date")}
                      noTime
                      className={archived ? undefined : classes.inputBase}
                      date={date}
                      onChange={this.handleChange("date")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Record Co:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.customer.name}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Label:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.project.label}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Rep:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.customer.contact}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Address:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={`${studioSession.customer.address} ${studioSession.customer.city} ${studioSession.customer.state} ${studioSession.customer.zipcode}`}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Phone:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormattedPhone value={studioSession.customer.phone} />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Artist or Group:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.project.name}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Session Producer:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.customer.contact}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Industry Project#:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.project.industryProjectNumber}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Session Description
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Wage Scale Agreement:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("wageAgreement");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("wageAgreement")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={wageAgreement}
                      onChange={this.handleChange("wageAgreement")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2"># Musicians:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.musicianCount}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Producer:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("producer");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("producer")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={producer}
                      onChange={this.handleChange("producer")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Engineer:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("engineer");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("engineer")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={engineer}
                      onChange={this.handleChange("engineer")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Conductor:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("conductor");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("conductor")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={conductor}
                      onChange={this.handleChange("conductor")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Help
                      title="Internal Notes:"
                      help="Anything useful for your own organization"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled={loading}
                      fullWidth
                      rows={3}
                      rowsMax={100}
                      multiline
                      onBlur={() => this.save("description")}
                      style={{ background: "rgba(255,235,59,0.1)" }}
                      className={classes.inputBase}
                      value={description || ""}
                      onChange={this.handleChange("description")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(B4);
