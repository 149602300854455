import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const sectionInstruments = `/${service}/sectionInstruments`;

export function getSectionInstruments() {
  const url = `${sectionInstruments}`;
  return {
    method: GET,
    url,
  };
}

export function getSectionInstrumentById(id) {
  const url = `${sectionInstruments}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSectionInstrument(id, body) {
  const url = `${sectionInstruments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSectionInstrument(id) {
  const url = `${sectionInstruments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSectionInstrument(body) {
  const url = `${sectionInstruments}`;
  return {
    method: POST,
    url,
    body,
  };
}
