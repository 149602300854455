import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "mercury";
const pipelines = `${REACT_APP_API_PREFIX}/${service}/pipelines`;

export function getPipelines(filters) {
  const url = `${pipelines}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getPipelineById(id) {
  const url = `${pipelines}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getPipelineGraphById(id) {
  const url = `${pipelines}/${id}/graph`;
  return {
    method: GET,
    url,
  };
}

export function updatePipeline(id, body) {
  const url = `${pipelines}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePipeline(id) {
  const url = `${pipelines}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPipeline(body) {
  const url = `${pipelines}`;
  return {
    method: POST,
    url,
    body,
  };
}
