export const typeOptions = [
  {
    id: 1,
    title: "Rehearsal",
    icon: "fas fa-list-music", //"fas fa-comment-music", //"fas fa-ear", //"fas fa-user-music",
    sessionTypeID: 13,
  },
  {
    id: 2,
    title: "Dress Rehearsal",
    icon: "fas fa-user-tie",
    sessionTypeID: 13,
  },
  { id: 3, title: "Sound Check", icon: "fas fa-speaker", sessionTypeID: 13 },
  {
    id: 4,
    title: "Performance",
    icon: "fas fa-theater-masks",
    sessionTypeID: 2,
  },
  {
    id: 5,
    title: "Recording Session",
    icon: "fas fa-microphone",
    sessionTypeID: 1,
  },
  { id: 6, title: "Other", icon: "fad fa-ellipsis-h", sessionTypeID: 1 },
];
