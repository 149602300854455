// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperStudioSessionPage from "../component/WrapperStudioSessionPage";

const mapStateToProps = (state) => ({
  studioSessions: state.studioSessions,
  activeStudioSessions: state.activeStudioSessions,
  archivedStudioSessions: state.archivedStudioSessions,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  getStudioSessions: (...args) => dispatch(Actions.getStudioSessions(...args)),
  getActiveStudioSessions: (...args) =>
    dispatch(Actions.getActiveStudioSessions(...args)),
  getArchivedStudioSessions: (...args) =>
    dispatch(Actions.getArchivedStudioSessions(...args)),
  updateStudioSession: (...args) =>
    dispatch(Actions.updateStudioSession(...args)),
  setPreferedWorkSessionPresentation: (...args) =>
    dispatch(Actions.setPreferedWorkSessionPresentation(...args)),
});

class StudioSessionPage extends PureComponent {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    studioSessions: PropTypes.array,
    activeStudioSessions: PropTypes.array,
    archivedStudioSessions: PropTypes.array,
    getStudioSessions: PropTypes.func,
    updateStudioSession: PropTypes.func,

    getActiveStudioSessions: PropTypes.func,
    getArchivedStudioSessions: PropTypes.func,

    setPreferedWorkSessionPresentation: PropTypes.func,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { getProjects, projects } = this.props;
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  componentWillReceiveProps(nextProps) {
    const {
      getActiveStudioSessions,
      getArchivedStudioSessions,
      getStudioSessions,
    } = this.props;

    const urlParams = getJsonFromUrl(this.props.location);
    const nextUrlParams = getJsonFromUrl(nextProps.location);
    if (urlParams.index !== nextUrlParams.index) {
      this.setState({ loading: true });
      const f = () => this.setState({ loading: false });
      switch (nextUrlParams.index) {
        case "0":
          if (nextProps.activeStudioSessions.length === 0) {
            getActiveStudioSessions().then(f);
          } else f();
          break;
        case "1":
          if (nextProps.archivedStudioSessions.length === 0) {
            getArchivedStudioSessions().then(f);
          } else f();
          break;
        case "2":
          if (nextProps.studioSessions.length === 0) {
            getStudioSessions().then(f);
          } else f();
          break;
        default:
          if (nextProps.activeStudioSessions.length === 0) {
            getActiveStudioSessions().then(f);
          } else f();
          break;
      }
    }
  }

  async refresh(filters) {
    const {
      getActiveStudioSessions,
      getArchivedStudioSessions,
      getStudioSessions,
      location,
    } = this.props;

    this.setState({ loading: true });

    let f;
    const urlParams = getJsonFromUrl(location);

    switch (urlParams.index) {
      case "0":
        f = getActiveStudioSessions;
        break;
      case "1":
        f = getArchivedStudioSessions;
        break;
      case "2":
        f = getStudioSessions;
        break;
      default:
        f = getActiveStudioSessions;
        break;
    }

    await Promise.all([f(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      activeStudioSessions,
      archivedStudioSessions,
      studioSessions,
      updateStudioSession,
      location,
      history,
      setPreferedWorkSessionPresentation,
      app,
    } = this.props;

    const { loading } = this.state;

    let sessions = [];

    const urlParams = getJsonFromUrl(location);

    switch (urlParams.index) {
      case "0":
        sessions = activeStudioSessions;
        break;
      case "1":
        sessions = archivedStudioSessions;
        break;
      case "2":
        sessions = studioSessions;
        break;
      default:
        sessions = activeStudioSessions;
        break;
    }

    return (
      <WrapperStudioSessionPage
        refresh={this.refresh.bind(this)}
        urlParams={getJsonFromUrl(window.location)}
        history={history}
        setPreferedWorkSessionPresentation={setPreferedWorkSessionPresentation}
        app={app}
        loading={loading}
        studioSessions={sessions}
        updateStudioSession={updateStudioSession}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudioSessionPage);
