import * as Api from "api";
import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS,
  GET_PROJECT_BY_ID,
  GET_PROJECT_HIRING,
  GET_PROJECT_LAYOUT,
  GET_PROJECT_MERCURY,
  GET_PROJECT_SECTIONS,
  GET_PROJECT_STATUSES,
  GET_PROJECT_STUDIO_SESSIONS,
  PROJECT_AUTOFILL,
  PUT_PROJECT_HIRING,
  PUT_PROJECT_HIRING_NOTIFY,
  SET_PROJECT_HIRING_SELECTION,
  UPDATE_PROJECT,
  UPDATE_PROJECT_MERCURY,
  UPLOAD_PROJECT_LOGO,
} from "constants/projects";
import { networkAction } from "helpers/network/networkAction";

export const getProjects = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECTS, Api.getProjects, [filters]);

export const getProjectMercury = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_MERCURY, Api.getProjectMercury, [id]);

export const updateProjectHiring = (id, body) => async (dispatch) =>
  networkAction(dispatch, PUT_PROJECT_HIRING, Api.updateProjectHiring, [
    id,
    body,
  ]);

export const updateProjectHiringNotify = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    PUT_PROJECT_HIRING_NOTIFY,
    Api.updateProjectHiringNotify,
    [id, body]
  );

export const getProjectHiring = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_HIRING, Api.getProjectHiring, [id]);

export const updateProjectMercury = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROJECT_MERCURY, Api.updateProjectMercury, [
    id,
    body,
  ]);

export const getProjectLayout = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_LAYOUT, Api.getProjectLayout, [id]);

export const getProjectSections = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_SECTIONS, Api.getProjectSections, [id]);

export const projectAutofill = (id, body) => async (dispatch) =>
  networkAction(dispatch, PROJECT_AUTOFILL, Api.projectAutofill, [id, body]);

export const getProjectStatuses = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_STATUSES, Api.getProjectStatuses, [id]);

export const getProjectById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_BY_ID, Api.getProjectById, [id]);

export const getProjectStudioSessions = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROJECT_STUDIO_SESSIONS,
    Api.getProjectStudioSessions,
    [id]
  );

export const deleteProject = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PROJECT, Api.deleteProject, [id]);

export const createProject = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PROJECT, Api.createProject, [body]);

export const updateProject = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROJECT, Api.updateProject, [id, body]);

export const uploadProjectLogo = (projectID, logoImage) => async (dispatch) =>
  networkAction(dispatch, UPLOAD_PROJECT_LOGO, Api.uploadProjectLogo, [
    projectID,
    logoImage,
  ]);

export const setProjectHiringSelection = (payload) => async (dispatch) =>
  dispatch({
    type: SET_PROJECT_HIRING_SELECTION,
    payload,
  });
