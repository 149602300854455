export const routeNames = [
  "Default",
  "MyAccount",
  "Signin",
  "Debug",
  "Customer",
  "Instrument",
  "Musician",
  "Project",
  "StudioSession",
  "StudioSessionDetail",
  "SessionType",
  "ProjectDetail",
  "CustomerDetail",
  "MusicianDetail",
  "Statement",
  "InstrumentFamilies",
  "InstrumentSections",
  "Payrolls",
  "Payroll",
  "InstrumentConfigurator",
  "Studio",
  "Studios",
  "EventStatuses",
  "Actions",
  "S",
  "Emails",
  "Email",
  "Pricings",
  "CoreConcept",
  "Slides",
  "Journal",
  "PostBuilder",
  "Pipeline",
  "Quotes",
  "Templates",
  "Template",
];
