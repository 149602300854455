// material-ui
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import CopyLabel from "hooks/CopyLabel";
import FormattedPhone from "hooks/FormattedPhone";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";
class QuotesList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    quotes: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
    getQuotes: PropTypes.func,
    searchUsers: PropTypes.func,
  };

  onSelect(selectedQuote) {
    const { onSelect } = this.props;
    this.setState({ selectedQuote });
    if (onSelect) {
      onSelect(selectedQuote);
    }
  }

  render() {
    const { quotes, getQuotes, history, classes, searchUsers } = this.props;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return (
      <AdvancedSearch
        history={history}
        rowCount={quotes.length}
        location={window.location}
        refresh={getQuotes}
        keyword="musicians"
        metaDataEndpoint="/rhapsody/quotes/searchMetadata"
        metaDataOveride={{
          assignedToUUID: {
            type: "autoFill",
            label: "Assigned To",
            getData: (search) =>
              searchUsers({
                text: search,
              }),
            valueKey: "uuid",
            labelTransform: (t) => `${t.firstName} ${t.lastName} (${t.email})`,
          },
          status: {
            type: "autocomplete",
            data: [
              { name: "draft" },
              { name: "pending" },
              { name: "ready" },
              { name: "invoiced" },
              { name: "paid" },
              { name: "canceled" },
              { name: "rejected" },
            ],
            valueKey: "name",
            labelKey: "name",
          },
        }}
      >
        <Table
          data={quotes}
          rowStyle={(d) => {
            let background;
            if (d.status === "pending") background = "rgba(33,150,243,0.1)";
            if (d.status === "ready") background = "rgba(63,81,181,0.1)";
            return {
              background,
            };
          }}
          meta={[
            {
              path: "createdAt",
              title: "Date",
              numeric: false,
              transform: (t) => moment(t).format("MM/DD/YYYY"),
            },
            {
              path: "reference",
              title: "Reference",
              numeric: false,
              render: (t) => (
                <div
                  style={{
                    border: "solid 1px black",
                    display: "inline-block",
                    padding: "0px 4px",
                    borderRadius: 20,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <CopyLabel label={t} />
                </div>
              ),
            },
            {
              path: "firstName",
              title: "Contact",
              render: (t, d) => (
                <>
                  <Typography variant="body2" display="block">
                    {d.firstName} {d.lastName}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {d.email}, <FormattedPhone value={d.phone} />
                  </Typography>
                </>
              ),
            },
            {
              path: "Specs",
              title: "Musicians",
              render: (t, d) => (
                <>
                  <Typography variant="body2" display="block">
                    {d.workSessionCount}{" "}
                    {d.urgent ? (
                      <span className={classes.urgent}>URGENT</span>
                    ) : (
                      []
                    )}{" "}
                    Work Sessions
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {d.musicianCount} Musicians
                  </Typography>
                </>
              ),
            },
            {
              path: "assignee",
              title: "Assignee",
              numeric: false,
              render: (t) => (
                <>
                  {t ? (
                    <>
                      <i class="fad fa-user-tie p-right"></i>
                      {t}
                    </>
                  ) : (
                    []
                  )}
                </>
              ),
            },
            {
              path: "status",
              title: "Status",
              numeric: false,
            },
            {
              path: "total",
              title: "Total",
              numeric: false,
              transform: (t) => formatter.format(t),
              render: (t) => <b>{t}</b>,
            },
            {
              path: "projectID",
              title: "",
              width: 30,
              render: (t) => {
                if (t) {
                  return (
                    <div style={{ textAlign: "right" }}>
                      <Typography>
                        <i class="far fa-chevron-right"></i>
                      </Typography>
                    </div>
                  );
                }
              },
            },
          ]}
          title={"Quotes"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(QuotesList);
