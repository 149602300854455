import * as Api from "api";
import {
  CREATE_QUOTE,
  DELETE_QUOTE,
  GET_QUOTES,
  GET_QUOTE_BY_ID,
  UPDATE_QUOTE,
} from "constants/quotes";
import { networkAction } from "helpers/network/networkAction";

export const getQuotes = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_QUOTES, Api.getQuotes, [filters]);

export const getQuoteById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_QUOTE_BY_ID, Api.getQuoteById, [id]);

export const deleteQuote = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_QUOTE, Api.deleteQuote, [id]);

export const createQuote = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_QUOTE, Api.createQuote, [body]);

export const updateQuote = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_QUOTE, Api.updateQuote, [id, body]);
