import { Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import * as Actions from "actions";
// constants
import { META } from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  setSelectedColumn: (...args) => dispatch(Actions.setSelectedColumn(...args)),
});
class MusicianTableHeader extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    variant: PropTypes.string,
    setSelectedColumn: PropTypes.func,
  };

  render() {
    const { classes, variant, setSelectedColumn } = this.props;

    if (!META[variant]) return <div />;

    return (
      <div className={classes.container}>
        {/* MUSICIAN */}
        {META[variant].musician ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].musician }}
          >
            <Typography variant="caption" color="textSecondary">
              Musician
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* INSTRUMENTS */}
        {META[variant].instrument ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].instrument }}
          >
            <Typography variant="caption" color="textSecondary">
              Instrument
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* ADDRESS */}
        {META[variant].address ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].address }}
          >
            <Typography variant="caption" color="textSecondary">
              Home Address
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* SSN */}
        {META[variant].ssn ? (
          <div className={classes.rowItem} style={{ flex: META[variant].ssn }}>
            <Typography variant="caption" color="textSecondary">
              SSN/EIN
            </Typography>
          </div>
        ) : (
          []
        )}

        {/*  REHEARSAL COUNT   */}
        {META[variant].rehearsalCount ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("rehearsalCount")}
            style={{ flex: META[variant].rehearsalCount, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                # Of Rehearsals <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/*  PERFORMANCE COUNT   */}
        {META[variant].performanceCount ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("performanceCount")}
            style={{ flex: META[variant].performanceCount, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                # Of Performances <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* HOURS WORKED  */}
        {META[variant].hoursWorked ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].hoursWorked, cursor: "pointer" }}
            onClick={() => setSelectedColumn("hoursWorked")}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                Hrs Worked <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* HOURS WORKED  */}
        {META[variant].hoursWorked ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].hoursWorked, cursor: "pointer" }}
            onClick={() => setSelectedColumn("hoursGuaranteed")}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                Hrs Guaranteed <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* TUNES  */}
        {META[variant].tunes ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("tunes")}
            style={{ flex: META[variant].tunes, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                ID of Tunes <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* OVERSCALE  */}
        {META[variant].overscale ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].overscale }}
          >
            <Typography variant="caption" color="textSecondary">
              Overscale
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* BENEFITS ONLY  */}
        {META[variant].benefitsOnly ? (
          <div
            className={classes.rowItem}
            style={{ flex: META[variant].benefitsOnly }}
          >
            <Typography variant="caption" color="textSecondary">
              Benefits Only
            </Typography>
          </div>
        ) : (
          []
        )}

        {/* CARTAGE  */}
        {META[variant].cartage ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("cartage")}
            style={{ flex: META[variant].cartage, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                Cartage <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* DOUBLES  */}
        {META[variant].doubles ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("doubles")}
            style={{ flex: META[variant].doubles, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                Doubles <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* PREMIUM PERCENT  */}
        {META[variant].premiumPercent ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("premiumPercent")}
            style={{ flex: META[variant].premiumPercent, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                % Premium <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* SCALE WAGES  */}
        {META[variant].scaleWages ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("scaleWages")}
            style={{ flex: META[variant].scaleWages, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                Scale Wages <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* WORK DUES  */}
        {META[variant].workDues ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("workDues")}
            style={{ flex: META[variant].workDues, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                Work Dues <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* PENSION  */}
        {META[variant].pension ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("pension")}
            style={{ flex: META[variant].pension, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                Pension <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* WELFARE  */}
        {META[variant].welfare ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("welfare")}
            style={{ flex: META[variant].welfare, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                H&W <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* NON PENSIONNABLE  */}
        {META[variant].nonPensionable ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("nonPensionable")}
            style={{ flex: META[variant].nonPensionable, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                Non-Pensionable <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}

        {/* OTHER PENSIONNABLE   */}
        {META[variant].otherPensionable ? (
          <div
            className={classes.rowItem}
            onClick={() => setSelectedColumn("otherPensionable")}
            style={{ flex: META[variant].otherPensionable, cursor: "pointer" }}
          >
            <Tooltip title="Select All Cells">
              <Typography variant="caption" color="textSecondary">
                Other Pensionable <i class="fas fa-layer-group"></i>
              </Typography>
            </Tooltip>
          </div>
        ) : (
          []
        )}
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MusicianTableHeader));
