import { Checkbox, FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// material-ui
import { withStyles } from "@material-ui/styles";
import { REACT_APP_API } from "config";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class RosterConfig extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    studioSession: PropTypes.object,
    studioSessionMusicians: PropTypes.array,
    studioSessionEngagements: PropTypes.array,
    api: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { studioSession } = this.props;
    this.state = {
      open: false,
      value: "",
      customTitle: "",
      ...studioSession,
    };

    this.refreshNotes();
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  async refreshNotes() {
    const { api, studioSession, refresh } = this.props;
    const { getRosterNotes } = api;

    const r = await getRosterNotes({
      name: "workSessionID",
      comparison: "eq",
      value: studioSession.id,
    });

    if (r.success) {
      this.setState({
        rosterNotes: r.payload,
        loading: false,
      });
    }
    refresh();
  }

  async save(name, numeric) {
    const { api, refresh, studioSession } = this.props;

    if (studioSession[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await api.updateStudioSession(studioSession.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  saveRosterNote() {
    const { api, studioSession } = this.props;
    const { createRosterNote, updateRosterNote } = api;
    const { selectedMusicianID, value, engagementID, selectedRosterNote } =
      this.state;

    const body = {
      jobID: selectedMusicianID,
      workSessionID: studioSession.id,
      engagementID,
      value,
    };
    if (selectedRosterNote) {
      updateRosterNote(selectedRosterNote.id, body).then(
        this.refreshNotes.bind(this)
      );
    } else {
      createRosterNote(body).then(this.refreshNotes.bind(this));
    }
    this.setState({
      open: false,
      value: "",
      selectedMusicianID: "",
      selectedRosterNote: undefined,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "The note will be removed from the roster",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => this.setState({ selectedRosterNote: undefined }),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { api } = this.props;

    const { selectedRosterNote } = this.state;

    const { NotificationCenter } = this.context;
    const resp = await api.deleteRosterNote(selectedRosterNote.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Note has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      this.refreshNotes();
      this.setState({ selectedRosterNote: undefined });
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  getConfig() {
    const {
      showAlternates,
      showMessages,
      showRemoved,
      projectTitle,
      familyTitle,
      showProjectNotes,
      customTitle,
    } = this.state;
    const url = new URL("https://studio.rhapsody.la");
    if (showAlternates) {
      url.searchParams.append("showAlternates", "true");
    }
    if (showProjectNotes) {
      url.searchParams.append("showProjectNotes", "true");
    }

    if (projectTitle) {
      url.searchParams.append("showProjectTitle", "true");
    }
    if (customTitle) {
      url.searchParams.append("title", customTitle);
    }
    if (familyTitle) {
      url.searchParams.append("showFamilyTitle", "true");
    }
    if (showMessages) {
      url.searchParams.append("showMessages", "true");
    }
    if (showRemoved) {
      url.searchParams.append("showRemoved", "true");
    }
    return url.search;
  }

  newConfig() {
    const { onNewConfig } = this.props;
    onNewConfig(this.getConfig());
  }

  render() {
    const { studioSession, studioSessionMusicians, studioSessionEngagements } =
      this.props;
    const {
      contractor,
      conductor,
      loading,
      selectedMusicianID,
      rosterNotes,
      showAlternates,
      showRemoved,
      showMessages,
      projectTitle,
      familyTitle,
      showProjectNotes,
      value,
      customTitle,
    } = this.state;

    const engagements = [];
    let engagementID = "";

    for (const key in studioSessionEngagements) {
      if (studioSessionEngagements.hasOwnProperty(key)) {
        const engagement = studioSessionEngagements[key];
        if (engagements.name !== "Rehearsal") {
          engagements.push(engagement);
          engagementID = engagement.id;
          this.state.engagementID = engagementID; //eslint-disable-line
        }
      }
    }

    let list =
      studioSessionMusicians &&
      studioSessionMusicians.filter((ssm) => ssm.musician?.id);
    list =
      list &&
      list.sort((a, b) => {
        if (a.musician.lastName < b.musician.lastName) {
          return -1;
        }
        if (a.musician.lastName > b.musician.lastName) {
          return 1;
        }
        return 0;
      });

    const archived = studioSession.archived;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">
            Roster Configuration
          </Typography>
          <Chip
            size="small"
            onClick={() =>
              window.open(
                `${REACT_APP_API}/rhapsody/workSessions/${
                  studioSession.id
                }/roster${this.getConfig()}`,
                "_blank"
              )
            }
            label={
              <span>
                <i class="fad fa-file-pdf p-right"></i> Download PDF
              </span>
            }
          />
          <Chip
            size="small"
            onClick={() =>
              window.open(
                `${REACT_APP_API}/rhapsody/workSessions/${
                  studioSession.id
                }/rosterCSV${this.getConfig()}`,
                "_blank"
              )
            }
            style={{ marginLeft: 4 }}
            label={
              <span>
                <i class="fad fa-file-csv"></i> Download CSV
              </span>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={customTitle}
            disabled={loading || archived}
            label="Custom Title"
            size="small"
            onChange={(e) =>
              this.setState({
                customTitle: e.target.value,
                projectTitle: false,
              })
            }
            onBlur={this.newConfig.bind(this)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle" color="textPrimary">
            People
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={contractor}
            disabled={loading || archived}
            label="Contractor"
            size="small"
            onChange={this.handleChange("contractor")}
            onBlur={() => this.save("contractor")}
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("contractor");
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            value={conductor}
            disabled={loading || archived}
            label="Conductor"
            onChange={this.handleChange("conductor")}
            onBlur={() => this.save("conductor")}
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("conductor");
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showAlternates}
                onChange={(e) =>
                  this.setState(
                    { showAlternates: e.target.checked },
                    this.newConfig
                  )
                }
              />
            }
            label="Show Alternates"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={customTitle}
            control={
              <Checkbox
                size="small"
                inputProps={{ "aria-label": "controlled" }}
                checked={projectTitle}
                onChange={(e) =>
                  this.setState(
                    { projectTitle: e.target.checked },
                    this.newConfig
                  )
                }
              />
            }
            label="Show Project title"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={familyTitle}
                onChange={(e) =>
                  this.setState(
                    { familyTitle: e.target.checked },
                    this.newConfig
                  )
                }
              />
            }
            label="Show Family Titles"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showMessages}
                onChange={(e) =>
                  this.setState(
                    { showMessages: e.target.checked },
                    this.newConfig
                  )
                }
              />
            }
            label="Show Messages"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showRemoved}
                onChange={(e) =>
                  this.setState(
                    { showRemoved: e.target.checked },
                    this.newConfig
                  )
                }
              />
            }
            label="Show Removed Musicians"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showProjectNotes}
                onChange={(e) =>
                  this.setState(
                    { showProjectNotes: e.target.checked },
                    this.newConfig
                  )
                }
              />
            }
            label="Show Project General Notes"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle" color="textPrimary">
            Roster Notes
          </Typography>
        </Grid>
        {rosterNotes &&
          rosterNotes.map((rn) => {
            let sectionMusician = studioSessionMusicians.find(
              (sm) => sm.id === rn.jobID
            );
            if (
              sectionMusician &&
              sectionMusician.musician &&
              sectionMusician.musician.id
            ) {
              let musician = sectionMusician.musician;
              return (
                <Grid item xs={12}>
                  <div
                    style={{
                      background: "rgba(255,193,7, 0.1)",
                      border: "solid 1px rgba(255,193,7, 0.3)",
                      padding: 4,
                      display: "flex",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <Typography variant="body2">
                        {`${musician.firstName} ${musician.lastName}`}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {rn.value}
                      </Typography>
                    </div>
                    <div style={{ width: 50, display: "flex" }}>
                      <IconButton
                        style={{ padding: 4 }}
                        disabled={archived}
                        onClick={() =>
                          this.setState({
                            selectedRosterNote: rn,
                            selectedMusicianID: sectionMusician.id,
                            value: rn.value,
                            open: true,
                          })
                        }
                      >
                        <Edit style={{ fontSize: 18 }} />
                      </IconButton>
                      <IconButton
                        style={{ padding: 4 }}
                        disabled={archived}
                        onClick={() =>
                          this.setState(
                            {
                              selectedRosterNote: rn,
                            },
                            this.delete.bind(this)
                          )
                        }
                      >
                        <Close style={{ fontSize: 18 }} />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              );
            }
            return [];
          })}
        <Grid item xs={12}>
          <Chip
            size="small"
            disabled={archived}
            onClick={this.handleClickOpen.bind(this)}
            label={
              <span>
                <i class="fad fa-sticky-note p-right"></i> Add Roster Note
              </span>
            }
          />
        </Grid>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth="sm"
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">New Roster Note</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="age-simple">Engagement</InputLabel>
                  <Select
                    value={engagementID}
                    disabled
                    onChange={this.handleChange("engagementID")}
                    inputProps={{
                      name: "age",
                      id: "age-simple",
                    }}
                  >
                    {engagements &&
                      engagements.map((e) => (
                        <MenuItem value={e.id}>
                          {e.noTimeRange
                            ? `${e.name} - ${moment(e.dateFromUTC).format(
                                "ll"
                              )}`
                            : `${e.name} - ${moment(e.dateFromUTC).format(
                                "lll"
                              )} to ${moment(e.dateToUTC).format("hh:mm A")}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="age-simple">Which Musician?</InputLabel>
                  <Select
                    value={selectedMusicianID || ""}
                    onChange={this.handleChange("selectedMusicianID")}
                    inputProps={{
                      name: "age",
                      id: "age-simple",
                    }}
                  >
                    {list &&
                      list.map((ss) => {
                        if (ss.musician && ss.musician.id) {
                          return (
                            <MenuItem value={ss.id}>
                              {ss.musician &&
                                `${ss.musician.firstName} ${
                                  ss.musician.lastName
                                } (${ss.instrument && ss.instrument.name})`}
                            </MenuItem>
                          );
                        }
                        return [];
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={value}
                  onChange={this.handleChange("value")}
                  label="Text"
                  style={{ background: "rgba(255,193,7, 0.1)" }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={this.saveRosterNote.bind(this)}
              color="secondary"
              autoFocus
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles)(RosterConfig);
