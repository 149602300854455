import {
  Divider,
  Link,
  List,
  ListItem,
  Popover,
  Tooltip,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppsIcon from "@material-ui/icons/Apps";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import Public from "@material-ui/icons/Public";
import Eye from "@material-ui/icons/RemoveRedEye";
// material-ui
import { withStyles } from "@material-ui/styles";
// reactor
import Apps from "components/Apps";
import Subscription from "components/Subscription";
import { REACT_APP_FRONT_BASE, REACT_APP_LOGO } from "config";
import {
  CUSTOMER,
  MUSICIAN,
  MY_ACCOUNT,
  PAYROLLS,
  PROJECT,
  STUDIOS,
  STUDIO_SESSION,
  TEMPLATES,
} from "constants/routes";
import { updateURLParameter } from "helpers";
import ListButton from "hooks/ListButton";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { withCookies } from "react-cookie";
// styles
import styles from "./styles";

class Bar extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    cookies: PropTypes.object,
    onMenuOpen: PropTypes.func,
    user: PropTypes.object,
    userApps: PropTypes.array,
    history: PropTypes.object,
    app: PropTypes.object,
    setDarkMode: PropTypes.func,
    stopImpersonate: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
    getCurrentUser: PropTypes.func,
    signout: PropTypes.func,
    subscriptions: PropTypes.array,
    bazaarCustomer: PropTypes.any,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    TourCenter: PropTypes.object,
  };

  state = {
    anchorEl: null,
    appsOpen: false,
  };

  getMenu() {
    const { signout, history, user, stopImpersonate, classes, userApps } =
      this.props;

    const { anchorEl } = this.state;
    const accountOpen = Boolean(anchorEl);

    return (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableAutoFocusItem
        open={accountOpen}
        onClose={this.handleAppBarClose}
      >
        <Hidden smUp>
          {user && user.impersonate ? (
            <MenuItem
              onClick={async () => {
                await stopImpersonate();
                window.location.reload();
              }}
              style={{
                background: "#f44336",
                color: "white",
              }}
            >
              <ListItemIcon>
                <Eye style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.white,
                }}
                primary={`Stop Impersonating ${user.firstName} ${user.lastName}`}
              />
            </MenuItem>
          ) : (
            <div />
          )}
        </Hidden>
        {userApps.length > 1 ? (
          <Hidden smUp>
            <MenuItem onClick={() => this.setState({ appsOpen: true })}>
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="Apps" />
            </MenuItem>
          </Hidden>
        ) : (
          []
        )}
        <Tooltip title={`${user.firstName} ${user.lastName}`}>
          <MenuItem onClick={() => history.push(MY_ACCOUNT)}>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="My Account" />
          </MenuItem>
        </Tooltip>
        <MenuItem
          onClick={async () => {
            await signout();
            window.location.replace(`${REACT_APP_FRONT_BASE}/signin`);
          }}
        >
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </MenuItem>
      </Menu>
    );
  }

  handleAppBarMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAppBarClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      onMenuOpen,
      user,
      userApps,
      history,
      app,
      setDarkMode,
      cookies,
      stopImpersonate,
      bazaarCustomer,
      setOrganizationByDefault,
      signout,
    } = this.props;

    const { TourCenter } = this.context;

    const { anchorEl, anchorOrganization } = this.state;
    const accountOpen = Boolean(anchorEl);
    const organizationOpen = Boolean(anchorOrganization);

    let currentOrganization =
      user.organizations?.find((o) => o.id === user.organizationID)?.name ??
      "None";

    return (
      <AppBar
        position="static"
        className={classes.appBar}
        style={{ zIndex: 1099, boxShadow: "none" }}
      >
        <Grid
          container
          justify="space-between"
          className={classes.appGrid}
          alignItems="center"
        >
          <Grid item>
            {/* Top Left Menu : Logo and Menu Icon */}
            <Grid container alignItems="center">
              <Grid item>
                <Hidden mdUp implementation="css">
                  <IconButton
                    color="inherit"
                    aria-label="Menu"
                    onClick={onMenuOpen}
                  >
                    <MenuIcon fontSize="small" />
                  </IconButton>
                </Hidden>
              </Grid>
              <Hidden smDown implementation="css">
                <Grid item>
                  <img
                    alt="logo"
                    src={REACT_APP_LOGO}
                    height="40"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  />
                </Grid>
              </Hidden>
              <Grid item>
                <div>
                  <Subscription creditLeft={bazaarCustomer.credit} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {/* Desktop Top Right Menu */}
            <Grid container alignItems="center">
              <Grid item>
                <Chip
                  data-tut="reactour__create"
                  label={
                    <span>
                      <i class="far fa-plus-circle"></i> Create
                    </span>
                  }
                  style={{
                    background: "#FFC107",
                    color: "#001E3C",
                    margin: "0px 4px",
                  }}
                  size="small"
                  deleteIcon={<ArrowDropDown style={{ color: "#001E3C" }} />}
                  onDelete={(e) =>
                    this.setState({ anchorNew: e.currentTarget })
                  }
                  onClick={(e) => {
                    TourCenter.hide();
                    this.setState({ anchorNew: e.currentTarget });
                  }}
                />
                <Popover
                  anchorEl={this.state.anchorNew}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(this.state.anchorNew)}
                  onClose={() => this.setState({ anchorNew: null })}
                >
                  <Grid container style={{ padding: 13, width: 340 }}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Create</Typography>
                    </Grid>
                    {/* <Grid item xs={12} style={{ marginTop: 12 }}>
                      <ListButton
                        title="Payroll Form"
                        icon={
                          <i
                            style={{ color: "#1D1F23" }}
                            class="fad fa-file-invoice"
                          />
                        }
                        subtitle="Generate a standalone payroll form."
                        onClick={() => {
                          this.setState({ anchorNew: null });
                          if (window.location.pathname === `/project`) {
                            history.push({
                              search: updateURLParameter("standalone", "true"),
                            });
                            history.push({
                              search: updateURLParameter(
                                "workSessionScheduler",
                                "true"
                              ),
                            });
                          } else {
                            history.push(
                              `${STUDIO_SESSION}?newWorkSession&standalone`
                            );
                          }
                        }}
                      />
                      <Divider />
                    </Grid> */}
                    <Grid item xs={12}>
                      <ListButton
                        title="Project"
                        context={window.location.pathname === `/projects`}
                        icon={
                          <i
                            style={{ color: "#1D1F23" }}
                            class="fad fa-folder-open"
                          />
                        }
                        subtitle="Schedule your work sessions, choose your musicians and send the call"
                        onClick={() => {
                          this.setState({ anchorNew: null });
                          history.push(`${PROJECT}?newProject`);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 12 }}>
                      <ListButton
                        title="Work Sessions"
                        context={
                          window.location.pathname === `/project` ||
                          window.location.pathname === `/workSessions`
                        }
                        icon={
                          <i
                            style={{ color: "#1D1F23" }}
                            class="fad fa-calendar-day"
                          />
                        }
                        subtitle="Insert new work sessions in a project."
                        onClick={() => {
                          this.setState({ anchorNew: null });
                          if (window.location.pathname === `/project`) {
                            history.push({
                              search: updateURLParameter(
                                "workSessionScheduler",
                                "true"
                              ),
                            });
                          } else {
                            history.push(`${STUDIO_SESSION}?newWorkSession`);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ListButton
                        title="Customer"
                        context={window.location.pathname === `/customers`}
                        icon={
                          <i
                            style={{ color: "#1D1F23" }}
                            class="fad fa-building"
                          />
                        }
                        subtitle="Input contact info to be ready to work on a new project."
                        onClick={() => {
                          this.setState({ anchorNew: null });
                          history.push(`${CUSTOMER}?newCustomer`);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ListButton
                        context={window.location.pathname === `/studios`}
                        title="Studio / Venue"
                        icon={
                          <i
                            style={{ color: "#1D1F23" }}
                            class="fad fa-location-dot"
                          />
                        }
                        subtitle="Setup a new place to work with your musicians."
                        onClick={() => {
                          this.setState({ anchorNew: null });
                          history.push(`${STUDIOS}?newStudio`);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ListButton
                        context={window.location.pathname === `/payrolls`}
                        title="Payroll"
                        icon={
                          <i
                            style={{ color: "#1D1F23" }}
                            class="fad fa-university"
                          />
                        }
                        subtitle="Link your projects with a new payroll company."
                        onClick={() => {
                          this.setState({ anchorNew: null });
                          history.push(`${PAYROLLS}?newPayroll`);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ListButton
                        context={window.location.pathname === `/musicians`}
                        title="Musician"
                        icon={
                          <i
                            style={{ color: "#1D1F23" }}
                            class="fad fa-user-music"
                          />
                        }
                        subtitle="Start working with new musicians. Invite them on your work sessions."
                        onClick={() => {
                          this.setState({ anchorNew: null });
                          history.push(`${MUSICIAN}?newMusician`);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ListButton
                        title="Template"
                        context={window.location.pathname === `/templates`}
                        icon={
                          <i
                            style={{ color: "#1D1F23" }}
                            class="fa-duotone fa-square-dashed"
                          />
                        }
                        subtitle="A template is a call order you can use to autofill new projects"
                        onClick={() => {
                          this.setState({ anchorNew: null });
                          history.push(`${TEMPLATES}?newProject`);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Popover>
              </Grid>
              {userApps.length > 1 ? (
                <Grid item>
                  <Apps
                    userApps={userApps}
                    history={history}
                    app={app}
                    baseHostname={REACT_APP_FRONT_BASE}
                  />
                </Grid>
              ) : (
                []
              )}
              {/* <Grid item>
                {user && user.impersonate ? (
                  <Chip
                    label={`Impersonating ${user.firstName} ${user.lastName}`}
                    avatar={
                      <Avatar
                        style={{
                          background: "#d32f2f",
                          color: "white",
                        }}
                      >
                        <Eye />
                      </Avatar>
                    }
                    style={{
                      background: "#f44336",
                      color: "white",
                    }}
                    onDelete={async () => {
                      await stopImpersonate();
                      window.location.reload();
                    }}
                  />
                ) : (
                  []
                )}
              </Grid> */}
              <Grid item>
                <Hidden xsDown>
                  <Chip
                    size="small"
                    style={{
                      margin: "0px 8px 0px 4px",
                      color: "white",
                      background:
                        user && user.impersonate
                          ? "red"
                          : "rgba(255,255,255,0.1)",
                    }}
                    onDelete={
                      user && user.impersonate
                        ? async () => {
                            await stopImpersonate();
                            window.location.reload();
                          }
                        : undefined
                    }
                    onClick={(e) =>
                      this.setState({ anchorMe: e.currentTarget })
                    }
                    label={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: 20,
                        }}
                      >
                        {user && user.impersonate ? (
                          <i
                            class="fas fa-eye p-right"
                            style={{
                              fontSize: 20,
                              marginTop: 2.8,
                              marginLeft: -4,
                            }}
                          ></i>
                        ) : (
                          <i
                            class="fad fa-user-circle p-right"
                            style={{
                              fontSize: 20,
                              marginTop: 2.8,
                              marginLeft: -4,
                            }}
                          ></i>
                        )}

                        <div style={{ lineHeight: 0.8 }}>
                          <Typography
                            display="block"
                            variant="caption"
                            style={{ lineHeight: 0.6, fontSize: 11 }}
                          >
                            <b>{`${user.firstName} ${user.lastName}`}</b>
                          </Typography>
                          {user.organizations?.length > 0 ? (
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 9,
                                lineHeight: 0.6,
                                padding: 0,
                              }}
                            >{`${currentOrganization}`}</Typography>
                          ) : (
                            []
                          )}
                        </div>
                        {user && user.impersonate ? (
                          []
                        ) : (
                          <i class="fas fa-caret-down p-left"></i>
                        )}
                      </div>
                    }
                  />
                </Hidden>
                <Hidden smUp>
                  <Tooltip
                    title={`${user.firstName} ${user.lastName} (${currentOrganization})`}
                  >
                    <IconButton
                      aria-owns={accountOpen ? "menu-appbar" : null}
                      aria-haspopup="true"
                      style={{
                        color: user.impersonate ? "red" : undefined,
                      }}
                      onClick={(e) =>
                        this.setState({ anchorMe: e.currentTarget })
                      }
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </Tooltip>
                </Hidden>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(this.state.anchorMe)}
                  anchorEl={this.state.anchorMe}
                  onClose={() => this.setState({ anchorMe: null })}
                >
                  <div style={{ width: 300, padding: 15, textAlign: "center" }}>
                    <Grid container justify="center" spacing={1}>
                      <Grid item xs={12}>
                        <Avatar
                          style={{ width: 75, height: 75, margin: "auto" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {user.firstName} {user.lastName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {user.email}
                        </Typography>
                        {user && user.impersonate ? (
                          <Chip
                            label={
                              <span>
                                <i class="fas fa-eye-slash p-right"></i>Stop
                                Impersonate
                              </span>
                            }
                            size="small"
                            onClick={async () => {
                              await stopImpersonate();
                              window.location.reload();
                            }}
                            style={{ background: "red", color: "white" }}
                          />
                        ) : (
                          []
                        )}
                        {user &&
                        user.organizations &&
                        user.organizations.length > 1 ? (
                          <>
                            <Chip
                              label={
                                user.organizations?.find(
                                  (o) => o.id === user.organizationID
                                )?.name ?? "None"
                              }
                              style={{
                                background: "rgba(155,155,155,0.3)",
                              }}
                              avatar={
                                <Avatar
                                  style={{
                                    background: "none",
                                  }}
                                >
                                  <Public />
                                </Avatar>
                              }
                              deleteIcon={<ArrowDropDown />}
                              onDelete={(e) =>
                                this.setState({
                                  anchorOrganization: e.currentTarget,
                                })
                              }
                              onClick={(e) =>
                                this.setState({
                                  anchorOrganization: e.currentTarget,
                                })
                              }
                            />
                            <Menu
                              id="simple-menu"
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              anchorEl={anchorOrganization}
                              open={organizationOpen}
                              style={{ maxHeight: 300 }}
                              onClose={() =>
                                this.setState({ anchorOrganization: null })
                              }
                            >
                              {user &&
                                user.organizations &&
                                user.organizations.map((o) => (
                                  <MenuItem
                                    dense
                                    onClick={async () => {
                                      await setOrganizationByDefault(o.id);
                                      window.location.reload();
                                    }}
                                    selected={o.id === user.organizationID}
                                  >
                                    {o.name}
                                  </MenuItem>
                                ))}
                            </Menu>
                          </>
                        ) : (
                          []
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                        <List dense>
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              history.push(`${MY_ACCOUNT}?index=0`);
                              this.setState({ anchorMe: null });
                            }}
                          >
                            <ListItemIcon>
                              <i style={{ fontSize: 22 }} class="fad fa-key" />
                            </ListItemIcon>
                            <ListItemText primary={"Credentials"} />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              history.push(`${MY_ACCOUNT}?index=1`);
                              this.setState({ anchorMe: null });
                            }}
                          >
                            <ListItemIcon>
                              <i
                                style={{ fontSize: 22 }}
                                class="fad fa-id-badge"
                              />
                            </ListItemIcon>
                            <ListItemText primary={"Contractor Settings"} />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              history.push(`${MY_ACCOUNT}?index=2`);
                              this.setState({ anchorMe: null });
                            }}
                          >
                            <ListItemIcon>
                              <i
                                style={{ fontSize: 22 }}
                                class="fad fa-analytics"
                              />
                            </ListItemIcon>
                            <ListItemText primary={"Statements"} />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              history.push(`${MY_ACCOUNT}?index=3`);
                              this.setState({ anchorMe: null });
                            }}
                          >
                            <ListItemIcon>
                              <i
                                style={{ fontSize: 22 }}
                                class="fad fa-file-invoice-dollar"
                              />
                            </ListItemIcon>
                            <ListItemText primary={"Invoices"} />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              history.push(`${MY_ACCOUNT}?index=4`);
                              this.setState({ anchorMe: null });
                            }}
                          >
                            <ListItemIcon>
                              <i
                                style={{ fontSize: 22 }}
                                class="fad fa-credit-card-front"
                              />
                            </ListItemIcon>
                            <ListItemText primary={"Payment Options"} />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              history.push(`${MY_ACCOUNT}?index=5`);
                              this.setState({ anchorMe: null });
                            }}
                          >
                            <ListItemIcon>
                              <i
                                style={{ fontSize: 22 }}
                                class="fad fa-file-import"
                              />
                            </ListItemIcon>
                            <ListItemText primary={"Imports"} />
                          </ListItem>
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              history.push(`${MY_ACCOUNT}?index=6`);
                              this.setState({ anchorMe: null });
                            }}
                          >
                            <ListItemIcon>
                              <i
                                style={{ fontSize: 22 }}
                                class="fad fa-users"
                              />
                            </ListItemIcon>
                            <ListItemText primary={"Organization Users"} />
                          </ListItem>
                          <Divider />
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              setDarkMode(!app.darkMode);
                              cookies.set("darkMode", !app.darkMode, {
                                path: "/",
                              });
                            }}
                          >
                            <ListItemIcon>
                              {app.darkMode ? (
                                <i
                                  style={{ fontSize: 22 }}
                                  class="fad fa-lightbulb"
                                />
                              ) : (
                                <i
                                  style={{ fontSize: 22 }}
                                  class="fad fa-lightbulb-slash"
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                app.darkMode ? "Light Mode" : "Dark Mode"
                              }
                            />
                          </ListItem>
                        </List>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          style={{
                            textTransform: "none",
                          }}
                          onClick={async () => {
                            await signout();
                            window.location.replace(
                              `${REACT_APP_FRONT_BASE}/signin`
                            );
                          }}
                        >
                          <PowerSettingsNew
                            style={{ marginRight: 8, fontSize: 18 }}
                          />{" "}
                          Sign Out
                        </Button>
                        <br />
                        <Link
                          component="button"
                          variant="caption"
                          color="textSecondary"
                          href="www.rhapsody.la/policies"
                        >
                          Terms of Service
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

export default withCookies(withStyles(styles, { withTheme: true })(Bar));
