import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// material-ui
import { withStyles } from "@material-ui/styles";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class TableArrow extends PureComponent {
  render() {
    return (
      <IconButton aria-label="Edit">
        <KeyboardArrowRight fontSize="small" />
      </IconButton>
    );
  }
}

export default withStyles(styles)(TableArrow);
