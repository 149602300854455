export default (theme) => ({
  container: {
    padding: 5,
    border: "solid 1px rgba(155,155,155,0.3)",
    width: 250,
  },
  addChip: {
    height: 24,
    color: "rgba(155,155,155, 1)",
    backgroundColor: theme.palette.background.paper,
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
    border: "dashed 1px rgba(155,155,155,0.3)",
  },
  chip: {
    height: 24,
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.text.default,
    border: `solid 1px ${theme.palette.text.default}`,
    backgroundColor: theme.palette.background.paper,
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  widget: {
    background: theme.palette.background.paper,
    borderRadius: 4,
    border: "solid 1px rgba(155, 155, 155, 0.3)",
    padding: 10,
    height: "calc(100% - 20px)",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
  },
  emptyChip: {
    height: 24,
    fontWeight: 600,
    color: theme.palette.text.default,
    border: `solid 1px ${theme.palette.text.default}`,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.background.default
        : "#f5f5f5",
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  avatar: {
    backgroundColor: theme.palette.background.paper,
  },
  menuItemDisabled: {
    opacity: 1,
  },
  profileButton: {
    padding: 4,
    margin: 4,
    background: "#2196f3",
    color: "#ffffff",
    "&:hover": {
      background: "#1976d2",
    },
  },
  secondaryProfileButton: {
    padding: 4,
    margin: 4,
    background: "#e0e0e0",
    color: theme.palette.type === "dark" ? "#212121" : "#9e9e9e",
    "&:hover": {
      background: theme.palette.type === "dark" ? "#bdbdbd" : "#111111",
    },
  },
  profileButtonIcon: {
    fontSize: 18,
  },
});
