import { Icon, IconButton, Tooltip } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import BulkArchive from "components/BulkArchive";
import Page from "components/Page";
import WorkSessionPresentationSelect from "components/WorkSessionPresentationSelect";
// components
import WorkSessions from "components/WorkSessions";
import { STUDIO_SESSION_DETAIL } from "constants/routes";
import { buildForURL, removeURLParameter, updateURLParameter } from "helpers";
// hooks
import ProjectSelect from "hooks/ProjectSelect";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperStudioSessionPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    projects: PropTypes.array,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    refresh: PropTypes.func,
    loading: PropTypes.bool,
    studioSessions: PropTypes.array,
    updateStudioSession: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      formStudioSessionOpen: urlParams.newWorkSession,
      searchText: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.urlParams.newWorkSession) {
      this.setState({ formStudioSessionOpen: true });
    }
  }

  getStepContent() {
    const { studioSessions, history, refresh } = this.props;

    return (
      <WorkSessions
        refresh={refresh}
        workSessions={studioSessions}
        history={history}
        onSelect={(session) =>
          history.push({
            pathname: STUDIO_SESSION_DETAIL,
            search: buildForURL({
              id: session.id,
              back: `${window.location.pathname}${window.location.search}`,
            }),
          })
        }
      />
    );
  }

  openStudioSession() {
    this.setState({ formStudioSessionOpen: true });
  }

  render() {
    const {
      classes,
      history,
      loading,
      projects,
      studioSessions,
      updateStudioSession,
      refresh,
    } = this.props;

    const { index, formStudioSessionOpen } = this.state;

    return (
      <Page
        helmet="All Work Sessions"
        loading={loading}
        loadingMessage={"Loading Work Sessions"}
        tabs={
          <div
            className={classes.tabs}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({
                  index: v,
                });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="Active"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Archived"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>

            <div style={{ color: "black", marginRight: 12 }}>
              {studioSessions.length ? <WorkSessionPresentationSelect /> : []}
              {studioSessions.length && index !== 1 ? (
                <Tooltip title="Archive work sessions">
                  <IconButton
                    onClick={() => this.setState({ bulkArchive: true })}
                    size="small"
                    style={{ background: "#E4E6EB", padding: 8 }}
                  >
                    <Icon
                      fontSize="inherit"
                      style={{ color: "#1D1F23" }}
                      class="fad fa-archive"
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                []
              )}
            </div>
          </div>
        }
      >
        {this.getStepContent()}
        <ProjectSelect
          projects={projects}
          open={formStudioSessionOpen}
          close={() => {
            this.setState({ formStudioSessionOpen: false });
            history.push({ search: removeURLParameter("newWorkSession") });
          }}
          history={history}
        />
        <BulkArchive
          open={this.state.bulkArchive}
          close={() => this.setState({ bulkArchive: false })}
          updateStudioSession={updateStudioSession}
          refresh={refresh}
          studioSessions={
            studioSessions && studioSessions.filter((s) => !s.archived)
          }
        />
      </Page>
    );
  }
}
export default withStyles(styles, { withTheme: true })(
  WrapperStudioSessionPage
);
