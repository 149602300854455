// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperStudioPage from "../component/WrapperStudioPage";

const mapStateToProps = (state) => ({
  user: state.user,
  customers: state.customers,
  currentCompany: state.customers,
});

const mapDispatchToProps = (dispatch) => ({
  getStudioById: (...args) => dispatch(Actions.getStudioById(...args)),
  updateStudio: (...args) => dispatch(Actions.updateStudio(...args)),
  getStudioStudioSessions: (...args) =>
    dispatch(Actions.getStudioStudioSessions(...args)),
  getStudioProjects: (...args) => dispatch(Actions.getStudioProjects(...args)),
  getCustomers: (...args) => dispatch(Actions.getCustomers(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),
});
class StudioPage extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
    getStudioById: PropTypes.func,
    updateStudio: PropTypes.func,
    getStudioStudioSessions: PropTypes.func,
    getStudioProjects: PropTypes.func,
    getCustomers: PropTypes.func,
    getCurrentCompany: PropTypes.func,
    currentCompany: PropTypes.func,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, getCurrentCompany } = this.props;
    const urlParams = getJsonFromUrl(location);
    getCurrentCompany();
    this.state = {
      urlParams,
      studioID: urlParams.id,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { studioID } = this.state;
    const {
      getStudioById,
      getStudioStudioSessions,
      getStudioProjects,
      getCustomers,
    } = this.props;

    const resps = await Promise.all([
      getStudioById(studioID),
      getStudioStudioSessions(studioID),
      getStudioProjects(studioID),
      getCustomers(),
    ]);
    this.setState({
      studio: resps[0].payload,
      projects: resps[2].payload,
      studioSessions: resps[1].payload,
      loading: false,
      refreshKey: new Date().getTime(),
    });
  }

  render() {
    const { location, history, updateStudio, customers, user } = this.props;

    const { urlParams, loading, studio, refreshKey, projects, studioSessions } =
      this.state;

    return (
      <WrapperStudioPage
        history={history}
        location={location}
        urlParams={urlParams}
        studio={studio}
        organizations={user.organizations}
        loading={loading}
        refreshKey={refreshKey}
        studioSessions={studioSessions}
        customers={customers}
        projects={projects}
        refresh={this.refresh.bind(this)}
        updateStudio={updateStudio}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudioPage);
