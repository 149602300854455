import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class Identification extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    identification: PropTypes.object,
    updateIdentification: PropTypes.func,
    deleteIdentification: PropTypes.func,
    refresh: PropTypes.func,
    disabled: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { identification } = this.props;
    this.state = {
      ...identification,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name) {
    const { updateIdentification, refresh, identification } = this.props;
    if (identification[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateIdentification(identification.id, {
      [name]: this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const {
      classes,
      deleteIdentification,
      refresh,
      identification,
      disabled,
    } = this.props;
    const { alphaKey, originalID, minutes, newID, loading } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("alphaKey");
            }}
            disabled={loading || disabled}
            onBlur={() => this.save("alphaKey")}
            className={disabled ? undefined : classes.inputBase}
            fullWidth
            defaultValue={alphaKey}
            onChange={this.handleChange("alphaKey")}
          />
        </Grid>
        <Grid item xs={3}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("originalID");
            }}
            disabled={loading || disabled}
            fullWidth
            onBlur={() => this.save("originalID")}
            className={disabled ? undefined : classes.inputBase}
            defaultValue={originalID}
            onChange={this.handleChange("originalID")}
          />
        </Grid>
        <Grid item xs={3}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("minutes");
            }}
            disabled={loading || disabled}
            fullWidth
            onBlur={() => this.save("minutes")}
            className={disabled ? undefined : classes.inputBase}
            defaultValue={minutes}
            onChange={this.handleChange("minutes")}
          />
        </Grid>
        <Grid item xs={3}>
          <InputBase
            onKeyPress={(e) => {
              if (e.key === "Enter") this.save("newID");
            }}
            disabled={loading || disabled}
            fullWidth
            onBlur={() => this.save("newID")}
            className={disabled ? undefined : classes.inputBase}
            defaultValue={newID}
            onChange={this.handleChange("newID")}
          />
        </Grid>
        <Grid item xs={1}>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <Tooltip title="Delete Identification">
              <IconButton
                disabled={disabled}
                aria-label="Delete"
                className={classes.margin}
                onClick={async () => {
                  await deleteIdentification(identification.id);
                  refresh();
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Identification);
