export default (theme) => ({
  container: {
    padding: 5,
    flex: 1,
    background: "red",
  },
  roleChip: {
    background: "rgba(155,155,155,0.1)",
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 6,
    display: "inline-flex",
    padding: "0px 2px",
    alignItems: "center",
    cursor: "pointer",
    width: "auto",
    textAlign: "center",
    marginLeft: 4,
  },
  musicianCard: {
    padding: 2,
    display: "flex",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 8,
    top: 0,
    position: "sticky",
    left: 0,
    background: theme.palette.background.default,
    zIndex: 999,
  },
  toolbarGroup3: {
    display: "flex",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  link: {
    padding: "0px 4px",
    textDecoration: "underline",
    cursor: "pointer",
  },
});
