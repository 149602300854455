import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Engagement from "components/Engagement";
import FormEngagement from "components/FormEngagement";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class Engagments extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    studioSession: PropTypes.object,
    studioSessionEngagements: PropTypes.array,
    studios: PropTypes.array,
    refresh: PropTypes.func,
    api: PropTypes.array,
    conciergeReadOnly: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { studioSession } = this.props;
    this.state = {
      ...studioSession,
      loading: false,
      open: false,
      newEngagementLoading: false,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  getEngagementList() {
    const {
      api,
      studioSession,
      studioSessionEngagements,
      studios,
      refresh,
      conciergeReadOnly,
    } = this.props;

    const sortedEngagements = studioSessionEngagements.sort((a, b) => {
      if (a.dateFromUTC < b.dateFromUTC) return -1;
      if (a.dateFromUTC > b.dateFromUTC) return 1;
      return 0;
    });

    const JSX = [];
    const now = moment();
    let label;

    for (const key in sortedEngagements) {
      if (sortedEngagements.hasOwnProperty(key)) {
        const engagement = sortedEngagements[key];
        const dateFromUTC = moment.tz(
          engagement.dateFromUTC,
          engagement.tzName
        );
        const dateToUTC = moment.tz(engagement.dateFromUTC, engagement.tzName);
        const past = now.diff(dateToUTC) > 0;

        if (past && label !== "Past") {
          JSX.push(
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ fontWeight: 700, fontSize: 28, paddingLeft: 8 }}
            >
              Past
            </Typography>
          );
          label = "Past";
        } else if (now.isSame(dateFromUTC, "day") && label !== "Today") {
          JSX.push(
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ fontWeight: 700, fontSize: 28, paddingLeft: 8 }}
            >
              Today
            </Typography>
          );
          label = "Today";
        } else if (
          moment().utc().add(1, "week").isSame(dateFromUTC, "week") &&
          label !== "Next Week"
        ) {
          JSX.push(
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ fontWeight: 700, fontSize: 28, paddingLeft: 8 }}
            >
              Next Week
            </Typography>
          );
          label = "Next Week";
        } else if (now.isSame(dateFromUTC, "week") && label !== "This Week") {
          JSX.push(
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ fontWeight: 700, fontSize: 28, paddingLeft: 8 }}
            >
              This Week
            </Typography>
          );
          label = "This Week";
        } else if (
          moment().utc().add(2, "week").diff(dateFromUTC) < 0 &&
          label !== "Forthcoming"
        ) {
          JSX.push(
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ fontWeight: 700, fontSize: 28, paddingLeft: 8 }}
            >
              Forthcoming
            </Typography>
          );
          label = "Forthcoming";
        }

        JSX.push(
          <Grid
            item
            xs={12}
            key={engagement.id}
            style={{ opacity: past ? 0.5 : 1 }}
          >
            <Engagement
              key={`studioSessionTunes_${engagement.id}`}
              engagement={engagement}
              studios={studios}
              studioSession={studioSession}
              updateEngagement={api.updateEngagement}
              deleteEngagement={api.deleteEngagement}
              refresh={refresh}
              disabled={
                studioSession.archived ||
                studioSession.recorded ||
                conciergeReadOnly
              }
              onSelect={(e) =>
                this.setState({ selectedEngagement: e, open: true })
              }
            />
          </Grid>
        );
      }
    }

    return JSX;
  }

  async save(name, numeric) {
    const { api, refresh, studioSession } = this.props;

    if (studioSession[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await api.updateStudioSession(studioSession.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const { api, studioSession, studios, refresh, conciergeReadOnly } =
      this.props;

    const { newEngagementLoading, selectedEngagement, open } = this.state;

    return (
      <Grid container spacing={2} justify="center">
        {!studioSession.archived &&
        !studioSession.recorded &&
        !conciergeReadOnly ? (
          <Grid item xs={12}>
            <Chip
              size="small"
              label={"+ Rehearsals"}
              onClick={() => this.setState({ open: true })}
            />
          </Grid>
        ) : (
          []
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid container style={{ maxWidth: 600 }} spacing={2}>
            {this.getEngagementList()}
          </Grid>
        </Grid>
        {newEngagementLoading ? (
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <div />
        )}
        <FormEngagement
          open={open}
          close={() =>
            this.setState({ open: false, selectedEngagement: undefined })
          }
          studioSession={studioSession}
          createEngagement={api.createEngagement}
          updateEngagement={api.updateEngagement}
          deleteEngagement={api.deleteEngagement}
          refresh={refresh}
          conciergeReadOnly={conciergeReadOnly}
          engagement={selectedEngagement}
          studios={studios}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(Engagments);
