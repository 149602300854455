import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// material-ui
import { withStyles } from "@material-ui/styles";
import FileUpload from "hooks/FileUpload";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class FormImport extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    fileImport: PropTypes.object,

    createFileImport: PropTypes.func,
    updateFileImport: PropTypes.func,
    deleteFileImport: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      file: "",
      notes: "",
      category: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.fileImport);
    }
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(fileImport) {
    this.setState({
      loading: false,
      fileLoading: false,
      notes: fileImport ? fileImport.notes : "",
      category: fileImport ? fileImport.category : "",
      file: fileImport ? fileImport.file : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this fileImport, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteFileImport, fileImport, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteFileImport(fileImport.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "FileImport has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateFileImport,
      createFileImport,
      fileImport,
      close,
      refresh,
    } = this.props;

    const { category, file, notes } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (fileImport) {
      resp = await updateFileImport(fileImport.id, {
        category,
        file,
        notes,
      });
    } else {
      resp = await createFileImport({
        category,
        file,
        notes,
        status: "Processing",
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: fileImport
          ? "FileImport has been updated."
          : "FileImport has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      setTimeout(NotificationCenter.hide, 2000);
      close();
      refresh();
    }
  }

  render() {
    const { classes, fullScreen, open, fileImport, close } = this.props;
    const { file, loading, category, notes } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {fileImport ? `${fileImport.category}` : "New Import"}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item>
                  <FileUpload onUpload={(f) => this.setState({ file: f })} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="file"
                    variant="outlined"
                    size="small"
                    label="Url"
                    className={classes.textField}
                    value={file}
                    helperText="Required"
                    onChange={this.handleChange("file")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant="outlined"
                    size="small"
                  >
                    <InputLabel htmlFor="project-simple">Category</InputLabel>
                    <Select
                      inputProps={{
                        name: "Project",
                        id: "project-simple",
                      }}
                      value={category}
                      onChange={this.handleChange("category")}
                    >
                      <MenuItem value={"musicians"}>Musicians</MenuItem>
                      <MenuItem value={"customers"}>Customers</MenuItem>
                      <MenuItem value={"payrolls"}>Payrolls</MenuItem>
                      <MenuItem value={"studios"}>Studios/Venues</MenuItem>
                    </Select>
                    <FormHelperText>Required</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="notes"
                    label="Notes"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={notes}
                    onChange={this.handleChange("notes")}
                    multiline
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {fileImport && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button
                color="primary"
                autoFocus
                disabled={!file && !category}
                onClick={this.save.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} />
                Import
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormImport));
