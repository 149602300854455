import InputBase from "@material-ui/core/InputBase";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class Input extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    focusSavers: PropTypes.array,
    focusHandlers: PropTypes.array,
    refresh: PropTypes.func,
    clearFocus: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    name: PropTypes.string,
    handleChange: PropTypes.func,
    save: PropTypes.func,
    focusable: PropTypes.bool,
    multiply: PropTypes.bool,
    numeric: PropTypes.bool,
    style: PropTypes.object,
    id: PropTypes.number,
  };

  state = {
    focused: false,
    bulkFocus: false,
  };

  render() {
    const {
      classes,
      value,
      name,
      handleChange,
      save,
      focusable,
      numeric,
      style,
      multiply,
    } = this.props;

    const { focused, bulkFocus } = this.state;

    let inputStyle = classes.inputBase;
    if (focused) inputStyle = classes.focused;
    if (bulkFocus) inputStyle = classes.bulkFocus;
    const _style = { ...style };

    if (focusable) {
      _style.boxShadow = "rgba(155,155,155,0.4) 0px 2px 8px";
      _style.transition = "box-shadow 0.6s";
    } else {
      _style.boxShadow = "rgba(155,155,155,0.4) 0px 0px 0px";
    }

    return (
      <InputBase
        onKeyPress={async (e) => {
          if (e.key === "Enter") {
            save(name, numeric, multiply);
          }
        }}
        onBlur={async () => {
          save(name, numeric, multiply);
        }}
        className={inputStyle}
        value={value}
        onChange={(e) => {
          handleChange(name, e.target.value);
        }}
        style={_style}
      />
    );
  }
}

export default withStyles(styles)(Input);
