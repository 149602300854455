export default (theme) => ({
  container: {},
  box: {
    background: "rgba(155,155,155,0.1)",
    border: "solid 1px rgba(155,155,155,0.3)",
    padding: 8,
    borderRadius: 4,
    marginTop: 8,
  },
  item: {
    padding: 6,
    display: "flex",
    alignItems: "center",
  },
  cancel: {
    marginRight: 4,
  },
  variation: {
    background: theme.palette.background.paper,
  },
});
