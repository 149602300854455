import { networkAction } from "helpers/network/networkAction";

import {
  GET_LAYOUT_PRESETS,
  GET_LAYOUT_PRESET_BY_ID,
  DELETE_LAYOUT_PRESET,
  UPDATE_LAYOUT_PRESET,
  CREATE_LAYOUT_PRESET,
} from "constants/layoutPresets";

import * as Api from "api";

export const getLayoutPresets = () => async (dispatch) =>
  networkAction(dispatch, GET_LAYOUT_PRESETS, Api.getLayoutPresets, []);

export const getLayoutPresetById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_LAYOUT_PRESET_BY_ID, Api.getLayoutPresetById, [
    id,
  ]);

export const deleteLayoutPreset = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_LAYOUT_PRESET, Api.deleteLayoutPreset, [id]);

export const createLayoutPreset = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_LAYOUT_PRESET, Api.createLayoutPreset, [body]);

export const updateLayoutPreset = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_LAYOUT_PRESET, Api.updateLayoutPreset, [
    id,
    body,
  ]);
