// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import AutoComplete from "components/AutoComplete";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class AddStudioSessionMusician extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    musicians: PropTypes.array,
    createStudioSessionMusician: PropTypes.func,
    studioSession: PropTypes.object,
    refresh: PropTypes.func,
  };

  state = {
    loading: false,
  };

  async createStudioSessionMusician(musician) {
    const { createStudioSessionMusician, refresh, studioSession } = this.props;
    await createStudioSessionMusician({
      musicianID: musician.value.id,
      sessionID: studioSession.id,
    });
    refresh();
  }

  render() {
    const { classes, musicians } = this.props;
    const { loading } = this.state;

    return (
      <div
        className={classes.paper}
        style={{
          borderLeft: loading ? "solid 3px #607d8b" : "solid 3px #ff9800",
        }}
      >
        <AutoComplete
          placeHolder={"Add Musician to Work Session"}
          onSelect={(m) => this.createStudioSessionMusician(m)}
          suggestions={
            musicians &&
            musicians.map((m) => ({
              value: m,
              label:
                m.nickName || `${m.firstName} ${m.middleName} ${m.lastName}`,
            }))
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(AddStudioSessionMusician);
