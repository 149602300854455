import { Avatar, Chip, Grid, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import React from "react";

export default function Post({
  date,
  users,
  action,
  canvas,
  discarded,
  relatedSubhead,
  relatedTitle,
  relatedSubTitle,
  actionURL,
  actionText,
  loading,
  history,
  id,
  refresh,
  updatePost,
}) {
  const [hover, setHover] = React.useState(false);

  const discard = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (updatePost && refresh)
      updatePost(id, { discarded: true }).then(refresh);
  };

  if (loading) {
    return (
      <Paper
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          boxShadow: hover
            ? "0 6px 28px 0 rgba(24,52,117, 0.2)"
            : "0 3px 14px 0 rgba(24,52,117,0.2)",
          cursor: "pointer",
          overflow: "hidden",
          position: "relative",
          opacity: discarded ? 0.6 : 1,
        }}
      >
        <Grid item style={{ padding: 12 }}>
          <Grid container>
            <Grid item style={{ marginRight: 8 }}>
              <Skeleton variant="circle" style={{ height: 40, width: 40 }} />
            </Grid>
            <Grid item>
              <Skeleton
                variant="text"
                style={{ marginLeft: 4, width: 254, height: 16 }}
              />
              <Skeleton
                variant="text"
                style={{ marginLeft: 4, width: 70, height: 12 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                background: "rgba(155,155,155,0.1)",
                borderTop: "solid 1px rgba(155,155,155,0.3)",
                padding: 12,
              }}
            >
              <Grid container justify="space-between">
                <Grid item>
                  <Skeleton variant="text" style={{ width: 80, height: 10 }} />
                  <Skeleton variant="text" style={{ width: 280, height: 25 }} />
                  <Skeleton variant="text" style={{ width: 415, height: 19 }} />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  if (!users && !action) {
    return (
      <Paper
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() =>
          history.push(
            `${actionURL}&back=${`${window.location.pathname}${window.location.search}`}`
          )
        }
        style={{
          boxShadow: hover
            ? "0 6px 28px 0 rgba(24,52,117, 0.2)"
            : "0 3px 14px 0 rgba(24,52,117,0.2)",
          cursor: "pointer",
          overflow: "hidden",
          position: "relative",
          opacity: discarded ? 0.6 : 1,
        }}
      >
        <Chip
          onClick={discard}
          label={
            <span>
              <i class="fad fa-archive"></i> Archive
            </span>
          }
          size="small"
        />
        <div dangerouslySetInnerHTML={{ __html: canvas }} />
        <Grid container>
          {relatedSubhead || relatedTitle || relatedSubTitle ? (
            <Grid item xs={12}>
              <div
                style={{
                  background: "rgba(155,155,155,0.1)",
                  borderTop: "solid 1px rgba(155,155,155,0.3)",
                  padding: 12,
                }}
              >
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      style={{ lineHeight: 1 }}
                    >
                      {relatedSubhead}
                    </Typography>
                    <Typography variant="h6">{relatedTitle}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {relatedSubTitle}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ) : (
            []
          )}
        </Grid>
      </Paper>
    );
  }
  return (
    <Paper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={
        actionURL
          ? () =>
              history.push(
                `${actionURL}&back=${`${window.location.pathname}${window.location.search}`}`
              )
          : console.log("clicked")
      }
      style={{
        boxShadow: hover
          ? "0 6px 28px 0 rgba(24,52,117, 0.2)"
          : "0 3px 14px 0 rgba(24,52,117,0.2)",
        cursor: "pointer",
        opacity: discarded ? 0.6 : 1,
      }}
    >
      <Grid container justify="space-between">
        {users || action ? (
          <Grid item style={{ padding: 12 }}>
            <Grid container>
              <Grid item style={{ marginRight: 8 }}>
                <Avatar src={users[0].avatar} />
              </Grid>
              <Grid item>
                <Typography style={{ display: "flex" }}>
                  <b>{`${users[0].firstName} ${users[0].lastName} `}</b>
                  <div
                    style={{ marginLeft: 4 }}
                    dangerouslySetInnerHTML={{ __html: action }}
                  />
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment(date).fromNow()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item />
        )}
        <Grid item style={{ padding: 12 }}>
          <Chip
            onClick={discard}
            label={
              <span>
                <i class="fad fa-archive"></i> Archive
              </span>
            }
            size="small"
          />
        </Grid>
        {canvas ? (
          <Grid item xs={12} style={{ padding: "0px 12px 12px 12px" }}>
            <div dangerouslySetInnerHTML={{ __html: canvas }} />
          </Grid>
        ) : (
          []
        )}
        {relatedSubhead || relatedTitle || relatedSubTitle ? (
          <Grid item xs={12}>
            <div
              style={{
                background: "rgba(155,155,155,0.1)",
                borderTop: "solid 1px rgba(155,155,155,0.3)",
                padding: 12,
              }}
            >
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    variant="overline"
                    color="textSecondary"
                    style={{ lineHeight: 1 }}
                  >
                    {relatedSubhead}
                  </Typography>
                  <Typography variant="h6">{relatedTitle}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {relatedSubTitle}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : (
          []
        )}
      </Grid>
    </Paper>
  );
}
