export default (theme) => ({
  container: {
    background: theme.palette.background.default,
    position: "sticky",
    top: 0,
    zIndex: 999,
    borderBottom: "solid 1px #CFD8DC",
    textAlign: "center",
    display: "flex",
    padding: "0px 10px 0px 13px",
  },
  rowItem: {
    display: "flex",
    alignItems: "center",
    margin: 4,
    justifyContent: "center",
  },
});
