import { GET_ACTIVE_STUDIO_SESSIONS } from "constants/studioSessions";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_ACTIVE_STUDIO_SESSIONS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
