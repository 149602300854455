import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "postal";
const trails = `${REACT_APP_API_PREFIX}/${service}/trails`;

export function getTrails(filters) {
  const url = `${trails}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getTrailById(id) {
  const url = `${trails}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTrail(id, body) {
  const url = `${trails}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTrail(id) {
  const url = `${trails}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTrail(body) {
  const url = `${trails}`;
  return {
    method: POST,
    url,
    body,
  };
}
