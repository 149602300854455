import { networkAction } from 'helpers/network/networkAction';

import {
  GET_TITLE_PATTERNS,
  GET_TITLE_PATTERN_BY_ID,
  DELETE_TITLE_PATTERN,
  UPDATE_TITLE_PATTERN,
  CREATE_TITLE_PATTERN,
} from 'constants/titlePatterns';

import * as Api from 'api';

export const getTitlePatterns = () => async dispatch => networkAction(
  dispatch,
  GET_TITLE_PATTERNS,
  Api.getTitlePatterns,
  []
);

export const getTitlePatternById = (id) => async dispatch => networkAction(
  dispatch,
  GET_TITLE_PATTERN_BY_ID,
  Api.getTitlePatternById,
  [id]
);

export const deleteTitlePattern = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_TITLE_PATTERN,
  Api.deleteTitlePattern,
  [id]
);

export const createTitlePattern = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_TITLE_PATTERN,
  Api.createTitlePattern,
  [body]
);

export const updateTitlePattern = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_TITLE_PATTERN,
  Api.updateTitlePattern,
  [id, body]
);
