import MomentUtils from "@date-io/moment";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import Paper from "@material-ui/core/Paper";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import parse from "color-parse";
// helpers
import { formatDate, getJsonFromUrl, updateURLParameter } from "helpers";
import MercurySessionGlance from "hooks/MercurySessionGlance";
import moment from "moment-timezone";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const Sidebar = ({
  changeDate,
  onSelect,
  workSessions,
  history,
  date,
  onHover,
  projectID,
  classes,
  setShowArchived,
  showArchived,
  archived,
  centrifuge,
  setSelectedCalendarDay,
}) => {
  const renderDay = (d, selectedDate, dayInCurrentMonth) => {
    const isToday =
      moment().format("YYYY-MM-DD") === d.utc().format("YYYY-MM-DD");
    const sessionsThisDay =
      workSessions &&
      workSessions.filter &&
      workSessions.filter((s) => {
        return (
          moment(s.dateFromUTC).format("YYYY-MM-DD") ===
          d.utc().format("YYYY-MM-DD")
        );
      });

    const s = {};

    if (d.isSame(date, "week")) {
      const isSunday = d.format("ddd") === "Sun";
      const isSaturday = d.format("ddd") === "Sat";
      s.borderTop = "solid 1px rgba(155,155,155,0.3)";
      s.borderBottom = "solid 1px rgba(155,155,155,0.3)";
      s.height = "calc(100% - 2px)";
      if (isSunday) {
        s.borderLeft = "solid 1px rgba(155,155,155,0.3)";
        s.borderTopLeftRadius = 30;
        s.borderBottomLeftRadius = 30;
      }
      if (isSaturday) {
        s.borderRight = "solid 1px rgba(155,155,155,0.3)";
        s.borderTopRightRadius = 30;
        s.borderBottomRightRadius = 30;
        s.height = "calc(100% - 2px)";
      }
    }

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth,
      [classes[`busy${sessionsThisDay.length}`]]: sessionsThisDay.length,
      [classes.today]: isToday,
    });

    return (
      <div style={s}>
        <IconButton className={dayClassName}>
          <span> {d.format("D")} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        borderRight: "solid 1px rgba(155,155,155,0.3)",
      }}
    >
      <div
        style={{
          display: "flex",
          placeContent: "space-between",
        }}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            autoOk
            variant="static"
            value={date}
            disableToolbar
            onChange={(d) => {
              changeDate(d);
              if (projectID) {
                setSelectedCalendarDay({
                  date: d.utc().format(),
                  id: projectID,
                });
              }
              history.push({
                search: updateURLParameter("date", d.utc().format()),
              });
            }}
            renderDay={renderDay}
          />
        </MuiPickersUtilsProvider>
      </div>
      {archived ? (
        <FormControlLabel
          style={{ paddingLeft: 14 }}
          control={
            <Switch
              value={showArchived}
              onChange={(e) => setShowArchived(e.target.checked)}
              size="small"
            />
          }
          label={
            <Typography variant="caption" color="textSecondary">
              Show Archived
            </Typography>
          }
        />
      ) : (
        []
      )}
      <Typography style={{ fontWeight: 600, paddingLeft: 8 }}>
        Work Sessions for Week {date.week()}:
      </Typography>
      <Divider />
      <div style={{ flex: 4, overflow: "scroll" }}>
        <List
          dense
          style={{ width: "100%", paddingTop: 0, height: 0 }}
          aria-label="main mailbox folders"
        >
          {workSessions &&
            workSessions.filter &&
            workSessions
              .filter((w) => moment(w.dateFromUTC).isSame(date, "week"))
              .sort(
                (a, b) =>
                  moment(a.dateFromUTC).unix() - moment(b.dateFromUTC).unix()
              )
              .map((s) => (
                <ListItem
                  key={s.id}
                  button
                  divider
                  dense
                  style={{ opacity: s.archived ? 0.5 : 1 }}
                  onMouseEnter={() => onHover(s.id)}
                  onMouseLeave={() => onHover()}
                  onClick={() => onSelect(s)}
                >
                  <ListItemText
                    primary={
                      <>
                        {s.archived ? (
                          <Typography
                            variant="overline"
                            style={{
                              border: "solid 1px rgba(155,155,155,1)",
                              padding: "0px 4px",
                              borderRadius: 16,
                            }}
                          >
                            Archived
                          </Typography>
                        ) : (
                          []
                        )}
                        <Typography variant="body2">{s.title}</Typography>
                      </>
                    }
                    secondary={
                      <div>
                        {formatDate(s.dateFromUTC, s.tzName)}
                        <br />
                        {s.mercuryEnabled ? (
                          <MercurySessionGlance
                            workSession={s}
                            centrifuge={centrifuge}
                          />
                        ) : (
                          <div />
                        )}
                      </div>
                    }
                  />
                </ListItem>
              ))}
        </List>
      </div>
    </div>
  );
};

export default function WorkSessionCalendar({
  workSessions,
  setSelectedCalendarDay,
  history,
  onSelect,
  archived,
  projectID,
  centrifuge,
}) {
  const urlParams = getJsonFromUrl(window.location);
  const classes = useStyles(styles);
  const [date, changeDate] = React.useState(moment(urlParams.date));
  const [hoverID, setHoverID] = React.useState(moment());
  const [showArchived, setShowArchived] = React.useState(false);
  const [anchor, setAnchor] = React.useState({ el: null, day: null });
  const WEEK_DAYS = [];

  let _workSessions = !showArchived
    ? workSessions.filter && workSessions.filter((w) => !w.archived)
    : workSessions;
  _workSessions = _workSessions.sort(
    (a, b) => moment(a.dateFromUTC).valueOf() - moment(b.dateFromUTC).valueOf()
  );
  let dayCursor = date.clone().startOf("week");
  dayCursor.add(1, "hour"); // daylight saving ugly hack
  for (let index = 0; index < 7; index++) {
    const sessions =
      _workSessions &&
      _workSessions.filter &&
      _workSessions.filter(
        // eslint-disable-next-line no-loop-func
        (w) =>
          moment(w.dateFromUTC).format("YYYY-MM-DD") ===
          dayCursor.utc().format("YYYY-MM-DD")
      );
    const noTimeRangeSessions =
      sessions &&
      sessions.filter(
        (w) => w.dateFromUTC === w.dateToUTC || !w.dateToUTC || w.noTimeRange
      );
    const noTimeRangeHover =
      noTimeRangeSessions && noTimeRangeSessions.find((w) => w.id === hoverID);
    const clone = dayCursor.clone();
    const isToday = moment.tz(clone, moment.tz.guess()).isSame(moment(), "day");

    console.log(noTimeRangeSessions);

    WEEK_DAYS.push(
      <div
        style={{
          flex: 1,
          textAlign: "center",
          borderLeft:
            index !== 0 ? "solid 1px rgba(155,155,155,0.3)" : undefined,
          height: "100%",
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          background: isToday ? "rgba(255,152,0,0.05)" : undefined,
        }}
      >
        <Typography
          variant="overline"
          color="textSecondary"
          style={{ flex: 1 }}
        >
          {dayCursor.format("ddd")}
        </Typography>
        <div style={{ flexBasis: "100%", height: 0 }} />
        <Typography variant="h6" color="textSecondary" style={{ flex: 1 }}>
          {dayCursor.format("DD")}
        </Typography>
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div
          style={{
            height: 35,
            width: "100%",
            flex: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "solid 1px rgba(155,155, 155, 0.3)",
            borderTop: "solid 1px rgba(155,155, 155, 0.3)",
          }}
        >
          {noTimeRangeSessions && noTimeRangeSessions.length ? (
            <>
              <Chip
                label={`${
                  noTimeRangeSessions && noTimeRangeSessions.length
                } all day ▾`}
                style={{
                  background: noTimeRangeHover
                    ? "rgba(155,155,155, 1)"
                    : "rgba(155,155,155, 0.1)",
                  borderColor: "rgba(155,155,155, 1)",
                  color: noTimeRangeHover ? "white" : "rgba(155,155,155, 1)",
                }}
                variant="outlined"
                size="small"
                onClick={(e) =>
                  setAnchor({ el: e.currentTarget, ref: clone.format("DD") })
                }
              />
              <Menu
                id="simple-menu"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                anchorEl={anchor.ref === clone.format("DD") ? anchor.el : null}
                open={anchor.ref === clone.format("DD")}
                onClose={(e) => setAnchor({ el: null, ref: null })}
              >
                {noTimeRangeSessions &&
                  noTimeRangeSessions.map((s) => (
                    <ListItem
                      key={s.id}
                      button
                      divider
                      onClick={() => onSelect(s)}
                    >
                      <ListItemText
                        primary={s.title}
                        secondary={
                          <div>
                            {formatDate(s.dateFromUTC, s.tzName)}
                            {s.mercuryEnabled ? (
                              <MercurySessionGlance
                                workSession={s}
                                centrifuge={centrifuge}
                              />
                            ) : (
                              <div />
                            )}
                          </div>
                        }
                      />
                    </ListItem>
                  ))}
              </Menu>
            </>
          ) : (
            []
          )}
        </div>
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div
          style={{
            flex: "auto",
            height: "calc(100% - 100px)",
            width: "100%",
            position: "relative",
            backgroundRepeat: "repeat",
            backgroundSize: "contain",
          }}
        >
          {isToday ? (
            <div
              style={{
                height: 1,
                background: "#ff9800",
                width: "100%",
                top: `${
                  (100 *
                    (parseInt(moment().format("mm")) / 60 +
                      parseInt(moment().format("HH")))) /
                  24
                }%`,
                zIndex: 1000,
                position: "absolute",
              }}
            />
          ) : (
            []
          )}
          {sessions
            .filter((s) => !s.noTimeRange)
            .map((s, index) => {
              const duration = moment.duration(
                moment(s.dateToUTC).diff(moment(s.dateFromUTC))
              );
              const hours = duration.asHours();
              const projectColor = s.projectColor
                ? parse(s.projectColor)
                : parse("#ff9800");
              const alpha1 = `rgba(${projectColor.values[0]}, ${projectColor.values[1]}, ${projectColor.values[2]}, 0.1)`;
              const startHour =
                parseInt(moment.tz(s.dateFromUTC, s.tzName).format("mm")) / 60 +
                parseInt(moment.tz(s.dateFromUTC, s.tzName).format("HH"));
              if (hours > 0) {
                return (
                  <Tooltip title={`Project: ${s.projectName}`}>
                    <div
                      style={{
                        position: "absolute",
                        top: `${(100 * startHour) / 24}%`,
                        height: `calc(${
                          (100 *
                            moment
                              .duration(
                                moment(s.dateToUTC).diff(moment(s.dateFromUTC))
                              )
                              .asHours()) /
                          24
                        }% - 8px)`,
                        width: `calc(100% - ${8 + index * 8}px)`,
                        marginLeft: -1 + index * 8,
                        padding: 4,
                        backdropFilter: "blur(4px)",
                        WebkitBackdropFilter: "blur(4px)",
                        zIndex: hoverID === s.id ? 999 : undefined,
                        transition: "background .2s",
                        background: hoverID === s.id ? s.projectColor : alpha1,
                        borderLeft: `solid 2px ${s.projectColor}`,
                        fontSize: 10,
                        color: hoverID === s.id ? "white" : s.projectColor,
                        textAlign: "left",
                        overflow: "scroll",
                        cursor: "pointer",
                        opacity: s.archived ? 0.5 : 1,
                        filter: s.archived ? "grayscale(100%)" : undefined,
                        minHeight: 20,
                      }}
                      onMouseEnter={() => setHoverID(s.id)}
                      onMouseLeave={() => setHoverID()}
                      onClick={() => onSelect(s)}
                    >
                      {s.archived ? (
                        <Typography
                          variant="overline"
                          style={{
                            border: `solid 1px ${s.projectColor}`,
                            padding: "0px 4px",
                            borderRadius: 16,
                          }}
                        >
                          Archived
                        </Typography>
                      ) : (
                        []
                      )}
                      <Typography variant="body2">{s.title}</Typography>
                      <Typography variant="caption" display="block">
                        {moment.tz(s.dateFromUTC, s.tzName).format("hh:mm A")} -{" "}
                        {moment.tz(s.dateToUTC, s.tzName).format("hh:mm A")}
                      </Typography>
                    </div>
                  </Tooltip>
                );
              }
              return [];
            })}
        </div>
      </div>
    );
    dayCursor = dayCursor.add(1, "day");
  }

  const GRADUATION = [];

  for (let index = 0; index < 24; index++) {
    GRADUATION.push(
      <div style={{ flex: "auto", width: "100%", position: "relative" }}>
        <div
          style={{
            width: "100%",
            borderTop: "solid 1px rgba(155,155,155,0.1)",
          }}
        />
        <Typography
          color="textSecondary"
          style={{ fontSize: 7, width: "100%" }}
        >{`${index <= 9 ? `0${index}` : index}:00`}</Typography>
      </div>
    );
  }

  return (
    <Paper
      elevation={0}
      style={{ flex: 1, display: "flex", overflow: "hidden" }}
    >
      <Grid container style={{ display: "flex", flex: 1 }}>
        <Grid item style={{ maxWidth: 311, flex: 1, display: "flex" }}>
          <Sidebar
            date={date}
            classes={classes}
            setSelectedCalendarDay={setSelectedCalendarDay}
            archived={archived}
            projectID={projectID}
            history={history}
            changeDate={changeDate}
            onSelect={onSelect}
            centrifuge={centrifuge}
            onHover={setHoverID}
            workSessions={_workSessions}
            showArchived={showArchived}
            setShowArchived={setShowArchived}
          />
        </Grid>
        <Grid style={{ flexGrow: 1, position: "relative" }}>
          <div
            style={{
              width: "100%",
              height: "calc(100% - 100px)",
              position: "absolute",
              top: 99,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "space-between",
              flexDirection: "row",
              textAlign: "left",
            }}
          >
            {GRADUATION}
          </div>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              borderRight: "solid 1px rgba(155,155,155,0.3)",
              height: "100%",
            }}
          >
            {WEEK_DAYS}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}
