import { Chip, Divider, Hidden, Icon, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// material-ui
import { useTheme, withStyles } from "@material-ui/styles";
// custom
import Signature from "components/Signature";
import FileUpload from "hooks/FileUpload";
import PhoneInput from "hooks/PhoneInput";
import PropTypes from "prop-types";
import React from "react";
import { Mention, MentionsInput } from "react-mentions";
import { getDefaultStyle } from "./defaultStyle";
// styles
import styles from "./styles";

function TitlePattern({ api, variant, tp, title }) {
  const [text, setText] = React.useState(tp?.template ?? "");
  const [loading, setLoading] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [titlePattern, setTitlePattern] = React.useState(tp);
  const input = React.useRef();
  const theme = useTheme();
  const mentionSuggestions = [];

  for (const key in titlePattern?.Mapping) {
    if (Object.hasOwnProperty.call(titlePattern?.Mapping, key)) {
      const item = titlePattern?.Mapping[key];
      mentionSuggestions.push({
        display: key,
        id: key,
        example: item,
      });
    }
  }

  const save = async () => {
    if (titlePattern?.id) {
      setLoading(true);
      await api.updateTitlePattern(titlePattern.id, { template: text });
      setLoading(false);
    }
  };

  const getPreview = () => {
    return text.replaceAll(
      /({){2}\.\w+(}){2}/g,
      (e) =>
        mentionSuggestions.find((s) => s.id === e.substring(3, e.length - 2))
          .example
    );
  };

  const create = async () => {
    const r = await api.createTitlePattern({ target: variant });
    if (r.succes) {
      setTitlePattern(r.payload);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        alt="SMS"
        style={{ marginRight: 16 }}
        src={`https://storage.googleapis.com/rhapsody/media/${variant}.png`}
      />
      {titlePattern ? (
        <div style={{ flex: 1 }}>
          <div>
            <Typography variant="h6">
              <b>{title}</b>
            </Typography>
          </div>
          <Typography variant="caption">Available Dynamic Values:</Typography>
          <br />
          <Grid container spacing={1} style={{ paddingBottom: 6 }}>
            {mentionSuggestions.map((s) => (
              <Grid item>
                <Chip
                  label={s.display}
                  size="small"
                  onClick={(e) => {
                    setText(`${text}{{.${s.display}}}`);
                    input.current.focus();
                  }}
                />
              </Grid>
            ))}
          </Grid>
          {preview ? (
            <div
              style={{
                padding: 16,
                background: "rgba(155,155,155,0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 28,
              }}
            >
              <Typography style={{ fontSize: 14 }}>
                <b>{getPreview()}</b>
              </Typography>
            </div>
          ) : (
            <MentionsInput
              value={text}
              disabled={loading}
              inputRef={input}
              placeholder={
                "Write your custom pattern. Type # to show the dynamic values to insert."
              }
              onChange={(e) => {
                setText(e.target.value);
                console.log(e.target.value);
              }}
              style={{
                width: "100%",
                ...getDefaultStyle(theme),
              }}
              allowSuggestionsAboveCursor
            >
              <Mention
                trigger="#"
                markup="{{.__display__}}"
                data={mentionSuggestions}
                style={{
                  backgroundColor: "#bbdefb",
                  color: "#1976d2 !important",
                }}
              />
            </MentionsInput>
          )}
          <Button
            style={{ marginTop: 4 }}
            size="small"
            onClick={() => {
              let v = !preview;
              setPreview(v);
              if (!v)
                setTimeout(() => {
                  input.current.focus();
                  input.current.setSelectionRange(text.length, text.length);
                }, 500);
            }}
            variant="contained"
          >
            {preview ? "Edit" : "Preview"}
          </Button>
          <Button
            style={{ marginTop: 4, marginLeft: 8 }}
            size="small"
            onClick={save}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <Typography variant="h6">
            <b>{title}</b>
          </Typography>
          <Button size="small" variant="contained" onClick={create}>
            Enable
          </Button>
        </div>
      )}
    </div>
  );
}

class FormCompanyInfo extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    company: PropTypes.object,
    updateCurrentCompany: PropTypes.func,
    upload: PropTypes.func,
    titlePatterns: PropTypes.array,
    api: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { company } = this.props;
    this.state = {
      name: company.name || "",
      address: company.address || "",
      city: company.city || "",
      userName: company.userName || "",
      zipcode: company.zipcode || "",
      state: company.state || "",
      phone: company.phone || "",
      contact: company.contact || "",
      email: company.email || "",
      logoURL: company.logoURL,
      referralCode: company.referralCode || "",
      signatureURL: company.signatureURL,
      hasChanged: false,
      signatureChanged: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.company === undefined &&
      nextProps.company &&
      nextProps.company.id
    ) {
      this.init(nextProps.company);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
      hasChanged: true,
    });
  };

  init(company) {
    this.setState({
      name: company.name,
      address: company.address,
      city: company.city,
      zipcode: company.zipcode,
      state: company.state,
      email: company.email,
      phone: company.phone,
      contact: company.contact,
      userName: company.userName,
      signatureURL: company.signatureURL,
      referralCode: company.referralCode,
      logoURL: company.logoURL,
    });
  }

  async save(name, numeric) {
    const { updateCurrentCompany, company } = this.props;

    if (company[name] === this.state[name]) return;

    await updateCurrentCompany({
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });
  }

  isValid() {
    const { name, hasChanged } = this.state;

    return name.length > 0 && hasChanged;
  }

  async saveSignature() {
    const { upload } = this.props;
    const { getSignature } = this.state;
    const signature = getSignature();
    if (signature) {
      fetch(signature)
        .then((res) => res.blob())
        .then(async (blob) => {
          const r = await upload(blob);
          this.setState({ signatureURL: r.payload }, () =>
            this.save("signatureURL")
          );
        });
    }
  }

  render() {
    const { classes, titlePatterns, api } = this.props;
    const {
      name,
      address,
      city,
      zipcode,
      state,
      userName,
      phone,
      contact,
      signatureURL,
      logoURL,
      email,
    } = this.state;

    return (
      <div>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <Paper className={classes.container}>
              <Typography variant="h6" gutterBottom>
                Me as a Contractor{" "}
                <Tooltip title="Blog Article: Setup your contractor profile">
                  <Icon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.blog.rhapsody.la/2021/01/28/how-do-i-customize-my-profile-as-a-contractor/",
                        "_blank"
                      )
                    }
                    class="fad fa-question-circle"
                  ></Icon>
                </Tooltip>
              </Typography>
              <br />
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="contact"
                    label="Contractor Name"
                    value={contact}
                    onBlur={() => this.save("contact")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("contact");
                    }}
                    onChange={this.handleChange("contact")}
                  />
                  <Typography variant="caption" style={{ color: "#2196f3" }}>
                    <i class="fad fa-info-circle p-right"></i>This name will
                    appear in your communications and payroll forms.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="contact"
                    label="Username"
                    value={userName}
                    onBlur={() => this.save("userName")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("userName");
                    }}
                    onChange={this.handleChange("userName")}
                  />
                  <Typography variant="caption" style={{ color: "#2196f3" }}>
                    <i class="fad fa-info-circle p-right"></i>All your mail
                    communications will be sent using the address{" "}
                    {userName || "studio"}@rhapsody.la
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="email"
                    onBlur={() => this.save("email")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("email");
                    }}
                    label="Contractor Email"
                    value={email}
                    onChange={this.handleChange("email")}
                  />
                  <Typography variant="caption" style={{ color: "#2196f3" }}>
                    <i class="fad fa-info-circle p-right"></i>This email will be
                    used as the reply-to address.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="phone"
                    label="Phone"
                    onBlur={() => this.save("phone")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("phone");
                    }}
                    value={phone}
                    onChange={this.handleChange("phone")}
                  />
                  <Typography variant="caption" style={{ color: "#2196f3" }}>
                    <i class="fad fa-info-circle p-right"></i>This phone will be
                    displayed your communications.
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography color="textSecondary" variant="caption">
                    Signature
                  </Typography>
                  <Signature
                    signature={signatureURL}
                    clearSignature={() =>
                      this.setState({ signatureURL: undefined })
                    }
                    onSave={this.saveSignature.bind(this)}
                    getSignature={(f) => this.setState({ getSignature: f })}
                    signatureHasChanged={() =>
                      this.setState({
                        signatureChanged: true,
                        hasChanged: true,
                      })
                    }
                    height={124}
                    width={368}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.container}>
              <Typography variant="h6" gutterBottom>
                Company Info
              </Typography>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    <Grid container>
                      <Grid item xs={12}>
                        {logoURL && (
                          <>
                            <img
                              src={logoURL}
                              alt="Your Logo"
                              style={{
                                width: "100%",
                                border: "solid 1px rgba(155,155,155,0.3)",
                                borderRadius: 4,
                              }}
                            />
                            <br />
                          </>
                        )}
                        <FileUpload
                          onUpload={(f) =>
                            this.setState({ logoURL: f }, () =>
                              this.save("logoURL")
                            )
                          }
                        />
                        <Typography variant="body2">
                          Upload the Logo that will appear by default on Work
                          Session Invites and also on the Roster.
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          Recommanded PNG with Dimension 460x90px on a
                          transparent Background.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}></Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="companyName"
                    label="Company Name"
                    value={name}
                    onBlur={() => this.save("name")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("name");
                    }}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="address"
                    label="Address"
                    onBlur={() => this.save("address")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("address");
                    }}
                    value={address}
                    onChange={this.handleChange("address")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="city"
                    label="City"
                    onBlur={() => this.save("city")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("city");
                    }}
                    value={city}
                    onChange={this.handleChange("city")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="zipcode"
                    label="Zipcode"
                    onBlur={() => this.save("zipcode")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("zipcode");
                    }}
                    value={zipcode}
                    onChange={this.handleChange("zipcode")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="state"
                    onBlur={() => this.save("state")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("state");
                    }}
                    label="State"
                    value={state}
                    onChange={this.handleChange("state")}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12}>
              <Paper className={classes.container}>
                <Typography variant="h6">Communication Settings</Typography>
                <Typography variant="caption" color="textSecondary">
                  Customize the email's subject, title and the content of the
                  SMS for your communications
                </Typography>
                <br />
                <TitlePattern
                  api={api}
                  title="SMS Content"
                  tp={titlePatterns?.find((tp) => tp.target === "smsContent")}
                  variant="smsContent"
                />
                <Divider style={{ margin: "16px 0px" }} />
                <TitlePattern
                  api={api}
                  title="Email Title"
                  tp={titlePatterns?.find((tp) => tp.target === "emailTitle")}
                  variant="emailTitle"
                />
                <Divider style={{ margin: "16px  0px" }} />
                <TitlePattern
                  api={api}
                  title="Email Subject"
                  tp={titlePatterns?.find((tp) => tp.target === "emailSubject")}
                  variant="emailSubject"
                />
              </Paper>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(FormCompanyInfo);
