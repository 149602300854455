import { networkAction } from "helpers/network/networkAction";

import {
  GET_SESSION_SECTIONS,
  GET_SESSION_SECTION_BY_ID,
  DELETE_SESSION_SECTION,
  UPDATE_SESSION_SECTION,
  CREATE_SESSION_SECTION,
} from "constants/sessionSections";

import * as Api from "api";

export const getSessionSections = () => async (dispatch) =>
  networkAction(dispatch, GET_SESSION_SECTIONS, Api.getSessionSections, []);

export const getSessionSectionById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_SESSION_SECTION_BY_ID,
    Api.getSessionSectionById,
    [id]
  );

export const deleteSessionSection = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SESSION_SECTION, Api.deleteSessionSection, [
    id,
  ]);

export const createSessionSection = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SESSION_SECTION, Api.createSessionSection, [
    body,
  ]);

export const updateSessionSection = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SESSION_SECTION, Api.updateSessionSection, [
    id,
    body,
  ]);
