import React, { PureComponent } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";

class CheckoutForm extends PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    // User clicked submit
  }

  render() {
    return (
      <div>
        <CardElement />
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
