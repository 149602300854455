import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const payrolls = `/${service}/payrolls`;

export function getPayrolls() {
  const url = `${payrolls}`;
  return {
    method: GET,
    url,
  };
}

export function getPayrollById(id) {
  const url = `${payrolls}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getPayrollStudioSessions(id) {
  const url = `${payrolls}/${id}/workSessions`;
  return {
    method: GET,
    url,
  };
}

export function getPayrollProjects(id) {
  const url = `${payrolls}/${id}/projects`;
  return {
    method: GET,
    url,
  };
}

export function updatePayroll(id, body) {
  const url = `${payrolls}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePayroll(id) {
  const url = `${payrolls}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPayroll(body) {
  const url = `${payrolls}`;
  return {
    method: POST,
    url,
    body,
  };
}
