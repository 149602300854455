import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Back from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Warning from "@material-ui/icons/Warning";
// material-ui
import { withStyles } from "@material-ui/styles";
// actions
import * as Actions from "actions";
import Card from "components/Card";
// components
import CreateCard from "components/CreateCard";
import NoRender from "components/NoRender";
// constant
import { REACT_APP_BAZAAR_CALLBACK, STRIPE_TOKEN } from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  cards: state.cards,
  cart: state.cart,
  bazaarCustomer: state.bazaarCustomer,
  restriction440: state.restriction440,
});

const data = {};

const mapDispatchToProps = (dispatch) => ({
  getCards: (...args) => dispatch(Actions.getCards(...args)),
  updateBazaarCustomer: (...args) =>
    dispatch(Actions.updateBazaarCustomer(...args)),
  getBazaarCurrentCustomer: (...args) =>
    dispatch(Actions.getBazaarCurrentCustomer(...args)),
  createCard: (...args) => dispatch(Actions.createCard(...args)),
  payCart: (...args) => dispatch(Actions.payCart(...args)),
  getCurrentCart: (...args) => dispatch(Actions.getCurrentCart(...args)),
  updateCart: (...args) => dispatch(Actions.updateCart(...args)),
});

class SubscriptionCenter extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    cards: PropTypes.array,
    subscriptions: PropTypes.array,
    bazaarCustomer: PropTypes.any,
    getCards: PropTypes.func,
    createCard: PropTypes.func,
    getBazaarCurrentCustomer: PropTypes.func,
    updateBazaarCustomer: PropTypes.func,
    payCart: PropTypes.func,
    getCurrentCart: PropTypes.func,
    cart: PropTypes.any,
    updateCart: PropTypes.func,
    restriction440: PropTypes.object,
  };

  static childContextTypes = {
    SubscriptionCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { getCurrentCart } = this.props;
    getCurrentCart();
    this.state = {
      open: false,
      upgradeOpen: false,
      stepIndex: 0,
      quantity: 1,
      cardFetched: false,
      cartLoading: false,
    };
  }

  getChildContext() {
    return {
      SubscriptionCenter: {
        restricted: this.restricted.bind(this),
        buyCredits: this.buyCredits.bind(this),
        upgrade: this.openUpgrade.bind(this),
        getBazaarCurrentCustomer: this.getBazaarCurrentCustomer.bind(this),
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      this.props.restriction440.timestamp,
      nextProps.restriction440.timestamp
    );
    if (
      this.props.restriction440.timestamp < nextProps.restriction440.timestamp
    ) {
      this.setState({ target: nextProps.restriction440.target });
      this.open();
    }
  }

  getBazaarCurrentCustomer() {
    const { getBazaarCurrentCustomer } = this.props;
    getBazaarCurrentCustomer();
  }

  getCards() {
    const { getCards } = this.props;
    const { cardFetched } = this.state;

    if (!cardFetched) {
      getCards().then((r) => {
        if (r.success) {
          const cards = r.payload;
          if (cards && cards.length) {
            this.setState({ selectedCard: cards[0] });
          }
        }
      });
      this.state.cardFetched = true; // eslint-disable-line
    }
  }

  restricted(target) {
    this.setState({
      target,
    });
    this.open();
  }

  check(target, noShow) {
    const { bazaarCustomer } = this.props;
    const allowed = bazaarCustomer.subscriptionID >= data[target].require;
    if (!allowed && !noShow) {
      this.setState({ target });
      this.open();
    }
    return allowed;
  }

  open() {
    this.setState({ open: true });
  }

  async buyCredits() {
    const { getCurrentCart } = this.props;
    this.getCards();
    await getCurrentCart();
    this.setState(
      {
        open: false,
        upgradeOpen: true,
        stepIndex: 1,
        selectedID: 3,
        quantity: 1,
        cardFetched: false,
        cartLoading: false,
        hideBack: true,
      },
      this.validateCart.bind(this)
    );
  }

  close() {
    this.setState({
      open: false,
      upgradeOpen: false,
      stepIndex: 0,
      quantity: 1,
      cardFetched: false,
      cartLoading: false,
    });
  }
  openUpgrade() {
    this.close();
    this.getCards();
    this.setState({
      upgradeOpen: true,
    });
    this.validateCart();
  }

  closeUpgrade() {
    this.setState({
      upgradeOpen: false,
      open: false,
      stepIndex: 0,
      quantity: 1,
      cardFetched: false,
      cartLoading: false,
    });
  }
  validateCart() {
    const { updateCart, cart, getCurrentCart } = this.props;
    const { coupon, quantity, selectedID } = this.state;

    this.setState({
      cartLoading: true,
    });
    updateCart(cart.id, {
      goods: [
        {
          id: selectedID || 3,
          quantity: selectedID === 3 ? quantity : 1,
        },
      ],
      coupons: coupon ? [{ token: coupon }] : [],
    }).then(async () => {
      getCurrentCart();
      this.setState({
        cartLoading: false,
      });
    });
  }

  async payCart() {
    const { payCart, cart, getBazaarCurrentCustomer } = this.props;
    const { selectedCard } = this.state;

    this.setState({ stepIndex: 2 });
    const resp = await payCart(cart.id, { stripeToken: selectedCard.id });
    if (resp.success) {
      const payment = resp.payload;
      await fetch(REACT_APP_BAZAAR_CALLBACK, {
        body: JSON.stringify({ paymentID: payment.id }),
        method: "POST",
      });
      setTimeout(getBazaarCurrentCustomer, 2000);
      this.setState({ stepIndex: 3 });
    } else {
      this.setState({ stepIndex: 1 });
    }
  }

  render() {
    const {
      children,
      fullScreen,
      classes,
      bazaarCustomer,
      cards,
      createCard,
      cart,
      getCards,
    } = this.props;

    const {
      open,
      target,
      upgradeOpen,
      selectedID,
      stepIndex,
      quantity,
      selectedCard,
      coupon,
      cartLoading,
      addCardCollapsed,
      hideBack,
    } = this.state;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    const content = data[target] || data[6];
    const disabled =
      bazaarCustomer.subscriptionID === 3 &&
      bazaarCustomer.credit &&
      stepIndex === 0;

    const lowPrice = 62;
    const price = 95;
    const maxQuantity = 100;
    const minQuantity = 1;
    const c = maxQuantity / minQuantity;

    return (
      <React.Fragment>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.close.bind(this)}
          aria-labelledby="responsive-dialog-title"
        >
          <React.Fragment>
            <DialogTitle
              id="responsive-dialog-title"
              style={{
                textAlign: "center",
              }}
            >
              <div>
                <img
                  src={content?.image}
                  alt="premium-feature"
                  style={{ width: 150 }}
                />
                {content?.premiumLabel ? (
                  <Typography variant="overline" display="block">
                    Premium Feature
                  </Typography>
                ) : (
                  []
                )}
                <Typography display="block" variant="h6" color="textPrimary">
                  <b>{content?.title}</b>
                </Typography>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{content?.body}</DialogContentText>
            </DialogContent>
          </React.Fragment>
          <DialogActions>
            <Button
              onClick={this.close.bind(this)}
              style={{ textTransform: "none" }}
            >
              Close
            </Button>
            {content?.action === 2 ? (
              <Button
                onClick={this.buyCredits.bind(this)}
                style={{
                  background: "#2196f3",
                  color: "white",
                  textTransform: "none",
                }}
                autoFocus
                variant="contained"
              >
                Buy Credits
              </Button>
            ) : (
              []
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen
          open={upgradeOpen}
          onClose={this.closeUpgrade.bind(this)}
          aria-labelledby="responsive-dialog-title"
        >
          <div style={{ flexGrow: 1 }}>
            <IconButton
              style={{
                background: "#e0e0e0",
                position: "fixed",
                top: 10,
                right: 10,
              }}
              onClick={() => this.setState({ upgradeOpen: false })}
            >
              <Close />
            </IconButton>
            <Grid
              container
              justify="space-between"
              style={{ flexGrow: 1, maxWidth: 1200, margin: "auto" }}
            >
              <Grid item xs={12} style={{ padding: 8 }}>
                <Grid container justify="space-between">
                  <Grid item>
                    <IconButton
                      style={{
                        background: "#e0e0e0",
                        display:
                          stepIndex !== 1 || hideBack ? "none" : undefined,
                      }}
                      onClick={() =>
                        this.setState((prevState) => ({
                          stepIndex: prevState.stepIndex - 1,
                        }))
                      }
                    >
                      <Back />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img
                      alt="rhapsody"
                      src="https://storage.googleapis.com/rhapsody/logos/logo_main.png"
                      style={{
                        width: "calc(100% - 40px)",
                        maxWidth: 200,
                        paddingBottom: 30,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {stepIndex === 0 ? (
                <Grid item xs={12} style={{ textAlign: "center", padding: 8 }}>
                  <Typography variant="h4" display="block">
                    Adjust your Plan
                  </Typography>
                  <Typography
                    variant="headline"
                    color="textSecondary"
                    display="block"
                  >
                    Choose the plan that sounds right for you
                  </Typography>
                  <Typography color="textSecondary" display="block">
                    No credit card required for free plan.
                  </Typography>
                </Grid>
              ) : (
                []
              )}
              {disabled ? (
                <Grid
                  item
                  style={{
                    textAlign: "center",
                    padding: 8,
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 10,
                  }}
                  xs={12}
                >
                  <div
                    style={{
                      border: "solid 1px #ff9800",
                      display: "flex",
                      alignItems: "center",
                      padding: "4px 8px",
                      borderRadius: 30,
                    }}
                  >
                    <Warning style={{ color: "#ff9800" }} />
                    <Typography style={{ color: "#ff9800" }}>
                      You still have some credits left. Please wait to use them
                      all before changing your plan.
                    </Typography>
                  </div>
                </Grid>
              ) : (
                []
              )}
              {stepIndex === 1 && selectedID === 3 ? (
                <Grid item xs={12} style={{ textAlign: "center", padding: 8 }}>
                  <Typography variant="display1">
                    Your <span className={classes.alpha}>Contractor</span> Plan
                  </Typography>
                  <br />
                  <Typography variant="headline" color="textSecondary">
                    Select the amount of Work Session you wish to purchase
                  </Typography>
                  <div
                    style={{
                      padding: 16,
                      border: "solid 1px #cfd8dc",
                      borderRadius: 8,
                      maxWidth: 450,
                      margin: "20px auto 20px auto",
                      textAlign: "left",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      justify="center"
                      direction="column"
                      alignItems="center"
                    >
                      <Grid item style={{ textAlign: "center" }}>
                        <Typography variant="h6" style={{ color: "#2196f3" }}>
                          <b>
                            {quantity} Work Session Credit
                            {quantity > 1 ? "s" : ""}
                          </b>
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ fontSize: 20 }}>$</span>{" "}
                          <span style={{ fontSize: 34 }}>
                            {Math.round(
                              ((lowPrice - price) /
                                (maxQuantity - minQuantity)) *
                                quantity +
                                (c * price - lowPrice) / (c - 1)
                            )}
                          </span>
                          <Typography variant="body2">Unit Price</Typography>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ marginTop: 1, width: "100%", maxWidth: 300 }}
                      >
                        <Slider
                          valueLabelDisplay="auto"
                          onChange={(e, v) => this.setState({ quantity: v })}
                          onChangeCommitted={this.validateCart.bind(this)}
                          value={quantity}
                          step={1}
                          style={{
                            maxWidth: 300,
                            width: "100%",
                          }}
                          classes={{
                            root: classes.sliderroot,
                            thumb: classes.thumb,
                            active: classes.active,
                            valueLabel: classes.valueLabel,
                            track: classes.track,
                            rail: classes.rail,
                          }}
                          min={1}
                          max={100}
                        />
                      </Grid>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ marginTop: 20 }}
                      >
                        IMPORTANT: Credits don't expire and are not refundable.
                        <br />A credit is used as soon as the first call is
                        sent.
                      </Typography>
                    </Grid>
                  </div>
                </Grid>
              ) : (
                []
              )}
              {stepIndex === 1 && (selectedID === 3 || selectedID === 2) ? (
                <Grid item xs={12} style={{ padding: 8 }}>
                  <div style={{ maxWidth: 600, margin: "auto" }}>
                    <Typography variant="h6" color="textPrimary">
                      Method of Payement
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      We use{" "}
                      <a
                        href="https://stripe.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{
                          color: "#31354C",
                          fontWeight: 800,
                          textDecoration: "none",
                        }}
                      >
                        Stripe
                      </a>{" "}
                      to secure the transaction.
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 16 }}>
                      {cards &&
                        cards.map((card) => (
                          <Grid item key={card.id} xs={12}>
                            <Card
                              dardMode={false}
                              card={card}
                              key={card.id}
                              selected={
                                selectedCard && selectedCard.id === card.id
                                  ? true
                                  : false
                              }
                              onSelect={(c) =>
                                this.setState({ selectedCard: c })
                              }
                            />
                          </Grid>
                        ))}
                    </Grid>
                    <ExpansionPanel
                      expanded={addCardCollapsed}
                      onChange={(e, p) => {
                        this.setState({ addCardCollapsed: p });
                      }}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="caption" color="textSecondary">
                          Add a new card
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div style={{ width: "100%" }}>
                          <StripeProvider apiKey={STRIPE_TOKEN}>
                            <Elements>
                              <CreateCard
                                createCard={(...args) => {
                                  const p = createCard(...args);
                                  p.then((r) => {
                                    if (r.success) {
                                      this.setState({
                                        selectedCard: r.payload,
                                        addCardCollapsed: false,
                                      });
                                    }
                                  });
                                  return p;
                                }}
                                refresh={getCards}
                                onClose={() =>
                                  this.setState({ addCardCollapsed: false })
                                }
                              />
                            </Elements>
                          </StripeProvider>
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </Grid>
              ) : (
                []
              )}
              {stepIndex === 1 && selectedID === 2 ? (
                <Grid item xs={12} style={{ padding: 8 }}>
                  <div style={{ maxWidth: 600, margin: "auto" }}>
                    {/* <FormControlLabel
                          control={(
                            <Checkbox
                              checked={bazaarCustomer && bazaarCustomer.autoPayment}
                              onChange={() => updateBazaarCustomer(bazaarCustomer.id, { autoPayment: !bazaarCustomer.autoPayment }).then(getBazaarCurrentCustomer)}
                              style={{ color: '#2196f3' }}
                            />
                          )}
                          label="Auto Payment"
                        /> */}
                    <Typography variant="caption" color="textPrimary">
                      You will be charged based on your usage at the end of the
                      month.
                    </Typography>
                  </div>
                </Grid>
              ) : (
                []
              )}
              {stepIndex === 1 && selectedID === 3 ? (
                <Grid item xs={12} style={{ padding: 8 }}>
                  <div style={{ maxWidth: 600, margin: "auto" }}>
                    <Typography variant="h6" color="textPrimary">
                      Coupon
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Provide a coupon if any.
                    </Typography>
                    <br />
                    <TextField
                      value={coupon}
                      onChange={(e) =>
                        this.setState({ coupon: e.target.value })
                      }
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.validateCart();
                      }}
                      onBlur={this.validateCart.bind(this)}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      placeholder="Coupon Code"
                      variant="outlined"
                      id="custom-css-outlined-input"
                    />
                  </div>
                </Grid>
              ) : (
                []
              )}
              {stepIndex === 1 && selectedID === 3 && cart ? (
                <Grid item xs={12} style={{ padding: 8 }}>
                  <div style={{ maxWidth: 600, margin: "auto" }}>
                    <Typography variant="h6" color="textPrimary">
                      Summary
                    </Typography>
                    {cartLoading ? (
                      <CircularProgress
                        style={{ color: "#2196f3", height: 20, width: 20 }}
                      />
                    ) : (
                      <Grid container justify="space-between">
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            Working Sessions x{quantity}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Typography variant="body2">
                            {formatter.format(cart.subTotal / 100)}
                          </Typography>
                        </Grid>
                        {cart.coupons &&
                          cart.coupons.map((c) => [
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                {c.token} Coupon ({c.percent}% Off)
                              </Typography>
                            </Grid>,
                            <Grid item xs={6}>
                              <Typography style={{ textAlign: "right" }}>
                                -
                                {formatter.format(
                                  (cart.subTotal / 100) * (c.percent / 100)
                                )}
                              </Typography>
                            </Grid>,
                          ])}
                        {cart.discount ? (
                          <Grid item xs={6}>
                            <Typography variant="body2">
                              Total Discount
                            </Typography>
                          </Grid>
                        ) : (
                          []
                        )}
                        {cart.discount ? (
                          <Grid item xs={6}>
                            <Typography style={{ textAlign: "right" }}>
                              -{formatter.format(cart.discount / 100)}
                            </Typography>
                          </Grid>
                        ) : (
                          []
                        )}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" color="textPrimary">
                            Total
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Typography variant="h6" color="textPrimary">
                            {formatter.format(cart.total / 100)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Grid>
              ) : (
                []
              )}
              {selectedID && stepIndex < 1 ? (
                <Grid item xs={12} style={{ textAlign: "center", padding: 8 }}>
                  <Grow in>
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "none",
                        background: "#2196f3",
                        color: "white",
                      }}
                      onClick={() => {
                        this.setState(
                          (prevState) => ({
                            stepIndex: prevState.stepIndex + 1,
                          }),
                          this.validateCart.bind(this)
                        );
                      }}
                    >
                      Continue
                    </Button>
                  </Grow>
                </Grid>
              ) : (
                []
              )}
              {selectedID !== 2 && stepIndex === 1 ? (
                <Grid
                  item
                  xs={12}
                  style={{
                    maxWidth: 600,
                    margin: "auto",
                    textAlign: "right",
                    padding: 8,
                    position: "sticky",
                    bottom: 0,
                  }}
                >
                  <Grow in>
                    <Button
                      variant="contained"
                      disabled={selectedCard === undefined && cart.total > 0}
                      fullWidth
                      style={{
                        textTransform: "none",
                        background: "#2196f3",
                        color: "white",
                        opacity: selectedCard ? 1 : 0.3,
                      }}
                      onClick={this.payCart.bind(this)}
                    >
                      <i class="fad fa-credit-card-front p-right"></i> Pay Now
                    </Button>
                  </Grow>
                </Grid>
              ) : (
                []
              )}
              {selectedID === 2 && stepIndex === 1 ? (
                <Grid
                  item
                  xs={12}
                  style={{
                    maxWidth: 600,
                    margin: "auto",
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Grow in>
                    <Button
                      variant="contained"
                      disabled={selectedCard === undefined}
                      style={{
                        textTransform: "none",
                        background: "#2196f3",
                        color: "white",
                        opacity: selectedCard ? 1 : 0.3,
                      }}
                      onClick={this.payCart.bind(this)}
                    >
                      Upgrade
                    </Button>
                  </Grow>
                </Grid>
              ) : (
                []
              )}
              {stepIndex === 2 ? (
                <Grid
                  item
                  xs={12}
                  style={{
                    maxWidth: 600,
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "20%",
                    padding: 8,
                  }}
                >
                  <Grow in>
                    <div>
                      <CircularProgress
                        style={{ color: "#2196f3", height: 40, width: 40 }}
                      />
                      <Typography variant="h6" color="textPrimary">
                        System Processing
                      </Typography>
                      <Typography variant="body2">
                        Thank you for your patience
                      </Typography>
                    </div>
                  </Grow>
                </Grid>
              ) : (
                []
              )}
              {stepIndex === 3 ? (
                <Grid
                  item
                  xs={12}
                  style={{
                    maxWidth: 600,
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "20%",
                    padding: 8,
                  }}
                >
                  <Grow in>
                    <div>
                      <span
                        role="img"
                        style={{ fontSize: 80 }}
                        aria-label="thumb up"
                      >
                        👍
                      </span>
                      <Typography variant="h6" color="textPrimary">
                        <b>You are all set!</b>
                      </Typography>
                      <Typography variant="body2">
                        Thank you for your purchase.
                        <br />
                        You should have received a confirmation email.
                      </Typography>
                      <br />
                      <Button
                        variant="contained"
                        onClick={this.closeUpgrade.bind(this)}
                        style={{
                          color: "white",
                          background: "#2196f3",
                          textTransform: "none",
                        }}
                      >
                        Continue on Rhapsody
                      </Button>
                    </div>
                  </Grow>
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </div>
        </Dialog>
        <NoRender open={open || upgradeOpen} node={children} />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(withStyles(styles)(SubscriptionCenter)));
