import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class MemberStatus extends PureComponent {
  static propTypes = {
    verified: PropTypes.bool,
    small: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  showVerifiedNotice(e) {
    const { NotificationCenter } = this.context;
    e.stopPropagation();
    e.preventDefault();
    NotificationCenter.sweetAlert(
      {
        title: "About Verified Musicians",
        body:
          "An verified musican has already participated to a Rhapsody session. It means that a contractor hired the person and didn't complain about the authenticity of provided info.",
        timestamp: new Date().getTime(),
      },
      {
        confirm: {
          label: "Got It",
          level: "info",
        },
      }
    );
  }

  showUnverifiedNotice(e) {
    const { NotificationCenter } = this.context;
    e.stopPropagation();
    e.preventDefault();
    NotificationCenter.sweetAlert(
      {
        title: "About Unverified Musicians",
        body:
          "An unverified musican has not already participated to a Rhapsody session. We cannot guarantee the authenticity of provided info.",
        timestamp: new Date().getTime(),
      },
      {
        confirm: {
          label: "Got It",
          level: "info",
        },
      }
    );
  }

  render() {
    const { verified, small } = this.props;

    if (verified) {
      if (small) {
        return (
          <Avatar
            style={{
              background: "#4caf50",
              width: 14,
              height: 14,
              cursor: "pointer",
            }}
            onClick={this.showVerifiedNotice.bind(this)}
          >
            <Check style={{ color: "white", fontSize: 12 }} />
          </Avatar>
        );
      }

      return (
        <Chip
          onClick={this.showVerifiedNotice.bind(this)}
          style={{ background: "#4caf50", color: "white", height: 24 }}
          avatar={
            <Avatar style={{ background: "#388e3c", height: 24, width: 24 }}>
              <Check style={{ color: "white", fontSize: 18 }} />
            </Avatar>
          }
          label="Verified"
        />
      );
    }

    if (small) {
      return (
        <Avatar
          style={{
            background: "#ff9800",
            width: 14,
            height: 14,
            cursor: "pointer",
          }}
          onClick={this.showUnverifiedNotice.bind(this)}
        >
          <Warning style={{ color: "white", fontSize: 12 }} />
        </Avatar>
      );
    }

    return (
      <Chip
        onClick={this.showUnverifiedNotice.bind(this)}
        style={{ background: "#ff9800", color: "white", height: 24 }}
        avatar={
          <Avatar style={{ background: "#f57c00", height: 24, width: 24 }}>
            <Warning style={{ color: "white", fontSize: 14 }} />
          </Avatar>
        }
        label="Unverified"
      />
    );
  }
}

export default withStyles(styles)(MemberStatus);
