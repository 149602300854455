export default (theme) => ({
  chip: {
    height: 24,
    color: "white",
    borderColor: "rgba(0,0,0,0) !important",
    fontWeight: theme.typography.fontWeightRegular,
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing(1) * 1.5,
  },
  root: {
    color: "white !important",
  },
});
