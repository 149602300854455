import moment from "moment";
import React from "react";

export default function Countdown({ date, prefix = "" }) {
  const [countDown, setCountDown] = React.useState(0);
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(moment(date).countdown().toString());
    }, 1000);
    return () => clearInterval(interval);
  }, []); //eslint-disable-line
  return (
    <div>
      {prefix}
      {countDown}
    </div>
  );
}
