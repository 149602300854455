import { SYNC_INPUT_HANDLE_CHANGE } from "constants/syncInput";

export default (state = "", action) => {
  switch (action.type) {
    case SYNC_INPUT_HANDLE_CHANGE:
      return action.value;
    default:
      return state;
  }
};
