import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import { Help } from "components";
import DateTime from "components/DateTime";
// custom
import Tune from "components/Tune";
import FormattedPhone from "hooks/FormattedPhone";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class B4 extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    api: PropTypes.array,
    studioSession: PropTypes.object,
    refresh: PropTypes.func,
    conciergeReadOnly: PropTypes.bool,
    studioSessionTunes: PropTypes.array,
    studios: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { studioSession } = this.props;
    this.state = {
      ...studioSession,
      loading: false,
      newTuneLoading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { api, refresh, studioSession } = this.props;

    if (studioSession[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await api.updateStudioSession(studioSession.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const {
      classes,
      api,
      studioSession,
      studioSessionTunes,
      conciergeReadOnly,
      refresh,
    } = this.props;

    const {
      loading,
      wageAgreement,
      afmProjectNumber,
      totalSessionHours,
      afmLocal,
      hoursOfEmployment,
      producer,
      newTuneLoading,
      productionTypeID,
      sessionMediumID,
      producerAddress,
      date,
      lowBudget,
      originalReportForm,
      originalRecordingDate,
      newUseSource,
      pictureShow,
      titleOfNewUse,
      memo,
      engineer,
      conductor,
      payrollNumber,
      title,
      newUse,
      recorded,
      archived,
      description,
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2">Title:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      fullWidth
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("title")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("title");
                      }}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={title}
                      onChange={this.handleChange("title")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2">Payroll #:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      fullWidth
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("payrollNumber");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("payrollNumber")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={payrollNumber}
                      onChange={this.handleChange("payrollNumber")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Project Description
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Contract Date:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DateTime
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("date");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("date")}
                      noTime
                      className={archived ? undefined : classes.inputBase}
                      date={date}
                      onChange={this.handleChange("date")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Record Co:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.customer.name}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Label:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.project.label}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Rep:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.customer.contact}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Address:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={`${studioSession.customer.address} ${studioSession.customer.city} ${studioSession.customer.state} ${studioSession.customer.zipcode}`}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Phone:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormattedPhone value={studioSession.customer.phone} />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Artist or Group:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.project.name}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Industry Project#:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.project.industryProjectNumber}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Project#:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("afmProjectNumber");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("afmProjectNumber")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={afmProjectNumber}
                      onChange={this.handleChange("afmProjectNumber")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Additional Info
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Memo:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("memo");
                      }}
                      disabled={archived || conciergeReadOnly}
                      multiline
                      fullWidth
                      onBlur={() => this.save("memo")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={memo}
                      onChange={this.handleChange("memo")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Tunes / Pieces List
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <Typography variant="caption" color="textSecondary">
                      Letter
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="caption" color="textSecondary">
                      Minutes
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="caption" color="textSecondary">
                      Title
                    </Typography>
                  </Grid>
                </Grid>
                {studioSessionTunes.map((sst) => (
                  <Tune
                    key={`studioSessionTunes_${sst.id}`}
                    tune={sst}
                    updateTune={api.updateTune}
                    deleteTune={api.deleteTune}
                    refresh={refresh}
                    disabled={archived}
                  />
                ))}
                {newTuneLoading ? (
                  <Grid container justify="center">
                    <Grid item>
                      <CircularProgress color="secondary" />
                    </Grid>
                  </Grid>
                ) : (
                  <div />
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={archived}
                  onClick={async () => {
                    this.setState({ newTuneLoading: true });
                    await api.createTune({
                      sessionID: studioSession.id,
                    });
                    this.setState({ newTuneLoading: false });
                    refresh();
                  }}
                >
                  Add Track
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Session Description
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Wage Scale Agreement:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("wageAgreement");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("wageAgreement")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={wageAgreement}
                      onChange={this.handleChange("wageAgreement")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Local#:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("afmLocal");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("afmLocal")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={afmLocal}
                      onChange={this.handleChange("afmLocal")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2"># Musicians:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled
                      fullWidth
                      value={studioSession.musicianCount}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Hrs of Employment:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("hoursOfEmployment");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("hoursOfEmployment")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={hoursOfEmployment}
                      onChange={this.handleChange("hoursOfEmployment")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Total Session Hrs:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("totalSessionHours");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("totalSessionHours")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={totalSessionHours}
                      onChange={this.handleChange("totalSessionHours")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Producer:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("producer");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("producer")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={producer}
                      onChange={this.handleChange("producer")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Address:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("producerAddress");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("producerAddress")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={producerAddress}
                      onChange={this.handleChange("producerAddress")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Engineer:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("engineer");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("engineer")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={engineer}
                      onChange={this.handleChange("engineer")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Conductor:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("conductor");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("conductor")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={conductor}
                      onChange={this.handleChange("conductor")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Help
                      title="Internal Notes:"
                      help="Anything useful for your own organization"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      disabled={loading}
                      fullWidth
                      rows={3}
                      rowsMax={100}
                      multiline
                      onBlur={() => this.save("description")}
                      style={{ background: "rgba(255,235,59,0.1)" }}
                      className={classes.inputBase}
                      value={description || ""}
                      onChange={this.handleChange("description")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Dubbing, New Use, Limited Pressing Upgrade, Other
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Original Report Form #:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("originalReportForm");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("originalReportForm")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={originalReportForm}
                      onChange={this.handleChange("originalReportForm")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Original Recording Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DateTime
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          this.save("originalRecordingDate");
                      }}
                      disabled={archived || conciergeReadOnly}
                      onBlur={() => this.save("originalRecordingDate")}
                      className={archived ? undefined : classes.inputBase}
                      date={originalRecordingDate}
                      onChange={this.handleChange("originalRecordingDate")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">New Use Source:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("newUseSource");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("newUseSource")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={newUseSource}
                      onChange={this.handleChange("newUseSource")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Picture / Show:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("pictureShow");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("pictureShow")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={pictureShow}
                      onChange={this.handleChange("pictureShow")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Title of New Use Release:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("titleOfNewUse");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("titleOfNewUse")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={titleOfNewUse}
                      onChange={this.handleChange("titleOfNewUse")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={loading ? classes.paperLoading : classes.paper}>
                <Typography variant="h6" color="textPrimary">
                  Production & Payment Info
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Production Type:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        inputProps={{
                          name: "Project",
                          id: "project-simple",
                        }}
                        value={productionTypeID}
                        disabled={recorded || archived}
                        onChange={(e) => {
                          this.state.productionTypeID = e.target.value; //eslint-disable-line
                          this.save("productionTypeID");
                        }}
                      >
                        {studioSession &&
                          studioSession.sessionType &&
                          studioSession.sessionType.productionTypes &&
                          studioSession.sessionType.productionTypes.map(
                            (pt) => (
                              <MenuItem key={`prodType_${pt.id}`} value={pt.id}>
                                {pt.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Payment Type:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        inputProps={{
                          name: "Project",
                          id: "project-simple",
                        }}
                        disabled={recorded || archived}
                        value={sessionMediumID}
                        onChange={(e) => {
                          this.state.sessionMediumID = e.target.value; //eslint-disable-line
                          this.save("sessionMediumID");
                        }}
                      >
                        {studioSession &&
                          studioSession.sessionType &&
                          studioSession.sessionType.sessionMedia &&
                          studioSession.sessionType.sessionMedia.map((sm) => (
                            <MenuItem
                              key={`sessionMedia_${sm.id}`}
                              value={sm.id}
                            >
                              {sm.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">New Use:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("newUse");
                      }}
                      disabled={archived || conciergeReadOnly}
                      fullWidth
                      onBlur={() => this.save("newUse")}
                      className={archived ? undefined : classes.inputBase}
                      defaultValue={newUse}
                      onChange={this.handleChange("newUse")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Low Budget Recording:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Switch
                      checked={lowBudget}
                      onChange={(e) => {
                        this.state.lowBudget = e.target.checked; //eslint-disable-line
                        this.save("lowBudget");
                      }}
                      disabled={archived}
                      color="secondary"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(B4);
