// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// component
import WrapperPayrollPage from "../component/WrapperPayrollPage";

const mapStateToProps = (state) => ({
  user: state.user,
  customers: state.customers,
});

const mapDispatchToProps = (dispatch) => ({
  getPayrollById: (...args) => dispatch(Actions.getPayrollById(...args)),
  updatePayroll: (...args) => dispatch(Actions.updatePayroll(...args)),
  getPayrollStudioSessions: (...args) =>
    dispatch(Actions.getPayrollStudioSessions(...args)),
  getPayrollProjects: (...args) =>
    dispatch(Actions.getPayrollProjects(...args)),
  getCustomers: (...args) => dispatch(Actions.getCustomers(...args)),
  getCurrentCompany: (...args) => dispatch(Actions.getCurrentCompany(...args)),
});
class PayrollPage extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
    getPayrollById: PropTypes.func,
    updatePayroll: PropTypes.func,
    getPayrollStudioSessions: PropTypes.func,
    getPayrollProjects: PropTypes.func,
    getCustomers: PropTypes.func,
    customers: PropTypes.array,
    currentCompany: PropTypes.object,
    getCurrentCompany: PropTypes.func,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, getCurrentCompany } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      payrollID: urlParams.id,
      loading: true,
    };
    getCurrentCompany();
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { payrollID } = this.state;
    const {
      getPayrollById,
      getPayrollStudioSessions,
      getPayrollProjects,
      getCustomers,
    } = this.props;

    const resps = await Promise.all([
      getPayrollById(payrollID),
      getPayrollStudioSessions(payrollID),
      getPayrollProjects(payrollID),
      getCustomers(),
    ]);
    this.setState({
      payroll: resps[0].payload,
      projects: resps[2].payload,
      studioSessions: resps[1].payload,
      loading: false,
      refreshKey: new Date().getTime(),
    });
  }

  render() {
    const {
      location,
      history,
      user,
      updatePayroll,
      customers,
      currentCompany,
    } = this.props;

    const {
      urlParams,
      loading,
      payroll,
      refreshKey,
      projects,
      studioSessions,
    } = this.state;

    return (
      <WrapperPayrollPage
        history={history}
        location={location}
        currentCompany={currentCompany}
        urlParams={urlParams}
        payroll={payroll}
        organizations={user.organizations}
        loading={loading}
        refreshKey={refreshKey}
        studioSessions={studioSessions}
        customers={customers}
        projects={projects}
        refresh={this.refresh.bind(this)}
        updatePayroll={updatePayroll}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PayrollPage);
