export default (theme) => ({
  container: {
    padding: 5,
    border: "solid 1px rgba(155,155,155,0.3)",
    width: 288,
    position: "relative",
    transition: "box-shadow 0.2s ease-in-out, width 0.2s ease-in-out",
  },
  loading: {
    backdropFilter: "blur(10px)",
    WebkitBackdropFilter: "blur(10px)",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(155,155,155,0.8)",
  },
  addChip: {
    height: 24,
    color: "rgba(155,155,155, 1)",
    backgroundColor: theme.palette.background.paper,
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
    border: "dashed 1px rgba(155,155,155,0.3)",
  },
  chip: {
    height: 24,
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.text.default,
    border: `solid 1px ${theme.palette.text.default}`,
    backgroundColor: theme.palette.background.paper,
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  widget: {
    background: theme.palette.background.paper,
    borderRadius: 4,
    border: "solid 1px rgba(155, 155, 155, 0.3)",
    padding: 10,
    height: "calc(100% - 20px)",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
  },
  emptyChip: {
    height: 24,
    fontWeight: 600,
    color: theme.palette.text.default,
    border: `solid 1px ${theme.palette.text.default}`,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.background.default
        : "#f5f5f5",
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  avatar: {
    backgroundColor: theme.palette.background.paper,
  },
  menuItemDisabled: {
    opacity: 1,
  },
  profileButton: {
    padding: 4,
    margin: 4,
    background: "#2196f3",
    color: "#ffffff",
    "&:hover": {
      background: "#1976d2",
    },
  },
  secondaryProfileButton: {
    padding: 4,
    margin: 4,
    background: "#e0e0e0",
    color: theme.palette.type === "dark" ? "#212121" : "#9e9e9e",
    "&:hover": {
      background: theme.palette.type === "dark" ? "#bdbdbd" : "#111111",
    },
  },
  profileButtonIcon: {
    fontSize: 18,
  },
  button: {
    border: "solid 1px rgba(155,155,155,0.3)",
    background: "black",
    height: 25,
    width: 25,
    color: "white",
  },
  jobHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.type === "dark" ? "#ffc107" : "#001E3C",
    color: theme.palette.type === "dark" ? "black" : "white",
  },
  jobSubheader: {
    background: theme.palette.type === "dark" ? "#ffc107" : "#001E3C",
    padding: "0px 8px 8px 8px",
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
    color: theme.palette.type === "dark" ? "black" : "white",
  },
  instrument: {
    border: "solid 1px rgba(155,155,155,0)",
    padding: "0px 2px",
    borderRadius: 4,
    "&:hover": {
      borderColor: "rgba(155,155,155,0.4)",
      background: "rgba(255,255,255,0.4)",
      "&::after": {
        content: '" ▾"',
      },
    },
  },
});
