import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
// material-ui
import { withStyles } from "@material-ui/styles";
import { formatDate } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class BulkArchive extends Component {
  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    close: PropTypes.func,
    refresh: PropTypes.func,
    studioSessions: PropTypes.array,
  };

  state = {
    toArchive: [],
    loading: false,
    count: 0,
    error: 0,
    done: false,
  };

  addSession(s) {
    const { toArchive } = this.state;
    let _toArchive = [...toArchive];
    _toArchive.push(s);
    this.setState({ toArchive: _toArchive });
  }

  removeSession(s) {
    const { toArchive } = this.state;
    let _toArchive = [...toArchive];
    if (_toArchive.length === 1) return this.setState({ toArchive: [] });
    const index = _toArchive.findIndex((w) => w.id === s.id);
    if (index) {
      _toArchive.splice(index, 1);
    }
    this.setState({ toArchive: _toArchive });
  }

  async archive() {
    const { updateStudioSession, close, refresh } = this.props;
    const { toArchive } = this.state;
    this.setState({ loading: true });
    const calls = [];
    for (const key in toArchive) {
      if (toArchive.hasOwnProperty(key)) {
        const ss = toArchive[key];
        calls.push(updateStudioSession(ss.id, { archived: true }));
      }
    }
    await Promise.all(calls);
    close();
    refresh();
  }

  render() {
    const { toArchive, loading, done } = this.state;
    const { close, open, studioSessions } = this.props;

    if (loading) {
      return (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
            <br />
            <Typography variant="title">Archiving Sessions...</Typography>
          </DialogTitle>
        </Dialog>
      );
    }

    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Archive Work Sessions
          <br />
          {!done ? (
            <>
              <Typography
                style={{ cursor: "pointer", textDecoration: "underline" }}
                variant="caption"
                color="textSecondary"
                onClick={() => this.setState({ toArchive: studioSessions })}
              >
                Select All
              </Typography>
              <Typography
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  marginLeft: 8,
                }}
                variant="caption"
                color="textSecondary"
                onClick={() => this.setState({ toArchive: [] })}
              >
                Deselect All
              </Typography>
            </>
          ) : (
            []
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!done ? (
              <List>
                {studioSessions &&
                  studioSessions.map((s) => (
                    <ListItem
                      button
                      key={s.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (toArchive.find((w) => w.id === s.id)) {
                          this.removeSession(s);
                        } else {
                          this.addSession(s);
                        }
                      }}
                    >
                      <ListItemAvatar>
                        <Checkbox
                          color="secondary"
                          checked={
                            toArchive.find((w) => w.id === s.id) !== undefined
                          }
                          onClick={(e) => {
                            if (toArchive.find((w) => w.id === s.id)) {
                              this.removeSession(s);
                            } else {
                              this.addSession(s);
                            }
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{ display: "flex" }}>
                            <Typography variant="body2" color="textPrimary">
                              {s.title || "Untitled"} ({s.projectName})
                            </Typography>
                          </div>
                        }
                        secondary={formatDate(s.dateFromUTC, s.tzName)}
                      />
                    </ListItem>
                  ))}
              </List>
            ) : (
              []
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} style={{ textTransform: "none" }}>
            Close
          </Button>
          <Button
            color="secondary"
            variant="contained"
            autoFocus
            style={{ textTransform: "none" }}
            disabled={!toArchive.length}
            onClick={this.archive.bind(this)}
          >
            Archive{" "}
            {toArchive.length
              ? `${toArchive.length} Session${toArchive.length > 1 ? "s" : ""}`
              : ""}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(BulkArchive);
