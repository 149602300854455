import { Checkbox, FormControlLabel } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import Back from "@material-ui/icons/ArrowBack";
import Person from "@material-ui/icons/Person";
import { withStyles } from "@material-ui/styles";
import LinkChip from "components/LinkChip";
import Loading from "components/Loading";
import MemberStatus from "components/MemberStatus";
import ProjectsList from "components/ProjectsList";
import WorkSessionPresentationSelect from "components/WorkSessionPresentationSelect";
import WorkSessions from "components/WorkSessions";
import { ORGANIZATION_ENABLED } from "config";
// constants
import { MUSICIAN, STUDIO_SESSION_DETAIL } from "constants/routes";
import { buildForURL, updateURLParameter } from "helpers";
import MusicianGroupManager from "hooks/MusicianGroupManager";
import MusicianInstrumentManager from "hooks/MusicianInstrumentManager";
import PhoneInput from "hooks/PhoneInput";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";
class WrapperMusicianPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    refresh: PropTypes.func,
    updateMusician: PropTypes.func,
    createMusicianInstrument: PropTypes.func,
    deleteMusicianInstrument: PropTypes.func,
    joinRequest: PropTypes.func,
    loading: PropTypes.bool,
    musician: PropTypes.object,
    musicians: PropTypes.array,
    projects: PropTypes.array,
    studioSessions: PropTypes.array,
    organizations: PropTypes.array,
    customers: PropTypes.array,
    instruments: PropTypes.array,
    refreshKey: PropTypes.number,
    currentCompany: PropTypes.object,
    getGroups: PropTypes.func,
    createMusicianGroup: PropTypes.func,
    deleteMusicianGroup: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams, musician } = this.props;
    this.state = {
      ...musician,
      index: urlParams.index ? Number(urlParams.index) : 0,
      anchorEl: null,
      loading: false,
      joinDetailOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.setState({
        ...nextProps.musician,
      });
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updateMusician, refresh, musician } = this.props;

    if (musician[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateMusician(musician.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  getStepContent() {
    const {
      studioSessions,
      history,
      classes,
      organizations,
      instruments,
      musician,
      refresh,
      projects,
      createMusicianInstrument,
      deleteMusicianInstrument,
      currentCompany,
      createMusicianGroup,
      deleteMusicianGroup,
    } = this.props;

    const {
      index,
      loading,
      firstName,
      middleName,
      nickName,
      lastName,
      email,
      address,
      zipcode,
      city,
      state,
      organizationID,
      cardNumber,
      phone,
      contactBySMS,
      ssn,
      ein,
      active,
      companyName,
      comments,
      star,
    } = this.state;

    switch (index) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Grid container>
                      <Grid item>
                        <Avatar
                          className={classes.avatarPerson}
                          src={musician.avatar}
                          style={{ height: 80, width: 80 }}
                        >
                          <Person style={{ color: "#D6D6D6", fontSize: 40 }} />
                        </Avatar>
                      </Grid>
                      <Grid
                        item
                        style={{
                          marginLeft: 8,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            variant="h6"
                            color="textPrimary"
                            display="block"
                          >
                            {musician.nickName ||
                              `${musician.firstName} ${musician.lastName}`}
                          </Typography>
                          {musician.userUUID ? (
                            <Typography variant="body2" display="block">
                              Is a Rhapsody Member
                            </Typography>
                          ) : (
                            []
                          )}
                          {musician.userUUID ? (
                            <div>
                              <MemberStatus verified={musician.verified} />
                            </div>
                          ) : (
                            []
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      style={{ marginTop: 20 }}
                    >
                      <Grid item xs={5}>
                        <Typography variant="body2">Favorite:</Typography>
                        <Typography variant="caption" color="textSecondary">
                          Set this musician as a favorite. Visible by you only.
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<FavoriteBorder />}
                              checkedIcon={<Favorite />}
                              checked={star}
                              style={{ color: star ? "#e91e63" : "#9e9e9e" }}
                              name="checkedH"
                              onChange={(e) => {
                                this.state.star = e.target.checked; // eslint-disable-line
                                this.save("star");
                              }}
                            />
                          }
                          label=""
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      style={{ marginTop: 20 }}
                    >
                      <Grid item xs={5}>
                        <Typography variant="body2">Active:</Typography>
                        <Typography variant="caption" color="textSecondary">
                          Unactive musicians won't show up in your hiring
                          suggestions
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Switch
                          checked={active}
                          onChange={(e) => {
                            this.state.active = e.target.checked; // eslint-disable-line
                            this.save("active");
                          }}
                          color="secondary"
                          disabled={loading}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">First Name:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("firstName");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("firstName")}
                          className={classes.inputBase}
                          value={firstName || ""}
                          onChange={this.handleChange("firstName")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">Middle Name:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("middleName");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("middleName")}
                          className={classes.inputBase}
                          value={middleName || ""}
                          onChange={this.handleChange("middleName")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">Last Name:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("lastName");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("lastName")}
                          className={classes.inputBase}
                          value={lastName || ""}
                          onChange={this.handleChange("lastName")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">Nickname:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("nickName");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("nickName")}
                          className={classes.inputBase}
                          value={nickName || ""}
                          onChange={this.handleChange("nickName")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">Company Name:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("companyName");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("companyName")}
                          className={classes.inputBase}
                          value={companyName || ""}
                          onChange={this.handleChange("companyName")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">Email:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("email");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("email")}
                          className={classes.inputBase}
                          value={email || ""}
                          onChange={this.handleChange("email")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">Phone:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <PhoneInput
                          onChange={this.handleChange("phone")}
                          value={phone}
                          variant="outlined"
                          fullWidth
                          label="Phone"
                          size="small"
                          className={classes.textField}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("phone");
                          }}
                          InputProps={{ style: { fontSize: "0.875rem" } }}
                          disabled={loading}
                          onBlur={() => this.save("phone")}
                        />
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={contactBySMS}
                                onChange={(e) => {
                                  this.state.contactBySMS = e.target.checked; // eslint-disable-line
                                  this.save("contactBySMS");
                                }}
                                name="checkedB"
                                size="small"
                              />
                            }
                            label={
                              <Typography variant="caption">
                                Agree to be contacted by SMS
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">Address:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("address");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("address")}
                          className={classes.inputBase}
                          value={address || ""}
                          onChange={this.handleChange("address")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">Zipcode:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("zipcode");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("zipcode")}
                          className={classes.inputBase}
                          value={zipcode || ""}
                          onChange={this.handleChange("zipcode")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">City:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("city");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("city")}
                          className={classes.inputBase}
                          value={city || ""}
                          onChange={this.handleChange("city")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">State:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("state");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("state")}
                          className={classes.inputBase}
                          value={state || ""}
                          onChange={this.handleChange("state")}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Typography variant="h6" color="textPrimary">
                      Instruments
                    </Typography>
                    <MusicianInstrumentManager
                      musicianInstruments={musician.instruments}
                      onDelete={async (i) => {
                        await deleteMusicianInstrument(musician.id, {
                          instrumentID: i.id,
                        });
                        refresh();
                      }}
                      onAdd={async (i) => {
                        await createMusicianInstrument(musician.id, {
                          instrumentID: i.id,
                          variation: i.variation,
                        });
                        refresh();
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Typography variant="h6" color="textPrimary">
                      Comments
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Private. Visible by you only.
                    </Typography>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        <InputBase
                          disabled={loading}
                          fullWidth
                          rows={3}
                          rowsMax={100}
                          multiline
                          onBlur={() => this.save("comments")}
                          style={{ background: "rgba(255,235,59,0.1)" }}
                          className={classes.inputBase}
                          value={comments || ""}
                          onChange={this.handleChange("comments")}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Typography variant="h6" color="textPrimary">
                      Groups
                    </Typography>
                    <MusicianGroupManager
                      musicianGroups={musician.groups}
                      onDelete={async (relationID) => {
                        await deleteMusicianGroup(relationID);
                        refresh();
                      }}
                      onAdd={async (group) => {
                        await createMusicianGroup({
                          groupID: group.id,
                          musicianID: musician.id,
                        });
                        refresh();
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    className={
                      loading ? classes.paperLoading : classes.inputPaper
                    }
                  >
                    <Typography variant="h6" color="textPrimary">
                      Identification
                    </Typography>
                    <Typography variant="caption">
                      <i class="fad fa-shield p-right"></i>
                      Privacy: Musician personal information is encrypted and
                      securely stored. Only you can access and/or edit.
                    </Typography>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">SSN:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("ssn");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("ssn")}
                          className={classes.inputBase}
                          value={ssn || ""}
                          onChange={this.handleChange("ssn")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">EIN:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("ein");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("ein")}
                          className={classes.inputBase}
                          value={ein || ""}
                          onChange={this.handleChange("ein")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2">Card Number:</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("cardNumber");
                          }}
                          disabled={loading}
                          fullWidth
                          onBlur={() => this.save("cardNumber")}
                          className={classes.inputBase}
                          value={cardNumber || ""}
                          onChange={this.handleChange("cardNumber")}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                {ORGANIZATION_ENABLED ? (
                  <Grid item xs={12}>
                    <Paper
                      className={
                        loading ? classes.paperLoading : classes.inputPaper
                      }
                    >
                      <Typography variant="h6" color="textPrimary">
                        Related
                      </Typography>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={5}>
                          <Typography variant="body2">Organization:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <Select
                              inputProps={{
                                name: "Musician",
                                id: "musician-simple",
                              }}
                              value={organizationID}
                              onChange={(e) => {
                                this.state.organizationID = e.target.value; // eslint-disable-line
                                this.save("organizationID");
                              }}
                            >
                              {organizations.map((w) => (
                                <MenuItem
                                  key={`organization_${w.id}`}
                                  value={w.id}
                                >
                                  {w.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <div style={{ width: "100%" }}>
            <ProjectsList
              history={history}
              projects={projects}
              currentCompany={currentCompany}
            />
          </div>
        );
      case 2:
        return (
          <WorkSessions
            workSessions={studioSessions}
            history={history}
            onSelect={(session) =>
              history.push({
                pathname: STUDIO_SESSION_DETAIL,
                search: buildForURL({
                  id: session.id,
                  back: `${window.location.pathname}${window.location.search}`,
                }),
              })
            }
          />
        );
      default:
        return (
          <Typography variant="h6" color="textPrimary">
            Coming Soon
          </Typography>
        );
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  async joinRequest() {
    const { joinRequest, musician, refresh } = this.props;
    const { NotificationCenter } = this.context;
    await joinRequest(musician.id);
    this.setState({ joinDetailOpen: false });
    refresh();
    NotificationCenter.sweetAlert(
      {
        title: "Done!",
        subtitle: "The musician just received an email join request.",
        timestamp: new Date().getTime(),
        success: true,
      },
      {
        cancel: {
          label: "Ok",
          level: "default",
          callback: () => console.log("Callback"),
        },
      }
    );
  }

  render() {
    const {
      classes,
      history,
      loading,
      musician,
      // joinRequest,
    } = this.props;

    const { index, joinDetailOpen } = this.state;

    if (loading) {
      return (
        <div className="page">
          <div className={`${classes.background}`}>
            <Loading message={"Loading Musician"} />
          </div>
        </div>
      );
    }

    return (
      <div className="page">
        {/* <Helmet>
          <title>{musician.firstName} {musician.lastName}</title>
          <meta name="musician" content="musician page" />
        </Helmet> */}
        <Paper
          elevation={10}
          style={{
            borderRadius: 0,
          }}
          className={classes.subHeader}
        >
          <Hidden smDown>
            <Grid container style={{ padding: 10 }} justify="space-between">
              <Grid item style={{ padding: 6 }}>
                <Breadcrumbs
                  arial-label="Breadcrumb"
                  separator={"-"}
                  style={{
                    color: "white",
                  }}
                >
                  <Chip
                    label={"All Musicians"}
                    className={classes.backChip}
                    onClick={() => history.push(MUSICIAN)}
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Back
                          className={classes.icon}
                          style={{ color: "white" }}
                        />
                      </Avatar>
                    }
                  />
                  <LinkChip
                    icon={<i class="fad fa-user-music"></i>}
                    label={`${musician.firstName} ${musician.lastName}`}
                    history={history}
                  />
                </Breadcrumbs>
              </Grid>
            </Grid>
          </Hidden>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={index}
              className={classes.subHeader}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
            >
              <Tab
                label="General"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  label: index === 0 ? classes.labelSelected : classes.label,
                }}
              />
              <Tab
                label="Projects"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  label: index === 1 ? classes.labelSelected : classes.label,
                }}
              />
              <Tab
                label="Calendar"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                  label: index === 2 ? classes.labelSelected : classes.label,
                }}
              />
            </Tabs>
            {index === 2 ? <WorkSessionPresentationSelect /> : []}
          </div>
        </Paper>
        <div className={`${classes.background}`}>{this.getStepContent()}</div>
        <Dialog
          onClose={() => this.setState({ joinDetailOpen: false })}
          open={joinDetailOpen}
          aria-labelledby="simple-dialog-title"
        >
          <Grid style={{ padding: 20 }} container spacing={1}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img
                alt="mobile"
                src="https://storage.googleapis.com/rhapsody/logos/mobile%20app-01.png"
                style={{ height: 150 }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h5">
                Ask {musician && musician.firstName} to join Rhapsody
                <br />
                It's free for both of you!
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body" style={{ marginTop: 16 }}>
                <b>Rhapsody Musicians can use the mobile app to: </b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body">
                • Receive push notifications. Get answers faster!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body">
                • Manage their own private data. No more data entry!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body">
                • Track their sessions. They won't miss a recording!
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="caption" color="textSecondary">
                Note that musician's info will be <b>LOCKED</b> after they join.
              </Typography>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              style={{ textTransform: "none" }}
            >
              Close
            </Button>
            <Button
              onClick={this.joinRequest.bind(this)}
              variant="contained"
              style={{
                background: "#2196f3",
                color: "white",
                textTransform: "none",
              }}
              autoFocus
            >
              Ask to Join
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperMusicianPage);
