import { EMAIL } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import EmailPage from "./container/EmailPage";

export default function getSettingsRoute(userAuth) {
  const { account, rhapsody } = serviceRoles;
  const requireAuth = {
    [rhapsody.name]: [account.roles.user],
    [account.name]: [account.roles.superadmin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: EMAIL,
    withSidebar: true,
    withAppBar: true,
    component: EmailPage,
  };
}
