import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpenTwoTone";
import Warning from "@material-ui/icons/Warning";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class CheckValue extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    value: PropTypes.string,
    warning: PropTypes.bool,
    label: PropTypes.string,
    locked: PropTypes.bool,
  };

  render() {
    const { label, value, locked, warning } = this.props;

    let background;
    let color;
    if (warning) {
      background = "#ff9800";
      color = "white";
    }
    if (locked) {
      background = "#000000";
      color = "white";
    }

    let avatar = (
      <Avatar>
        <LockOpen />
      </Avatar>
    );
    if (warning) {
      avatar = (
        <Avatar style={{ color: "white", background: "#ef6c00" }}>
          <Warning />
        </Avatar>
      );
    }

    if (locked) {
      avatar = (
        <Avatar
          style={{ color: "white", background: "rgba(255, 255, 2555, 0.3)" }}
        >
          <Lock />
        </Avatar>
      );
    }

    return (
      <Chip
        avatar={avatar}
        style={{
          background,
          color,
        }}
        label={
          <span>
            {label}: <b>{value}</b>
          </span>
        }
      />
    );
  }
}

export default withStyles(styles)(CheckValue);
