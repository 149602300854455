import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "rhapsody";
const identifications = `/${service}/identifications`;

export function getIdentifications() {
  const url = `${identifications}`;
  return {
    method: GET,
    url,
  };
}

export function getIdentificationById(id) {
  const url = `${identifications}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateIdentification(id, body) {
  const url = `${identifications}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteIdentification(id) {
  const url = `${identifications}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createIdentification(body) {
  const url = `${identifications}`;
  return {
    method: POST,
    url,
    body,
  };
}
