import Chip from "@material-ui/core/Chip";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormSessionType from "components/FormSessionType";
// reactor
import Page from "components/Page";
// components
import SessionTypesList from "components/SessionTypesList";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperSessionTypePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    sessionTypes: PropTypes.array,
    createSessionType: PropTypes.func,
    deleteSessionType: PropTypes.func,
    updateSessionType: PropTypes.func,
    getSessionTypeSessionMedia: PropTypes.func,
    getSessionTypeCategories: PropTypes.func,
    getSessionTypeProductionTypes: PropTypes.func,
    createProductionType: PropTypes.func,
    deleteProductionType: PropTypes.func,
    createSessionMedia: PropTypes.func,
    deleteSessionMedia: PropTypes.func,
    createSessionCategory: PropTypes.func,
    deleteSessionCategory: PropTypes.func,
    createSessionRate: PropTypes.func,
    deleteSessionRate: PropTypes.func,
    getSessionTypeRates: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedSessionType: undefined,
      formSessionTypeOpen: false,
      sessionTypeLoading: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { sessionTypes } = this.props;

    switch (index) {
      case 0:
        return (
          <div>
            <Chip
              onClick={() => this.setState({ formSessionTypeOpen: true })}
              color="secondary"
              label="+ Session Type"
              style={{ marginBottom: 8 }}
            />
            <SessionTypesList
              sessionTypes={sessionTypes}
              onSelect={(selectedSessionType) => {
                this.state.selectedSessionType = selectedSessionType; // eslint-disable-line
                this.setState({
                  selectedSessionType,
                  formSessionTypeOpen: true,
                  sessionTypeLoading: true,
                });
                this.refreshSessionType(selectedSessionType);
              }}
            />
          </div>
        );
      default:
    }

    return [];
  }

  async refreshSessionType(sessionType) {
    const {
      getSessionTypeSessionMedia,
      getSessionTypeProductionTypes,
      getSessionTypeCategories,
      getSessionTypeRates,
    } = this.props;

    const { selectedSessionType } = this.state;

    const resps = await Promise.all([
      getSessionTypeSessionMedia(sessionType.id),
      getSessionTypeProductionTypes(sessionType.id),
      getSessionTypeCategories(sessionType.id),
      getSessionTypeRates(sessionType.id),
    ]);

    selectedSessionType.sessionMedia = resps[0].payload;
    selectedSessionType.productionTypes = resps[1].payload;
    selectedSessionType.sessionCategories = resps[2].payload;
    selectedSessionType.rates = resps[3].payload;

    this.setState({
      selectedSessionType,
      sessionTypeLoading: false,
    });
  }

  render() {
    const {
      classes,
      history,
      loading,
      createSessionType,
      deleteSessionType,
      updateSessionType,
      refresh,
      createProductionType,
      deleteProductionType,
      createSessionMedia,
      deleteSessionMedia,
      createSessionCategory,
      deleteSessionCategory,
      createSessionRate,
      deleteSessionRate,
    } = this.props;

    const {
      index,
      selectedSessionType,
      sessionTypeLoading,
      formSessionTypeOpen,
    } = this.state;

    return (
      <Page
        helmet="All Session Tabs"
        loading={loading}
        loadingMessage={"Loading Session Types"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormSessionType
          open={formSessionTypeOpen}
          sessionType={selectedSessionType}
          close={() => {
            this.setState({
              formSessionTypeOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedSessionType: undefined,
                }),
              200
            );
          }}
          createSessionType={createSessionType}
          deleteSessionType={deleteSessionType}
          updateSessionType={updateSessionType}
          sessionTypeLoading={sessionTypeLoading}
          refreshSessionType={this.refreshSessionType.bind(this)}
          createProductionType={createProductionType}
          deleteProductionType={deleteProductionType}
          createSessionMedia={createSessionMedia}
          deleteSessionMedia={deleteSessionMedia}
          createSessionCategory={createSessionCategory}
          deleteSessionCategory={deleteSessionCategory}
          createSessionRate={createSessionRate}
          deleteSessionRate={deleteSessionRate}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSessionTypePage);
