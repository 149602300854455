import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const customers = `/${service}/customers`;

export function getCustomers() {
  const url = `${customers}`;
  return {
    method: GET,
    url,
  };
}

export function getCustomerById(id) {
  const url = `${customers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getCustomerStudioSessions(id) {
  const url = `${customers}/${id}/workSessions`;
  return {
    method: GET,
    url,
  };
}

export function getCustomerProjects(id) {
  const url = `${customers}/${id}/projects`;
  return {
    method: GET,
    url,
  };
}

export function updateCustomer(id, body) {
  const url = `${customers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCustomer(id) {
  const url = `${customers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCustomer(body) {
  const url = `${customers}`;
  return {
    method: POST,
    url,
    body,
  };
}
