import { networkAction } from "helpers/network/networkAction";

import {
  GET_FILE_IMPORTS,
  GET_FILE_IMPORT_BY_ID,
  DELETE_FILE_IMPORT,
  UPDATE_FILE_IMPORT,
  CREATE_FILE_IMPORT,
} from "constants/fileImports";

import * as Api from "api";

export const getFileImports = () => async (dispatch) =>
  networkAction(dispatch, GET_FILE_IMPORTS, Api.getFileImports, []);

export const getFileImportById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_FILE_IMPORT_BY_ID, Api.getFileImportById, [id]);

export const deleteFileImport = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_FILE_IMPORT, Api.deleteFileImport, [id]);

export const createFileImport = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_FILE_IMPORT, Api.createFileImport, [body]);

export const updateFileImport = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_FILE_IMPORT, Api.updateFileImport, [id, body]);
