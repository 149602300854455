import { makeStyles } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as Actions from "actions";
// custom
import StudioSessionSection from "components/StudioSessionSection";
import { move, reorder } from "helpers/rhapsody";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import styles from "./styles";
const useStyle = makeStyles(styles);

export default function StudioSessionFamily({
  api,
  disabled,
  history,
  refresh,
  refreshFuncs,
  sectionRoles,
  sessionFamily,
  layoutPresets,
  studioSession,
  eventStatuses,
  subscription,
  instrumentSections,
  instruments,
  onMusicianEdit,
  refreshKey,
  conciergeReadOnly,
}) {
  const [lists, setLists] = React.useState([]);
  const dispatch = useDispatch();
  const classes = useStyle(styles);

  React.useEffect(() => {
    if (sessionFamily.sections) {
      const _lists = {};
      for (const key in sessionFamily.sections) {
        if (Object.hasOwnProperty.call(sessionFamily.sections, key)) {
          const section = sessionFamily.sections[key];
          _lists[section.sectionID] = section.jobs;
        }
      }
      setLists(_lists);
    }
  }, [sessionFamily]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const selectedJob = lists[source.droppableId][source.index];
    const newSectionOrder = destination.index;
    const newSectionID = Number(destination.droppableId);

    dispatch(
      Actions.updateStudioSessionMusician(selectedJob.id, {
        sectionOrder: newSectionOrder,
        sectionID:
          destination.droppableId !== source.droppableId
            ? newSectionID
            : undefined,
      })
    );

    if (source.droppableId === destination.droppableId) {
      // move within the same list
      const result = reorder(
        lists[source.droppableId],
        source.index,
        destination.index
      );

      let _lists = { [source.droppableId]: result };

      setLists({ ...lists, ..._lists });
    } else {
      const result = move(
        lists[source.droppableId],
        lists[destination.droppableId],
        source,
        destination
      );
      setLists({
        ...lists,
        ...result,
      });
    }
  };

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ width: "100%" }}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h6" color="textPrimary">
                {sessionFamily.name}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {sessionFamily.sections.length} Section
                {sessionFamily.sections.length > 1 ? "s" : ""}.{" "}
                {sessionFamily.musicians.length} Musician
                {sessionFamily.musicians.length > 1 ? "s" : ""}.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <div style={{ width: "100%" }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={2}>
              {sessionFamily.sections.map((s) => (
                <Grid
                  item
                  key={s.id}
                  style={{
                    borderRight: "solid 1px rgba(155, 155, 155, 0.3)",
                  }}
                >
                  <StudioSessionSection
                    api={api}
                    subscription={subscription}
                    refreshKey={refreshKey}
                    disabled={disabled}
                    conciergeReadOnly={conciergeReadOnly}
                    history={history}
                    sectionRoles={sectionRoles}
                    sessionSection={s}
                    jobs={lists[s.sectionID]}
                    layoutPresets={layoutPresets}
                    refresh={refresh}
                    refreshFuncs={refreshFuncs}
                    instruments={instruments}
                    onMusicianEdit={onMusicianEdit}
                    eventStatuses={eventStatuses}
                    studioSession={studioSession}
                  />
                </Grid>
              ))}
            </Grid>
          </DragDropContext>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
