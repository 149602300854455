export default {
  root: {
    position: "absolute",
    cursor: "pointer",
    userSelect: "none",
  },
  container: {
    background: "rgba(255, 255, 255, 1)",
    padding: "0 7px",
    borderRadius: "3px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
    transition: "box-shadow 0.5s cubic-bezier(0.25, 0.8, 0.25, 1)",
    textAlign: "center",
  },
  container_dragging_yes: {
    boxShadow:
      "0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)",
  },
  label: {
    display: "inline-block",
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#222",
  },
  interfacesWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  interfaces: { flexBasis: "50%" },
  edgeJointPoint: {
    width: "5px",
    height: "5px",
    marginTop: "16px",
    display: "inline-block",
    verticalAlign: "middle",
    background: "#222",
    borderRadius: "50%",
    cursor: "pointer",
  },
  edgeJoint_ouput: { textAlign: "right" },
  "edgeJoint_input .edgeJointPoint": { marginRight: "7px" },
  "edgeJoint_ouput .edgeJointPoint": { marginLeft: "7px" },
};
