// helpers
// actions
import * as Actions from "actions";
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperJournalPage from "../component/WrapperJournalPage";

const mapStateToProps = (state) => ({
  posts: state.posts,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  updatePost: (...args) => dispatch(Actions.updatePost(...args)),
  getPosts: (...args) => dispatch(Actions.getPosts(...args)),
});
class JournalPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    updatePost: PropTypes.func,
    user: PropTypes.object,
    posts: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getPosts } = this.props;
    const { id } = this.state;
    const r = await getPosts(id);
    if (r.success) {
      this.setState({
        loading: false,
        element: r.payload,
      });
    }
  }

  render() {
    const { location, history, user, posts, updatePost } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperJournalPage
        history={history}
        location={location}
        urlParams={urlParams}
        updatePost={updatePost}
        posts={posts}
        refresh={this.refresh.bind(this)}
        loading={loading}
        user={user}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(JournalPage);
